import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import ConfigsTab from "./configsTab/ConfigsTab";
import ContentTab from "./contentTab/ContentTab";
import "../surveyBuilder.scss";

const ControlSection = (props) => {
  const intl = useIntl();
  const {
    surveySections,
    setSurveySections,
    filteredSections,
    handleNewSection,
    handleDuplicateSection,
    handleDeleteSection,
    emptySection,
    setEmptySection,
    handleDeleteQuestion,
    setSQIndex,
    sqIndex,
    setSSIndex,
    ssIndex,
    handleSaveChanges,
    setSurveyData,
    setLogoData,
    logoData,
    surveyData,
    setEmptySurveyQuestions,
    emptySurveyQuestions,
    setOperationPerformed,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
    scrollToIdFunc,
    proceedError,
    afterSecError,
    secTitleErr,
  } = props;

  const [value, setValue] = useState(0);
  const [tab, setTab] = useState("content");
  const handleChange = (e, newVal) => {
    setValue(newVal);
  };

  return (
    <Box className="control-section-container">
      <Box className="tab-style">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon tabs example"
        >
          <Tab
            label={CheckValueLocale("content_tab", "", {}, intl)}
            data-testid="content"
          />
          <Tab
            label={CheckValueLocale("configs_tab", "", {}, intl)}
            data-testid="configs"
          />
        </Tabs>
      </Box>
      <Box className="scroll-bar-border" />
      <Box className="scroll-bar-control-section">
        {value === 0 ? (
          <ContentTab
            surveySections={surveySections}
            setSurveySections={setSurveySections}
            filteredSections={filteredSections}
            handleNewSection={handleNewSection}
            handleDuplicateSection={handleDuplicateSection}
            handleDeleteSection={handleDeleteSection}
            emptySection={emptySection}
            setEmptySection={setEmptySection}
            setEmptySurveyQuestions={setEmptySurveyQuestions}
            emptySurveyQuestions={emptySurveyQuestions}
            handleDeleteQuestion={handleDeleteQuestion}
            setTab={setTab}
            tab={tab}
            setSQIndex={setSQIndex}
            sqIndex={sqIndex}
            setSSIndex={setSSIndex}
            ssIndex={ssIndex}
            setOperationPerformed={setOperationPerformed}
            scrollToIdFunc={scrollToIdFunc}
            proceedError={proceedError}
            afterSecError={afterSecError}
            secTitleErr={secTitleErr}
          />
        ) : (
          <ConfigsTab
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            setLogoData={setLogoData}
            logoData={logoData}
            handleSaveChanges={handleSaveChanges}
            setTab={setTab}
            multiRes={multiRes}
            handleMultiRes={handleMultiRes}
            setSaveChangesFlag={setSaveChangesFlag}
          />
        )}
      </Box>
    </Box>
  );
};

export default ControlSection;
