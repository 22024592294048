import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import moment from "moment";
import { CheckValueLocale, isArabic } from "utils/helpers";
import requestToken from "images/luci-images/request-token.svg";
import "./request.scss";
import { useState } from "react";

const LuciRequest = ({
  requestsList,
  setQuestionIdAnswer,
  setShowLuciAnswers,
  handleRequestClick,
}) => {
  const intl = useIntl();
  const [isInValidImage, setIsInValidImage] = useState(false);

  const handleImageError = () => {
    setIsInValidImage(true);
  };

  const localizedDateTime = (requestDate) => {
    return window.localStorage.lang === "en"
      ? moment.unix(parseInt(requestDate)).format("D MMM YYYY - h:mm A")
      : moment.unix(requestDate).format("D MMM - YYYY")?.split(" ")[0] +
          " " +
          CheckValueLocale(
            moment.unix(requestDate).format("D MMM - YYYY")?.split(" ")[1],
            "",
            {},
            intl,
          ) +
          ", " +
          moment.unix(requestDate).format("D MMM - YYYY")?.split(" ")[3] +
          " " +
          moment
            ?.unix(requestDate)
            ?.format("D MMM - YYYY h:mm A")
            ?.split(" ")[4] +
          " " +
          moment
            ?.unix(requestDate)
            ?.format("D MMM - YYYY h:mm A")
            ?.split(" ")[5];
  };

  return requestsList?.map(
    ({
      attributes: {
        status,
        token,
        text,
        user_name,
        luci_section_name,
        avatar,
        created_at,
        product: { name },
        id,
      },
    }) => {
      return (
        <Box
          className="luci-request-wrapper"
          onClick={() => handleRequestClick(id)}
        >
          <Box className="luci-request-header">
            <Box className="luci-request-img">
              <Tooltip
                title={user_name}
                arrow
                placement={
                  window?.localStorage?.lang == "en"
                    ? "left-start"
                    : "right-start"
                }
                PopperProps={{ style: { zIndex: 9999 } }}
              >
                <Box className="luci-img-username">
                  {avatar == null || isInValidImage ? (
                    <Box className="luci-img-char">{user_name?.charAt(0)}</Box>
                  ) : (
                    <img src={avatar} onError={handleImageError} />
                  )}
                </Box>
              </Tooltip>
              <Box className="luci-request-date">
                {localizedDateTime(created_at)}
              </Box>
            </Box>
            <Box className="luci-request-tokens">
              <Box className="luci-request-token">
                {status == "in_progress" || status == "done"
                  ? token * -1
                  : token}
                <img src={requestToken} />
              </Box>
              <Box className={`luci-request-status luci_${status}`}>
                {CheckValueLocale(`luci_${status}`, "", {}, intl)}
              </Box>
            </Box>
          </Box>
          <Box
            className={
              isArabic(text)
                ? "luci-text-arabic luci-request-desc"
                : "luci-request-desc"
            }
          >
            {text}
          </Box>
          <Box className="luci-request-product">
            {CheckValueLocale(name, "", {}, intl)} - {luci_section_name}
          </Box>
        </Box>
      );
    },
  );
};

export default LuciRequest;
