import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import "./alertform.scss";
import AlertActions from "./components/alertsActions";
import MainAlertsFields from "./components/mainAlertsFields";
import AdavancedAlertsFields from "./components/advancedAlertsFields";
import CircularLoading from "components/circularProgress";
import WebhooksAlertsFields from "./components/webhooksAlertsFields";

const AlertForm = (props) => {
  const intl = useIntl();
  const {
    openForm,
    handleCloseForm,
    productType,
    datasourceType,
    alertType,
    alertRateType,
    setSelectedMonitors,
    getAlertsMonitors,
    setAlertThresholdType,
    getAlertsLoading,
    alertThresold,
    isCreateAlert,
    alertEditData,
    setAlertName,
    setProductType,
    setDatasourceType,
    setAlertType,
    setAlertRateType,
    setRecieveAlertsHourly,
    setSelectedEmails,
    setMiniumimNoFollowers,
    setEmailsEnabled,
    setAlertKeywords,
    setSelectedPostUsers,
    setThresholdManualValue,
    getEditAlertsLoading,
    splitDataSourceType,
    filteredAlertsData,
    setMaxmNumberEmailsValue,
    setMiniumimNoInteractions,
    alertWebhookInfo,
    setEnableWebhooks,
    getApisLoading,
    setApiKey,
    setMethod,
    setUrl,
    setVerificationKey,
    setTestWebhooksResult,
    formFieldErrors,
    setFormFieldErrors,
    lucidyaApisList,
    getAlertMonitorsEngagements,
    alertConversationType,
    selectedSources,
    setSelectedSources,
    alertsSources,
    handleSourcesIds,
    setAlertConversationType,
    setAuthVarName,
    setAuthLocation,
    isMonitorAction,
  } = props;
  let formtitle = isCreateAlert ? "create_new_alert" : "edit_alert";

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 304,
        width: 300,
      },
    },
  };

  //Here we set data in edit form pop-up , we check here if value returned or not from BE.
  const setAlertSpecificData = () => {
    let keywordsData =
        alertEditData?.constrains?.keywords !== "" &&
        alertEditData?.constrains?.keywords?.split(","),
      postsUsersData =
        alertEditData?.constrains?.usernames !== "" &&
        alertEditData?.constrains?.usernames?.split(",");

    alertEditData?.name && setAlertName(alertEditData?.name);
    alertEditData?.products?.id &&
      setProductType(alertEditData?.products?.id?.toString());
    alertEditData?.data_source?.id &&
      setDatasourceType(
        `${alertEditData?.data_source?.id}-${alertEditData?.data_source?.name}-${alertEditData?.data_source?.channel_type_id}`,
      );

    alertEditData?.alert_type?.id &&
      setAlertType(alertEditData?.alert_type?.id);
    alertEditData?.rate && setAlertRateType(alertEditData?.rate);
    alertEditData?.monitors && setSelectedMonitors(alertEditData?.monitors);
    alertEditData?.engagement_monitors?.length &&
      setSelectedSources(handleSourcesIds(alertEditData?.engagement_monitors));
    alertEditData?.conversation_type?.length &&
      setAlertConversationType(
        handleSourcesIds(alertEditData?.conversation_type)?.toString(),
      );
    alertEditData?.hours &&
      setRecieveAlertsHourly(alertEditData?.hours?.toString());
    alertEditData?.constrains?.threshold &&
      setAlertThresholdType(
        alertEditData?.constrains?.threshold?.toLowerCase(),
      );
    alertEditData?.users && setSelectedEmails(alertEditData?.users);
    alertEditData?.constrains?.min_followers &&
      setMiniumimNoFollowers(
        alertEditData?.constrains?.min_followers?.toString(),
      );
    alertEditData?.send_email && setEmailsEnabled(alertEditData?.send_email);
    keywordsData && setAlertKeywords(keywordsData);
    postsUsersData && setSelectedPostUsers(postsUsersData);
    alertEditData?.constrains?.posts_increase_rate &&
      setThresholdManualValue(alertEditData?.constrains?.posts_increase_rate);
    alertEditData?.max_emails &&
      setMaxmNumberEmailsValue(alertEditData?.max_emails);
    alertEditData?.constrains?.min_num_of_interactions &&
      setMiniumimNoInteractions(
        alertEditData?.constrains?.min_num_of_interactions,
      );
    setEnableWebhooks(alertEditData?.webhook_info?.enabled || false);
    setApiKey(alertEditData?.webhook_info?.token_id?.toString() || "");
    setMethod(alertEditData?.webhook_info?.method_type || "");
    setUrl(alertEditData?.webhook_info?.callback_url || "");
    setVerificationKey(alertEditData?.webhook_info?.verification_key || "");
    alertEditData?.webhook_info?.verification_key_name &&
      setAuthVarName(
        alertEditData?.webhook_info?.verification_key_name ||
          "verification_key",
      );
    alertEditData?.webhook_info?.key_location_type_id &&
      setAuthLocation(alertEditData?.webhook_info?.key_location_type_id || [1]);
    alertEditData?.webhook_info?.callback_url &&
      !alertEditData?.webhook_info?.is_valid &&
      setTestWebhooksResult("failed");
    alertEditData?.webhook_info?.callback_url &&
      alertEditData?.webhook_info?.is_valid &&
      setTestWebhooksResult("passed");
    alertEditData?.webhook_info?.token_id &&
    !isApiValid(alertEditData?.webhook_info?.token_id)
      ? setFormFieldErrors({
          ...formFieldErrors,
          apiKey: "api_key_invalid",
        })
      : setFormFieldErrors({
          ...formFieldErrors,
          apiKey: "",
        });
  };

  const isApiValid = (id) =>
    lucidyaApisList.find((api) => id == api.id && api?.attributes?.is_valid);

  useEffect(() => {
    alertEditData?.id && setAlertSpecificData();
  }, [alertEditData, lucidyaApisList]);

  let alertLoader = isCreateAlert
    ? getAlertsLoading ||
      (getApisLoading &&
        alertWebhookInfo?.webhook_feature_enabled &&
        handlelUserRoles("PUBLIC_API", "CREATE_WEBHOOK_URL"))
    : getAlertsLoading ||
      (getApisLoading &&
        alertWebhookInfo?.webhook_feature_enabled &&
        handlelUserRoles("PUBLIC_API", "CREATE_WEBHOOK_URL")) ||
      getEditAlertsLoading;

  useEffect(() => {
    if (isCreateAlert) {
      if (!isMonitorAction) setSelectedMonitors([]);
      if (
        productType !== "" &&
        datasourceType !== "" &&
        alertType !== "" &&
        activeProductName !== "engagements" &&
        !isMonitorAction
      )
        getAlertsMonitors(
          productType,
          splitDataSourceType()?.id,
          alertType,
          splitDataSourceType()?.channel_type_id,
        );
      if (alertThresold?.length)
        setAlertThresholdType(alertThresold[0]?.toLowerCase());
    }
  }, [productType, datasourceType, alertType, alertRateType]);

  useEffect(() => {
    if (activeProductName === "engagements") {
      if (isCreateAlert) setSelectedSources([]);
      getAlertMonitorsEngagements(alertConversationType);
    }
  }, [alertConversationType]);

  let activeProductName = filteredAlertsData?.name?.toLowerCase();
  // for survey product
  let surveyAlertTypes =
    alertsSources?.length &&
    alertsSources[0]?.alert_types?.length &&
    alertsSources[0]?.alert_types[0];
  useEffect(() => {
    if (activeProductName === "survey" && alertsSources?.length) {
      setDatasourceType(
        `${alertsSources[0]?.id}-${alertsSources[0]?.name}-${alertsSources[0]?.channel_type_id}`,
      );
      setAlertType(surveyAlertTypes?.id);
      surveyAlertTypes?.rates?.length &&
        setAlertRateType(surveyAlertTypes?.rates[0]);
      surveyAlertTypes?.threshold?.length &&
        setAlertThresholdType(surveyAlertTypes?.threshold[0]);
    }
    if (activeProductName == "engagements") {
      if (alertsSources?.length)
        setDatasourceType(
          `${alertsSources[0]?.id}-${alertsSources[0]?.name}-${alertsSources[0]?.channel_type_id}`,
        );
    }
  }, [productType]);
  return (
    <Dialog
      open={openForm}
      className="alert-form-view"
      onClose={handleCloseForm}
    >
      {alertLoader ? (
        <CircularLoading />
      ) : (
        <>
          <DialogTitle id="create-alerts-dialog-title">
            {CheckValueLocale(formtitle, "", {}, intl)}
          </DialogTitle>
          <DialogContent id="create-alert-dialog-content">
            <Grid container spacing={2}>
              <MainAlertsFields
                {...props}
                MenuProps={MenuProps}
                activeProductName={activeProductName}
                isMonitorAction={isMonitorAction}
                monitorData={props?.monitorData}
              />
              <AdavancedAlertsFields
                {...props}
                MenuProps={MenuProps}
                activeProductName={activeProductName}
              />
              {alertWebhookInfo?.webhook_feature_enabled &&
              handlelUserRoles("PUBLIC_API", "CREATE_WEBHOOK_URL") ? (
                <WebhooksAlertsFields {...props} MenuProps={MenuProps} />
              ) : null}
            </Grid>
          </DialogContent>
          <AlertActions {...props} isCreateAlert={isCreateAlert} />
        </>
      )}
    </Dialog>
  );
};
export default AlertForm;
