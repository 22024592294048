import { useState } from "react";
import { useIntl } from "react-intl";
import { TextField, Box, Alert } from "@mui/material";
import { Add } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { Link, useHistory } from "react-router-dom";
import {
  CheckValueLocale,
  handlelUserRoles,
} from "../../../../../utils/helpers/index.js";
import PageHeader from "components/pageHeader.js";
import LucButton from "shared/lucButton/lucButton.js";

const DashboardHeader = (props) => {
  const history = useHistory();
  const intl = useIntl();
  const {
    canCreateDashboard,
    setSearchActivated,
    getDashboards,
    setPage,
    pagination,
    smMonitorsCount,
    cxmMonitorsCount,
  } = props;
  const [open, setOpen] = useState(false);
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);

  let createDashbaordFlag =
    !handlelUserRoles("SM", "CREATE_DASHBOARD") ||
    (smMonitorsCount < 1 && cxmMonitorsCount < 1);

  window.addEventListener("scroll", (event) => {
    if (open) {
      setOpen(false);
    }
  });

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      props?.setSearchInput(props?.searchText);
      setSearchActivated(true);
      e.target.blur();
      // If page is already 1, setPage will not work so we will call getDashboards directly
      if (props?.page === 1) {
        getDashboards();
      } else {
        setPage(1);
      }
    }
  };

  const searchIconOnClick = () => {
    props?.setSearchInput(props?.searchText);
    // If page is already 1, setPage will not work so we will call getDashboards directly
    if (props.page === 1) {
      props.getDashboards();
    } else {
      props.setPage(1);
    }
  };

  return (
    <>
      <Box id="monitorsListHeader" className="header-table">
        <Box container mb={"20px"} className="title-header-box">
          <PageHeader
            title="dashboards_page_title"
            titleAttributeName="num"
            titleAttributeValue={pagination?.count || 0}
          />
          <Box id="title-button-box">
            <LucButton
              onClick={() => history.push(`/dashboard/create_dashboard`)}
              variant="filled"
              startIcon={<Add className="add-icon" />}
              disabled={createDashbaordFlag}
              id="dashboard-list-new-dashboard-btn"
            >
              {CheckValueLocale("new_dashboard", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>

        {smMonitorsCount == 0 && cxmMonitorsCount == 0 ? (
          <Alert severity={"info"} id="create-dashboard-alert-msg">
            {CheckValueLocale(
              "create_dashboard_monitors_alert_msg",
              "",
              {},
              intl,
            )}
          </Alert>
        ) : null}

        {props?.dashboards?.length > 0 || props?.searchInput ? (
          <Box className="search-desblay search-dashboard-box">
            <Box className="search-company">
              <Box className="search-box">
                <TextField
                  className="search-field-dashboard search-field-width"
                  label={CheckValueLocale(
                    "dashboard_placeholder",
                    "",
                    {},
                    intl,
                  )}
                  variant="outlined"
                  value={props?.searchText}
                  onChange={(e) => {
                    props.setSearchActivated(true);
                    props.setSearchText(e.target.value);
                  }}
                  onBlur={() => setSearchBoxClicked(false)}
                  onFocus={() => setSearchBoxClicked(true)}
                  onKeyPress={handleEnterKeyPress}
                />
                <Box
                  className={
                    "search-icon-container search-icon-position " +
                    (searchBoxClicked ? "search-icon-container-blue" : "")
                  }
                  onClick={() => searchIconOnClick()}
                >
                  <FontAwesomeIcon className="search-icon" icon={faSearch} />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
export default DashboardHeader;
