import {
  Box,
  Typography,
  OutlinedInput,
  FormHelperText,
  Button,
} from "@mui/material";
import "./mainEdit.scss";
import { useState, useEffect } from "react";
import { CheckValueLocale } from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import wordIcon from "images/engagements-images/wordIcon.svg";
import FormControl from "@mui/material/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import WordFileUpload from "../setup/wordFileUpload";
import PopupModal from "components/popupModal";
import EngagementsController from "services/controllers/engagementsController";
import LucButton from "shared/lucButton/lucButton";

const inputes = [
  { label: "luci_industry", placeholder: "industry_plac" },
  { label: "luci_business_type", placeholder: "bus_type_plac" },
  { label: "luci_business_hq", placeholder: "hq_plac" },
  { label: "brand_tone", placeholder: "tone_plac" },
];

const AiSection = ({ selectedData, setSelectedData }) => {
  const intl = useIntl();
  const [openTermsPopup, setOpenTermsPopup] = useState(false);
  const [errorFile, setErrorFile] = useState("");

  //word........................................

  let wordFileName =
    selectedData?.stepTwo?.files && selectedData?.stepTwo?.files[0]?.file?.name;
  let wordFileError =
    selectedData?.stepTwo?.files && selectedData?.stepTwo?.files[0]?.errors;
  let wordFileErrorMsg =
    wordFileError && selectedData?.stepTwo?.files[0]?.errors[0]?.code;
  let fileName = wordFileName && wordFileName?.split(".")[0];

  const displayUploadErrorMessage = () => {
    const regex = /^[\u0621-\u064A0-9a-zA-Z0-9-_]+$/;
    let errorMessage = "";
    selectedData?.stepTwo?.files?.length
      ? wordFileErrorMsg === "file-invalid-type"
        ? (errorMessage = "file_not_sup")
        : wordFileErrorMsg === "file-too-large"
          ? (errorMessage = "size_limit")
          : (errorMessage = "")
      : (errorMessage = "");
    return errorMessage;
  };

  useEffect(() => {
    if (
      selectedData?.stepTwo?.isFileUploaded &&
      selectedData?.stepTwo?.files[0]?.file
    ) {
      setSelectedData({
        ...selectedData,
        stepTwo: {
          ...selectedData?.stepTwo,
          fetching: true,
          isFileUploaded: false,
          isFileChanged: true,
          network_error: "",
        },
      });
      const formDataFile = new FormData();
      formDataFile.append(
        "knowledge_base_file",
        selectedData?.stepTwo?.files[0]?.file,
      );
      formDataFile.append("product_id", window?.localStorage?.engagements_id);
      EngagementsController.uploadFile(formDataFile).then((data) => {
        if (data?.data?.status == 200) {
          setSelectedData({
            ...selectedData,
            stepTwo: {
              ...selectedData?.stepTwo,
              isFileUploaded: false,
              fetching: false,
              knowledge_base_url: data?.data?.data?.knowledge_base_url,
              network_error: "",
            },
          });
        } else {
          if (data?.data?.response?.status == 404) {
            setSelectedData({
              ...selectedData,
              stepTwo: {
                ...selectedData?.stepTwo,
                files: [],
                knowledge_base_url: "",
                isFileUploaded: false,
                fetching: false,
                network_error: "upload_failed",
              },
            });
          } else {
            setSelectedData({
              ...selectedData,
              stepTwo: {
                ...selectedData?.stepTwo,
                files: [],
                knowledge_base_url: "",
                isFileUploaded: false,
                fetching: false,
                network_error: "network_failed",
              },
            });
          }
        }
      });
    }
  }, [selectedData?.stepTwo]);

  //word........................................

  const handleInputs = (e, type) => {
    setSelectedData({
      ...selectedData,
      stepTwo: {
        ...selectedData?.stepTwo,
        [type]: e.target.value,
      },
    });
  };

  const termsPopup = () => {
    let terms = ["terms_one", "terms_two", "terms_three", "terms_four"];
    return (
      <Box className="main-terms-popup">
        <Box className="main-terms-header">
          {CheckValueLocale("terms_tit", "", {}, intl)}
        </Box>
        <Box className="main-terms-contant">
          {terms?.map((item, i) => {
            return (
              <Box key={i} className="main-term-card">
                <Box className="term-card-dotted">.</Box>
                <Box className="term-card-contant">
                  {CheckValueLocale(item, "", {}, intl)}
                </Box>
              </Box>
            );
          })}
          <Box className="main-term-card">
            <Box className="term-card-dotted">.</Box>
            <Box className="term-card-contant">
              <Box>
                {CheckValueLocale(
                  "terms_five",
                  "",
                  {
                    link: (
                      <a
                        href={`https://openai.com/policies/api-data-usage-policies`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://openai.com/policies/api-data-usage-policies
                      </a>
                    ),
                  },
                  intl,
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="main-terms-footer">
          <Button onClick={() => setOpenTermsPopup(false)}>
            {CheckValueLocale("audience_close", "", {}, intl)}
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <Box className="main-step-two">
      {!selectedData?.stepTwo?.enabled ? (
        <Box className="step-two-footer">
          <Box>{CheckValueLocale("first_term_edit", "", {}, intl)}</Box>
          <Box
            className="step-two-footer-blue"
            onClick={() => setOpenTermsPopup(true)}
          >
            {CheckValueLocale("terms", "", {}, intl)}
          </Box>
          <Box>{CheckValueLocale("sec_term_edit", "", {}, intl)}</Box>
        </Box>
      ) : null}
      <Box className="step-two-sec frist">
        <Box className="sec-header">
          <Box className="blue-header">
            {CheckValueLocale("improve_res", "", {}, intl)}
          </Box>
          <Box className="sec-pragrgh">
            {CheckValueLocale("invest_more", "", {}, intl)}
          </Box>
        </Box>
        <Box>
          <Box className="main-step-inputs">
            {inputes?.map((item, i) => (
              <Box key={i}>
                <Box className="input-label">
                  {CheckValueLocale(item?.label, "", {}, intl)}
                  {/* <span className="red-star-req"> *</span> */}
                </Box>
                <FormControl className="input-field">
                  <OutlinedInput
                    id={`engagement_settings_${item?.label}`}
                    value={selectedData?.stepTwo[item?.label]}
                    onChange={(e) => handleInputs(e, item?.label)}
                    placeholder={CheckValueLocale(
                      item?.placeholder,
                      "",
                      {},
                      intl,
                    )}
                  />
                </FormControl>
              </Box>
            ))}
          </Box>
          <Box className="input-about-you">
            <Box className="input-label">
              {CheckValueLocale("about_bus", "", {}, intl)}
              {/* <span className="red-star-req"> *</span> */}
            </Box>
            <FormControl className="input-field">
              <OutlinedInput
                id="engagement-settings-about-business-input"
                multiline
                maxRows={5}
                value={selectedData?.stepTwo["aboutYour"]}
                onChange={(e) => handleInputs(e, "aboutYour")}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box className="step-two-sec">
        <Box className="sec-header">
          <Box className="blue-header">
            {CheckValueLocale("smart_cus", "", {}, intl)}
          </Box>
          <Box className="sec-pragrgh">
            {CheckValueLocale("smart_cus_pragagh", "", {}, intl)}
          </Box>
        </Box>
        <Box className="main-file-upload">
          <Typography className="upload-file-title">
            {CheckValueLocale("word_document", "", {}, intl)}
            {/* <span className="red-star-req"> *</span> */}
          </Typography>
          {!selectedData?.stepTwo?.knowledge_base_url ||
          selectedData?.stepTwo?.fetching ? (
            !selectedData?.stepTwo?.fetching ? (
              <>
                <WordFileUpload
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  displayUploadErrorMessage={displayUploadErrorMessage}
                  fileName={fileName}
                  wordFileName={wordFileName}
                />
                {displayUploadErrorMessage() ||
                selectedData?.stepTwo?.network_error?.length ? (
                  <FormHelperText error className="upload-file-error-msg">
                    {CheckValueLocale(
                      displayUploadErrorMessage()
                        ? displayUploadErrorMessage()
                        : selectedData?.stepTwo?.network_error,
                      "",
                      {},
                      intl,
                    )}
                  </FormHelperText>
                ) : null}
              </>
            ) : (
              <Box className="main-upload-loader">
                <Box className="upload-loader-container">
                  <FontAwesomeIcon icon={faSpinnerThird} spin />
                  {CheckValueLocale("upload_file", "", {}, intl)}
                </Box>
              </Box>
            )
          ) : (
            <Box className="word-file-container">
              <Box className="word-file-content">
                <img
                  src={wordIcon}
                  className="word-file-icon"
                  alt="word icon"
                />
                <Box className="uploaded-file-content">
                  <Typography className="word-file-name-title">
                    {wordFileName
                      ? wordFileName
                      : selectedData?.stepTwo?.fileName}
                  </Typography>
                  <Typography className="word-file-kb-num">
                    {`(${(selectedData?.stepTwo?.files[0]?.file?.size > 0
                      ? selectedData?.stepTwo?.files[0]?.file?.size / 1000
                      : selectedData?.stepTwo?.fileSize / 1000
                    ).toFixed(0)}KB)`}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <LucButton
                  id="engagement-settings-remove-file-btn"
                  variant="flat"
                  startIcon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() =>
                    setSelectedData({
                      ...selectedData,
                      stepTwo: {
                        ...selectedData?.stepTwo,
                        files: [],
                        knowledge_base_url: "",
                        isFileChanged: true,
                        isFileUploaded: false,
                      },
                    })
                  }
                >
                  {CheckValueLocale("del_file", "", {}, intl)}
                </LucButton>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box className="step-two-sec last">
        <Box className="sec-header">
          <Box className="blue-header">
            {CheckValueLocale("learn_from", "", {}, intl)}
            <Box className="soon-engagment-msg">
              {CheckValueLocale("soon_engagement", "", {}, intl)}
            </Box>
          </Box>
          <Box className="sec-pragrgh">
            {CheckValueLocale("learn_from_pragragh", "", {}, intl)}
          </Box>
        </Box>
        <Box className="step-boolean">
          <FormControlLabel
            className="engagement-step-switch-bool"
            control={
              <Switch
                checked={false}
                color="primary"
                onChange={() =>
                  setSelectedData({
                    ...selectedData,
                    stepTwo: {
                      ...selectedData?.stepTwo,
                      enabled: !selectedData?.stepTwo?.enabled,
                    },
                  })
                }
                disabled={true}
              />
            }
            label={CheckValueLocale("enable", "", {}, intl)}
          />
        </Box>
      </Box>
      <PopupModal
        body={termsPopup()}
        fixPadding
        open={openTermsPopup}
        close={() => setOpenTermsPopup(false)}
      />
    </Box>
  );
};

export default AiSection;
