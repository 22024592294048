import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../../createMonitor/createMonitor.scss";
import { isPublicSpecialCharactersOrEmojis } from "./specialCharOrEmojis";
import InfoIcon from "@mui/icons-material/Info";

const CustomerCareInput = ({
  coustomerCareVal,
  setCoustomerCareVal,
  dataSourceName,
  isSpecificFormat,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const intl = useIntl();

  const handleInputChange = (event) => {
    let val = event.target.value;
    if (val.length > 15 && isSpecificFormat) {
      setErrorMsg("username_max_char");
    } else if (
      isPublicSpecialCharactersOrEmojis(
        val,
        undefined,
        undefined,
        false,
        isSpecificFormat,
      )
    ) {
      setErrorMsg("username_special_characters_error");
    } else {
      setErrorMsg("");
    }
    setCoustomerCareVal([val]);
  };
  return (
    <Box className="shared-customer-care-container">
      <Typography component={"h3"}>
        {CheckValueLocale("customer_care_account", "", {}, intl)}
        <Tooltip
          title={CheckValueLocale("customer_care_info", "", {}, intl)}
          placement={intl?.locale === "en" ? "right" : "left"}
          arrow
          id="radio-buttons-box"
          componentsProps={{
            tooltip: {
              sx: {
                width: "234px",
              },
            },
          }}
        >
          <Typography component={"span"} className="tooltip-btn">
            <InfoIcon id="customer-care-info-icon" />
          </Typography>
        </Tooltip>
      </Typography>
      <TextField
        id="outlined-error-helper-text"
        className={
          dataSourceName == "facebook"
            ? "customer-care-input-facebook customer-care-input"
            : "customer-care-input"
        }
        helperText={
          <span className={errorMsg ? "error-msg helper-msg" : "helper-msg"}>
            {CheckValueLocale(
              errorMsg ? errorMsg : "customer_care_username",
              "",
              {},
              intl,
            )}
          </span>
        }
        value={coustomerCareVal}
        onChange={(e) => handleInputChange(e)}
        InputProps={{
          startAdornment:
            dataSourceName == "facebook" ? null : (
              <InputAdornment position="start">@</InputAdornment>
            ),
        }}
      />
    </Box>
  );
};

export default CustomerCareInput;
