import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LiveDashboardController from "services/controllers/liveDashboardController";
import { convertTwitterToXPlateform } from "utils/helpers";
import { data } from "pages/Monitors/Components/sidebar/dataSourceTabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import publicFacebook from "images/social-icons/facebook-public-icon.png";
import xPlatform from "images/social-icons/x-platform-icon.svg";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";

const CreateDashboardFunction = () => {
  const urlParams = useParams();
  const history = useHistory();
  const [dataSources, setDataSources] = useState([]);
  const [liveDashboardName, setLiveDashboardName] = useState("");
  const [liveDashboardNameError, setLiveDashboardNameError] = useState("");
  const [selectedWidgets, setSelectedWidgets] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [loadingMonitorWidgets, setLoadingMonitorWidgets] = useState(true);
  const [monitorWidgets, setMonitorWidgets] = useState(null);
  const [monitorLiveDashboardDetails, setMonitorLiveDashboardDetails] =
    useState(null);
  const [
    loadingMonitorLiveDashboardDetails,
    setLoadingMonitorLiveDashboardDetails,
  ] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [snackBarHeading, setSnackBarHeading] = useState("");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [openPopupDelete, setOpenPopupDelete] = useState(null);
  const [isEditLiveDashboard, setIsEditLiveDashboard] = useState(false);
  const [hasLiveDashboard, setHasLiveDashboard] = useState(true);
  const tabsName = [
    "account",
    "engagements",
    "customer_care",
    "posts",
    "authors",
    "comments_mentions",
    "questions",
  ];

  const getSourceName = (id) =>
    dataSources?.find((singleDataSource) => singleDataSource?.id == id)?.source;

  const handleChangeCollapse = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelectWidget = (id, dataSource) => {
    if (selectedWidgets?.[dataSource]?.includes(id)) {
      setSelectedWidgets((prevSelectedWidgets) => ({
        ...prevSelectedWidgets,
        [dataSource]: [...prevSelectedWidgets[dataSource]]?.filter(
          (idSelected) => idSelected !== id,
        ),
      }));
    } else {
      setSelectedWidgets((prevSelectedWidgets) => ({
        ...prevSelectedWidgets,
        [dataSource]: prevSelectedWidgets[dataSource]
          ? [...prevSelectedWidgets[dataSource], id]
          : [id],
      }));
    }
  };

  const handleLiveDashboardDetails = () => {
    setIsEditLiveDashboard(
      monitorLiveDashboardDetails?.monitor_user_live_dashboard?.length,
    );
    const dataSourcesListArr =
      monitorLiveDashboardDetails?.monitor_user_live_dashboard?.map(
        (dataSource) => dataSource?.data_sources[0],
      );
    let selectedWidgetsObj = {};
    dataSourcesListArr?.map((dataSource) => {
      selectedWidgetsObj = {
        ...selectedWidgetsObj,
        [dataSource?.data_source_id]: dataSource?.widgets?.map(
          (widget) => widget?.id,
        ),
      };
      setSelectedWidgets(selectedWidgetsObj);
    });
    setLiveDashboardName(
      monitorLiveDashboardDetails?.monitor_user_live_dashboard?.[0]
        ?.dashboard_name,
    );
  };

  const getMonitorLiveDashboardList = () => {
    if (!urlParams?.userDashboardId) {
      LiveDashboardController.getMonitorLiveDashboardList({
        monitor_id: urlParams.monitorId,
      }).then((data) => {
        const accessDashboard = data?.data?.monitor_user_live_dashboard?.find(
          (item) => +item?.user_id === +localStorage.getItem("user_id"),
        );
        if (accessDashboard) {
          history.push({
            pathname: `/social/monitor_list`,
          });
        }
        setHasLiveDashboard(false);
      });
    } else {
      setHasLiveDashboard(false);
    }
  };

  const getMonitorWidgets = () => {
    setLoadingMonitorWidgets(true);
    LiveDashboardController.getMonitorWidgets({
      monitor_id: urlParams?.monitorId,
    }).then((data) => {
      setMonitorWidgets(data?.data);
      setLoadingMonitorWidgets(false);
    });
  };

  const createLiveDashboardHandler = () => {
    setIsActionLoading(true);
    LiveDashboardController.createLiveDashboard(getQueryData()).then((res) =>
      handleResponse(res, "create"),
    );
  };

  const editLiveDashboardHandler = () => {
    setIsActionLoading(true);
    LiveDashboardController.editLiveDashboard(getQueryData()).then((res) =>
      handleResponse(res, "edit"),
    );
  };

  const getQueryData = () => {
    const dataSourceWidgets = Object.keys(selectedWidgets || {})
      ?.filter((dataSource) => selectedWidgets[dataSource]?.length)
      ?.map((dataSource) => ({
        data_source_id: dataSource,
        widgets: selectedWidgets[dataSource]?.map((id, i) => ({
          widget_id: id,
          order: i + 1,
        })),
      }));
    return {
      monitor_user_live_dashboard: {
        monitor_model_id: urlParams?.monitorId,
        dashboard_name: liveDashboardName,
        data_sources: dataSourceWidgets,
      },
    };
  };
  const handleResponse = (res, type) => {
    const userDashboardId = res?.data?.dashboard_user_id;
    if (res?.status_code === 201 || res?.status_code === 200) {
      setSuccess(true);
      setSnackBarHeading(
        type === "create"
          ? "dashboard_created_successfull"
          : "dashboard_changes_saved",
      );
      setTimeout(() => {
        history.push({
          pathname: `/live-dashboard/${urlParams?.monitorType}/${urlParams?.monitorId}/${userDashboardId}`,
          state: history.location.state,
        });
      }, 2000);
    } else {
      setSuccess(false);
      setSnackBarHeading("update_not");
      setIsActionLoading(false);
    }
  };
  const getMonitorLiveDashboardDetails = () => {
    setLoadingMonitorLiveDashboardDetails(true);
    LiveDashboardController.getMonitorLiveDashboardDetails({
      monitor_id: urlParams?.monitorId,
      dashboard_user_id: urlParams?.userDashboardId,
    }).then((data) => {
      setMonitorLiveDashboardDetails(data?.data);
      setLoadingMonitorLiveDashboardDetails(false);
    });
  };

  const deleteLiveDashboard = () => {
    const queryData = {
      monitor_user_live_dashboard: {
        monitor_model_id: urlParams?.monitorId,
      },
    };
    setIsDeleteLoading(true);
    LiveDashboardController.deleteLiveDashboard(queryData).then((res) => {
      if (res?.errorMsg) {
        setSuccess(false);
        setSnackBarHeading("update_not");
        setOpenPopupDelete(null);
      } else {
        setSuccess(true);
        setSnackBarHeading("addons_success");
        setTimeout(() => {
          history.push("/social/monitor_list");
        }, 1000);
      }
      setIsDeleteLoading(false);
    });
  };

  const getSelectedWidgetsLength = (tabsWidgets, selectedWidgets) =>
    tabsWidgets?.filter((widgetValue) =>
      selectedWidgets?.includes(widgetValue?.id),
    )?.length;

  const getDataSourceTabsSorted = (dataSourceTabs) =>
    Object.keys(dataSourceTabs || {})?.sort(
      (a, b) => tabsName.indexOf(a) - tabsName.indexOf(b),
    );

  const getMonitorUrl = (urlParams, dataSourceId) => {
    const monitor_id = urlParams?.monitorId;
    const monitorType = urlParams?.monitorType;
    const monitorDataSources = getSourceName(dataSourceId);
    const dataSourceName = convertTwitterToXPlateform(monitorDataSources);
    let monitorUrl = {};
    data.forEach((i) => {
      //'data' here is static array of object for all avaliable tabs for each datasource as per the its monitorType
      if (Object.keys(i)[0] == `${monitorDataSources}_${monitorType}`) {
        monitorUrl = {
          pathname: `monitor/${monitorType}/${
            dataSourceName === "TALKWALKER" || dataSourceName === "NEWSBLOGS"
              ? "NEWSBLOGS"
              : dataSourceName
          }/${Object.values(i)[0][0]?.analytics[0]?.tabName}/${monitor_id}`,
          state: history.location.state,
          tabs: `${monitorDataSources}_${monitorType}`,
        };
      }
    });
    return monitorUrl;
  };

  const getSocialIcon = (type) => {
    const SocialIcons = {
      FACEBOOK: <img src={publicFacebook} alt="facebook-logo" />,
      TWITTER: <img src={xPlatform} alt="x-platform-logo" />,
      INSTAGRAM: <img src={publicInstagram} alt="instagram-logo" />,
      TALKWALKER: (
        <FontAwesomeIcon
          className="news-blogs-icon"
          alt="news-blogs-logo"
          icon={faNewspaper}
        />
      ),
    };
    return SocialIcons[type];
  };

  const handleInputChange = (e) => {
    let monitorInfo = e.target.value;
    if (
      isPublicSpecialCharactersOrEmojis(monitorInfo, undefined, true) ||
      removeSpecialCharacters(monitorInfo).length === 0
    ) {
      setLiveDashboardNameError("spam_keywords_rules");
    }

    if (
      !isPublicSpecialCharactersOrEmojis(monitorInfo, undefined, true) &&
      !(removeSpecialCharacters(monitorInfo).length === 0)
    ) {
      setLiveDashboardNameError("");

      if (monitorInfo.length > 100) {
        setLiveDashboardNameError("monitor_name_length_error");
      }
    }
    setLiveDashboardName(e.target.value);
  };

  return {
    liveDashboardName,
    selectedWidgets,
    setSelectedWidgets,
    expanded,
    setExpanded,
    loadingMonitorWidgets,
    monitorWidgets,
    isActionLoading,
    success,
    setSuccess,
    snackBarHeading,
    handleChangeCollapse,
    getMonitorWidgets,
    createLiveDashboardHandler,
    editLiveDashboardHandler,
    handleSelectWidget,
    getSourceName,
    monitorLiveDashboardDetails,
    getMonitorLiveDashboardDetails,
    loadingMonitorLiveDashboardDetails,
    handleLiveDashboardDetails,
    dataSources,
    setDataSources,
    deleteLiveDashboard,
    openPopupDelete,
    setOpenPopupDelete,
    isDeleteLoading,
    isEditLiveDashboard,
    getSelectedWidgetsLength,
    getDataSourceTabsSorted,
    getMonitorUrl,
    getSocialIcon,
    handleInputChange,
    liveDashboardNameError,
    hasLiveDashboard,
    getMonitorLiveDashboardList,
  };
};

export default CreateDashboardFunction;
