import { useWidgetName } from "../../../hooks/useWidgetName";

const widgetsMapping = {
  // ----------- Twitter Private -----------
  themes: "themes",
  sentiment: "sentiment_analysis",
  dialects: "dialects_sub_dialects",
  interactions: "conversation_interactions",
  channel_accounts: "channel_accounts",
  content_type: "content_type",
  top_keywords: "top_keywords",
  genders: "gender_distribution",
  top_engaged_authors: "most_engaged_contacts",
  top_influencers: "top_influencers",
  verified_authors: "top_verified_engagers",
  channel_activity: "account_contacts_activity",

  // ----------- Twitter Public -----------
  Twitter__AccountPage__followers_growth: "posts_followers_growth",
  Twitter__AccountPage__posts_interactions: "posts_posts_interactions",
  Twitter__AccountPage__reach_funnel: "posts_reach_funnel",
  Twitter__AccountPage__content_type: "posts_content_type",
  Twitter__AccountPage__account_author_activity:
    "posts_account_authors_activity",
  Twitter__EngagementsPage__volume_overtime: "comments_volume_overtime",
  Twitter__EngagementsPage__sentiment_analysis: "comments_sentiment_analysis",
  Twitter__EngagementsPage__associated_topics: "comments_associated_topics",
  Twitter__EngagementsPage__top_keywords: "comments_top_keywords",
  Twitter__EngagementsPage__content_style: "comments_content_style",
  Twitter__EngagementsPage__top_hashtags: "comments_top_hash_tags",
  Twitter__EngagementsPage__top_languages: "comments_top_languages",
  Twitter__EngagementsPage__top_countries: "comments_top_countries",
  Twitter__EngagementsPage__dialects_and_sub_dialects:
    "comments_dialects_sub_dialects",
  Twitter__EngagementsPage__top_cities: "comments_top_cities",
  Twitter__EngagementsPage__gender_distribution: "comments_gender_distribution",
  Twitter__EngagementsPage__account_types: "comments_account_types",
  Twitter__AccountPage__top_images: "posts_posts_top_images",
  Twitter__AccountPage__top_videos: "posts_posts_top_videos",
  Twitter__EngagementsPage__top_engagers: "comments_top_engagers",
  Twitter__EngagementsPage__top_verified_engagers:
    "comments_top_verified_engagers",
  Twitter__EngagementsPage__top_influencers: "comments_top_influencers",
  Twitter__EngagementsPage__customer_care_performance:
    "comments_customer_care_performance",
  Twitter__EngagementsPage__customer_care_performance:
    "comments_customer_care_performance",
  Twitter__EngagementsPage__themes_and_sub_themes: "comments_themes",
  Twitter__EngagementsPage__top_sources: "comments_top_sources",
  Twitter__AccountPage__volume_overtime: "posts_volume_overtime",
};

export const TwitterOmniRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  const widgetKey = widgetsMapping[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      widgetKey,
      response?.monitor_id,
      response?.eventName?.includes("__") ? "TWITTER" : "TWITTER_PRIVATE",
      response?.eventData,
    );
  else if (stateKey)
    handleWidgetResponse(
      stateKey,
      response?.monitor_id,
      response?.eventName?.includes("__") ? "TWITTER" : "TWITTER_PRIVATE",
      response?.eventData,
    );
};
