import React, { useState, useEffect } from "react";
import IntlTelInput from "react-intl-tel-input";
import { useIntl } from "react-intl";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import { Box, Card, CardContent, FormControl, Input } from "@mui/material";
import "../../surveyBuilder.scss";

const PhoneNumberQuestion = (props) => {
  const {
    question,
    questionIndex,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    handleCardClick,
  } = props;

  const intl = useIntl();
  const [editable, setEditable] = useState(false);
  const [value, setValue] = useState({});

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setValue({ title: question?.text, description: question?.description });
    }
  }, [question]);

  return (
    <Card
      className={`cardStyle ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        "noError"
      }`}
      onClick={() => handleCardClick(sectionIndex, questionIndex)}
    >
      <CardContent className="card-body title-question">
        <div style={{ display: "flex" }}>
          <span className="questionNoStyle">{questionIndex + 1}.</span>
          <Input
            fullWidth
            multiline
            className="questionStylee"
            style={{
              direction: checkKeyboardLanguage(value?.title) ? "rtl" : "ltr",
            }}
            value={value?.title}
            onChange={(e) => {
              handleQuestionTitle(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            name="title"
            id={questionIndex}
            placeholder={CheckValueLocale(
              "enter_your_phone_number",
              "",
              {},
              intl,
            )}
            disableUnderline={true}
          />
        </div>
        <Input
          id={questionIndex}
          variant="outlined"
          className="subtitle"
          onChange={(e) => {
            handleQuestionDesc(e, sectionIndex, questionIndex, value, setValue);
          }}
          style={{
            direction: checkKeyboardLanguage(value?.description)
              ? "rtl"
              : "ltr",
          }}
          name="subtitle"
          placeholder={CheckValueLocale("question_description", "", {}, intl)}
          disableUnderline={true}
          value={value?.description}
        />
        <Box className="phone-number-question">
          <FormControl sx={{ width: "25ch" }}>
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName="form-control react-tel-input phoneInput"
              separateDialCode
              defaultCountry={question?.constraints?.country_code?.toLowerCase()}
              excludeCountries={["il"]}
              fieldName="phone_number"
              placeholder=""
              disabled
            />
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PhoneNumberQuestion;
