import { useIntl } from "react-intl";
import { Box, Button, Tooltip, Zoom } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import "./editAndDeleteButtons.scss";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses?.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses?.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "10px 8px",
    fontWeight: "500",
  },
}));

const EditAndDeleteButtons = (props) => {
  const intl = useIntl();
  const {
    handleOpenDeleteModal,
    deleteTooltip,
    editTooltip,
    setActiveReplyEdit,
    activeReplyEdit,
    index,
    setActiveReplyEditEngagement,
    isDeletedResponse,
    isTweetLink,
    hideEditIcon,
    hideDeleteIcon,
  } = props;
  const isDeleteEditBtn = isDeletedResponse;

  const activeEditMode = () => {
    if (activeReplyEdit) {
      activeReplyEdit[index] = true;
      setActiveReplyEdit([...activeReplyEdit]);
    } else {
      setActiveReplyEditEngagement(true);
    }
  };

  return (
    <Box
      className={`${
        isTweetLink ? "edit-delete-buttons-link" : "edit-delete-buttons"
      }`}
    >
      {!hideEditIcon ? (
        <BootstrapTooltip
          title={CheckValueLocale(editTooltip, "", {}, intl)}
          arrow
          placement="top"
          TransitionComponent={Zoom}
        >
          <Box
            onClick={activeEditMode}
            className={`edit-icon-wrapper ${
              isDeleteEditBtn && "disable-del-edit"
            }`}
          >
            <FontAwesomeIcon icon={faPen} />
          </Box>
        </BootstrapTooltip>
      ) : null}
      {!hideDeleteIcon ? (
        <BootstrapTooltip
          title={CheckValueLocale(deleteTooltip, "", {}, intl)}
          arrow
          placement="top"
          TransitionComponent={Zoom}
        >
          <Box
            onClick={(event) => {
              handleOpenDeleteModal(event);
            }}
            className={`delete-icon-wrapper ${
              isDeleteEditBtn && "disable-del-edit"
            }`}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="saved-result-delete-icon"
            />
          </Box>
        </BootstrapTooltip>
      ) : null}
    </Box>
  );
};

export default EditAndDeleteButtons;
