import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid, OutlinedInput, Box } from "@mui/material";
import { useIntl } from "react-intl";
import "../../../createMonitor.scss";
import { getCompanyApps } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import SocialMediaAccountButton from "../../../Components/SocialMediaAuthButton/socialMediaAuthButton";
import CustomerCareInput from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/customerCareInput";

const FacebookPublicAccount = (props) => {
  const intl = useIntl();
  const {
    monitorData,
    monitorErrors,
    setMonitorErrors,
    setMonitorData,
    handleCreateMonitorBtn,
    fbSourceId,
    facebookAccounts,
    isLoading,
  } = props;

  const [companyApps, setCompanyApps] = useState({});
  const [coustomerCareVal, setCoustomerCareVal] = useState([]);

  useEffect(() => {
    getCompanyApps(setCompanyApps);
    props.setActiveDataSourceId(fbSourceId);
  }, []);

  useEffect(() => {
    if (
      monitorErrors?.pageName ||
      !monitorData.pageName ||
      monitorErrors?.monitorName ||
      monitorErrors?.monitorDescription
    ) {
      handleCreateMonitorBtn("");
    } else {
      handleCreateMonitorBtn("fb_pub");
    }
  }, [
    monitorErrors?.pageName,
    monitorErrors?.monitorName,
    monitorErrors?.monitorDescription,
  ]);

  const handlePageName = (e) => {
    setMonitorErrors({ ...monitorErrors, pageName: "" });
    if (e.target.value.length > 50) {
      // FB max length is 50
      setMonitorErrors({
        ...monitorErrors,
        pageName: "fb_page_max_char",
      });
    } else if (
      !isPublicSpecialCharactersOrEmojis(
        e.target.value,
        undefined,
        undefined,
        true,
      )
    ) {
      handleCreateMonitorBtn(e.target.value);
    } else {
      setMonitorErrors({
        ...monitorErrors,
        pageName: "pageName_special_characters_error",
      });
    }

    setMonitorData({ ...monitorData, pageName: e.target.value });
  };
  useEffect(() => {
    setMonitorData({ ...monitorData, coustomerCareVal });
  }, [coustomerCareVal]);
  return (
    <Box>
      {!facebookAccounts || isLoading ? (
        <CircularLoading />
      ) : isEmptyArray(facebookAccounts) ? (
        <>
          <SocialMediaAccountButton
            authHead={"you_need_to_connect_facebook_account"}
            companyApps={companyApps}
            authDataSourceName={"add_facebook_account_profile"}
            dataSourceName={"facebook"}
            isPublicAccount
            isCompanyAccount={false}
            redirectUrl={
              window.location.origin +
              "/settings/accounts_management/users_accounts"
            }
          />
        </>
      ) : (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          className="social-media-fields"
        >
          <Grid item xs={6} className="social-media-field-grid">
            <Typography variant="caption" className="monitor-page-name">
              {CheckValueLocale("page_name", "", {}, intl)}
            </Typography>
            <OutlinedInput
              svariant="outlined"
              className="social-media-field"
              value={monitorData?.pageName || ""}
              onChange={(e) => {
                handlePageName(e);
              }}
            />
            {monitorErrors.pageName ? (
              <Box component="p" className="monitor-error-name">
                {CheckValueLocale(monitorErrors?.pageName, "", {}, intl)}
              </Box>
            ) : null}
          </Grid>
          <Grid
            item
            xs={6}
            className="social-media-field-grid keywords-inputs-fields"
          >
            <CustomerCareInput
              coustomerCareVal={coustomerCareVal}
              setCoustomerCareVal={setCoustomerCareVal}
              dataSourceName={"facebook"}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FacebookPublicAccount;
