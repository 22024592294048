import { Box, Button } from "@mui/material";
import "./mainEdit.scss";
import { useState, useEffect } from "react";
import { CheckValueLocale } from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinnerThird,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar.js";
import SmIcon from "images/engagements-images/SmIcon.svg";
import CxmIcon from "images/engagements-images/CxmIcon.svg";
import AiSection from "./aiSection";
import ReplySection from "./replySection";
import CompaniesController from "services/controllers/companiesController";
import MainSelsctCard from "./mainSelsctCard";
import MainEditFooter from "./mainEditFooter";

const MainEdit = () => {
  const intl = useIntl();
  const history = useHistory();
  const [isSetup, setIsSetup] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const [selectedData, setSelectedData] = useState({
    mainData: {
      accounts: [],
      feaching: true,
      monitors: [],
      channels: [],
      company_products: {},
    },
    stepOne: {
      isPublishError: false,
      isCxmAcc: false,
      isEmptyError: false,
      isAccError: false,
      monitors: [],
      TWITTER_SlAcc: [],
      INSTAGRAM_SlAcc: [],
      FACEBOOK_SlAcc: [],
      channels: [],
      TWITTER_CxmAcc: [],
      INSTAGRAM_CxmAcc: [],
      FACEBOOK_CxmAcc: [],
      TWITTER_PubAcc: [],
      INSTAGRAM_PubAcc: [],
      FACEBOOK_PubAcc: [],
    },
    stepTwo: {
      luci_industry: "",
      luci_business_type: "",
      luci_business_hq: "",
      brand_tone: "",
      aboutYour: "",
      knowledge_base_url: "",
      isFileChanged: false,
      files: [],
      isFileUploaded: false,
      fetching: false,
      enabled: false,
    },
  });
  const [initialselectedData, setInitialselectedData] = useState({
    changedNum: 0,
    stepOne: {
      monitors: [],
      TWITTER_SlAcc: [],
      INSTAGRAM_SlAcc: [],
      FACEBOOK_SlAcc: [],
      cxm: [],
      TWITTER_CxmAcc: [],
      INSTAGRAM_CxmAcc: [],
      FACEBOOK_CxmAcc: [],
      TWITTER_PubAcc: [],
      INSTAGRAM_PubAcc: [],
      FACEBOOK_PubAcc: [],
    },
    stepTwo: {
      luci_industry: "",
      luci_business_type: "",
      luci_business_hq: "",
      brand_tone: "",
      aboutYour: "",
      knowledge_base_url: "",
      fileName: "",
      fileSize: 0,
    },
  });
  const [slDemoDiasable, setSlDemoDiasable] = useState(false);
  const [cxmDemoDiasable, setCxmDemoDiasable] = useState(false);

  //start of check if user set setup before, if not , redirect to setup page
  useEffect(() => {
    let engagementsProduct = JSON.parse(
      window?.localStorage?.getItem("activeProducts"),
    );
    let engagementsProductId = engagementsProduct?.filter(
      (item) => item?.name == "ENGAGEMENTS" && item?.active,
    );
    if (!engagementsProductId?.length) {
      history.push("/social/monitor_list");
    } else {
      setSelectedData({
        ...selectedData,
        mainData: {
          ...selectedData?.mainData,
          feaching: true,
        },
      });
      EngagementsController.getSavedMonitors(
        window?.localStorage?.engagements_id,
      ).then((data) => {
        if (!data?.data?.engagements_setuped) {
          history.push("/engagements/setup");
        } else {
          setIsSetup(true);
        }
      });
    }
  }, []);
  //end of check if user set setup before, if not , redirect to setup page

  //start of get all data and handle it
  useEffect(() => {
    if (isSetup) {
      EngagementsController.getUserInfo({
        product_id: window?.localStorage?.engagements_id,
      }).then((data) => {
        if (data?.data?.data?.engagements_monitors) {
          let mainData = { ...data?.data?.data };
          let TWITTER_SlAcc = [],
            INSTAGRAM_SlAcc = [],
            FACEBOOK_SlAcc = [],
            TWITTER_CxmAcc = [],
            INSTAGRAM_CxmAcc = [],
            FACEBOOK_CxmAcc = [],
            TWITTER_PubAcc = [],
            INSTAGRAM_PubAcc = [],
            FACEBOOK_PubAcc = [],
            accountsArr = [];

          let monitors = [...mainData?.engagements_monitors?.monitors?.data];
          let channels = [...mainData?.engagements_monitors?.channels?.data];
          let monitorsSelected = monitors?.filter(
            (item) => item?.attributes?.is_engagement_monitor,
          );

          let channelsSelected = channels?.filter(
            (item) => item?.attributes?.is_engagement_monitor,
          );
          mainData?.engagements_accounts?.TWITTER?.data?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_sources: ["TWITTER"],
                data_source: "TWITTER",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["TWITTER"]
                    : [],
              },
            });
            if (item?.attributes?.engagements_products?.includes("SM")) {
              TWITTER_SlAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["TWITTER"],
                  data_source: "TWITTER",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["TWITTER"]
                      : [],
                },
              });
            }
            if (item?.attributes?.engagements_products?.includes("CXM")) {
              TWITTER_CxmAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["TWITTER"],
                  data_source: "TWITTER",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["TWITTER"]
                      : [],
                },
              });
            }
            if (item?.attributes?.can_publish) {
              TWITTER_PubAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["TWITTER"],
                  data_source: "TWITTER",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["TWITTER"]
                      : [],
                },
              });
            }
          });
          mainData?.engagements_accounts?.FACEBOOK?.included?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_sources: ["FACEBOOK"],
                data_source: "FACEBOOK",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["FACEBOOK"]
                    : [],
              },
            });
            if (item?.attributes?.engagements_products?.includes("SM")) {
              FACEBOOK_SlAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["FACEBOOK"],
                  data_source: "FACEBOOK",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["FACEBOOK"]
                      : [],
                },
              });
            }
            if (item?.attributes?.engagements_products?.includes("CXM")) {
              FACEBOOK_CxmAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["FACEBOOK"],
                  data_source: "FACEBOOK",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["FACEBOOK"]
                      : [],
                },
              });
            }
            if (item?.attributes?.can_publish) {
              FACEBOOK_PubAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["FACEBOOK"],
                  data_source: "FACEBOOK",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["FACEBOOK"]
                      : [],
                },
              });
            }
          });
          mainData?.engagements_accounts?.INSTAGRAM?.included?.map((item) => {
            accountsArr.push({
              ...item,
              attributes: {
                ...item?.attributes,
                data_sources: ["INSTAGRAM"],
                data_source: "INSTAGRAM",
                account_error:
                  item?.attributes?.status == "Re-authenticate"
                    ? ["INSTAGRAM"]
                    : [],
              },
            });
            if (item?.attributes?.engagements_products?.includes("SM")) {
              INSTAGRAM_SlAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["INSTAGRAM"],
                  data_source: "INSTAGRAM",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["INSTAGRAM"]
                      : [],
                },
              });
            }
            if (item?.attributes?.engagements_products?.includes("CXM")) {
              INSTAGRAM_CxmAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["INSTAGRAM"],
                  data_source: "INSTAGRAM",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["INSTAGRAM"]
                      : [],
                },
              });
            }
            if (item?.attributes?.can_publish) {
              INSTAGRAM_PubAcc.push({
                ...item,
                attributes: {
                  ...item?.attributes,
                  data_sources: ["INSTAGRAM"],
                  data_source: "INSTAGRAM",
                  account_error:
                    item?.attributes?.status == "Re-authenticate"
                      ? ["INSTAGRAM"]
                      : [],
                },
              });
            }
          });
          setInitialselectedData({
            changedNum: 0,
            stepOne: {
              monitors: monitorsSelected?.length
                ? monitorsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_SlAcc,
              INSTAGRAM_SlAcc,
              FACEBOOK_SlAcc,
              channels: channelsSelected?.length
                ? channelsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_CxmAcc,
              INSTAGRAM_CxmAcc,
              FACEBOOK_CxmAcc,
              TWITTER_PubAcc,
              INSTAGRAM_PubAcc,
              FACEBOOK_PubAcc,
            },
            stepTwo: {
              luci_industry: mainData?.ai_info?.data?.attributes?.industry,
              luci_business_type:
                mainData?.ai_info?.data?.attributes?.business_type,
              luci_business_hq:
                mainData?.ai_info?.data?.attributes?.business_location,
              brand_tone: mainData?.ai_info?.data?.attributes?.brand_voice,
              aboutYour:
                mainData?.ai_info?.data?.attributes?.ai_company_knowledge,
              knowledge_base_url:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.content,
              enabled: mainData?.ai_info?.data?.attributes?.should_learn,
              fileName:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_name,
              fileSize:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_size,
              enabled: mainData?.ai_info?.data?.attributes?.should_learn,
            },
          });
          setSelectedData({
            ...selectedData,
            mainData: {
              accounts: [...accountsArr],
              feaching: false,
              monitors: [...monitors],
              channels: [...channels],
              company_products: {
                ...mainData?.engagements_monitors?.company_products,
              },
            },
            stepOne: {
              ...selectedData?.stepOne,
              monitors: monitorsSelected?.length
                ? monitorsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_SlAcc,
              INSTAGRAM_SlAcc,
              FACEBOOK_SlAcc,
              channels: channelsSelected?.length
                ? channelsSelected?.map((item) => item?.attributes?.id)
                : [],
              TWITTER_CxmAcc,
              INSTAGRAM_CxmAcc,
              FACEBOOK_CxmAcc,
              TWITTER_PubAcc,
              INSTAGRAM_PubAcc,
              FACEBOOK_PubAcc,
            },
            stepTwo: {
              ...selectedData?.stepTwo,
              luci_industry: mainData?.ai_info?.data?.attributes?.industry,
              luci_business_type:
                mainData?.ai_info?.data?.attributes?.business_type,
              luci_business_hq:
                mainData?.ai_info?.data?.attributes?.business_location,
              brand_tone: mainData?.ai_info?.data?.attributes?.brand_voice,
              aboutYour:
                mainData?.ai_info?.data?.attributes?.ai_company_knowledge,
              knowledge_base_url:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.content,
              enabled: mainData?.ai_info?.data?.attributes?.should_learn,
              fileName:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_name,
              fileSize:
                mainData?.ai_info?.data?.attributes?.knowledge_base_file
                  ?.file_size,
              isFileChanged: false,
            },
          });
        }
      });
    }
  }, [isSetup]);
  //end of get all data and handle it

  //start of handle request demo this will run only if the user have not one of SM or CXM
  const requestDemo = (type) => {
    let queryData = {
      service_name: type,
    };
    if (type == "request_social_listening_demo") {
      setSlDemoDiasable(true);
    } else {
      setCxmDemoDiasable(true);
    }
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSnackBar({
          open: true,
          title: "request_access_success_heading",
          message: "demo_success",
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          title: "something_wrong",
          message: "wrong_msg",
          severity: "error",
        });
      }
      setSlDemoDiasable(false);
      setCxmDemoDiasable(false);
    });
    setTimeout(() => {
      setSnackBar({
        open: false,
        severity: "",
        title: "",
        message: "",
      });
    }, 7000);
  };
  //end of handle request demo this will run only if the user have not one of SM or CXM
  return (
    <Box className="main-edit-engagment">
      <Box className="main-edit-header">
        <Box>{CheckValueLocale("engag_setting", "", {}, intl)}</Box>
        <Box
          className="edit-header-btn"
          onClick={() => history.push("/engagements/main")}
        >
          {CheckValueLocale("back_main", "", {}, intl)}
          <FontAwesomeIcon
            icon={intl.locale == "en" ? faChevronRight : faChevronLeft}
          />
        </Box>
      </Box>

      {!selectedData?.mainData?.feaching ? (
        <Box>
          <Box className="main-edit-step">
            {CheckValueLocale("inbox_setting", "", {}, intl)}
            <Box className="main-step-countainer">
              <Box className="main-step-header">
                <img src={SmIcon} />
                {CheckValueLocale("SM", "", {}, intl)}
              </Box>
              {!selectedData?.mainData?.company_products?.SM ? (
                <Box className="req-demo">
                  {CheckValueLocale("sm_demo", "", {}, intl)}
                  <Button
                    onClick={() => requestDemo("request_social_listening_demo")}
                    disabled={slDemoDiasable}
                  >
                    {CheckValueLocale("request_demo", "", {}, intl)}
                  </Button>
                </Box>
              ) : (
                <Box>
                  <MainSelsctCard
                    type="monitors"
                    isAcc={false}
                    isborder={true}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectId="engagement-settings-monitors-select"
                  />
                  <MainSelsctCard
                    type="SlAcc"
                    isAcc={true}
                    isborder={false}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectId="engagement-settings-sl-acc-select"
                    companyAuthId="engagement-settings-sl-company-auth"
                  />
                </Box>
              )}
            </Box>
            <Box className="main-step-countainer">
              <Box className="main-step-header">
                <img src={CxmIcon} />
                {CheckValueLocale("CXM", "", {}, intl)}
              </Box>
              {!selectedData?.mainData?.company_products?.CXM ? (
                <Box className="req-demo">
                  {CheckValueLocale("cxm_demo", "", {}, intl)}
                  <Button
                    onClick={() =>
                      requestDemo("request_channel_analytics_demo")
                    }
                    disabled={cxmDemoDiasable}
                  >
                    {CheckValueLocale("request_demo", "", {}, intl)}
                  </Button>
                </Box>
              ) : (
                <Box>
                  <MainSelsctCard
                    type="channels"
                    isAcc={false}
                    isborder={selectedData?.stepOne?.isCxmAcc}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectId="engagement-settings-channels-select"
                  />
                  {selectedData?.stepOne?.isCxmAcc ? (
                    <MainSelsctCard
                      type="CxmAcc"
                      isAcc={true}
                      isborder={false}
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                      selectId="engagement-settings-cxm-acc-select"
                      companyAuthId="engagement-settings-cxm-company-auth"
                    />
                  ) : null}
                </Box>
              )}
            </Box>
          </Box>
          <Box className="main-edit-step">
            {CheckValueLocale("pub_setting", "", {}, intl)}
            <Box className="main-step-countainer">
              <MainSelsctCard
                type="PubAcc"
                isAcc={true}
                isborder={false}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                selectId="engagement-settings-public-acc-select"
                companyAuthId="engagement-settings-public-company-auth"
              />
            </Box>
          </Box>
          <Box className="main-edit-step">
            {CheckValueLocale("AI_Assistant", "", {}, intl)}
            <Box className="main-step-countainer">
              <AiSection
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            </Box>
          </Box>
          <Box className="main-edit-step">
            {CheckValueLocale("saved_replies", "", {}, intl)}
            <Box className="main-step-countainer">
              <ReplySection
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            </Box>
          </Box>
          <MainEditFooter
            setSnackBar={setSnackBar}
            initialselectedData={initialselectedData}
            setInitialselectedData={setInitialselectedData}
            selectedData={selectedData}
          />
        </Box>
      ) : (
        <Box className="fetching-loader">
          <FontAwesomeIcon icon={faSpinnerThird} spin />
          {CheckValueLocale("setup_load", "", {}, intl)}
        </Box>
      )}
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        handleClose={() => {
          setSnackBar({
            open: false,
            title: "",
            message: "",
            severity: "",
          });
        }}
      />
    </Box>
  );
};

export default MainEdit;
