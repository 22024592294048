import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { Box } from "@mui/material";
import SnackBar from "components/snackBar";
import LucButton from "shared/lucButton/lucButton";
import NoDataFoundProducts from "components/no-Data";
import CircularLoading from "components/circularProgress";
import CustomDashboardFunctions from "./customDashboardFunctions";
import CustomDashboardHeader from "../components/customDashboardHeader";
import WidgetsContainer from "./components/widgetsContainer";
import "./style.scss";

const CustomDashboardView = () => {
  const params = useParams();
  const history = useHistory();
  const intl = useIntl();
  const {
    isLoading,
    dashboardInfo,
    startDate,
    endDate,
    applyFilter,
    dashboardWidgets,
    socialWidgets,
    isLiveData,
    handleChangeLiveData,
    snackBarData,
    setSnackBarData,
    disabledLive,
  } = CustomDashboardFunctions(params);

  let slProduct = +localStorage?.getItem("sm_id");

  let enabledEdit =
    slProduct == dashboardInfo?.product_id
      ? handlelUserRoles("SM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("SM", "EDIT_DASHBOARD")
      : handlelUserRoles("CXM", "DESTROY_DASHBOARD")
        ? true
        : dashboardInfo?.dashboard_owner &&
          handlelUserRoles("CXM", "EDIT_DASHBOARD");

  useEffect(() => {
    if (history?.location?.successEdit) {
      setSnackBarData({
        open: true,
        severity: "success",
        title: "",
        message: "custom_dashboard_updated_successfully_msg",
      });
    }
  }, [history?.location]);

  return (
    <Box>
      <CustomDashboardHeader
        isLoading={false}
        dashboardInfo={dashboardInfo}
        applyFilter={applyFilter}
        startDate={startDate}
        endDate={endDate}
        openCustomDateLeft={true}
        isLiveData={isLiveData}
        showLiveButton={dashboardWidgets?.length}
        disabledLive={disabledLive}
        handleChangeLiveData={handleChangeLiveData}
      />
      {isLoading ? (
        <CircularLoading />
      ) : !dashboardWidgets?.length ? (
        <Box id="empty-widgets-dashboard">
          <NoDataFoundProducts
            image="customDashboard"
            title="no_widgets_added"
            description="adding_widgets_dashboard"
          />
          <LucButton
            id="empty-custom-dashboard-add-widgets-btn"
            disabled={!enabledEdit}
            onClick={() =>
              history?.push({
                pathname: `/dashboard/custom/edit/${dashboardInfo?.id}`,
              })
            }
          >
            {CheckValueLocale("custom_dashboard_add_widgets_btn", "", {}, intl)}
          </LucButton>
        </Box>
      ) : (
        <WidgetsContainer
          dashboardInfo={dashboardInfo}
          dashboardWidgets={dashboardWidgets}
          socialWidgets={socialWidgets}
        />
      )}
      <SnackBar
        open={snackBarData?.open}
        severity={snackBarData?.severity}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
        handleClose={() => setSnackBarData({})}
        alertStyle={{ maxWidth: "372px" }}
      />
    </Box>
  );
};

export default CustomDashboardView;
