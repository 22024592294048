import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import { CheckValueLocale, classNames, handlelUserRoles } from "utils/helpers";
import "./navigationContent.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PublishIcon from "images/engagements-images/publish-icon.svg";
import CommentIcon from "@mui/icons-material/Comment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";

const SwitchMenuList = ({
  openNavigationMenu,
  setOpenNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  className,
}) => {
  const history = useHistory();

  const showPublish =
    handlelUserRoles("ENGAGEMENT_ACCESS", "PUBLISH") &&
    navigationMenuStatus !== "publish";
  const showInbox =
    handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
    navigationMenuStatus !== "inbox";
  const showAnalytics =
    handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX") &&
    navigationMenuStatus !== "analytics";

  const intl = useIntl();
  const handleClickNavigation = (path) => {
    setNavigationMenuStatus(path);
    history.push(`/engagements/main/${path}`);
    setOpenNavigationMenu(false);
  };
  return openNavigationMenu ? (
    <ClickAwayListener onClickAway={() => setOpenNavigationMenu(false)}>
      <Box className={classNames("switch-navigation-container", className)}>
        <Box className="switch-navigation-content">
          <Box className="switch-navigation-inbox">
            <Typography component={"p"} className="switch-to-engagement">
              {CheckValueLocale("switch_to_engagement", "", {}, intl)}
            </Typography>
            {
              <>
                {showInbox ? (
                  <SwitchMenuItem
                    navigationMenuStatus="inbox"
                    handleClickNavigation={handleClickNavigation}
                  />
                ) : null}
                {showPublish ? (
                  <SwitchMenuItem
                    navigationMenuStatus="publish"
                    handleClickNavigation={handleClickNavigation}
                  />
                ) : null}
              </>
            }
            {showAnalytics ? (
              <>
                {showPublish ? (
                  <Box className="switch-navigation-divider" />
                ) : null}
                <MenuLinkItem
                  id="engagements-analytics-link-btn"
                  to="/engagements/analytics/inbox"
                  icon={<FontAwesomeIcon icon={faAnalytics} />}
                  title={CheckValueLocale("analytics_engagement", "", {}, intl)}
                />
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  ) : null;
};

const SwitchMenuItem = ({ navigationMenuStatus, handleClickNavigation }) => {
  const intl = useIntl();
  return (
    <>
      <Box
        id={
          navigationMenuStatus === "inbox"
            ? "engagements-publish-btn"
            : "engagements-inbox-btn"
        }
        className="navigation-content-types"
        onClick={() => handleClickNavigation(navigationMenuStatus)}
      >
        <Box className="navigation-content-types-main">
          <Box className="publish-icon-container">
            {navigationMenuStatus === "publish" ? (
              <img src={PublishIcon} alt="publish-icon" />
            ) : (
              <CommentIcon className="comment-icon-engagement comment-icon-inbox" />
            )}
          </Box>
          <Box className="switch-type-wrapper">
            <Typography component={"p"} className="switch-type-enagement">
              {CheckValueLocale(
                `${navigationMenuStatus}_engagement`,
                "",
                {},
                intl,
              )}
            </Typography>
            <Typography component={"p"} className="switch-desc-enagement">
              {CheckValueLocale(
                `${navigationMenuStatus}_describtion`,
                "",
                {},
                intl,
              )}
            </Typography>
          </Box>
          <Box className="arrow-icon-navigate-wrapper">
            <Box className="arrow-icon-navigate-wrapper-icon">
              <FontAwesomeIcon
                icon={faArrowRight}
                className={`arrow-icon-navigate arrow-icon-navigate-${navigationMenuStatus}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const MenuLinkItem = ({ id, to, icon, title }) => {
  return (
    <Link id={id} to={to}>
      <Box className="switch-navigation-inbox-dm">
        <Box className="switch-navigation-inbox-dm-content">
          <Box className="switch-navigation-inbox-dm-content-icon">{icon}</Box>
          <Typography className="switch-navigation-inbox-dm-content-text">
            {title}
          </Typography>
        </Box>
        <Box className="switch-navigation-inbox-dm-arrow">
          <FontAwesomeIcon icon={faArrowRight} />
        </Box>
      </Box>
    </Link>
  );
};

export default SwitchMenuList;
