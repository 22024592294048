import { useEffect, useRef, useState } from "react";
import { isEmptyObj } from "utils/helpers";
import { useParams } from "react-router-dom";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import MainAlertView from "./alertTypesView/mainAlertView.js";
import AlertController from "services/controllers/alertController";
import { useIntl } from "react-intl";
import DeletedAlertView from "./alertTypesView/components/deletedAlertView/deletedAlertView.js";
import NotFoundNotification from "./notFound/notFoundNotification.js";
import { getStaticAlertsData } from "./alertTypesView/staticAlertsData.js";

const AlertDetailPage = () => {
  const intl = useIntl();

  const urlParams = useParams();
  const alertId = urlParams?.id; // to get [ alert ID ] from URL
  const [alertDetailType, setAlertDetailType] = useState("");
  const [alertDetailDataSource, setAlertDetailDataSource] = useState("");
  const [alertDetailProductType, setSlertDetailProductType] = useState("");
  const [alertMonitorType, setAlertMonitorType] = useState("");
  const [alertDetailMonitorName, setAlertDetailMonitorName] = useState("");
  const [alertDetailMonitorId, setAlertDetailMonitorId] = useState([]);
  const [alertName, setAlertName] = useState("");
  const [alertCreatedAt, setAlertCreatedAt] = useState("");
  const [response, setResponse] = useState([]);
  const [resetAllFields, setResetAllFields] = useState(0);
  const [monitorDeleted, setMonitorDeleted] = useState(0);
  const [surveyPreloaderDatasource, setSurveyPreloaderDatasource] = useState(
    [],
  );
  const [page, setPage] = useState(1);
  const [deletedAlert, setDeletedAlert] = useState(false);

  //RabbitMQ configration
  const clientMQTT = useRef();
  const mqttConnectionClosed = useRef(false);
  const [alertsDataWidgets, setAlertDataWidgets] = useState([]);
  useEffect(() => {
    AlertController.getAlertDetail(alertId, page).then((data) => {
      setResponse([]);
      if (data?.data?.monitor_is_deleted) {
        setMonitorDeleted(data?.data?.monitor_is_deleted);
      } else {
        if (
          !isEmptyObj(data?.data) &&
          (data?.status_code === 201 || data?.status_code === 200)
        ) {
          setResetAllFields((prev) => prev + 1);
          setAlertDetailType(data?.data?.alert_type);
          setAlertDetailDataSource(data?.data?.data_source_name?.toLowerCase());
          setSlertDetailProductType(data?.data?.product_name?.toLowerCase());
          setAlertDetailMonitorName(data?.data?.monitor_name);
          setAlertName(data?.data?.alert_name);
          setAlertCreatedAt(data?.data?.alert_created_at);
          setAlertDetailMonitorId([data?.data?.monitor_id]);
          setAlertMonitorType(data?.data?.monitor_type);
          setSurveyPreloaderDatasource([
            data?.data?.data_source_name?.toLowerCase(),
          ]);
          let newAlertVal = `${data?.data?.data_source_name?.toLowerCase()}_${data?.data?.monitor_type?.toLowerCase()}`;
          setAlertDataWidgets(getStaticAlertsData[newAlertVal]);
          connectWithRabbitMQ(
            mqttConnectionClosed,
            clientMQTT,
            data?.data,
            setResponse,
          );
          setDeletedAlert(false);
        } else if (
          data?.errorMsg?.response?.status === 400 ||
          data?.errorMsg?.response?.status === 401 ||
          data?.errorMsg?.response?.status === 404
        )
          setDeletedAlert(true);
        else {
          mqttConnectionClosed.current = true;
        }
      }
    });
  }, [intl.locale, page]);

  const alertByType = (alertDetailType) => {
    switch (alertDetailType) {
      case "NewPost":
      case "Trending":
      case "VerifiedAuthor":
      case "NegativePosts":
      case "HighVolumeReach":
      case "ViralTweet":
      case "Influencer":
        return (
          <MainAlertView
            response={response}
            alertDetailType={alertDetailType}
            alertDetailDataSource={alertDetailDataSource}
            alertDetailMonitorId={alertDetailMonitorId}
            alertName={alertName}
            alertCreatedAt={alertCreatedAt}
            alertDetailMonitorName={alertDetailMonitorName}
            resetAllFields={resetAllFields}
            alertMonitorType={alertMonitorType}
            alertDetailProductType={alertDetailProductType}
            surveyPreloaderDatasource={surveyPreloaderDatasource}
            page={page}
            setPage={setPage}
            alertsDataWidgets={alertsDataWidgets}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {monitorDeleted ? (
        <NotFoundNotification />
      ) : deletedAlert ? (
        <DeletedAlertView />
      ) : (
        !isEmptyObj(response) && alertByType(alertDetailType)
      )}
    </>
  );
};
export default AlertDetailPage;
