import React from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import { arrayMoveImmutable } from "array-move";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import addWindgetsImg from "images/custom-dashboard/add-widgets.svg";
import ContentWidgetCard from "./components/contentWidgetCard";
import CircularLoading from "components/circularProgress";

const CustomDashboardContent = (props) => {
  const intl = useIntl();
  const {
    isContentLoading,
    dashboardInfo,
    contentWidgets,
    setContentWidgets,
    handleDeleteWidget,
    setChangesFlag,
    handleWidgetImage,
  } = props;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex != newIndex) {
      const reOrderedWidgets = arrayMoveImmutable(
        contentWidgets,
        oldIndex,
        newIndex,
      )?.map((widget, i) => ({ ...widget, order: i + 1 }));
      setContentWidgets(reOrderedWidgets);
      setChangesFlag(true);
    }
  };

  const DragHandle = SortableHandle(() => (
    <Tooltip
      arrow
      placement="top"
      title={CheckValueLocale("move", "", {}, intl)}
    >
      <Box
        component={"span"}
        id="custom-dashboard-move-widget-btn"
        className="move-icon"
      >
        <FontAwesomeIcon icon={faBars} fontSize={"20px"} />
      </Box>
    </Tooltip>
  ));

  const Container = SortableContainer(({ children }) => {
    return (
      <Grid container spacing={2} className="custom-dashboard-content-box">
        {children}
      </Grid>
    );
  });

  const Element = SortableElement(({ widgetIndex, widget }) => {
    return (
      <ContentWidgetCard
        index={widgetIndex}
        dashboardInfo={dashboardInfo}
        widget={widget}
        handleDeleteWidget={handleDeleteWidget}
        DragHandle={DragHandle}
        handleWidgetImage={handleWidgetImage}
      />
    );
  });

  return (
    <Box
      className={`editor-content ${!contentWidgets?.length && !isContentLoading ? "empty" : ""}`}
    >
      {isContentLoading ? (
        <CircularLoading />
      ) : !contentWidgets?.length ? (
        <Box className="empty-widgets">
          <img src={addWindgetsImg} alt="" />
          <Typography className="title">
            {CheckValueLocale("custom_dashboard_add_widgets", "", {}, intl)}
          </Typography>
          <Typography className="desc">
            {CheckValueLocale(
              "custom_dashboard_add_widgets_msg",
              "",
              { br: <br /> },
              intl,
            )}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography className="widgets-content-top-header">
            {CheckValueLocale(
              "custom_dashboard_you_can_add_up_to",
              "",
              {
                maxNum: dashboardInfo?.max_widgets,
                selectedNum: contentWidgets?.length,
              },
              intl,
            )}
          </Typography>
          <Container
            axis={"xy"}
            onSortEnd={onSortEnd}
            useDragHandle
            useWindowAsScrollContainer={false}
          >
            {contentWidgets?.map((widget, index) => {
              return (
                <Element
                  key={index}
                  index={index}
                  widgetIndex={index}
                  widget={widget}
                />
              );
            })}
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default CustomDashboardContent;
