import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  //   .MuiContainer-root {
  //     padding-left: 100px;
  //     padding-right: 100px;
  // }
  typography: {
    fontFamily: "Graphik",
    subtitle1: {
      fontSize: 18,
      fontWeight: 500,
      color: "#64748B",
    },
    h5: {
      fontWeight: 600,
      fontSize: "28px",
    },
    h6: {
      fontWeight: 600,
      color: "#000",
    },
  },
  palette: {
    primary: {
      main: "#456EB2 ",
    },
    secondary: {
      main: "#80868C",
    },
    success: {
      main: "#4CAF50",
    },
    warning: {
      main: "#ED6C02",
    },
    error: {
      main: "#F44336",
    },
  },
  components: {
    // Buttons
    MuiButton: {
      styleOverrides: {
        sizeMedium: {
          paddingRight: "22px",
          paddingLeft: "22px",
          boxShadow: "none",
          fontWeight: "500",
          fontSize: "15px",
        },
        contained: {
          color: "white",
        },
        containedPrimary: {
          "&:hover": {
            background: "#2497CA",
          },
        },
        containedSecondary: {
          "&:hover": {
            background: "#80868C",
          },
        },
        // disabled: {
        //   background: "#E2E8F0",
        //   color: "#B9C2CC",
        // },
        outlined: {
          fontWeight: "500",
          fontSize: "15px",
        },
        outlinedPrimary: {
          "&:hover": {
            background: "rgb(42 170 226 / 10%)",
          },
        },
        outlinedSecondary: {
          color: "rgba(128, 134, 140, 1)",
          "&:hover": {
            background: "rgba(240, 240, 240, 1)",
          },
        },
        textSecondary: {
          color: "#80868C",
          "&:hover": {
            background: "#80868C14",
          },
        },
      },
    },

    // Tables
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: "1.5px solid #CBD5E1",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          padding: "0 16px",
          // color: "rgba(12, 25, 42, 0.71)",
          fontSize: "14px",
        },
        head: {
          fontSize: "12px",
          fontWeight: "500",
          color: "#64748B",
          textTransform: "uppercase",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: 50,
        },
        head: {
          height: 58,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& > :nth-of-type(odd)": {
            background: "#F1F5F9",
          },
          "& > :nth-of-type(even)": {
            background: "#FFFFFF",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "100px !important",
          paddingRight: "100px !important",
        },
      },
    },
  },
});

export default theme;
