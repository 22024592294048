import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faCheckCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { faCopy, faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import "./customDomainInfo.scss";
import DeleteCustomDomainModal from "../deleteCustomDomain/deleteCustomDomainModal";
import CustomizedTooltip from "components/customizedTooltip";

const CustomDomainInfo = ({
  customDomain,
  setCustomDomain,
  setCustomDomainName,
  setCustomDomainError,
}) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const [openDeleteSurveyModal, setOpenDeleteSurveyModal] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(customDomain);
  };

  const handleDeleteCustomDomain = () => {
    setOpenDeleteSurveyModal(true);
  };

  return (
    <>
      <Box className="custom-domain-info">
        <Box className="custom-copy-name">
          <FontAwesomeIcon icon={faGlobe} className="custom-globle-icon" />
          {customDomain}
          <CustomizedTooltip
            id={copied ? "custom-domain-copied-tooltip" : ""}
            title={
              <Box className="item-title-tooltip">
                {CheckValueLocale(
                  copied ? "linked_copy" : "copy",
                  "",
                  {},
                  intl,
                )}
              </Box>
            }
            placement="bottom"
            arrow
          >
            <Box
              component="span"
              onClick={handleCopy}
              className="custom-domain-copy custom-domain-copy-container"
            >
              <FontAwesomeIcon
                className={copied && "domain-copied-icon"}
                icon={faCopy}
              />
            </Box>
          </CustomizedTooltip>
        </Box>

        <Box className="custom-connected-delete">
          <Box className="custom-connected">
            <FontAwesomeIcon icon={faCheckCircle} />
            {CheckValueLocale("custom_domain_connected", "", {}, intl)}
          </Box>
          <CustomizedTooltip
            title={
              <Box className="item-title-tooltip">
                {CheckValueLocale("delete", "", {}, intl)}
              </Box>
            }
            arrow
            placement="bottom"
          >
            <Button
              id="delete-custom-domain"
              onClick={handleDeleteCustomDomain}
            >
              <FontAwesomeIcon
                className={`delete-survey-icon ${openDeleteSurveyModal && "delete-survey-icon-active"}`}
                icon={faTrashCan}
              />
            </Button>
          </CustomizedTooltip>
        </Box>
      </Box>
      {openDeleteSurveyModal && (
        <DeleteCustomDomainModal
          openDeleteSurveyModal={openDeleteSurveyModal}
          setOpenDeleteSurveyModal={setOpenDeleteSurveyModal}
          setCustomDomain={setCustomDomain}
          setCustomDomainName={setCustomDomainName}
          setCustomDomainError={setCustomDomainError}
        />
      )}
    </>
  );
};

export default CustomDomainInfo;
