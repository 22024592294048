export const customDashboardSmTooltips = {
  // --------------- Twitter Keyword ---------------
  sm_twitter_keyword_posts_volume: "posts_volume_public_ig_tooltip",
  sm_twitter_keyword_posts_interactions: "posts_interactions_tw_tooltip",
  sm_twitter_keyword_posts_sentiment_analysis:
    "posts_sentiment_analysis_tw_tooltip",
  sm_twitter_keyword_reach_funnel: "reach_funnel_tw_tooltip",
  sm_twitter_keyword_posts_content_style: "comments_content_style_tw_tooltip",
  sm_twitter_keyword_posts_associated_topics:
    "posts_associated_topics_ig_tooltip",
  sm_twitter_keyword_posts_top_keywords: "posts_top_keywords_tw_tooltip",
  sm_twitter_keyword_posts_top_hashtags: "posts_top_hashtags_tw_tooltip",
  sm_twitter_keyword_top_images: "posts_top_images_tooltip",
  sm_twitter_keyword_top_videos: "top_videos_tooltip_managed_dash",
  sm_twitter_keyword_top_languages: "top_languages_fb_tooltip",
  sm_twitter_keyword_dialects_subdialects: "dialects_subdialects_ig_tooltip",
  sm_twitter_keyword_top_countries: "top_countries_ig_tooltip",
  sm_twitter_keyword_top_cities: "top_cities_tw_tooltip",
  sm_twitter_keyword_gender_distribution: "gender_distribution_tw_tooltip",
  sm_twitter_keyword_account_types: "account_types_tw_tooltip",
  sm_twitter_keyword_top_engagers: "top_engagers_tw_tooltip",
  sm_twitter_keyword_top_sources: "top_sources_tw_tooltip",
  sm_twitter_keyword_top_verified_engagers: "top_verified_engagers_tw_tooltip",
  sm_twitter_keyword_top_influencers: "top_infleuncers_tw_tooltip",
  // --------------- Twitter Public Account ---------------
  sm_twitter_account_analysis_followers_growth: "followers_growth_tw_tooltip",
  sm_twitter_account_analysis_account_authors_activity:
    "account_authors_activity_tw_tooltip",
  sm_twitter_account_analysis_posts_volume: "posts_volume_public_ig_tooltip",
  sm_twitter_account_analysis_posts_interactions:
    "posts_interactions_tw_tooltip",
  sm_twitter_account_analysis_reach_funnel: "reach_funnel_tw_tooltip",
  sm_twitter_account_analysis_posts_content_type:
    "posts_content_type_tw_tooltip",
  sm_twitter_account_analysis_account_analysis_top_images:
    "top_images_tw_tooltip",
  sm_twitter_account_analysis_account_analysis_top_videos:
    "top_videos_tw_tooltip",
  sm_twitter_account_analysis_posts_volume: "posts_volume_public_ig_tooltip",
  sm_twitter_account_analysis_comments_interactions:
    "comments_interactions_tw_tooltip",
  sm_twitter_account_analysis_comments_sentiment_analysis:
    "comments_sentiment_analysis_tooltip",
  sm_twitter_account_analysis_comments_content_style:
    "comments_content_style_tw_tooltip",
  sm_twitter_account_analysis_comments_associated_topics:
    "comments_associated_topics_tooltip",
  sm_twitter_account_analysis_comments_top_keywords:
    "comments_top_keywords_tooltip",
  sm_twitter_account_analysis_comments_top_hashtags:
    "comments_top_hashtags_tooltip",
  sm_twitter_account_analysis_engagements_analysis_top_images:
    "comments_top_images_tw_tooltip",
  sm_twitter_account_analysis_engagements_analysis_top_videos:
    "comments_top_videos_tw_tooltip",
  sm_twitter_account_analysis_top_languages: "top_languages_fb_tooltip",
  sm_twitter_account_analysis_dialects_subdialects:
    "dialects_subdialects_tooltip",
  sm_twitter_account_analysis_top_countries: "top_countries_tw_tooltip",
  sm_twitter_account_analysis_top_cities: "top_cities_tw_tooltip",
  sm_twitter_account_analysis_gender_distribution:
    "gender_distribution_tw_tooltip",
  sm_twitter_account_analysis_account_types: "account_types_tw_tooltip",
  sm_twitter_account_analysis_top_engagers: "top_engagers_tw_tooltip",
  sm_twitter_account_analysis_top_sources: "top_sources_tw_tooltip",
  sm_twitter_account_analysis_top_verified_engagers:
    "top_verified_engagers_tw_tooltip",
  sm_twitter_account_analysis_top_influencers: "top_infleuncers_tw_tooltip",
  sm_twitter_account_analysis_customer_care_performance:
    "customer_performance_ig_tooltip",
  // --------------- Instagram Public Account ---------------
  sm_instagram_account_analysis_followers_growth: "followers_growth_ig_tooltip",
  sm_instagram_account_analysis_posts_content_type:
    "posts_content_type_fb_tooltip",
  sm_instagram_account_analysis_posts_volume: "posts_volume_public_ig_tooltip",
  sm_instagram_account_analysis_posts_top_keywords:
    "posts_top_keywords_ig_tooltip",
  sm_instagram_account_analysis_posts_top_hashtags:
    "posts_top_hashtags_ig_tooltip",
  sm_instagram_account_analysis_top_images: "posts_top_images_tooltip",
  sm_instagram_account_analysis_top_reels: "posts_top_reels_tooltip",
  sm_instagram_account_analysis_posts_interactions:
    "posts_interactions_ig_tooltip",
  // --------------- Instagram Keyword ---------------
  sm_instagram_keyword_posts_volume: "posts_volume_fb_tooltip",
  sm_instagram_keyword_posts_interactions: "posts_interactions_ig_tooltip",
  sm_instagram_keyword_posts_sentiment_analysis:
    "comments_sentiment_analysis_nb_tooltip",
  sm_instagram_keyword_posts_content_type: "posts_content_type_tw_tooltip",
  sm_instagram_keyword_posts_associated_topics:
    "posts_associated_topics_ig_tooltip",
  sm_instagram_keyword_posts_top_keywords: "posts_top_keywords_fb_tooltip",
  sm_instagram_keyword_posts_top_hashtags: "posts_top_hashtags_ig_tooltip",
  sm_instagram_keyword_top_images: "top_images_ig_tooltip",
  sm_instagram_keyword_top_reels: "posts_top_reels_tooltip",
  sm_instagram_keyword_dialects_subdialects: "dialects_subdialects_ig_tooltip",
  // --------------- Instagram Managed Account ---------------
  sm_instagram_managed_account_followers_growth: "followers_growth_ig_tooltip",
  sm_instagram_managed_account_page_contact_details_interactions:
    "page_contact_details_interactions_ig_tooltip",
  sm_instagram_managed_account_account_authors_activity:
    "account_authors_activity_ig_tooltip",
  sm_instagram_managed_account_posts_volume: "posts_volume_ig_tooltip",
  sm_instagram_managed_account_posts_reach_impressions:
    "posts_reach_impressions_ig_tooltip",
  sm_instagram_managed_account_posts_interactions:
    "posts_interactions_ig_tooltip",
  sm_instagram_managed_account_average_engagements_per_post:
    "average_engagements_per_post_ig_tooltip",
  sm_instagram_managed_account_posts_content_type:
    "posts_content_type_ig_tooltip",
  sm_instagram_managed_account_top_images: "posts_top_images_tooltip",
  sm_instagram_managed_account_top_reels: "posts_top_reels_tooltip",
  sm_instagram_managed_account_comments_volume: "comments_volume_ig_tooltip",
  sm_instagram_managed_account_comments_sentiment_analysis:
    "comments_sentiment_analysis_tooltip",
  sm_instagram_managed_account_comments_interactions:
    "comments_interactions_ig_tooltip",
  sm_instagram_managed_account_comments_top_keywords:
    "comments_top_keywords_tooltip",
  sm_instagram_managed_account_comments_top_hashtags:
    "comments_top_hashtags_ig_tooltip",
  sm_instagram_managed_account_comments_associated_topics:
    "comments_associated_topics_tooltip",
  sm_instagram_managed_account_gender_age_distribution:
    "gender_age_dist_ig_tooltip",
  sm_instagram_managed_account_dialects_subdialects:
    "dialects_subdialects_tooltip",
  sm_instagram_managed_account_top_authors: "top_authors_ig_tooltip",
  sm_instagram_managed_account_top_cities: "top_cities_ig_tooltip",
  sm_instagram_managed_account_top_countries: "top_countries_ig_tooltip",
  sm_instagram_managed_account_customer_care_performance:
    "customer_performance_ig_tooltip",
  // --------------- Facebook Managed Page ---------------
  sm_facebook_managed_page_page_views: "page_views_fb_tooltip",
  sm_facebook_managed_page_page_likes_unlikes: "page_like_unlikes_fb_tooltip",
  sm_facebook_managed_page_page_reach_impressions:
    "page_reach_impressions_fb_tooltip",
  sm_facebook_managed_page_page_contact_details_interactions:
    "page_contact_details_interactions_fb_tooltip",
  sm_facebook_managed_page_page_actions: "page_actions_fb_tooltip",
  sm_facebook_managed_page_account_authors_activity:
    "account_authors_activity_fb_tooltip",
  sm_facebook_managed_page_posts_volume: "posts_volume_fb_tooltip",
  sm_facebook_managed_page_posts_reach_impressions:
    "posts_reach_impressions_fb_tooltip",
  sm_facebook_managed_page_posts_interactions: "posts_interactions_fb_tooltip",
  sm_facebook_managed_page_posts_reactions: "reactions_fb_tooltip",
  sm_facebook_managed_page_posts_content_type: "posts_content_type_fb_tooltip",
  sm_facebook_managed_page_videos_views: "videos_views_fb_tooltip",
  sm_facebook_managed_page_posts_top_keywords: "posts_top_keywords_fb_tooltip",
  sm_facebook_managed_page_posts_top_hashtags: "posts_top_hashtags_fb_tooltip",
  sm_facebook_managed_page_top_images: "posts_top_images_tooltip",
  sm_facebook_managed_page_top_videos: "posts_top_videos_tooltip",
  sm_facebook_managed_page_comments_volume: "comments_volume_fb_tooltip",
  sm_facebook_managed_page_comments_reactions: "comments_reactions_fb_tooltip",
  sm_facebook_managed_page_comments_sentiment_analysis:
    "comments_sentiment_analysis_tooltip",
  sm_facebook_managed_page_comments_interactions:
    "comments_interactions_fb_tooltip",
  sm_facebook_managed_page_comments_top_keywords:
    "comments_top_keywords_tooltip",
  sm_facebook_managed_page_comments_top_hashtags:
    "comments_top_hashtags_tooltip",
  sm_facebook_managed_page_comments_associated_topics:
    "comments_associated_topics_tooltip",
  sm_facebook_managed_page_engagers_volume: "engagers_volume_fb_tooltip",
  sm_facebook_managed_page_gender_age_distribution:
    "gender_distribution_fb_tooltip",
  sm_facebook_managed_page_top_languages: "top_languages_fb_tooltip",
  sm_facebook_managed_page_dialects_subdialects: "dialects_subdialects_tooltip",
  sm_facebook_managed_page_top_countries: "top_countries_fb_tooltip",
  sm_facebook_managed_page_top_cities: "top_cities_fb_tooltip",
  sm_facebook_managed_page_engagers_activity: "engagers_activity_fb_tooltip",
  sm_facebook_managed_page_customer_care_performance:
    "customer_performance_fb_tooltip",
  // --------------- Facebook Public Account ---------------
  sm_facebook_account_analysis_account_authors_activity:
    "account_authors_activity_fb_tooltip",
  sm_facebook_account_analysis_posts_volume: "posts_volume_fb_tooltip",
  sm_facebook_account_analysis_posts_interactions:
    "posts_interactions_fb_tooltip",
  sm_facebook_account_analysis_posts_reactions: "posts_reactions_fb_tooltip",
  sm_facebook_account_analysis_posts_top_keywords:
    "posts_top_keywords_fb_tooltip",
  sm_facebook_account_analysis_posts_top_hashtags:
    "posts_top_hashtags_fb_tooltip",
  sm_facebook_account_analysis_comments_volume: "comments_volume_ig_tooltip",
  sm_facebook_account_analysis_comments_sentiment_analysis:
    "comments_sentiment_analysis_tooltip",
  sm_facebook_account_analysis_comments_top_keywords:
    "comments_top_keywords_tooltip",
  sm_facebook_account_analysis_comments_top_hashtags:
    "comments_top_hashtags_tooltip",
  sm_facebook_account_analysis_engagers_activity:
    "engagers_activity__account_fb_tooltip",
  sm_facebook_account_analysis_dialects_subdialects:
    "dialects_subdialects_tooltip",
  sm_facebook_account_analysis_customer_care_performance:
    "customer_performance_fb_tooltip",
  // --------------- Facebook Keyword ---------------
  sm_facebook_keyword_posts_volume: "posts_volume_fb_tooltip",
  sm_facebook_keyword_posts_interactions: "posts_interactions_fb_tooltip",
  sm_facebook_keyword_posts_sentiment_analysis:
    "comments_sentiment_analysis_fb_tooltip",
  sm_facebook_keyword_posts_reactions: "reactions_fb_tooltip",
  sm_facebook_keyword_posts_associated_topics:
    "posts_associated_topics_fb_tooltip",
  sm_facebook_keyword_posts_top_keywords: "posts_top_keywords_fb_tooltip",
  sm_facebook_keyword_posts_top_hashtags: "posts_top_hashtags_ig_tooltip",
  sm_facebook_keyword_dialects_subdialects: "dialects_subdialects_ig_tooltip",
  // --------------- NewsBlogs ---------------
  sm_talkwalker_keyword_top_languages: "top_languages_nb_tooltip",
  sm_talkwalker_keyword_top_countries: "top_countries_nb_tooltip",
  sm_talkwalker_keyword_top_sites: "top_sites_nb_tooltip",
  sm_talkwalker_keyword_top_engaged_sites: "top_engaged_sites_nb_tooltip",
  sm_talkwalker_keyword_posts_reach: "posts_reach_nb_tooltip",
  sm_talkwalker_keyword_posts_sentiment_analysis:
    "comments_sentiment_analysis_nb_tooltip",
  sm_talkwalker_keyword_posts_volume: "posts_volume_fb_tooltip",
};
