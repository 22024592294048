import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import InfoIcon from "@mui/icons-material/Info";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";

const CustomerCareChannel = ({ coustomerCareVal, setCoustomerCareVal }) => {
  const [errorMsg, setErrorMsg] = useState(false);
  const intl = useIntl();

  const handleInputChange = (event) => {
    let val = event.target.value;
    if (isPublicSpecialCharactersOrEmojis(val)) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
    setCoustomerCareVal([val]);
  };
  return (
    <Box className="shared-customer-care-container-channels">
      <Typography component={"h3"}>
        {CheckValueLocale("customer_care_account", "", {}, intl)}
        <Tooltip
          title={CheckValueLocale("customer_care_info", "", {}, intl)}
          placement={intl?.locale === "en" ? "right" : "left"}
          arrow
          id="radio-buttons-box"
          componentsProps={{
            tooltip: {
              sx: {
                width: "234px",
              },
            },
          }}
        >
          <Typography component={"span"} className="tooltip-btn">
            <InfoIcon id="customer-care-info-icon" />
          </Typography>
        </Tooltip>
      </Typography>
      <TextField
        id="outlined-error-helper-text"
        type="email"
        className="customer-care-input"
        helperText={
          <span className={errorMsg ? "error-msg helper-msg" : "helper-msg"}>
            {CheckValueLocale(
              errorMsg ? "username_special_characters_error" : "type_username",
              "",
              {},
              intl,
            )}
          </span>
        }
        value={coustomerCareVal}
        onChange={(e) => handleInputChange(e)}
      />
    </Box>
  );
};

export default CustomerCareChannel;
