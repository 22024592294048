import React from "react";
import { Grid } from "@mui/material";
import WidgetComponent from "./widgetComponent";

const WidgetsContainer = (props) => {
  const { dashboardInfo, dashboardWidgets, socialWidgets } = props;

  return (
    <Grid id="custom-dashboard-view-widgets-container" container>
      {dashboardWidgets
        ?.sort(
          (a, b) => (a?.attributes?.order ?? 0) - (b?.attributes?.order ?? 0),
        )
        ?.map((widget) => {
          return (
            <WidgetComponent
              key={widget?.id}
              widget={widget?.attributes}
              dashboardInfo={dashboardInfo}
              socialWidgets={socialWidgets}
            />
          );
        })}
    </Grid>
  );
};

export default WidgetsContainer;
