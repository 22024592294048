import React, { useEffect, useState } from "react";
import PieChartWidget from "components/widgets/pieChartWidget";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";
import { translateLocalsFromRabbitMQ } from "utils/helpers";

const CustomDashboardPieChart = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [colors, setColors] = useState(null);

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "dialects":
        case "dialects_sub_dialects":
        case "comments_dialects_sub_dialects":
        case "dialects_subdialects":
          setData(dataState?.["dialects"]);
          setSubData(dataState?.["sub_dialects"]);
          setColors(null);
          break;

        case "content_type":
          setData(
            dataState?.posts_content_types_legends ||
              dataState?.content_type_piechart,
          );
          setColors(null);
          break;

        case "content_style":
          setData(dataState?.[widget?.widget_name]);
          setColors(["#ED6C02", "#2AAAE2", "#80868C", "#8CC63F"]);
          break;

        case "comments_content_style":
          setData(dataState);
          setColors(["#ED6C02", "#2AAAE2", "#80868C", "#8CC63F"]);
          break;

        case "comments_associated_topics":
          setData(dataState?.["associated_topics"]);
          setColors(null);
          break;

        case "gender_distribution":
          setData(
            dataState?.[widget?.widget_name] ||
              dataState?.["genders"] ||
              dataState?.["top_genders"],
          );
          setColors(null);
          break;

        case "gender":
          setData(dataState?.["genders"]);
          setColors(null);
          break;

        case "comments_gender_distribution":
          setData(dataState?.["top_genders"]);
          setColors(["#F680BC", "#009BDF"]);
          break;

        case "comments_top_languages":
          if (
            dataState?.[widget?.widget_name]?.length ||
            dataState?.["top_languages"]?.length
          ) {
            const handledLanguages = translateLocalsFromRabbitMQ(
              "top_languages",
              dataState?.["top_languages"],
            );
            if (handledLanguages) setData(handledLanguages);
          } else setData([]);
          setColors(null);
          break;

        case "comments_top_countries":
          setData(dataState?.["top_countries"]);
          setColors(null);
          break;

        case "comments_top_cities":
          setData(dataState?.["top_cities"]);
          setColors(null);
          break;

        case "comments_account_types":
          setData(dataState?.["account_types"]);
          setColors(null);
          break;

        case "channel_accounts":
          setData(dataState?.["channel_accounts"]);
          setColors(null);
          break;

        case "comments_top_sources":
          setData(
            dataState?.[widget?.widget_name] || dataState?.["top_sources"],
          );
          setColors(null);
          break;

        case "top_languages":
          if (dataState?.[widget?.widget_name]?.length) {
            const handledLanguagesData = translateLocalsFromRabbitMQ(
              "top_languages",
              dataState?.[widget?.widget_name],
            );
            if (handledLanguagesData) setData(handledLanguagesData);
          } else setData([]);
          setColors(null);
          break;

        default:
          setData(dataState?.[widget?.widget_name]);
          setColors(null);
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  let isDialectsSubdialects = [
    "dialects_subdialects",
    "comments_dialects_sub_dialects",
    "dialects",
    "dialects_sub_dialects",
    "custom_omni_dialects_subdialects",
    "custom_omni_comments_dialects_sub_dialects",
    "custom_omni_dialects",
    "custom_omni_dialects_sub_dialects",
  ]?.includes(handledWidgetName);

  return (
    <PieChartWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={titleTooltip || ""}
      data={data || []}
      subData={subData || []}
      color={colors}
      areaStyle={true}
      toolTipLabel={"toolTipLabel"}
      showAI
      showDownloadIcon
      pieChartPreLoaderDataSources={
        socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
          monitorId
        ]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ] || []
      }
      isDialectsSubDialects={isDialectsSubdialects ? true : undefined}
      preLoaderTrackerDataSources={[monitorId] || []}
      isCustomDashboard
      monitorData={{
        name:
          dashboardInfo?.product === "cxm"
            ? widget?.account_identifier
            : widget?.monitor_name,
        type:
          dashboardInfo?.product === "cxm"
            ? widget?.data_category
            : widget?.monitor_type?.name,
        datasource: widget?.is_dm
          ? `${widget?.data_source}_private`
          : widget?.data_source,
      }}
    />
  );
};

export default CustomDashboardPieChart;
