import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import InteractionsController from "services/controllers/interactionsController";
import SubThemesDropdown from "./subThemesDropdown";
import ThemesDropdown from "./themesDropdown";
const ThemesMenu = (props) => {
  const intl = useIntl();
  const [themesOptions, setThemesOptions] = useState([]);
  const [subThemesOptions, setSubThemesOptions] = useState([]);
  useEffect(() => {
    InteractionsController.getThemes().then((data) =>
      setThemesOptions(data?.data?.data?.data),
    );
    InteractionsController.getSubThemes().then((data) =>
      setSubThemesOptions(data?.data?.data?.data),
    );
  }, []);
  return (
    <>
      <ThemesDropdown
        themesOptions={themesOptions}
        data={props?.data}
        themes={props?.themes}
        subThemes={props?.subThemes}
        interactionId={props?.interactionId}
        trackerId={props?.trackerId}
        createdAt={props?.createdAt}
        channelId={props?.channelId}
        mediaId={props?.mediaId}
        commentId={props?.commentId}
        apiVars={props?.apiVars}
        account_id={props?.account_id}
        location_id={props?.location_id}
        id={Number(props?.id)}
        productId={Number(props?.productId)}
        answerId={Number(props?.answerId)}
        isSurveyProduct={props.isSurveyProduct}
        audio_id={props?.audioId}
        lucidya_audio_id={props?.lucidyaAudioId}
        datasourceName={props?.datasourceName}
      />
      <SubThemesDropdown
        subThemesOptions={subThemesOptions}
        data={props?.data}
        themes={props?.themes}
        subThemes={props?.subThemes}
        interactionId={props?.interactionId}
        trackerId={props?.trackerId}
        createdAt={props?.createdAt}
        channelId={props?.channelId}
        mediaId={props?.mediaId}
        commentId={props?.commentId}
        apiVars={props?.apiVars}
        account_id={props?.account_id}
        location_id={props?.location_id}
        id={Number(props?.id)}
        productId={Number(props?.productId)}
        answerId={Number(props?.answerId)}
        isSurveyProduct={props.isSurveyProduct}
        audio_id={props?.audioId}
        lucidya_audio_id={props?.lucidyaAudioId}
        datasourceName={props?.datasourceName}
      />
    </>
  );
};
export default ThemesMenu;
