import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  MenuItem,
  ListItemText,
  Menu,
  Typography,
  Button,
  Checkbox,
  Select,
  Box,
  styled,
} from "@mui/material";

import "./ThemeDropdown.scss";
import InteractionsController from "services/controllers/interactionsController";
import SurveyController from "services/controllers/surveyController";
import { CheckValueLocale } from "utils/helpers";

const SubThemesDropdown = (props) => {
  const intl = useIntl();
  const [selectedSubThemes, setSelectedSubThemes] = useState(
    props?.isSurveyProduct
      ? props?.subThemes
        ? props?.subThemes
        : []
      : props?.data?.sub_themes
        ? props?.data?.sub_themes
        : [],
  );
  let labelColorSubThemes = (theme) => {
    switch (theme) {
      case "Products & Services":
        return "#0876B9";
      case "Delivery":
        return "#9CCEEA";
      case "Portal/App":
        return "#9FEA76";
      case "Payment":
        return "#D6B6E0";
      case "Customer Services":
        return "#DCDCDC";
      case "Undefined":
        return "#FFC245";
      default:
        return "";
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const valueAsArray = typeof value === "string" ? value.split(",") : value;
    // we should not allow the user to set the subThemes to nothing.
    if (valueAsArray?.length === 0) {
      return;
    }
    setSelectedSubThemes(valueAsArray);
    if (props.isSurveyProduct) {
      SurveyController?.editSubThemes(
        props?.answerId,
        props?.id,
        props?.sub_themes ? props?.sub_themes : [""],
        typeof value === "string" ? value.split(",") : value,
        props?.productId,
      ).then((result) => {});
    } else {
      InteractionsController.updateSingleInteractionSubThemes(
        props?.datasourceName == "gmail"
          ? props?.data?.email_id
          : props.interactionId,
        props.trackerId,
        props.data.sub_themes ? props.data.sub_themes : [""],
        typeof value === "string" ? value.split(",") : value,
        window.localStorage.cxm_id,
        props.channelId,
        props.createdAt,
        props.commentId,
        props.mediaId,
        props?.account_id,
        props?.location_id,
        props?.audio_id,
        props?.lucidya_audio_id,
        props.apiVars,
      ).then((result) => {});
    }
  };

  return (
    <div className="single-header-options">
      <Box className="sub-themes-container">
        <TypoTextHead
          sx={{ fontSize: "11px" }}
          className="sub-themes-dropdown-title"
        >
          {CheckValueLocale("sub_themes", "", {}, intl)}
        </TypoTextHead>
      </Box>
      <ButtonStyle
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        className="sub-themes-dropdown"
        multiple
        value={selectedSubThemes}
        onChange={handleChange}
        renderValue={(selected) => {
          return (
            <Box className="themes-box">
              <Box
                style={{
                  backgroundColor: labelColorSubThemes(selected[0]),
                }}
                className="box-color"
              ></Box>
              <span>
                {" "}
                {selected[0] ? CheckValueLocale(selected[0], "", {}, intl) : ""}
              </span>
              <Box className="themes-selected-item">
                {selected?.length > 1 ? (
                  <Box className="themes-selected-count">{`+${
                    selected?.length - 1
                  }`}</Box>
                ) : null}
              </Box>
            </Box>
          );
        }}
      >
        <CustomMenuItem disabled value="" className="themes-sub-title">
          <TypoTextHead>
            {CheckValueLocale("sub_themes", "", {}, intl)}
          </TypoTextHead>
        </CustomMenuItem>
        {props.subThemesOptions?.map((theme, index) => (
          <CustomMenuItem key={theme} value={theme}>
            <Checkbox checked={selectedSubThemes?.indexOf(theme) > -1} />
            <Box
              style={{
                backgroundColor: labelColorSubThemes(theme),
              }}
              className="box-color box-color-theme"
            ></Box>
            <ListItemText
              className="sub-theme-item"
              primary={theme ? CheckValueLocale(theme, "", {}, intl) : ""}
            />
          </CustomMenuItem>
        ))}
      </ButtonStyle>
    </div>
  );
};
export default SubThemesDropdown;
const ButtonStyle = styled(Select)`
  background-color: #eef2f6;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 10px;
  margin: 5px;
`;
const TypoText = styled(Typography)`
  color: #606874;
  font-weight: 400;
  font-size: 12px;
`;
const TypoTextHead = styled(Typography)`
  color: #333b48;
  font-weight: 500;
  font-size: 12px;
  padding: 0;
  margin: 0;
`;
export const CustomMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    padding: 0;
    padding-bottom: 5px;
    width: 200px;
  }
  .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-size: 14px;
    color: #334155;
    margin: 0px 5px;
  }
  .MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 5px;
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    font-size: 20px;
  }
  .MuiListItemText-root.css-tlelie-MuiListItemText-root {
    .MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
      font-size: 14px !important;
    }
  }
`;
