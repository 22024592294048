import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import lucidyaLogo from "images/login-logo.svg";

const FormHeader = () => {
  const intl = useIntl();

  return (
    <Box className="form-header-wrapper">
      <Box>
        <img src={lucidyaLogo} alt="" />
      </Box>
    </Box>
  );
};
export default FormHeader;
