import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "../../surveyBuilder.scss";

const LinearScale = (props) => {
  const intl = useIntl();
  const {
    question,
    filteredSections,
    surveySections,
    setSurveySections,
    sectionIndex,
    questionIndex,
  } = props;

  const [editable, setEditable] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);
  const [lowValue, setLowValue] = useState("");
  const [highValue, setHighValue] = useState("");
  const maxSelects = [2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    if (question) {
      setEditable(question?.editable);
      setMinValue(question?.constraints?.min_value);
      setMaxValue(question?.constraints?.max_value);
      setLowValue(question?.constraints?.low_value);
      setHighValue(question?.constraints?.high_value);
    }
  }, [question]);

  const handleSwitch = (e, constraint) => {
    filteredSections[sectionIndex].questions.filter(
      (question) => question?.is_deleted !== true,
    )[questionIndex].question.constraints[constraint] = e.target.checked;
    setSurveySections([...surveySections]);
  };

  const handleChangeValues = (e) => {
    if (e.target.name === "low-value") {
      setMinValue(e.target.value);
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints.min_value = e.target.value;
      setSurveySections([...surveySections]);
    } else if (e.target.name === "high-value") {
      setMaxValue(e.target.value);
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints.max_value = e.target.value;
      setSurveySections([...surveySections]);
    }
  };

  const handleChangeText = (e) => {
    if (e.target.name === "low-text") {
      setLowValue(e.target.value);
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints.low_value = e.target.value;
      setSurveySections([...surveySections]);
    } else if (e.target.name === "high-text") {
      setHighValue(e.target.value);
      filteredSections[sectionIndex].questions.filter(
        (question) => question?.is_deleted !== true,
      )[questionIndex].question.constraints.high_value = e.target.value;
      setSurveySections([...surveySections]);
    }
  };

  return (
    <>
      {Object?.entries(question?.constraints)?.map((constraint, index) => {
        if (constraint[0] === "required")
          return (
            <Box
              className={`constraints-text ${
                editable === false && `disabled-color`
              }`}
              key={index}
            >
              <Box>{CheckValueLocale(constraint[0], "", {}, intl)}</Box>
              <Switch
                data-testid="required_switch"
                onChange={(e) => handleSwitch(e, constraint[0])}
                checked={Object.values(constraint)[1]}
                disabled={
                  editable === false && constraint[0] !== "required"
                    ? true
                    : false
                }
              />
            </Box>
          );
      })}
      <Box
        className={`linearscale-constraints constraints-text ${
          editable === false && `disabled-color`
        }`}
      >
        <Box className="valuesTitle">
          {CheckValueLocale("survey_value", "", {}, intl)}
        </Box>
        <Box className="valuesContainer">
          <Box className="lowValue">
            <FormControl fullWidth size="small">
              <Select
                labelId="low-value-select-label"
                id="low-value-select"
                name="low-value"
                value={minValue}
                onChange={handleChangeValues}
                disabled={editable === false && true}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={1}>1</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mx={2}>{CheckValueLocale("pdf_to", "", {}, intl)}</Box>
          <Box className="highValue">
            <FormControl fullWidth size="small">
              <Select
                labelId="high-value-select-label"
                id="high-value-select"
                name="high-value"
                value={maxValue}
                onChange={handleChangeValues}
                disabled={editable === false && true}
                MenuProps={{ PaperProps: { style: { maxHeight: 195 } } }}
              >
                {maxSelects?.map((val, index) => {
                  return (
                    <MenuItem key={index} value={val}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        className={`linearscale-constraints constraints-text ${
          editable === false && `disabled-color`
        }`}
      >
        <Box mt={1} className="linearscale-constraints-container">
          <Box className="valuesTitle">
            {CheckValueLocale("low_value", "", {}, intl)}
          </Box>
          <TextField
            id="low-value-text"
            name="low-text"
            variant="outlined"
            size="small"
            placeholder={CheckValueLocale(
              "low_value_placeholder",
              "",
              {},
              intl,
            )}
            value={lowValue}
            onChange={handleChangeText}
            disabled={editable === false && true}
            fullWidth
          />
        </Box>
        <Box mt={2} className="linearscale-constraints-container">
          <Box className="valuesTitle">
            {CheckValueLocale("high_value", "", {}, intl)}
          </Box>
          <TextField
            id="high-value-text"
            name="high-text"
            variant="outlined"
            size="small"
            placeholder={CheckValueLocale(
              "high_value_placeholder",
              "",
              {},
              intl,
            )}
            value={highValue}
            onChange={handleChangeText}
            disabled={editable === false && true}
            fullWidth
          />
        </Box>
      </Box>
    </>
  );
};

export default LinearScale;
