import { useState } from "react";
import LoginController from "services/controllers/loginController.js";
import CompaniesController from "services/controllers/companiesController.js";
import { CheckValueLocale, emailValidator } from "utils/helpers/index.js";
import UserAccount from "services/controllers/userAccountController";
import Services from "services/api/Services.js";
import { handlelUserRoles } from "utils/helpers/index.js";
import { Userpilot } from "userpilot";
import { useIntl } from "react-intl";

const LoginFormFunctions = () => {
  const intl = useIntl();

  const [stats, setStats] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    wrongInput: false,
    errorText: CheckValueLocale("invalid_email_or_password", "", {}, intl),
    activeProducts: [],
    showForgetPassword: false,
    showSnackBarError: null,
    showSnackBarWarning: null,
    showSnackBarSuccess: null,
    checked: false,
    lang:
      window?.localStorage?.lang === undefined
        ? window?.navigator?.language?.split("-")[0]
        : window?.localStorage?.lang,
    isForgetPassword: false,
    loginLoading: false,
    forgetPasswordLoading: false,
    resetPasswordLoading: false,
    newUserLoading: false,
    ssoLoader: false,
    isResetPassword: false,
    isNewUser: false,
    verify: false,
  });
  const intialStats = {
    email: "",
    password: "",
    confirmPassword: "",
    wrongInput: false,
    errorText: CheckValueLocale("invalid_email_or_password", "", {}, intl),
    verify: false,
  };
  const [errors, setErrors] = useState([]);
  const [errorFields, setErrorFields] = useState({});
  const validatePassword = (passwordText) => {
    const regex_uppercase = /[A-Z]/;
    const regex_lowercase = /[a-z]/;
    const regex_number = /[0-9]/;

    let errors = [];

    if (passwordText?.length < 8) {
      errors?.push("chars_num_err");
    }
    if (!passwordText?.match(regex_uppercase)) {
      errors?.push("uppercase_err");
    }
    if (!passwordText?.match(regex_lowercase)) {
      errors?.push("lowercase_err");
    }
    if (!passwordText?.match(regex_number)) {
      errors?.push("number_err");
    }
    setErrors(errors);
  };
  const handleSnackbarClose = () => {
    setStats({
      ...stats,
      showSnackBarError: null,
      showSnackBarWarning: null,
      showSnackBarSuccess: null,
    });
  };
  const handleInputChange = (event) => {
    if (event?.target?.name === "password") {
      validatePassword(event?.target?.value);
    }
    if (event?.target?.name === "checked") {
      setStats({ ...stats, checked: event?.target?.checked });
    } else {
      setStats({ ...stats, [event?.target?.name]: event?.target?.value });
    }
  };
  const handleVerifySuccess = () => {
    setStats({
      ...stats,
      verify: true,
    });
  };

  const handleVerifyExpire = () => {
    setStats({
      ...stats,
      verify: false,
    });
  };

  const callApiHubspotLogin = (url) => {
    let urlParams = new URLSearchParams(url);
    let redirectUrl = urlParams?.get("redirect_url");
    LoginController.userLoginHubspot(
      stats?.email,
      stats?.password,
      stats?.lang,
      redirectUrl,
    ).then((data) => {
      if (data?.data?.status == 200 || data?.data?.status == 204)
        return createHutspotUser();
      else
        return setStats({
          ...stats,
          wrongInput: true,
        });
    });
  };
  const callApiNormalLogin = () => {
    LoginController.userLogin(stats?.email, stats?.password, stats?.lang).then(
      (data) => {
        Userpilot.identify(data?.data?.id);
        if (data?.data?.id) return getCompanyProducts();
        else {
          if (
            data?.errorMsg?.response?.data?.exception?.message ===
            "COMPANY_NOT_ENABLED"
          ) {
            return setStats({
              ...stats,
              showSnackBarError: "showCxmCore",
              wrongInput: false,
            });
          } else if (
            data?.errorMsg?.response?.data?.exception?.message ===
            "COMPANY_NOT_STARTED"
          ) {
            return setStats({
              ...stats,
              showSnackBarError: "showNotStarted",
              wrongInput: false,
            });
          } else if (
            data?.data?.response?.data?.exception?.message ===
            "SUSPENDED_COMPANY"
          ) {
            return setStats({
              ...stats,
              showSnackBarError: "showError",
              wrongInput: false,
            });
          } else if (
            data?.errorMsg?.response?.data?.exception?.exception_class ===
            "deactivated_user_error"
          ) {
            return setStats({
              ...stats,
              showSnackBarWarning: "showDeactivated",
              wrongInput: false,
            });
          } else if (
            data?.data?.response?.data?.exception?.message ===
            "ACCOUNT_IS_LOCKED"
          ) {
            return setStats({
              ...stats,
              errorText: CheckValueLocale("failed_login_locked", "", {}, intl),
              wrongInput: true,
            });
          } else
            return setStats({
              ...stats,
              errorText: CheckValueLocale(
                "invalid_email_or_password",
                "",
                {},
                intl,
              ),
              wrongInput: true,
            });
        }
      },
    );
  };
  const handleUserLogin = () => {
    let errorField = {};
    if (!stats?.email) {
      errorField["email"] = "this_field_required";
    }
    if (!stats?.password) {
      errorField["password"] = "this_field_required";
    }
    if (!stats?.verify) {
      errorField["verify"] = "this_field_required";
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      let url = window?.location?.search;
      let language = localStorage?.getItem("lang");
      localStorage?.clear();
      localStorage?.setItem("lang", JSON.stringify(language));
      setStats({ ...stats, loginLoading: true });
      //check user hubspot or not
      if (url?.includes("?redirect_url")) {
        callApiHubspotLogin(url);
      } else {
        callApiNormalLogin();
      }
    }
  };
  const getCompanyProducts = () => {
    CompaniesController.getCompanyProducts().then((data) => {
      const activeProducts = data?.data?.filter((product) => product?.active);
      //SET ALL PRODUCTS ID IN LOCAL STORAGE TEMPORARLY TO TEST BACKEND IN INSIGHTS
      // eslint-disable-next-line
      var systemsList = new Array();

      activeProducts?.map((product) => {
        systemsList?.push(product?.name);
        if (product?.name === "CXM") {
          return window?.localStorage?.setItem(
            "cxm_id",
            JSON.stringify(product?.id),
          );
        } else if (product?.name === "SM") {
          window?.localStorage?.setItem("sm_id", JSON.stringify(product?.id));
        }
      });

      //END OF TEMP. TESTING CODE
      if (activeProducts?.length > 0) {
        window?.localStorage?.setItem(
          "activeProducts",
          JSON.stringify(activeProducts),
        );
        if (systemsList?.length >= 0) {
          window.location.href = `/home_page`;
        }
        setStats({ ...stats, loginLoading: false });
      }
    });
  };
  //create user hutspot
  const createHutspotUser = () => {
    UserAccount.createUserHutspot().then((result) => {
      if (result?.data) {
        window?.open(result?.data?.url, "_self");
      }
    });
  };

  const handleShowForgetPassword = () => {
    setStats({
      ...stats,
      ...intialStats,
      showForgetPassword: true,
    });
    setErrors([]);
    setErrorFields({});
  };

  const handleBkToSignIn = () => {
    setStats({
      ...stats,
      ...intialStats,
      showForgetPassword: false,
    });
    setErrors([]);
    setErrorFields({});
  };

  const handleUserForgetPassword = () => {
    let errorField = {};
    if (!emailValidator(stats?.email)) {
      errorField["email"] = "email_format_is_incorrect";
    }
    if (!stats?.email) {
      errorField["email"] = "this_field_required";
    }
    if (!stats?.verify) {
      errorField["verify"] = "this_field_required";
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      setStats({ ...stats, forgetPasswordLoading: true });
      LoginController.userForgetPassword(stats?.email).then((data) => {
        if (data?.data?.status == 200) {
          return setStats({
            ...stats,
            forgetPasswordLoading: false,
            showForgetPassword: false,
            isForgetPassword: true,
            showSnackBarSuccess: "showSuccess",
          });
        } else {
          return setStats({
            ...stats,
            forgetPasswordLoading: false,
            wrongInput: true,
            errorText: CheckValueLocale(
              "please_enter_valid_email_address_login",
              "",
              {},
              intl,
            ),
          });
        }
      });
    }
  };
  const handleUserResetPassword = (token) => {
    let errorField = {};
    if (stats?.password !== stats?.confirmPassword) {
      errorField["confirmPassword"] = "confirm_not_match_new_password";
    }
    if (!stats?.password) {
      errorField["password"] = "this_field_required";
    }
    if (!stats?.confirmPassword) {
      errorField["confirmPassword"] = "this_field_required";
    }
    if (errors.length) {
      errorField["errorsPassword"] = "password_weak";
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      setStats({ ...stats, resetPasswordLoading: true });
      LoginController.userResetPassword(token, stats?.password).then((data) => {
        if (data?.data?.status === 200) {
          return setStats({
            ...stats,
            isResetPassword: true,
            showSnackBarSuccess: "showSuccess",
          });
        } else {
          return setStats({
            ...stats,
            resetPasswordLoading: false,
            wrongInput: true,
          });
        }
      });
    }
  };

  const handleActivateSignUp = (invitationToken, userEmail) => {
    let errorField = {};
    if (stats?.password !== stats?.confirmPassword) {
      errorField["confirmPassword"] = "confirm_not_match_new_password";
    }
    if (!stats?.password) {
      errorField["password"] = "this_field_required";
    }
    if (!stats?.confirmPassword) {
      errorField["confirmPassword"] = "this_field_required";
    }
    if (errors?.length) {
      errorField["errorsPassword"] = "password_weak";
    }
    if (!stats?.checked) {
      errorField["checked"] = "this_field_required";
    }
    setErrorFields(errorField);
    if (!Object.keys(errorField)?.length) {
      setStats({ ...stats, newUserLoading: true });
      LoginController.activateSignUp(
        invitationToken,
        userEmail,
        stats?.password,
      ).then((data) => {
        if (data?.data?.status == 200) {
          return setStats({
            ...stats,
            isNewUser: true,
            showSnackBarSuccess: "showSuccess",
          });
        } else {
          return setStats({
            ...stats,
            newUserLoading: false,
            wrongInput: true,
            errorText: CheckValueLocale(
              data?.errorMsg?.response?.data?.msg?.toLocaleLowerCase(),
              "",
              {},
              intl,
            ),
          });
        }
      });
    }
  };

  const handleSSOlogin = () => {
    setStats({ ...stats, ssoLoader: true });
    window.location.href = Services?.baseRoute + "/api/v3/auth/sso/login";
  };

  return {
    stats,
    errors,
    handleInputChange,
    handleUserLogin,
    handleSnackbarClose,
    errorFields,
    handleShowForgetPassword,
    handleBkToSignIn,
    handleUserForgetPassword,
    handleUserResetPassword,
    handleActivateSignUp,
    handleSSOlogin,
    handleVerifySuccess,
    handleVerifyExpire,
  };
};
export default LoginFormFunctions;
