import { Box, Container, Typography } from "@mui/material";
import "./editTeam.scss";
import { useEffect, useState } from "react";
import CircularLoading from "components/circularProgress";
import { EditTeamHeader } from "./components/editTeamHeader";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import { TeamSharedFuntions } from "../sharedFunctions/teamSharedFunctions";
import { TeamInformation } from "../components/teamInformation";
import { EditTeamMembers } from "./components/editTeamMembers";
import { EditTeamFooter } from "./components/editTeamFooter";

const isSelectedMembersChanged = (selectedMembers, originalSelectedMembers) => {
  if (selectedMembers?.length !== originalSelectedMembers?.length) return true;
  const originalMembers = new Set(originalSelectedMembers);
  let isChanged = false;
  selectedMembers?.forEach((member) => {
    if (!originalMembers?.has(member)) {
      isChanged = true;
    }
  });
  return isChanged;
};

export const EditTeam = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [team, setTeam] = useState({});
  const intl = useIntl();
  const [numberOfChanges, setNumberOfChanges] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const {
    teamInformation,
    setTeamInformation,
    members,
    setMembers,
    selectedMembers,
    setSelectedMembers,
    socialAccounts,
    setSocialAccounts,
    selectedSocialAccounts,
    setSelectedSocialAccounts,
    monitors,
    setMonitors,
    selectedMonitors,
    setSelectedMonitors,
    channels,
    setChannels,
    selectedChannels,
    setSelectedChannels,
    handleTeamInformationChange,
    handleSelectMember,
    handleSelectSocialAccount,
    handleSelectMonitor,
    handleSelectChannel,
    handleSaveTeam,
    initilizeTeamDetails,
    isTeamChannelsLoading,
    isTeamMemberLoading,
    isTeamMonitorsLoading,
    isTeamProductsLoading,
    isTeamSocialAccountsLoading,
    products,
    teamError,
    setTeamError,
    initializeDataForEditTeam,
  } = TeamSharedFuntions();
  const isEditTeamFormValid =
    !teamError?.teamNameError &&
    numberOfChanges &&
    !!teamInformation?.teamName?.trim?.() &&
    !!teamInformation?.selectedProduct &&
    !!selectedMembers?.length;

  const handleCloseSnackBar = () => {
    setTeamError({
      openSnackBar: false,
    });
  };

  const initializeTeamDetailsData = () => {
    initializeDataForEditTeam(id);
    CompaniesController.getTeam(id).then((res) => {
      if (!res?.errorMsg) {
        const team = res?.data?.data?.attributes;
        const selectedTeams = team?.users_ids || [];
        setTeamInformation({
          teamName: team?.team_name,
          selectedProduct: team?.product_id,
        });
        setSelectedMembers(selectedTeams);
        setTeam(team);
      } else {
        setTeamError({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      }
      setIsLoading(false);
    });
  };

  const handleSave = () => {
    setIsSavingLoading(true);
    const updatedTeam = {
      team_info: {
        name: teamInformation?.teamName,
        product_id: teamInformation?.selectedProduct,
      },
      users: selectedMembers,
    };
    CompaniesController.updateTeam(id, updatedTeam).then((res) => {
      if (res?.errorMsg) {
        // check if duplicate team name
        const isDuplicateTeamName =
          res?.errorMsg?.response?.data?.exception?.message ===
          "TEAM_NAME_ALREADY_EXIST";
        if (isDuplicateTeamName) {
          setTeamError({
            teamNameError: "team_name_already_exist",
          });
          // go to the top of the page
          window.scrollTo(0, 0);
        } else {
          setTeamError({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          });
        }
      } else {
        // go to team details page
        history.push(`/settings/teams/view/${id}?status=updated_successfully`);
      }
      setIsSavingLoading(false);
    });
  };

  const handleCancel = () => {
    // go back to teams list or team details
    const backTo =
      location?.state?.from === "teamDetails"
        ? `/settings/teams/view/${id}`
        : "/settings/teams";
    history.push(backTo);
  };
  useEffect(() => {
    initializeTeamDetailsData();
  }, []);

  useEffect(() => {
    if (!teamInformation?.teamName || !team?.team_name) return;
    // here we will count the number of changes and if its valid, we will show the save button footer
    let counter = 0;
    if (teamInformation?.teamName?.trim() !== team?.team_name?.trim()) {
      counter++;
    }
    const isMembersChanged = isSelectedMembersChanged(
      selectedMembers || [],
      team?.users_ids || [],
    );
    if (isMembersChanged) counter++;

    setNumberOfChanges(counter);
  }, [teamInformation, selectedMembers]);

  if (isLoading || isTeamMemberLoading || isTeamProductsLoading)
    return <CircularLoading />;

  return (
    <>
      <Container maxWidth="xl" className="edit-team-container">
        <EditTeamHeader team={team} id={id} />
        <Box className="edit-team-main-container">
          <Box className="edit-team-details">
            {/* Team Information (Team name and Team Product) */}
            <Box className="edit-team-details-section">
              <Typography variant="h3" className="edit-team-details-title">
                {CheckValueLocale("team_details", "", {}, intl)}
              </Typography>
              <Box className="edit-team-details-content">
                <TeamInformation
                  isProductsDisabled={true}
                  teamNameError={teamError?.teamNameError}
                  options={products}
                  setTeamName={(teamName) => {
                    handleTeamInformationChange({ teamName });
                  }}
                  teamName={teamInformation?.teamName}
                  selectedProduct={teamInformation?.selectedProduct}
                  setSelectedProduct={(selectedProduct) => {
                    handleTeamInformationChange({ selectedProduct });
                  }}
                />
                <EditTeamMembers
                  selectedMembers={selectedMembers}
                  handleSelectMember={handleSelectMember}
                  members={members}
                  setMembers={setMembers}
                  teamId={id}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <SnackBar
          open={teamError?.openSnackBar}
          severity={teamError?.severity}
          message={CheckValueLocale(teamError?.message, "", {}, intl)}
          title={CheckValueLocale(teamError?.title, "", {}, intl)}
          handleClose={handleCloseSnackBar}
        />
      </Container>
      {numberOfChanges > 0 ? (
        <EditTeamFooter
          handleCancel={handleCancel}
          numberOfChanges={numberOfChanges}
          handleSave={handleSave}
          isDisabled={!isEditTeamFormValid}
          isLoading={isSavingLoading}
        />
      ) : null}
    </>
  );
};
