import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Popover,
  Typography,
} from "@mui/material";

import { faBadgeCheck, faCommentCheck } from "@fortawesome/pro-solid-svg-icons";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import zVector from "images/monitor_revamp/z-vector.svg";
import user from "images/monitor_revamp/user.svg";
import NewsBlogPlaceholder from "images/placeholders/news_blogs_placeholder.svg";
import {
  faChartColumn,
  faExternalLink,
  faBan,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import moment from "moment";
import { useIntl } from "react-intl";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import SnackBar from "components/snackBar.js";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import {
  CheckValueLocale,
  extractDisplayInfoFromDataSource,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import ReplyModal from "../replyModal/replyModal";
import EditAndDeleteButtons from "pages/engagements/components/details/postsAndReplies/editAndDeleteButtons/editAndDeleteButtons";
import "pages/engagements/components/details/postsAndReplies/editAndDeleteButtons/editAndDeleteButtons.scss";
import PopupModal from "components/popupModal";
import EngagementsController from "services/controllers/engagementsController";
import SpamOverlay from "./components/spamOverlay";
import lexisnexis from "../../../../images/lexisnexis/lexisnexis.svg";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import CustomizedTooltip from "components/customizedTooltip";

const CardHeader = (props) => {
  const {
    question,
    icon,
    iconColor,
    showBanIcon,
    showScreenName,
    showDefaultImg,
    newsBlogImg,
    hideChartBar,
    hideVector,
    monitorDataSource,
    monitorType,
    snackBarData,
    openSnackBar,
    handleCloseSnack,
    postSpamTweets,
    handleSpamPost,
    handleUndoSpam,
    getMoreInsights,
    insightsID,
    response,
    dates,
    datasourcePostsType,
    index,
    replyModalData,
    isAggregateDashboard,
    isAlert,
    setIsReplyDeleted,
    isReplyDeleted,
    setActiveReplyEdit,
    activeReplyEdit,
    activeTab,
    allawedTrackers,
    showProfile,
    unixDate,
    productId,
    isTwitterLiveDashBoard,
    isInstagramLiveDashBoard,
  } = props;
  const showlexisnexisLicenses =
    question?.licenses?.some((item) => item?.name === "LexisNexis Licensed") ||
    null;
  const [iconToDisplay, setIconToDisplay] = useState(question?.publisher_image);
  const [openProfile, setOpenProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  const [text, setText] = useState("");
  const [openDeleteModalStats, setOpenDeleteModalStats] = useState({
    openDeleteModal: false,
    snackBarAction: "",
    snackBarSeverity: "",
  });
  const [snackBarDeleteData, setSnackBarDeleteData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [isDeletedResponse, setIsDeletedResponse] = useState(false);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(false);

  const [spamLoading, setSpamLoading] = useState(false);
  const [isSpam, setIsSpam] = useState(false);

  const handleCloseDeleteModal = () => {
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: false,
    });
  };

  const handleOpenDeleteModal = (event) => {
    event.stopPropagation();
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: true,
    });
  };

  const getModelDeletePostdBody = () => {
    return (
      <Box className="delete-post-body-modal">
        <Typography component={"p"}>
          {CheckValueLocale("delete_post_body", "", {}, intl)}
        </Typography>
        <Typography component={"p"}>
          {CheckValueLocale("delete_post_body_two", "", {}, intl)}
          <Box component="span">
            {CheckValueLocale("delete_post_body_undone", "", {}, intl)}
          </Box>
        </Typography>
      </Box>
    );
  };

  const activeDeleteMsgMode = () => {
    if (isReplyDeleted) {
      isReplyDeleted[index] = true;
      setIsReplyDeleted([...isReplyDeleted]);
    }
  };
  const handleDeletePost = (replyId) => {
    const dataSourceId = +question?.CA_channel_id;
    const monitorId = +question?.tracker_id;
    const postType = question?.type;
    setDisabledDeleteBtn(true);
    EngagementsController.deleteEngagementPost(
      replyId,
      dataSourceId,
      monitorId,
      postType,
      window?.localStorage?.sm_id,
    ).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        setDisabledDeleteBtn(false);
        handleCloseDeleteModal();
        activeDeleteMsgMode();
        setIsReplyDeleted(true);
        handleCloseDeleteModal();
        setOpenSnackBarDelete(true);
        setIsDeletedResponse(res?.data?.data?.is_deleted);
        setSnackBarDeleteData({
          severity: "success",
          title: "post_was_deleted",
        });
      } else {
        handleCloseDeleteModal();
        setDisabledDeleteBtn(false);
        setSnackBarDeleteData({
          title: "something_went_wrong",
          severity: "error",
        });
        setOpenSnackBarDelete(true);
      }
    });
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
  };

  const handleCloseRepliedSnack = () => {
    setShowErrorSnackBar(false);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    (async () => {
      const image = new Image();
      image.src = question?.publisher_image;
      image.onload = () => {
        setIconToDisplay(question?.publisher_image);
      };
    })();
  }, [question?.publisher_image]);

  //check if user image is broken or not & replace it with dummyImage
  const handleImageError = (event) => {
    event.target.src = user;
    event.target.alt = "placeholder-image";
  };

  const intl = useIntl();

  useEffect(() => {
    const handleScroll = () => {
      if (open) {
        setAnchorEl(null);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [open]);

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };
  const dataSource = monitorDataSource
    ? monitorDataSource
    : question?.data_source;
  const isInstagram =
    dataSource?.toLowerCase() === "instagram" ||
    monitorDataSource === "INSTAGRAM" ||
    monitorDataSource === "INSTAGRAM_PUBLIC" ||
    datasourcePostsType === "INSTAGRAM" ||
    datasourcePostsType === "INSTAGRAM_PUBLIC" ||
    isInstagramLiveDashBoard;
  const { displayName, profilePicture, subName } =
    extractDisplayInfoFromDataSource(
      { data_source: dataSource, ...question },
      {
        displayName: question?.user_name,
        profilePicture: question?.user_profile_image_url,
        subName: isInstagram
          ? question?.profile_name
            ? question?.user_name
            : null
          : question?.screen_name,
      },
    );
  const supportUserNameTurncate =
    props?.noOfChar !== undefined || (isInstagram && displayName && subName);

  const isTwitter =
    monitorDataSource === "TWITTER" ||
    monitorDataSource === "TWITTER_PUBLIC" ||
    datasourcePostsType === "TWITTER" ||
    datasourcePostsType === "TWITTER_PUBLIC" ||
    isTwitterLiveDashBoard;
  const handleSideBar = () => {
    if (showProfile) {
      setOpenProfile(true);
    }
  };
  return (
    <Box className="post-head">
      {openProfile ? (
        <UserProfileSideBar
          dataSourceName={
            monitorDataSource ? monitorDataSource : datasourcePostsType
          }
          unixDate={unixDate}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={question}
          monitorDataSource={monitorDataSource}
          monitorId={question?.tracker_id}
          productId={productId}
        />
      ) : null}
      <Box
        className={`post-title-date ${showProfile ? "hover-show" : ""}`}
        onClick={handleSideBar}
      >
        <Box className={`question-post-img  ${iconColor}-post`}>
          {profilePicture ? (
            <img src={profilePicture} alt="" onError={handleImageError} />
          ) : newsBlogImg === true ? (
            <img
              src={iconToDisplay ? iconToDisplay : NewsBlogPlaceholder}
              alt="NewsBlog Placeholder"
              onError={handleImageError}
            />
          ) : (
            showDefaultImg && <img src={user} alt="" />
          )}
          <Box
            className={`post-title-icon-end ${
              isInstagram ? " insta-icon-end" : ""
            } ${isTwitter ? " twitter-icon-end" : ""}`}
          >
            {isTwitter || isInstagram ? (
              <img
                src={icon}
                alt="x-platform-logo"
                className={`${isInstagram ? " instagram-icon-img" : ""} ${
                  isTwitter ? " x-platform-img" : ""
                }`}
                id="monitors-color-icon"
              />
            ) : (
              <FontAwesomeIcon
                icon={icon}
                className={` ${iconColor}  title-icon-img`}
                solid
                id="monitors-color-icon"
              />
            )}
          </Box>
        </Box>
        <Box className="post-title">
          <Box className="display-in-row">
            <Box>
              <Box className="display-in-row">
                {showlexisnexisLicenses && (
                  <Tooltip
                    title={CheckValueLocale(
                      "licensed_content_lexisNexis",
                      "",
                      {},
                      intl,
                    )}
                    arrow
                    placement="top"
                  >
                    <img
                      className="lexisnexis-img post-title-icon-end"
                      src={lexisnexis}
                      alt="lexisnexis"
                    />
                  </Tooltip>
                )}
                <Box className="post-name">
                  {supportUserNameTurncate && displayName?.length > 10 ? (
                    <Tooltip title={displayName} placement="top" arrow>
                      <div>{displayName?.toString()?.slice(0, 10) + ".."}</div>
                    </Tooltip>
                  ) : (
                    displayName
                  )}
                </Box>
                {question.verified && question.verified !== "false" && (
                  <FontAwesomeIcon
                    icon={faBadgeCheck}
                    color="#1D9BF0"
                    id="monitors-question-verified-icon"
                    className="post-title-icon-start"
                  />
                )}
              </Box>
              {showScreenName && subName && (
                <Box className="post-user-name">@{subName}</Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="post-top-icons">
        {monitorDataSource !== "NEWSBLOGS" &&
          (monitorType == "MANAGED_PAGE" || monitorType == "MANAGED_ACCOUNT") &&
          handlelUserRoles("ENGAGEMENT_ACCESS", "SL_ENGAGEMENT") &&
          retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
          allawedTrackers?.includes(+question?.tracker_id) && (
            <>
              <EditAndDeleteButtons
                handleOpenDeleteModal={handleOpenDeleteModal}
                setActiveReplyEdit={setActiveReplyEdit}
                activeReplyEdit={activeReplyEdit}
                index={index}
                deleteTooltip={
                  activeTab == "comments_mentions"
                    ? "delete_comment"
                    : "delete_post"
                }
                isDeletedResponse={isDeletedResponse}
                editTooltip={
                  activeTab == "comments_mentions"
                    ? "edit_comment_tooltip"
                    : "edit_post_tooltip"
                }
                activeTab={activeTab}
                hideDeleteIcon={
                  (monitorDataSource === "FACEBOOK" &&
                    monitorType == "MANAGED_PAGE" &&
                    (activeTab == "account" || activeTab == "posts") &&
                    !question?.published_by_lucidya) ||
                  (monitorDataSource === "INSTAGRAM" &&
                    monitorType == "MANAGED_ACCOUNT" &&
                    (activeTab == "account" || activeTab == "posts"))
                }
                hideEditIcon={
                  ((activeTab == "comments_mentions" ||
                    activeTab == "engagements") &&
                    monitorDataSource === "FACEBOOK") ||
                  monitorDataSource === "INSTAGRAM"
                }
              />
              <Divider orientation="vertical" className="divider-style" />
            </>
          )}
        {showBanIcon && (
          <>
            <CustomizedTooltip
              title={
                <Box className="tooltip-custom-new">
                  {CheckValueLocale("mark_as_spam", "", {}, intl)}
                </Box>
              }
              placement="bottom"
              arrow
            >
              <Box className="top-posts-header-icon">
                <FontAwesomeIcon
                  icon={faBan}
                  onClick={() =>
                    handleSpamPost(
                      question,
                      setSpamLoading,
                      setIsSpam,
                      datasourcePostsType,
                    )
                  }
                  id="monitors-spam-tweets-icon"
                />
              </Box>
            </CustomizedTooltip>
            <Divider orientation="vertical" className="divider-style" />
          </>
        )}
        {showBanIcon && (
          <SpamOverlay
            question={question}
            spamLoading={spamLoading}
            isSpam={isSpam}
            setIsSpam={setIsSpam}
            handleUndoSpam={handleUndoSpam}
            datasourcePostsType={datasourcePostsType}
          />
        )}
        {/* {hideVector !== true && (
          <a href="">
            <img src={zVector} />
          </a>
        )} */}
        {!hideChartBar && (
          <>
            {(monitorType === "MANAGED_PAGE" ||
              monitorType === "MANAGED_ACCOUNT") &&
            (monitorDataSource === "INSTAGRAM" ||
              monitorDataSource === "FACEBOOK" ||
              datasourcePostsType === "INSTAGRAM" ||
              datasourcePostsType === "FACEBOOK") ? (
              <CustomizedTooltip
                title={
                  insightsID &&
                  insightsID?.indexOf(question.tweet_id) !== -1 ? (
                    <>
                      {CheckValueLocale(
                        "you_already_requested_insights_on_this_post",
                        "",
                        {},
                        intl,
                      )}{" "}
                      <FontAwesomeIcon
                        icon={faSync}
                        className="spam-tweet-sync"
                        id="monitors-insights-spam-tweet-fa-icon"
                      />
                      {CheckValueLocale("button", "", {}, intl)}
                    </>
                  ) : (
                    <Box className="tooltip-custom-new">
                      {CheckValueLocale("analyze_tweet", "", {}, intl)}
                    </Box>
                  )
                }
                placement="bottom"
                arrow
              >
                <Box className="top-posts-header-icon">
                  <FontAwesomeIcon
                    icon={faChartColumn}
                    className={
                      insightsID &&
                      insightsID?.indexOf(question.tweet_id) !== -1
                        ? "chart-icon more-insights-disable"
                        : "chart-icon "
                    }
                    onClick={() =>
                      insightsID &&
                      insightsID?.indexOf(question.tweet_id) === -1 &&
                      getMoreInsights(
                        question,
                        response,
                        insightsID,
                        dates,
                        undefined,
                        undefined,
                        datasourcePostsType,
                      )
                    }
                    id="monitors-chart-bar-fa-icon"
                  />
                </Box>
              </CustomizedTooltip>
            ) : (
              /* analyze_tweet icon_____________________________________________*/
              <CustomizedTooltip
                title={
                  <Box className="tooltip-custom-new">
                    {props.singleTweetAnalysis
                      ? CheckValueLocale("analyze_tweet", "", {}, intl)
                      : CheckValueLocale("analyze_tweet_upgrade", "", {}, intl)}
                  </Box>
                }
                arrow
                placement="bottom"
              >
                <Box className="top-posts-header-icon">
                  <IconButton className="chart-analysis-container">
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      solid
                      onClick={() =>
                        !props.singleTweetAnalysis
                          ? false
                          : props?.updateAnalysis(
                              question?.tweet_id,
                              question?.screen_name,
                              question?.tracker_id,
                              question?.created_at,
                            )
                      }
                      id="monitors-single-tweet-chart-bar-fa-icon"
                    />
                  </IconButton>
                </Box>
              </CustomizedTooltip>
            )}
          </>
        )}
        <CustomizedTooltip
          title={
            <Box className="tooltip-custom-new">
              {CheckValueLocale("external_media_tooltip", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <a href={question?.tweet_link} target="_blank" rel="noreferrer">
            <Box className="top-posts-header-icon">
              <FontAwesomeIcon
                icon={faExternalLink}
                id="monitors-external-link-fa-icon"
              />
            </Box>
          </a>
        </CustomizedTooltip>
        {monitorDataSource !== "NEWSBLOGS" &&
          handlelUserRoles("ENGAGEMENT_ACCESS", "SL_ENGAGEMENT") &&
          retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
          (isAggregateDashboard === undefined || !isAggregateDashboard) &&
          !isAlert &&
          allawedTrackers?.includes(+question?.tracker_id) && (
            <>
              <Divider orientation="vertical" className="reply-divider" />
              {!showSuccess &&
              (question?.system_replies?.is_replied === undefined ||
                question?.system_replies?.is_replied === 0) ? (
                <div
                  className={
                    open
                      ? "display-in-row reply-button reply-button-active"
                      : "display-in-row reply-button"
                  }
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    className="reply-icon"
                  />
                  <span>{CheckValueLocale("reply", "", {}, intl)}</span>
                </div>
              ) : (
                <div
                  className={
                    open
                      ? "display-in-row replied-button replied-button-active"
                      : "display-in-row replied-button"
                  }
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <FontAwesomeIcon
                    icon={faCommentCheck}
                    className="replied-icon"
                  />
                  <span>{CheckValueLocale("replied", "", {}, intl)}</span>
                </div>
              )}
            </>
          )}
        <Popover
          anchorOrigin={anchorPosition}
          transformOrigin={transformPosition}
          onClose={handleClose}
          id={id}
          open={open}
          anchorEl={anchorEl}
          className="reply-modal-popover"
        >
          {!isAlert ? (
            <ReplyModal
              question={question}
              setShowSuccessModal={setShowSuccess}
              showSuccessModal={showSuccess}
              index={index}
              replyModalData={replyModalData}
              monitorDataSourceType={monitorDataSource}
              setShowErrorSnackBar={setShowErrorSnackBar}
              setAnchorEl={setAnchorEl}
              setText={setText}
              text={text}
            />
          ) : null}
        </Popover>
      </Box>

      {openSnackBar && (
        <SnackBar
          open={openSnackBar}
          autoHideDuration={5000}
          severity={snackBarData.severity}
          message={CheckValueLocale(
            snackBarData.message,
            "failed_error",
            {},
            intl,
          )}
          title={CheckValueLocale(snackBarData.title, "", {}, intl)}
          handleClose={handleCloseSnack}
        />
      )}
      {showErrorSnackBar && (
        <SnackBar
          open={showErrorSnackBar}
          autoHideDuration={5000}
          severity="error"
          message={CheckValueLocale("please_try_again", "", {}, intl)}
          title={CheckValueLocale("wrong_request_parameter", "", {}, intl)}
          handleClose={handleCloseRepliedSnack}
        />
      )}

      {/* Delete Modal Popup */}
      <PopupModal
        title={CheckValueLocale("delete_post", "", {}, intl)}
        body={getModelDeletePostdBody()}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete", "", {}, intl)}
        open={openDeleteModalStats?.openDeleteModal}
        close={handleCloseDeleteModal}
        accept={() => handleDeletePost(question?.tweet_id)}
        rightColor="error"
        disabled={disabledDeleteBtn}
        warning
        minWidth="600px"
        addClasses="delete-engagement-buttons"
      />

      <SnackBar
        open={openSnackBarDelete}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(snackBarDeleteData?.title, "", {}, intl)}
        severity={snackBarDeleteData?.severity}
        message={CheckValueLocale(snackBarDeleteData?.message, "", {}, intl)}
      />
    </Box>
  );
};

export default CardHeader;
