import { useEffect, useState } from "react";
import CircularLoading from "components/circularProgress";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gmail from "../../images/social-icons/gmail.svg";
import {
  faFacebookF,
  faInstagram,
  faIntercom,
  faWhatsapp,
  faLinkedinIn,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
// sub component
import InteractionsListHeader from "./components/InteractionListHeader";
import InteractionsListMessage from "./components/InteractionListMessage";
import InteractionListPrivateMessage from "./components/InteractionListPrivateMessage";
import InteractionsListFooter from "./components/InteractionListFooter";
import GMBInteractionsListHeader from "./components/gmbInteractionListHeader";
import {
  CheckValueLocale,
  extractDisplayInfoFromDataSource,
} from "utils/helpers";
import { useIntl } from "react-intl";
import Genesys from "images/shared-images/genesys-icon.svg";
import publicFacebook from "images/social-icons/facebook-wite.svg";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

//style
import "./InteractionsList.scss";
import InteractionsListMessageGenesys from "./components/InteractionsListMessageGenesys";
import InteractionsListMessageTiktok from "./components/InteractionsListMessageTiktok";

const InteractionsListItem = (props) => {
  const intl = useIntl();
  let { obj } = props;
  const [isLoading, setLoading] = useState(false);
  const [icon, setIcon] = useState("");
  const [audio, setAudio] = useState("");
  const [link, setLink] = useState("");
  const [channelName, setChannelName] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [sub_name, setSubName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [text, setText] = useState("");
  const [attachementType, setAttachementType] = useState("");
  const [attachementLink, setAttachementLink] = useState("");
  const [trackerId, setTrackerId] = useState("");
  const [channelId, setChannelId] = useState("");
  const [objId, setObjId] = useState("");
  const [forwardedMessageFlag, setForwardedMessageFlag] = useState(false);
  const [interactionType, setInteractionType] = useState("type_post");
  const [waitingTime, setWaitingTime] = useState({});
  const [handlingTime, setHandlingTime] = useState({});
  const [status, setStatus] = useState("");
  const [ratings, setRatings] = useState("");
  const [redirection, setRedirection] = useState("");
  const [gmbDatasource, setGmbDatasource] = useState("");
  const [genesysDatasource, setGenesysDatasource] = useState("");
  const [apiVars, setApiVars] = useState("");
  const [isPublicChannel, setIsPublicChannel] = useState(false);
  const [systemReplies, setSystemReplies] = useState({});

  useEffect(() => {
    handleIcons();
    //remove this line later once engine return data dependency of useEffect [props.dataSources]
  }, [props.dataSources]);
  //check datasource is private or not
  useEffect(() => {
    if (obj.channel_type) {
      setInteractionType("type_conversation");
    }
  }, []);
  const handleIcons = () => {
    props.dataSources?.map((i) => {
      let icon,
        link,
        channelName,
        createdBy,
        sub_name,
        text,
        phoneNumber,
        attachementType,
        attachementLink,
        trackerId,
        channelId,
        objId,
        forwardedMessageFlag,
        waitingTime,
        handlingTime,
        status,
        apiVar,
        rating,
        gmbDataSourceName,
        externalLink,
        genesysDataSourceName,
        audio,
        isPublic,
        system_replies;
      if (obj?.channel_id == i.id || obj?.CA_channel_id == i.id) {
        switch (i.source) {
          case "TWITTER":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--twitter">
                <img src={xPlatform} alt="x-platform-logo" />
              </span>
            );
            link =
              obj?.channel_type === "private"
                ? "/interactions/social-media/x-platform-private"
                : "/interactions/social-media/x-platform-public";
            channelName =
              obj?.channel_type === "private"
                ? "x-platform-private"
                : "twitter";
            // Comment for now, we need to replace above code with this when we need to add it for DM pages also
            // channelName = "twitter";
            createdBy = obj?.name ? obj?.name : obj?.user_name;
            sub_name =
              obj?.channel_type === "private"
                ? "@" + obj?.user_name
                : obj?.created_by
                  ? "@" + obj?.created_by
                  : "@" + obj?.screen_name;
            text =
              obj?.channel_type === "private"
                ? obj?.text
                : obj?.tweet_text
                  ? obj?.tweet_text
                  : obj?.text
                    ? obj?.text
                    : "";
            trackerId = obj.tracker_id;
            channelId = obj?.channel_id ? obj?.channel_id : obj?.CA_channel_id;
            objId = obj?.id ? obj?.id : obj?.tweet_id;
            attachementType = "text";
            waitingTime = obj?.waiting_time;
            handlingTime = obj?.handling_time;
            isPublic =
              obj?.channel_type === "private" ||
              window.location.pathname?.includes("audience/user-profile")
                ? false
                : true;
            status =
              obj?.status?.toLowerCase() === "active"
                ? "active_status"
                : obj?.status?.toLowerCase() === "pending"
                  ? "pending_status"
                  : obj?.status;

            apiVar =
              obj?.channel_type === "private"
                ? "social_media/twitter/private"
                : "social_media/twitter/public";
            system_replies = obj?.system_replies;
            break;
          case "FACEBOOK":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--facebook">
                <img src={publicFacebook} />
              </span>
            );
            link =
              obj.channel_type === "private"
                ? "/interactions/social-media/facebook-private"
                : "/interactions/social-media/facebook-public";
            channelName =
              obj?.channel_type === "private" ? "facebook-private" : "facebook";
            // Comment for now, we need to replace above code with this when we need to add it for DM pages also
            // channelName = "facebook";
            createdBy =
              obj?.user_name || obj?.username
                ? `@${obj?.user_name ? obj?.user_name : obj?.username}`
                : obj?.user_name
                  ? obj?.user_name
                  : obj?.username;

            text =
              obj?.channel_type === "private"
                ? obj?.message
                : obj?.tweet_text
                  ? obj?.tweet_text
                  : obj?.message
                    ? obj?.message
                    : "";
            trackerId = obj.tracker_id;
            channelId = obj?.channel_id ? obj?.channel_id : obj?.CA_channel_id;

            objId = obj?.id ? obj?.id : obj?.post_id;
            attachementType = "text";
            waitingTime = obj?.waiting_time;
            handlingTime = obj?.handling_time;
            isPublic =
              obj?.channel_type === "private" ||
              window.location.pathname?.includes("audience/user-profile")
                ? false
                : true;
            status =
              obj?.status?.toLowerCase() === "active"
                ? "active_status"
                : obj?.status?.toLowerCase() === "pending"
                  ? "pending_status"
                  : obj?.status;
            apiVar =
              obj.channel_type === "private"
                ? "social_media/facebook/private"
                : "social_media/facebook/public";
            system_replies = obj?.system_replies;
            break;
          case "INSTAGRAM":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--insta">
                <img src={publicInstagram} />
              </span>
            );
            link =
              obj.channel_type === "private"
                ? "/interactions/social-media/instagram-private"
                : "/interactions/social-media/instagram-public";
            channelName =
              obj?.channel_type === "private"
                ? "instagram-private"
                : "instagram";
            // Comment for now, we need to replace above code with this when we need to add it for DM pages also
            // channelName = "instagram";
            createdBy =
              obj?.user_name || obj?.username
                ? `@${obj?.user_name ? obj?.user_name : obj?.username}`
                : obj?.user_name
                  ? obj?.user_name
                  : obj?.username;
            if (obj?.channel_type !== "private") {
              const { displayName, subName } = extractDisplayInfoFromDataSource(
                { data_source: "instagram", ...obj },
                {
                  displayName: obj?.user_name || obj?.username,
                },
              );
              if (!subName) {
                createdBy = `@${displayName}`;
              } else {
                createdBy = displayName;
                sub_name = `@${subName}`;
              }
            }
            text =
              obj.channel_type === "private"
                ? obj.text
                : obj?.tweet_text
                  ? obj?.tweet_text
                  : obj?.text
                    ? obj?.text
                    : "";
            trackerId = obj.tracker_id;
            channelId = obj?.channel_id ? obj?.channel_id : obj?.CA_channel_id;
            objId = obj?.id ? obj?.id : obj?.tweet_id;
            attachementType = "text";
            waitingTime = obj?.waiting_time;
            handlingTime = obj?.handling_time;
            isPublic =
              obj?.channel_type === "private" ||
              window.location.pathname?.includes("audience/user-profile")
                ? false
                : true;
            status =
              obj?.status?.toLowerCase() === "active"
                ? "active_status"
                : obj?.status?.toLowerCase() === "pending"
                  ? "pending_status"
                  : obj?.status;
            apiVar =
              obj.channel_type === "private"
                ? "social_media/instagram/private"
                : "social_media/instagram/public";
            system_replies = obj?.system_replies;
            break;
          case "INTERCOM":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--intercom">
                <FontAwesomeIcon icon={faIntercom} />
              </span>
            );
            link = `/interactions/chat/intercom`;
            channelName = "intercom";
            createdBy = obj.author_name;
            sub_name = obj.author_email_address;
            text = obj.messages[0].message_body.replace(
              /(<p[^>]+?>|<p>|<\/p>)/gim,
              "",
            );
            trackerId = obj.tracker_id;
            channelId = obj.channel_id;
            objId = obj.id;
            attachementType = "text";
            apiVar = "chat/intercom";
            break;
          case "GMAIL":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--gmail">
                <img src={gmail} alt={"gamil"} />
              </span>
            );
            link = `/interactions/email/gmail`;
            channelName = "gmail";
            createdBy = obj?.sender_name;
            sub_name = obj?.sender_email_address;
            text = obj?.message
              ? obj?.message
                  .replace(/(\r\n)+/g, "\r\n")
                  .replace(/(\n\r)+/g, "\r\n")
                  .replace(/\n+/g, "\n")
                  .replace(/\r+/g, "\r")
                  .trim()
              : obj?.content
                ? obj?.content
                    .replace(/(\r\n)+/g, "\r\n")
                    .replace(/(\n\r)+/g, "\r\n")
                    .replace(/\n+/g, "\n")
                    .replace(/\r+/g, "\r")
                    .trim()
                : "";
            trackerId = obj?.tracker_id;
            channelId = obj?.channel_id;
            attachementType = "text";
            objId = obj?.id;
            apiVar = "email/gmail";
            break;
          case "WHATSAPP":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--Whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} />
              </span>
            );
            link = `/interactions/chat/whatsapp`;
            channelName = "whatsapp";
            createdBy = obj?.customer_phone_number;
            phoneNumber = obj?.customer_phone_number;
            text =
              obj?.message?.text === "" &&
              obj?.message?.attachement?.type === "text"
                ? CheckValueLocale("whatsapp_sticker", "", {}, intl)
                : obj?.message?.text;
            attachementType = obj?.message?.attachement?.type;
            attachementLink = obj?.message?.attachement?.link;
            trackerId = obj.tracker_id;
            channelId = obj.channel_id;
            objId = obj.id;
            forwardedMessageFlag = obj.message.forwarded_message;
            apiVar = "chat/whatsapp";
            break;
          case "GOOGLEMYBUSINESS":
            link = `/interactions/ratings/google-my-business`;
            channelName = "gmb";
            icon = obj.profilePhotoUrl;
            sub_name = obj.sender_email_address;
            const messageType = typeof obj.message;
            if (messageType === "string") {
              text = obj.message
                .replace(/(\r\n)+/g, "\r\n")
                .replace(/(\n\r)+/g, "\r\n")
                .replace(/\n+/g, "\n")
                .replace(/\r+/g, "\r")
                .trim();
            } else {
              text = "";
            }
            trackerId = obj.tracker_id;
            channelId = obj.channel_id;
            rating = obj.rating;
            attachementType = "text";
            objId = obj.id;
            externalLink = obj.redirection_link;
            apiVar = "ratings/gmb";
            gmbDataSourceName = "googlemybusiness";
            break;
          case "LINKEDIN":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--linkedin">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </span>
            );
            link = `/interactions/social-media/linkedin-channel`;
            channelName = "linkedIn";
            text = obj.text;
            createdBy = obj.created_by;
            trackerId = obj.tracker_id;
            channelId = obj.channel_id;
            objId = obj.id;
            attachementType = "text";
            apiVar =
              obj.channel_type === "private"
                ? "social_media/linkedin/private"
                : "social_media/linkedin/public";
            break;
          case "TIKTOK":
            var regex = /id=(\d+)/;

            // Use the exec method to extract the video ID
            var match = regex.exec(obj?.embed_link);

            // Check if there is a match and extract the video ID
            if (match && match[1]) {
              var videoId = match[1];
              obj.videoId = videoId;
            }
            if (obj?.type == "comment") {
              setInteractionType("type_comment");
            } else if (obj?.type == "reply") {
              setInteractionType("type_reply");
            } else {
              setInteractionType("type_post");
            }
            icon = (
              <span className="interaction-list__icon interaction-list__icon--tiktok">
                <FontAwesomeIcon icon={faTiktok} />
              </span>
            );
            link = `/interactions/social-media/tikTok-channel`;
            channelName = "tiktok";
            text = obj?.text;
            createdBy = obj?.created_by;
            trackerId = obj?.tracker_id;
            channelId = obj?.channel_id;
            objId = obj?.id;
            attachementType = "text";
            apiVar = "social_media/tiktok/public";
            break;

          case "GENESYS":
            icon = (
              <span className="interaction-list__icon interaction-list__icon--genesys">
                <img
                  src={Genesys}
                  alt={"Genesys"}
                  className="genesys-interactions-icon"
                />
              </span>
            );
            link = `/interactions/calls/genesys`;
            channelName = "genesys";
            phoneNumber = obj?.created_by;
            text = obj?.text;
            createdBy = obj?.cdp_name ? obj?.cdp_name : "Genesys";
            trackerId = obj?.tracker_id;
            channelId = obj?.channel_id;
            objId = obj.id;
            attachementType = "text";
            apiVar = "calls/genesys";
            genesysDataSourceName = "genesys";
            audio = obj?.audio;
            break;
          default:
            break;
        }
        // These are common parameters among all data sources
        setIcon(icon);
        setLink(link);
        setChannelName(channelName);
        setCreatedBy(createdBy);
        setText(text);
        setTrackerId(trackerId);
        setChannelId(channelId);
        setObjId(objId);
        // This specific for Gmail & intercom & Twitter design
        setSubName(sub_name);
        // This specific for whatsapp design
        setPhoneNumber(phoneNumber);
        setAttachementType(attachementType);
        setAttachementLink(attachementLink);
        setForwardedMessageFlag(forwardedMessageFlag);
        setWaitingTime(waitingTime);
        setHandlingTime(handlingTime);
        setStatus(status);
        setRatings(rating);
        setRedirection(externalLink);
        setIsPublicChannel(isPublic);
        if (gmbDataSourceName !== undefined)
          setGmbDatasource(gmbDataSourceName);
        genesysDataSourceName !== undefined &&
          setGenesysDatasource(genesysDataSourceName);
        setApiVars(apiVar);
        setAudio(audio);
        setSystemReplies(system_replies);
      }
    });
  };

  obj.previousVarApis = apiVars;
  obj.isPublicChannel = isPublicChannel;

  {
    /* {note: we prevent make editing sentiment themes sub-themes until BE fixed it} */
  }
  let hideEditSentimentThemes =
    channelName === "genesys" ||
    channelName === "intercom" ||
    channelName === "gmail";

  return (
    <>
      {isLoading === true ? (
        <CircularLoading />
      ) : (
        <Box
          className={
            props.isInteractionPage
              ? "interaction-page-list"
              : "interaction-list latest-interaction-page"
          }
          component="div"
        >
          {props.component === "google_my_business" || gmbDatasource !== "" ? (
            <GMBInteractionsListHeader
              sub_name={sub_name}
              icon={icon}
              tracker_id={trackerId}
              link={link}
              id={objId}
              channel_id={channelId}
              rating={ratings}
              component={props.component}
              text={text}
            />
          ) : (
            <InteractionsListHeader
              unixDate={props.unixDate}
              showProfile={props.showProfile}
              icon={icon}
              obj={obj}
              createdBy={createdBy}
              channelName={channelName}
              phoneNumber={phoneNumber}
              sub_name={sub_name}
              tracker_id={trackerId}
              link={link}
              id={objId}
              channel_id={channelId}
              channelType={obj.channel_type}
              interactionType={interactionType}
              isAudiencePage={props?.isAudiencePage}
              audio={audio}
              missedCalls={obj?.missed_call}
              systemReplies={systemReplies}
              allawedTrackers={props?.allawedTrackers}
            />
          )}
          {channelName == "tiktok" ? (
            <InteractionsListMessageTiktok obj={obj} />
          ) : genesysDatasource === "genesys" ? (
            <InteractionsListMessageGenesys
              audio={audio}
              objId={objId}
              obj={obj}
            />
          ) : obj.channel_type == "private" ? (
            //  check is private latest interactions or not
            <InteractionListPrivateMessage
              waitingTime={waitingTime}
              handlingTime={handlingTime}
              status={status}
            />
          ) : (
            <InteractionsListMessage
              id={objId}
              channel_id={channelId}
              tracker_id={trackerId}
              channelName={channelName}
              link={link}
              text={text}
              attachementType={attachementType}
              attachementLink={attachementLink}
              isInteractionPage={props.isInteractionPage}
              obj={props.obj}
              forwardedMessageFlag={forwardedMessageFlag}
              redirection={redirection}
              component={props.component}
              rating={ratings}
            />
          )}
          {!obj?.missed_call || channelName !== "genesys" ? (
            <InteractionsListFooter
              obj={obj}
              setLoading={setLoading}
              apiVars={apiVars}
              component={props.component}
              text={text}
              className={hideEditSentimentThemes && "hide-sentiment-themes"}
              channelName={channelName}
            />
          ) : null}
        </Box>
      )}
    </>
  );
};

export default InteractionsListItem;
