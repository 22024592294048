import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useIntl } from "react-intl";
import "./tableWidget.scss";
import ItemsList from "./itemsList.js";
import WidgetHeader from "../widgetHeader";
import WordsList from "./wordsList";
import { TableFooter, TablePagination } from "@mui/material";
import { Box } from "@mui/system";
// import NoDataFound from "components/no-Data/noDataFound";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import NoDataFound from "components/no-Data/noDataFound";
import ChartDetailsWidget from "../customStatisctics/chartDetailsWidget";

const TableWidget = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const { monitorDataSource, activeTab } = urlParams;
  const [page, setPage] = useState(0);
  const [dataForChart, setDataForChart] = useState(props.data);

  //handle sorted data
  const handelSortingData = () => {
    if (props?.isSorted && props?.data.length !== 0) {
      props?.data?.sort((a, b) => b.value - a.value);
    }
  };
  // _______________________________________________________
  useEffect(() => {
    handelSortingData();
    getChartMeta(props.data);
  }, [props.data]);
  // _______________________________________________________

  const getChartMeta = (data) => {
    setDataForChart(data);
  };

  // _______________________________________________________
  const rowsPerPage = props?.isVerifiedContacts ? 3 : 5;
  const calculatedRows = props.data?.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage,
  );

  // _______________________________________________________

  const handleChangePage = (event, newPage) => {
    const calculatedRowsNewPage = props.data?.slice(
      newPage * rowsPerPage,
      (newPage + 1) * rowsPerPage,
    );
    if (!calculatedRowsNewPage?.length && props?.setGlobalPage) {
      props?.setGlobalPage?.(newPage + 1);
    }
    setPage(newPage);
  };

  return (
    <div
      className={props.reduceHeight ? "chart" : "chart table-widget"}
      id={props.title}
    >
      <WidgetHeader
        title={props.title}
        showDownloadIcon={props.showDownloadIcon}
        chartId={props.title}
        data={props.data}
        getChartMeta={getChartMeta}
        iconAfterTitle={props.iconAfterTitle}
        titleToolTip={props.titleToolTip}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard}
        monitorData={props?.monitorData}
      />
      {_.isEqual(
        props.tableChartPreLoaderDataSources?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : isEmptyArray(props.data) ? (
        <NoDataFound />
      ) : (
        <>
          <TableContainer>
            {props.showChartDetailsWidget && (
              <ChartDetailsWidget statsDetailsData={props.statsDetailsData} />
            )}
            <Table className="tablemargin">
              <TableHead className="border-none">
                <TableRow>
                  <TableCell
                    className={`ar-postuser-heading1 ${
                      props.wordsList && "table-cell-keyword"
                    }`}
                  >
                    {CheckValueLocale(props.leftColomnTitle, "", {}, intl)}
                  </TableCell>
                  <TableCell
                    className={`ar-postuser-heading1 ${
                      props.wordsList && "table-cell-keyword"
                    }`}
                  >
                    {CheckValueLocale(props.rightColomnTitle, "", {}, intl)}
                  </TableCell>
                  {props.thirdColomnTitle ? (
                    <TableCell
                      className={`ar-postuser-heading1 ${
                        props.wordsList && "table-cell-keyword"
                      }`}
                    >
                      {CheckValueLocale(props.thirdColomnTitle, "", {}, intl)}
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              {/* Case of Items List that include avatar+name+username____________________________________________ */}
              {props.itemsList && (
                <TableBody>
                  {calculatedRows?.map((row, index) => (
                    <>
                      <ItemsList
                        unixDate={props.unixDate}
                        showProfile={props.showProfile}
                        monitorId={props.monitorId}
                        productId={props.productId}
                        circularPP={props.circularPP}
                        linkedInPage={props.linkedInPage}
                        key={index}
                        totalValue={
                          row.value !== undefined ? row.value : row.stats_count
                        }
                        isEngagInbox={props?.isEngagInbox}
                        row={row}
                        isTiktok={props?.isTiktok}
                        iconBeforeItemTitle={props.iconBeforeItemTitle}
                        monitorDataSource={monitorDataSource}
                      />
                    </>
                  ))}
                </TableBody>
              )}
              {/* Case of words list which includes words _________________________________________________________ */}
              {props.wordsList && (
                <TableBody>
                  {calculatedRows?.map((row) => (
                    <WordsList
                      row={row}
                      activeTab={activeTab}
                      monitorDataSource={monitorDataSource}
                      showAtIcon={props.showAtIcon} // showAtIcon is to display '@' icon
                      socialKeywordName={props.socialKeywordName} // to handle name of keyword in social --> CA
                      //this props used for handle QuickInspect
                      handleOpenQuickInspect={props.handleOpenQuickInspect}
                      inspectorName={props.inspectorName}
                      dynamicClass={props.dynamicClass}
                      dataSource={props.dataSource}
                      iconBeforeItemTitle={props?.iconBeforeItemTitle}
                      isGensys={props?.isGensys}
                      isCustomDashboard={props?.isCustomDashboard}
                      links={props?.links}
                    />
                  ))}
                </TableBody>
              )}
              {/* _________________________________________________________ */}

              {props.tablePagination && props.count !== 0 && (
                <TableFooter className="pagination-table-style">
                  <TableRow>
                    <TablePagination
                      className="intl1"
                      count={props.count}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={props?.isVerifiedContacts ? 3 : 5}
                      rowsPerPageOptions={false}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};
export default TableWidget;
