import { useState } from "react";
import "../../../Monitors/Components/replyModal/replyModal.scss";
import Reply from "./reply";

const ReplyModal = (props) => {
  const {
    setSelectedCardIteractionID,
    replyData,
    showSuccessModal,
    setIsReplied,
    selectedCard,
    postData,
    setPostData,
    setOpenDetailsSideBar,
    setOpenDetailsSideBarReplay,
    setOpenSlaSideBar,
    checkAiSuggestions,
    setCheckAiSuggestions,
    text,
    setText,
    selectedValue,
    setSelectedValue,
    setOpenSidebar,
    translationActive,
    setTranslationActive,
    translationSource,
    setTranslationSource,
    translationTarget,
    setTranslationTarget,
    stats,
    setStats,
    languagesArraySource,
    languagesArrayTarget,
    translateStatus,
    setTranslateStatus,
    headerData,
    openSidebar,
    openDetailsSideBarReplay,
    isSidebarsOpened,
    showAllIcon,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [limitRateDate, setLimitRateDate] = useState("");
  const [snackBarSeverity, setSnackbarSeverity] = useState("");
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [snackBarTitle, setSnackbarTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);
  const [base64Code, setBase64Code] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [mediaFile, setMediaFile] = useState([]);
  const [mediaLoader, setMediaLoader] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedAccountImg, setSelectedAccountImg] = useState("");

  return (
    <Reply
      replyType="public"
      replyData={replyData}
      setSelectedCardIteractionID={setSelectedCardIteractionID}
      showSuccessModal={showSuccessModal}
      selectedCard={selectedCard}
      limitRateDate={limitRateDate}
      setLimitRateDate={setLimitRateDate}
      showSnackBar={showSnackBar}
      setShowSnackBar={setShowSnackBar}
      snackBarSeverity={snackBarSeverity}
      setSnackbarSeverity={setSnackbarSeverity}
      snackBarMessage={snackBarMessage}
      setSnackbarMessage={setSnackbarMessage}
      snackBarTitle={snackBarTitle}
      setSnackbarTitle={setSnackbarTitle}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      base64Code={base64Code}
      setBase64Code={setBase64Code}
      attachments={attachments}
      setAttachments={setAttachments}
      text={text}
      setText={setText}
      mediaFile={mediaFile}
      setMediaFile={setMediaFile}
      mediaLoader={mediaLoader}
      setMediaLoader={setMediaLoader}
      btnLoading={btnLoading}
      setBtnLoading={setBtnLoading}
      selectedAccountImg={selectedAccountImg}
      setSelectedAccountImg={setSelectedAccountImg}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      loading={loading}
      setLoading={setLoading}
      postData={postData}
      setPostData={setPostData}
      setIsReplied={setIsReplied}
      setOpenDetailsSideBar={setOpenDetailsSideBar}
      setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
      setOpenSlaSideBar={setOpenSlaSideBar}
      checkAiSuggestions={checkAiSuggestions}
      setCheckAiSuggestions={setCheckAiSuggestions}
      setOpenSidebar={setOpenSidebar}
      translationActive={translationActive}
      setTranslationActive={setTranslationActive}
      translationSource={translationSource}
      setTranslationSource={setTranslationSource}
      translationTarget={translationTarget}
      setTranslationTarget={setTranslationTarget}
      stats={stats}
      setStats={setStats}
      languagesArraySource={languagesArraySource}
      languagesArrayTarget={languagesArrayTarget}
      translateStatus={translateStatus}
      setTranslateStatus={setTranslateStatus}
      headerData={headerData}
      openSidebar={openSidebar}
      openDetailsSideBarReplay={openDetailsSideBarReplay}
      isSidebarsOpened={isSidebarsOpened}
      showAllIcon={showAllIcon}
    />
  );
};

export default ReplyModal;
