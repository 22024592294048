import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Container, Box } from "@mui/material";
import { isEmptyValue, retrieveActiveProductsArray } from "utils/helpers";
import { useParams } from "react-router-dom";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers/index";

import InteractionsController from "services/controllers/interactionsController";

import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu.js";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faShare } from "@fortawesome/free-solid-svg-icons";

import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import Luci from "pages/luci";
import SnackBar from "components/snackBar.js";
import ReplyModal from "../../../../../replyModal/replyModal";
import FacebookLikeIcon from "images/interactions-details/facebook-like.svg";
import FacebookLoveIcon from "images/interactions-details/facebook-love.svg";
import FacebookHahaIcon from "images/interactions-details/facebook-haha.svg";
import FacebookWowIcon from "images/interactions-details/facebook-wow.svg";
import FacebookSadIcon from "images/interactions-details/facebook-sad.svg";
import FacebookAngryIcon from "images/interactions-details/facebook-angry.svg";
import InteractionFooter from "../../../common/InteractionFooter";
import InteractionContentRenderer from "../../../common/InteractionContentRenderer";
import InteractionBrand from "../../../common/InteractionBrand";
import FacebookLogo from "images/social-icons/facebook-wite.svg";
import FacebookCareIcon from "images/interactions-details/facebook-care.svg";
import "../../../common/interactionCommon.scss";
import MonitorsController from "services/controllers/monitorsController";

const getFaceboookPostMetrics = (conversation) => {
  const reactions = conversation?.reactions || {};
  const filteredReaction = [
    {
      name: "like",
      value: reactions?.like || 0,
      icon: <img src={FacebookLikeIcon} alt="like" />,
    },
    {
      name: "haha",
      value: reactions?.haha || 0,
      icon: <img src={FacebookHahaIcon} alt="haha" />,
    },
    {
      name: "care",
      value: reactions?.care || 0,
      icon: <img src={FacebookCareIcon} alt="love" />,
    },
    {
      name: "love",
      value: reactions?.love || 0,
      icon: <img src={FacebookLoveIcon} alt="love" />,
    },
    {
      name: "wow",
      value: reactions?.wow || 0,
      icon: <img src={FacebookWowIcon} alt="wow" />,
    },
    {
      name: "sad",
      value: reactions?.sad || 0,
      icon: <img src={FacebookSadIcon} alt="sad" />,
    },
    {
      name: "angry",
      value: reactions?.angry || 0,
      icon: <img src={FacebookAngryIcon} alt="angry" />,
    },
  ]
    .filter((item) => item?.value > 0)
    .sort((a, b) => b?.value - a?.value);

  if (filteredReaction?.length > 0) {
    filteredReaction?.push({
      name: "divider",
    });
  }
  filteredReaction?.push(
    {
      name: "comments",
      value: conversation?.total_comments || 0,
      icon: <FontAwesomeIcon icon={faComment} />,
    },
    {
      name: "shares",
      value: conversation?.shares_number || 0,
      icon: <FontAwesomeIcon icon={faShare} />,
    },
  );
  return filteredReaction;
};

const SingleFacebookInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;
  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  const [text, setText] = useState("");
  const [allawedTrackers, setAllawedTrackers] = useState([]);
  const [replyModalData, setReplyModalData] = useState([
    {
      selectedValue: "",
      replyTime: "",
    },
  ]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  let apiVars = "social_media/facebook/public";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true" ? `/facebook-channel/${dataSourceId}` : ""
      }`,
    },
    {
      name: "facebook",
      url: "insights/social-media/facebook-channel/" + dataSourceId,
    },
  ];

  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
    ).then((result) => {
      if (result !== undefined && !isEmptyValue(result?.data)) {
        if (
          handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
          retrieveActiveProductsArray()?.includes("ENGAGEMENTS")
        ) {
          MonitorsController.getAllawedMonitors(
            window?.localStorage?.cxm_id,
          ).then((AllawedData) => {
            if (AllawedData?.data?.data?.monitors_list?.length) {
              setAllawedTrackers([...AllawedData?.data?.data?.monitors_list]);
            }
          });
        }
        setConversation(result?.data);
      }
    });
  }, []);

  const handleCloseRepliedSnack = () => {
    setShowErrorSnackBar(false);
  };
  const interactionMetrics = getFaceboookPostMetrics(conversation);
  return (
    <>
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyValue(conversation) ? (
          <CircularLoading />
        ) : (
          <Box className="single-interaction-container">
            <SingleInteractionHeader links={links} dataSource="facebook" />
            <Box className="single-intr">
              <Box className="single-intr-wrapper">
                <InteractionBrand
                  interactionType={conversation?.interaction_type}
                  title={CheckValueLocale("facebook", "", {}, intl)}
                  brand={<img src={FacebookLogo} alt="facebook" />}
                />
                <Box className="interactions-themes-wrapper">
                  <ThemesMenu
                    data={conversation}
                    themes={conversation?.themes}
                    subThemes={conversation?.sub_themes}
                    interactionId={interactionId}
                    trackerId={trackerId}
                    createdAt={conversation?.created_at}
                    channelId={conversation?.channel_id}
                    commentId={conversation?.id}
                    apiVars={apiVars}
                  />
                  <Box className="vertical-line"></Box>
                  <SentimentDropdown
                    sentiment={conversation?.sentiment}
                    data={conversation}
                    interactionId={interactionId}
                    trackerId={trackerId}
                    createdAt={conversation?.created_at}
                    channelId={conversation?.channel_id}
                    commentId={conversation?.id}
                    apiVars={apiVars}
                    useOutlineIcons
                  />
                </Box>
              </Box>
              <Box className="single-intr__body">
                <InteractionContentRenderer
                  content={conversation?.message}
                  username={conversation?.username}
                  interactionURL={conversation?.interaction_url}
                  interactionId={interactionId}
                  trackerId={trackerId}
                  dataSourceId={dataSourceId}
                  interactionType={conversation?.interaction_type}
                  dataSourceName={conversation?.data_source}
                  isEngage={
                    handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
                    retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
                    allawedTrackers?.includes(+trackerId)
                  }
                  createdAt={conversation?.created_at}
                />
                <InteractionFooter
                  interactionMetrics={interactionMetrics}
                  createdAt={conversation?.created_at}
                />
              </Box>
              {handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
              retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
              allawedTrackers?.includes(+trackerId) ? (
                <ReplyModal
                  replyData={conversation}
                  setText={setText}
                  text={text}
                  replyModalData={replyModalData}
                  setReplyModalData={setReplyModalData}
                  setShowSuccessModal={setShowSuccess}
                  showSuccessModal={showSuccess}
                  setShowErrorSnackBar={setShowErrorSnackBar}
                />
              ) : null}
            </Box>
          </Box>
        )}
        {showErrorSnackBar ? (
          <SnackBar
            open={showErrorSnackBar}
            autoHideDuration={5000}
            severity="error"
            message={CheckValueLocale("please_try_again", "", {}, intl)}
            title={CheckValueLocale("wrong_request_parameter", "", {}, intl)}
            handleClose={handleCloseRepliedSnack}
          />
        ) : null}
      </Container>
    </>
  );
};

export default SingleFacebookInteraction;
