import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import enSampleImg from "images/en-branding-chart-sample.svg";
import arSampleImg from "images/ar-branding-chart-sample.svg";

const BrandingPreviewModal = (props) => {
  const { companyBranding, fonts, previewOpened, setPreviewOpened } = props;
  const intl = useIntl();
  const [fontName, setFontName] = useState("");

  useEffect(() => {
    if (companyBranding && fonts) {
      fonts?.map((font) => {
        if (font?.id == companyBranding?.font_type_id)
          setFontName(font?.attributes?.font_name);
      });
    }
  }, [companyBranding, fonts]);

  const colorsData = [
    { name: "preview_primary_color", color: companyBranding?.primary_color },
    {
      name: "preview_secondary_color",
      color: companyBranding?.secondary_color,
    },
    {
      name: "preview_tertiary_color",
      color: companyBranding?.selected_tertiary_color,
    },
  ];

  const staticData = {
    header: { title: "posts_interactions", date: "date_range" },
    subHeader: [
      { num: 50, desc: "max_interaction" },
      { num: 78, desc: "min_interaction" },
      { num: 22, desc: "avg_interaction_per_day" },
    ],
  };

  const handleCloseModal = () => {
    setPreviewOpened(false);
  };

  return (
    <Dialog
      id="company-branding-dialog"
      open={previewOpened}
      onClose={handleCloseModal}
    >
      <DialogContent className="content">
        <Box className="slide-container">
          <Box
            className="slide"
            style={{ background: companyBranding?.selected_tertiary_color }}
          >
            <Box
              className="header"
              sx={{ fontFamily: `${fontName} !important` }}
            >
              <Box className="logo">
                <img
                  className={companyBranding?.logo?.length ? "" : "no-logo"}
                  src={companyBranding?.logo}
                  alt="company-logo"
                />
              </Box>
              <Typography
                variant="h2"
                className="title"
                style={{ color: companyBranding?.primary_color }}
              >
                {CheckValueLocale(staticData?.header?.title, "", {}, intl)}
              </Typography>
              <Typography variant="p" className="date">
                {`(${CheckValueLocale(staticData?.header?.date, "", {}, intl)})`}
              </Typography>
            </Box>
            <Box className="sub-header">
              {staticData?.subHeader?.map((item) => {
                return (
                  <Box
                    className="sub-header-item"
                    key={item?.num}
                    sx={{ fontFamily: `${fontName} !important` }}
                  >
                    <Typography
                      variant="h3"
                      className="number"
                      style={{
                        color: companyBranding?.secondary_color
                          ? companyBranding?.secondary_color
                          : "#000000",
                      }}
                    >
                      {item?.num}
                    </Typography>
                    <Typography variant="p" className="description">
                      {CheckValueLocale(item?.desc, "", {}, intl)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <img
              className="slide-chart"
              src={intl?.locale == "ar" ? arSampleImg : enSampleImg}
              alt=""
            />
          </Box>
        </Box>
        <Grid container className="text-content">
          <Grid item xs={6}>
            <Typography variant="h6" className="title">
              {CheckValueLocale("brand_identity", "", {}, intl)}
            </Typography>
            <Typography variant="p" className="font">
              {CheckValueLocale("branding_font", "", {}, intl)}: {fontName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {colorsData?.map((item, index) => {
              return (
                <Box key={index} className="color-container">
                  <Box
                    component={"span"}
                    className="color-rectangle"
                    style={{ background: item?.color }}
                  ></Box>
                  {CheckValueLocale(item?.name, "", {}, intl)}
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="footer">
        <LucButton
          id="company-branding-preview-close-btn"
          onClick={handleCloseModal}
        >
          {CheckValueLocale("close", "", {}, intl)}
        </LucButton>
      </DialogActions>
    </Dialog>
  );
};

export default BrandingPreviewModal;
