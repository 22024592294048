import { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import "../../../../shared/createHeader/createHeader.scss";
import Header from "../../../../shared/createHeader/createHeader";
import Steppers from "../../../../shared/steppers/steppers";
import MonitorType from "./Components/CreateMonitorsType/createMonitorType";
import StepperButtons from "../../../../shared/steppers/stepperButtons";
import ManagedAccount from "./MonitorTypes/ManagedAccount/managedAccount";
import KeywordMonitor from "./MonitorTypes/KeywordsMonitor/keywordMonitor";
import PublicAccount from "./MonitorTypes/PublicAccount/publicAccount";
import CommonFuntions from "./createCommonFunctions/createMonitorCommonFunctions";
import SharedFunctions from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctions";
import CircularLoading from "components/circularProgress";
import {
  getDatasourcesId,
  getMonitorTypesName,
} from "../MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import MonitorsController from "services/controllers/monitorsController";
import TopicsFunctions from "../MonitorsShared/SharedComponent/manualTopics/components/topicsFunctions";
import { useSelector } from "react-redux";
import SummaryWidgetController from "services/controllers/summaryWidgetController";

const CreateMonitor = () => {
  const {
    setMonitorData,
    monitorData,
    setMonitorErrors,
    monitorErrors,
    submitCreateMonitor,
    handleCreateMonitorBtn,
    createMonitorBtn,
    setCreateMonitorBtn,
    steps,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    selectedMonitorType,
    setSelectedMonitorType,
    handleNext,
    getCompanySources,
    companyAllowedSources,
    isLoading,
    getCompanyMonitorTypes,
    companyMonitorTypes,
    setActiveDataSourceId,
    reverseMonitorTypes,
    errorMsgMonitor,
    setErrorMsgMonitor,
    allSocialAccounts,
    setAllSocialAccounts,
    activeDataSourceId,
    isClikedIg,
    setIsClikedIg,
    isClikedFb,
    setIsClikedFb,
    authorizedAccounts,
    setAuthorizedAccounts,
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    facebookPages,
    setFacebookPages,
    getFacebookPages,
    isLoadingSelectList,
  } = CommonFuntions();
  const { selectedDatasources } = useSelector(
    (state) => state?.explorePageSlice,
  );
  useEffect(() => {
    getCompanySources();
    getCompanyMonitorTypes();
  }, []);

  const [checked, setChecked] = useState(
    selectedDatasources?.length >= 2 ? [...selectedDatasources] : [0],
  );
  const [arrowChecked, setArrowChecked] = useState([0]);
  let dataSourcesId = getDatasourcesId(companyAllowedSources);
  let monitorTypes = getMonitorTypesName(companyMonitorTypes);
  const {
    keywords,
    handleKeywords,
    setKeywords,
    answer,
    setAnswer,
    collectTweets,
    setCollecttweets,
    setSpamAnswer,
    spamAnswer,
    ignoreTweets,
    setIgnoretweets,
    sQuestion,
    setSQuestion,
    handleDelete,
    copyValue,
    setCopyValue,
    handleValidation,
    handleData,
    newsBlogCount,
    wordCountNews,
    wordCountFacebook,
    wordCountInstagram,
    wordCountNewsSpamKeyword,
    language,
    setLanguage,
    countries,
    setCountries,
    majorSource,
    setMajorSource,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
    nbExcludedSource,
    setNbExcludedSource,
    setSelectedSource,
    selectedSource,
    titleSearchKeyword,
    setTitleSearchKeyword,
    titleSearchKeywordError,
    setTitleSearchKeywordError,
    nbExcludedSourceError,
    setNbExcludedSourceError,
    setWordCountInstagram,
    setnewsBlogCount,
    setWordCountFacebook,
    setWordCountNews,
    twitterCountries,
    setTwitterCountries,
    twitterLanguages,
    setTiwtterLanguages,
    twitterWordsCounter,
    setTwitterWordsCounter,
    selectedFacebookPages,
    setSelectedFacebookPages,
    twitterKeywordCounter,
    setTwitterKeywordCounter,
    monitorInstagramOldHashtags,
    setMonitorInstagramOldHashtags,
  } = SharedFunctions({
    selectedMonitorType,
    isTwitterOpen: checked?.includes("twitter"),
  });
  const { handleMonitorTopicsUsage, manualTopicStats, disabledManualTopic } =
    TopicsFunctions();

  useEffect(() => {
    handleMonitorTopicsUsage();
  }, []);

  const [monitorTwitterKeywordLimit, setMonitorTwitterKeywordLimit] =
    useState(0);
  const [monitorTwitterOtherFieldLimit, setMonitorTwitterOtherFieldLimit] =
    useState(0);
  const [monitorInstagramHashtagLimit, setMonitorInstagramHashtagLimit] =
    useState(0);
  const [monitorFaceBookKeywordLimit, setMonitorFaceBookKeywordLimit] =
    useState(0);
  const [monitorFaceBookOtherFieldLimit, setMonitorFaceBookOtherFieldLimit] =
    useState(0);
  const [summarySmTokens, setSummarySmTokens] = useState({});
  const [autoSummaryWidgetPackage, setAutoSummaryWidgetPackage] =
    useState(false);

  const getKeywordsLimit = () => {
    MonitorsController?.getKeywordsLimit()?.then((res) => {
      if (res?.data) {
        const twitterlimits = res?.data?.find(
          (ob) => ob?.data_source_name === "TWITTER",
        );
        setMonitorTwitterKeywordLimit(twitterlimits?.keywords || 0);
        setMonitorTwitterOtherFieldLimit(twitterlimits?.keywords_filters || 0);

        const FaceBookLimits = res?.data?.find(
          (ob) => ob?.data_source_name === "FACEBOOK",
        );
        setMonitorFaceBookKeywordLimit(FaceBookLimits?.keywords || 0);
        setMonitorFaceBookOtherFieldLimit(
          FaceBookLimits?.keywords_filters || 0,
        );
      }
    });
  };
  const getHashtagsLimit = () => {
    let queryData = {
      product_id: window?.localStorage?.sm_id,
    };
    MonitorsController?.getHashtagsLimit(queryData)?.then((res) => {
      if (res?.data) {
        setMonitorInstagramHashtagLimit(
          res?.data?.maximum_hashtags - res?.data?.used_hashtags || 0,
        );
        setMonitorInstagramOldHashtags(res?.data?.old_hashtags || []);
      }
    });
  };
  const getSummarySmTokens = () => {
    SummaryWidgetController.getSummarySmTokens().then((result) => {
      if (result?.data) {
        setSummarySmTokens(result?.data);
        setAutoSummaryWidgetPackage(result?.data?.auto_summary_widget_package);
      }
    });
  };

  useEffect(() => {
    getKeywordsLimit();
    getHashtagsLimit();
    getSummarySmTokens();
  }, []);

  const limitCount =
    selectedMonitorType === "KEYWORD" ? monitorTwitterOtherFieldLimit : 100;

  useEffect(() => {
    if (window.location.href?.endsWith("/step_2"))
      window.location.href?.replace("/step_2", "/step_1");
    else if (
      window.location.href?.endsWith("/create_monitor") ||
      window.location.href?.endsWith("/create_monitor/")
    )
      window.history?.pushState(
        {},
        "",
        window.location.href?.replace(
          "/create_monitor",
          "/create_monitor/step_1",
        ),
      );
  }, []);

  useEffect(() => {
    if (activeStep == 0) {
      window.history.pushState(
        {},
        "",
        window.location.href.replace("/step_2", "/step_1"),
      );
    } else if (activeStep == 1) {
      window.history.pushState(
        {},
        "",
        window.location.href.replace("/step_1", "/step_2"),
      );
    }
  }, [activeStep]);

  return (
    <Container maxWidth="xl">
      {/* ACTIVATE LOADING GIF AFTER ALL BE APIS  */}
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Header
            mainHeaderTitle={"social_listening"}
            subHeaderTitle={"monitors_list"}
            subHeaderLink={"/social/monitor_list"}
            cardHeaderHeading={"create_new_monitor"}
            errorMsg={errorMsgMonitor}
            setErrorMsg={setErrorMsgMonitor}
            isCreateScreen
          />
          <Box className="main-container">
            <Steppers
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              completed={completed}
              setCompleted={setCompleted}
            />

            {activeStep === 1 && selectedMonitorType === "KEYWORD" ? (
              <KeywordMonitor
                setMonitorData={setMonitorData}
                monitorData={monitorData}
                setMonitorErrors={setMonitorErrors}
                monitorErrors={monitorErrors}
                keywords={keywords}
                handleKeywords={handleKeywords}
                setKeywords={setKeywords}
                answer={answer}
                setAnswer={setAnswer}
                collectTweets={collectTweets}
                setCollecttweets={setCollecttweets}
                setSpamAnswer={setSpamAnswer}
                spamAnswer={spamAnswer}
                ignoreTweets={ignoreTweets}
                setIgnoretweets={setIgnoretweets}
                sQuestion={sQuestion}
                setSQuestion={setSQuestion}
                handleDelete={handleDelete}
                copyValue={copyValue}
                setCopyValue={setCopyValue}
                handleValidation={handleValidation}
                setCreateMonitorBtn={setCreateMonitorBtn}
                companyAllowedSources={companyAllowedSources}
                handleData={handleData}
                dataSourcesId={dataSourcesId}
                wordCountNews={wordCountNews}
                wordCountFacebook={wordCountFacebook}
                wordCountInstagram={wordCountInstagram}
                wordCountNewsSpamKeyword={wordCountNewsSpamKeyword}
                setLanguage={setLanguage}
                language={language}
                setCountries={setCountries}
                countries={countries}
                setMajorSource={setMajorSource}
                majorSource={majorSource}
                nbRanks={nbRanks}
                setNbRanks={setNbRanks}
                nbDuplicated={nbDuplicated}
                setNbDuplicated={setNbDuplicated}
                setNbExcludedSource={setNbExcludedSource}
                nbExcludedSource={nbExcludedSource}
                setTitleSearchKeyword={setTitleSearchKeyword}
                titleSearchKeyword={titleSearchKeyword}
                newsBlogCount={newsBlogCount}
                setActiveDataSourceId={setActiveDataSourceId}
                checked={checked}
                setChecked={setChecked}
                arrowChecked={arrowChecked}
                setArrowChecked={setArrowChecked}
                setSelectedSource={setSelectedSource}
                selectedSource={selectedSource}
                setAuthorizedAccounts={setAuthorizedAccounts}
                titleSearchKeywordError={titleSearchKeywordError}
                setTitleSearchKeywordError={setTitleSearchKeywordError}
                nbExcludedSourceError={nbExcludedSourceError}
                setNbExcludedSourceError={setNbExcludedSourceError}
                setWordCountInstagram={setWordCountInstagram}
                setnewsBlogCount={setnewsBlogCount}
                setWordCountFacebook={setWordCountFacebook}
                setWordCountNews={setWordCountNews}
                monitorTopics={monitorTopics}
                setMonitorTopics={setMonitorTopics}
                errorKeywordsMsg={errorKeywordsMsg}
                setErrorKewyordsMsg={setErrorKewyordsMsg}
                errorExKeywordsMsg={errorExKeywordsMsg}
                setExErrorKewyordsMsg={setExErrorKewyordsMsg}
                displayNameMsgError={displayNameMsgError}
                setDisplayNameMsgError={setDisplayNameMsgError}
                disabledManualTopic={disabledManualTopic}
                manualTopicStats={manualTopicStats}
                twitterCountries={twitterCountries}
                setTwitterCountries={setTwitterCountries}
                twitterLanguages={twitterLanguages}
                setTiwtterLanguages={setTiwtterLanguages}
                twitterWordsCounter={twitterWordsCounter}
                setTwitterWordsCounter={setTwitterWordsCounter}
                getTwitterLanguagesAndCountries={
                  getTwitterLanguagesAndCountries
                }
                twitterLanguagesAndCountries={twitterLanguagesAndCountries}
                selectedFacebookPages={selectedFacebookPages}
                setSelectedFacebookPages={setSelectedFacebookPages}
                facebookPages={facebookPages}
                setFacebookPages={setFacebookPages}
                getFacebookPages={getFacebookPages}
                monitorFaceBookKeywordLimit={monitorFaceBookKeywordLimit}
                monitorFaceBookOtherFieldLimit={monitorFaceBookOtherFieldLimit}
                isLoadingSelectList={isLoadingSelectList}
                monitorTwitterOtherFieldLimit={monitorTwitterOtherFieldLimit}
                monitorTwitterKeywordLimit={monitorTwitterKeywordLimit}
                twitterKeywordCounter={twitterKeywordCounter}
                setTwitterKeywordCounter={setTwitterKeywordCounter}
                monitorInstagramHashtagLimit={monitorInstagramHashtagLimit}
                monitorInstagramOldHashtags={monitorInstagramOldHashtags}
                summarySmTokensKeywords={summarySmTokens?.["Keywords"]}
                autoSummaryWidgetPackage={autoSummaryWidgetPackage}
              />
            ) : activeStep === 1 &&
              (selectedMonitorType === "MANAGED_ACCOUNT" ||
                selectedMonitorType === "MANAGED_PAGE") ? (
              <ManagedAccount
                setMonitorData={setMonitorData}
                monitorData={monitorData}
                setMonitorErrors={setMonitorErrors}
                monitorErrors={monitorErrors}
                handleCreateMonitorBtn={handleCreateMonitorBtn}
                companyAllowedSources={companyAllowedSources}
                dataSourcesId={dataSourcesId}
                setActiveDataSourceId={setActiveDataSourceId}
                activeDataSourceId={activeDataSourceId}
                isClikedFb={isClikedFb}
                setIsClikedFb={setIsClikedFb}
                isClikedIg={isClikedIg}
                setIsClikedIg={setIsClikedIg}
                monitorTopics={monitorTopics}
                setMonitorTopics={setMonitorTopics}
                errorKeywordsMsg={errorKeywordsMsg}
                setErrorKewyordsMsg={setErrorKewyordsMsg}
                errorExKeywordsMsg={errorExKeywordsMsg}
                setExErrorKewyordsMsg={setExErrorKewyordsMsg}
                displayNameMsgError={displayNameMsgError}
                setDisplayNameMsgError={setDisplayNameMsgError}
                disabledManualTopic={disabledManualTopic}
                manualTopicStats={manualTopicStats}
                summarySmTokensManagedAccount={
                  summarySmTokens?.["Managed Account"]
                }
                autoSummaryWidgetPackage={autoSummaryWidgetPackage}
              />
            ) : activeStep === 1 &&
              selectedMonitorType === "ACCOUNT_ANALYSIS" ? (
              <PublicAccount
                setMonitorData={setMonitorData}
                monitorData={monitorData}
                setMonitorErrors={setMonitorErrors}
                monitorErrors={monitorErrors}
                handleCreateMonitorBtn={handleCreateMonitorBtn}
                companyAllowedSources={companyAllowedSources}
                dataSourcesId={dataSourcesId}
                setActiveDataSourceId={setActiveDataSourceId}
                setAllSocialAccounts={setAllSocialAccounts}
                authorizedAccounts={authorizedAccounts}
                setAuthorizedAccounts={setAuthorizedAccounts}
                monitorTopics={monitorTopics}
                setMonitorTopics={setMonitorTopics}
                errorKeywordsMsg={errorKeywordsMsg}
                setErrorKewyordsMsg={setErrorKewyordsMsg}
                errorExKeywordsMsg={errorExKeywordsMsg}
                setExErrorKewyordsMsg={setExErrorKewyordsMsg}
                displayNameMsgError={displayNameMsgError}
                setDisplayNameMsgError={setDisplayNameMsgError}
                disabledManualTopic={disabledManualTopic}
                manualTopicStats={manualTopicStats}
                twitterCountries={twitterCountries}
                setTwitterCountries={setTwitterCountries}
                twitterLanguages={twitterLanguages}
                setTiwtterLanguages={setTiwtterLanguages}
                getTwitterLanguagesAndCountries={
                  getTwitterLanguagesAndCountries
                }
                twitterLanguagesAndCountries={twitterLanguagesAndCountries}
                twitterWordsCounter={twitterWordsCounter}
                setTwitterWordsCounter={setTwitterWordsCounter}
                summarySmTokensAccountAnalysis={
                  summarySmTokens?.["Account Analysis"]
                }
                autoSummaryWidgetPackage={autoSummaryWidgetPackage}
              />
            ) : (
              <MonitorType
                setSelectedMonitorType={setSelectedMonitorType}
                selectedMonitorType={selectedMonitorType}
                companyAllowedSources={companyAllowedSources}
                monitorCardsTypes={monitorTypes}
                dataSourcesId={dataSourcesId}
                companyMonitorTypes={companyMonitorTypes}
                reverseMonitorTypes={reverseMonitorTypes}
                setKeywords={setKeywords}
                setMonitorData={setMonitorData}
                setChecked={setChecked}
                setArrowChecked={setArrowChecked}
                setLanguage={setLanguage}
                setCountries={setCountries}
                setMajorSource={setMajorSource}
                nbRanks={nbRanks}
                setNbRanks={setNbRanks}
                nbDuplicated={nbDuplicated}
                setNbDuplicated={setNbDuplicated}
                setNbExcludedSource={setNbExcludedSource}
                setTitleSearchKeyword={setTitleSearchKeyword}
                monitorData={monitorData}
                setSelectedSource={setSelectedSource}
                setMonitorErrors={setMonitorErrors}
                monitorTopics={monitorTopics}
                setMonitorTopics={setMonitorTopics}
                setErrorKewyordsMsg={setErrorKewyordsMsg}
                setExErrorKewyordsMsg={setExErrorKewyordsMsg}
                setDisplayNameMsgError={setDisplayNameMsgError}
                disabledManualTopic={disabledManualTopic}
                manualTopicStats={manualTopicStats}
              />
            )}

            <StepperButtons
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              disableNextStep={
                selectedMonitorType !== "" &&
                monitorTypes.length > 0 &&
                companyAllowedSources.length > 0
                  ? false
                  : true
              }
              handleNext={handleNext}
              monitorData={monitorData}
              submitCreateMonitor={submitCreateMonitor}
              createMonitorBtn={createMonitorBtn}
              keywords={keywords}
              setKeywords={setKeywords}
              companyAllowedSources={companyAllowedSources}
              checked={checked}
              setChecked={setChecked}
              arrowChecked={arrowChecked}
              setArrowChecked={setArrowChecked}
              languages={language}
              sources={majorSource}
              nbRanks={nbRanks}
              setNbRanks={setNbRanks}
              nbDuplicated={nbDuplicated}
              setNbDuplicated={setNbDuplicated}
              countries={countries}
              nbExcludedSource={nbExcludedSource}
              titleSearchKeyword={titleSearchKeyword}
              allSocialAccounts={allSocialAccounts}
              selectedMonitorType={selectedMonitorType}
              monitorTopics={monitorTopics}
              setMonitorTopics={setMonitorTopics}
              errorKeywordsMsg={errorKeywordsMsg}
              errorExKeywordsMsg={errorExKeywordsMsg}
              displayNameMsgError={displayNameMsgError}
              createMonitor
              twitterCountries={twitterCountries}
              setTwitterCountries={setTwitterCountries}
              twitterLanguages={twitterLanguages}
              setTiwtterLanguages={setTiwtterLanguages}
              twitterWordsCounter={twitterWordsCounter}
              setTwitterWordsCounter={setTwitterWordsCounter}
              exceededLimit={
                twitterWordsCounter > limitCount ||
                selectedFacebookPages?.length > monitorFaceBookOtherFieldLimit
              }
              selectedFacebookPages={selectedFacebookPages}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default CreateMonitor;
