import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData } from "../excelCommonFn";

const engagements_nb_Keywords = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var overView = [],
      postsVolume = [],
      sentimentAnalysis = [],
      topEngaged = [],
      topSites = [],
      reachFunnelLegacy = [],
      postsInteractions = [],
      topPosts = [],
      topLanguages = [],
      topCountries = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "overView":
            value?.map((i) => {
              overView.push({
                [lang.overview]: lang[i?.name],
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([overView, lang.overview]);
            break;
          //_______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i.name == value.neutral[index].name) {
                let total =
                  value.neutral[index].value +
                  value.positive[index].value +
                  value.negative[index].value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                  [lang.neutral]:
                    total !== 0
                      ? (value.neutral[index].value / total) * 100 + "%"
                      : 0,
                  [lang.positive]:
                    total !== 0
                      ? (value.positive[index].value / total) * 100 + "%"
                      : 0,
                  [lang.negative]:
                    total !== 0
                      ? (value.negative[index].value / total) * 100 + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([
              sentimentAnalysis,
              lang.posts_sentiment_analysis,
            ]);
            break;
          //_______________________________________________________________________________

          case "postsInteractions":
            value?.map((i) => {
              postsInteractions.push({
                [lang.date]: i.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([postsInteractions, lang.posts_interactions]);
            break;
          //_______________________________________________________________________________

          case "postsVolume":
            value?.map((i) => {
              postsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([postsVolume, lang.posts_volume]);
            break;
          //_______________________________________________________________________________

          case "topLanguages":
            value?.map((i) => {
              topLanguages.push({
                [lang.language]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([topLanguages, lang.top_languages]);
            break;
          //_______________________________________________________________________________

          case "topEngaged":
            value?.map((i) => {
              topEngaged.push({
                [lang.links]: i?.id,
                [lang.nb_posts]: i?.stats_count,
              });
            });
            allSheetData.push([topEngaged, lang.top_engaged_sites]);
            break;
          //_______________________________________________________________________________

          case "topCountries":
            value?.map((i) => {
              topCountries.push({
                [lang.countries]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([topCountries, lang.top_countries]);
            break;

          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData.push([topPosts, lang.top_articles]);
            break;
          //_______________________________________________________________________________
          case "topSites":
            value?.map((i) => {
              topSites.push({
                [lang.links]: i?.id,
                [lang.reach]: i?.stats_count,
              });
            });
            allSheetData.push([topSites, lang.top_sites]);
            break;
          //_______________________________________________________________________________
          case "reachFunnelLegacy":
            value?.map((i) => {
              reachFunnelLegacy.push({
                [lang.posts_reach]: i?.name,
                [lang.number]: i?.value[0],
                [lang.total]: i?.value[0],
              });
            });
            allSheetData.push([reachFunnelLegacy, lang.posts_reach]);
            break;
          //_______________________________________________________________________________

          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { engagements_nb_Keywords };
