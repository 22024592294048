import React, { useEffect, useState } from "react";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";

const CustomDashboardThemesWidget = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "themes":
          setData(dataState?.[widget?.widget_name]?.theme_analysis);
          break;

        case "comments_themes":
          setData(dataState?.["themes"]?.theme_analysis);
          break;

        default:
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  return (
    <ThemesAnalysis
      title={handledWidgetName}
      titleToolTip={titleTooltip || ""}
      data={data || []}
      showDownloadIcon
      bigImg
      themeAnalysisPreLoaderDataSources={
        socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
          monitorId
        ]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ] || []
      }
      preLoaderTrackerDataSources={[monitorId] || []}
      isCustomDashboard
      monitorData={{
        name:
          dashboardInfo?.product === "cxm"
            ? widget?.account_identifier
            : widget?.monitor_name,
        type:
          dashboardInfo?.product === "cxm"
            ? widget?.data_category
            : widget?.monitor_type?.name,
        datasource: widget?.is_dm
          ? `${widget?.data_source}_private`
          : widget?.data_source,
      }}
    />
  );
};

export default CustomDashboardThemesWidget;
