import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useState } from "react";

import { Box, Typography, Button, Tooltip, Zoom, Badge } from "@mui/material";
import ListOptions from "./Options";
import "./list.scss";
import { getSocialIcon } from "utils/helpers";
import SharedTable from "../../../shared/table/sharedTable";
import ErrorIcon from "@mui/icons-material/Error";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "4px 8px",
    fontWeight: "500",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #999",
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const showReportDataSourcesData = (dataSources) => {
  let dataSourcesArray = [];

  dataSources?.map((item) =>
    item?.name === "TWITTER" ||
    item?.name === "FACEBOOK" ||
    item?.name === "INSTAGRAM"
      ? dataSourcesArray?.push(`${item?.name}_${item?.type}`)
      : dataSourcesArray?.push(`${item?.name}`),
  );

  const showDatasources = dataSourcesArray.slice(0, 7);
  const showLimitDatasources = dataSourcesArray.slice(
    7,
    dataSourcesArray?.length,
  );

  const getDatasoucrsContent = (data) => {
    return data?.map((item, key) => <Box key={key}>{getSocialIcon(item)}</Box>);
  };
  return (
    <>
      <Box className="tooltip-content">
        {dataSourcesArray?.length ? getDatasoucrsContent(showDatasources) : "-"}
        {dataSourcesArray?.length >= 8 ? (
          <LightTooltip
            title={
              <Box className="tooltip-content">
                {getDatasoucrsContent(showLimitDatasources)}
              </Box>
            }
            arrow
            placement="top"
            id="tooltip-datasouces-report"
          >
            <Typography component="span" className="tooltip-content-count">
              {`(+${showLimitDatasources?.length})`}
            </Typography>
          </LightTooltip>
        ) : null}
      </Box>
    </>
  );
};

const showReportStatusData = (status, translated) => {
  let btnStyle = {
    background:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50",
    borderColor: "rgba(76, 175, 80, 0.5)",
    color: "#3B873E",
  };
  if (status === "paused") {
    btnStyle.background = "rgba(0, 0, 0, 0.09)";
    btnStyle.borderColor = "rgba(0, 0, 0, 0.23)";
    btnStyle.color = "#616161";
  }
  return (
    <Badge className="report-status-btn" sx={btnStyle}>
      {translated}
    </Badge>
  );
};

const ReportList = ({
  list,
  handleOpenDeleteModal,
  searchText,
  getReportData,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const [tableBody, setTableBody] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const showWarningMsg = (arrMonitors) => {
    return (
      <Box className="">
        <Tooltip
          title={
            <ul className="warning-content-report">
              {arrMonitors?.map((item) => {
                return item?.status ? (
                  <li>
                    <Box
                      component="span"
                      className="account-name-report"
                    >{`${item?.name}`}</Box>
                    {CheckValueLocale(
                      `${getMsgStatusFormating(item?.status)}`,
                      "",
                      {},
                      intl,
                    )}
                  </li>
                ) : null;
              })}
            </ul>
          }
          id="warning-tooltip-report"
          arrow
          placement="bottom"
        >
          <Box className="warning-icon-countainer">
            <ErrorIcon
              className="dashboard-warning-icon report-warning-icon"
              fontSize="small"
            />
          </Box>
        </Tooltip>
      </Box>
    );
  };
  const getMsgStatusFormating = (status) => {
    const statuses = {
      "Fetching GNIP": "fetching_gnip",
      "Updating Tracker": "updating_tracker",
      "Authentication error!": "authentication_error",
      paused: "paused",
      "non-social": "non_social_medias_data_limit_reached",
      social: "social_medias_data_limit_reached",
      "Invalid twitter handler name!": "Invalid_twitter_handler_name",
      monitor_list_social_twitter_account_locked:
        "monitor_list_social_twitter_account_locked",
      "Account needs to be re-authenticated ":
        "accounts_needs_re-authenticated_report",
      tw_limit_reached: "reports_limit_reached",
    };
    return statuses[status] || status;
  };
  const showReportText = (text, textLength, item) => {
    return (
      <Box className="report-name-container">
        {text?.length > textLength ? (
          <BootstrapTooltip
            title={text}
            arrow
            TransitionComponent={Zoom}
            placement="top"
          >
            <Button
              variant="text"
              sx={{
                padding: 0,
                margin: 0,
                borderRadius: 0,
              }}
              id="report-create-new-show-report-text-btn"
            >
              {text?.substring(0, textLength)}...
            </Button>
          </BootstrapTooltip>
        ) : (
          <Typography variant="subtitle2">{text}</Typography>
        )}
      </Box>
    );
  };

  const handelViewReport = (reportData) => {
    history?.push({
      pathname: "/reports/view-report/" + reportData.id,
      state: { detail: reportData },
    });
  };

  const nameCell = (item) => {
    let reportsWarningStatus = item?.attributes?.monitors?.filter((i) => {
      return i?.status?.length !== 0;
    });
    return item.attributes?.title ? (
      <Box className="report-name-countainer">
        {reportsWarningStatus?.length ? (
          <Box>{showWarningMsg(item?.attributes?.monitors)}</Box>
        ) : null}
        <Box
          onClick={() => handelViewReport(item)}
          sx={{ display: "inline-block", cursor: "pointer" }}
        >
          {showReportText(item.attributes?.title, 18, item)}
        </Box>
      </Box>
    ) : (
      "—"
    );
  };

  const options = (item) => {
    return (
      <ListOptions
        reportData={item}
        handleOpenDeleteModal={handleOpenDeleteModal}
        history={history}
        handelViewReport={handelViewReport}
        getReportData={getReportData}
      />
    );
  };
  const stateCell = (item) => {
    return (
      <>
        <Box
          className={`status ${
            item?.attributes?.manual_status === true ? "active" : "paused"
          }`}
        >
          {CheckValueLocale(
            item?.attributes?.manual_status == true
              ? "Active"
              : "reports_paused",
            "",
            {},
            intl,
          )}
        </Box>
      </>
    );
  };
  useEffect(() => {
    setTableLoading(true);
    const tabBody =
      list?.length == 0
        ? []
        : list?.map((item) => {
            return {
              id: item?.id,
              className: item?.attributes?.monitors?.filter((i) => {
                return i?.status?.length !== 0;
              })?.length
                ? "warning"
                : "",
              data: [
                {
                  body: nameCell(item),
                  className: "report-name-td",
                },
                {
                  body: item?.attributes?.report_type?.length
                    ? CheckValueLocale(
                        `reports_${item?.attributes?.report_type}`,
                        "—",
                        {},
                        intl,
                      )
                    : "—",
                },
                {
                  body: item?.attributes?.platform?.length
                    ? item?.attributes?.platform === "SM"
                      ? CheckValueLocale("social_listening", "—", {}, intl)
                      : CheckValueLocale("channel_analytics", "—", {}, intl)
                    : "—",
                },
                {
                  body: item?.attributes?.data_sources?.length
                    ? showReportDataSourcesData(item?.attributes?.data_sources)
                    : "—",
                  className: "reports-social-icons",
                },
                {
                  body: item?.attributes?.frequency?.length
                    ? CheckValueLocale(
                        `report_${item?.attributes?.frequency?.toLowerCase()}`,
                        "—",
                        {},
                        intl,
                      )
                    : "—",
                },
                {
                  body:
                    item?.attributes?.status === "active"
                      ? item?.attributes?.next_delivery
                        ? moment(item?.attributes?.next_delivery)
                            .utcOffset(item?.attributes?.next_delivery)
                            .format("YYYY-MM-DD (h:mma)")
                        : "—"
                      : "—",
                  className: "date-direction",
                },
                {
                  body: item?.attributes?.created_by
                    ? item?.attributes?.created_by
                    : "—",
                  isCreatedBy: true,
                },
                {
                  body: stateCell(item),
                },

                {
                  body: options(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(list));
    setTableLoading(false);
  }, [list, intl?.locale]);

  const tableHead = [
    {
      body: CheckValueLocale("report_name", "", {}, intl),
      width: "12%",
    },

    {
      body: CheckValueLocale("report_type", "", {}, intl),
      width: "11.99%",
    },
    {
      body: CheckValueLocale("report_product", "", {}, intl),
      width: "10.53%",
    },
    {
      body: CheckValueLocale("report_data_source", "", {}, intl),
      width: "10.96%",
    },
    {
      body: CheckValueLocale("report_frequency", "", {}, intl),
      width: "8.56%",
    },
    {
      body: CheckValueLocale("report_next_delivery", "", {}, intl),
      width: "13.78%",
    },
    {
      body: CheckValueLocale("creator", "", {}, intl),
      width: "12.7%",
    },
    {
      body: CheckValueLocale("status", "", {}, intl),
      width: "10.36%",
    },
    {
      body: null,
      width: "5.74%",
    },
  ];
  const emptyBody = (list) => {
    return (
      <>
        {searchText !== "" && list?.length == 0 ? (
          <Box className="no-data-cdp">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="sorry_adjust_your_search"
            />
          </Box>
        ) : list?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="reports"
              title="create_your_report"
              description="create_your_report_dec"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks.reports_link}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <>
      <SharedTable
        className="report-table"
        tableHeader={tableHead}
        tableBody={tableBody}
        isSharedStyle
        emptyBody={tableEmptyBody}
      />
    </>
  );
};
export default ReportList;
