import { Avatar, Box, Typography } from "@mui/material";
import { useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import profileImg from "images/engagements-images/profileImg.png";
import { useIntl } from "react-intl";
import CompaniesController from "services/controllers/companiesController";
import "./activityLog.scss";
import moment from "moment";
import Filter from "shared/general-filter/filter";
import Pagination from "@mui/material/Pagination";
import NoDataFoundProducts from "components/no-Data";
import CircularLoading from "components/circularProgress";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";

const ActivityLog = (props) => {
  const { isUserActivityLog, isTeamActivityLog, teamId, userData } = props;
  const intl = useIntl();
  const todayDate = new Date();
  const [activityLog, setActivityLog] = useState({});
  const [filterFields, setFilterFields] = useState([]);
  const [filterActivityLog, setFilterActivityLog] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [oldDate, setOldDate] = useState(null);
  const [filtersFailsStatus, setFiltersFailsStatus] = useState(false);

  const getFiltersFileds = () => {
    CompaniesController.getFilterActivityLogs().then((res) => {
      if (res?.data?.status === 200) {
        setFilterFields(res?.data?.data?.data);
        setFiltersFailsStatus(true);
      } else setFiltersFailsStatus(false);
    });
  };
  const getUserActivityLogs = (startDate, endDate, page, filterParams) => {
    setIsLoading(true);
    let filtersObj = {};
    Object.keys(filterParams)?.map((key) => {
      filtersObj[key] = filterParams[key]?.join(",");
    });
    CompaniesController.getUserActivityLogs(
      startDate,
      endDate,
      !isUserActivityLog && !isTeamActivityLog
        ? Array.isArray(filterParams?.user)
          ? filtersObj?.user.replace(/""/g, "")
          : undefined
        : userData?.id,
      Array.isArray(filterParams?.product)
        ? filtersObj?.product?.replace(/""/g, "")
        : undefined,
      page,
      isTeamActivityLog ? teamId : undefined,
    ).then((result) => {
      if (result?.data?.status === 200) {
        setIsLoading(false);
        setActivityLog(result?.data);
        setPagination(result?.data?.data?.pagination);
        setOldDate(result?.data?.data?.oldest_date);
      } else {
        setIsLoading(false);
      }
    });
  };
  const getInitials = (sentence) => {
    return sentence
      .split(" ")
      .map((word) => word[0])
      .join("");
  };
  const checkStatusMsg = (action, entity, name, controllerName, subentity) => {
    const entityLocale = entity?.replace(/ /g, "_");
    const actionLocale = action?.replace(/ /g, "_");
    const actionCheckLocale = {
      // social & omni
      MonitorsController: `activity_log_${actionLocale}`,
      UserSessionsController: `activity_log_${actionLocale}`,
      OmniauthCallbacksController: `activity_log_${
        subentity == "company account authentication"
          ? actionLocale + "_auth"
          : actionLocale
      }`,
      TwitterController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      InstagramController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      FacebookController: `activity_log_${subentity == "powerpoint file" ? actionLocale + "_powerp" : actionLocale}`,
      MonitorsController: `activity_log_${entity == "Omnichannel" ? actionLocale + "_onni" : actionLocale}`,
      SingleTweetsController: `activity_log_${actionLocale}`,
      DashboardsController: `activity_log_${actionLocale}_dashboards`,
      CustomDashboardsController: `activity_log_${actionLocale}_custom_live_dashboard`,
      ExcelController: `activity_log_${actionLocale}`,
      //omniChannel
      DmConfigurationsController: `activity_log_${actionLocale}-dm`,
      // setting
      UserManagementsController: `activity_log_${actionLocale}_user`,
      // UserSessionsController: `activity_log_${actionLocale}_cdp`,
      XlsxController: `activity_log_${subentity == "excel file" ? actionLocale + "_excel" : actionLocale}`,
      // CDP
      SegmentsController: `activity_log_${actionLocale}_cdp`,
      // Surveys
      SurveysController: `activity_log_${entity == "Survey" ? actionLocale + "_survey" : actionLocale}`,
      // Utilities
      ReportsController: `activity_log_${entity == "Utilities" ? actionLocale + "_report" : actionLocale}`,
      AlertsController: `activity_log_${entity == "Utilities" ? actionLocale + "_alerts" : actionLocale}`,
      //luci
      QuestionsController: `activity_log_${actionLocale}`,
      TermPoliciesController: `activity_log_${actionLocale}`,
      //teams
      TeamsController: `activity_log_${actionLocale}_team`,
      // Historical Requests
      HistoricalRequestsController: `activity_log_${actionLocale}_historical`,
      //lucidya api
      PublicApisController: `activity_log_${actionLocale}_apis`,
    };
    return action == "Successful log in" && entity == "Settings" ? (
      <Box className="msg-content">
        {CheckValueLocale(`activity_log_${actionLocale}`, "", {}, intl)}{" "}
      </Box>
    ) : action == "Unsuccessful log in attempt" && entity == "Settings" ? (
      <Box className="msg-content">
        {CheckValueLocale(`activity_log_${actionLocale}`, "", {}, intl)}{" "}
      </Box>
    ) : action == "Successful log in" && entity == "Omnichannel" ? (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"} className="entity">
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    ) : (
      <Box className="msg-content">
        {CheckValueLocale(actionCheckLocale[controllerName], "", {}, intl) +
          " "}
        {name ? CheckValueLocale(`"${name}"`, "", {}, intl) : null}{" "}
        {CheckValueLocale("activity_log_in", "", {}, intl)}{" "}
        <Box component={"strong"}>
          {CheckValueLocale(`activity_log_${entityLocale}`, "", {}, intl)}{" "}
        </Box>
      </Box>
    );
  };

  const handleClearFilterParams = () => {
    setFilterParams({});
  };

  const applyFilter = (startDate, endDate) => {
    setPage(1);
    setStartDate(startDate);
    setEndDate(endDate);
    getUserActivityLogs(startDate, endDate, 1, filterParams);
  };

  //pagination
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    getUserActivityLogs(startDate, endDate, page, filterParams);
  };

  const handleImageError = (event) => {
    event.target.src = profileImg;
    event.target.alt = "placeholder-image";
  };

  const checekDay = (createdAt) => {
    let value =
      moment(createdAt)?.format("DD/MM/yyyy") ==
      moment(todayDate)?.format("DD/MM/yyyy")
        ? true
        : false;

    return value;
  };

  return (
    <Box className="activity-log-container">
      <Box className="activity-log-head">
        <Typography>
          {CheckValueLocale(
            isUserActivityLog
              ? "user_activity_history"
              : "users_activity_history",
            "",
            {},
            intl,
          )}
        </Typography>
        <Box className="activity-log-datepicker-filter">
          <DateRangePicker
            applyFilter={applyFilter}
            startDate={startDate}
            endDate={endDate}
            oldestDate={oldDate}
            isUserActivity
            openCustomDateLeft={true}
            datePickerPosition="monitorheader-datepicker-position"
            id="date-range-picker-user-activity"
            monitorActiveTabId="user-activity-header"
          />

          {!isUserActivityLog && !isTeamActivityLog ? (
            <Filter
              handleClearFilterParams={handleClearFilterParams}
              filterParams={filterParams}
              filterFields={filterFields}
              setFilterParams={setFilterParams}
              monitorActiveTabFilterID={"user-activity-filter"}
              isUserActivity
              assignActiveFilters={filterActivityLog}
              setAssignActiveFilters={setFilterActivityLog}
              applyFilter={applyFilter}
              getFiltersFileds={getFiltersFileds}
              startDate={startDate}
              endDate={endDate}
              disabled={!filtersFailsStatus}
            />
          ) : null}
        </Box>
      </Box>

      {isLoading ? (
        <CircularLoading />
      ) : activityLog?.data?.data?.length > 0 ? (
        Object.entries(activityLog?.data?.data).map(
          ([date, activities], index) => (
            <Box>
              <Box
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-tab"
              ></Box>
              <Box className="accordion-content">
                <Box
                  className={
                    isUserActivityLog
                      ? "user-item user-single-page"
                      : "user-item"
                  }
                  key={activities?.id}
                >
                  <Box>
                    {isUserActivityLog ? (
                      <Box className="view-user-embty-img"> </Box>
                    ) : activities?.attributes?.avatar ? (
                      <Box className="user-img">
                        <img
                          src={activities?.attributes?.avatar}
                          onError={handleImageError}
                        />
                      </Box>
                    ) : (
                      <Avatar className="user-img">
                        {getInitials(activities?.attributes?.user_name)}
                      </Avatar>
                    )}

                    <Typography component={"strong"} className="user-name">
                      {activities?.attributes?.user_name}
                    </Typography>
                    {checkStatusMsg(
                      activities?.attributes?.action,
                      activities?.attributes?.entity,
                      activities?.attributes?.inside_subentity_name,
                      activities?.attributes?.controller_name,
                      activities?.attributes?.subentity,
                    )}
                  </Box>
                  {moment(activities?.attributes?.created_at).format("h:mm A")}
                  {!checekDay(activities?.attributes?.created_at)
                    ? ` - ${moment(activities?.attributes?.created_at)?.format(
                        "DD/MM/yyyy",
                      )}`
                    : null}
                </Box>
              </Box>
            </Box>
          ),
        )
      ) : (
        <Box className="empty-box" id="empty-activity-log">
          <NoDataFoundProducts
            image="userActivity"
            title="no_data_found"
            description="no_activity_logs_found_msg"
          />
        </Box>
      )}
      {pagination?.pages > 1 ? (
        <Pagination
          onChange={handleChangePage}
          page={page}
          className="pagination-user-activity-log"
          count={pagination?.pages}
          variant="outlined"
        />
      ) : null}
    </Box>
  );
};

export default ActivityLog;
