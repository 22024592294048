import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import MediaInsta from "components/media/media-insta/mediaInsta";
import MediaTw from "components/media/media-tw/mediaTw";
import MediaFb from "components/media/media-fb/mediaFb";
import LinkedInMedia from "components/media/media-linkedin/linkedInMedia";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";

const CustomDashboardMediaComponent = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;
  const intl = useIntl();

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "posts_top_videos":
          if (
            widget?.dashboard_page_name === "INSTAGRAM_PUBLIC" ||
            widget?.dashboard_page_name === "TWITTER_PUBLIC"
          ) {
            setData(dataState?.["top_videos"]);
          } else setData(dataState?.[widget?.widget_name]);
          break;

        case "posts_top_images":
          if (
            widget?.dashboard_page_name === "INSTAGRAM_PUBLIC" ||
            widget?.dashboard_page_name === "TWITTER_PUBLIC"
          ) {
            setData(dataState?.["top_images"]);
          } else setData(dataState?.[widget?.widget_name]);
          break;

        default:
          setData(dataState?.[widget?.widget_name]);
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  return (
    <>
      {widget?.data_source === "INSTAGRAM" ? (
        <MediaInsta
          title={CheckValueLocale(
            dashboardInfo?.product == "sm"
              ? handledWidgetName
              : `custom_omni_${handledWidgetName}`,
            "",
            {},
            intl,
          )}
          titleToolTip={titleTooltip || ""}
          data={data || []}
          mediaInstaPreLoaderDataSources={
            socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
              monitorId
            ]?.[
              widget?.is_dm
                ? `${widget?.data_source}_private`
                : widget?.data_source
            ] || []
          }
          preLoaderTrackerDataSources={[widget?.monitor_id] || []}
          hideMoreInsights
          monitorInstagramMedia
          isCustomDashboard
          monitorData={{
            name:
              dashboardInfo?.product === "cxm"
                ? widget?.account_identifier
                : widget?.monitor_name,
            type:
              dashboardInfo?.product === "cxm"
                ? widget?.data_category
                : widget?.monitor_type?.name,
            datasource: widget?.is_dm
              ? `${widget?.data_source}_private`
              : widget?.data_source,
          }}
        />
      ) : widget?.data_source === "FACEBOOK" ? (
        <MediaFb
          title={CheckValueLocale(
            dashboardInfo?.product == "sm"
              ? handledWidgetName
              : `custom_omni_${handledWidgetName}`,
            "",
            {},
            intl,
          )}
          titleToolTip={titleTooltip || ""}
          data={data || []}
          mediaFbPreLoaderDataSources={
            socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
              monitorId
            ]?.[
              widget?.is_dm
                ? `${widget?.data_source}_private`
                : widget?.data_source
            ] || []
          }
          preLoaderTrackerDataSources={[monitorId] || []}
          showDownloadIcon
          questions={data || []}
          insightsID={[]}
          hideMoreInsights
          hideTotalInteractions
          dates={[]}
          isCustomDashboard
          monitorData={{
            name:
              dashboardInfo?.product === "cxm"
                ? widget?.account_identifier
                : widget?.monitor_name,
            type:
              dashboardInfo?.product === "cxm"
                ? widget?.data_category
                : widget?.monitor_type?.name,
            datasource: widget?.is_dm
              ? `${widget?.data_source}_private`
              : widget?.data_source,
          }}
        />
      ) : widget?.data_source === "TWITTER" ||
        widget?.data_source === "TIKTOK" ? (
        <MediaTw
          title={CheckValueLocale(
            dashboardInfo?.product == "sm"
              ? handledWidgetName
              : `custom_omni_${handledWidgetName}`,
            "",
            {},
            intl,
          )}
          titleToolTip={titleTooltip || ""}
          data={data}
          mediaTwPreLoaderDataSources={
            socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
              monitorId
            ]?.[
              widget?.is_dm
                ? `${widget?.data_source}_private`
                : widget?.data_source
            ] || []
          }
          preLoaderTrackerDataSources={[widget?.monitor_id] || []}
          isCustomDashboard
          monitorData={{
            name:
              dashboardInfo?.product === "cxm"
                ? widget?.account_identifier
                : widget?.monitor_name,
            type:
              dashboardInfo?.product === "cxm"
                ? widget?.data_category
                : widget?.monitor_type?.name,
            datasource: widget?.is_dm
              ? `${widget?.data_source}_private`
              : widget?.data_source,
          }}
          isTiktok={widget?.data_source === "TIKTOK" ? true : false}
        />
      ) : widget?.data_source === "LINKEDIN" ? (
        <LinkedInMedia
          isLinkedInVideo
          title={handledWidgetName}
          titleToolTip={titleTooltip || ""}
          data={data || []}
          mediaTwPreLoaderDataSources={
            socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
              monitorId
            ]?.[
              widget?.is_dm
                ? `${widget?.data_source}_private`
                : widget?.data_source
            ] || []
          }
          preLoaderTrackerDataSources={[monitorId] || []}
          showDownloadIcon
          bigImg
          isCustomDashboard
          monitorData={{
            name:
              dashboardInfo?.product === "cxm"
                ? widget?.account_identifier
                : widget?.monitor_name,
            type:
              dashboardInfo?.product === "cxm"
                ? widget?.data_category
                : widget?.monitor_type?.name,
            datasource: widget?.is_dm
              ? `${widget?.data_source}_private`
              : widget?.data_source,
          }}
        />
      ) : null}
    </>
  );
};

export default CustomDashboardMediaComponent;
