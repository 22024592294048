import React, { useEffect, useState } from "react";
import { Box, Container, Pagination } from "@mui/material";
//Locals
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import SurveyHeader from "pages/survey/components/surveyHeader";
import SurveyCards from "./surveyCards/surveyCards";
import SurveyController from "services/controllers/surveyController";
import CreateSurveyModal from "./components/createSurveyModal";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";

const SurveyMainPage = (props) => {
  const intl = useIntl();
  let history = useHistory();
  const [emptySearch, setEmptySearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [stats, setStats] = useState({
    surveys: [],
    emptyPage: false,
    openCreateSurveyModal: false,
    loading: true,
    surveyLimitReach: false,
  });

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  useEffect(() => {
    if (count === 1) {
      getSurveys(searchText, page);
    } else {
      setCount(count + 1);
    }
  }, [count, page]);

  const getSurveys = (searchText) => {
    setStats({ ...stats, surveys: [], loading: true });
    setEmptySearch(false);
    SurveyController.getSurveys(searchText, page).then((data) => {
      if (!isEmptyArray(data?.data?.data)) {
        setStats({
          ...stats,
          surveys: data?.data?.data,
          loading: false,
          emptyPage: false,
          surveyLimitReach: data?.survey_limit_reach,
        });
        setPagination(data?.data?.count);
        setPageSize(data?.data?.page_size);
      } else {
        if (searchText?.length) {
          setEmptySearch(true);
          setStats({ ...stats, emptyPage: false, loading: false, surveys: [] });
        } else {
          setStats({ ...stats, emptyPage: true, loading: false, surveys: [] });
        }
      }
    });
  };
  const handleOpenCreateSurvey = (value) => {
    setStats({ ...stats, openCreateSurveyModal: value });
  };
  const handleCreateSurvey = (surveyName) => {
    SurveyController.createSurveys(surveyName, "Eng").then((data) => {
      if (data && data?.data && data?.data?.status === "created") {
        setStats({ ...stats, loading: false, openCreateSurveyModal: false });
        history.push(`/survey/survey-builder/${data?.data?.data?.id}`);
      } else {
        setStats({
          ...stats,
          emptyPage: true,
          loading: false,
          openCreateSurveyModal: false,
        });
      }
    });
  };
  // add pagination
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };
  return (
    <>
      <Container maxWidth="xl" className="container-survey-header">
        <SurveyHeader
          title={"surveys"}
          action={"new_survey"}
          handleAction={() => {
            handleOpenCreateSurvey("open");
          }}
          getSurveys={getSurveys}
          emptySearch={emptySearch}
          type="SURVEY"
          role="CREATE_SURVEY"
          showActionIcon={true}
          showBtn={true}
          isLoading={stats?.loading}
          stats={stats}
          setPage={setPage}
          page={page}
          searchText={searchText}
          setSearchText={setSearchText}
          surveyData={stats?.surveys}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        {stats.loading ? (
          <CircularLoading />
        ) : (
          <>
            {emptySearch ? (
              <Box className="empty-item empty-item-survey">
                <NoDataFoundProducts
                  image={"survey"}
                  title={"no_data_found"}
                  subTitle="no_data_matching_your_search"
                />
              </Box>
            ) : stats?.emptyPage ? (
              <Box className="empty-item empty-item-survey">
                <NoDataFoundProducts
                  image="EmptySurvey"
                  title="create_your_survey"
                  description="create_your_survey_dec"
                  linkOne="how_to_use"
                  linkOneHref={NoDataLinks.survey_link}
                  orCase="or_visit_the"
                  linkTwo="knowledge_base"
                />
              </Box>
            ) : (
              <SurveyCards
                surveyLimitReach={stats?.surveyLimitReach}
                surveys={stats?.surveys}
                getSurveys={getSurveys}
              />
            )}
          </>
        )}
        {stats.openCreateSurveyModal && (
          <CreateSurveyModal
            handleOpenCreateSurvey={handleOpenCreateSurvey}
            openCreateSurveyModal={stats?.openCreateSurveyModal}
            handleCreateSurvey={handleCreateSurvey}
          />
        )}
      </Container>
      {Math.ceil(pagination / pageSize) > 1 &&
      !stats?.loading &&
      !emptySearch ? (
        <Pagination
          onChange={handleChangePage}
          className="pagination-monitor-list"
          count={Math.ceil(pagination / pageSize)}
          page={page}
          variant="outlined"
        />
      ) : null}
    </>
  );
};
export default SurveyMainPage;
