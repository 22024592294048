import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Tooltip } from "@mui/material";
import lucidyaLogoHeader from "images/live-dashboard-images/live-dashboard-logo.svg";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import { useHistory, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import {
  faAngleRight,
  faAngleLeft,
  faPenToSquare,
  faGlobe,
  faShareNodes,
} from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { useState } from "react";

const LiveDashboardHeader = ({
  applyFilter,
  startDate,
  endDate,
  lastUpdated,
  liveDashboardName,
  changeLang,
  selectedWidgets,
  redirectToMonitor,
  showEditBtn,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const urlParams = useParams();
  const backButtonIcon =
    window.localStorage.lang === "ar" ? faAngleRight : faAngleLeft;
  const [copied, setCopied] = useState(false);

  const copyLinkDashboard = () => {
    const dashboardLink = location?.href;
    navigator.clipboard.writeText(dashboardLink);
    setCopied(true);
  };

  return (
    <Box className="live-dashboard-heading-wrapper">
      <Box className="live-dashboard-heading">
        <Box
          className="live-dashboard-back"
          onClick={() => redirectToMonitor(urlParams, selectedWidgets)}
        >
          <FontAwesomeIcon icon={backButtonIcon} />
        </Box>
        <img src={lucidyaLogoHeader} alt="livedashboard" />
        <Box>
          <Box component="p" className="live-dashboard-name">
            {liveDashboardName}
          </Box>
          <Box component="p" className="live-dashboard-date">
            {CheckValueLocale("this_page_refreshed", "", {}, intl)}{" "}
            {lastUpdated?.format("h:mm:ss a")}
          </Box>
        </Box>
      </Box>
      <Box className="live-dashboard-button-wrapper">
        <LucButton
          type="primary"
          variant="flat"
          size="small"
          onClick={changeLang}
          id="lang-live-dashboard-btn"
          startIcon={<FontAwesomeIcon icon={faGlobe} />}
          className="live-dashboard-button"
        >
          {CheckValueLocale("change_lang_login", "", {}, intl)}
        </LucButton>
        <Tooltip
          arrow
          placement="bottom"
          title={
            <Box className="go-to-live-dashboard-tooltip">
              {CheckValueLocale(
                `${copied ? "copied" : "shar_dashboard_tooltip"}`,
                "",
                {},
                intl,
              )}
            </Box>
          }
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: `${copied ? "#3B873E" : ""}`,
              },
            },
          }}
        >
          <Box>
            <LucButton
              type="primary"
              variant="flat"
              size="small"
              onClick={copyLinkDashboard}
              onMouseLeave={() => setTimeout(() => setCopied(false), 500)}
              id="shar-live-dashboard-btn"
              startIcon={<FontAwesomeIcon icon={faShareNodes} />}
              className="live-dashboard-button"
            >
              {CheckValueLocale("copy_link", "", {}, intl)}
            </LucButton>
          </Box>
        </Tooltip>
        {showEditBtn ? (
          <LucButton
            type="primary"
            variant="flat"
            size="small"
            onClick={() =>
              history.push({
                pathname: `/edit-live-dashboard/${urlParams?.monitorType}/${urlParams?.monitorId}/${urlParams?.userDashboardId}`,
                state: history.location.state,
              })
            }
            id="edit-live-dashboard-btn"
            startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
            className="live-dashboard-button"
          >
            {CheckValueLocale("edit", "", {}, intl)}
          </LucButton>
        ) : null}
        <Divider
          orientation="vertical"
          className="live-dashboard-button-divider"
        />
        <DateRangePicker
          applyFilter={applyFilter}
          startDate={startDate}
          endDate={endDate}
          openCustomDateRight={true}
          datePickerPosition="live-dashboard-datepicker-position"
          id="date-range-picker-live-dashboard-center"
          monitorActiveTabId="live-dashboard"
          lastHourEnabled
        />
      </Box>
    </Box>
  );
};
export default LiveDashboardHeader;
