import { Typography, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "../../src/utils/helpers";

const PageHeader = (props) => {
  const intl = useIntl();
  /** Page Header props are: 
      boxMB: optional, 
      container: optional, --- discuss
      title: required,
      titleId: optional,
      titleAttributeName: optional,
      titleAttributeValue: optional,
      titleVariant:optional,
      subTitle: optional, 
      subTitleVariant:optional,
      subTitleStyle : optional,
      withBreadCrumb: optional
   */

  var titleWithInjectedAttributes = {};
  titleWithInjectedAttributes[props.titleAttributeName] =
    props.titleAttributeValue;

  let checkInjectedAttributes =
      props.titleAttributeName !== undefined &&
      props.titleAttributeValue !== undefined,
    titleVariant = props.titleVariant ? props.titleVariant : "h6",
    subTitleId = props.withBreadCrumb
      ? "main-pages-header-with-breadcrumb"
      : props.subTitle
        ? "main-pages-header-subtitles"
        : "main-pages-header",
    subTitleStyleClassName = props.subTitleStyle ? props.subTitleStyle : "",
    subTitleVariant = props.subTitleVariant ? props.subTitleVariant : "body2";

  return (
    <>
      <Box xs={props?.boxXS} mb={props?.boxMB}>
        <Typography variant={titleVariant} id={subTitleId}>
          {checkInjectedAttributes
            ? CheckValueLocale(
                props.title,
                "",
                titleWithInjectedAttributes,
                intl,
              )
            : CheckValueLocale(props.title, "", {}, intl)}
        </Typography>

        {props.subTitle && (
          <Typography
            variant={subTitleVariant}
            className={subTitleStyleClassName}
          >
            {CheckValueLocale(props.subTitle, "", {}, intl)}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default PageHeader;
