import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import EngagementsController from "services/controllers/engagementsController";
import "./engagementTranslation.scss";
import LoadingSpinner from "components/loadingSpinner/loadingSpinner";

const EngagementTranslation = ({
  translationType,
  setTranslationActive,
  translationSource,
  setTranslationSource,
  setTranslationTarget,
  handleCloseTranslation,
  stats,
  setStats,
  languagesArraySource,
  languagesArrayTarget,
  setTranslateStatus = () => {},
  getSourceLanguage = () => {},
}) => {
  const intl = useIntl();
  const [savedLanguageLoading, setSavedLanguageLoading] = useState(true);
  const [source, setSource] = useState("");
  const [target, setTarget] = useState("");

  const handleSourceChange = (event) => {
    setSource(event.target.value);
  };

  const handleTargetChange = (event) => {
    setTarget(event.target.value);
  };

  const getSavedLanguage = () => {
    const queryData = {
      product_id: window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.getSavedLanguage(queryData).then((res) => {
      if (res?.data?.data) {
        setTarget(res?.data?.data?.attributes?.language);
      } else {
        setTarget(window.localStorage.lang);
      }
      setSource(translationSource || "auto_detect");
      setSavedLanguageLoading(false);
    });
  };

  const translationConfirmHandler = () => {
    const queryData = {
      language: target,
      product_id: window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.saveTranslationLanguage(queryData).then((res) => {
      if (res?.status_code === 204 || res?.status_code === 200) {
        setTranslationSource(source);
        setTranslationTarget(target);
        setTranslationActive(true);
        if (translationType === "reply") {
          if (source === "auto_detect") {
            getSourceLanguage(target, source);
          }
          setTranslateStatus(true);
        }
        setStats({
          ...stats,
          open: true,
          title: (
            <span className="translation-snackbar-heading">
              {CheckValueLocale("translation_is_on", "", {}, intl)}
            </span>
          ),
          body: (
            <span className="translation-snackbar-description">
              {CheckValueLocale(
                "translation_settings_saved_successfully",
                "",
                {},
                intl,
              )}
            </span>
          ),
          severity: "success",
        });
        setTimeout(() => {
          setStats({
            ...stats,
            open: false,
          });
        }, 7000);
      } else {
        setStats({
          ...stats,
          open: true,
          title: (
            <span className="translation-snackbar-heading">
              {CheckValueLocale("something_went_wrong", "", {}, intl)}
            </span>
          ),
          body: (
            <span className="translation-snackbar-description">
              {CheckValueLocale("try_again", "", {}, intl)}
            </span>
          ),
          severity: "error",
        });
        setTimeout(() => {
          setStats({
            ...stats,
            open: false,
          });
        }, 7000);
      }
      handleCloseTranslation();
    });
  };

  useEffect(() => {
    getSavedLanguage();
  }, []);

  const translationFromArray =
    translationType === "header" ? languagesArraySource : languagesArrayTarget;
  const translationToArray =
    translationType === "header" ? languagesArrayTarget : languagesArraySource;

  const translationFrom = translationType === "header" ? source : target;
  const translationTo = translationType === "header" ? target : source;
  const handleFromChange =
    translationType === "header" ? handleSourceChange : handleTargetChange;
  const handleToChange =
    translationType === "header" ? handleTargetChange : handleSourceChange;

  return (
    <Box className="engagement-translation-menu">
      {!savedLanguageLoading ? (
        <>
          <p className="translation-menu-heading">
            {CheckValueLocale("translation_settings", "", {}, intl)}
          </p>
          <Box className="translation-select-wrapper">
            <FormControl className="translation-input-field">
              <InputLabel id="demo-multiple-checkbox-label">
                {CheckValueLocale("from_lang", "", {}, intl)}
              </InputLabel>
              <Select
                value={translationFrom}
                placeholder={CheckValueLocale("from_lang", "", {}, intl)}
                onChange={handleFromChange}
                className="translation-owner"
              >
                {translationFromArray?.map((language, i) => {
                  return (
                    <MenuItem
                      value={language}
                      key={i}
                      className="translation-name-item"
                      id={`translation-${language ?? "id"}`}
                    >
                      {CheckValueLocale(language, "", {}, intl)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className="translation-input-field">
              <InputLabel id="demo-multiple-checkbox-label">
                {CheckValueLocale("to_lang", "", {}, intl)}
              </InputLabel>
              <Select
                value={translationTo}
                placeholder={CheckValueLocale("to_lang", "", {}, intl)}
                onChange={handleToChange}
                className="translation-owner"
              >
                {translationToArray?.map((language, i) => {
                  return (
                    <MenuItem
                      value={language}
                      key={i}
                      className="translation-name-item"
                      id={`translation-${language ?? "id"}`}
                    >
                      {CheckValueLocale(language, "", {}, intl)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Button
            disabled={false}
            variant="contained"
            className="translation-menu-button"
            onClick={translationConfirmHandler}
          >
            {CheckValueLocale("confirm_lang", "", {}, intl)}
          </Button>
        </>
      ) : (
        <Box className="engagement-translation-loading">
          <LoadingSpinner />
        </Box>
      )}
    </Box>
  );
};

export default EngagementTranslation;
