import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { TeamInformation } from "../components/teamInformation";
import { TeamMembers } from "../components/teamMembers";
import { ShowMoreButton } from "../components/showMoreButton";
import CompaniesController from "services/controllers/companiesController";

export const TeamsDetails = ({
  teamInformation,
  handleTeamInformationChange,
  members,
  handleSelectMember,
  selectedMembers,
  teamNameError,
  setMembers,
  showMoreMembersObj = {},
  isProductsDisabled,
  products,
}) => {
  const intl = useIntl();
  const {
    isSettled,
    setIsSettled,
    setShowedData: setShowedMembers,
    showedData: showedMembers,
    showMore,
    setShowMore,
    isLoading,
    setIsLoading,
    onShowLess,
    nextPage,
    hasPagination,
  } = showMoreMembersObj;

  const onShowMore = () => {
    if (isSettled) {
      setShowedMembers(members?.data);
      setShowMore(false);
      return;
    }
    setIsLoading(true);
    CompaniesController.getTeamMembers(nextPage).then((res) => {
      if (!res?.errorMsg) {
        const newMembers = res?.data?.users;
        const newMembersData = [...members?.data, ...newMembers?.data];
        const nextPage = newMembers?.pagination?.next;
        setMembers({
          data: newMembersData,
          pagination: newMembers?.pagination,
        });
        setShowedMembers(newMembersData);
        if (!nextPage) {
          setIsSettled(true);
          setShowMore(false);
        }
      }
      setIsLoading(false);
    });
  };
  return (
    <Box className="add-team-section add-team-details">
      <Typography variant="h6" className="add-team-title">
        {CheckValueLocale("add_team_details", "", {}, intl)}
      </Typography>
      <TeamInformation
        isProductsDisabled={isProductsDisabled}
        teamNameError={teamNameError}
        options={products}
        setTeamName={(teamName) => {
          handleTeamInformationChange({ teamName });
        }}
        teamName={teamInformation?.teamName}
        selectedProduct={teamInformation?.selectedProduct}
        setSelectedProduct={(selectedProduct) => {
          handleTeamInformationChange({ selectedProduct });
        }}
      />
      <Box className="team-members-section-block">
        <TeamMembers
          members={showedMembers}
          selectedMembers={selectedMembers}
          handleSelectMember={handleSelectMember}
          overallCount={members?.pagination?.count}
        />
        <ShowMoreButton
          show={hasPagination}
          loading={isLoading}
          hasMore={showMore}
          onShowLess={onShowLess}
          onShowMore={onShowMore}
        />
      </Box>
    </Box>
  );
};
