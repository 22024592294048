import { useWidgetName } from "../../../hooks/useWidgetName";

export const instagramRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  switch (response?.eventName) {
    case "Instagram__EngagementsPage__dialects_and_sub_dialects":
      if (monitorType === "MANAGED_ACCOUNT") {
        handleWidgetResponse(
          "dialects_subdialects",
          response?.monitor_id,
          "INSTAGRAM",
          response?.eventData,
        );
      } else {
        handleWidgetResponse(
          monitorType?.toLowerCase() === "keyword"
            ? "dialects_subdialects"
            : "posts_dialects_subdialects",
          response?.monitor_id,
          "INSTAGRAM",
          response?.eventData,
        );
      }
      break;

    case "Instagram__AccountPage__posts_interactions":
    case "Instagram__EngagementsPage__posts_interactions":
      handleWidgetResponse(
        "posts_interactions",
        response?.monitor_id,
        "INSTAGRAM",
        {
          interactions: response?.eventData?.posts_interactions,
        },
      );
      break;

    case "Instagram__EngagementsPage__comments_interactions":
      handleWidgetResponse(
        monitorType === "MANAGED_ACCOUNT"
          ? "comments_interactions"
          : "posts_interactions",
        response?.monitor_id,
        "INSTAGRAM",
        { interactions: response?.eventData },
      );
      break;

    case "Instagram__AccountPage__average_engagement_per_post":
      handleWidgetResponse(
        "average_engagements_per_post",
        response?.monitor_id,
        "INSTAGRAM",
        response?.eventData,
      );
      break;

    case "Instagram__AccountPage__reach_funnel":
      handleWidgetResponse(
        "posts_reach_impressions",
        response?.monitor_id,
        "INSTAGRAM",
        response?.eventData,
      );
      break;

    case "Instagram__EngagementsPage__top_engagers":
      handleWidgetResponse(
        "top_authors",
        response?.monitor_id,
        "INSTAGRAM",
        response?.eventData,
      );
      break;

    case "Instagram__AccountPage__account_author_activity":
      handleWidgetResponse(
        "account_authors_activity",
        response?.monitor_id,
        "INSTAGRAM",
        {
          account_authors_activity: response?.eventData,
        },
      );
      break;

    case "Instagram__EngagementsPage__gender_distribution":
      handleWidgetResponse(
        "gender_age_distribution",
        response?.monitor_id,
        "INSTAGRAM",
        response?.eventData,
      );
      break;

    default:
      if (stateKey)
        handleWidgetResponse(
          stateKey,
          response?.monitor_id,
          "INSTAGRAM",
          response?.eventData,
        );
      break;
  }
};
