import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Card, Box, Alert } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";

// Import Components
import CompanyInfo from "./companyInfo";
import CustomerMangerDetails from "./customerMangerDetails";
import ReportsBranding from "./reportsBranding";
import Snackbar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import PageHeader from "components/pageHeader.js";
// Import Controllers
import CompaniesController from "../../../../services/controllers/companiesController";
import {
  handlelUserRoles,
  CheckValueLocale,
  getDatasourceId,
} from "utils/helpers";
import CustomDomain from "./customDomain/customDomain";
import { genesysLastStepAuth } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks";

const CompanyInfoIndex = (props) => {
  const intl = useIntl();
  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    id: "",
    time_zone: "",
  });
  const [tempCompanyInfo, setTempCompanyInfo] = useState({
    name: "",
    id: "",
    time_zone: "",
  });
  const [customerSuccessManager, setCustomerSuccessManager] = useState({});
  const [reportsBranding, setReportsBranding] = useState({});
  const [tempReportsBranding, setTempReportsBranding] = useState({});
  const [fonts, setFontsList] = useState([]);
  const [success, setSuccess] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [customDomain, setCustomDomain] = useState("");
  const [genesysDataSourceId, setGenesysDataSourceId] = useState("");
  const [tiktokDataSourceId, setTiktokDataSourceId] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [genesysCodeLoading, setGenesysCodeLoading] = useState(false);
  const [genesysStatus, setGenesysStatus] = useState("");
  const [failSnackBarData, setFailSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarAllSource, setOpenSnackBarAllSource] = useState(false);
  const [infoChangesFlag, setInfoChangesFlag] = useState(false);
  const [brandingChangesFlag, setBrandingChangesFlag] = useState(false);
  const [logoError, setLogoError] = useState("");
  const [showAlert, setAlertShow] = useState(true);

  // Get Company Info
  const getCompanyInfo = () => {
    CompaniesController.getCompanyInfo().then((result) => {
      if (!result.errorMsg) {
        const data = result?.data?.data?.attributes;
        setCompanyInfo({
          name: data?.name,
          id: data?.id,
          time_zone: data?.time_zone,
        });
        setTempCompanyInfo({
          name: data?.name,
          id: data?.id,
          time_zone: data?.time_zone,
        });
        setCustomerSuccessManager(data?.customer_success_manager);
        setCustomDomain(result?.data?.data?.attributes?.custom_domain);
      } else {
        setCompanyInfo({ name: "", id: "", time_zone: "" });
        setTempCompanyInfo({ name: "", id: "", time_zone: "" });
        setCustomerSuccessManager({});
        setCustomDomain("");
        setOpenSnackBarAllSource(true);
        setFailSnackBarData({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
        });
      }
      setIsLoading(false);
    });
  };

  // Get Reports Branding Details
  const getReportsBrandingDetails = () => {
    CompaniesController.getReportsBranding().then((result) => {
      if (!result.errorMsg) {
        setReportsBranding(result?.data?.attributes);
        setTempReportsBranding(result?.data?.attributes);
      }
    });
  };

  // Get List of Fonts
  const getFonts = () => {
    CompaniesController.getFonts().then((result) => {
      if (!result.errorMsg) {
        setFontsList(result.data.data);
      }
    });
  };

  /* Edit Company Data */

  // Edit Company Info
  const editCompanyInfo = () => {
    CompaniesController.putCompanyInfo(companyInfo).then((result) => {
      if (!result.errorMsg) {
        setSuccessMsg("company_info_updated_success");
        setSuccess(true);
        setTempCompanyInfo({ ...companyInfo });
        setInfoChangesFlag(false);
      } else {
        setErrorMsg("failed_error");
        setSuccess(false);
      }
    });
  };

  // Set Reports Branding
  const editReportsBranding = () => {
    let formData = new FormData();
    let report_branding = {};

    for (var key of Object.keys(reportsBranding)) {
      if (reportsBranding[key] instanceof File) {
        formData?.append(key, reportsBranding[key]);
      } else {
        if (key === "logo") {
          if (!reportsBranding["logo"]) formData?.append("logo", null);
        } else if (key !== "updated_at" && key !== "logo_details")
          report_branding[key] = reportsBranding?.[key];
      }
    }
    formData?.append("report_branding", JSON?.stringify(report_branding));
    CompaniesController.postReportsBranding(formData).then((result) => {
      if (!result.errorMsg) {
        setReportsBranding(result?.data?.attributes);
        setTempReportsBranding(result?.data?.attributes);
        setSuccessMsg("company_info_updated_success");
        setSuccess(true);
        setBrandingChangesFlag(false);
      } else {
        setErrorMsg("failed_error");
        setSuccess(false);
      }
    });
  };

  // Cancel All Changes
  const cancelAllChanges = () => {
    if (infoChangesFlag) setCompanyInfo({ ...tempCompanyInfo });
    if (brandingChangesFlag) setReportsBranding({ ...tempReportsBranding });
    setInfoChangesFlag(false);
    setBrandingChangesFlag(false);
  };

  // Save All Changes
  const saveAllChanges = () => {
    if (logoError !== "") {
      setErrorMsg("branding_cant_save_error_msg");
      setSuccess(false);
      return;
    }
    if (infoChangesFlag) editCompanyInfo();
    if (brandingChangesFlag) editReportsBranding();
  };

  useEffect(() => {
    getDataSources(setDataSources);
  }, []);

  useEffect(() => {
    setGenesysDataSourceId(getDatasourceId(dataSources, "GENESYS")?.id);
    setTiktokDataSourceId(getDatasourceId(dataSources, "TIKTOK")?.id);
  }, [dataSources]);
  //genesys auth
  let url = window.location?.search;
  let code = url?.slice(url?.indexOf("?code=") + 6);

  useEffect(() => {
    if (window.location?.search?.includes("?error")) {
      setFailSnackBarData({
        message: "genesys_error_request_message",
        severity: "error",
        title: "genesys_error_request_title",
      });
      setOpenSnackBarAllSource(true);
      window.history.pushState({}, "", window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (
      code !== undefined &&
      code !== "" &&
      genesysDataSourceId &&
      url?.includes("code") &&
      !url?.includes("error")
    ) {
      genesysLastStepAuth(
        genesysDataSourceId,
        code,
        setGenesysCodeLoading,
        setGenesysStatus,
        setFailSnackBarData,
        setOpenSnackBarAllSource,
        getCompanyInfo,
      );
    }
  }, [code, genesysDataSourceId]);

  //Render Componenet
  useEffect(() => {
    (async () => {
      await getCompanyInfo();
      await getFonts();
      await getReportsBrandingDetails();
    })();
  }, []);

  useEffect(() => {
    localStorage?.setItem("companyInfo", companyInfo.time_zone);
  }, [companyInfo]);

  const handleDisableSave = () => {
    let disabled = false;
    if (
      !companyInfo?.name?.trim()?.length ||
      reportsBranding?.primary_color?.length < 4 ||
      reportsBranding?.secondary_color?.length < 4 ||
      reportsBranding?.tertiary_color?.length < 4
    ) {
      disabled = true;
    }
    return disabled;
  };

  return (
    <>
      {isLoading === true ||
      (genesysCodeLoading &&
        url?.includes("code") &&
        !url?.includes("error")) ? (
        <CircularLoading />
      ) : (
        <div id="comp_info">
          <PageHeader title="company_info" titleId="secondary-pages-header" />

          {showAlert ? (
            <Alert
              severity="info"
              id="company-information-accounts-management-alert"
            >
              <Box className="alert-content">
                {CheckValueLocale(
                  "company_information_page_accounts_management_alert_msg",
                  "",
                  {},
                  intl,
                )}
                <Box className="btns-wrapper">
                  <Link
                    to={"/settings/accounts_management"}
                    id="accounts-management-alert-view-btn"
                    className="view-btn"
                  >
                    {CheckValueLocale(
                      "accounts_managemnt_view_btn",
                      "",
                      {},
                      intl,
                    )}
                  </Link>
                  <Box
                    component={"span"}
                    id="company-information-accounts-management-alert-close-btn"
                    className="close-btn"
                    onClick={() => setAlertShow(false)}
                  >
                    <FontAwesomeIcon icon={faClose} size="lg" />
                  </Box>
                </Box>
              </Box>
            </Alert>
          ) : null}

          <CompanyInfo
            id={companyInfo.id}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
            setInfoChangesFlag={setInfoChangesFlag}
          />
          <CustomerMangerDetails
            customerSuccessManager={customerSuccessManager}
          />
          {handlelUserRoles("GENERAL", "CREATE_UTILITIES") && (
            <CustomDomain
              customDomain={customDomain}
              setCustomDomain={setCustomDomain}
              getCompanyInfo={getCompanyInfo}
            />
          )}
          {handlelUserRoles("GENERAL", "VIEW_REPORT_BRANDING") && (
            <ReportsBranding
              fonts={fonts}
              companyBranding={reportsBranding}
              setCompanyBranding={setReportsBranding}
              logoError={logoError}
              setLogoError={setLogoError}
              brandingChangesFlag={brandingChangesFlag}
              setBrandingChangesFlag={setBrandingChangesFlag}
            />
          )}
          {infoChangesFlag || brandingChangesFlag ? (
            <Card id="settings-changes-footer">
              {(handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") ||
                handlelUserRoles("GENERAL", "EDIT_COMPANY_BASIC_INFO")) && (
                <Box className="btns-box">
                  <LucButton
                    id="setting-company-info-save-btn"
                    type="primary"
                    variant="filled"
                    onClick={saveAllChanges}
                    disabled={handleDisableSave()}
                  >
                    {CheckValueLocale("save", "", {}, intl)}
                  </LucButton>
                  <LucButton
                    id="setting-company-info-cancel-btn"
                    variant="outline"
                    onClick={cancelAllChanges}
                  >
                    {CheckValueLocale("cancel", "", {}, intl)}
                  </LucButton>
                  <Box className="have-changes">
                    {CheckValueLocale("You_have_Changes", "", {}, intl)}
                  </Box>
                </Box>
              )}
            </Card>
          ) : (
            ""
          )}
          {success != null ? (
            <Snackbar
              open={success != null ? true : false}
              handleClose={() => setSuccess(null)}
              severity={success ? "success" : "error"}
              title={
                !logoError
                  ? success
                    ? CheckValueLocale(successMsg, "", {}, intl)
                    : CheckValueLocale(errorMsg, "", {}, intl)
                  : ""
              }
              message={
                success
                  ? ""
                  : CheckValueLocale(
                      logoError ? errorMsg : "try_again",
                      "",
                      {},
                      intl,
                    )
              }
            />
          ) : null}
        </div>
      )}
    </>
  );
};
export default CompanyInfoIndex;
