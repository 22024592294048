import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box, Divider, Typography, Button } from "@mui/material";
import {
  getTimeZone,
  isEmptyObj,
  CheckValueLocale,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import { useParams } from "react-router-dom";
import InteractionsController from "services/controllers/interactionsController";

import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu.js";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faComment } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import styled from "@emotion/styled";
import InteractionListPrivateMessage from "components/interactions-list/components/InteractionListPrivateMessage";
import dummyImage from "images/shared-images/image-placeholder.svg";
import dummyVideo from "images/shared-images/video-placeholder.svg";
import NoDataFound from "components/no-Data/noDataFound";
import SnackBar from "components/snackBar";
import Luci from "pages/luci";
import ReplyModal from "../../../../../replyModal/replyModal";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import StoryReplayPreview from "components/conversation/StoryReplayPreview";
import MonitorsController from "services/controllers/monitorsController";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import { getUnixEndDate } from "utils/helpers";
const SingleInstagramInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;

  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  let apiVars = "social_media/instagram/private";
  const [conversationMessages, setConersationMessgaes] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [allawedTrackers, setAllawedTrackers] = useState([]);

  const [text, setText] = useState("");
  const [replyModalData, setReplyModalData] = useState([
    {
      selectedValue: "",
      replyTime: "",
    },
  ]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true"
          ? `/instagram-private-channel/${dataSourceId}`
          : ""
      }`,
    },
  ];
  const handleImageError = (event) => {
    event.target.src = dummyImage;
  };

  const getSingleInteractions = () => {
    setPageNumber(pageNumber + 1);
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
      pageNumber,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result.data)) {
        if (result?.data?.msg) {
          setConversation(result?.data?.msg?.single_interaction);
          setConersationMessgaes((oldArray) => [
            ...oldArray,
            ...result?.data?.msg?.single_interaction?.conversation,
          ]);
          setSuccess(true);
          if (
            handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
            retrieveActiveProductsArray()?.includes("ENGAGEMENTS")
          ) {
            MonitorsController.getAllawedMonitors(
              window?.localStorage?.cxm_id,
            ).then((AllawedData) => {
              if (AllawedData?.data?.data?.monitors_list?.length) {
                setAllawedTrackers([...AllawedData?.data?.data?.monitors_list]);
              }
            });
          }
        }
        setLoading(false);
        setSuccess(false);
      }
    });
  };
  useEffect(() => {
    getSingleInteractions();
  }, []);
  const handleLoadMoreMessgaes = () => {
    getSingleInteractions();
  };
  const parseJsonData = (data) => {
    return JSON.parse(data);
  };
  const handleVideoError = (event) => {
    event.target.src = dummyVideo;
  };

  const handleCloseRepliedSnack = () => {
    setShowErrorSnackBar(false);
  };
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  const unixDate = {
    start: conversation?.created_at,
    end: getUnixEndDate(conversation?.created_at),
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          unixDate={unixDate}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: conversation?.client_username,
            user_name: conversation?.client_name,
            profile_image: conversation.client_profile_image_url,
          }}
          dataSourceName={"INSTAGRAM"}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {loading ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="instagram" />
            {isEmptyObj(conversation) ? (
              <>
                <SnackBar
                  open={success != null ? true : false}
                  severity={"error"}
                  handleClose={() => {
                    setSuccess(null);
                  }}
                  message={CheckValueLocale(
                    "please_wait_and_try_again_later",
                    "",
                    {},
                    intl,
                  )}
                  title={CheckValueLocale("failed_error", "", {}, intl)}
                />
                <Box
                  component="div"
                  className="interaction-list single-interaction-empty-data"
                >
                  <NoDataFound showApplyFilterMsg={false} />
                </Box>
              </>
            ) : (
              <Box
                className="interaction-list interaction-list-main"
                component="div"
              >
                <div className="header-interaction interaction-head">
                  <div className="interaction-list-header interaction-header-align">
                    <Box
                      className={`user-single-header hover-show `}
                      onClick={handleSideBar}
                    >
                      <span className="interaction-list-instagram">
                        <img src={publicInstagram} />
                      </span>
                      <span className="interaction-list-user-data">
                        <p className="label label-name">
                          @{conversation?.client_username}
                        </p>
                      </span>
                    </Box>

                    <Divider
                      orientation="vertical"
                      className="vertical-line-list-header"
                    />
                    <Typography className="interaction-list-type">
                      {CheckValueLocale(
                        conversation?.channel_type === "private"
                          ? "type_conversation"
                          : "type_post",
                        "",
                        {},
                        intl,
                      )}
                    </Typography>
                  </div>

                  <Box
                    className="interaction-list__footer single-int interaction-right-head"
                    component="div"
                  >
                    <ThemesMenu
                      data={conversation}
                      interactionId={interactionId}
                      trackerId={trackerId}
                      createdAt={conversation?.created_at}
                      channelId={conversation?.channel_id}
                      apiVars={apiVars}
                    />

                    <Divider
                      className="interaction-divider"
                      orientation="vertical"
                    />
                    <SentimentDropdown
                      sentiment={conversation?.sentiment}
                      data={conversation}
                      interactionId={interactionId}
                      trackerId={trackerId}
                      createdAt={conversation?.created_at}
                      channelId={conversation?.channel_id}
                      apiVars={apiVars}
                    />
                  </Box>
                </div>
                <InteractionListPrivateMessage
                  isRemoveBox
                  waitingTime={conversation?.waiting_time}
                  handlingTime={conversation?.handling_time}
                  status={
                    conversation?.status?.toLowerCase() === "active"
                      ? "active_status"
                      : conversation?.status?.toLowerCase() === "pending"
                        ? "pending_status"
                        : conversation?.status
                  }
                />
                <div className="message-container message-container-private chat-main">
                  {conversationMessages?.map((data) => {
                    let displayText = true;
                    if (
                      data?.content?.text === "" &&
                      data?.content?.url === null &&
                      data?.content?.media === null &&
                      (data?.content?.story === null ||
                        data?.content?.story === undefined)
                    ) {
                      displayText = false;
                    }
                    return (
                      <Box
                        pt={2}
                        className={
                          data?.direction === "inbound"
                            ? "interaction-list-header chat-main-box clearfix"
                            : "interaction-list-header chat-main-box chat-main-box-right clearfix"
                        }
                        key={data?.message_id}
                      >
                        <span className="interaction-list-user-data">
                          <span className="label">{data?.name}</span>
                          <Typography
                            className="label-user-name"
                            component="p"
                            sx={{ paddingTop: "6px" }}
                          >
                            {data?.username?.charAt(0) === "@"
                              ? data?.username
                              : "@" + data?.username}
                          </Typography>
                        </span>
                        <div className={`${displayText ? "chat-message" : ""}`}>
                          {data?.content?.media && (
                            <div className="chat-message-media">
                              {parseJsonData(data?.content?.media)?.map(
                                (item) => {
                                  return (
                                    <div className="single-interactions-media">
                                      {item?.image_data && (
                                        <img
                                          src={item?.image_data?.url}
                                          alt=""
                                          onError={handleImageError}
                                        />
                                      )}
                                      {item?.video_data && (
                                        <video controls>
                                          <source
                                            onError={handleVideoError}
                                            src={item?.video_data?.url}
                                          />
                                        </video>
                                      )}
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          )}
                          {displayText ? (
                            <StoryReplayPreview data={data} />
                          ) : null}
                        </div>

                        <div className="date-chat">
                          {data?.direction === "inbound" &&
                          window.localStorage.lang !== "ar" ? (
                            <>
                              <span>
                                {moment
                                  .unix(data?.created_at)
                                  .utc()
                                  .format("YYYY/MM/DD ")}
                              </span>
                              <span className="time-zone-space">
                                {moment
                                  .unix(data?.created_at)
                                  .utc()
                                  .format("LT")
                                  .toLowerCase()}
                                {getTimeZone() > 0
                                  ? `(${CheckValueLocale(
                                      "gmt",
                                      "",
                                      {},
                                      intl,
                                    )} +${getTimeZone()})`
                                  : `(${CheckValueLocale(
                                      "gmt",
                                      "",
                                      {},
                                      intl,
                                    )} -${getTimeZone()})`}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>
                                {moment
                                  .unix(data?.created_at)
                                  .utc()
                                  .format("LT")
                                  .toLowerCase()}
                                {getTimeZone() > 0
                                  ? `(${CheckValueLocale(
                                      "gmt",
                                      "",
                                      {},
                                      intl,
                                    )} +${getTimeZone()})`
                                  : `(${CheckValueLocale(
                                      "gmt",
                                      "",
                                      {},
                                      intl,
                                    )} -${getTimeZone()})`}
                              </span>
                              <span className="time-zone-space">
                                {moment
                                  .unix(data?.created_at)
                                  .utc()
                                  .format("YYYY/MM/DD ")}
                              </span>
                            </>
                          )}
                        </div>
                      </Box>
                    );
                  })}
                  {conversation?.conversation?.length >= 10 && (
                    <Button
                      variant="contained"
                      className="view-more load-more-btn"
                      onClick={handleLoadMoreMessgaes}
                    >
                      {CheckValueLocale("load_more", "", {}, intl)}
                    </Button>
                  )}
                </div>
                {handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
                retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
                allawedTrackers?.includes(+trackerId) ? (
                  <ReplyModal
                    replyData={conversation}
                    setText={setText}
                    text={text}
                    replyModalData={replyModalData}
                    setReplyModalData={setReplyModalData}
                    setShowSuccessModal={setShowSuccess}
                    showSuccessModal={showSuccess}
                    setShowErrorSnackBar={setShowErrorSnackBar}
                    setConversationMessages={setConersationMessgaes}
                    conversationMessages={conversationMessages}
                    isPrivateInteraction
                  />
                ) : null}
              </Box>
            )}
            {showErrorSnackBar ? (
              <SnackBar
                open={showErrorSnackBar}
                autoHideDuration={5000}
                severity="error"
                message={CheckValueLocale("please_try_again", "", {}, intl)}
                title={CheckValueLocale(
                  "wrong_request_parameter",
                  "",
                  {},
                  intl,
                )}
                handleClose={handleCloseRepliedSnack}
              />
            ) : null}
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleInstagramInteraction;
