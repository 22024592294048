import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import LogoDropZone from "./logoDropZone";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const UploadLogo = (props) => {
  const intl = useIntl();
  const [openLogoImg, setOpenLogoImg] = useState(false);

  const handleClickOpen = () => setOpenLogoImg(true);
  const handleClose = () => setOpenLogoImg(false);

  const handleUploadLogo = (img) => {
    props?.setSaveChangesFlag(true);
    props?.setLogoData({
      ...props?.logoData,
      logoUploading: true,
      logoImage: img,
      logoName: img.name,
      logoUploaded: true,
    });
  };

  const handleCloseLogoBox = () => {
    props?.setSaveChangesFlag(true);
    props?.setLogoData({
      ...props.logoData,
      logoUploading: false,
      logoUploaded: false,
      logoUpload: true,
      logoImage: "",
    });
  };

  return (
    <>
      <Box data-testid="logo" className="flexStyle" mb={1}>
        <Box className="logo-header">
          {CheckValueLocale("logo", "", {}, intl)}

          <Tooltip
            title={CheckValueLocale("logo_tooltip", "", {}, intl)}
            placement="top"
            arrow
          >
            <HelpIcon />
          </Tooltip>
        </Box>
        <LogoDropZone
          handleUploadLogo={handleUploadLogo}
          logoData={props.logoData}
        />
      </Box>
      {props?.logoData?.logoUploaded && (
        <Box className="logo-box">
          <img
            src={
              props?.logoData?.logoImage instanceof File
                ? URL.createObjectURL(
                    props?.logoData?.logoImage ? props.logoData?.logoImage : "",
                  )
                : props.logoData?.logoImage
            }
            alt="logo"
          />
          <FullscreenIcon
            onClick={handleClickOpen}
            style={{ color: "rgba(0, 0, 0, 0.6)" }}
          />

          <CloseIcon className="exit-logo-box" onClick={handleCloseLogoBox} />
          <p>Lucidyalogo.png</p>
        </Box>
      )}
      <Dialog open={openLogoImg} onClose={handleClose} className="logo-dialog">
        <DialogActions>
          <CloseIcon onClick={handleClose} />
        </DialogActions>
        <DialogContent>
          <img
            src={
              props?.logoData?.logoImage instanceof File
                ? URL.createObjectURL(
                    props?.logoData?.logoImage ? props.logoData?.logoImage : "",
                  )
                : props.logoData?.logoImage
            }
            alt="logo"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default UploadLogo;
