import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import MonitorCard from "../../components/monitorCard";
import AccountCard from "../../components/accountCard";
import CircularLoading from "components/circularProgress";

const ManageDialog = (props) => {
  const intl = useIntl();
  const {
    isLoading,
    manageDialog,
    dashboardInfo,
    handleCloseManageDialog,
    sidebarWidgets,
    availableMonitors,
    selectedMonitors,
    getCustomDashboardMonitors,
    getCustomDashboardAccounts,
    updateCustomDashboard,
    monitorsLoading,
    monitorsPagination,
    monitorsChanges,
    handleChangeMonitors,
  } = props;

  const [monitorsList, setMonitorsList] = useState([]);
  const [btnText, setBtnText] = useState("show_less");

  useEffect(() => {
    if (manageDialog) {
      if (dashboardInfo?.product === "sm") getCustomDashboardMonitors();
      else getCustomDashboardAccounts();
    }
  }, [manageDialog, dashboardInfo?.product]);

  useEffect(() => {
    if (availableMonitors) setMonitorsList(availableMonitors);
  }, [availableMonitors]);

  const handleClickShowLess = () => {
    if (btnText === "show_less") {
      let lessMonitors = availableMonitors?.slice(0, 8);
      setBtnText("show_more");
      setMonitorsList(lessMonitors);
    } else {
      setBtnText("show_less");
      setMonitorsList(availableMonitors);
    }
  };

  return (
    <Dialog
      id="custom-dashboard-manage-monitors-dialog"
      open={manageDialog ? true : false}
      onClose={handleCloseManageDialog}
    >
      <DialogTitle className="dialog-title">
        {CheckValueLocale(
          dashboardInfo?.product === "sm"
            ? "custom_dashboard_monitors"
            : "custom_dashboard_accounts",
          "",
          {},
          intl,
        )}
        <Box component={"span"} className="counter">
          ({selectedMonitors?.length}/{dashboardInfo?.max_monitors || 0})
        </Box>
      </DialogTitle>
      <DialogContent
        className={`dialog-content ${monitorsLoading ? "loading" : ""}`}
      >
        {monitorsLoading && !availableMonitors?.length ? (
          <CircularLoading />
        ) : (
          <Grid container>
            {dashboardInfo?.product === "sm"
              ? sidebarWidgets?.map((monitor, index) => {
                  let selected = dashboardInfo?.monitor_ids?.includes(
                    +monitor?.id,
                  );
                  let unselected =
                    dashboardInfo?.monitor_ids?.includes(+monitor?.id) &&
                    !selectedMonitors?.includes(+monitor?.id);
                  return (
                    <Grid item xs={6} p={1} key={monitor?.id}>
                      <MonitorCard
                        id={monitor?.id}
                        name={monitor?.name}
                        type={monitor?.monitor_type}
                        dataSources={monitor?.data_sources}
                        handleClick={handleChangeMonitors}
                        selected={selected}
                        unselected={unselected}
                      />
                    </Grid>
                  );
                })
              : null}

            {dashboardInfo?.product === "sm"
              ? monitorsList
                  ?.filter(
                    (monitor) =>
                      !dashboardInfo?.monitor_ids?.includes(+monitor?.id),
                  )
                  ?.map((monitor) => {
                    return (
                      <Grid item xs={6} p={1} key={monitor?.id}>
                        <MonitorCard
                          id={+monitor?.id}
                          name={monitor?.attributes?.name}
                          type={monitor?.attributes?.monitor_type_name}
                          dataSources={monitor?.attributes?.data_sources}
                          handleClick={handleChangeMonitors}
                          selected={selectedMonitors?.includes(+monitor?.id)}
                          unselected={false}
                        />
                      </Grid>
                    );
                  })
              : Object.keys(availableMonitors)?.map((dataSource, index) => {
                  const accounts = availableMonitors?.[dataSource]?.filter(
                    (item) => item?.attributes?.is_dm !== true,
                  );
                  const dm_accounts = availableMonitors?.[dataSource]?.filter(
                    (item) => item?.attributes?.is_dm === true,
                  );

                  return (
                    <Box className="accounts-grid-wrapper">
                      {accounts?.length > 0 ? (
                        <Grid container key={index}>
                          <Grid item xs={12} p={1}>
                            <Typography className="platform-title">
                              {getSocialIcon(dataSource)}
                              {`${CheckValueLocale(dataSource, "", {}, intl)}:`}
                            </Typography>
                          </Grid>
                          <Box className="platform-accounts-container">
                            <Grid container>
                              {accounts?.map((account) => {
                                let selected = selectedMonitors?.includes(
                                  +account?.id,
                                );
                                let unselected =
                                  dashboardInfo?.monitor_ids?.includes(
                                    +account?.id,
                                  ) &&
                                  !selectedMonitors?.includes(+account?.id);
                                return (
                                  <Grid item xs={4} p={1} key={account?.id}>
                                    <AccountCard
                                      id={account?.attributes?.id}
                                      name={account?.attributes?.name}
                                      handleClick={handleChangeMonitors}
                                      selected={selected}
                                      unselected={unselected}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Box>
                        </Grid>
                      ) : null}
                      {dm_accounts?.length > 0 ? (
                        <Grid container key={index}>
                          <Grid item xs={12} p={1}>
                            <Typography className="platform-title">
                              {getSocialIcon(`${dataSource}_private`)}
                              {`${CheckValueLocale(`${dataSource?.toLowerCase()}_dm`, "", {}, intl)}:`}
                            </Typography>
                          </Grid>
                          <Box className="platform-accounts-container">
                            <Grid container>
                              {dm_accounts?.map((account) => {
                                let selected = selectedMonitors?.includes(
                                  +account?.id,
                                );
                                let unselected =
                                  dashboardInfo?.monitor_ids?.includes(
                                    +account?.id,
                                  ) &&
                                  !selectedMonitors?.includes(+account?.id);
                                return (
                                  <Grid item xs={4} p={1} key={account?.id}>
                                    <AccountCard
                                      id={account?.attributes?.id}
                                      name={account?.attributes?.name}
                                      handleClick={handleChangeMonitors}
                                      selected={selected}
                                      unselected={unselected}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Box>
                        </Grid>
                      ) : null}
                    </Box>
                  );
                })}

            {dashboardInfo?.product === "sm" &&
            monitorsPagination?.pages > 1 ? (
              <Box className="show-more-container">
                <LucButton
                  id="custom-dashboard-manage-monitors-show-more-btn"
                  type="secondary"
                  variant="outline"
                  loading={monitorsLoading}
                  onClick={
                    monitorsPagination?.next
                      ? getCustomDashboardMonitors
                      : handleClickShowLess
                  }
                >
                  {CheckValueLocale(
                    monitorsPagination?.next ? "show_more" : btnText,
                    "",
                    {},
                    intl,
                  )}
                </LucButton>
              </Box>
            ) : null}
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={`dialog-actions has-message`}>
        <Box className="message-box">
          <FontAwesomeIcon icon={faTriangleExclamation} size="small" />
          <Typography className="message-text">
            {CheckValueLocale(
              "custom_dashboard_manage_monitors_only_widgets_msg",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
        <Box className="action-btns-box">
          <LucButton
            id="custom-dashboard-manage-monitors-cancel-btn"
            variant="flat"
            onClick={handleCloseManageDialog}
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            id="custom-dashboard-manage-monitors-save-changes-btn"
            loading={isLoading}
            disabled={!selectedMonitors?.length || monitorsChanges == false}
            onClick={() => {
              updateCustomDashboard();
              handleCloseManageDialog();
            }}
          >
            {CheckValueLocale("custom_dashboard_save_changes", "", {}, intl)}
          </LucButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ManageDialog;
