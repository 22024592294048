// pass hideLegend=false to hide graph default label and to display custom one
// pass showLogChart=true to display graph in algorithmic
// pass dataValues and dataNames to display graph names and values
// pass showZoomPercentage to display graph zoom slider
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const formatToolTip = (data, totalValues) => {
  let format = data[0].axisValue + "<br />",
    total = 0;
  data.forEach((item) => {
    if (item.data !== "" && !isNaN(item.data)) {
      format +=
        item.marker +
        " " +
        item.seriesName +
        ": " +
        item.data.toLocaleString() +
        "<br />";
      total += item.data;
    }
  });

  return format + totalValues + ": " + total.toLocaleString();
};

const StackedBarChart = (props) => {
  var {
    totalLabel,
    showLogChart,
    colors,
    greaterThanHundred,
    dataValues,
    dataNames,
    hideLegend,
    reachFunnel,
    fromThemesWidget,
  } = props;
  const intl = useIntl();
  const totalValues = CheckValueLocale("total", "", {}, intl);
  let series = dataNames?.map((dataName, index) => {
    return {
      name: dataName,
      cursor: "default",
      type: "bar",
      stack: totalLabel === undefined ? "bar" : showLogChart ? "" : "one",
      data: dataValues[index],
      emphasis: totalLabel === undefined && {
        focus: "series",
      },
      cursor: props.handleOpenQuickInspect ? "pointer" : "default",
      itemStyle: totalLabel !== undefined &&
        fromThemesWidget === undefined && {
          normal: {
            color: colors[index],
          },
        },
    };
  });
  let option = {
    color: props.dataColor,
    tooltip: {
      trigger: "axis",

      show: props.noData ? false : true,
      backgroundColor: "#fff",

      textStyle: {
        fontSize: 12,
      },
      padding: [10, 15],
      formatter: (val) => formatToolTip(val, totalValues),
    },
    barMaxWidth: "50px",
    legend:
      props.legendPosition === "right"
        ? {
            data: props.legend,
            itemGap: 25,
            type: "scroll",
            icon: "circle",
            orient: "vertical",
            itemHeight: 15,
            itemWidth: 15,
            right: 1,
            top: 20,
            show: hideLegend !== undefined ? hideLegend : true,
          }
        : props.legendPosition === "topLeft"
          ? {
              data: props.legend,
              itemGap: 15,
              itemHeight: 14,
              itemWidth: 14,
              left: 1,
              top: 0,
              show: hideLegend !== undefined ? hideLegend : true,
            }
          : props.legendPosition === "topRight"
            ? {
                data: props.legend,
                itemGap: 15,
                itemHeight: 14,
                itemWidth: 14,
                right: 1,
                top: 0,
                show: hideLegend !== undefined ? hideLegend : true,
              }
            : {
                data: props.legend,
                bottom: 0,
                show: hideLegend !== undefined ? hideLegend : true,
              },
    calculable: totalLabel ? true : false,
    grid:
      props.legendPosition === "right" && totalLabel === undefined
        ? {
            left: "1%",
            right: "14%",
            bottom: "14%",
            containLabel: true,
          }
        : totalLabel === undefined
          ? {
              left: "3%",
              right: "4%",
              bottom: props.sliderPercentage ? "30%" : "10%",
            }
          : {
              x: 80,
              x2: 40,
              y: 35,
              y2: 105,
              left: showLogChart ? "4%" : "1%",
              right: "1%",
              bottom: showLogChart ? "25%" : "20%",
              containLabel: showLogChart ? false : true,
            },
    xAxis: {
      data: props.dataLabels,
      type: "category",
      axisLabel: {
        fontSize: 11,
        color: "rgba(109, 115, 122, 0.74)",
        textStyle: {
          color: "rgba(0, 0, 0, 0.42)",
        },
      },
      axisLine: {
        show: true,
        onZero: true,
        lineStyle: {
          width: 0,
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: [
      totalLabel === undefined
        ? {
            type: "value",
          }
        : {
            type: showLogChart ? "log" : reachFunnel ? "value" : "value",
            min:
              greaterThanHundred === false && showLogChart
                ? 1
                : greaterThanHundred === true && showLogChart
                  ? 0.9
                  : reachFunnel
                    ? 1
                    : 0,
            showMinLabel: false,
            show: true,

            splitLine: {
              lineStyle: {
                type: "solid",
                color: "#F0EFEF",
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 0.42)",
              },
            },
            axisLabel: {
              show: reachFunnel ? true : true,
              margin: reachFunnel ? -25 : 0,
              textStyle: {
                color: "rgba(0, 0, 0, 0.42)",
              },
            },
          },
    ],
    // POPO
    series: series, // Display toolbox
    toolbox:
      totalLabel === undefined
        ? {
            show: props.showToolBox === false ? false : true,
            // NOTE:
            // COMMENTED , if need it please make sure not affected other widgets
            // feature: {
            //   magicType: {
            //     show: true,
            //     title: {
            //       line: "Lines",
            //       bar: "Bars",
            //     },
            //     type: ["line", "bar"],
            //   },
            // },
          }
        : {
            show: false,

            orient: "vertical",
            x: "right",
            y: "center",
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },

              magicType: {
                show: true,
                type: ["line", "bar", "stack", "tiled"],
              },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
    dataZoom: [
      totalLabel !== undefined && {
        start: props.sliderPercentage,
        end: props.showZoomPercentage ? 50 : 100,
        bottom: 15,

        show: props.showZoomPercentage ? true : false,

        fillerColor: "rgba(42, 170, 226, 0.06)",
        dataBackground: {
          areaStyle: {
            color: "transparent",
          },
        },
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(203, 213, 225, 1)",
          },
          areaStyle: {
            color: "transparent",
          },
        },
      },
    ],
  };

  if (props.sliderPercentage) {
    option["dataZoom"] = [
      {
        start: props.sliderPercentage,
        end: 100,
        height: 20,

        bottom: props.bottomLegend !== true ? 35 : 0,

        fillerColor: "rgba(42, 170, 226, 0.06)",
        dataBackground: {
          areaStyle: {
            color: "transparent",
          },
        },
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(203, 213, 225, 1)",
          },
          areaStyle: {
            color: "transparent",
          },
        },
      },
    ];
  }
  return (
    <ReactEcharts
      notMerge={true}
      option={option}
      theme="custom_theme"
      style={{ height: "400px", width: "100%" }}
    />
  );
};

export default StackedBarChart;
