import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Fragment } from "react/jsx-runtime";
import { CheckValueLocale } from "utils/helpers";

const slaLogsStatuses = {
  firstAutoAssignee: "sla_logs_first_assignee", // means the system has auto assigned the first assignee, assigned_by is empty for the first object
  autoAssignedTeam: "sla_logs_auto_assigned_team", // means the system has auto assigned the team with no user anssigned yet, assigned_by is empty for and next_team not and next user not.
  // this means the assignee has been changed to be empty
  teamUnassigned: "sla_logs_team_unassigned", // means the team has been unassigned, next_team_id is empty and the previous_team_id is not empty
  // this means the team has been changed to be empty
  teamChanged: "sla_logs_team_changed", // means the team has been changed, next_team_id is not empty and the previous_team_id is not empty
  // this means the team has been assigned to be empty
  teamAssigned: "sla_logs_team_assigned", // means the team has been assigned, previous_team_id is empty and the next_team_id is not empty
  // this means the assignee has been changed to be empty
  agentUnassigned: "sla_logs_agent_unassigned", // means the agent has been unassigned, next_agent_id is empty and the previous_agent_id is not empty
  // this means the assignee has been changed to be from one to another
  agentChanged: "sla_logs_assignee_changed", // means the agent has been changed, next_agent_id is not empty and the previous_agent_id is not empty
  // this means the assignee has been changed from empty to be assigned
  agentAssigned: "sla_logs_agent_assigned", // means the agent has been assigned, previous_agent_id is empty and the next_agent_id is not empty

  // deleted
  deletedUser: "sla_logs_deleted_user", // means the user has been deleted
  deletedTeam: "sla_logs_deleted_team", // means the team has been deleted
};

// generate logs based on the assigning_logs which is an array of objects that came from the backend to describe the assigning logs
// the logs are generated based on the following rules:
// 1. if the assigning_logs is empty, then no logs will be generated
// 2. if the assigning_logs has only one object, then the log will be generated based on the next_user_email
// 3. if the assigning_logs has more than one object, then the logs will be generated based on the following rules:
//    a. if the previous_team_id is not equal to the next_team_id, then the log will be generated based on the team
//    b. if the previous_user_email is not equal to the next_user_email, then the log will be generated based on the user
//    c. if the previous_team_id is equal to the next_team_id and the previous_user_email is equal to the next_user_email, then no logs will be generated
const generateLogs = (slaLogs = {}, users = [], teams = []) => {
  const assigningLogs = slaLogs?.assigning_logs || [];
  const logs = [];
  assigningLogs?.forEach((log, idx) => {
    const {
      assigned_by_id: by,
      next_team_id: nextTeam,
      next_user_id: to,
      previous_team_id: prevTeam,
      previous_user_id: from,
      assigned_at: date,
    } = log;
    // here we want already have all teams and users for the company so we can get the name of the team and the user
    // to show it in the logs
    const byUser = users?.find((user) => user?.attributes?.id === by)
      ?.attributes?.name;
    const fromUser = users?.find((user) => user?.attributes?.id === from)
      ?.attributes?.name;
    const toUser = users?.find((user) => user?.attributes?.id === to)
      ?.attributes?.name;
    const fromTeam = teams?.find((team) => team?.id === prevTeam)?.name;
    const toTeam = teams?.find((team) => team?.id === nextTeam)?.name;
    // first assignee, must be around two cases:
    // 1. the first assignee is auto assigned, then the by will be null
    // 2. the first assignee is assigned by someone, then the by will be the id of the user who assigned the first assignee
    if (!by) {
      // auto assignee, from null, to value but without by
      if (to && !by) {
        logs.push({
          by: null,
          from: null,
          to: toUser,
          date,
          type: slaLogsStatuses.firstAutoAssignee,
        });
      }
      // auto assign to team by, user id is null and team id is not null
      else if (nextTeam && !to && !by) {
        logs.push({
          by: null,
          from: null,
          to: toTeam,
          date,
          type: slaLogsStatuses.autoAssignedTeam,
          isTeam: true,
        });
      }
    } // change the team
    else if (prevTeam !== nextTeam) {
      // unassigned team, from value to null
      if (prevTeam && !nextTeam) {
        logs.push({
          by: byUser,
          from: fromTeam,
          to: null,
          date,
          type: slaLogsStatuses.teamUnassigned,
          isTeam: true,
        });
      }
      // changed team, from value to value
      else if (prevTeam && nextTeam) {
        logs.push({
          by: byUser,
          from: fromTeam,
          to: toTeam,
          date,
          type: slaLogsStatuses.teamChanged,
          isTeam: true,
        });
      }
      // assigned team, from null to value
      else if (!prevTeam && nextTeam) {
        logs.push({
          by: byUser,
          from: null,
          to: toTeam,
          date,
          type: slaLogsStatuses.teamAssigned,
          isTeam: true,
        });
      }
    }
    // changed agent
    else if (from !== to) {
      // unassigned agent, from value to null
      if (from && !to) {
        logs.push({
          by: byUser,
          from: fromUser,
          to: null,
          date,
          type: slaLogsStatuses.agentUnassigned,
        });
      }
      // changed agent, from value to value
      else if (from && to) {
        logs.push({
          by: byUser,
          from: fromUser,
          to: toUser,
          date,
          type: slaLogsStatuses.agentChanged,
        });
      }
      // assigned agent, from null to value
      else if (!from && to) {
        logs.push({
          by: byUser,
          from: null,
          to: toUser,
          date,
          type: slaLogsStatuses.agentAssigned,
        });
      }
    }
  });
  return logs;
};

const SlaLogs = ({ slaLogs }) => {
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const [logs, setLogs] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    setLogs(generateLogs(slaLogs, reduxSlaData?.users, reduxSlaData?.teams));
  }, [slaLogs]);
  let companyTimeZone = window?.localStorage?.companyInfo;

  if (!logs?.length) {
    return null;
  }
  return (
    <Box className="sla-logs-container">
      <Box>
        <Tooltip
          title={CheckValueLocale("sla_logs_tooltip", "", {}, intl)}
          placement="bottom"
          arrow
          classes={{ tooltip: "sidebar-sla-logs-tooltip" }}
        >
          <Typography span="h6" className="sla-logs-container-title">
            {CheckValueLocale("sla_logs", "", {}, intl)}
          </Typography>
        </Tooltip>
      </Box>
      <Box className="sla-logs-list">
        {logs?.map((log, index) => {
          const deletedTeamMessage = CheckValueLocale(
            slaLogsStatuses.deletedTeam,
            "",
            {},
            intl,
          );
          const deletedUserMessage = CheckValueLocale(
            slaLogsStatuses.deletedUser,
            "",
            {},
            intl,
          );
          const currentDeletedMessage = log?.isTeam
            ? deletedTeamMessage
            : deletedUserMessage;
          return (
            <Fragment key={index}>
              <Box className="sla-logs-item">
                <Typography className="sla-logs-item-details">
                  {CheckValueLocale(
                    log?.type,
                    "",
                    {
                      by: log?.by || deletedUserMessage,
                      from: log?.from || currentDeletedMessage,
                      to: log?.to || currentDeletedMessage,
                    },
                    intl,
                  )}
                </Typography>
                <Typography className="sla-logs-item-date">
                  <Typography component="span">
                    {new Date(log?.date).toLocaleDateString("en-GB")},
                  </Typography>
                  <Typography component="span">
                    {new Date(log?.date).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </Typography>
                  <Typography component="span">
                    {companyTimeZone >= 0
                      ? `(${CheckValueLocale(
                          "gmt",
                          "",
                          {},
                          intl,
                        )} +${companyTimeZone})`
                      : `(${CheckValueLocale(
                          "gmt",
                          "",
                          {},
                          intl,
                        )} -${companyTimeZone})`}
                  </Typography>
                </Typography>
              </Box>
              {index !== logs?.length - 1 ? (
                <Box className="sla-logs-item-separator" />
              ) : null}
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default SlaLogs;
