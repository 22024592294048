import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Alert, Box, Container } from "@mui/material";
import { CheckValueLocale, handleActiveFeature } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import Header from "shared/createHeader/createHeader.js";
import Steppers from "shared/steppers/steppers";
import StepperButtons from "shared/steppers/stepperButtons";
import CircularLoading from "../../../components/circularProgress";
import DashboardInformation from "./dashboardInformation";
import DashboardMonitors from "./createAggDashboard/aggDashboardMonitors";
import DashboardFunctions from "../dashboardFunctions";
import CustomDashboardController from "services/controllers/customDashboardController";
import "../style.scss";

const CreateDashboard = () => {
  const {
    steps,
    dashboardData,
    setdashboardData,
    dashboardErrors,
    handleNext,
    completed,
    setCompleted,
    errorMsg,
    setErrorMsg,
    activeStep,
    setActiveStep,
    isLoading,
    handleMonitorValueChange,
    selectedMonitors,
    setSelectedMonitors,
    handleSelectMonitor,
    handleInputChange,
    selectDashboardType,
    setdashboardErrors,
    exceedLimit,
    getTypesForDashboard,
    getMonitorsListForDashboard,
    monitorsList,
    showAllMonitors,
    showLessMonitors,
    handleShowAllMonitors,
    handleShowLessMonitors,
    dashboardList,
    selectedDashboardType,
    displayMonitorNameAndUsername,
    displayMonitorNameTooltip,
    dashboardTypesLoading,
    dashboardMonitorsLoading,
    submitCreateDashboard,
    isVaildDashboard,
    countMonitorsSelected,
    isCustomDashboard,
    setIsCustomDashboard,
    enabledAggregate,
    enabledCustomDashboard,
    smMonitorsCount,
    canCreateDashboard,
  } = DashboardFunctions();

  const intl = useIntl();
  const history = useHistory();

  const [isValidDashboardInfo, setIsValidDashboardInfo] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  let isSMEnabled = handleActiveFeature(
    "SM",
    "is_sl_custom_dashboards_enabled",
  );
  let isCxmEnabled = handleActiveFeature(
    "CXM",
    "is_cxm_custom_dashboards_enabled",
  );
  const slProduct = window.localStorage.getItem("sm_id");
  const cxmProduct = window.localStorage.getItem("cxm_id");
  const [selectedProduct, setSelectedProduct] = useState(
    isSMEnabled ? slProduct : cxmProduct,
  );
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });

  useEffect(() => {
    getTypesForDashboard();
  }, []);

  useEffect(() => {
    setdashboardData({
      ...dashboardData,
      dashabordName: dashboardData?.dashabordName,
    });
  }, [dashboardData.dashabordName]);

  const createNewDashboard = () => {
    setLoading(true);
    const selectedIds = selectedOptions?.map((item) => item?.id)?.join(",");
    const selectedVal = `${selectedIds}`;
    CustomDashboardController.createNewDashboard(
      dashboardData?.dashabordName,
      dashboardData?.dashboardTypeID,
      selectedVal,
      selectedProduct,
    ).then((result) => {
      if (!result?.errorMsg) {
        history?.push({
          pathname: `/dashboard/custom/edit/${result?.data?.data?.id}`,
          showCreatedSuccess: true,
        });
      } else {
        setSnackBar({
          open: true,
          title: "failed_error_message",
          message: "wrong_msg",
          severity: "error",
        });
      }
      setLoading(false);
    });
  };

  return (
    <Container maxWidth="xl">
      {/* ACTIVATE LOADING GIF AFTER ALL BE APIS  */}
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          <Header
            mainHeaderTitle={"utilities"}
            mainHeaderLink={"/social/monitor_list"}
            subHeaderTitle={"dashboards"}
            subHeaderLink={"/dashboard/dashboard_list"}
            cardHeaderHeading={"create_dashboard"}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            isCreateScreen
          />

          {handleActiveFeature("SM", "is_dashboards_enabled") &&
          smMonitorsCount < 2 ? (
            <Alert
              severity="info"
              id="create-dashboard-alert-msg"
              className="aggregate-alert"
            >
              {CheckValueLocale(
                "create_aggregate_dashboard_monitors_alert_msg",
                "",
                {},
                intl,
              )}
              <Link
                id="create-dashboard-alert-msg-new-monitor-btn"
                to={"/social/monitors/create_monitor"}
              >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                {CheckValueLocale("new_monitor", "", {}, intl)}
              </Link>
            </Alert>
          ) : null}

          <Box className="dashboard-container main-container">
            <Steppers
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              completed={completed}
              setCompleted={setCompleted}
              dashboardData={dashboardData}
              setdashboardData={setdashboardData}
              isValidDashboardInfo={isValidDashboardInfo}
              createDashboard
            />

            {activeStep === 0 ? (
              <DashboardInformation
                setIsValidDashboardInfo={setIsValidDashboardInfo}
                handleInputChange={handleInputChange}
                selectDashboardType={selectDashboardType}
                dashboardData={dashboardData}
                setdashboardData={setdashboardData}
                dashboardErrors={dashboardErrors}
                setdashboardErrors={setdashboardErrors}
                dashboardList={dashboardList}
                selectedDashboardType={selectedDashboardType}
                dashboardTypesLoading={dashboardTypesLoading}
                selectedOptions={selectedOptions}
                enabledAggregate={enabledAggregate}
                enabledCustomDashboard={enabledCustomDashboard}
                canCreateDashboard={canCreateDashboard}
              />
            ) : (
              <DashboardMonitors
                dashboardData={dashboardData}
                setdashboardData={setdashboardData}
                handleMonitorValueChange={handleMonitorValueChange}
                selectedMonitors={selectedMonitors}
                setSelectedMonitors={setSelectedMonitors}
                handleSelectMonitor={handleSelectMonitor}
                exceedLimit={exceedLimit}
                getMonitorsListForDashboard={getMonitorsListForDashboard}
                monitorsList={monitorsList}
                showAllMonitors={showAllMonitors}
                showLessMonitors={showLessMonitors}
                handleShowAllMonitors={handleShowAllMonitors}
                handleShowLessMonitors={handleShowLessMonitors}
                displayMonitorNameAndUsername={displayMonitorNameAndUsername}
                displayMonitorNameTooltip={displayMonitorNameTooltip}
                dashboardMonitorsLoading={dashboardMonitorsLoading}
                countMonitorsSelected={countMonitorsSelected}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                isCustomDashboard={isCustomDashboard}
                setIsCustomDashboard={setIsCustomDashboard}
                loading={loading}
                setLoading={setLoading}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                slProduct={slProduct}
                cxmProduct={cxmProduct}
                snackBar={snackBar}
                setSnackBar={setSnackBar}
                isSMEnabled={isSMEnabled}
                isCxmEnabled={isCxmEnabled}
              />
            )}
            <StepperButtons
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              disableNextStep={!isValidDashboardInfo}
              handleNext={handleNext}
              createDashboard
              dashboardData={dashboardData}
              setdashboardData={setdashboardData}
              submitCreateDashboard={submitCreateDashboard}
              isVaildDashboard={isVaildDashboard}
              selectedDashboardType={selectedDashboardType}
              selectedOptions={selectedOptions}
              isCustomDashboard={isCustomDashboard}
              createCustomDashboard={createNewDashboard}
            />
          </Box>
        </>
      )}
    </Container>
  );
};

export default CreateDashboard;
