import { useState } from "react";
import { Menu, MenuItem, IconButton, Box, Tooltip } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import {
  Autorenew,
  MoreHoriz,
  SyncDisabled,
  Delete,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faUnlink,
  faPen,
  faEye,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import Repeat from "images/shared-images/repeat.svg";
import DisConnect from "images/shared-images/dis-connect.svg";
//Locals
import { useIntl } from "react-intl";
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();
  let history = useHistory();

  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const open = Boolean(stats?.anchorEl);

  const handleClick = (event) => {
    setStats({ anchorEl: event.currentTarget });
  };
  const handleClose = () => {
    setStats({ anchorEl: null });
  };
  window.addEventListener("scroll", (event) => {
    if (open) {
      handleClose();
    }
  });
  return (
    <Box className="user-management-menu-wrapper">
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          aria-label="more"
          id="settings-dropdown-user-management-long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl.locale == "ar" ? "left" : "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl.locale == "ar" ? "left" : "right",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        className="user-management-list"
      >
        <MenuItem
          onClick={() =>
            history.push(`/settings/user_managements/view/${props?.id}`)
          }
          disableRipple
          className="option__btn"
          id={`user-management-dropdown-veiw-${props?.id ?? "id"}`}
        >
          <FontAwesomeIcon icon={faEye} />
          {CheckValueLocale("view_team", "", {}, intl)}
        </MenuItem>
        <MenuItem
          disableRipple
          disabled={props.status == "invited" ? true : false}
          className="option__btn"
          id={`user-management-dropdown-edit-${props?.id ?? "id"}`}
          style={{
            padding: "0px",
          }}
        >
          <Link
            to={{
              pathname: `/settings/user_managements/edit/${props.user.id}`,
              state: { id: props.user.id },
            }}
            style={{
              width: "100%",
            }}
          >
            <FontAwesomeIcon icon={faPen} />
            {CheckValueLocale("edit", "", {}, intl)}
          </Link>
        </MenuItem>
        {props.status !== "deactivated" ? (
          <MenuItem
            onClick={() => {
              props.deactiveUser(
                props.id,
                props.index,
                props.page,
                "deactivated",
              );
            }}
            disableRipple
            disabled={props.status === "invited" ? true : false}
            className="option__btn"
            id={`user-management-dropdown-de-active-${props?.id ?? "id"}`}
          >
            <img src={DisConnect} />
            {CheckValueLocale("deactive", "", {}, intl)}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              props.activeUser(props.id, props.index, props.page, "active");
            }}
            disableRipple
            className="option__btn"
            id={`user-management-dropdown-re-active-${props?.id ?? "id"}`}
          >
            <SyncDisabled />
            {CheckValueLocale("re_active", "", {}, intl)}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            props.inviteUser(props.id);
            handleClose();
          }}
          disableRipple
          disabled={props.status !== "invited" ? true : false}
          className="option__btn"
          id={`user-management-dropdown-re-invite-${props?.id ?? "id"}`}
        >
          <img src={Repeat} />
          {CheckValueLocale("reinvite", "", {}, intl)}
        </MenuItem>
        {handlelUserRoles("GENERAL", "DESTROY_USER") && (
          <>
            <MenuItem
              onClick={() => {
                props.openDeleteModal(
                  props.id,
                  props.index,
                  props.page,
                  props.name,
                );
                handleClose();
              }}
              disableRipple
              className="option__btn"
              id={`user-management-dropdown-delete-${props?.id ?? "id"}`}
            >
              <FontAwesomeIcon icon={faTrash} />
              {CheckValueLocale("delete", "", {}, intl)}
            </MenuItem>
          </>
        )}
      </Menu>
    </Box>
  );
};
export default OptionsDropDown;
