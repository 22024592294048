import React from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Grid } from "@mui/material";
import SnackBar from "components/snackBar";
import CustomDashboardHeader from "../components/customDashboardHeader";
import CustomDashboardSidebar from "./customDashboardSidebar/sidebar";
import CustomDashboardContent from "./customDashboardContent/content";
import "./style.scss";
import EditorFunctions from "./editorFunctions";

const CustomWidgetEdit = () => {
  const intl = useIntl();
  const params = useParams();
  const {
    isLoading,
    isContentLoading,
    dashboardInfo,
    sidebarWidgets,
    contentWidgets,
    setContentWidgets,
    updateCustomDashboard,
    getCustomDashboardMonitors,
    getCustomDashboardAccounts,
    availableMonitors,
    selectedMonitors,
    setSelectedMonitors,
    monitorsLoading,
    monitorsPagination,
    handleAddWidget,
    handleDeleteWidget,
    monitorsChanges,
    handleChangeMonitors,
    changesFlag,
    setChangesFlag,
    snackBarData,
    setSnackBarData,
    handleWidgetImage,
  } = EditorFunctions(params);

  const handleCloseSnackBar = () => {
    setSnackBarData({
      open: false,
      severity: "",
      title: "",
      message: "",
    });
  };

  return (
    <Box id="custom-dashboard-editor">
      <CustomDashboardHeader
        isLoading={isLoading || isContentLoading}
        dashboardInfo={dashboardInfo}
        updateCustomDashboard={updateCustomDashboard}
        changesFlag={changesFlag}
        isEdit
      />
      <Grid container>
        <Grid item xs={3}>
          <CustomDashboardSidebar
            isLoading={isLoading}
            monitorsLoading={monitorsLoading}
            dashboardInfo={dashboardInfo}
            sidebarWidgets={sidebarWidgets}
            getCustomDashboardMonitors={getCustomDashboardMonitors}
            getCustomDashboardAccounts={getCustomDashboardAccounts}
            availableMonitors={availableMonitors}
            selectedMonitors={selectedMonitors}
            setSelectedMonitors={setSelectedMonitors}
            updateCustomDashboard={updateCustomDashboard}
            monitorsPagination={monitorsPagination}
            handleAddWidget={handleAddWidget}
            monitorsChanges={monitorsChanges}
            handleChangeMonitors={handleChangeMonitors}
            contentWidgets={contentWidgets}
            handleWidgetImage={handleWidgetImage}
          />
        </Grid>
        <Grid item xs={9}>
          <CustomDashboardContent
            isContentLoading={isContentLoading}
            dashboardInfo={dashboardInfo}
            contentWidgets={contentWidgets}
            setContentWidgets={setContentWidgets}
            handleDeleteWidget={handleDeleteWidget}
            setChangesFlag={setChangesFlag}
            handleWidgetImage={handleWidgetImage}
          />
        </Grid>
      </Grid>
      <SnackBar
        open={snackBarData?.open}
        severity={snackBarData?.severity}
        title={CheckValueLocale(
          snackBarData?.title,
          "",
          { maxWidgets: dashboardInfo?.max_widgets },
          intl,
        )}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
        handleClose={handleCloseSnackBar}
        alertStyle={{ maxWidth: "372px" }}
      />
    </Box>
  );
};

export default CustomWidgetEdit;
