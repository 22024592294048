import React from "react";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Typography,
  Grid,
  Box,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";

import PopupModal from "../../../../../../../../components/popupModal";

import monitorsController from "../../../../../../../../services/controllers/monitorsController";
import topicsFunctions from "../topicsFunctions";
import "../style.scss";
import { getSocialIcon } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers";

const TopicManage = (props) => {
  const intl = useIntl();

  const {
    monitorTopics,
    setMonitorTopics,
    setTopicRemoved,
    setOpenSnakbar,
    setTopicDeletedNumber,
  } = props;
  const { moniotrSelectedDataSources, collectDataSourcesArray } =
    topicsFunctions();

  const [open, setOpen] = useState(props.openManageModal);
  const [selectOpened, setSelectOpened] = useState(false);
  const [selectTopicOpened, setSelectTopicOpened] = useState(false);

  const [monitorsList, setMoniotrsList] = useState([]);
  const [monitorSelected, setMonitorSelected] = useState(0);
  const [monitorSelectedName, setMonitorSelectedName] = useState("");

  const [topicsArray, setTopicsArray] = useState([]);
  const [topicsExist, setTopicsExist] = useState(false); // should be | false | by default
  const [topicsSelectedArray, setTopicsSelectedArray] = useState([]);

  const handleClose = () => {
    props.handleOpenManageModal(false);
  };

  const handleSelectDisabledValue = (e) => {
    if (e) {
      setSelectOpened(true);
    }
  };

  const handleSelectDisabledTopic = (e) => {
    if (e) {
      setSelectTopicOpened(true);
    }
  };

  // From Controllers
  const getAllTopicsMonitors = () => {
    monitorsController.getTopicsMonitors().then((data) => {
      setMoniotrsList(data?.data);
      setMonitorSelected(data?.data[0]?.id);
      collectDataSourcesArray(data?.data);
    });
  };

  const getTopicsForMonitor = (monitor_Id) => {
    monitorsController.getTopicsForMonitor(monitor_Id).then((data) => {
      let newData = data.data;

      if (newData !== undefined && newData.length !== 0) {
        setTopicsArray(newData);
        setTopicsExist(true);
      } else {
        setTopicsExist(false);
      }
    });
  };

  const handleDeleteTopics = (monitor_Id, topicsDeletedArray) => {
    let deletedDone = false;

    monitorsController
      .deleteTopicsFromMonitor(monitor_Id, topicsDeletedArray)
      .then((data) => {
        let error = data?.errorMsg?.response?.status,
          deletedFlag = data.data.status;

        if (
          (deletedFlag === 202 || deletedFlag === 200) &&
          error === undefined
        ) {
          deletedDone = true;
          setTopicRemoved(true);
          setTopicDeletedNumber(topicsSelectedArray.length);
        } else if (error === 400) {
          deletedDone = false;
          setTopicRemoved(false);
        } else {
          deletedDone = false;
          setTopicRemoved(false);
        }
        setOpenSnakbar(true);
      });
    return deletedDone;
  };

  useEffect(() => {
    getAllTopicsMonitors();
  }, []);

  const handleSelectTopic = (event) => {
    setTopicsSelectedArray(event.target.value);
  };

  const handleMonitorValue = (monitorId, monitorName) => {
    setMonitorSelected(monitorId);
    setMonitorSelectedName(monitorName);
    getTopicsForMonitor(monitorId);
  };

  const handleRemoveTopics = (e) => {
    let deletedArray = [...monitorTopics];
    topicsSelectedArray.map((topicName, index) => {
      deletedArray.map((arr, i) => {
        if (arr?.name === topicName) {
          deletedArray.splice(i, 1);
        }
      });
    });

    setMonitorTopics(deletedArray);
    let topicsDeletedArray = { topics: topicsSelectedArray.toString() };

    handleDeleteTopics(monitorSelected, topicsDeletedArray);

    handleClose();
  };

  const ManageBody = (
    <Grid id="import-body" item lg={6} xl={6}>
      <Box className="shared-dialog-body manage-topics-remove-label">
        <p>{CheckValueLocale("manage_topics_popup_label", "", {}, intl)}</p>
      </Box>
      <Box className="import-body-input import-body-input-manage">
        <InputLabel className="body-input-label">
          {CheckValueLocale("monitor", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="report-platform-select-label"
          id="import-platform-simple-select"
          fullWidth
          name="monitor"
          className="import-select-box"
          value={selectOpened ? monitorSelected : "none"}
          defaultValue={selectOpened ? monitorSelected : "none"}
          onOpen={(e) => {
            handleSelectDisabledValue(e);
          }}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: { sm: 150, xs: 150, md: 220, lg: 220, xl: 220 },
              },
            },
          }}
        >
          <MenuItem value="none" disabled id="monitors-topics-placeholder">
            {selectOpened
              ? CheckValueLocale("results", "", {}, intl)
              : CheckValueLocale("select_placeholder", "", {}, intl)}
          </MenuItem>
          {monitorsList !== undefined
            ? monitorsList?.map((monitor) => (
                <MenuItem
                  onClick={() =>
                    handleMonitorValue(monitor?.id, monitor?.attributes?.name)
                  }
                  value={monitor?.id}
                  key={monitor?.id}
                  id={`monitors-topics-${monitor?.attributes?.name ?? "name"}`}
                >
                  <div className="icons-before-monitors-list">
                    {moniotrSelectedDataSources !== undefined &&
                      moniotrSelectedDataSources !== [] &&
                      moniotrSelectedDataSources.map((source) =>
                        source.id === monitor?.id
                          ? source.data_sources.length > 1
                            ? source.data_sources.map((s) => {
                                return getSocialIcon(s);
                              })
                            : getSocialIcon(source.data_sources)
                          : null,
                      )}
                  </div>

                  <div className="topic-monitor-names-list">
                    {monitor?.attributes?.name}
                  </div>
                </MenuItem>
              ))
            : null}
        </Select>
      </Box>

      <Box className="import-body-input import-body-input-manage">
        <InputLabel className="body-input-label">
          {CheckValueLocale("topics", "", {}, intl)}
        </InputLabel>

        <Select
          fullWidth
          multiple
          labelId="import-body-input-label"
          name="manualTopicsArray"
          id="import-platform-topics-select"
          value={topicsSelectedArray}
          native={false}
          displayEmpty={true}
          onOpen={(e) => {
            handleSelectDisabledTopic(e);
          }}
          onChange={(e) => handleSelectTopic(e)}
          input={<OutlinedInput />}
          renderValue={(topicsSelectedArray) => {
            if (topicsSelectedArray.length === 0) {
              return CheckValueLocale("select_placeholder", "", {}, intl);
            }
            return topicsArray
              .filter((val) =>
                topicsSelectedArray.includes(val.attributes.name),
              )
              .map((val) => {
                return val.attributes.name;
              })
              .join(", ");
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: { sm: 130, xs: 130, md: 130, lg: 130, xl: 200 },
              },
            },
          }}
          className="import-select-box"
        >
          <MenuItem
            value={[""]}
            disabled
            className="report-multi-menuItem"
            id={`monitors-topics-select-topics`}
          >
            <Checkbox checked={false} />
            <ListItemText
              primary={CheckValueLocale("select_topics", "", {}, intl)}
            />
          </MenuItem>
          {topicsArray.map((val) => (
            <MenuItem
              key={`dataSource-multi-${val.attributes.name}`}
              value={val.attributes.name}
              disabled={false}
              className="report-multi-menuItem"
              id={`monitors-topics-${val?.attributes?.name ?? "name"}`}
            >
              <Checkbox
                checked={topicsSelectedArray.indexOf(val.attributes.name) > -1}
              />
              <ListItemText primary={val?.attributes?.name} />
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Grid>
  );

  return (
    <Box className="Import-topics-box">
      <PopupModal
        title={CheckValueLocale("manage_topics_popup", "", {}, intl)}
        body={ManageBody}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("remove_topic", "", {}, intl)}
        open={open}
        close={handleClose}
        disabled={topicsSelectedArray.length === 0}
        classeName="import-topics-window"
        modalStyle="import-topics-modal"
        addClasses="import-topics-actions"
        topicsExist
        accept={handleRemoveTopics} //save only option
        minWidth="600px"
      />
    </Box>
  );
};

export default TopicManage;
