import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faEllipsisH,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { dummyQuestions } from "pages/SurveyBuilder/dummyQuestions";

const DragableQuestions = (props) => {
  const {
    sectionQuestions,
    surveySections,
    setSurveySections,
    filteredSections,
    sectionIndex,
    sqIndex,
    setSQIndex,
    ssIndex,
    setSSIndex,
    anchorEl,
    setAnchorEl,
    open,
    setOperationPerformed,
    setNewIndex,
    setStats,
    scrollToIdFunc,
    proceedError,
  } = props;

  const intl = useIntl();

  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const [openedMoreMenuIndex, setOpenedMoreMenuIndex] = useState(null);
  const clickQuesMoreMenu = (e, index) => {
    setOpenedMoreMenuIndex(index);
    setMoreAnchorEl(e.currentTarget);
  };
  const closeQuesMoreMenu = (e) => {
    setOpenedMoreMenuIndex(null);
    setMoreAnchorEl(null);
  };

  const handleListItemClick = (e, index) => {
    setSSIndex(sectionIndex + 1);
    setSQIndex(index + 1);
    scrollToIdFunc(`section-${sectionIndex}-question-${index}`);
  };

  const handleOpenDeleteModal = (sectionIndex, index) => {
    closeQuesMoreMenu();
    setNewIndex(index);
    setStats({
      openDeleteModal: true,
      addClasses: "model-buttons",
      title: `${CheckValueLocale("delete_question", "", {}, intl)}`,
      body: (
        <Box className="delete-pop">
          <Box className="delete-pop-title">
            {intl.formatMessage(
              { id: "are_you_sure_you_delete" },
              {
                number: CheckValueLocale(index ? index + 1 : "1", "", {}, intl),
              },
            )}
          </Box>
          <Box className="delete-popup delete-question-msg">
            {CheckValueLocale("this_action_cant_be_undone", "", {}, intl)}
          </Box>
        </Box>
      ),
      modalRightBtn: CheckValueLocale("yes_delete", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      warning: true,
      sIndex: sectionIndex,
      qIndex: index,
    });
  };

  const handleDuplicateQuestion = (e, ques, qIndex) => {
    e.stopPropagation();
    closeQuesMoreMenu();
    let sectionQuestions = [...filteredSections[sectionIndex]?.questions];
    dummyQuestions?.forEach((question) => {
      const typeMatch =
        CheckValueLocale(ques?.question?.type, "", {}, intl) ===
        CheckValueLocale(question?.question?.type, "", {}, intl);
      if (typeMatch) {
        const items = {};
        items.question = {};
        items.question.text = `${ques?.question?.text} (${CheckValueLocale(
          "survey_copy",
          "",
          {},
          intl,
        )})`;
        items.question.description = question?.question?.description;
        items.question.editable = question?.question?.editable;
        items.question.type = question?.question?.type;
        items.is_deleted = false;
        if (ques?.is_proceed_on_answer !== undefined)
          items.is_proceed_on_answer = false;
        if (ques?.is_proceed_editable !== undefined)
          items.is_proceed_editable = true;

        items.question.constraints = {};
        Object.keys(question?.question?.constraints)?.forEach((key) => {
          if (key === "min_number" || key === "max_number") {
            items.question.constraints[key] = {};
            items.question.constraints[key].checked =
              ques?.question?.constraints[key]?.checked;
            items.question.constraints[key].value =
              ques?.question?.constraints[key]?.value;
          } else
            items.question.constraints[key] = ques?.question?.constraints[key];
        });
        if (
          ques?.question?.items !== undefined ||
          ques?.question?.items?.length >= 0
        ) {
          let itemsArray = [];
          if (ques?.question?.items?.length > 0) {
            ques?.question?.items?.map((i) => {
              const questionItems = {};
              questionItems[Object.keys(i)[0]] = Object.values(i)[0];
              itemsArray.push(questionItems);
            });
          }
          items.question.items = itemsArray;
        }
        sectionQuestions?.splice(qIndex + 1, 0, items);
      }
    });

    filteredSections[sectionIndex].questions = sectionQuestions;
    setSurveySections([...surveySections]);
    setSQIndex(qIndex + 2);
    setSSIndex(sectionIndex + 1);
    setOperationPerformed("operationsPerformed");
    scrollToIdFunc(`section-${sectionIndex}-question-${qIndex + 1}`);
  };

  const handleDropEnd = (param) => {
    if (!param?.destination) return;
    let unFilteredSrc = filteredSections[sectionIndex]?.questions?.findIndex(
      (ques) =>
        ques?.question ===
        filteredSections[sectionIndex]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )[param?.source?.index]?.question,
    );
    let unFilteredDest = filteredSections[sectionIndex]?.questions?.findIndex(
      (ques) =>
        ques?.question ===
        filteredSections[sectionIndex]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )[param?.destination?.index]?.question,
    );
    if (unFilteredSrc === unFilteredDest) return;
    const updatedQuestions = filteredSections[sectionIndex]?.questions;
    const [removedQuestion] = updatedQuestions?.splice(unFilteredSrc, 1);
    updatedQuestions?.splice(unFilteredDest, 0, removedQuestion);
    filteredSections[sectionIndex].questions = updatedQuestions;
    setSurveySections([...surveySections]);
    setSSIndex(sectionIndex + 1);
    setSQIndex(param?.destination?.index + 1);
    scrollToIdFunc(
      `section-${sectionIndex}-question-${param?.destination?.index}`,
    );
  };

  return (
    <Box>
      <DragDropContext onDragEnd={(param) => handleDropEnd(param)}>
        <Droppable droppableId="droppable-1">
          {(provided, snapshot) => (
            <List
              provided={provided}
              ref={provided.innerRef}
              component="nav"
              aria-label="main mailbox folders"
              data-testid="list_of_questions"
            >
              {sectionQuestions?.filter((ques) => ques?.is_deleted !== true)
                ?.length < 1 && (
                <Box className="content-tab-empty-questions">
                  <Typography
                    component="p"
                    style={{ textTransform: "none !important" }}
                  >
                    {CheckValueLocale(
                      "empty_questions_msg",
                      "",
                      {},
                      intl,
                    ).toUpperCase()}
                  </Typography>
                </Box>
              )}

              {sectionQuestions &&
                sectionQuestions
                  ?.filter((ques) => ques?.is_deleted !== true)
                  ?.map((question, index) => {
                    const isMoreOpen = openedMoreMenuIndex === index;
                    return (
                      <Draggable
                        draggableId={`${index}`}
                        index={index}
                        key={index}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`nav-question-item ${
                              ssIndex === sectionIndex + 1 &&
                              sqIndex === index + 1 &&
                              `active`
                            } ${
                              ssIndex === sectionIndex + 1 &&
                              sqIndex === index + 1 &&
                              proceedError === index &&
                              `question-error`
                            }`}
                            style={{
                              ...provided.draggableProps.style,
                              boxShadow: snapshot.isDragging
                                ? "0 0 .4rem #666"
                                : "none",
                            }}
                          >
                            <ListItemButton
                              selected={
                                ssIndex === sectionIndex + 1 &&
                                sqIndex === index + 1
                              }
                              onClick={(e) => handleListItemClick(e, index)}
                              className="question-card"
                              key={index}
                            >
                              <Box
                                component="span"
                                className="question-index"
                              >{`${index + 1}.`}</Box>
                              <ListItemText
                                primary={question?.question?.text}
                                className="question-title"
                              />
                              <Box>
                                <Tooltip
                                  arrow
                                  placement="bottom"
                                  title={CheckValueLocale("more", "", {}, intl)}
                                >
                                  <Box
                                    className={`question-more-btn ${
                                      isMoreOpen === true && `active`
                                    }`}
                                  >
                                    <LucButton
                                      id="more-button"
                                      aria-controls={
                                        isMoreOpen ? "more-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        isMoreOpen ? "true" : undefined
                                      }
                                      variant="flat"
                                      size="small"
                                      minWidth={36}
                                      onClick={(e) =>
                                        clickQuesMoreMenu(e, index)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faEllipsisH}
                                        color="#000000"
                                      />
                                    </LucButton>
                                  </Box>
                                </Tooltip>
                                <Menu
                                  id="more-menu"
                                  anchorEl={moreAnchorEl}
                                  open={isMoreOpen}
                                  onClose={(e) => closeQuesMoreMenu(e)}
                                >
                                  <MenuItem
                                    onClick={(e) =>
                                      handleDuplicateQuestion(
                                        e,
                                        question,
                                        index,
                                      )
                                    }
                                    id={`survey-builder-control-section-duplicate`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faClone}
                                      fontSize="small"
                                    />
                                    <Typography component="h1" mx={1}>
                                      {CheckValueLocale(
                                        "duplicate_question",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    </Typography>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      handleOpenDeleteModal(sectionIndex, index)
                                    }
                                    id={`survey-builder-control-section-delete`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      fontSize="small"
                                    />
                                    <Typography component="h1" mx={1}>
                                      {CheckValueLocale("delete", "", {}, intl)}
                                    </Typography>
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </ListItemButton>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default DragableQuestions;
