import SingleTweet from "../api/singleTweet";

let SingleTweetController = {};

SingleTweetController.getAnalysis = (id, monitor_id) => {
  let queryData = {
    product_id: localStorage.sm_id,
  };
  return new Promise(function (resolve) {
    SingleTweet.getAnalysis(id, monitor_id, queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SingleTweetController.createAnalysisSingleTweet = (
  monitor_id,
  tweet_id,
  screen_name,
  is_update,
  cap_count,
  startDate,
  endDate,
) => {
  let queryData = {
    product_id: localStorage.sm_id,
    monitor_id: monitor_id,
    tweet_id: tweet_id,
    screen_name: screen_name,
    is_update: is_update === true ? true : false,
    cap_count: cap_count,
    start_date: startDate,
    end_date: endDate,
  };
  return new Promise(function (resolve) {
    SingleTweet.createAnalysisSingleTweet(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error?.response?.data?.msg,
          status: error?.response?.status,
        });
      });
  });
};

SingleTweetController.getExportPPT = (
  id,
  monitor_id,
  langValue,
  product_id,
  exportPpt,
) => {
  return new Promise(function (resolve) {
    SingleTweet.getExportPPT(id, monitor_id, product_id, exportPpt, langValue)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default SingleTweetController;
