import { Box, Button, Tooltip } from "@mui/material";
import profileImg from "images/engagements-images/profileImg.png";
import { faExternalLink, faLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  CheckValueLocale,
  extractDisplayInfoFromDataSource,
  handlelUserRoles,
} from "utils/helpers";
import { useIntl } from "react-intl";

const Profile = ({ profileData, selectedCard }) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(`
      ${window.location.origin}
      /audience/user-profile/
      ${profileData?.profile_id}
    `);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  let activeCdp = JSON.parse(window.localStorage?.activeProducts).filter(
    (product) => product?.active && product?.name == "CDP",
  );

  const handleImageError = (event) => {
    event.target.src = profileImg;
  };
  const isTwitter = selectedCard?.data_source?.toLowerCase?.() === "twitter";
  const isInstagram =
    selectedCard?.data_source?.toLowerCase?.() === "instagram";
  const isDM = selectedCard?.isDm;
  const isRTL = intl?.locale === "ar";
  const { profilePicture, displayName, subName } =
    extractDisplayInfoFromDataSource(selectedCard, {
      displayName: selectedCard?.user_name
        ? isDM && (isTwitter || isInstagram)
          ? selectedCard?.name
          : selectedCard?.user_name
        : "user",
      subName:
        isTwitter || isInstagram
          ? isDM
            ? selectedCard?.user_name
            : selectedCard?.screen_name
          : null,
      profilePicture: profileData?.profile_image
        ? profileData?.profile_image
        : profileImg,
    });

  return (
    <Box className="details-side-bar-profile">
      {profileData?.profile_id &&
      handlelUserRoles("CDP", "VIEW_PROFILE") &&
      activeCdp?.length ? (
        <Tooltip
          title={
            copied
              ? CheckValueLocale("link_copied", "", {}, intl)
              : CheckValueLocale("copy_profile_link", "", {}, intl)
          }
          id={`${copied ? "copied-tooltip" : "copy-tooltip"}`}
          placement="bottom"
        >
          <Box className="link-copy" onClick={handleCopy}>
            <FontAwesomeIcon icon={faLink} />
          </Box>
        </Tooltip>
      ) : null}
      <img
        src={profilePicture}
        onError={handleImageError}
        className="profile-img"
      />
      <Box className="profile-user-box">
        <p className="profile-side-bar-name">{displayName}</p>
        {(isTwitter || (isInstagram && isDM)) && subName ? (
          <span className="profile-side-bar-subname">
            {isRTL ? `${subName}@` : `@${subName}`}
          </span>
        ) : null}
      </Box>
      {profileData?.profile_id &&
      handlelUserRoles("CDP", "VIEW_PROFILE") &&
      activeCdp?.length ? (
        <Button
          variant="outlined"
          id="visit-cdp"
          className="Visit-btn"
          onClick={() =>
            window.open(
              `/audience/user-profile/${profileData?.profile_id}`,
              "_blank",
            )
          }
        >
          {" "}
          <FontAwesomeIcon icon={faExternalLink} />{" "}
          {CheckValueLocale("visit_cdp_profile", "", {}, intl)}{" "}
        </Button>
      ) : null}
    </Box>
  );
};
export default Profile;
