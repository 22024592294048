import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Typography,
  Card,
  Grid,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";

import OverviewRetweetIcon from "../../../../images/notification-icons/OverviewRetweet.svg";
import OverviewHeartIcon from "../../../../images/notification-icons/OverviewHeart.svg";
import ReplyIcon from "../../../../images/notification-icons/Reply.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "components/no-Data/noDataFound";

const EngagersList = (props) => {
  const intl = useIntl();
  const { engagerListTitle, users } = props;

  return (
    <Grid item xs={6} className="engager-list">
      <Card className="table-card">
        <CardContent>
          <Typography variant="body1" className="table-title">
            {CheckValueLocale(engagerListTitle, "", {}, intl)}
          </Typography>
          {isEmptyArray(users) ? (
            <NoDataFound />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {CheckValueLocale("engager", "", {}, intl)}
                  </TableCell>
                  <TableCell sx={{ width: "100%" }}>
                    {CheckValueLocale("interactions_type", "", {}, intl)}
                  </TableCell>
                  <TableCell>
                    {CheckValueLocale("top_engagers_followers", "", {}, intl)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => {
                  return (
                    <TableRow>
                      <TableCell>
                        <Box className="engager-td">
                          <Avatar src={user.user_img_profile_url} />
                          <Box>
                            <Typography variant="body1">{user.name}</Typography>
                            <Typography variant="body2">
                              @{user.username}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell className="interactions-type-td">
                        {user.interaction_type_retweet ? (
                          <img src={OverviewRetweetIcon} alt="retweet icon" />
                        ) : user.interaction_type_reply ? (
                          <Typography>
                            <img src={ReplyIcon} alt="reply icon" />
                            <a className="reply-num">
                              {user.interaction_type_reply}
                            </a>
                          </Typography>
                        ) : null}
                      </TableCell>
                      <TableCell className="followers-td">
                        {user.followers_count}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EngagersList;
