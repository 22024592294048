import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "../.././surveyInsights.scss";
import PieChartWidget from "components/widgets/pieChartWidget";
import SocialAccountQuestion from "./socialAccountQuestion";
import _ from "lodash";
import BarChartWidget from "components/widgets/barChartWidget";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu.js";
import { CheckValueLocale, pieChartSort } from "utils/helpers";
import NoDataFound from "components/no-Data/noDataFound";

const OneQuestionsData = (props) => {
  const intl = useIntl();
  const [data, setData] = useState();
  const getSurveyName = (name) => {
    let result = "";
    if (name === "7" || name === "8") {
      result = "passive";
    } else if (name === "9" || name === "10") {
      result = "promoters";
    } else {
      result = "detractors";
    }
    return result;
  };

  const colorsSurvey = {
    "": "#E85954",
    0: "#E85954",
    1: "#E85954",
    2: "#E85954",
    3: "#E85954",
    4: "#E85954",
    5: "#E85954",
    6: "#E85954",
    7: "#F5B819",
    8: "#F5B819",
    9: "#9FC653",
    10: "#9FC653",
  };

  const answers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  useEffect(() => handleQuestions(), [props?.selectedQuestion]);
  let valueExist = props?.selectedQuestion?.answer_list?.nps_values?.nps?.every(
    (val) => val.value === 0,
  );
  let nameExist = props?.selectedQuestion?.answer_list?.nps_values?.nps?.every(
    (val) => val.name === null,
  );
  const handleQuestions = () => {
    let item;
    switch (props?.selectedQuestion?.type) {
      case "short_text":
        item = props?.selectedQuestion?.answer_list?.map((answer, i) => {
          return (
            answer?.value !== "" && (
              <Box className="single-text-answers-parant" key={i}>
                <Box className="single-text-answers">{answer?.value}</Box>
                <Box className={"interaction-list__footer"}>
                  <ThemesMenu
                    themes={answer?.themes}
                    subThemes={answer?.sub_themes}
                    id={props?.id}
                    productId={props?.productId}
                    isSurveyProduct={true}
                    answerId={answer?.answer_id}
                  />
                  <Divider
                    orientation="vertical"
                    className="vertical-line-list-header"
                  />
                  <SentimentDropdown
                    sentiment={answer?.sentiment}
                    id={props.id}
                    productId={props?.productId}
                    isSurveyProduct={true}
                    answerId={answer?.answer_id}
                  />
                </Box>
              </Box>
            )
          );
        });
        break;
      case "email":
      case "number":
      case "phone_number":
        item = props?.selectedQuestion?.answer_list?.map((answer, i) => {
          return (
            answer !== "" && (
              <Box className="single-text-answer" key={i}>
                {answer}
              </Box>
            )
          );
        });
        break;
      case "name":
        item = props?.selectedQuestion?.answer_list?.map((answer, i) => {
          return (
            answer !== "" && (
              <Box className="single-text-answer" key={i}>
                {answer?.first_name} {answer?.last_name}
              </Box>
            )
          );
        });
        break;
      case "dropdown":
      case "multiple_choice":
        item = (
          <Box className="pie-chart-question">
            <PieChartWidget
              data={props?.selectedQuestion?.answer_list}
              pieChartPreLoaderDataSources={["questions"]}
              preLoaderTrackerDataSources={["questions"]}
              legendPosition={"beside"}
              flagTitle={"surveyTitle"}
              showLabel
              customStyle
            />
          </Box>
        );
        break;
      case "yes_no":
        item = (
          <Box className="pie-chart-question">
            <PieChartWidget
              data={props?.selectedQuestion?.answer_list}
              pieChartPreLoaderDataSources={["questions"]}
              preLoaderTrackerDataSources={["questions"]}
              legendPosition={"beside"}
              showLabel
              solidPie
              customStyle
            />
          </Box>
        );
        break;
      case "social_media":
        item = (
          <SocialAccountQuestion data={props?.selectedQuestion?.answer_list} />
        );
        break;
      case "nps":
        item = (
          <>
            {props?.isSummary ? (
              <Box className="nps-question">
                <Box className="item-nps">
                  <div className="nps-item-parant">
                    {_.isEqual(
                      props?.questionsPreLoader?.["questions"],
                      props?.preLoaderTrackerDataSources?.["questions"],
                    ) &&
                      props?.selectedQuestion?.answer_list?.nps_values?.nps && (
                        <>
                          {!valueExist &&
                            !nameExist &&
                            props?.selectedQuestion?.answer_list?.nps_values
                              ?.nps !== undefined && (
                              <Box className="nps-item">
                                <Typography>
                                  {CheckValueLocale("nps_widget", "", {}, intl)}
                                </Typography>
                                <Typography component={"h4"}>
                                  {props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString() !==
                                    "0" &&
                                  props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString() !==
                                    "0.00"
                                    ? props?.selectedQuestion?.answer_list?.nps_values?.nps_score
                                        ?.toString()
                                        ?.startsWith("-")
                                      ? props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString()
                                      : "+" +
                                        props?.selectedQuestion?.answer_list?.nps_values?.nps_score?.toString()
                                    : 0}
                                </Typography>
                              </Box>
                            )}

                          <PieChartWidget
                            title={"nps_tradmark"}
                            titleToolTip="nps_tooltip"
                            data={pieChartSort(
                              props?.selectedQuestion?.answer_list?.nps_values
                                ?.nps,
                            )}
                            toolTipLabel={"toolTipLabel"}
                            areaStyle={true}
                            pieChartPreLoaderDataSources={["questions"]}
                            preLoaderTrackerDataSources={["questions"]}
                          />
                        </>
                      )}
                  </div>
                </Box>

                <Box className="item-nps-chart">
                  <BarChartWidget
                    title={"response_by_Score"}
                    showDownloadIcon={false}
                    getSurveyName={getSurveyName}
                    colorsSurvey={colorsSurvey}
                    data={
                      props?.selectedQuestion?.answer_list?.nps_score
                        ?.response_by_score
                    }
                    barChartPreLoaderDataSources={["questions"]}
                    preLoaderTrackerDataSources={["questions"]}
                    applyAxisFormatter=" %{value}"
                    isSurveyInsights
                    isSurvey
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                {props?.selectedQuestion?.answer_list?.nps_score?.response_by_score?.map(
                  (key, index) => {
                    return (
                      <Box key={index}>
                        {key?.value !== "" && (
                          <Box className="answers-box">
                            <Box>{props?.selectedQuestion?.question}</Box>
                            <Box className="radio-item-individual">
                              <Box className="radio-item ">
                                {answers?.map((item, index) => {
                                  return (
                                    <label className="radio-item" key={index}>
                                      <input
                                        disabled
                                        type="radio"
                                        name={
                                          "colors" +
                                          index +
                                          props?.selectedQuestion?.question_id
                                        }
                                        checked={index === +key.name}
                                      />
                                      <Typography
                                        component={"span"}
                                        className={
                                          props?.selectedQuestion?.answer_list
                                            ?.coloured
                                            ? index === 7 || index === 8
                                              ? "choose yellow"
                                              : index === 9 || index === 10
                                                ? "choose green"
                                                : "choose red"
                                            : "choose"
                                        }
                                      >
                                        <Box>{item}</Box>
                                      </Typography>
                                    </label>
                                  );
                                })}
                              </Box>
                              <Box className="most-like">
                                <Typography>
                                  {CheckValueLocale("unlikely", "", {}, intl)}
                                </Typography>
                                <Typography>
                                  {CheckValueLocale(
                                    "very_likely",
                                    "",
                                    {},
                                    intl,
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Box className="answers-header">
                                {key?.value}{" "}
                                {CheckValueLocale("response", "", {}, intl)}
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    );
                  },
                )}
              </Box>
            )}
          </>
        );
        break;
      case "scale":
        item = (
          <Box className="nps-question">
            <Box className="item-scale-chart">
              {props?.selectedQuestion?.answer_list !== undefined
                ? props?.selectedQuestion?.answer_list[0]?.scale?.length >
                    0 && (
                    <BarChartWidget
                      title={""}
                      showDownloadIcon={false}
                      getSurveyName={getSurveyName}
                      dataColor="#2AAAE2"
                      colorsSurvey={colorsSurvey}
                      data={props?.selectedQuestion?.answer_list[0]?.scale}
                      barChartPreLoaderDataSources={["questions"]}
                      preLoaderTrackerDataSources={["questions"]}
                      applyAxisFormatter=" %{value}"
                      isSurveyInsights
                      isScaleQues={true}
                    />
                  )
                : null}
              <Box className="values-text" mx={4}>
                <Typography component="p">
                  {props?.selectedQuestion?.answer_list[0]?.low_value}
                </Typography>
                <Typography component="p">
                  {props?.selectedQuestion?.answer_list[0]?.high_value}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
        break;

      default:
        break;
    }
    setData(item);
  };
  return <Box>{data}</Box>;
};
export default OneQuestionsData;
