import { useState } from "react";
import {
  Box,
  Pagination,
  Typography,
  Card,
  CardContent,
  Input,
  FormControl,
  Divider,
} from "@mui/material";
import { useIntl } from "react-intl";
import "./individualSurvey.scss";
import IntlTelInput from "react-intl-tel-input";
import { isEmptyArray, isEmptyObj, CheckValueLocale } from "utils/helpers";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import NoDataFound from "components/no-Data/noDataFound";
import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import moment from "moment";

const IndividualSurveyTab = (props) => {
  const intl = useIntl();
  const { individualSurvey } = props;
  const [page, setPage] = useState(1);
  const [selectedQuestion, setSelectedQuestion] = useState(
    Object.values(individualSurvey)[0],
  );
  const answers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  //handle each question individualy
  const handleQuestionType = (question) => {
    let item;
    switch (question?.type) {
      case "short_text":
        item =
          question?.answer_list !== "" ? (
            <Box className="single-text">
              <Box className="single-text-answers">
                <Input
                  id="standard-basic"
                  variant="standard"
                  className="text-field-style"
                  value={question?.answer_list}
                  readOnly
                />
              </Box>
              <Box className={"interaction-list__footer"}>
                <ThemesMenu
                  themes={question?.themes}
                  subThemes={question?.sub_themes}
                  id={props?.id}
                  productId={props?.productId}
                  answerId={question?.answer_id}
                  isSurveyProduct={true}
                />
                <Divider
                  orientation="vertical"
                  className="vertical-line-list-header"
                />
                <SentimentDropdown
                  sentiment={question?.sentiment}
                  id={props.id}
                  productId={props?.productId}
                  answerId={question?.answer_id}
                  isSurveyProduct={true}
                />
              </Box>
            </Box>
          ) : (
            <Box className="no-data-found">
              <NoDataFound />
            </Box>
          );
        break;
      case "email":
      case "number":
        item = (
          <>
            <Box>
              {question?.answer_list?.length ? (
                <Input
                  id="standard-basic"
                  variant="standard"
                  className="text-field-style"
                  value={question?.answer_list}
                  readOnly
                />
              ) : (
                <Box className="no-data-found">
                  <NoDataFound />
                </Box>
              )}
            </Box>
          </>
        );
        break;
      case "name":
        item =
          question?.answer_list?.first_name?.length ||
          question?.answer_list?.last_name ? (
            <Box>
              <Input
                id="standard-basic"
                placeholder={CheckValueLocale("first_name", "", {}, intl)}
                variant="standard"
                className="name-text-field"
                value={question?.answer_list?.first_name}
                readOnly
              />
              <Input
                id="standard-basic"
                placeholder={CheckValueLocale("last_name", "", {}, intl)}
                variant="standard"
                className="name-text-field"
                value={question?.answer_list?.last_name}
                readOnly
              />
            </Box>
          ) : (
            <Box className="no-data-found">
              <NoDataFound />
            </Box>
          );
        break;
      case "phone_number":
        item = (
          <Box className="phone-number-question phone-preview">
            {question?.answer_list !== undefined &&
            question?.answer_list[0]?.phone_number?.length ? (
              <FormControl className="phone-form">
                <IntlTelInput
                  containerClassName="intl-tel-input"
                  inputClassName="form-control react-tel-input phoneInput"
                  separateDialCode
                  defaultCountry={question?.answer_list[0]?.country_code?.toLowerCase()}
                  excludeCountries={["il"]}
                  fieldName="phone_number"
                  value={question?.answer_list[0]?.phone_number}
                />
              </FormControl>
            ) : (
              <Box className="no-data-found">
                <NoDataFound />
              </Box>
            )}
          </Box>
        );
        break;
      case "yes_no":
      case "multiple_choice":
      case "dropdown":
        item =
          !isEmptyArray(question?.answer_list) &&
          question?.answer_list !== "" ? (
            question?.answer_list?.map((answer, i) => {
              return (
                answer !== "" && (
                  <Box className="mcq-options" key={i}>
                    <Box>{`${i + 1}-`}</Box>{" "}
                    <Box className={"option-value"}>{answer}</Box>
                  </Box>
                )
              );
            })
          ) : (
            <Box className="no-data-found">
              <NoDataFound />
            </Box>
          );
        break;
      case "social_media":
        item =
          !isEmptyArray(question?.answer_list) &&
          question?.answer_list !== "" ? (
            question?.answer_list?.map((pf, i) => {
              const pfImg =
                Object.keys(pf)[0] === "twitter"
                  ? "x-platform"
                  : Object.keys(pf)[0];
              return (
                <Box className="social-media-account" key={i}>
                  <img
                    src={`/survey_builder_icons/${pfImg}.png`}
                    alt="logo"
                    className="imgStyle social-media-icons"
                  />
                  <Input
                    id="standard-basic"
                    variant="standard"
                    className="text-field-style"
                    value={Object.values(pf)[0]}
                    readOnly
                  />
                </Box>
              );
            })
          ) : (
            <Box className="no-data-found">
              <NoDataFound />
            </Box>
          );
        break;
      case "nps":
        item = (
          <>
            {question?.answer_list !== null ? (
              <Box className="radio-item-individual">
                <Box className="radio-item ">
                  {answers?.map((item, index) => {
                    return (
                      <label className="radio-item" key={index}>
                        <input
                          disabled
                          type="radio"
                          name={"colors" + index + question?.question_id}
                          checked={index === +question?.answer_list}
                        />
                        <Typography
                          component={"span"}
                          className={
                            question?.coloured
                              ? index === 7 || index === 8
                                ? "choose yellow"
                                : index === 9 || index === 10
                                  ? "choose green"
                                  : "choose red"
                              : "choose"
                          }
                        >
                          <Box>{item}</Box>
                        </Typography>
                      </label>
                    );
                  })}
                </Box>
                <Box className="most-like">
                  <Typography>
                    {CheckValueLocale("unlikely", "", {}, intl)}
                  </Typography>
                  <Typography>
                    {CheckValueLocale("very_likely", "", {}, intl)}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box className="no-data-found">
                <NoDataFound />
              </Box>
            )}
          </>
        );
        break;
      case "scale":
        let scaleAnswers = [];
        for (let i = question?.min_value; i <= question?.max_value; i++)
          scaleAnswers?.push(i);

        item = (
          <>
            {question?.answer_list !== null ? (
              <Box className="scaleBox">
                <FormControl className="scaleFormControl">
                  <Box className="scaleContainer">
                    {scaleAnswers?.map((item, index) => {
                      return (
                        <label
                          key={index}
                          className={`radio-item ${
                            (index === 0 || item == question?.max_value) &&
                            "valuesText"
                          }`}
                        >
                          <input
                            disabled
                            type="radio"
                            name={"colors" + index + question?.question_id}
                            checked={item == question?.answer_list}
                          />
                          <Typography
                            component={"span"}
                            className="choose"
                            mx={0}
                          >
                            <Box>{item}</Box>
                          </Typography>
                          {index == 0 && (
                            <Typography component={"p"} textAlign={"center"}>
                              {question?.low_value}
                            </Typography>
                          )}
                          {item == question?.max_value && (
                            <Typography component={"p"} textAlign={"center"}>
                              {question?.high_value}
                            </Typography>
                          )}
                        </label>
                      );
                    })}
                  </Box>
                </FormControl>
              </Box>
            ) : (
              <Box className="no-data-found">
                <NoDataFound />
              </Box>
            )}
          </>
        );
        break;
      default:
        break;
    }
    return <Box>{item}</Box>;
  };
  // Handle Pagination_______________________________________________
  //Handle Page number changes which dynamically reflects to the index of array visibile to user

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    setSelectedQuestion(Object.values(individualSurvey)[page - 1]);
  };
  return (
    <>
      {_.isEqual(
        props?.individualSurveyPreLoader?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <Box className="fetching-questions">
          <FetchingData />
        </Box>
      ) : isEmptyObj(individualSurvey) ||
        isEmptyArray(Object.values(individualSurvey)) ? (
        <NoDataFound />
      ) : (
        <>
          {Object.entries(selectedQuestion)?.map(([sectionId, sectionData]) => (
            <Box className="individual-box">
              {Object.entries(selectedQuestion)?.length > 1 && (
                <Box className="section-name-insights">
                  {sectionData?.section_name}
                </Box>
              )}
              <Box key={sectionId}>
                <div key={sectionId}>
                  {sectionData?.questions?.map((question, i) => {
                    return (
                      <div className="card-style-individual">
                        <Typography className={"question-style"}>
                          <span>{`${i + 1}.`}</span>
                          {question?.question}
                        </Typography>
                        {handleQuestionType(question)}
                      </div>
                    );
                  })}
                </div>
              </Box>
            </Box>
          ))}

          <Card className="card-style pagination-card">
            {/* Pagination_______________________________________________ */}
            {Object.keys(individualSurvey)?.length > 1 && (
              <Pagination
                count={Object.keys(individualSurvey)?.length}
                size="small"
                onChange={handleChangePage}
                page={page}
                className="individual-pagination survey-insight-pagination"
              />
            )}
            {/* SubmittedAtText_______________________________________________ */}
            <Typography className="submmitted-at">
              {CheckValueLocale(
                "response_submitted_at",
                "",
                {
                  date: moment(
                    Object.values(individualSurvey)[page - 1][0]?.submitted_at,
                  ).format("D/M/YYYY"),
                  hour: moment(
                    Object.values(individualSurvey)[page - 1][0]?.submitted_at,
                  ).format("hh:mm"),
                  a:
                    moment(
                      Object.values(individualSurvey)[page - 1][0]
                        ?.submitted_at,
                    ).format("A") === "AM"
                      ? CheckValueLocale("hashtag_period_am", "", {}, intl)
                      : CheckValueLocale("hashtag_period_pm", "", {}, intl),
                },
                intl,
              )}
            </Typography>
          </Card>
        </>
      )}
    </>
  );
};
export default IndividualSurveyTab;
