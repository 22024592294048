import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BotConfiguration from "./botConfiguration";
import EditSessionConfiguration from "./editSessionConfiguration";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "./addChannel.scss";
import { CheckValueLocale } from "utils/helpers/index";
import { useGetConfigData } from "../hooks/useGetConfigData";
import { useEditChannel } from "../hooks/useEditChannel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { editChannelConfigurationsFormikValidationSchema } from "../manageChannelValidation";
import LucButton from "shared/lucButton/lucButton";

export default function DmConfigurationEdit(props) {
  const { setIsErrorEditSnackBarOpen, setIsSuccessEditSnackBarOpen } = props;
  const [count, setCount] = useState(1);
  const intl = useIntl();
  const handleClose = () => props.setIsEditModalOpen(false);
  const { data: configDetailsResponse, isPending } = useGetConfigData({
    monitorId: props.monitorId,
    DMConfigurationId: props.dm_Configuration_Id,
  });
  const configDetailsData = configDetailsResponse?.data;

  const { mutate: editChannel, isPending: isEditChannelPending } =
    useEditChannel();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      botMessageArray: configDetailsData?.attributes?.bot_to_human_text.length
        ? configDetailsData?.attributes?.bot_to_human_text.split(",")
        : [],
      isBot: configDetailsData?.attributes?.is_bot_enabled || 0,
      IS_PRIVATE_CHANNEL: false,
      clientConversationEnd:
        configDetailsData?.attributes?.client_conversation_end,
      agentConversationEnd:
        configDetailsData?.attributes?.agent_conversation_end,
      agentEndMsg: configDetailsData?.attributes?.agent_end_msg.split(","),
    },
    validationSchema: editChannelConfigurationsFormikValidationSchema(intl),
    onSubmit: () => {
      putEditConfigData();
    },
  });

  const putEditConfigData = () => {
    const transformedQueryData = {
      IS_PRIVATE_CHANNEL: false,
      dm_configuration: {
        is_bot_enabled: formik.values.isBot,
        bot_to_human_text: formik.values.botMessageArray.join(","),
        client_conversation_end: formik.values.clientConversationEnd,
        agent_conversation_end: formik.values.agentConversationEnd,
        agent_end_msg: formik.values.agentEndMsg.join(","),
      },
    };

    const monitorId = props.monitorId;
    const dm_Configuration_Id = props.dm_Configuration_Id;

    editChannel(
      { monitorId, dm_Configuration_Id, queryData: transformedQueryData },
      {
        onSuccess: () => {
          handleClose();
          setIsSuccessEditSnackBarOpen(true);
          setIsErrorEditSnackBarOpen(false);
        },
        onError: () => {
          handleClose();
          setIsSuccessEditSnackBarOpen(false);
          setIsErrorEditSnackBarOpen(true);
        },
      },
    );
  };

  return (
    <>
      <div>
        <Dialog
          open={props.isEditModalOpen}
          onClose={handleClose}
          className="edit-dailog-box dailog-box"
        >
          <div className="dialog-subscribe">
            <DialogTitle className="subscribe-head">
              <span className="edit-dm-config-title">
                {CheckValueLocale("edit_dm", "", {}, intl)}
              </span>
              <Typography className="step-dm-config" component="span">
                {CheckValueLocale(
                  "dm_congfig_step",
                  "",
                  {
                    count: count,
                  },
                  intl,
                )}
              </Typography>
            </DialogTitle>
            <DialogContent className="subscribe-head-main">
              {count === 1 ? (
                <BotConfiguration
                  editBot={true}
                  formik={formik}
                  className="subscribe-bot"
                  loading={isPending}
                />
              ) : (
                <EditSessionConfiguration
                  editSession={true}
                  className="subscribe-session"
                  formik={formik}
                />
              )}
            </DialogContent>
            <DialogActions className="btn-flex">
              {count == 1 ? null : (
                <div className="back-button">
                  <LucButton
                    onClick={() => setCount(count - 1)}
                    id="channel-analytics-add-channel-edit-dm-configuration-back-btn"
                    variant="outline"
                  >
                    {count > 1 ? CheckValueLocale("back", "", {}, intl) : " "}
                  </LucButton>
                </div>
              )}

              <div className="btn-popup-container">
                <LucButton
                  onClick={handleClose}
                  id="channel-analytics-add-channel-edit-dm-configuration-cancel-btn"
                  variant="flat"
                  type="secondary"
                  className="cancel-popup-btn "
                >
                  {CheckValueLocale("cancel", "", {}, intl)}
                </LucButton>
                {count <= 1 ? (
                  <LucButton
                    onClick={() => setCount(count + 1)}
                    id="channel-analytics-add-channel-edit-dm-configuration-next-btn"
                  >
                    {CheckValueLocale("luci_next", "", {}, intl)}
                  </LucButton>
                ) : (
                  <LucButton
                    className="config-button"
                    disabled={isEditChannelPending}
                    onClick={formik.handleSubmit}
                    id="channel-analytics-add-channel-edit-dm-configuration-save-apply-btn"
                  >
                    {CheckValueLocale("save_apply_dm", "", {}, intl)}
                  </LucButton>
                )}
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </>
  );
}
