// Common file used in whole app to show PopupModal .
// Don't repeat/update or remove this file!
// props to be send :
// open,close,
// addClasses,classeName,modalStyle,
// title,body,children,leftBtn,rightBtn,rightColor,warning

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import "./popupModal.scss";

const PopupModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
      className={props.addClasses ? props.addClasses : ""} //custom style for the modal
    >
      <Box
        className={`pop-up-model-shared-content ${props?.classeName ? props?.classeName : ""}`}
        minWidth={props?.minWidth}
        height={props?.height}
      >
        {!props?.fixPadding ? (
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        ) : null}
        <DialogContent className={props.modalStyle ? props.modalStyle : ""}>
          <DialogContentText id="alert-dialog-description">
            {props.body}
            {props.childrenModal}
            {/*body and childer could accept any <tag> with custom style */}
          </DialogContentText>
        </DialogContent>
        {!props?.fixPadding ? (
          <DialogActions className={props.addClasses ? props.addClasses : ""}>
            {props.topicsExist && props.topicSelectedCounter}

            {props.leftBtn && (
              <LucButton
                onClick={
                  props.acceptOtherOption
                    ? props.acceptOtherOption
                    : props.close
                }
                id={props?.leftBtnId ?? "pop-up-left-btn"}
                variant={props?.leftBtnVariant ? props?.leftBtnVariant : "flat"}
                type={props?.leftBtnType ? props?.leftBtnType : "secondary"}
                disabled={props?.disableLeftBtn}
              >
                {props.leftBtn}
              </LucButton>
            )}
            {props.rightBtn && (
              <LucButton
                disabled={props?.disabled ? props.disabled : false}
                onClick={() => {
                  props.accept(props?.monitor_id);
                }}
                id={props?.rightBtnId ?? "pop-up-right-btn"}
                variant="filled"
                type={props.warning ? "red" : "primary"} //if u want the btn to be red in color pass warning={true} else it will be primary btn
                className={"popup__rightbtn"} // to margin
                loading={props?.isRightBtnLoading}
              >
                {props.rightBtn}
              </LucButton>
            )}
          </DialogActions>
        ) : null}
      </Box>
    </Dialog>
  );
};
export default PopupModal;
