import { useWidgetName } from "../../../hooks/useWidgetName";

export const twitterRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  switch (response?.eventName) {
    case "Twitter__AccountPage__posts_interactions": // Public
      handleWidgetResponse(
        "posts_interactions",
        response?.monitor_id,
        "TWITTER",
        { interactions: response?.eventData?.posts_interactions },
      );
      break;

    case "Twitter__EngagementsPage__comments_interactions": // Public
      handleWidgetResponse(
        monitorType == "KEYWORD"
          ? "posts_interactions"
          : "comments_interactions",
        response?.monitor_id,
        "TWITTER",
        { interactions: response?.eventData },
      );
      break;

    case "Twitter__EngagementsPage__dialects_and_sub_dialects": // Public
      handleWidgetResponse(
        "dialects_subdialects",
        response?.monitor_id,
        "TWITTER",
        response?.eventData,
      );
      break;

    case "Twitter__EngagementsPage__gender_distribution": // Keyword
      handleWidgetResponse(
        "gender_distribution",
        response?.monitor_id,
        "TWITTER",
        { gender_distribution: response?.eventData?.top_genders },
      );
      break;

    case "Twitter__EngagementsPage__content_style":
      handleWidgetResponse(
        monitorType === "KEYWORD"
          ? "posts_content_style"
          : "comments_content_style",
        response?.monitor_id,
        "TWITTER",
        { content_style: response?.eventData },
      );
      break;

    case "Twitter__AccountPage__account_author_activity":
      handleWidgetResponse(
        "account_authors_activity",
        response?.monitor_id,
        "TWITTER",
        { account_authors_activity: response?.eventData },
      );
      break;

    default:
      if (stateKey)
        handleWidgetResponse(
          stateKey,
          response?.monitor_id,
          "TWITTER",
          response?.eventData,
        );
      break;
  }
};
