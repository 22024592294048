import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmile as faSmileOutline,
  faMeh as faMehOutline,
  faQuestionCircle as faQuestionCircleOutline,
  faFrown as faFrownOutline,
} from "@fortawesome/free-regular-svg-icons";
import {
  faFrown as faFrownSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faSmile as faSmileSolid,
  faMeh as faMehSolid,
} from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";
import "./SentimentDropdown.scss";
import {
  Button,
  Menu,
  MenuItem,
  Box,
  styled,
  Typography,
  CircularProgress,
} from "@mui/material";
import InteractionsController from "services/controllers/interactionsController";
import SurveyController from "services/controllers/surveyController";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const SentimentDropdown = (props) => {
  let icons;
  if (props.useOutlineIcons) {
    icons = {
      negative: faFrownOutline,
      neutral: faMehOutline,
      positive: faSmileOutline,
      none: faQuestionCircleOutline,
    };
  } else {
    icons = {
      negative: faFrownSolid,
      neutral: faMehSolid,
      positive: faSmileSolid,
      none: faQuestionCircleSolid,
    };
  }
  const intl = useIntl();
  const [selectedSentiment, setSelectedSentiment] = useState({
    key: props?.data?.CA_sentiment
      ? props?.data?.CA_sentiment
      : props.sentiment,
    value: props?.data?.CA_sentiment
      ? props?.data?.CA_sentiment
      : props.sentiment,
  });
  const [sentimentOptions, setSentimentOptions] = useState([]);
  const [sentimentIcon, setSentimentIcon] = useState("");
  const [sentimentLabel, setSentimentLabel] = useState("");
  const [sentimentColor, setSentimentColor] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    handleSentiment(selectedSentiment);
    getSentiment();
  }, []);

  useEffect(() => {
    if (props?.data?.isPublicChannel === true) {
      const objSentiment = {
        key: props?.data?.CA_sentiment,
        value: props?.data?.CA_sentiment,
      };
      setSelectedSentiment({
        ...objSentiment,
      });
      handleSentiment(objSentiment);
    }
  }, [props?.data?.isPublicChannel]);

  useEffect(() => {
    getSentiment();
  }, [window.localStorage.getItem("lang")]);

  const handleSentiment = (selectedSentiment) => {
    let icon, label, color;
    switch (selectedSentiment.key) {
      case 0:
      case "negative":
        icon = "negative";
        label = icons?.negative;
        color = "#E50C35";
        break;
      case 1:
      case "positive":
        icon = "positive";
        label = icons?.positive;
        color = "#89BB2A";
        break;
      case 2:
      case "neutral":
        icon = "neutral";
        label = icons?.neutral;
        color = "#F9A700";
        break;
      default:
        icon = "none";
        label = icons.none;
        color = "#80868c";
        break;
    }
    setSentimentIcon(icon);
    setSentimentLabel(label);
    setSentimentColor(color);
  };

  const checkIsSurveyInsight = (newitem, olditem) => {
    if (props.isSurveyProduct) {
      editSentiment(newitem, olditem);
    } else {
      updateSingleInteractionSentiment(newitem, olditem);
    }
  };

  const getSentiment = () => {
    setIsLoading(true);
    InteractionsController.getSentiments().then((data) => {
      let icon;
      let options = [];
      if (!isEmptyObj(data.data)) {
        Object.entries(data.data).map((i) => {
          switch (i[0]) {
            case "negative":
              icon = (
                <MenuItem
                  onClick={() => checkIsSurveyInsight(i[1], i[0])}
                  className="sentiment-item negative"
                  id="interactions-list-sentiment-item-negative"
                >
                  <FontAwesomeIcon icon={icons?.negative} />
                  <span>{CheckValueLocale("negative", "", {}, intl)}</span>
                </MenuItem>
              );
              break;
            case "neutral":
              icon = (
                <MenuItem
                  onClick={() => checkIsSurveyInsight(i[1], i[0])}
                  className="sentiment-item neutral"
                  id="interactions-list-sentiment-item-neutral"
                >
                  <FontAwesomeIcon icon={icons?.neutral} />
                  <span>{CheckValueLocale("neutral", "", {}, intl)}</span>
                </MenuItem>
              );
              break;
            case "positive":
              icon = (
                <MenuItem
                  onClick={() => checkIsSurveyInsight(i[1], i[0])}
                  className="sentiment-item positive"
                  id="interactions-list-sentiment-item-positive"
                >
                  <FontAwesomeIcon icon={icons?.positive} />
                  <span>{CheckValueLocale("positive", "", {}, intl)}</span>
                </MenuItem>
              );
              break;
            default:
              break;
          }

          options.push(icon);
          setSentimentOptions(options);

          if (selectedSentiment.value === i[1]) {
            return setSelectedSentiment({ key: i[0], value: i[1] });
          }
        });
      }
      setIsLoading(false);
    });
  };

  const updateSingleInteractionSentiment = (newLabelKey, newLabelValue) => {
    InteractionsController.updateSingleInteractionSentiment(
      props?.data?.isPublicChannel
        ? props?.data?.tweet_id
        : props.interactionId,
      props.trackerId,
      selectedSentiment.value,
      newLabelKey,
      window.localStorage.cxm_id,
      props?.data?.isPublicChannel
        ? props?.data?.CA_channel_id
        : props.channelId,
      props.createdAt,
      props?.data?.isPublicChannel ? props?.data?.tweet_id : props?.commentId,
      props.mediaId,
      props?.data?.isPublicChannel
        ? props?.data?.tracker_id
        : props?.account_id,
      props?.location_id,
      props?.audio_id,
      props?.lucidya_audio_id,
      props?.isLatestInteractionEdit
        ? props.data.previousVarApis
        : props?.apiVars,
    ).then((result) => {
      if (result !== undefined && result.data.status === 200) {
        setSelectedSentiment({ key: newLabelKey, value: newLabelValue });
        handleSentiment({ key: newLabelKey, value: newLabelValue });
        setAnchorEl(null);
      }
    });
  };

  const editSentiment = (newLabelKey, newLabelValue) => {
    SurveyController.editSentiment(
      props.answerId,
      Number(props.id),
      selectedSentiment.value,
      newLabelKey,
      Number(props.productId),
    ).then((result) => {
      if (result !== undefined && result.data.status === 200) {
        setSelectedSentiment({ key: newLabelKey, value: newLabelValue });
        handleSentiment({ key: newLabelKey, value: newLabelValue });
        setAnchorEl(null);
      }
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="edit-sentiment-interactions"
      >
        <Box className="sentiment-box" style={{ color: sentimentColor }}>
          <FontAwesomeIcon icon={sentimentLabel} />
          {sentimentIcon && (
            <span>{CheckValueLocale(sentimentIcon, "", {}, intl)}</span>
          )}
          <ArrowDropDownIcon className="sentiment-interactions-arrow" />
        </Box>
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className={`sentiment-dropdown ${
          props.useOutlineIcons ? "outlined" : ""
        }`}
      >
        <MenuItem
          disabled
          value=""
          className="themes-sub-title sentiment-sub-title"
          id="interactions-list-sentiment-item-sentiment"
        >
          <Typography>{CheckValueLocale("sentiment", "", {}, intl)}</Typography>
        </MenuItem>

        {isLoading === true ? (
          <CircularProgress className="progress-icon-sentiments" />
        ) : (
          <span>{sentimentOptions}</span>
        )}
      </Menu>
    </div>
  );
};

export default SentimentDropdown;
