import { useEffect, useState } from "react";
import {
  Divider,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Tooltip,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import { getDatasourceEditInputFieldName } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import {
  handleInputChange,
  checkDirectionLang,
  handleInputMouseDown,
} from "../../MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { DropdownSection } from "../../MonitorsShared/SharedComponent/monitorDropdowns/monitorDropdowns";
import HashtagUsedNumber from "../../createMonitor/MonitorTypes/KeywordsMonitor/components/keywordHeader/hashtagUsedNumber";

const AccountAnalysisType = (props) => {
  const intl = useIntl();
  const isRTL = intl?.locale === "ar";
  const {
    handleKeywords,
    keywords,
    handleValidation,
    setSQuestion,
    setUpdatedParams,
    updatedParams,
    monitorDetails,
    handleDelete,
    monitorErrors,
    setMonitorErrors,
    twitterCountries,
    setTwitterCountries,
    twitterLanguages,
    setTiwtterLanguages,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    twitterWordsCounter,
  } = props;
  const [accountName, setAccountName] = useState(monitorDetails?.account_name);

  const [err, setErr] = useState({
    errMsg: "",
    keyword: "",
  });
  const [focused, setFocused] = useState(false);
  const isTwitter = monitorDetails?.data_sources[0] === "TWITTER";

  /* __________________________________________________________ */
  const handleKeyDown = (event, clickType) => {
    handleKeywords(event, "spam_keywords", "twitter", 1, clickType, 100);
    handleUpdateParams();
  };
  // ______________________________________________________________
  const handleDeleteWord = (e, index, keywordType) => {
    handleDelete(e, index, keywordType, 0, "twitter");
    handleUpdateParams();
  };
  const handleUpdateParams = () => {
    setUpdatedParams({
      ...updatedParams,
      spam_keywords: {
        TWITTER: Object.values(keywords[0])[0][1].spam_keywords?.join(),
      },
    });
  };
  // ______________________________________________________________
  const handleAccountName = (event) => {
    // IG max length is 30, TW max length is 15, FB max length is 50
    if (
      monitorDetails?.data_sources[0] === "TWITTER" &&
      event.target.value.length > 15
    ) {
      // IG max length is 30, TW max length is 15
      setMonitorErrors({
        ...monitorErrors,
        userName: "username_max_char",
      });
    } else if (
      monitorDetails?.data_sources[0] === "INSTAGRAM" &&
      event?.target?.value?.length > 30
    ) {
      setMonitorErrors({
        ...monitorErrors,
        userName: "ig_max_char",
      });
    } else if (
      monitorDetails?.data_sources[0] === "FACEBOOK" &&
      event?.target?.value?.length > 50
    ) {
      setMonitorErrors({
        ...monitorErrors,
        userName: "fb_page_max_char",
      });
      // Validate Special character page name for FB
    } else if (
      monitorDetails?.data_sources[0] === "FACEBOOK" &&
      isPublicSpecialCharactersOrEmojis(
        event?.target?.value,
        undefined,
        undefined,
        true,
      )
    ) {
      setMonitorErrors({
        ...monitorErrors,
        userName: "pageName_special_characters_error",
      });
      // Validate Special character page name for TW
    } else if (
      monitorDetails?.data_sources[0] === "TWITTER" &&
      isPublicSpecialCharactersOrEmojis(
        event?.target?.value,
        undefined,
        undefined,
        false,
        true,
      )
    ) {
      setMonitorErrors({
        ...monitorErrors,
        userName: "pageName_special_characters_error",
      });
      // Validate Special characters for other data sources
    } else if (isPublicSpecialCharactersOrEmojis(event.target.value)) {
      setMonitorErrors({
        ...monitorErrors,
        userName: "username_special_characters_error",
      });
    } else {
      setMonitorErrors({
        ...monitorErrors,
        userName: "",
      });
      setUpdatedParams({
        ...updatedParams,
        account_name: event?.target?.value,
      });
    }
    setAccountName(event?.target?.value);
  };
  const applySelectionConditionFn = (optionsToBeAdded) => {
    return optionsToBeAdded + twitterWordsCounter <= 100;
  };
  // Tracking of Error message in Spam keywords of twitter
  useEffect(() => {
    setMonitorErrors({
      ...monitorErrors,
      spamKeywords: keywords[0]?.twitter[1]?.errorMsg,
    });
  }, [keywords[0]?.twitter[1]?.errorMsg]);
  const onCoutriesChange = (newCoutries = []) => {
    setUpdatedParams({
      ...updatedParams,
      twitterCountries: newCoutries,
    });
    setTwitterCountries(newCoutries);
  };
  const onLanguagesChange = (newLanguages = []) => {
    setUpdatedParams({
      ...updatedParams,
      twitterLanguages: newLanguages,
    });
    setTiwtterLanguages(newLanguages);
  };

  useEffect(() => {
    if (isTwitter) getTwitterLanguagesAndCountries();
  }, [isTwitter]);

  const handleInputChangeCustomerCare = (event) => {
    let val = event.target.value;
    if (val.length > 15) {
      setMonitorErrors({
        ...monitorErrors,
        cstCareAccount: "username_max_char",
      });
    } else if (
      isPublicSpecialCharactersOrEmojis(val, undefined, undefined, false, true)
    ) {
      setMonitorErrors({
        ...monitorErrors,
        cstCareAccount: "username_special_characters_error",
      });
    } else {
      setMonitorErrors({
        ...monitorErrors,
        cstCareAccount: "",
      });
    }
    setUpdatedParams({
      ...updatedParams,
      customer_care_accounts: [val],
    });
  };
  return (
    <>
      <Box className="inline-align">
        <Box>
          <Typography className="section-header">
            {CheckValueLocale(
              monitorDetails?.data_sources[0]?.toLowerCase(),
              "",
              {},
              intl,
            )}
          </Typography>
          <Typography className="posts-used">
            {CheckValueLocale("posts_used", "", {}, intl)}:{" "}
            {monitorDetails?.posts_count_stats[
              monitorDetails?.data_sources[0]
            ].toLocaleString()}
          </Typography>
        </Box>
        {/* ______________________________________________________________ */}
        {isTwitter && twitterWordsCounter > 0 ? (
          <Box className="inline-flex data-source-setting">
            <HashtagUsedNumber
              wordCountDataSource={twitterWordsCounter}
              limitationOfDataSource={100}
              isNewBlogs
            />
          </Box>
        ) : null}
      </Box>
      <Box className="section-box">
        <Box className="section-box-data">
          <Typography className="main-titles">
            {CheckValueLocale(
              getDatasourceEditInputFieldName(monitorDetails?.data_sources[0]),
              "",
              {},
              intl,
            )}
          </Typography>

          <TextField
            className={`account-name-input ${
              monitorDetails?.data_sources[0] === "TWITTER" &&
              "account-name-input-tw"
            }`}
            label={CheckValueLocale(
              getDatasourceEditInputFieldName(monitorDetails?.data_sources[0]),
              "",
              {},
              intl,
            )}
            id="outlined-start-adornment"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">@</InputAdornment>
              ),
            }}
            value={accountName}
            onChange={handleAccountName}
            variant="outlined"
            disabled={!handlelUserRoles("SM", "EDIT_MONITOR")}
          />
        </Box>
        {/* _______________________________________________________________________________________ */}
        {/* Error Msg is fired in case of accountName input filed is empty and save btn will not work  */}
        {accountName === "" ? (
          <Box className="section-box-data">
            <Typography className="main-titles"></Typography>
            <Box
              component="p"
              className="spam-text-field err-msg-text keywords-error-msg "
            >
              {CheckValueLocale("this_field_required", "", {}, intl)}
            </Box>
          </Box>
        ) : monitorErrors?.userName !== "" ? (
          <Box className="section-box-data">
            <Typography className="main-titles"></Typography>
            <Box
              component="p"
              className="spam-text-field err-msg-text keywords-error-msg "
            >
              {CheckValueLocale(monitorErrors?.userName, "", {}, intl)}
            </Box>
          </Box>
        ) : (
          ""
        )}
        {/* Spam Keywords (Twitter only)______________________________________________________________ */}
        {monitorDetails?.data_sources[0] === "TWITTER" && (
          <>
            <Divider />
            <Box className="section-box-data section-box-data-keyword">
              <Tooltip
                title={CheckValueLocale(
                  "you_can_use_connectiong_methods",
                  "",
                  {},
                  intl,
                )}
                arrow
                placement="bottom"
              >
                <Typography className="main-titles underlined">
                  {CheckValueLocale("spam_keywords", "", {}, intl)}
                </Typography>
              </Tooltip>

              <TextField
                variant="outlined"
                onChange={(e) => {
                  setSQuestion("twitter");
                  handleInputChange(
                    e,
                    "spam_keywords",
                    "twitter",
                    1,
                    0,
                    keywords,
                    setErr,
                    handleValidation,
                  );
                }}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                InputProps={{
                  endAdornment: focused && (
                    <InputAdornment onMouseDown={handleInputMouseDown}>
                      <Button
                        className="add-btn-with-plus add-btn-plus-setting"
                        onClick={(event) => handleKeyDown(event, "btn")}
                        value={keywords[0].twitter[1].answer}
                        id="monitor-options-tw-aa-add-exclude-keyword-btn"
                      >
                        {CheckValueLocale("add_plus", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                value={keywords[0].twitter[1].answer}
                label={CheckValueLocale(
                  "exclude_posts_edit_public",
                  "",
                  {},
                  intl,
                )}
                onKeyDown={(event) => handleKeyDown(event)}
                className={`
                spam-text-field input-field-edit account-analysis-input  ${checkDirectionLang(
                  keywords[0].twitter[1]?.answer,
                )} `}
                disabled={!handlelUserRoles("SM", "EDIT_MONITOR")}
              />
            </Box>

            {keywords[0]?.twitter?.map((obj) => {
              if (Object.values(obj)[1]) {
                return (
                  <Box className="section-box-data">
                    <Typography className="main-titles"></Typography>
                    <Box
                      component="p"
                      className="spam-text-field err-msg-text spam-error-msg"
                    >
                      {CheckValueLocale(Object.values(obj)[1], "", {}, intl)}
                    </Box>
                  </Box>
                );
              }
            })}

            <Box className="section-box-data">
              <Box></Box>
              <Box className="spam-keywords-area spam-keyword-container">
                {keywords[0]?.twitter?.map((obj) => {
                  if (
                    Object.values(obj)[0].length > 0 &&
                    Object.keys(obj)[0] === "spam_keywords"
                  ) {
                    return Object.values(obj)[0].map((labels, index) => (
                      <>
                        <KeywordTooltipChip
                          onDelete={(e) =>
                            handleDeleteWord(e, index, Object.keys(obj)[0])
                          }
                          dataSource={"twitter"}
                          index={index}
                          labels={labels}
                          keywordSetting
                        />
                      </>
                    ));
                  }
                })}
              </Box>
            </Box>
            {isTwitter ? (
              <>
                <Divider />
                <DropdownSection
                  options={
                    twitterLanguagesAndCountries?.countries?.length
                      ? twitterLanguagesAndCountries?.countries
                      : []
                  }
                  placeholder={CheckValueLocale(
                    "select_countries",
                    "",
                    {},
                    intl,
                  )}
                  label={CheckValueLocale("countries", "", {}, intl)}
                  tooltip={CheckValueLocale(
                    "targeted_countries_tooltip",
                    "",
                    {},
                    intl,
                  )}
                  onChange={onCoutriesChange}
                  containerClassName="section-box-data-keyword-setting"
                  selectWrapperClassName="select-wrapper-public-twitter"
                  tooltipPosition={isRTL ? "left" : "right"}
                  defaultValue={twitterCountries}
                  applySelectionConditionFn={applySelectionConditionFn}
                />
                <Box className="chip-keywords-wrapper" />
                <Divider />
                <DropdownSection
                  options={
                    twitterLanguagesAndCountries?.languages?.length
                      ? twitterLanguagesAndCountries?.languages
                      : []
                  }
                  placeholder={CheckValueLocale(
                    "select_languages",
                    "",
                    {},
                    intl,
                  )}
                  label={CheckValueLocale("languages", "", {}, intl)}
                  tooltip={CheckValueLocale(
                    "targeted_languages_tooltip",
                    "",
                    {},
                    intl,
                  )}
                  onChange={onLanguagesChange}
                  containerClassName="section-box-data-keyword-setting"
                  selectWrapperClassName="select-wrapper-public-twitter"
                  tooltipPosition={isRTL ? "left" : "right"}
                  defaultValue={twitterLanguages}
                  applySelectionConditionFn={applySelectionConditionFn}
                />
                <Box className="chip-keywords-wrapper" />
                <Divider />
              </>
            ) : null}
          </>
        )}
      </Box>
      <Box className="section-box">
        <Box className="section-box-data section-box-data-customer">
          <Typography component={"h3"}>
            {CheckValueLocale("customer_care_account", "", {}, intl)}
            <Tooltip
              title={CheckValueLocale("customer_care_info", "", {}, intl)}
              placement={intl?.locale === "en" ? "right" : "left"}
              arrow
              id="radio-buttons-box"
              componentsProps={{
                tooltip: {
                  sx: {
                    width: "234px",
                  },
                },
              }}
            >
              <Typography component={"span"} className="tooltip-btn">
                <InfoIcon id="customer-care-info-icon" />
              </Typography>
            </Tooltip>
          </Typography>
          <Box className="shared-customer-care-container">
            <TextField
              error={monitorErrors?.cstCareAccount}
              label={
                <span
                  className={
                    monitorErrors?.cstCareAccount ? "username-label" : ""
                  }
                >
                  {CheckValueLocale("username", "", {}, intl)}
                </span>
              }
              placeholder={CheckValueLocale(
                "insert_username_then_enter",
                "",
                {},
                intl,
              )}
              id="outlined-start-adornment"
              className="customer-care-input"
              value={
                updatedParams?.customer_care_accounts?.length
                  ? updatedParams?.customer_care_accounts[0]
                  : monitorDetails?.customer_care_accounts
              }
              onChange={(e) => handleInputChangeCustomerCare(e)}
            />
          </Box>
        </Box>
        {monitorErrors?.cstCareAccount ? (
          <Box className="section-box-data">
            <Typography className="main-titles"></Typography>
            <Box
              component="p"
              className="spam-text-field err-msg-text spam-error-msg"
            >
              {CheckValueLocale(monitorErrors?.cstCareAccount, "", {}, intl)}
            </Box>
          </Box>
        ) : null}
        {/* _______________________________________________________________________________________ */}
      </Box>
    </>
  );
};

export default AccountAnalysisType;
