import { Box, FormControl } from "@mui/material";
import "./slaDetailsSideBar.scss";
import React, { useState, useEffect } from "react";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultUserAvtar from "images/shared-images/user-management-default.svg";
import { faUserTimes } from "@fortawesome/pro-solid-svg-icons";
import EngagementsController from "services/controllers/engagementsController";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SnackBar from "components/snackBar.js";
import UserImage from "../../sideBar/sideBarTabs/componenets/userImage";

const SlaAssigne = ({ selectedCard, headerData }) => {
  const intl = useIntl();
  const [teamsSelected, setTeamsSelected] = useState(null);
  const [userAssignList, setUserAssignList] = useState([]);
  const [userSelected, setUserSelected] = useState(null);
  const [disableSelect, setDisableSelect] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    userName: "",
  });

  const dispatch = useDispatch();
  //reduxSlaData is object of sla data to all cards , slaKey is the reduxSlaData key to get selected card sla data
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  let slaKey = `${selectedCard?.tweet_id ? selectedCard?.tweet_id : selectedCard?.id}-${selectedCard?.tracker_id}`;

  const handleChangeUserAndTeam = (dataObject, snackObj) => {
    setDisableSelect(true);
    let queryData = {
      //selected user
      new_user_id: dataObject?.userId ? +dataObject?.userId : null,
      //previos selected user
      old_user_id: reduxSlaData[slaKey]?.userId
        ? +reduxSlaData[slaKey]?.userId
        : null,
      //selected team
      new_user_team_id: dataObject?.teamId ? +dataObject?.teamId : null,
      // previos selected team
      old_user_team_id: reduxSlaData[slaKey]?.teamId
        ? +reduxSlaData[slaKey]?.teamId
        : null,
      interaction_id: selectedCard?.tweet_id || selectedCard?.id,
      monitor_id: selectedCard?.tracker_id,
      data_source_name: selectedCard?.data_source,
      product_id: +window.localStorage.getItem("engagementsProductId"),
    };
    EngagementsController.assignTeamsAndUsers({ ...queryData }).then((res) => {
      if (!res?.errorMsg) {
        // if success we update userId and teamId at redux to efect here and at card
        let slaDataCopy = { ...reduxSlaData };
        let updatedSlaData = {
          ...slaDataCopy[slaKey],
          userId: +dataObject?.userId || null,
          teamId: +dataObject?.teamId || null,
        };
        let updatedReduxSlaData = {
          ...slaDataCopy,
          [slaKey]: updatedSlaData,
        };
        dispatch(slaData(updatedReduxSlaData));
        setSnackBar({
          ...snackObj,
          open: true,
          severity: "success",
        });
      } else {
        const isAgentPermissionError =
          res?.errorMsg?.response?.data?.exception?.message ===
          "User does not have access to this monitor";
        setSnackBar({
          ...snackObj,
          open: true,
          severity: "error",
          isAgentPermissionError,
        });
      }
      setTimeout(() => {
        setSnackBar({
          open: false,
        });
      }, 5000);
      setDisableSelect(false);
    });
  };

  useEffect(() => {
    setSnackBar({ open: false });
  }, [intl.locale]);

  // handle change selected team
  useEffect(() => {
    setDisableSelect(true);
    // filter TeamsSelected from list of teams by teamId.
    let teamObj =
      reduxSlaData?.teams?.filter(
        (item) => +item?.id === +reduxSlaData[slaKey]?.teamId,
      )?.[0] || null;
    setTeamsSelected(teamObj !== null ? { ...teamObj } : null);
    if (reduxSlaData[slaKey]?.teamId) {
      // every time we change selected team we call getTeamUsers api to get list of users to this team
      EngagementsController.getTeamUsers(
        +reduxSlaData[slaKey]?.teamId,
        window.localStorage.getItem("engagementsProductId"),
      ).then((res) => {
        if (
          !res?.errorMsg &&
          res?.data?.data?.attributes?.users?.data?.length
        ) {
          setUserAssignList([...res?.data?.data?.attributes?.users?.data]);
        }
        setDisableSelect(false);
      });
    } else {
      // if teamId is falsy we reset users list and selected user
      setUserAssignList([]);
      setUserSelected(null);
      if (reduxSlaData[slaKey]?.userId) {
        let slaDataCopy = { ...reduxSlaData };
        let updatedSlaData = {
          ...slaDataCopy[slaKey],
          userId: null,
        };
        let updatedReduxSlaData = {
          ...slaDataCopy,
          [slaKey]: updatedSlaData,
        };
        dispatch(slaData(updatedReduxSlaData));
      }
    }
  }, [reduxSlaData[slaKey]?.teamId]);

  useEffect(() => {
    setDisableSelect(true);
    let userObj =
      userAssignList?.filter(
        (item) => +item?.id === +reduxSlaData[slaKey]?.userId,
      )?.[0] || null;
    if (userAssignList?.length && !userObj && reduxSlaData[slaKey]?.userId) {
      let slaDataCopy = { ...reduxSlaData };
      let updatedSlaData = {
        ...slaDataCopy[slaKey],
        userId: null,
      };
      let updatedReduxSlaData = {
        ...slaDataCopy,
        [slaKey]: updatedSlaData,
      };
      dispatch(slaData(updatedReduxSlaData));
    }
    setUserSelected(userObj !== null ? { ...userObj } : null);
    setDisableSelect(false);
  }, [reduxSlaData[slaKey]?.userId, userAssignList]);

  const handleTeamSelect = (event, value) => {
    let snackObj = {
      open: false,
      severity: "",
      msg: CheckValueLocale(
        value?.id ? "snack_assigned_team" : "snack_unassigned_user",
        "",
        { name: value?.name },
        intl,
      ),
      isTeam: true,
    };
    setDisableSelect(true);
    handleChangeUserAndTeam({ userId: null, teamId: +value?.id }, snackObj);
  };
  const handleUserSelect = (event, value) => {
    let snackObj = {
      open: false,
      severity: "",
      msg: CheckValueLocale(
        value?.id ? "snack_assigned_user" : "snack_unassigned_user",
        "",
        { name: value?.attributes?.name },
        intl,
      ),
      isTeam: false,
    };
    setDisableSelect(true);
    handleChangeUserAndTeam(
      {
        userId: +value?.id,
        teamId: +reduxSlaData[slaKey]?.teamId,
      },
      snackObj,
    );
  };
  // disabling the selects of assigning user and team if the user is not admin
  const isUserAdmin = handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS");
  return (
    <Box className="main-sla-contant with-border">
      <Box className="sla-contant-title">
        {CheckValueLocale("assigned_to", "", {}, intl)}
      </Box>
      <FormControl id="sla-assigns-selects">
        <Autocomplete
          disablePortal
          disabled={disableSelect || !isUserAdmin}
          options={
            reduxSlaData?.teams?.filter(
              (item) => +item?.id !== +teamsSelected?.id,
            ) || []
          }
          onChange={handleTeamSelect}
          value={teamsSelected}
          disableClearable
          groupBy={(option) => option?.typeOf}
          renderGroup={(params) => {
            return (
              <>
                {params?.children}
                {teamsSelected?.id ? (
                  <Box
                    disabled={disableSelect}
                    id="unassign-btn-sla"
                    onClick={() =>
                      !disableSelect && handleTeamSelect({ id: null })
                    }
                  >
                    <FontAwesomeIcon icon={faUserTimes} />
                    {CheckValueLocale("unassign", "", {}, intl)}
                  </Box>
                ) : null}
              </>
            );
          }}
          noOptionsText={
            <Box id="empty-result-sla">
              <Box className="empty-msg-sla">
                {CheckValueLocale(
                  !reduxSlaData?.teams?.length
                    ? "no_result"
                    : reduxSlaData?.teams?.length == 1 && teamsSelected?.id
                      ? "no_result"
                      : "no_result",
                  "",
                  {},
                  intl,
                )}
              </Box>
              {teamsSelected?.id ? (
                <Box
                  id="unassign-btn-sla"
                  onClick={() =>
                    !disableSelect && handleTeamSelect({ id: null })
                  }
                  disabled={disableSelect}
                >
                  <FontAwesomeIcon icon={faUserTimes} />
                  {CheckValueLocale("unassign", "", {}, intl)}
                </Box>
              ) : null}
            </Box>
          }
          getOptionLabel={(option) => option?.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={CheckValueLocale("assign_team", "", {}, intl)}
            />
          )}
          renderOption={(props, option) => (
            <Box disabled={disableSelect} component="li" {...props}>
              <Box className={`assign-item-countainer-sla-sidebar`}>
                <Box className="assign-item-details">
                  <Box>{option?.name}</Box>
                  <Box className="assign-item-state team-assign-item">
                    {option?.user_teams_count ? <Box></Box> : null}
                    {CheckValueLocale(
                      option?.user_teams_count
                        ? "users_num"
                        : "users_unavailable",
                      "",
                      { num: option?.user_teams_count },
                      intl,
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        />
        <Autocomplete
          disablePortal
          disabled={disableSelect || !isUserAdmin}
          options={
            userAssignList?.filter((item) => +item?.id !== +userSelected?.id) ||
            []
          }
          onChange={handleUserSelect}
          value={userSelected}
          disableClearable
          noOptionsText={
            <Box id="empty-result-sla">
              <Box className="empty-msg-sla">
                {CheckValueLocale(
                  !userAssignList?.length
                    ? "no_result"
                    : userAssignList?.length == 1 && userSelected?.id
                      ? "no_result"
                      : "no_result",
                  "",
                  {},
                  intl,
                )}
              </Box>
              {userSelected?.id ? (
                <Box
                  disabled={disableSelect}
                  id="unassign-btn-sla"
                  onClick={() =>
                    !disableSelect && handleUserSelect({ id: null })
                  }
                >
                  <FontAwesomeIcon icon={faUserTimes} />
                  {CheckValueLocale("unassign", "", {}, intl)}
                </Box>
              ) : null}
            </Box>
          }
          getOptionLabel={(option) => option?.attributes?.name}
          groupBy={(option) => option?.typeOf}
          renderGroup={(params) => {
            return (
              <>
                {params?.children}
                {userSelected?.id ? (
                  <Box
                    disabled={disableSelect}
                    id="unassign-btn-sla"
                    onClick={() =>
                      !disableSelect && handleUserSelect({ id: null })
                    }
                  >
                    <FontAwesomeIcon icon={faUserTimes} />
                    {CheckValueLocale("unassign", "", {}, intl)}
                  </Box>
                ) : null}
              </>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={CheckValueLocale("assign_user", "", {}, intl)}
            />
          )}
          renderOption={(props, option) => (
            <Box component="li" {...props} disabled={disableSelect}>
              <Box className={`assign-item-countainer-sla-sidebar`}>
                <Box className="assign-item-profile">
                  <UserImage user={option} />
                  <Box
                    className={
                      option?.attributes?.availability_status !== "unavailable"
                        ? "available"
                        : "unavailable"
                    }
                  ></Box>
                </Box>
                <Box className="assign-item-details">
                  <Box>{option?.attributes?.name}</Box>
                  <Box className="assign-item-state">
                    {CheckValueLocale(
                      option?.attributes?.availability_status !== "unavailable"
                        ? "available"
                        : "unavailable",
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        />
      </FormControl>
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        title={
          snackBar?.severity == "error" && !snackBar?.isAgentPermissionError
            ? CheckValueLocale("something_wrong", "", {}, intl)
            : null
        }
        message={
          snackBar?.severity == "error"
            ? CheckValueLocale(
                snackBar?.isTeam
                  ? "snack_assigned_team_error"
                  : snackBar?.isAgentPermissionError
                    ? "sla_agents_dont_have_permission"
                    : "snack_assigned_user_error",
                "",
                {},
                intl,
              )
            : snackBar?.msg
        }
      />
    </Box>
  );
};
export default SlaAssigne;
