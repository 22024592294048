import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Box,
  Stack,
} from "@mui/material";
import { useIntl } from "react-intl";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getCompanyApps } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import { checkQuotationMark } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/advancedKeyword";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../../createMonitor.scss";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import KeywordTooltipChip from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import {
  handleCopyBtn,
  checkValue,
  checkKeywordsValidation,
  checkEnabledCreateBtn,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";

import SocialMediaAccountButton from "../../../Components/SocialMediaAuthButton/socialMediaAuthButton";
import InputFieldName from "../../../Components/InputFieldName/inputFieldName";
import {
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import { DropdownSection } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/monitorDropdowns/monitorDropdowns";
import HashtagUsedNumber from "../../KeywordsMonitor/components/keywordHeader/hashtagUsedNumber";
import { getKeywordsLength } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordsLength";
import CustomerCareInput from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/customerCareInput";
const TwitterPublicAccount = (props) => {
  const intl = useIntl();
  const {
    monitorData,
    setMonitorData,
    monitorErrors,
    setMonitorErrors,
    handleCreateMonitorBtn,
    twSourceId,
    twitterAccounts,
    isLoading,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    twitterWordsCounter,
    setTwitterWordsCounter,
  } = props;

  const [companyApps, setCompanyApps] = useState([]);
  const [spamKeyword, setSpamKeyword] = useState("");
  const [coustomerCareVal, setCoustomerCareVal] = useState([]);
  const [copyBtn, setCopyBtn] = useState("click_to_copy");

  //// input Foucsed Case
  const [focused, setFocused] = useState(false);
  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  const [dataExist, setDataExist] = useState(true);

  const handleDeleteSpamKeyword = (index) => {
    monitorData.spamKeywords.splice(index, 1);
    setMonitorData({ ...monitorData });
  };

  // handle keyPress
  const handlekeyboardPress = (event, type, btnValue) => {
    if (twitterWordsCounter >= 100) return;
    let val = "";
    if (type === "btn") {
      val = btnValue;
    } else if (event.key === "Enter" && type === "input") {
      val = event.target.value;
    }
    if (
      (event.key === "Enter" || (type === "btn" && val)) &&
      dataExist &&
      val?.trim() !== ""
    ) {
      let keywordsCopy = [...monitorData.spamKeywords];

      let objData = handlekeywordsShared(
        val,
        "spam_keywords",
        [],
        keywordsCopy?.length ? keywordsCopy : [],
        "twitter",
        [
          {
            keywords: [],
          },
          {
            spam_keywords: keywordsCopy?.length ? keywordsCopy : [],
          },
        ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
        100,
        undefined, //in case newsblogs,
        undefined,
      );
      if (!objData?.errorMsg) {
        setMonitorData({
          ...monitorData,
          spamKeywords: [...objData?.spamKeywords],
        });
        setSpamKeyword("");
        setMonitorErrors({
          ...monitorErrors,
          spamKeywords: "",
        });
      } else {
        setSpamKeyword(val);
        setMonitorErrors({
          ...monitorErrors,
          spamKeywords: objData?.errorMsg,
        });
      }
    }
    setCopyBtn("click_to_copy");
  };

  const handleSpamKeywordChange = (event) => {
    let spam = event.target.value;
    setSpamKeyword(spam);
    let keywordsCopy = [...monitorData.spamKeywords];

    //using shared
    let errorMessage = handleValidationKeywords(
      spam,
      [],
      keywordsCopy?.length ? keywordsCopy : [],
      "spam_keywords",
      setDataExist,
      "twitter",
    );

    setMonitorErrors({
      ...monitorErrors,
      spamKeywords: errorMessage,
    });
  };

  useEffect(() => {
    getCompanyApps(setCompanyApps);
    props.setActiveDataSourceId(twSourceId);
  }, []);

  useEffect(() => {
    if (
      monitorErrors?.userName ||
      !monitorData?.userName ||
      monitorErrors?.spamKeywords ||
      monitorErrors?.monitorName ||
      monitorErrors?.monitorDescription
    ) {
      handleCreateMonitorBtn("");
    } else {
      handleCreateMonitorBtn("tw_pub");
    }
  }, [
    monitorErrors?.userName,
    monitorErrors?.spamKeywords,
    monitorErrors?.monitorName,
    monitorErrors?.monitorDescription,
  ]);

  const applySelectionConditionFn = (optionsToBeAdded) => {
    return optionsToBeAdded + twitterWordsCounter <= 100;
  };

  const onCoutriesChange = (newCoutries = []) => {
    setMonitorData({ ...monitorData, twitterCountries: newCoutries });
  };
  const onLanguagesChange = (newLanguages = []) => {
    setMonitorData({ ...monitorData, twitterLanguages: newLanguages });
  };

  useEffect(() => {
    let spamKeywordsCount = 0;
    if (monitorData?.spamKeywords?.length) {
      spamKeywordsCount = getKeywordsLength(monitorData?.spamKeywords);
    }
    setTwitterWordsCounter(
      spamKeywordsCount +
        monitorData?.twitterLanguages?.length +
        monitorData?.twitterCountries?.length,
    );
  }, [
    monitorData?.spamKeywords?.length,
    monitorData?.twitterLanguages,
    monitorData?.twitterCountries,
  ]);

  useEffect(() => {
    getTwitterLanguagesAndCountries();
    return () => {
      setTwitterWordsCounter(0);
    };
  }, []);
  useEffect(() => {
    setMonitorData({ ...monitorData, coustomerCareVal });
  }, [coustomerCareVal]);
  return (
    <Box>
      {!twitterAccounts || isLoading ? (
        <CircularLoading />
      ) : (
        // : isEmptyArray(twitterAccounts) ? (
        //   <>
        //     <SocialMediaAccountButton
        //       authHead={"you_need_to_connect_twitter_account"}
        //       companyApps={companyApps}
        //       authDataSourceName={"add_twitter_account"}
        //       isTwitter
        //       isPublicAccount
        //       isCompanyAccount={false}
        //     />
        //   </>
        // )
        <>
          <Stack direction="row-reverse">
            {twitterWordsCounter > 0 ? (
              <HashtagUsedNumber
                wordCountDataSource={twitterWordsCounter}
                limitationOfDataSource={100}
                isNewBlogs
              />
            ) : null}
          </Stack>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="social-media-fields"
          >
            <InputFieldName
              autoFocues={true}
              name="username"
              nameInfoMsg="username_with_sign"
              setMonitorErrors={setMonitorErrors}
              monitorErrors={monitorErrors}
              isPublicSpecialCharactersOrEmojis={
                isPublicSpecialCharactersOrEmojis
              }
              handleCreateMonitorBtn={handleCreateMonitorBtn}
              setMonitorData={setMonitorData}
              monitorData={monitorData}
              checkUserNameChar={true}
              maxNameLength={15}
              isSpecificFormat={true}
            />

            <Grid
              item
              xs={6}
              className="social-media-field-grid keywords-inputs-fields"
            >
              <CustomerCareInput
                coustomerCareVal={coustomerCareVal}
                setCoustomerCareVal={setCoustomerCareVal}
                isSpecificFormat={true}
              />
              <Tooltip
                title={CheckValueLocale(
                  "you_can_use_connectiong_methods",
                  "",
                  {},
                  intl,
                )}
                arrow
                placement="bottom"
              >
                <Typography variant="caption" className="media-input-label">
                  {CheckValueLocale("spam_keywords", "", {}, intl)}
                </Typography>
              </Tooltip>
              <OutlinedInput
                variant="outlined"
                className={`social-media-field keyword-input-field spam-x-platform-public ${checkDirectionLang(
                  spamKeyword,
                )}`}
                onFocus={() => setFocused(true)}
                onBlur={() => {
                  // Remove Error message if string is empty
                  if (spamKeyword?.length === 0) {
                    setMonitorErrors({
                      ...monitorErrors,
                      spamKeywords: "",
                    });
                  }
                  // Remove focus
                  setFocused(false);
                }}
                endAdornment={
                  focused && (
                    <InputAdornment onMouseDown={handleInputMouseDown}>
                      <Button
                        className="add-btn-with-plus"
                        onClick={(event) =>
                          handlekeyboardPress(event, "btn", spamKeyword)
                        }
                        id="monitor-options-tw-pa-add-exclude-keyword-btn"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {CheckValueLocale("add", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  )
                }
                placeholder={CheckValueLocale("exclude_posts", "", {}, intl)}
                onKeyPress={(event) =>
                  handlekeyboardPress(event, "input", spamKeyword)
                }
                onChange={handleSpamKeywordChange}
                value={spamKeyword}
              />
              {monitorErrors.spamKeywords && (
                <Box
                  component="p"
                  className="monitor-error-name input-msg-error-contaier"
                >
                  {CheckValueLocale(monitorErrors.spamKeywords, "", {}, intl)}
                </Box>
              )}
              <Box className="keyword-spam-wrapper">
                {checkValue(monitorData.spamKeywords).map((keyword, i) => {
                  return (
                    <KeywordTooltipChip
                      onDelete={() => handleDeleteSpamKeyword(i)}
                      dataSource={"twitter"}
                      index={i}
                      labels={keyword}
                    />
                  );
                })}
                <KeywordPopupModal
                  keywords={monitorData.spamKeywords}
                  handleDelete={handleDeleteSpamKeyword}
                  dataSourceType={"twitter"}
                  keywordType={"spam_keywords"}
                  dataSourceIndex={0} // dataSource index to handle delete keyword
                />
                {monitorData.spamKeywords.length !== 0 && (
                  <Tooltip
                    title={CheckValueLocale(copyBtn, "", {}, intl)}
                    arrow
                    placement="bottom"
                  >
                    <ContentCopyIcon
                      className="copy-icon"
                      onClick={() =>
                        handleCopyBtn(
                          setCopyBtn,
                          CheckValueLocale("copied", "", {}, intl),
                          monitorData.spamKeywords,
                        )
                      }
                    />
                  </Tooltip>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <DropdownSection
                options={
                  twitterLanguagesAndCountries?.countries?.length
                    ? twitterLanguagesAndCountries?.countries
                    : []
                }
                placeholder={CheckValueLocale("select_countries", "", {}, intl)}
                label={CheckValueLocale("countries", "", {}, intl)}
                tooltip={CheckValueLocale(
                  "targeted_countries_tooltip",
                  "",
                  {},
                  intl,
                )}
                onChange={onCoutriesChange}
                applySelectionConditionFn={applySelectionConditionFn}
              />
            </Grid>
            <Grid item xs={6}>
              <DropdownSection
                options={
                  twitterLanguagesAndCountries?.languages?.length
                    ? twitterLanguagesAndCountries?.languages
                    : []
                }
                placeholder={CheckValueLocale("select_languages", "", {}, intl)}
                label={CheckValueLocale("languages", "", {}, intl)}
                tooltip={CheckValueLocale(
                  "targeted_languages_tooltip",
                  "",
                  {},
                  intl,
                )}
                onChange={onLanguagesChange}
                applySelectionConditionFn={applySelectionConditionFn}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default TwitterPublicAccount;
