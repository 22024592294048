import { useWidgetName } from "../../../hooks/useWidgetName";

export const facebookRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  switch (response?.eventName) {
    case "Facebook__EngagementsPage__dialects_and_sub_dialects": // Keyword
      handleWidgetResponse(
        "dialects_subdialects",
        response?.monitor_id,
        "FACEBOOK",
        response?.eventData,
      );
      break;

    case "Facebook__AccountPage__posts_interactions": // Manged
    case "Facebook__AccountPage__posts_interactions": // Public
      handleWidgetResponse(
        "posts_interactions",
        response?.monitor_id,
        "FACEBOOK",
        { interactions: response?.eventData?.posts_interactions },
      );
      break;

    case "Facebook__EngagementsPage__posts_interactions": // Keyword
      handleWidgetResponse(
        monitorType === "KEYWORD"
          ? "posts_interactions"
          : "comments_interactions",
        response?.monitor_id,
        "FACEBOOK",
        { interactions: response?.eventData?.posts_interactions },
      );
      break;

    case "Facebook__EngagementsPage__gender_distribution":
      handleWidgetResponse(
        "gender_age_distribution",
        response?.monitor_id,
        "FACEBOOK",
        response?.eventData,
      );
      break;

    case "Facebook__EngagementsPage__reactions":
      handleWidgetResponse(
        monitorType === "KEYWORD" ? "posts_reactions" : "comments_reactions",
        response?.monitor_id,
        "FACEBOOK",
        response?.eventData?.posts_reactions,
      );
      break;

    case "Facebook__AccountPage__page_videos_views": // Managed
      handleWidgetResponse(
        "videos_views",
        response?.monitor_id,
        "FACEBOOK",
        response?.eventData,
      );
      break;

    case "Facebook__AccountPage__page_reach_impressions": // Managed
      handleWidgetResponse(
        "page_reach_impressions",
        response?.monitor_id,
        "FACEBOOK",
        response?.eventData,
      );
      break;

    case "Facebook__AccountPage__reach_funnel": // Managed
      handleWidgetResponse(
        "posts_reach_impressions",
        response?.monitor_id,
        "FACEBOOK",
        response?.eventData,
      );
      break;

    case "Facebook__CustomerCarePage__customer_care_performance":
      handleWidgetResponse(
        "customer_care_performance",
        response?.monitor_id,
        "FACEBOOK",
        { customer_care_performance: response?.eventData },
      );
      break;

    case "Facebook__AccountPage__account_author_activity":
      handleWidgetResponse(
        "account_authors_activity",
        response?.monitor_id,
        "FACEBOOK",
        { account_authors_activity: response?.eventData },
      );
      break;

    case "Facebook__EngagementsPage__account_author_activity": // engagers activity in Managed
      handleWidgetResponse(
        "engagers_activity",
        response?.monitor_id,
        "FACEBOOK",
        { engagers_activity: response?.eventData },
      );
      break;

    default:
      if (stateKey)
        handleWidgetResponse(
          stateKey,
          response?.monitor_id,
          "FACEBOOK",
          response?.eventData,
        );
      break;
  }
};
