// @ts-nocheck
import { useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  Tooltip,
  Typography,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import moment from "moment";
import getGroupedData from "./GroupThemeTrend";
import { extractPathfromURLForIds } from "utils/helpers";
//CSS
import "./ThemesTrends.scss";
// Stacked bar Chart
import StackedBarChart from "../../../echarts/StackedBarChart.js";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import _ from "lodash";
import FetchingData from "../../../../components/fetchingData/fetchingData";
//import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aiIcon from "../../../../images/ai.svg";
import WidgetDownloader from "../../../../components/widgets/WidgetDownloader";
import NoDataFound from "components/no-Data/noDataFound";
import ThemesAnalysis from "../themeAnalysis/ThemeAnalysis";

const dummyXAxisData = [
  "Jan",
  "Feb",
  "Mar",
  "April",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const reOrderData = (data) => {
  if (data.indexOf("Undefined") !== -1) {
    let temp = data.splice(data.indexOf("Undefined"), 1)[0];
    data.push(temp);
  }
  if (data.indexOf("غير معرف") !== -1) {
    let temp = data.splice(data.indexOf("غير معرف"), 1)[0];
    data.push(temp);
  }
  if (data.indexOf("Other") !== -1) {
    let temp = data.splice(data.indexOf("Other"), 1)[0];
    data.push(temp);
  }
  if (data.indexOf("أخري") !== -1) {
    let temp = data.splice(data.indexOf("أخري"), 1)[0];
    data.push(temp);
  }
  return data;
};
const useStyles = makeStyles((theme) => ({
  customWidth: {
    marginLeft: "50px",
    marginRight: "50px",
  },
}));
const ThemesTrends = (props) => {
  const intl = useIntl();
  const classes = useStyles();
  const themeList = _.orderBy(props.data, [], ["desc"]).reverse();
  const [chart, setChart] = useState({
    values: [],
    labels: [],
    barName: [],
    legends: [],
  });
  const [themeTab, setThemeTab] = useState([]);
  const [activeThemeTab, setActiveThemeTab] = useState("All");
  const [expandBy, setExpandBy] = useState("main-themes");
  const [groupDuration, setGroupDuration] = useState("day");
  const [haveData, setHaveData] = useState(false);

  const [sliderPercentage, setSliderPercentage] = useState(0);
  const [themeColors, setThemeColors] = useState([]);
  let { loading } = props;

  const themesColors = {
    complaint: "#E85954",
    Compliment: "#9FC653",
    compliment: "#9FC653",
    Complement: "#9FC653",
    complement: "#9FC653",
    other: "#D3D3D3",
    question: "#2AAAE2",
    "customer services": "#23A41A",
    delivery: "#A4CEE7",
    "portal/app": "#B0E381",
    payment: "#CCB0D8",
    "products & services": "#1176B9",
    undefined: "#FEC160",
    "custom services": "#23A41A",
    أسئلة: "#2AAAE2",
    اسئلة: "#2AAAE2",
    تكملة: "#9FC653",
    أخري: "#D3D3D3",
    "غير معرف": "#FEC160",
    توصيل: "#A4CEE7",
    شكاوي: "#E85954",
    "خدمة العملاء": "#23A41A",
    دفع: "#CCB0D8",
    "البوابة / التطبيق": "#B0E381",
    "المنتجات والخدمات": "#1176B9",
    "خدمات المنتج": "#1176B9",
    مدح: "#9FC653",
    شكوي: "#E85954",
    السؤال: "#2AAAE2",
    أخرى: "#D3D3D3",
  };
  let colorList = [];
  let chartName = chart.barName;
  if (chartName !== null && chartName !== undefined) {
    for (var i = 0; i < chartName.length; i++) {
      if (!colorList.includes(themesColors[chartName[i]?.toLowerCase()])) {
        colorList.push(themesColors[chartName[i]?.toLowerCase()]);
      }
    }
  }

  useEffect(() => {
    if (!isEmptyObj(props.data)) {
      setDataForChart(props.data);
      setGroupDuration("day");
    } else if (isEmptyObj(props.data)) {
      setDummyDataForChart();
    }
  }, [
    props.data,
    expandBy,
    intl?.locale, // re-calculate the chart data when the language is changed
  ]);

  const setDummyDataForChart = () => {
    let subTheme = [];
    if (!_.isEmpty(themeList.sub_themes)) {
      themeList.sub_themes.forEach((theme) => {
        subTheme.push(Object.keys(theme)[0]);
      });
    }

    setChart({
      values: [[0]],
      labels: dummyXAxisData,
      legends: subTheme,
      barName: subTheme,
    });
    setHaveData(false);
  };

  const getChartMeta = (data, duration) => {
    let barName = new Set(),
      tabs = new Set(),
      legends = new Set(),
      legendsForChart = new Set(),
      labels = [],
      sliderPercentage = 0;

    tabs.add("All");

    for (let [key, theme] of Object.entries(data)) {
      let mainTheme =
        expandBy === "main-themes"
          ? theme.main_themes_view
          : theme.sub_themes_view;

      if (mainTheme) {
        for (let key in mainTheme) {
          let newKey = key;
          if (key.toLowerCase() === "complement") {
            newKey = "Compliment";
          }
          tabs.add(CheckValueLocale(newKey, "", {}, intl));

          let subTheme =
            expandBy === "main-themes"
              ? mainTheme[key].sub_themes
              : mainTheme[key].main_themes;

          for (let subThemeKey in subTheme) {
            if (subThemeKey.toLowerCase() === "complement") {
              subThemeKey = "Compliment";
            }
            legends.add(subThemeKey);
            legendsForChart.add(CheckValueLocale(subThemeKey, "", {}, intl));
            barName.add(CheckValueLocale(subThemeKey, "", {}, intl));
          }
        }

        labels.push(
          duration === undefined
            ? moment.unix(Number(key)).format("YYYY-MM-DD")
            : key,
        );
      }
    }

    if (labels.length > 30) {
      sliderPercentage = Math.floor(100 - (10 / labels.length) * 100);
    }
    return {
      labels: labels,
      legends: [...legends],

      legendsForChart: [...legendsForChart],
      barName: [...barName],
      tabs: [...tabs],
      sliderPercentage,
    };
  };

  const setDataForChart = (data, duration) => {
    let themeValues = [];
    let chartMeta = getChartMeta(data, duration);
    chartMeta.legends.map((legend, index) => {
      let values = [];
      for (let [key, theme] of Object.entries(data)) {
        if (!isEmptyObj(theme)) {
          let value = "";

          let subTheme =
            expandBy === "main-themes"
              ? theme.sub_themes_view
              : theme.main_themes_view;
          for (let [key, subThemeValue] of Object.entries(subTheme)) {
            if (key.toLowerCase() === "complement") {
              key = "Compliment";
            }
            if (key === legend) {
              value = subThemeValue.count;
            }
          }
          values.push(value);
        }
      }
      themeValues[index] = values;
    });

    if (themeValues.length === 0) {
      setChart({ ...chart, labels: dummyXAxisData, values: [[0]] });
      setHaveData(false);
    } else {
      setSliderPercentage(chartMeta.sliderPercentage);
      setThemeTab(chartMeta.tabs);
      setChart({
        values: themeValues,
        labels: chartMeta.labels,
        legends: chartMeta.legendsForChart,
        barName: chartMeta.barName,
      });
      setHaveData(true);
    }
  };

  const handleThemeTab = (tab) => {
    setActiveThemeTab(tab);
    if (tab === "All") {
      if (groupDuration !== "day") {
        setDataForChart(
          getGroupedData(props.data, groupDuration),
          groupDuration,
        );
      } else {
        setDataForChart(props.data);
      }
    } else {
      let themeValues = [];

      let chartMeta = getChartMeta(
        getGroupedData(props.data, groupDuration),
        groupDuration,
      );
      chartMeta.legendsForChart.map((legend, index) => {
        let values = [];
        Object.values(props.data).forEach((theme) => {
          if (!isEmptyObj(theme)) {
            let value = "",
              mainTheme =
                expandBy === "main-themes"
                  ? theme.main_themes_view
                  : theme.sub_themes_view;

            for (let [key, mainThemeValue] of Object.entries(mainTheme)) {
              if (
                CheckValueLocale(key, "", {}, intl).toLowerCase() ===
                tab.toLowerCase()
              ) {
                let subTheme =
                  expandBy === "main-themes"
                    ? mainThemeValue.sub_themes
                    : mainThemeValue.main_themes;
                for (let [key, val] of Object.entries(subTheme)) {
                  if (key.toLowerCase() === "complement") {
                    key = "Compliment";
                  }
                  if (CheckValueLocale(key, "", {}, intl) === legend) {
                    value = val;
                  }
                }
              }
            }
            values.push(value);
          }
        });
        themeValues[index] = values;
      });

      setChart({
        values: themeValues,
        legends: chartMeta.legendsForChart,
        labels: chartMeta.labels,
        barName: chartMeta.barName,
      });
    }
  };

  const toggleThemes = (event) => {
    setExpandBy(event.target.value);
    setActiveThemeTab("All");
    setGroupDuration("day");
  };

  const showGroupData = (duration) => {
    setGroupDuration(duration);
    if (duration === "day") {
      setDataForChart(props.data);
    } else {
      let groupedData = getGroupedData(props.data, duration);
      setDataForChart(groupedData, duration);
    }
  };

  const totalLabel = CheckValueLocale("themes_trend", "", {}, intl);

  return (
    <div
      className="chart"
      style={{ height: "500px" }}
      id={props?.title ?? "themes-trend-widget-title"}
    >
      <header className="chart__header data-overtime__header widget-header-container-dashboard">
        <h3 className="widget-header-container-title">
          {props.title && props.titleToolTip ? (
            <Tooltip
              title={
                <div className="tooltip-custom-new">
                  {props.titleToolTip &&
                    CheckValueLocale(props.titleToolTip, "", {}, intl)}
                </div>
              }
              arrow
              placement="top"
              classes={{ tooltip: classes.customWidth }}
            >
              <Typography variant={"h7"} className="widget-title">
                {CheckValueLocale(props.title, "", {}, intl)}
                {props?.widgetSection ? (
                  <span className="widget-title-section">
                    ({CheckValueLocale(props?.widgetSection, "", {}, intl)})
                  </span>
                ) : (
                  ""
                )}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant={"h7"} className="widget-title">
              {CheckValueLocale(props.title, "", {}, intl)}
              {props?.widgetSection ? (
                <span className="widget-title-section">
                  ({CheckValueLocale(props?.widgetSection, "", {}, intl)})
                </span>
              ) : (
                ""
              )}
            </Typography>
          )}
          {props.iconAfterTitle && (
            <span className="icon-after-title-themes-trends">
              {props.iconAfterTitle}
            </span>
          )}
        </h3>
        <div className="theme_options widget-header-container-filter">
          {!_.isEqual(
            props.themeTrendPreLoaderDataSources.sort(),
            props.preLoaderTrackerDataSources.sort(),
          ) !== true ? (
            <div className="icons-wrapper">
              <Box component="div" className={"select__container"}>
                <TextField
                  select
                  label={CheckValueLocale("expand_by", "", {}, intl)}
                  value={expandBy}
                  onChange={(e) => toggleThemes(e)}
                  className={"select btn-themes-theme-trand"}
                  defaultValue={"main-themes"}
                >
                  <MenuItem value="main-themes" id="themes-trends-item-themes">
                    {CheckValueLocale("themes", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="sub_themes"
                    id="themes-trends-item-sub-themes"
                  >
                    {CheckValueLocale("sub_themes", "", {}, intl)}
                  </MenuItem>
                </TextField>
              </Box>

              <Box component="div" className={"select__container"}>
                <TextField
                  select
                  label={CheckValueLocale("group_by", "", {}, intl)}
                  value={groupDuration}
                  onChange={(e) => showGroupData(e.target.value)}
                  className={"btn-group-by select btn-themes-theme-trand"}
                >
                  <MenuItem value="day" id="themes-trends-item-day">
                    {CheckValueLocale("day", "", {}, intl)}
                  </MenuItem>
                  <MenuItem value="weekly" id="themes-trends-item-weekly">
                    {CheckValueLocale("weekly", "", {}, intl)}
                  </MenuItem>
                  <MenuItem value="monthly" id="themes-trends-item-monthly">
                    {CheckValueLocale("monthly", "", {}, intl)}
                  </MenuItem>
                  <MenuItem value="yearly" id="themes-trends-item-yearly">
                    {CheckValueLocale("yearly", "", {}, intl)}
                  </MenuItem>
                </TextField>
              </Box>
            </div>
          ) : null}
          <div className="themes_themestrends">
            {props.showAI && (
              <div className="aiIconDiv">
                <img src={aiIcon} alt="aiIcon" />
              </div>
            )}
            {props.showDownloadIcon && (
              <WidgetDownloader
                chartId={props.title}
                bigImg={props.bigImg}
                downloadBtnId={`widget-downloader-theme-downloader-${
                  extractPathfromURLForIds(window?.location?.pathname) ??
                  "themes-trends-pathName"
                }`}
              />
            )}
          </div>
        </div>
      </header>

      {_.isEqual(
        props.themeTrendPreLoaderDataSources.sort(),
        props.preLoaderTrackerDataSources.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : _.isEmpty(props.data) || !haveData ? (
        <NoDataFound />
      ) : (
        <div>
          {themeTab.length > 0 && (
            <div className="theme-tab">
              {themeTab.map((tab) => (
                <span
                  onClick={() => handleThemeTab(tab)}
                  className={activeThemeTab === tab && "active"}
                >
                  {tab === "All" ? CheckValueLocale(tab, "", {}, intl) : tab}
                </span>
              ))}
            </div>
          )}

          <StackedBarChart
            dataNames={chart?.barName}
            dataColor={colorList}
            dataValues={chart.values}
            dataLabels={chart.labels}
            sliderPercentage={sliderPercentage}
            activeThemeTab={activeThemeTab}
            expandBy={expandBy}
            legend={chart.legends}
            legendPosition={
              window.localStorage.lang === "ar" ? "topRight" : "topLeft"
            }
            showZoomPercentage={true}
            fromThemesWidget
          />
        </div>
      )}
    </div>
  );
};

export default ThemesTrends;
