import React, { useEffect, useState } from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./userProfileDrawer.scss";
import { Box } from "@mui/material";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import { isEmptyObj, convertXPlateformToTwitter } from "utils/helpers";
import EngagementsController from "services/controllers/engagementsController";
import ProfileSideBar from "./profileSideBar";
import { useIntl } from "react-intl";
import ProfileSideBarLoading from "pages/engagements/components/detailsSideBar/profileSideBar/profileSideBarLoading";
const UserProfileSideBar = ({
  dataSourceName,
  openProfile,
  setOpenProfile,
  selectedCard,
  monitorDataSource,
  monitorId,
  productId,
  unixDate,
}) => {
  const intl = useIntl();
  const [response, setResponse] = useState({});
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [profileSideBarData, setProfileSideBarData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  dataSourceName = convertXPlateformToTwitter(dataSourceName);
  const queryData = {
    start_date: unixDate?.start,
    end_date: unixDate?.end,
    product_id: productId,
    monitor_id: monitorId,
    data_source_name: dataSourceName,
    page_number: 1,
    user_name: selectedCard?.screen_name
      ? selectedCard?.screen_name
      : selectedCard?.user_name,
  };
  const supportedDataSource = ["TWITTER", "FACEBOOK", "INSTAGRAM"];
  const isSupported = supportedDataSource.includes(dataSourceName);

  useEffect(() => {
    if (!isSupported) {
      setProfileSideBarData([{ dataSourceName: dataSourceName }]);
      setIsLoading(false);
    } else {
      EngagementsController.getSingleUserProfileData({ ...queryData }).then(
        (data) => {
          if (data?.data?.password && !isEmptyObj(data?.data)) {
            setResponse({});
            connectWithRabbitMQ(
              mqttConnectionClosed,
              clientMQTT,
              data?.data,
              setResponse,
            );
          } else {
            mqttConnectionClosed.current = true;
          }
        },
      );
    }
  }, []);
  useEffect(() => {
    if (!isEmptyObj(response)) {
      switch (response.eventName) {
        case "Twitter_public__EngagementsProfileDataPage":
        case "Instagram_public__EngagementsProfileDataPage":
        case "Facebook_public__EngagementsProfileDataPage":
        case "Facebook_public__EngagementsProfileDataPage":
        case "Instagramprivate__EngagementsProfileDataPage":
        case "Twitterprivate__EngagementsProfileDataPage":
        case "Facebookprivate__EngagementsProfileDataPage":
          setProfileSideBarData(response.eventData);
          setIsLoading(false);
          break;
        default:
          break;
      }
    }
  }, [response]);

  return (
    <SwipeableDrawer
      className="user-side-bar-drawer user-swipeable-drawer"
      id="user-swipeable-drawer"
      anchor={intl.locale === "en" ? "right" : "left"}
      onClose={() => setOpenProfile(false)}
      open={openProfile}
    >
      <Box className="user-side-bar-drawer">
        {isLoading ? (
          <ProfileSideBarLoading />
        ) : (
          <ProfileSideBar
            isSupported={isSupported}
            dataSourceName={dataSourceName}
            setOpenProfile={setOpenProfile}
            selectedCard={selectedCard}
            profileSideBarData={profileSideBarData}
          />
        )}
      </Box>
    </SwipeableDrawer>
  );
};

export default UserProfileSideBar;
