import { useState } from "react";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faEye, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { faPen } from "@fortawesome/pro-light-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import dashboardController from "services/controllers/dashboardController";
import PopupModal from "components/popupModal.js";
import "./style.scss";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const history = useHistory();
  const {
    dashboardItem,
    setSnackBarData,
    setOpenSnackBarDelete,
    dashboards,
    page,
    setPage,
  } = props;
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(false);
  let slProduct = localStorage?.getItem("sm_id");
  let enabledDelete =
    props?.dashboardItem?.attributes?.product_id == slProduct
      ? handlelUserRoles("SM", "DESTROY_DASHBOARD")
      : handlelUserRoles("CXM", "DESTROY_DASHBOARD");
  let enabledEdit =
    props?.dashboardItem?.attributes?.product_id == slProduct
      ? handlelUserRoles("SM", "DESTROY_DASHBOARD")
        ? true
        : props?.dashboardItem?.attributes?.dashboard_owner &&
          handlelUserRoles("SM", "EDIT_DASHBOARD")
      : handlelUserRoles("CXM", "DESTROY_DASHBOARD")
        ? true
        : props?.dashboardItem?.attributes?.dashboard_owner &&
          handlelUserRoles("CXM", "EDIT_DASHBOARD");
  const open = Boolean(anchorEl);
  const [stats, setStats] = useState({
    openDeleteModal: false,
    openDropDownList: true,
    adminPermissions: true,
    openPauseResumeModal: false,
    modalTitle: "",
    modalBody: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    snackBarAction: "",
    snackBarSeverity: "",
  });
  window.addEventListener("scroll", (event) => {
    if (anchorEl) {
      setAnchorEl(false);
    }
  });
  const handleClick = (event, dashboardItem) => {
    setStats({ openDropDownList: true });
    setAnchorEl(event.currentTarget);
  };
  var deleteModalTitle = "delete_dashboard",
    deleteModalBody = (
      <Box className="shared-dialog-body">
        <p>{`${CheckValueLocale("delete_dashboard_msg_title", "", {}, intl)} ${dashboardItem?.attributes?.name} ${CheckValueLocale("question_mark", "", {}, intl)}`}</p>
        <h2 className="sub-text-desc">
          {CheckValueLocale("delete_dashboard_msg_body", "", {}, intl)}
        </h2>
      </Box>
    ),
    deleteModalRightBtn = "delete_confirm",
    deleteModalLeftBtn = "dashboard_cancel";
  const handleOpenDeleteModal = () => {
    setStats({
      ...stats,
      openDeleteModal: true,
      modalTitle: deleteModalTitle,
      modalBody: deleteModalBody,
      modalRightBtn: deleteModalRightBtn,
      modalLeftBtn: deleteModalLeftBtn,
    });
    setAnchorEl(false);
  };
  const handleDeleteDashboard = (dashboardId) => {
    dashboardController.deleteDashboard(dashboardId).then((res) => {
      if (
        res.data &&
        (res?.data?.status === 202 || res?.data?.status === 204)
      ) {
        if (dashboards?.length === 1 && page > 1) {
          setPage(page - 1);
        } else {
          props.getDashboards();
        }
        setSnackBarData({
          severity: "success",
          message: "deleted_dashboard_success_msg",
        });
        setOpenSnackBarDelete(true);
      } else {
        setSnackBarData({
          severity: "error",
          message: res?.errorMsg?.response?.data?.exception?.message
            ? res?.errorMsg?.response?.data?.exception?.message
            : "failed_error",
        });
        setOpenSnackBarDelete(true);
      }
      setStats({ ...stats, openDeleteModal: false });
    });
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleCloseDeleteModal = () => {
    setStats({ ...stats, openDeleteModal: false });
  };
  return (
    <>
      <Box className="menu-list dasboard-list">
        <CustomizedTooltip
          id="menu-item-list-tooltip"
          title={
            <Box className="item-title-tooltip">
              {CheckValueLocale("items_ellipsis", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <Button
            variant="text"
            className={
              "menu-items  menu-item-container " +
              (open && stats?.openDropDownList ? "menu-item-active" : "")
            }
            aria-label="more"
            id="dashboard-list-long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              handleClick(e, props?.dashboardItem);
            }}
          >
            <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
          </Button>
        </CustomizedTooltip>

        {stats?.openDropDownList ? (
          <Menu
            id="dashboard-menu-dropdown"
            MenuListProps={{
              "aria-labelledby": "monitor-menu-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {props?.dashboardItem?.attributes?.dashboard_type ===
            "CUSTOM_DASHBOARD" ? (
              <MenuItem
                id={`dashboard-menu-dropdown-view-custom-dashboard}`}
                disableRipple
                disabled={false}
                onClick={() =>
                  history.push({
                    pathname: `/dashboard/custom/view/${props?.dashboardItem?.id}`,
                  })
                }
              >
                <FontAwesomeIcon fixedWidth icon={faEye} />
                <span className="dashboard-menu-text">
                  {CheckValueLocale("dashboard_view", "", {}, intl)}
                </span>
              </MenuItem>
            ) : null}
            <MenuItem
              disabled={!enabledEdit}
              onClick={() => {
                history.push({
                  pathname: `/dashboard/${
                    props?.dashboardItem?.attributes?.dashboard_type ===
                    "CUSTOM_DASHBOARD"
                      ? "custom/edit"
                      : "edit_dashboard"
                  }/${props?.dashboardItem?.id}`,
                  state: {
                    sideBarData: dashboardItem?.attributes?.dashboard_side_bar,
                    monitorType:
                      dashboardItem?.attributes?.dashboard_monitor_type,
                  },
                });
              }}
              disableRipple
              id={`dashboard-menu-dropdown-edit-${
                props?.dashboardItem?.id ?? "dashboard-item"
              }`}
            >
              <FontAwesomeIcon fixedWidth icon={faPen} />
              <span className="dashboard-menu-text">
                {CheckValueLocale("edit", "", {}, intl)}
              </span>
            </MenuItem>
            <MenuItem
              disabled={!enabledDelete}
              disableRipple
              onClick={handleOpenDeleteModal}
              id={`dashboard-menu-dropdown-delete-${
                props?.dashboardItem?.id ?? "dashboard-item"
              }`}
            >
              <FontAwesomeIcon fixedWidth icon={faTrash} />
              <span className="dashboard-menu-text">
                {CheckValueLocale("dashboard_delete", "", {}, intl)}
              </span>
            </MenuItem>
          </Menu>
        ) : null}
        {stats?.openDeleteModal && stats?.openDropDownList && (
          <PopupModal
            title={CheckValueLocale(stats?.modalTitle, "", {}, intl)}
            body={deleteModalBody}
            leftBtn={CheckValueLocale(stats?.modalLeftBtn, "", {}, intl)}
            rightBtn={CheckValueLocale(stats?.modalRightBtn, "", {}, intl)}
            open={stats?.openDeleteModal}
            close={handleCloseDeleteModal}
            accept={() => handleDeleteDashboard(dashboardItem?.id)}
            rightColor="error"
            warning
            minWidth="600px"
            classeName="actions-buttons-shared"
          />
        )}
      </Box>
    </>
  );
};
export default OptionsDropDown;
