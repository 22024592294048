// *******COMMON FILE,Any change in this file will have effect on many pages
// Please Don't Change, delete or update in this file without perrmission

import ReactEcharts from "echarts-for-react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const BarChart = (props) => {
  const intl = useIntl();

  const formatToolTip = (data, toolTipLabel) => {
    let data_value = data[0].value;
    if (props?.isScaleQues === true) {
      return `${data[0].marker} ${Number(data_value).toLocaleString()}`;
    }
    if (props.isSurvey) {
      return `${data[0].marker} ${CheckValueLocale(
        props.getSurveyName(data[0].name),
        "",
        {},
        intl,
      )}   : ${Number(data_value).toLocaleString()}`;
    }
    if (toolTipLabel) {
      return `${CheckValueLocale(data[0].name, "", {}, intl)} <br /> ${
        data[0].marker
      } ${toolTipLabel} : ${Number(data_value).toLocaleString()}`;
    }

    return `${data[0].marker} ${CheckValueLocale(
      data[0].name,
      "",
      {},
      intl,
    )}: ${Number(data_value).toLocaleString()}`;
  };

  let option = {
    color: props.dataColor,
    tooltip: {
      trigger: "axis",
      show: props.noData ? false : true,
      backgroundColor: "#fff",
      textStyle: {
        fontSize: 12,
      },
      padding: [10, 15],
      formatter: (val) => formatToolTip(val),
    },
    legend: {
      show: props.showLegend, // Start legendPosition___________________//
      legend:
        props.legendPosition === "right"
          ? {
              data: props.legend,
              itemGap: 25,
              type: "scroll",
              icon: "circle",
              orient: "vertical",
              itemHeight: 15,
              itemWidth: 15,
              right: 1,
              top: 20,
            }
          : props.legendPosition === "topLeft"
            ? {
                data: props.legend,
                itemGap: 15,
                itemHeight: 14,
                itemWidth: 14,
                left: 1,
                top: 0,
              }
            : props.legendPosition === "topRight"
              ? {
                  data: props.legend,
                  itemGap: 15,
                  itemHeight: 14,
                  itemWidth: 14,
                  right: 1,
                  top: 0,
                }
              : {
                  data: props.legend,
                  bottom: 0,
                },
      // End legendPosition___________________//
    },
    calculable: false,
    grid: {
      x: 10,
      x2: 10,
      y: 30,
      y2: 10,
      left: props.yAxisGridLeft,
      right: "1%",
      bottom: props.showZoomPercentage ? "20%" : "5%",
      height: props.gridHeight,
    },

    xAxis: {
      data: props.dataLabels,
      type: "category",
      axisLabel: {
        fontSize: 11,
        color: props.xAxisFontColor,
        fontWeight: props.xAxisFontWeight,
        textStyle: {
          color: "rgba(0, 0, 0, 0.42)",
        },
      },
      axisLine: {
        show: true,
        onZero: true,
        lineStyle: {
          width: 0,
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "value",
        axisLabel: {
          textStyle: {
            color: "rgba(0, 0, 0, 0.42)",
          },
        },
      },
    ],
    series: [
      {
        name: props.dataLabels,
        cursor: props.handleOpenQuickInspect ? "pointer" : "default",
        type: "bar",
        stack: "bar",
        barMaxWidth: "50px",
        data: props.dataValues,
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: function (param) {
            return props.customBarColor
              ? props.customBarColor[param.name]
              : props?.isSurvey
                ? props.colorsSurvey[param.name]
                : props.dataColor;
          },
        },
      },
    ],
    dataZoom: [
      {
        start: props.sliderPercentage,
        end: props.showZoomPercentage ? 50 : 100,
        bottom: 10,
        show: props.showZoomPercentage ? true : false,
        fillerColor: "rgba(42, 170, 226, 0.06)",
        dataBackground: {
          areaStyle: {
            color: "transparent",
          },
        },
        selectedDataBackground: {
          lineStyle: {
            color: "rgba(203, 213, 225, 1)",
          },
          areaStyle: {
            color: "transparent",
          },
        },
      },
    ],
  };

  return (
    <ReactEcharts
      notMerge={true}
      option={option}
      theme="custom_theme"
      style={{ height: "370px", width: "100%" }}
    />
  );
};

export default BarChart;
