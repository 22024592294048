import { Box, Button, Menu, Tooltip, Popover } from "@mui/material";
import {
  CheckValueLocale,
  isArabic,
  handlelUserRoles,
  extractDisplayInfoFromDataSource,
} from "utils/helpers";
import "./engagementHeader.scss";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faCopy,
  faCheckSquare,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle, faLanguage } from "@fortawesome/free-solid-svg-icons";
import EngagementsController from "services/controllers/engagementsController";
import ProfileUser from "../profileUser/profileUser";
import EngagementComplete from "./engagementComplete";
import "./engagementHeader.scss";
import { faFolderOpen } from "@fortawesome/pro-regular-svg-icons";
import EngagementTranslation from "../engagementTranslation/engagementTranslation";
import publicFacebook from "images/engagements-images/fb-icon-engag.svg";
import publicInstagram from "images/engagements-images/ig-icon-engag.svg";
import LucButton from "shared/lucButton/lucButton";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";

const EngagementHeader = ({
  selectedCard,
  setCardState,
  cardState,
  itemData,
  headerData,
  setStatusChanged,
  statusChanged,
  setRefresh,
  stats,
  setStats,
  setTabValue,
  setComplete,
  setOpenDetailsSideBar,
  profileSideBarData,
  fetchProfileData,
  showTotalEngagementsHandler,
  setOpenDetailsSideBarReplay,
  setOpenSlaSideBar,
  handleToggleSlaSideBar,
  setOpenSidebar,
  setTranslationActive,
  translationSource,
  setTranslationSource,
  translationTarget,
  setTranslationTarget,
  languagesArraySource,
  languagesArrayTarget,
  translationActive,
  isLogsFetched,
  selectedCardIteractionID,
  openSidebarToAssignTeam,
  setOpenSidebarToAssignTeam,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [copied, setCopied] = useState(false);
  const [userId, setUserId] = useState(
    selectedCard?.system_replies?.engagement_id
      ? selectedCard?.system_replies?.engagement_id
      : "",
  );
  let statusItemCard = itemData
    ? itemData?.system_replies?.status
    : itemData?.status;
  // const [complete, setComplete] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [completionReason, setCompletionReason] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [completionContent, setCompletionContent] = useState("");
  const [anchorElTranslation, setAnchorElTranslation] = useState(null);
  const [disabledAction, setDisabledAction] = useState(false);
  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  let activeCdp = JSON.parse(window.localStorage?.activeProducts).filter(
    (product) => product?.active && product?.name == "CDP",
  );
  const getSocialIcon = (type) => {
    const SocialIcons = {
      facebook: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="facebook" icon={faFacebook} />
        </Box>
      ),
      twitter: (
        <Box className={type + "-icon user-source"}>
          <img src={xPlatform} alt="x-platform-logo" className="twitter" />
        </Box>
      ),
      instagram: (
        <Box className={type + "-icon user-source"}>
          <FontAwesomeIcon className="instagram" icon={faInstagram} />
        </Box>
      ),
    };
    return SocialIcons[type];
  };

  const updateStatus = (status) => {
    if (
      selectedCard?.isDm &&
      statusItemCard === "complete" &&
      (!selectedCard?.is_last_conversation ||
        (selectedCard?.data_source !== "twitter" &&
          selectedCard?.exceed_fb_ig_window))
    ) {
      setStats({
        ...stats,
        open: true,
        title: CheckValueLocale("reopen_failed", "", {}, intl),
        body: CheckValueLocale(
          !selectedCard?.is_last_conversation ? "other_active" : "time_exceed",
          "",
          {},
          intl,
        ),
        severity: "error",
      });
      setTimeout(() => {
        setStats({
          ...stats,
          open: false,
        });
      }, 7000);
    } else {
      setDisabledAction(true);
      let queryData = {};
      const slaInfo = {
        sla_id: selectedCard?.sla_info?.sla_id,
        routing_id: selectedCard?.sla_info?.routing_id,
      };
      if (statusItemCard === "complete") {
        queryData = {
          parent_id: selectedCard?.tweet_id || selectedCard?.id,
          product_id: window.localStorage.getItem("engagementsProductId"),
          monitor_id: selectedCard?.tracker_id,
          data_source_name: selectedCard?.data_source?.toUpperCase(),
          interaction_id: selectedCardIteractionID,
          status: "reopen",
          conversation_staus: itemData?.isDm ? itemData?.status : undefined,
          conversation_created_at: itemData?.isDm
            ? itemData?.created_at
            : undefined,
          client_id: itemData?.isDm ? itemData?.client_id : undefined,
          agent_id: itemData?.isDm ? itemData?.agent_id : undefined,
          ...slaInfo,
        };
      } else {
        queryData = {
          parent_id: selectedCard?.tweet_id || selectedCard?.id,
          product_id: window.localStorage.getItem("engagementsProductId"),
          monitor_id: selectedCard?.tracker_id,
          data_source_name: selectedCard?.data_source?.toUpperCase(),
          interaction_id: selectedCardIteractionID,
          status: status === "complete" ? "reopen" : "complete",
          completion_reason: completionReason,
          other_reason: valueInput,
          conversation_staus: itemData?.isDm ? itemData?.status : undefined,
          conversation_created_at: itemData?.isDm
            ? itemData?.created_at
            : undefined,
          client_id: itemData?.isDm ? itemData?.client_id : undefined,
          agent_id: itemData?.isDm ? itemData?.agent_id : undefined,
          ...slaInfo,
        };
      }

      EngagementsController.updateStatus(queryData).then((data) => {
        // setStatusChanged(!statusChanged);
        // setComplete(0);
        if (data?.data?.status === 204 || data?.data?.status === 200) {
          setCardState({
            ...cardState,
            changeStateTo: queryData?.status,
            changeStateDone: true,
          });
          if (statusItemCard === "complete") {
            // setTabValue(2);
            // setComplete("active");
            //setRefresh?.();
          } else {
            // setTabValue(3);
            // setComplete("complete");
            //setRefresh?.();
            setStats({
              ...stats,
              open: true,
              title: "",
              body: CheckValueLocale("engagement_marked", "", {}, intl),
              severity: "success",
            });
            setTimeout(() => {
              setStats({
                ...stats,
                open: false,
              });
            }, 7000);
          }
        } else {
          if (
            data?.errorMsg?.response?.data?.error?.detail ==
            "INTERACTION_ALREADY_COMPLETED"
          ) {
            setStats({
              ...stats,
              open: true,
              title: "",
              body: CheckValueLocale("interaction_is_completed", "", {}, intl),
              severity: "error",
            });
          } else {
            setStats({
              ...stats,
              open: true,
              title: CheckValueLocale(
                "request_access_error_heading",
                "",
                {},
                intl,
              ),
              body: CheckValueLocale(
                "request_access_error_description",
                "",
                {},
                intl,
              ),
              severity: "error",
            });
          }
          setTimeout(() => {
            setStats({
              ...stats,
              open: false,
            });
          }, 7000);
        }
        setDisabledAction(false);
        handelClosePopup();
      });
    }
  };
  const handelClosePopup = () => {
    setValueInput("");
    setOpenPopup(false);
  };
  const handleUpdate = () => {
    statusItemCard === "complete" ? updateStatus() : setOpenPopup(true);
  };
  const CompletionReasonsApi = () => {
    EngagementsController.getCompletionReasons(
      window.localStorage.getItem("engagementsProductId"),
    ).then((result) => {
      setCompletionContent(result?.data?.completion_reason_tooltips);
    });
  };
  useEffect(() => {
    CompletionReasonsApi();
  }, []);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(userId);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  let menuDirection = intl.locale === "en" ? "left" : "right";

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenProfile = () => {
    setOpenSlaSideBar(false);
    setOpenDetailsSideBarReplay(false);
    setOpenSidebar(false);
    setOpenDetailsSideBar({
      open: true,
      type: "profile",
    });
  };

  // translation Dropdown HANDLER
  const handleClickTranslation = (event) => {
    setAnchorElTranslation(event.currentTarget);
  };
  const handleCloseTranslation = () => {
    setAnchorElTranslation(null);
  };
  const openTranslation = Boolean(anchorElTranslation);
  const isTwitter = selectedCard?.data_source?.toLowerCase?.() === "twitter";
  const isInstagram =
    selectedCard?.data_source?.toLowerCase?.() === "instagram";
  const isDM = selectedCard?.isDm;
  const isRTL = intl?.locale === "ar";
  let { displayName, profilePicture, subName } =
    extractDisplayInfoFromDataSource(selectedCard, {
      displayName: selectedCard?.user_name
        ? isDM && (isTwitter || isInstagram)
          ? selectedCard?.name
          : selectedCard?.user_name
        : "user",
      subName:
        isTwitter || isInstagram
          ? isDM
            ? selectedCard?.user_name
            : selectedCard?.screen_name
          : null,
      profilePicture: selectedCard?.user_profile_image_url,
    });

  useEffect(() => {
    if (isLogsFetched && openSidebarToAssignTeam) {
      setOpenSidebarToAssignTeam(false);
      handleToggleSlaSideBar();
    }
  }, [isLogsFetched]);

  return (
    <>
      <Box className="main-engagment-header-parant">
        <Box className="main-engagment-header">
          <Box className="header-left-side">
            <Box className="engagment-header-user-box">
              <Box
                id="engagement-user-profile"
                className="engagment-header-user"
                onClick={handleOpenProfile}
              >
                <Box className="engagment-header-img">
                  <img className="user-avatar" src={profilePicture} />
                  {getSocialIcon(selectedCard?.data_source)}
                </Box>
                <Box className="engagment-header-user-info">
                  <span className="user-name">{displayName}</span>
                  {(isTwitter || (isInstagram && isDM)) && subName ? (
                    <span className="user-sub-name">
                      {isRTL ? `${subName}@` : `@${subName}`}
                    </span>
                  ) : null}
                </Box>
              </Box>
              <div className="engagment-header-profile">
                <ProfileUser
                  fetchProfileData={fetchProfileData}
                  profileSideBarData={profileSideBarData}
                  showTotalEngagementsHandler={showTotalEngagementsHandler}
                  selectedCard={selectedCard}
                />
              </div>
            </Box>
          </Box>
          <Box className="header-right-side">
            {/* {may be used later} */}
            {/* <spna className="monitor-name">{headerData?.monitor_name}</spna>
            <span className="vertical-divider"></span> */}
            <Box className="engagement-header-translation">
              <Tooltip
                title={CheckValueLocale("translation_settings", "", {}, intl)}
                placement="bottom"
                arrow
              >
                <Box
                  id="engagement-header-translation-btn"
                  className="translation-box"
                  onClick={handleClickTranslation}
                >
                  <FontAwesomeIcon
                    icon={faLanguage}
                    className={translationActive ? "active-transelation" : ""}
                  />
                </Box>
              </Tooltip>
              <Popover
                anchorOrigin={anchorPosition}
                transformOrigin={transformPosition}
                onClose={handleCloseTranslation}
                open={openTranslation}
                anchorEl={anchorElTranslation}
              >
                <EngagementTranslation
                  translationType="header"
                  setTranslationActive={setTranslationActive}
                  translationSource={translationSource}
                  setTranslationSource={setTranslationSource}
                  translationTarget={translationTarget}
                  setTranslationTarget={setTranslationTarget}
                  handleCloseTranslation={handleCloseTranslation}
                  stats={stats}
                  setStats={setStats}
                  languagesArraySource={languagesArraySource}
                  languagesArrayTarget={languagesArrayTarget}
                  showActiveTranselationIcon={translationActive}
                />
              </Popover>
            </Box>
            <span className="vertical-divider"></span>
            <Box>
              <LucButton
                onClick={handleUpdate}
                variant="outline"
                size="small"
                id={
                  statusItemCard === "complete"
                    ? "engagement-reopen-btn"
                    : "engagement-mark-complete-btn"
                }
                className={
                  statusItemCard === "complete" ? "reopen-btn" : "complete-btn"
                }
                startIcon={
                  <FontAwesomeIcon
                    icon={
                      statusItemCard === "complete"
                        ? faFolderOpen
                        : faCircleCheck
                    }
                  />
                }
                disabled={disabledAction}
              >
                {statusItemCard === "complete"
                  ? CheckValueLocale("re_open", "", {}, intl)
                  : CheckValueLocale("mark_complete", "", {}, intl)}
              </LucButton>
            </Box>
            <Tooltip
              title={CheckValueLocale("engag_det", "", {}, intl)}
              placement="bottom"
              arrow
            >
              <Box
                className={`sla-det-tooltip ${!isLogsFetched && "sla-details-engagement-fetched"}`}
                onClick={() => isLogsFetched && handleToggleSlaSideBar()}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {openPopup ? (
        <EngagementComplete
          updateStatus={updateStatus}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          completionReason={completionReason}
          setCompletionReason={setCompletionReason}
          valueInput={valueInput}
          setValueInput={setValueInput}
          completionContent={completionContent}
          setCompletionContent={setCompletionContent}
          handelClosePopup={handelClosePopup}
          disabledAction={disabledAction}
        />
      ) : null}
    </>
  );
};

export default EngagementHeader;
