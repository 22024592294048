import Services from "services/api/Services";
import NotificationsController from "services/controllers/notificationsController";
import UserAccount from "services/controllers/userAccountController";

// Download notification [ XLSX TYPE ]
const handelxlsxDownload = (id, history, setOpenSnackBar) => {
  NotificationsController.getNotificationsXlsxDownload(id).then((data) => {
    let result = data?.data?.data;
    if (result && result[0]?.file_url) {
      if (
        result &&
        result[0]?.encryption == true &&
        result[0]?.notification_type === "excel_export"
      ) {
        let contentType = "application/vnd.ms-excel";
        let blob1 = b64toBlob(result[0]?.file_url, contentType);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(blob1);
        a.download = result[0]?.file_name + ".xlsx";
        a.click();
        history.push(`/notification`);
      }
    } else {
      setOpenSnackBar(true);
    }
  });
};

const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

// Download Function
const handelDownload = (id, history, setOpenSnackBar) => {
  NotificationsController.getNotificationsDownloadLink(id).then((data) => {
    if (data?.data?.file_url) {
      window.open(data?.data.file_url, "_self");
      history.push(`/notification`);
    } else {
      setOpenSnackBar(true);
    }
  });
};

// View Report Redirection Function
const getSmData = async (page) => {
  let baseUrl = Services.switchUrl;
  await UserAccount.getSmData().then((result) => {
    if (result.data !== undefined) {
      localStorage.setItem("user_token", result?.data?.data?.jwt);
      window.open(
        `${baseUrl}${page}?lang=${localStorage?.lang}&sm_token=${result?.data?.data?.jwt}`,
        "_blank",
      );
    }
  });
};

// Notification View Link [ when click on View button it return to view page [ reports / single tweet ] ]
const handelViewLink = (item, history) => {
  const checkIfLucidyaCustomDomain = item?.custom_domain
    ? `https://${item?.custom_domain}/`
    : "https://survey.lucidya.com/";
  if (item?.encryption == true) {
    let contentType = "application/vnd.ms-excel";
    let blob1 = b64toBlob(item?.file_url, contentType);
    var a = document.createElement("a"); //Create
    a.href = URL.createObjectURL(blob1);
    a.download = item?.file_name + ".xlsx"; //File name Here
    a.click();
  } else if (item?.notification_type === "single_tweet_analysis") {
    return history.push(
      `/single_post_analysis/${item?.monitor}/${item?.tweet_id}/${item?.screen_name}`,
    );
  } else if (item?.notification_type === "nb_report") {
    return getSmData(
      `#/newslettersreport_details/${item?.report_id}?report_data_id=${item?.report_data_id}`,
    );
  } else if (item?.notification_type === "excel_export") {
    return window.open(item.file_url);
  } else if (item?.notification_type === "published_survey") {
    return (window.location.href = `${checkIfLucidyaCustomDomain}${item?.url_slug}`);
  } else {
    if (item?.product_name?.toLowerCase() == "engagements") {
      return history.push(`/engagements/main/?alert_id=${item?.alert_data_id}`);
    } else {
      return history.push(
        item?.notification_type === "alert"
          ? "/404"
          : item?.notification_type === "alert_v2" &&
              `/alerts/${
                item?.alert_type_name
              }/${item?.data_source_name?.toUpperCase()}/${item?.alert_data_id}`,
      );
    }
  }
};

export { b64toBlob, handelViewLink, handelDownload, handelxlsxDownload };
