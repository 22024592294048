import moment from "moment";
import { isEmptyArray, secondsToHms } from "utils/helpers";
import { handleXlsData, handleSortedCase } from "../excelCommonFn";

const analytics = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var overview = [],
      summedInteractions = [],
      theme = [],
      subTheme = [],
      themesTrend = [],
      subThemesTrend = [],
      dialectsData = [],
      subDialectsData = [],
      categories = [],
      channels = [],
      genders = [],
      topCountries = [],
      topCities = [],
      latestInteractions = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        let preLoaderTrackerDataSources = i?.preLoaderTrackerDataSources;
        switch (key) {
          case "overview":
            value?.map((i) => {
              overview.push({
                [lang.xlsx_name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.xlsx_value]:
                  i?.name == "net_sentiment"
                    ? lang[i?.value[0]?.name]
                    : i?.name == "response_rate"
                      ? `${(
                          i?.value / preLoaderTrackerDataSources?.length
                        ).toFixed(2)} %`
                      : i?.name == "average_waiting_time"
                        ? secondsToHms(i?.value)?.length
                          ? `${secondsToHms(i?.value)[0]} ${lang["hour"]}  ${
                              secondsToHms(i?.value)[1]
                            } ${lang["minutes"]}  ${secondsToHms(i?.value)[2]} ${
                              lang["seconds"]
                            }`
                          : `0 ${lang["hour"]} 0 ${lang["minutes"]} 0 ${lang["seconds"]}`
                        : i?.value,
              });
            });
            allSheetData.push([overview, lang.xlxs_overview]);
            break;
          // // _______________________________________________________________________________
          case "summedInteractions":
            value?.map((i) => {
              summedInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.interactions]: i?.value,
              });
            });
            allSheetData.push([summedInteractions, lang.interactions]);
            break;
          // // _______________________________________________________________________________
          case "themeData":
            //themes
            let mainThemes = value?.theme_analysis?.main_themes_view;
            mainThemes &&
              Object.entries(mainThemes).forEach(([key1, value1]) => {
                let subThemes = value1?.sub_themes;
                subThemes &&
                  Object.entries(subThemes).forEach(([key2, value2]) => {
                    theme.push({
                      [lang.themes]: `${lang[key1]} (${lang[key2]})`,
                      [lang.total_interactions]: value2,
                    });
                  });
              });
            allSheetData.push([theme, lang.themes]);

            //sub-themes
            let mainSubThemes = value?.theme_analysis?.sub_themes_view;
            mainSubThemes &&
              Object.entries(mainSubThemes).forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes;
                mainThemes &&
                  Object.entries(mainThemes).forEach(([key2, value2]) => {
                    subTheme.push({
                      [lang.sub_themes]: `${lang[key1]} (${lang[key2]})`,
                      [lang.total_interactions]: value2,
                    });
                  });
              });
            allSheetData.push([subTheme, lang.sub_themes]);

            //themes-trend
            let mainthemesTrend = value?.theme_trend;
            mainthemesTrend &&
              Object.entries(mainthemesTrend).forEach(([key1, value1]) => {
                let mainThemes = value1?.main_themes_view;
                let rawObj = {};
                mainThemes &&
                  Object.entries(mainThemes).forEach(([key2, value2]) => {
                    let subThemes = value2?.sub_themes;
                    subThemes &&
                      Object.entries(subThemes).forEach(([key3, value3]) => {
                        rawObj = {
                          ...rawObj,
                          [`${lang[key2]} (${lang[key3]})`]: value3,
                        };
                      });
                  });
                themesTrend.push({
                  [lang.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData.push([themesTrend, lang.themes_trend]);

            //sub-themes-trend
            mainthemesTrend &&
              Object.entries(mainthemesTrend).forEach(([key1, value1]) => {
                let subThemesTrendObj = value1?.sub_themes_view;
                let rawObj = {};
                subThemesTrendObj &&
                  Object.entries(subThemesTrendObj).forEach(
                    ([key2, value2]) => {
                      let mainThemes = value2?.main_themes;
                      mainThemes &&
                        Object.entries(mainThemes).forEach(([key3, value3]) => {
                          rawObj = {
                            ...rawObj,
                            [`${lang[key2]} (${lang[key3]})`]: value3,
                          };
                        });
                    },
                  );
                subThemesTrend.push({
                  [lang.date]: moment.unix(key1).utc().format("YYYY-MM-DD"),
                  ...rawObj,
                });
              });
            allSheetData.push([subThemesTrend, lang.sub_themes_trend]);
            break;
          // _______________________________________________________________________________
          case "dialectsXlxs":
            handleSortedCase(
              value,
              dialectsData,
              lang.dialect,
              lang.main_dialect_filter,
              lang,
            );
            allSheetData.push([dialectsData, lang.main_dialect_filter]);
            break;
          // //_______________________________________________________________________________
          case "subDialectsXlsx":
            handleSortedCase(
              value,
              subDialectsData,
              lang.sub_dialects,
              lang.number,
              lang,
            );
            allSheetData.push([subDialectsData, lang.sub_dialects]);
            break;
          // //_______________________________________________________________________________
          case "categoriesXlsx":
            value?.map((i) => {
              categories.push({
                [lang.xlsx_name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.xlsx_value]: i?.value,
              });
            });
            allSheetData.push([categories, lang.categories]);
            break;
          // //_______________________________________________________________________________
          case "channelsXlxs":
            value?.map((i) => {
              channels.push({
                [lang.xlsx_name]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.xlsx_value]: i?.value,
              });
            });
            allSheetData.push([channels, lang.channels]);
            break;
          //_______________________________________________________________________________
          case "gendersXlsx":
            value?.map((i) => {
              genders.push({
                [lang.gender]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([genders, lang.genders_distribution]);
            break;
          //_______________________________________________________________________________
          case "countriesXlsx":
            value?.map((i) => {
              topCountries.push({
                [lang.countries]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([topCountries, lang.top_countries]);
            break;
          //_______________________________________________________________________________
          case "citiesXlsx":
            value?.map((i) => {
              topCities.push({
                [lang.cities]: lang[i?.name] ? lang[i?.name] : i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([topCities, lang.top_cities]);
            break;
          // _______________________________________________________________________________
          case "latestInteractions":
            value?.map((i) => {
              if (i?.channel_type != "private" && (i?.tweet_link || i?.url)) {
                latestInteractions.push({
                  [lang.latest_interactions]: i?.tweet_link
                    ? i?.tweet_link
                    : i?.url,
                });
              }
            });
            allSheetData.push([latestInteractions, lang.latest_interactions]);

            break;
          default:
            break;
        }
      });
    });

    return handleXlsData(allSheetData);
  }
};
export { analytics };
