import React, { Component, useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import SentimentRepliesChart from "./widgets/SentimentRepliesChart";
import moment from "moment";
import DaysSlider from "./widgets/daysSlider";
import { Select, MenuItem, Box } from "@mui/material";
import "./charts-style.scss";
import ai from "images/ai.svg";
import { CheckValueLocale } from "utils/helpers";

const SentimentOfReplies = (props) => {
  const [stats, setStats] = useState({
    timeGroups: {
      month: "Group_by_Month",
      week: "Group_by_Week",
      day: "Group_by_Day",
      hour: "Group_by_Hour",
    },
    activeTimeGroup: "day",
    showDays: false,

    sentimentData: {},
    xAxisData: [],
    activeDay: moment(props.analysis_created_at).format("YYYY-MM-DD"),
  });
  const [days, setDays] = useState([]);

  useEffect(() => {
    formatSentimentData();
  }, [stats.activeTimeGroup]);

  const changeTimeGroup = (type) => {
    setStats(
      {
        ...stats,
        activeTimeGroup: type,
      },
      () => {
        formatSentimentData();
      },
    );
  };

  const formatAxisData = () => {
    let { tweet_datetime, analysis_created_at } = props;

    const endDate = moment(analysis_created_at).format("YYYY-MM-DD");
    tweet_datetime = moment(tweet_datetime).format("YYYY-MM-DD");

    let startDate = "";
    let dateArr = [];

    switch (stats.activeTimeGroup) {
      case "month":
        startDate = tweet_datetime;

        while (moment(startDate).isSameOrBefore(endDate)) {
          dateArr.push(moment(startDate).format("YYYY-M"));
          startDate = moment(startDate).add(30, "days").format("YYYY-MM-DD");
        }

        break;
      case "week":
        startDate = tweet_datetime;

        while (moment(startDate).isSameOrBefore(endDate)) {
          let beginningOfWeek =
            moment(startDate).year() +
            "-" +
            moment(startDate).week(startDate).startOf("week").format("MM-DD");
          let endOfWeek =
            moment(startDate).year() +
            "-" +
            moment(startDate)
              .week(startDate)
              .startOf("week")
              .add(6, "days")
              .format("MM-DD");
          let dateKey = beginningOfWeek + "~" + endOfWeek;

          dateArr.push(dateKey);
          startDate = moment(beginningOfWeek)
            .add(7, "days")
            .format("YYYY-MM-DD");
        }

        break;
      case "hour":
        dateArr = [
          "12 AM",
          "1 AM",
          "2 AM",
          "3 AM",
          "4 AM",
          "5 AM",
          "6 AM",
          "7 AM",
          "8 AM",
          "9 AM",
          "10 AM",
          "11 AM",
          "12 PM",
          "1 PM",
          "2 PM",
          "3 PM",
          "4 PM",
          "5 PM",
          "6 PM",
          "7 PM",
          "8 PM",
          "9 PM",
          "10 PM",
          "11 PM",
        ];

        break;
      default:
        startDate = tweet_datetime;

        while (moment(startDate).isSameOrBefore(endDate)) {
          dateArr.push(moment(startDate).format("YYYY-MM-DD"));
          startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
        }
        break;
    }
    return dateArr;
  };

  const formatSentimentData = () => {
    const { top_replies } = props;
    let sentimentData = [];
    setStats({
      ...stats,
      showDays: false,
    });
    switch (stats.activeTimeGroup) {
      case "hour":
        sentimentData = getSummaryData(top_replies, "hourly");
        setStats({ ...stats, showDays: true });
        if (days.length === 0) {
          handleNavigateDays(stats.activeDay, "prev");
        }
        break;
      case "day":
        sentimentData = getSummaryData(top_replies, "daily");
        break;
      case "week":
        sentimentData = getSummaryData(top_replies, "weekly");
        break;
      case "month":
        sentimentData = getSummaryData(top_replies, "monthly");
        break;
      default:
        sentimentData = getSummaryData(top_replies, "daily");
        break;
    }

    const xAxisData = formatAxisData();
    if (stats.activeTimeGroup == "hour") {
      setStats({
        ...stats,
        sentimentData: sentimentData,
        xAxisData: xAxisData,
        showDays: true,
      });
    } else {
      setStats({
        ...stats,
        sentimentData: sentimentData,
        xAxisData: xAxisData,
        showDays: false,
      });
    }
  };

  const handleSelectDay = (day) => {
    setStats({ ...stats, activeDay: day }, () => {
      formatSentimentData();
    });
  };

  const handleNavigateDays = (date, direction) => {
    let startDate = "";
    let endDate = "";

    if (direction === "next") {
      startDate = moment(date).format("YYYY-MM-DD");
      endDate = moment(date).add(10, "days").format("YYYY-MM-DD");
    } else {
      startDate = moment(date).subtract(10, "days").format("YYYY-MM-DD");
      endDate = moment(date).format("YYYY-MM-DD");
    }

    let daysList = [];

    while (moment(startDate).isBefore(endDate)) {
      daysList.push(moment(startDate).format("YYYY-MM-DD"));
      startDate = moment(startDate).add(1, "days").format("YYYY-MM-DD");
    }

    daysList.push(endDate);

    setDays(daysList);
  };

  const getSummaryData = (data, duration) => {
    let data2 = data;
    const { activeDay } = stats;
    let groups = data2.reduce(function (acc, data2) {
      let date_id = data2.created_at;
      let dateKey = moment(date_id).format("YYYY-MM-DD");

      const beginningOfWeek = moment(date_id)
        .week(date_id)
        .startOf("week")
        .format("MM-DD");

      const endOfWeek = moment(date_id)
        .week(date_id)
        .startOf("week")
        .add(6, "days")
        .format("MM-DD");

      if (duration === "weekly") {
        dateKey =
          moment(date_id).year() +
          "-" +
          beginningOfWeek +
          "~" +
          moment(date_id).year() +
          "-" +
          endOfWeek;
      } else if (duration === "monthly") {
        dateKey = moment(date_id).year() + "-" + (moment(date_id).month() + 1);
      } else if (duration === "hourly") {
        if (activeDay === moment(date_id).format("YYYY-MM-DD")) {
          dateKey = moment(date_id).format("h A");
        } else {
          dateKey = false;
        }
      }

      if (dateKey && data2.overallSentiment_label !== "None") {
        const sentimentType = data2.overallSentiment_label;

        if (!(dateKey in acc)) {
          acc[dateKey] = {};
        }
        acc[dateKey][sentimentType] = (acc[dateKey][sentimentType] || 0) + 1;
        acc[dateKey]["total"] = (acc[dateKey]["total"] || 0) + 1;
      }

      return acc;
    }, {});

    return groups;
  };

  useEffect(() => {
    formatSentimentData();
  }, [stats.activeTimeGroup]);

  useEffect(() => {
    formatSentimentData();
  }, [stats.activeDay]);

  const { intl, analysis_created_at, tweet_datetime } = props;

  const {
    sentimentData,
    xAxisData,
    timeGroups,
    activeTimeGroup,
    activeDay,
    showDays,
  } = stats;

  return (
    <div className="panel-card no-pad sentiment-panel">
      <div className="panel-card-heading">
        <p className="panel-card-title">
          <span>
            {CheckValueLocale("comments_sentiments_volume", "", {}, intl)}
          </span>
          <i
            className="fas fa-question informativeIcon"
            data-tip={CheckValueLocale(
              "help_sentiment_of_replies",
              "",
              {},
              intl,
            )}
            aria-hidden="true"
          ></i>
          <div className="ai_icon">
            <img src={ai} alt="AI" />
          </div>
        </p>
      </div>
      <div>
        <div className="panel-card-body">
          <div className="chart-data replies-chart">
            <div className="chart-nav-list">
              <Box component="div" className="groupby_wrapper">
                <Box component="span" className="groupby_title">
                  {CheckValueLocale("group_by", "", {}, intl)}:
                </Box>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={activeTimeGroup}
                  onChange={(e) => changeTimeGroup(e.target.value)}
                  className="select"
                >
                  <MenuItem
                    value="day"
                    id="single-tweets-sentiment-of-reply-day"
                  >
                    {" "}
                    {CheckValueLocale("day", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="week"
                    id="single-tweets-sentiment-of-reply-week"
                  >
                    {CheckValueLocale("week", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="month"
                    id="single-tweets-sentiment-of-reply-month"
                  >
                    {CheckValueLocale("month", "", {}, intl)}
                  </MenuItem>
                  <MenuItem
                    value="hour"
                    id="single-tweets-sentiment-of-reply-hour-group"
                  >
                    {CheckValueLocale("hour_group", "", {}, intl)}
                  </MenuItem>
                </Select>
              </Box>
            </div>

            <SentimentRepliesChart
              sentimentData={sentimentData}
              activeTimeGroup={activeTimeGroup}
              activeDay={activeDay}
              xAxisData={xAxisData}
              // filterReplies={filterReplies}
              intl={intl}
            />

            {showDays ? (
              <DaysSlider
                days={days}
                activeDay={activeDay}
                selectDay={handleSelectDay}
                navigateDays={handleNavigateDays}
                analysis_created_at={analysis_created_at}
                tweet_datetime={tweet_datetime}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SentimentOfReplies;
