import { useEffect, useState } from "react";
import AudienceController from "services/controllers/audienceController";
import PopupModal from "components/popupModal";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import "./newSegmentPopup.scss";
import PropTypes from "prop-types";
import { CheckValueLocale } from "../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import CommonFunctions from "pages/Monitors/Components/commonFunctions";

const NewSegmentPopup = (props) => {
  const {
    create,
    usersIds,
    open,
    close,
    segmentData,
    getListOfSegments,
    segmentList,
    filteredAudienceData,
    startDate,
    endDate,
    identifier,
    filters,
    allSegments,
    count,
  } = props;
  const intl = useIntl();
  const { changeFilterParamsToString } = CommonFunctions();

  const [disable, setDisable] = useState(true);
  const [checked, setChecked] = useState(true);
  const [segmentName, setSegmentName] = useState("");
  const [err, setErr] = useState("");
  const usersArr = usersIds ? usersIds : [];

  useEffect(() => {
    if (usersArr.length == 0) {
      setChecked(false);
    }
    if (segmentData?.id !== undefined) {
      setSegmentName(segmentData?.name);
    }
  }, []);

  const createSegment = (name, users) => {
    AudienceController.createSegment(
      name,
      users,
      allSegments,
      startDate,
      endDate,
      identifier,
      filters && changeFilterParamsToString(filters, true),
    ).then((result) => {
      let addedUserNum;
      if (create) {
        if (result.status == 200) {
          if (users) {
            if (allSegments === true) {
              addedUserNum = count - result?.data?.num;
            } else {
              addedUserNum = users.length - result?.data?.num;
            }
            create(
              "success",
              "create_new_segment_with_users_success_msg",
              "",
              addedUserNum?.toString(),
            );
          } else {
            getListOfSegments?.();
            create("success", "create_new_segment_success_msg");
          }
        } else {
          create("error", "try_again_error_message", "failed_error_message");
        }
      }
    });
  };

  const EditSegment = (segmentId, name) => {
    AudienceController.updateSegmentName(segmentId, name).then((result) => {
      if (create) {
        if (result.status === 200) {
          create("success", "rename_segment_success_msg");
          getListOfSegments?.();
        } else {
          create("error", "try_again_error_message", "failed_error_message");
        }
      }
    });
  };

  const handleValditon = (e) => {
    setErr("");
    setDisable(true);

    let duplicate = [];
    const txt = e.target.value;
    const regex = /^[\u0621-\u064A0-9a-zA-Z0-9-_.]+$/;
    const result = regex.test(txt);
    duplicate = segmentList?.filter(
      (item) => item.name?.toLowerCase() === txt?.toLowerCase(),
    );
    setSegmentName(txt);

    if (txt.length > 30) {
      setErr("audiance_max_char");
    } else if (!result && txt.length !== 0) {
      setErr("audiance_not_allowed_chars");
    } else if (duplicate.length > 0) {
      setErr("audiance_duplicate");
    } else if (txt.length > 0) {
      setDisable(false);
    }
  };

  const handleCreate = () => {
    if (usersArr.length === 0 || !checked) {
      segmentData?.id !== undefined
        ? EditSegment(segmentData?.id, segmentName)
        : createSegment(segmentName);
    } else {
      createSegment(segmentName, usersArr);
    }
    close();
  };

  let body = (
    <Box className="segment-pop">
      <Typography className="body-input-label show-red-icon">
        {segmentData?.id !== undefined
          ? CheckValueLocale("segment_enter_the_new_name", "", {}, intl)
          : CheckValueLocale("new_segment_name", "", {}, intl)}
      </Typography>

      <Box className="segment-pop-input">
        <TextField
          className="segment-input"
          onChange={handleValditon}
          fullWidth
          error={err.length > 0}
          id="outlined-error-helper-text"
          placeholder={CheckValueLocale("segment_name_label", "", {}, intl)}
          defaultValue={segmentName}
          helperText={CheckValueLocale(err, "", {}, intl)}
        />
      </Box>
      {usersArr.length > 0 && (
        <Box className="segment-pop-checkbox">
          <FormControlLabel
            control={
              <Checkbox defaultChecked onChange={() => setChecked(!checked)} />
            }
            label={CheckValueLocale(
              "new_segment_selected_profiles",
              "",
              {},
              intl,
            )}
          />
        </Box>
      )}
    </Box>
  );
  return (
    <PopupModal
      title={
        segmentData?.id !== undefined
          ? CheckValueLocale("rename_segment", "", {}, intl)
          : CheckValueLocale("create_new_segment", "", {}, intl)
      }
      body={body}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={
        segmentData?.id !== undefined
          ? CheckValueLocale("save", "", {}, intl)
          : CheckValueLocale("create", "", {}, intl)
      }
      disabled={disable}
      open={open}
      close={close}
      monitor_id={segmentName}
      accept={handleCreate}
      minWidth="600px"
      classeName="actions-buttons-shared"
    />
  );
};

NewSegmentPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.bool.isRequired,
};

export default NewSegmentPopup;
