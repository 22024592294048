import React from "react";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import noDataImg from "images/noDataFound/noDataCase.svg";
import createdAMonitor from "images/noDataFound/emptyCase.svg";
import monitorListEmpty from "images/noDataFound/monitor-list-empty.svg";
import TopHashtagsImg from "images/noDataFound/TopHashtagsImg.svg";
import Dashboard from "images/noDataFound/Dashboard.svg";
import Monitor from "images/noDataFound/Monitor.svg";
import MonitorCollecting from "images/noDataFound/MonitorCollecting.svg";
import insights from "images/noDataFound/insights.svg";
import calls from "images/noDataFound/Calls.svg";
import email from "images/noDataFound/Email.svg";
import chats from "images/noDataFound/chats.svg";
import ratings from "images/noDataFound/Ratings.svg";
import socialMedia from "images/noDataFound/SocialMedia.svg";
import Interctions from "images/noDataFound/Interctions.svg";
import ManageChannels from "images/noDataFound/ManageChannels.svg";
import engagement from "images/noDataFound/engagementAll.svg";
import cdp from "images/noDataFound/CPD.svg";
import EmptySurvey from "images/noDataFound/EmptySurvey.svg";
import survey from "images/noDataFound/Survey.svg";
import alert from "images/noDataFound/EmptyAlert.svg";
import reports from "images/noDataFound/EmptyReports.svg";
import SLBenchmark from "images/noDataFound/EmptySLBenchmark.svg";
import UManagement from "images/noDataFound/UManagement.svg";
import companyInfo from "images/noDataFound/companyInfo.svg";
import emptyHR from "images/noDataFound/emptyHR.svg";
import inboundRouting from "images/noDataFound/inboundRouting.svg";
import instagramHashtags from "images/noDataFound/instagramHashtags.svg";
import teams from "images/noDataFound/teams.svg";
import lucidyaAPI from "images/noDataFound/lucidyaAPI.svg";
import topics from "images/noDataFound/topics.svg";
import NoDataLinks from "../noDataLinks";
import userActivity from "images/noDataFound/userActivity.svg";
import customDashboard from "images/noDataFound/empty-dashboard-view.svg";

const EmptyDataShared = (props) => {
  const intl = useIntl();
  const {
    title,
    subTitle,
    showApplyFilterMsg,
    image,
    description,
    linkOne,
    linkOneHref,
    orCase,
    linkTwo,
    needsAttentionUserName,
  } = props;

  const handleDataIcon = (iconName) => {
    let iconByName = {
      noDataImg: noDataImg,
      createdAMonitor: createdAMonitor,
      monitorListEmpty: monitorListEmpty,
      TopHashtagsImg: TopHashtagsImg,
      Dashboard: Dashboard,
      Monitor: Monitor,
      MonitorCollecting: MonitorCollecting,
      insights: insights,
      calls: calls,
      email: email,
      chats: chats,
      ratings: ratings,
      socialMedia: socialMedia,
      Interctions: Interctions,
      ManageChannels: ManageChannels,
      engagement: engagement,
      cdp: cdp,
      EmptySurvey: EmptySurvey,
      survey: survey,
      alert: alert,
      reports: reports,
      emptyBenchmark: SLBenchmark,
      lucidyaAPI: lucidyaAPI,
      teams: teams,
      instagramHashtags: instagramHashtags,
      inboundRouting: inboundRouting,
      emptyHR: emptyHR,
      companyInfo: companyInfo,
      UManagement: UManagement,
      topics: topics,
      userActivity: userActivity,
      customDashboard: customDashboard,
    };
    return iconByName[iconName];
  };
  return (
    <Box className="empty-data-search">
      <img src={handleDataIcon(image)} alt="No Data Available Image" />

      <Box className="no-data-date">
        <Typography component={"h3"} className="no-data-found">
          {CheckValueLocale(title, "", {}, intl)}
        </Typography>
        {showApplyFilterMsg !== false && subTitle ? (
          <Typography component={"span"} className="try-adjust-date">
            {CheckValueLocale(subTitle, "", {}, intl)}
          </Typography>
        ) : null}
        <Box className="empty-data-box">
          {CheckValueLocale(
            description,
            "",
            { br: <br />, search_name: needsAttentionUserName },
            intl,
          )}
          {linkOne ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={linkOneHref ? linkOneHref : ""}
            >
              {CheckValueLocale(linkOne, "", {}, intl)}
            </a>
          ) : null}
          {CheckValueLocale(orCase, "", {}, intl)}
          {linkTwo ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={NoDataLinks.kwowledge_link}
            >
              {CheckValueLocale(linkTwo, "", {}, intl)}.
            </a>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyDataShared;
