import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Typography,
  Tooltip,
  Box,
  InputAdornment,
  Button,
  OutlinedInput,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import "../keywords.scss";
import KeywordTooltipChip from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import CopyIcon from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import SocialMediaAccountButton from "../../../Components/SocialMediaAuthButton/socialMediaAuthButton";
import {
  handleInputChange,
  checkPlaceHolderText,
  handleInputFieldName,
  checkAllInputsField,
  checkKeywordPopup,
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import {
  handleKeywordsPopUp,
  checkValue,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import { DropdownSection } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/monitorDropdowns/monitorDropdowns";

const TwitterKeyword = (props) => {
  const intl = useIntl();
  const {
    handleDelete,
    indexes,
    handleKeywords,
    keywords,
    handleValidation,
    handleData,
    twitterWordsCounter,
    twitterAccounts,
    collectTweets,
    ignoreTweets,
    answer,
    spamAnswer,
    setTwitterCountries,
    setTiwtterLanguages,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    twitterCountries,
    twitterLanguages,
    monitorTwitterOtherFieldLimit,
    monitorTwitterKeywordLimit,
  } = props;

  //// input Foucsed Case
  const [focused, setFocused] = useState(false);

  let keywordsInfo = [
    "keywords",
    "spam_keywords",
    "collect_tweets",
    "ignore_tweets",
  ];
  const valueArr = [answer, spamAnswer, collectTweets, ignoreTweets];

  const [err, setErr] = useState({
    errMsg: "",
    keyword: "",
  });

  const handleKeyDown = (event, keyword, index, clickType) => {
    if (
      keyword === "keywords" ||
      keyword === "spam_keywords" ||
      keyword === "collect_tweets" ||
      keyword === "ignore_tweets"
    ) {
      const limit =
        keyword === "keywords"
          ? monitorTwitterKeywordLimit
          : monitorTwitterOtherFieldLimit;
      handleKeywords(event, keyword, "twitter", index, clickType, limit);
    }
  };

  const applySelectionConditionFn = (optionsToBeAdded) => {
    return (
      optionsToBeAdded + twitterWordsCounter <= monitorTwitterOtherFieldLimit
    );
  };
  const onCoutriesChange = (newCoutries = []) => {
    setTwitterCountries(newCoutries);
  };
  const onLanguagesChange = (newLanguages = []) => {
    setTiwtterLanguages(newLanguages);
  };
  useEffect(() => {
    getTwitterLanguagesAndCountries();
  }, []);
  return (
    <Box className="data-source-keyword keywords-inputs-fields">
      {!twitterAccounts || props.isLoading ? (
        <CircularLoading />
      ) : (
        // : isEmptyArray(twitterAccounts) ? (
        //   <>
        //     <SocialMediaAccountButton
        //       authHead={"you_need_to_connect_twitter_account"}
        //       companyApps={props.companyApps}
        //       authDataSourceName={"add_twitter_account"}
        //       isTwitter
        //       isPublicAccount
        //       isCompanyAccount={false}
        //     />
        //   </>
        // )
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {keywordsInfo.map((keyword, index) => {
            const twitterValues = Object.values(
              keywords[indexes].twitter[index],
            );
            return (
              <>
                <Grid item xs={6}>
                  <Typography
                    variant="caption"
                    className="keywords keyword-head-title"
                  >
                    {keyword === "keywords" || keyword === "spam_keywords" ? (
                      <Tooltip
                        title={CheckValueLocale(
                          "keywords_spam_keywords_tooltip",
                          "",
                          {},
                          intl,
                        )}
                        arrow
                        placement="bottom"
                      >
                        <Typography
                          variant="caption"
                          className="media-input-label"
                        >
                          {CheckValueLocale(
                            handleInputFieldName(keyword),
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      </Tooltip>
                    ) : (
                      CheckValueLocale(
                        handleInputFieldName(keyword),
                        "",
                        {},
                        intl,
                      )
                    )}
                  </Typography>
                  <OutlinedInput
                    variant="outlined"
                    className={` keywords-field keyword-input-field ${checkDirectionLang(
                      keywords[indexes]?.twitter[index]?.answer,
                    )}`}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      // Delete Error message when leaving the Keyword box
                      if (
                        keywords[indexes]?.twitter[index]?.answer?.length === 0
                      ) {
                        keywords[indexes].twitter[index].errorMsg = "";
                      }
                      setFocused(false);
                    }}
                    endAdornment={
                      focused && (
                        <InputAdornment onMouseDown={handleInputMouseDown}>
                          <Button
                            className="add-btn-with-plus"
                            onClick={(event) =>
                              handleKeyDown(event, keyword, index, "btn")
                            }
                            value={
                              Object.keys(
                                keywords[indexes]?.twitter[index],
                              )[0] === keyword
                                ? keywords[indexes].twitter[index].answer
                                : ""
                            }
                            id="monitor-options-tw-add-keyword-btn"
                          >
                            {CheckValueLocale("add_plus", "", {}, intl)}
                          </Button>
                        </InputAdornment>
                      )
                    }
                    error={
                      keywords[indexes].twitter[index].errorMsg !== "" &&
                      Object.keys(keywords[indexes].twitter[index])[0] ===
                        keyword
                    }
                    placeholder={CheckValueLocale(
                      checkPlaceHolderText(keyword),
                      "",
                      {},
                      intl,
                    )}
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        keyword,
                        "twitter",
                        index,
                        indexes,
                        keywords,
                        setErr,
                        handleValidation,
                      )
                    }
                    value={
                      Object.keys(keywords[indexes]?.twitter[index])[0] ===
                      keyword
                        ? keywords[indexes].twitter[index].answer
                        : ""
                    }
                    onKeyDown={(event) => handleKeyDown(event, keyword, index)}
                  />
                  {keywords[indexes]?.twitter?.map((obj) => {
                    if (
                      Object.values(obj)[1] !== "" &&
                      Object.keys(obj)[0] === keyword
                    ) {
                      const limit =
                        keyword === "keywords"
                          ? monitorTwitterKeywordLimit
                          : monitorTwitterOtherFieldLimit;
                      return (
                        <Box className="err-msg">
                          {CheckValueLocale(
                            Object.values(obj)[1],
                            "",
                            { limit: limit },
                            intl,
                          )}
                        </Box>
                      );
                    }
                  })}
                  <Box className="keyword-spam-wrapper">
                    {keywords[indexes]?.twitter?.map((obj) => {
                      if (
                        Object.values(obj)[0].length > 0 &&
                        Object.keys(obj)[0] === keyword
                      ) {
                        return checkValue(Object.values(obj)[0]).map(
                          (labels, index) => (
                            <KeywordTooltipChip
                              onDelete={(e) =>
                                handleDelete(
                                  e,
                                  index,
                                  Object.keys(obj)[0],
                                  indexes,
                                  "twitter",
                                )
                              }
                              dataSource={"twitter"}
                              index={index}
                              indexes={indexes}
                              labels={labels}
                            />
                          ),
                        );
                      }
                    })}
                    {checkAllInputsField(keyword, keywordsInfo) && (
                      <KeywordPopupModal
                        keywords={handleKeywordsPopUp(
                          keyword,
                          keywords[0].twitter[0]?.keywords,
                          keywords[0].twitter[1]?.spam_keywords,
                          keywords[0].twitter[2]?.collect_tweets,
                          keywords[0].twitter[3]?.ignore_tweets,
                        )}
                        handleDelete={handleDelete}
                        dataSourceType={"twitter"}
                        keywordType={checkKeywordPopup(keyword)}
                        dataSourceIndex={0} // dataSource index to handle delete keyword
                      />
                    )}
                    {/* copy icon component*/}
                    {twitterValues[0].length !== 0 && (
                      <CopyIcon
                        checkCopyValue={twitterValues[2]}
                        handleData={(e) =>
                          handleData(e, twitterValues[0], indexes, index)
                        }
                      />
                    )}
                  </Box>
                </Grid>
              </>
            );
          })}
          <Grid item xs={6}>
            <DropdownSection
              options={
                twitterLanguagesAndCountries?.countries?.length
                  ? twitterLanguagesAndCountries?.countries
                  : []
              }
              placeholder={CheckValueLocale("select_countries", "", {}, intl)}
              label={CheckValueLocale("countries", "", {}, intl)}
              tooltip={CheckValueLocale(
                "targeted_countries_tooltip",
                "",
                {},
                intl,
              )}
              onChange={onCoutriesChange}
              applySelectionConditionFn={applySelectionConditionFn}
              defaultValue={twitterCountries?.length ? twitterCountries : []}
            />
          </Grid>
          <Grid item xs={6}>
            <DropdownSection
              options={
                twitterLanguagesAndCountries?.languages?.length
                  ? twitterLanguagesAndCountries?.languages
                  : []
              }
              placeholder={CheckValueLocale("select_languages", "", {}, intl)}
              label={CheckValueLocale("languages", "", {}, intl)}
              tooltip={CheckValueLocale(
                "targeted_languages_tooltip",
                "",
                {},
                intl,
              )}
              onChange={onLanguagesChange}
              applySelectionConditionFn={applySelectionConditionFn}
              defaultValue={twitterLanguages?.length ? twitterLanguages : []}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default TwitterKeyword;
