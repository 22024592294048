import { Tooltip, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index";

const HashtagUsedNumber = (props) => {
  const {
    limitationOfDataSource,
    wordCountDataSource,
    instaHashtag,
    isNewBlogs,
  } = props;
  const intl = useIntl();

  const wordCountRemaining = limitationOfDataSource - wordCountDataSource;
  return (
    <Tooltip
      title={
        instaHashtag ? (
          <Box className="instagram-hashtags-tooltip">
            {CheckValueLocale(
              wordCountDataSource >= limitationOfDataSource
                ? "instagram_limitation"
                : "remaining_hashtags_ig_tooltip",
              "",
              {},
              intl,
            )}
          </Box>
        ) : (
          ""
        )
      }
      placement="top"
      arrow
    >
      <Box
        className="keywords-counter"
        style={
          wordCountDataSource >= limitationOfDataSource
            ? { color: "#F44336" }
            : {}
        }
      >
        <Box component="span" className="keyword-num-name">
          <Box component="span">
            {instaHashtag ? wordCountRemaining : wordCountDataSource}{" "}
          </Box>
          <Box component="span">
            {instaHashtag
              ? CheckValueLocale("no_of_hash", "", {}, intl)
              : isNewBlogs
                ? CheckValueLocale(
                    "no_of_conditions",
                    "",
                    {
                      allowedConditions: limitationOfDataSource,
                    },
                    intl,
                  )
                : CheckValueLocale("no_of_keywords", "", {}, intl)}
          </Box>
        </Box>

        {/* Commented as not Implemeneted from BE */}
        {/* <Box className="next-update-wrapper">
          Next update in: 5-17-2022 - 11:25 PM
        </Box> */}
      </Box>
    </Tooltip>
  );
};

export default HashtagUsedNumber;
