import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  InputLabel,
  TextField,
  FormControl,
  Link,
  Stack,
  Box,
  Grid,
  Tooltip,
  InputAdornment,
  IconButton,
  Avatar,
} from "@mui/material";
import {
  emailValidator,
  CheckValueLocale,
  maskPhone,
  maskEmail,
} from "utils/helpers";
import Snackbar from "components/snackBar";
import ImageModal from "./imageEditorComponents/imageModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Visibility, VisibilityOff, Warning } from "@mui/icons-material";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import CircularLoading from "components/circularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faEye, faCircle } from "@fortawesome/free-solid-svg-icons";

// Controllers
import UserAccount from "services/controllers/userAccountController";
import {
  EDIT_SUCCESS,
  TRY_AGAIN,
  UNVALID_PASSWORD,
  UNCOMPLETED_DATA,
  CONNECTION_FAILED,
} from "utils/constants";

const UserInfo = (props) => {
  const intl = useIntl();
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    avatar: {},
    email: "",
    job_title: "",
    user_password: null,
    confirm_password: null,
  });
  const [tempUserInfo, setTempUserInfo] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    avatar: {},
    email: "",
    job_title: "",
    user_password: null,
    confirm_password: null,
  });
  const [phoneValue, setPhoneValue] = useState("");
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [isMaskedInputShown, setIsMaskedInputShown] = useState(true);

  const [openModal, setModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [success, setSuccess] = useState(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordInput, showPasswordInput] = useState(false);
  const [changePassword, hideChangePassword] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  // const [checkPassword, setCheckPassword] = useState(true);
  const [countryCode, setCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //Handle Change Value
  const changeHandler = (e) => {
    if (!e.target) {
      setPhoneValue(e[1]);
      setUserInfo({
        ...userInfo,
        phone_number: e[3].replace(/[^+\d]+/g, ""),
      });
    } else {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
      if (e.target.name == "user_password") {
        validatePassword(e.target.value);
      }
    }
  };
  const [errors, setErrors] = useState([]);
  const validatePassword = (passwordText) => {
    const regex_uppercase = /[A-Z]/;
    const regex_lowercase = /[a-z]/;
    const regex_number = /[0-9]/;

    let errors = [];

    if (passwordText.length < 8) {
      errors.push("chars_num_err");
    }
    if (!passwordText.match(regex_uppercase)) {
      errors.push("uppercase_err");
    }
    if (!passwordText.match(regex_lowercase)) {
      errors.push("lowercase_err");
    }
    if (!passwordText.match(regex_number)) {
      errors.push("number_err");
    }
    setErrors(errors);
  };
  const changeFlagHandler = (e) => {
    setPhoneValue(e[0]);
    setUserInfo({
      ...userInfo,
      phone_number: e[2].replace(/[^+\d]+/g, ""),
    });
  };

  const checkValuesChanged = () => {
    Object.keys(userInfo).forEach((key) => {
      if (key !== "phone_number") {
        if (userInfo[key] !== tempUserInfo[key]) {
          setButtonDisabled(false);
        }
      }
    });
  };

  const checkPhoneValuesChanged = () => {
    if (userInfo["phone_number"] !== tempUserInfo["phone_number"]) {
      setButtonDisabled(false);
    }
  };

  //Password Validator
  // const passwordValidation = () => {
  //   if (!buttonDisabled && userInfo.user_password) {
  //     setCheckPassword(passwordValidator(userInfo.user_password));
  //   }
  // };

  //Handle Edit Image
  const setImageUpdate = async (newImage) => {
    setUserInfo({ ...userInfo, avatar: newImage });
    setButtonDisabled(false);
    handleModal();
  };

  //Handle Show Password Input
  const handleShowPasswordInput = () => {
    setUserInfo({ ...userInfo, user_password: "" });
    showPasswordInput(true);
    hideChangePassword(false);
  };

  //Handle Modal
  const handleModal = () => {
    if (openModal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  //Handle Click Show Password
  const handleClickShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  //Handle Click Show Confirm Password
  const handleClickShowConfirmPassword = () => {
    if (showConfirmPassword) {
      setShowConfirmPassword(false);
    } else {
      setShowConfirmPassword(true);
    }
  };

  //Handle Validate Confirm Password
  const handleValidateConfirmPassword = () => {
    if (userInfo.confirm_password != userInfo.user_password) {
      return false;
    } else {
      return true;
    }
  };

  const checkValid = () => {
    if (
      userInfo.first_name == "" ||
      userInfo.last_name == "" ||
      !emailValidator(userInfo.email)
    ) {
      setSuccess(false);
      setErrorMsg(UNCOMPLETED_DATA);
      return false;
    } else if (!handleValidateConfirmPassword()) {
      setSuccess(false);
      setErrorMsg(UNVALID_PASSWORD);
      return false;
    }
    return true;
  };

  // Submit Edit User Info
  const handleSubmit = async () => {
    const isValid = checkValid();
    const validatePhoneNumber = checkValidPhoneNumber();

    if (isValid && validatePhoneNumber) {
      var user_info = new FormData();
      for (var key of Object.keys(userInfo)) {
        if (userInfo[key] instanceof File) {
          user_info.append(key, userInfo[key]);
        } else if (key != "avatar") {
          if (userInfo[key] != null) {
            user_info.append(key, userInfo[key]);
          }
        }
      }
      await UserAccount.editUserInfo(user_info).then((result) => {
        if (result.errorMsg) {
          setErrorMsg("connection_failed");
          setSuccess(false);
        } else {
          setSuccess(true);
          setInterval(function () {
            window.location.reload();
          }, 2000);
        }
      });
    } else {
      if (!validatePhoneNumber) {
        setErrorMsg(CheckValueLocale("invalid_phone_number", "", {}, intl));
        setSuccess(false);
      }
      if (!success) {
        setSuccess(false);
      }
    }
  };

  const checkValidPhoneNumber = () => {
    let result = true;
    if (phoneValue == "" || phoneValue == null) {
      userInfo.phone_number = null;
    } else if (phoneValue && userInfo.phone_number) {
      result = isValidPhoneNumber(userInfo.phone_number);
    }
    return result;
  };

  const getUserInfo = async () => {
    await UserAccount.getUserInfo().then((result) => {
      if (!result.errorMsg) {
        let data = result.user.data.attributes;
        setUserInfo(data);
        setTempUserInfo(data);
        setMaskedPhoneNumber(
          data.phone_number
            ? parsePhoneNumber(data.phone_number).formatNational()
            : data.phone_number,
        );
        setPhoneValue(
          data.phone_number
            ? parsePhoneNumber(data.phone_number).formatNational()
            : data.phone_number,
        );
        setCountryCode(
          data.phone_number
            ? parsePhoneNumber(data.phone_number)?.country?.toLowerCase()
            : data.phone_number,
        );

        setIsLoading(false);
      } else {
        setErrorMsg(CONNECTION_FAILED);
        setSuccess(false);
      }
    });
  };

  //Render Componenet
  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      checkValuesChanged();
    }
  }, [userInfo]);

  useEffect(() => {
    checkPhoneValuesChanged();
  }, [phoneValue]);

  return (
    <>
      {isLoading ? (
        <div className="user-info-loader">
          <CircularLoading />
        </div>
      ) : (
        <div className="user-info-section">
          <Box className="profile__head">
            <Box>
              {CheckValueLocale("user_info", "", {}, intl).toUpperCase()}
            </Box>
          </Box>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid container spacing={2}>
              {/* Photo Part */}
              <Grid item lg={3} align="center" md={5} sm={12} xs={12}>
                <Stack direction="row" justifyContent="center">
                  <Avatar
                    sx={{ width: 200, height: 200 }}
                    src={
                      userInfo.avatar instanceof File
                        ? URL.createObjectURL(userInfo.avatar)
                        : userInfo.avatar
                    }
                    id="profile-user-avatar"
                  />
                </Stack>
                <div id="button">
                  <Tooltip title={CheckValueLocale("edit_photo", "", {}, intl)}>
                    <Button
                      onClick={() => {
                        handleModal();
                      }}
                      id="profile-user-info-edit-photo-btn"
                    >
                      <CameraAltIcon className="cameraIcon" />
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item lg={9} md={3} sm={12} xs={12} alignContent="center">
                <Stack direction="row" justifyContent="center">
                  <Grid container direction="row" spacing={1}>
                    {/* First Name */}
                    <Grid item mt={2}>
                      <InputLabel shrink htmlFor="first_name">
                        {CheckValueLocale("first_name", "", {}, intl)}
                      </InputLabel>
                      <Box>
                        <FormControl sx={{ width: "30ch" }}>
                          <TextField
                            placeholder={CheckValueLocale(
                              "enter_first_name",
                              "",
                              {},
                              intl,
                            )}
                            className="form-control"
                            name="first_name"
                            value={
                              userInfo.first_name ? userInfo.first_name : ""
                            }
                            InputProps={{
                              endAdornment:
                                !buttonDisabled && !userInfo.first_name ? (
                                  <Tooltip
                                    title={CheckValueLocale(
                                      "fill_field",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  >
                                    <InputAdornment position="start">
                                      <IconButton edge="end">
                                        <Warning />
                                      </IconButton>
                                    </InputAdornment>
                                  </Tooltip>
                                ) : (
                                  ""
                                ),
                            }}
                            onInput={changeHandler}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* Last Name */}
                    <Grid item mt={2}>
                      <InputLabel shrink htmlFor="last_name">
                        {CheckValueLocale("last_name", "", {}, intl)}
                      </InputLabel>
                      <Box>
                        <FormControl sx={{ width: "30ch" }}>
                          <TextField
                            placeholder={CheckValueLocale(
                              "enter_last_name",
                              "",
                              {},
                              intl,
                            )}
                            inputClass="form-control"
                            name="last_name"
                            value={userInfo.last_name ? userInfo.last_name : ""}
                            InputProps={{
                              endAdornment:
                                !buttonDisabled && !userInfo.last_name ? (
                                  <Tooltip
                                    title={CheckValueLocale(
                                      "fill_field",
                                      "",
                                      {},
                                      intl,
                                    )}
                                  >
                                    <InputAdornment position="start">
                                      <IconButton edge="end">
                                        <Warning />
                                      </IconButton>
                                    </InputAdornment>
                                  </Tooltip>
                                ) : (
                                  ""
                                ),
                            }}
                            onInput={changeHandler}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* Email */}
                    <Grid item mt={2}>
                      <InputLabel shrink htmlFor="email">
                        {CheckValueLocale("Emails", "", {}, intl)}
                      </InputLabel>
                      <Box>
                        <FormControl sx={{ width: "30ch" }}>
                          <TextField
                            placeholder={CheckValueLocale(
                              "enter_email_address",
                              "",
                              {},
                              intl,
                            )}
                            inputClass="form-control"
                            name="email"
                            value={maskEmail(userInfo?.email) || ""}
                            disabled
                            InputProps={{
                              endAdornment:
                                !buttonDisabled &&
                                userInfo.email &&
                                !emailValidator(userInfo.email) ? (
                                  <Tooltip
                                    title={
                                      userInfo.email == ""
                                        ? CheckValueLocale(
                                            "fill_field",
                                            "",
                                            {},
                                            intl,
                                          )
                                        : CheckValueLocale(
                                            "invalid_email_address",
                                            "",
                                            {},
                                            intl,
                                          )
                                    }
                                  >
                                    <InputAdornment position="start">
                                      <IconButton edge="end">
                                        <Warning />
                                      </IconButton>
                                    </InputAdornment>
                                  </Tooltip>
                                ) : (
                                  ""
                                ),
                            }}
                            onInput={changeHandler}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* Phone Number */}
                    <Grid item mt={2}>
                      <InputLabel shrink htmlFor="phone_no">
                        {CheckValueLocale("phone_number", "", {}, intl) +
                          `(${CheckValueLocale("optional", "", {}, intl)})`}
                      </InputLabel>
                      <Box>
                        <FormControl sx={{ width: "30ch" }}>
                          {isMaskedInputShown ? (
                            <Box onClick={() => setIsMaskedInputShown(false)}>
                              <IntlTelInput
                                containerClassName="intl-tel-input"
                                inputClassName="form-control react-tel-input"
                                separateDialCode
                                defaultCountry={
                                  countryCode ? countryCode : "sa"
                                }
                                excludeCountries={["il"]}
                                autoHideDialCode
                                nationalMode
                                // format
                                fieldName="phone_number"
                                placeholder="5xx xxx xxx xxx"
                                value={
                                  maskedPhoneNumber
                                    ? maskPhone(maskedPhoneNumber)
                                    : ""
                                }
                                onPhoneNumberChange={() => {}}
                                onSelectFlag={() => {}}
                                enableSearch
                              />
                            </Box>
                          ) : (
                            <IntlTelInput
                              containerClassName="intl-tel-input"
                              inputClassName="form-control react-tel-input"
                              separateDialCode
                              defaultCountry={countryCode ? countryCode : "sa"}
                              excludeCountries={["il"]}
                              autoHideDialCode
                              nationalMode
                              // format
                              fieldName="phone_number"
                              placeholder="5xx xxx xxx xxx"
                              value={phoneValue || ""}
                              onPhoneNumberChange={(...args) => {
                                changeHandler(args);
                              }}
                              onSelectFlag={(...args) => {
                                changeFlagHandler(args);
                              }}
                              enableSearch
                            />
                          )}

                          {/* <IntlTelInput
                            containerClassName="intl-tel-input"
                            inputClassName="form-control react-tel-input"
                            separateDialCode
                            defaultCountry={countryCode ? countryCode : "sa"}
                            excludeCountries={["il"]}
                            autoHideDialCode
                            nationalMode
                            // format
                            fieldName="phone_number"
                            placeholder="5xx xxx xxx xxx"
                            value={
                              maskedPhoneNumber
                                ? maskPhone(maskedPhoneNumber)
                                : ""
                            }
                            onPhoneNumberChange={(...args) => {
                              console.log(args);
                              changeHandler(args);
                              setMaskedPhoneNumber(args[1]);
                            }}
                            onSelectFlag={(...args) => {
                              changeFlagHandler(args);
                            }}
                            enableSearch
                          /> */}
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* Job Title */}
                    <Grid item mt={2}>
                      <InputLabel shrink htmlFor="job_title">
                        {CheckValueLocale("job_title", "", {}, intl) +
                          `(${CheckValueLocale("optional", "", {}, intl)})`}
                      </InputLabel>
                      <Box>
                        <FormControl sx={{ width: "30ch" }}>
                          <TextField
                            placeholder={CheckValueLocale(
                              "enter_job_title",
                              "",
                              {},
                              intl,
                            )}
                            inputClass="form-control"
                            name="job_title"
                            value={
                              userInfo.job_title != "" ? userInfo.job_title : ""
                            }
                            onInput={changeHandler}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  style={{ display: passwordInput ? "" : "none" }}
                  mt={1}
                >
                  {/* Password */}
                  <Grid item>
                    <InputLabel shrink htmlFor="password">
                      {CheckValueLocale("password", "", {}, intl)}
                    </InputLabel>
                    <Box>
                      <FormControl sx={{ width: "30ch" }}>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          inputClass="form-control"
                          name="user_password"
                          autoComplete="new-password"
                          onInput={changeHandler}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Tooltip
                                      title={CheckValueLocale(
                                        "hide_pass",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    >
                                      <VisibilityOff />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={CheckValueLocale(
                                        "show_pass",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    >
                                      <Visibility />
                                    </Tooltip>
                                  )}
                                </IconButton>
                                {/* {checkPassword ? (
                                  ""
                                ) : userInfo.user_password ? (
                                  <IconButton edge="end">
                                    <Tooltip
                                      title={CheckValueLocale("password_pattern","", {},intl)}
                                    >
                                      <Warning />
                                    </Tooltip>
                                  </IconButton>
                                ) : (
                                  ""
                                )} */}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <>
                          <div className="pass-errs-list">
                            <span
                              className={
                                errors.includes("chars_num_err") ||
                                userInfo?.user_password?.length === 0
                                  ? "pass-err"
                                  : "pass-err pass-err-green"
                              }
                            >
                              {errors.includes("chars_num_err") ||
                              userInfo?.user_password?.length === 0 ? (
                                <FontAwesomeIcon icon={faCircle} />
                              ) : (
                                <FontAwesomeIcon icon={faCheck} />
                              )}
                              {CheckValueLocale("chars_num_err", "", {}, intl)}
                            </span>
                            <span
                              className={
                                errors.includes("uppercase_err") ||
                                userInfo?.user_password?.length === 0
                                  ? "pass-err"
                                  : "pass-err pass-err-green"
                              }
                            >
                              {errors.includes("uppercase_err") ||
                              userInfo?.user_password?.length === 0 ? (
                                <FontAwesomeIcon icon={faCircle} />
                              ) : (
                                <FontAwesomeIcon icon={faCheck} />
                              )}
                              {CheckValueLocale("uppercase_err", "", {}, intl)}
                            </span>
                            <span
                              className={
                                errors.includes("lowercase_err") ||
                                userInfo?.user_password?.length === 0
                                  ? "pass-err"
                                  : "pass-err pass-err-green"
                              }
                            >
                              {errors.includes("lowercase_err") ||
                              userInfo?.user_password?.length === 0 ? (
                                <FontAwesomeIcon icon={faCircle} />
                              ) : (
                                <FontAwesomeIcon icon={faCheck} />
                              )}
                              {CheckValueLocale("lowercase_err", "", {}, intl)}
                            </span>
                            <span
                              className={
                                errors.includes("number_err") ||
                                userInfo?.user_password?.length === 0
                                  ? "pass-err"
                                  : "pass-err pass-err-green"
                              }
                            >
                              {errors.includes("number_err") ||
                              userInfo?.user_password?.length === 0 ? (
                                <FontAwesomeIcon icon={faCircle} />
                              ) : (
                                <FontAwesomeIcon icon={faCheck} />
                              )}
                              {CheckValueLocale("number_err", "", {}, intl)}
                            </span>
                          </div>
                        </>
                      </FormControl>
                    </Box>
                  </Grid>

                  {/* Confirm Password */}
                  <Grid item>
                    <InputLabel shrink htmlFor="confirm_password">
                      {CheckValueLocale("confirm_password", "", {}, intl)}
                    </InputLabel>
                    <Box>
                      <FormControl sx={{ width: "30ch" }}>
                        <TextField
                          type={showConfirmPassword ? "text" : "password"}
                          inputClass="form-control"
                          id="confirm_password"
                          name="confirm_password"
                          onInput={changeHandler}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={handleClickShowConfirmPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Tooltip
                                      title={CheckValueLocale(
                                        "hide_pass",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    >
                                      <VisibilityOff />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={CheckValueLocale(
                                        "show_pass",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    >
                                      <Visibility />
                                    </Tooltip>
                                  )}
                                </IconButton>
                                {!buttonDisabled &&
                                userInfo.confirm_password &&
                                !handleValidateConfirmPassword() ? (
                                  <IconButton edge="end">
                                    <Tooltip
                                      title={CheckValueLocale(
                                        "fill_field",
                                        "",
                                        {},
                                        intl,
                                      )}
                                    >
                                      <Warning />
                                    </Tooltip>
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Button
                      className="save__profile"
                      variant="contained"
                      disabled={buttonDisabled ? true : false}
                      onClick={() => {
                        handleSubmit();
                      }}
                      id="profile-user-info-save-profile-btn"
                    >
                      {CheckValueLocale("save", "", {}, intl)}
                    </Button>
                    <Link
                      variant="button"
                      style={{
                        textTransform: "none",
                        fontSize: "15px",
                        marginTop: "20px",
                        display: changePassword ? "" : "none",
                        position: "relative",
                        top: "20px",
                        color: "#199BDF",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleShowPasswordInput();
                      }}
                      id="profile-user-info-change-password-profile-btn"
                    >
                      {CheckValueLocale("change_password", "", {}, intl)}
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {openModal ? (
            <ImageModal
              open={openModal}
              handleModal={handleModal}
              avatar={userInfo.avatar}
              setImage={setImageUpdate}
              id="user-profile-image-modal"
            />
          ) : (
            ""
          )}
          {success != null ? (
            <Snackbar
              open={success != null ? true : false}
              handleClose={() => {
                setSuccess(null);
              }}
              severity={success ? "success" : "error"}
              message={
                success ? (
                  CheckValueLocale(EDIT_SUCCESS, "", {}, intl)
                ) : (
                  <div>
                    <span style={{ fontWeight: "500", fontSize: "16px" }}>
                      {CheckValueLocale(errorMsg, "", {}, intl)}
                    </span>
                    <span>
                      <br />
                      {CheckValueLocale(TRY_AGAIN, "", {}, intl)}
                    </span>
                  </div>
                )
              }
            />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};
export default UserInfo;
