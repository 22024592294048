import { Box, Tooltip, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import "./teams.scss";
import { useEffect, useState } from "react";
import CompaniesController from "services/controllers/companiesController";
import SnackBar from "components/snackBar";
import { TeamsPreview } from "./components/teamsPreview";
import { TeamsHeader } from "./components/teamsHeader";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import APIIcon from "images/product-icons/api-icon.svg";
import EngagementIcon from "images/product-icons/engagement-icon.svg";
import DCPIcon from "images/product-icons/cdp-icon.svg";
import OCIcon from "images/product-icons/oc.icon.svg";
import SettingIcon from "images/product-icons/settings-icon.svg";
import SLIcon from "images/product-icons/sl-icon.svg";
import Surveys from "images/product-icons/surveys-icon.svg";
import DefaultUser from "images/shared-images/user-management-default.svg";
import OptionsDropDown from "./components/optionsDropdown";
import PopupModal from "components/popupModal";
import { sanitize } from "dompurify";
import NoDataFoundProducts from "components/no-Data";

export const iconObjectMapper = {
  ENGAGEMENTS: EngagementIcon,
  SM: SLIcon,
  CDP: DCPIcon,
  CXM: OCIcon,
  PUBLIC_API: APIIcon,
  GENERAL: SettingIcon,
  SURVEY: Surveys,
};

const Teams = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [teams, setTeams] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(1);
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const [stats, setStats] = useState({
    team_id: null,
    team_name: "",
    openDeleteModal: false,
    page: "",
  });
  const [teamsSnackBar, setTeamsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });

  const handleSearchIconClick = (searchInput) => {
    if (searchInput !== search) {
      setSearch(searchInput);
      setPage(1);
    }
  };
  const handleCloseSnackBar = () => {
    setTeamsSnackBar({
      openSnackBar: false,
    });
  };
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };
  const deleteTeam = (teamId) => {
    CompaniesController.deleteTeam(teamId).then((res) => {
      if (res?.errorMsg) {
        setTeamsSnackBar({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      } else {
        setTeamsSnackBar({
          message: "team_deleted_successfully",
          severity: "success",
          openSnackBar: true,
        });
        // if the team has been deleted, and current page is not the first page and it has only one team
        // then go to the previous page
        if (teams?.length === 1 && page !== 1) {
          setPage(page - 1);
        } else {
          getTeams();
        }
      }
    });
  };
  const getTeams = () => {
    setLoading(true);
    CompaniesController.getTeams(page, search).then((res) => {
      if (res?.errorMsg) {
        setTeamsSnackBar({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
        setTeams([]);
        setPagination(1);
      } else {
        setTeams(res?.data?.data);
        setPagination(res?.data?.pagination);
      }
      setLoading(false);
    });
  };

  const handleTeamDeletion = (id) => {
    deleteTeam(id);
    handleCloseDeleteModal();
  };
  const handleCloseDeleteModal = () => {
    setStats({
      openDeleteModal: false,
    });
  };

  const handleDeleteUserAccount = (team) => {
    setStats({
      team_id: team?.id,
      team_name: team?.name,
      openDeleteModal: true,
      page: page,
    });
  };
  const teamNameCell = (name, id) => {
    return (
      <Box>
        <Link
          to={{
            pathname: `/settings/teams/view/${id}`,
            state: { id: name },
          }}
          className="teams-table-name"
        >
          {name}
        </Link>
      </Box>
    );
  };

  const teamCreatedAtCell = (createdAt) => {
    return (
      <Box>
        <Typography as="span" className="teams-table-created-at">
          {new Date(createdAt).toLocaleDateString("en-GB").replaceAll("/", "-")}
        </Typography>
        <br />
        <Typography as="span" className="teams-table-created-at-time">
          {new Date(createdAt).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </Typography>
      </Box>
    );
  };
  const productAccessCell = (product) => {
    const icon = iconObjectMapper[product];
    if (!icon) return "—";
    return (
      <Box className="teams-table-products">
        <Tooltip
          title={CheckValueLocale(product, "", {}, intl)}
          placement="top"
          arrow
        >
          <Box className="teams-table-product">
            <img src={icon} alt={product} />
          </Box>
        </Tooltip>
      </Box>
    );
  };
  const teamMembersCell = (members) => {
    if (!members?.length)
      return (
        <Typography component="span" className="teams-table-no-members">
          {CheckValueLocale("no_members_found", "", {}, intl)}
        </Typography>
      );

    return (
      <Box className="teams-table-members">
        <>
          {members?.slice(0, 4).map((memberObj) => {
            const member = memberObj?.attributes;
            return (
              <Box className="teams-table-member">
                <Tooltip
                  title={member?.name}
                  key={member?.id}
                  placement="top"
                  arrow
                  classes={{
                    tooltip: "teams-members-preview-tooltip",
                  }}
                >
                  <img
                    key={member?.id}
                    src={member?.avatar || DefaultUser}
                    alt={member?.name}
                    onError={(e) => {
                      e.target.src = DefaultUser;
                      e.target.className = "broken-image";
                    }}
                  />
                </Tooltip>
              </Box>
            );
          })}
          {members?.length > 4 ? (
            <Tooltip
              title={members
                ?.slice(4)
                ?.map((memberObj) => memberObj?.attributes?.name)
                .join(", ")}
              placement="top"
              arrow
              classes={{
                tooltip: classNames(
                  members?.length > 53 && "teams-max-width-tooltip",
                  "teams-members-preview-tooltip",
                  "teams-members-preview-tooltip-more",
                ),
              }}
            >
              <Box className="teams-table-member teams-table-member-more">
                +{Math.min(members?.length - 4, 99)}
              </Box>
            </Tooltip>
          ) : null}
        </>
      </Box>
    );
  };

  const optionsDropdown = (team) => {
    return (
      <OptionsDropDown team={team} openDeleteModal={handleDeleteUserAccount} />
    );
  };

  useEffect(() => {
    const tabBody =
      teams?.length == 0
        ? []
        : teams?.map((teamObj) => {
            const team = teamObj?.attributes || {};
            return {
              data: [
                {
                  body: teamNameCell(team?.name, team?.id),
                },
                {
                  body: teamCreatedAtCell(team?.created_at),
                },
                {
                  body: productAccessCell(team?.product),
                },
                {
                  body: teamMembersCell(team?.users?.data),
                },
                {
                  body: optionsDropdown(team),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(teams));
  }, [teams, intl?.locale]);

  useEffect(() => {
    getTeams();
  }, [page, search]);

  useEffect(() => {
    // if the search has a deleted_successfully message status,
    // then show a snackbar with the message deleted_successfully
    const search = history?.location?.search;
    if (search && search?.includes("status=deleted_successfully")) {
      // show the error message and then remove it from the url
      setTeamsSnackBar({
        message: "team_deleted_successfully",
        severity: "success",
        openSnackBar: true,
      });
      window.history.pushState({}, "", window.location.pathname);
    }
  }, []);

  const emptyBody = (teams) => {
    return (
      <>
        {search !== "" && teams?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="monitor_list_search_empty_submsg"
            />
          </Box>
        ) : teams?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="teams"
              title="create_a_team"
              subTitle="create_a_team_dec"
            />
          </Box>
        ) : null}
      </>
    );
  };

  if (loading) {
    return <CircularLoading />;
  }

  return (
    <Box className="teams">
      <TeamsHeader
        teams={teams}
        search={search}
        handleSearchIconClick={handleSearchIconClick}
      />
      <TeamsPreview
        teams={teams}
        search={search}
        deleteTeam={deleteTeam}
        pagination={pagination}
        handleChangePage={handleChangePage}
        page={page}
        tableBody={tableBody}
        tableEmptyBody={tableEmptyBody}
      />
      <SnackBar
        open={teamsSnackBar?.openSnackBar}
        severity={teamsSnackBar?.severity}
        message={CheckValueLocale(teamsSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(teamsSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
      <PopupModal
        title={CheckValueLocale("delete_team", "", {}, intl)}
        body={
          <Box className="shared-dialog-body">
            <p>
              {CheckValueLocale(
                "delete_team_body",
                "",
                { team_name: stats?.team_name },
                intl,
              )}
            </p>
            <h2 className="sub-text-desc">
              {CheckValueLocale("deleting_msg", "", {}, intl)}
            </h2>
          </Box>
        }
        open={stats.openDeleteModal}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
        close={handleCloseDeleteModal}
        accept={() => handleTeamDeletion(stats?.team_id)}
        warning
        minWidth="600px"
        classeName="delete-team-modal actions-buttons-shared"
      />
    </Box>
  );
};

export default Teams;
