import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "./navigationContent.scss";
import { CheckValueLocale } from "utils/helpers";
import CommentIcon from "@mui/icons-material/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PublishIcon from "images/engagements-images/publish-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons";

const NavigationSidebar = ({
  openNavigationMenu,
  navigationMenuStatus,
  notOpened,
}) => {
  const intl = useIntl();
  let title = `${navigationMenuStatus}_engagement`;

  const renderIcon = () => {
    switch (navigationMenuStatus) {
      case "inbox":
        return <CommentIcon className="comment-icon-engagement" />;
      case "analytics":
        return (
          <Box className="analytics-engagement-icon-container">
            <FontAwesomeIcon
              icon={faAnalytics}
              className="analytics-engagement-icon"
            />
          </Box>
        );
      default:
        return (
          <img
            src={PublishIcon}
            alt="publish-icon"
            className="publish-icon-inbox"
          />
        );
    }
  };
  return (
    <Box
      className={`conainer-engagement-inbox ${
        openNavigationMenu
          ? "conainer-engagement-inbox-opened"
          : notOpened
            ? "no-access"
            : "have-access"
      }`}
    >
      {renderIcon()}
      <Box className="inbox-wrapper-engagement">
        <Box className="inbox-content-engagement">
          <Typography component={"p"} className="inbox-type-enagement">
            {CheckValueLocale(title, "", {}, intl)}
          </Typography>
          {!notOpened ? <ExpandMoreIcon /> : null}
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationSidebar;
