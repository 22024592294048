import { createSlice } from "@reduxjs/toolkit";

const initialState = { selectedCard: {}, sla: {} };

const engagementsSlice = createSlice({
  name: "engagementsSlice",
  initialState,
  reducers: {
    clear: (state) => {
      state.selectedCard = {};
    },
    cardData: (state, action) => {
      state.selectedCard = { ...action?.payload };
    },
    clearSla: (state) => {
      state.sla = {};
    },
    slaData: (state, action) => {
      state.sla = { ...action?.payload };
    },
  },
});

export default engagementsSlice.reducer;
export const { clear, cardData, clearSla, slaData } = engagementsSlice.actions;
