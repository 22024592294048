import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import PopupModal from "components/popupModal.js";
import {
  Box,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Alert,
  Tooltip,
} from "@mui/material";
import CompaniesController from "../../../../../services/controllers/companiesController";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import moment from "moment";
import SnackBar from "components/snackBar";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getSocialIcon,
  CheckValueLocale,
  commasAfterDigit,
} from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import AddIcon from "@mui/icons-material/Add";
import Timer from "images/Historical Request/Timer.svg";
import timeList from "./timeRangeDay";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft } from "@fortawesome/pro-regular-svg-icons";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};

const CreateHistorical = (props) => {
  const intl = useIntl();
  const remaining = props;

  const [remainNewsBlogs, setRemainNewsBlogs] = useState();
  const [remainPosts, setRemainPosts] = useState();
  const [remainInteractions, setRemainInteractions] = useState();

  const [stats, setStats] = useState({
    anchorEl: null,
    openCreateModal: false,
  });
  const [reqParams, setReqParams] = useState({
    platform: "",
    datasource: "",
    monitor: "",
  });
  const [platforms, setPlatforms] = useState();
  const [datasources, setDatasources] = useState();
  const [monitorsList, setMonitorsList] = useState();
  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [startNewDate, setStartNewDate] = useState(moment().unix());
  const [endNewDate, setEndNewDate] = useState(moment().unix());
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(23.5);
  const [smId, setSmId] = useState(null);
  const [xplatformId, setXplatformId] = useState(null);
  const [defaultDateRange, setDefaultDateRange] = useState("last_7_days");
  const [quotaLimitMsg, setQuotaLimitMsg] = useState(null);
  const [step, setStep] = useState(1);
  const [postNum, setPostNum] = useState("");
  const [success, setSuccess] = useState(null);
  const [timeError, setTimeError] = useState(null);
  const [datasourceLoad, setDatasourceLoad] = useState(null);
  const [monitorLoad, setMonitorLoad] = useState(null);
  const [remainQuota, setRemainQuota] = useState(null);
  const [quotaAlert, setQuotaAlert] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [newsDateLimit, setNewsDateLimit] = useState();
  const [successStatus, setSuccessStatus] = useState("");
  const [oldestDate, setOldestDate] = useState(
    moment.unix(645794640).format("YYYY/MM/DD"),
  );
  const [actualCountDataTwitter, setActualCountDataTwitter] = useState(0);
  const [duplicatesCountDataTwitter, setDuplicatesCountDataTwitter] =
    useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [createHistFlag, setCreateHistFlag] = useState(true);
  const [isInstagramKeyword, setIsInstagramKeyword] = useState(false);
  const [dataSourcesArr, setDataSourcesArr] = useState([]);

  useEffect(() => {
    if (props?.isMonitorOption && props?.monitorData) {
      if (
        props?.monitorData?.attributes?.monitor_type_name === "KEYWORD" &&
        props?.monitorData?.attributes?.data_sources?.length === 1 &&
        props?.monitorData?.attributes?.data_sources?.[0] === "INSTAGRAM"
      )
        setIsInstagramKeyword(true);
      else setIsInstagramKeyword(false);
    }
  }, [props?.isMonitorOption, props?.monitorData]);

  const historicalDate = (type) => {
    const date = {
      legacy: moment().subtract(30, "d").startOf("day").unix(),
      enhanced: moment().subtract(100, "d").startOf("day").unix(),
    };
    return date[type];
  };
  useEffect(() => {
    if (stats?.openCreateModal) {
      (async () => {
        await getHistoricalCompanyPlatforms();
      })();
    }
  }, [stats?.openCreateModal]);

  useEffect(() => {
    if (reqParams?.platform !== "") {
      getHistoricalDatasources();
    }
  }, [reqParams?.platform]);

  useEffect(() => {
    if (reqParams?.platform !== "" && reqParams?.datasource !== "") {
      getHistoricalMonitors();
      if (
        reqParams?.platform ===
          JSON.parse(localStorage.getItem("platformsObj"))?.SM &&
        reqParams?.datasource ===
          JSON.parse(localStorage.getItem("dataSourcesObj"))?.TALKWALKER
      ) {
        setOldestDate(
          moment.unix(historicalDate(newsDateLimit))?.format("YYYY/MM/DD"),
        );
      } else {
        setOldestDate(moment.unix(645794640)?.format("YYYY/MM/DD"));
      }
    }
  }, [reqParams?.datasource]);

  const getHistoricalCompanyPlatforms = () => {
    CompaniesController.getHistoricalCompanyPlatforms().then((result) => {
      setPlatforms(result?.data?.platforms?.data);
      var platformsObj = {};
      for (var i = 0; i < result?.data?.platforms?.data?.length; i++) {
        platformsObj[result?.data?.platforms?.data[i]?.attributes?.name] =
          result?.data?.platforms?.data[i]?.attributes?.id;

        if (result?.data?.platforms?.data[i]?.attributes?.name === "SM") {
          setSmId(result?.data?.platforms?.data[i]?.attributes?.id);
        }
      }

      localStorage.setItem("platformsObj", JSON.stringify(platformsObj));
    });
  };

  const getHistoricalDatasources = () => {
    setDatasourceLoad(true);
    CompaniesController.getHistoricalDatasources(reqParams.platform).then(
      (result) => {
        setNewsDateLimit(result?.data?.nb_provider_name);
        setDatasources(result?.data?.sources?.data);
        setDatasourceLoad(false);
        const xplatformId = result?.data?.sources?.data?.filter(
          (dataSource) => dataSource?.attributes?.source === "TWITTER",
        );
        setXplatformId(xplatformId[0]?.attributes?.id);
      },
    );
  };

  const getHistoricalMonitors = () => {
    setMonitorLoad(true);
    CompaniesController.getHistoricalMonitors(
      reqParams.platform,
      reqParams.datasource,
    ).then((result) => {
      setMonitorsList(result?.data?.monitors?.data);
      setMonitorLoad(false);
    });
  };

  const createHistoricalRequest = () => {
    var queryData = {
      monitor_model_id: reqParams?.monitor,
      product_id: reqParams?.platform,
      data_source_id: reqParams?.datasource,
      start_date: startDate,
      end_date: endDate,
      count: postNum,
    };
    if (reqParams?.datasource === xplatformId) {
      queryData = { ...queryData, duplicates: duplicatesCountDataTwitter };
    }
    handleCloseCreateModal();
    if (props?.isMonitorOption) {
      props?.handleCloseDropdown();
    }
    CompaniesController.createHistoricalRequest(queryData).then((result) => {
      if (result?.status === 201 || result?.status === 200) {
        if (props?.isMonitorOption) {
          props?.setMonitorsListSnack({
            open: true,
            status: "success",
            title: CheckValueLocale(
              "create_historical_success_title",
              "",
              {},
              intl,
            ),
            msg: CheckValueLocale(
              "create_historical_success_body_monitor_option",
              "",
              {
                link: (
                  <Link
                    className="snack-historical-link"
                    to="/settings/historical_request"
                  >
                    {CheckValueLocale("historical_request", "", {}, intl)}
                  </Link>
                ),
              },
              intl,
            ),
          });
        } else {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(null);
          }, 7000);
          setSnackBarMsg(
            <div className="historical-snackbar historical-snackbar-success">
              <h3>
                {CheckValueLocale(
                  "create_historical_success_title",
                  "",
                  {},
                  intl,
                )}
              </h3>
              <span>
                {CheckValueLocale(
                  "create_historical_success_body",
                  "",
                  {},
                  intl,
                )}
              </span>
            </div>,
          );
          setSuccessStatus("success");
          props.getHistoricalRequestsList("retryLoader");
        }
      } else {
        if (props?.isMonitorOption) {
          props?.setMonitorsListSnack({
            open: true,
            status: "error",
            title: CheckValueLocale(
              "create_historical_fail_title",
              "",
              {},
              intl,
            ),
            msg: CheckValueLocale(
              "create_historical_fail_body_monitor_option",
              "",
              {},
              intl,
            ),
          });
        } else {
          setSuccess(true);
          setSuccessStatus("error");
          setTimeout(() => {
            setSuccess(null);
          }, 7000);
          setSnackBarMsg(
            <div className="historical-snackbar historical-snackbar-fail">
              <h3>
                {CheckValueLocale("create_historical_fail_title", "", {}, intl)}
              </h3>
              <span>
                {CheckValueLocale("create_historical_fail_body", "", {}, intl)}
              </span>
            </div>,
          );
        }
      }
      if (!props?.isMonitorOption) props.createHistoricalRequestFlag();
    });
  };

  const calcRemaining = () => {
    if (
      reqParams?.platform ===
      JSON.parse(localStorage.getItem("platformsObj"))?.CXM
    ) {
      if (props?.isMonitorOption) setRemainQuota(remainInteractions);
      else setRemainQuota(remaining?.remainInteractions);
    } else if (
      reqParams?.platform ===
        JSON.parse(localStorage.getItem("platformsObj"))?.SM &&
      reqParams?.datasource ===
        JSON.parse(localStorage.getItem("dataSourcesObj"))?.TALKWALKER
    ) {
      if (props?.isMonitorOption) setRemainQuota(remainNewsBlogs);
      else setRemainQuota(remaining?.remainNewsBlogs);
    } else {
      if (props?.isMonitorOption) setRemainQuota(remainPosts);
      else setRemainQuota(remaining?.remainPosts);
    }
  };
  useEffect(() => {
    if (dataSourcesArr?.length) {
      var dataSourcesObj = {};
      for (var i = 0; i < dataSourcesArr?.length; i++) {
        dataSourcesObj[dataSourcesArr[i]?.source] = dataSourcesArr[i]?.id;
      }
      localStorage.setItem("dataSourcesObj", JSON.stringify(dataSourcesObj));
    }
  }, [dataSourcesArr]);
  useEffect(() => {
    !localStorage.getItem("dataSourcesObj")
      ? getDataSources(setDataSourcesArr)
      : null;
  }, []);
  const updatePlatformSelect = (event) => {
    const {
      target: { value, name },
    } = event;
    if (name === "platform") {
      setReqParams({ ...reqParams, datasource: "", monitor: "" });
    }
    if (name === "datasource") {
      if (props?.isMonitorOption) setReqParams({ ...reqParams });
      else setReqParams({ ...reqParams, monitor: "" });
    }

    setReqParams((val) => ({ ...val, [name]: value }));
  };
  const handlePostNum = (e) => {
    setPostNum(e.target.value);
    if (e.target.value <= 0) {
      setPostNum("");
    }

    if (remainQuota < e.target.value) {
      setQuotaAlert(true);
    } else {
      setQuotaAlert(false);
    }
  };

  const applyFilter = (startDate, endDate) => {
    setStartNewDate(startDate);
    setEndNewDate(endDate);
  };

  const nextStep = (step) => {
    if (step === 2) {
      if (startDate < endDate) {
        setStep(2);
      } else {
        if (props?.isMonitorOption) {
          props?.setMonitorsListSnack({
            open: true,
            status: "error",
            msg: CheckValueLocale("error_time_msg", "", {}, intl),
          });
        } else {
          setTimeError(true);
          setTimeout(() => {
            setTimeError(null);
          }, 7000);
        }
      }
    } else {
      setStep(step);
    }
    calcRemaining();
  };

  useEffect(() => {
    const newEndDate = endNewDate - (24 - endTime) * 60 * 60 + 1;
    const newStartDate = startNewDate + startTime * 60 * 60;
    setEndDate(newEndDate);
    setStartDate(newStartDate);
  }, [startNewDate, endNewDate, endTime, startTime]);

  useEffect(() => {
    if (reqParams?.datasource == xplatformId) {
      if (reqParams?.monitor) {
        if (startDate < endDate) {
          getAvailableDataTwitter();
        } else {
          if (props?.isMonitorOption) {
            props?.setMonitorsListSnack({
              open: true,
              status: "error",
              msg: CheckValueLocale("error_time_msg", "", {}, intl),
            });
          } else {
            setTimeError(true);
            setTimeout(() => {
              setTimeError(null);
            }, 7000);
          }
        }
      }
    } else {
      setQuotaLimitMsg(null);
    }
  }, [
    reqParams?.monitor,
    reqParams?.datasource,
    startDate,
    endDate,
    xplatformId,
  ]);

  const getAvailableDataTwitter = async () => {
    var queryData = {
      monitor_model_id: reqParams?.monitor,
      product_id: reqParams?.platform,
      data_source_id: reqParams?.datasource,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      setLoadingData(true);
      const result =
        await CompaniesController.getAvailableDataTwitter(queryData);
      setActualCountDataTwitter(result?.data?.data?.actual_count || 0);
      setDuplicatesCountDataTwitter(result?.data?.data?.current_count || 0);
      if (result?.data?.msg) {
        setQuotaLimitMsg(true);
      } else {
        setQuotaLimitMsg(null);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenCreateModal = () => {
    if (props?.isMonitorOption) document.body.classList.add("modal-open-body");
    setStats({
      ...stats,
      openCreateModal: true,
      anchorEl: null,
    });
  };

  const handleCloseCreateModal = () => {
    if (props?.isMonitorOption) {
      document.body.classList.remove("modal-open-body");
      props?.handleCloseDropdown();
    }
    setStats({ ...stats, openCreateModal: false });
    setReqParams({ platform: "", datasource: "", monitor: "" });
    setPostNum("");
    setStep(1);
    setStartTime(0);
    setEndTime(23.5);
    setDefaultDateRange("last_7_days");
    setDuplicatesCountDataTwitter(0);
    setActualCountDataTwitter(0);
    setQuotaLimitMsg(null);
  };

  const getCompanyDataInsightsQuota = () => {
    CompaniesController.getCompanyDataInsightsQuota().then((result) => {
      var quotas = result?.data;
      for (var i = 0; i < quotas?.length; i++) {
        if (quotas[i]?.name === "SM") {
          setRemainNewsBlogs(
            quotas[i]?.quota?.news_blogs?.limit -
              quotas[i]?.quota.news_blogs?.used,
          );
          setRemainPosts(
            quotas[i]?.quota?.posts_limit?.limit -
              quotas[i]?.quota?.posts_limit?.used,
          );
        }
        if (quotas[i]?.name === "CXM") {
          setRemainInteractions(
            quotas[i]?.quota?.interactions?.limit -
              quotas[i]?.quota?.interactions?.used,
          );
        }
      }
    });
  };

  useEffect(() => {
    if (props?.isMonitorOption && stats?.openCreateModal)
      getCompanyDataInsightsQuota();
  }, [props?.isMonitorOption, stats?.openCreateModal]);

  const stepOne = (
    <div className="step-one">
      <Box className="text-field">
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("platform", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="historical-platform-select-label"
          fullWidth
          name="platform"
          value={reqParams.platform}
          onChange={updatePlatformSelect}
          displayEmpty
          className="create-historical-select"
          disabled={props?.isMonitorOption}
        >
          <MenuItem value="" disabled className="paltform-select-item">
            {CheckValueLocale("select_placeholder", "", {}, intl)}
          </MenuItem>
          {platforms?.map((paltform, i) => {
            return (
              <MenuItem
                value={paltform.attributes.id}
                className="paltform-select-item"
                id={`settings-historical-request-platform-${paltform?.attributes?.name ?? "name"}`}
              >
                {CheckValueLocale(paltform.attributes.name, "", {}, intl)}
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box className="text-field">
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("data_source_channel", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="report-platform-select-label"
          id="report-platform-simple-select"
          fullWidth
          name="datasource"
          value={reqParams?.datasource}
          onChange={updatePlatformSelect}
          displayEmpty
          disabled={
            datasources === undefined ||
            props?.monitorData?.attributes?.data_sources?.length === 1
          }
          className="create-historical-select"
        >
          <MenuItem value="" disabled className="paltform-select-item">
            {CheckValueLocale("select_placeholder", "", {}, intl)}
          </MenuItem>
          {datasources
            ?.filter((source) => {
              if (props?.isMonitorOption)
                return props?.monitorData?.attributes?.data_sources?.includes(
                  source?.attributes?.source,
                );
              else return true;
            })
            ?.map((datasource, i) => {
              let isIgKeyword =
                props?.isMonitorOption &&
                props?.monitorData?.attributes?.monitor_type_name ===
                  "KEYWORD" &&
                datasource?.attributes?.source === "INSTAGRAM";
              if (isIgKeyword) {
                return (
                  <Tooltip
                    key={i}
                    id="monitor-options-hr-item-tooltip"
                    placement="top"
                    arrow
                    title={
                      isIgKeyword
                        ? CheckValueLocale(
                            "historical_request_monitor_option_tooltip",
                            "",
                            {},
                            intl,
                          )
                        : ""
                    }
                  >
                    <Box id="menu-item-wrapper">
                      <MenuItem
                        value={datasource?.attributes?.id}
                        className={`paltform-select-item ${
                          isIgKeyword ? "disabled-grey-item" : ""
                        }`}
                        disabled={
                          datasource?.attributes?.source === "INTERCOM" ||
                          (props?.isMonitorOption &&
                            props?.monitorData?.attributes
                              ?.monitor_type_name === "KEYWORD" &&
                            datasource?.attributes?.source === "INSTAGRAM")
                        }
                        id={`settings-historical-request-datasource-${
                          datasource?.attributes?.source ?? "data-source-name"
                        }`}
                      >
                        <Box>
                          {getSocialIcon(datasource?.attributes?.source)}
                        </Box>
                        {CheckValueLocale(
                          datasource?.attributes?.source?.toLowerCase(),
                          "",
                          {},
                          intl,
                        )}
                      </MenuItem>
                    </Box>
                  </Tooltip>
                );
              } else
                return (
                  <MenuItem
                    key={i}
                    value={datasource?.attributes?.id}
                    className="paltform-select-item"
                    disabled={datasource?.attributes?.source === "INTERCOM"}
                    id={`settings-historical-request-datasource-${datasource?.attributes?.source ?? "data-source-name"}`}
                  >
                    <Box>{getSocialIcon(datasource?.attributes?.source)}</Box>
                    {CheckValueLocale(
                      datasource?.attributes?.source?.toLowerCase(),
                      "",
                      {},
                      intl,
                    )}
                  </MenuItem>
                );
            })}
        </Select>
        {datasourceLoad ? <CircularProgress className="input-loader" /> : null}
      </Box>

      {quotaLimitMsg && (
        <Alert severity={"error"}>
          <strong>
            {reqParams?.platform === smId
              ? CheckValueLocale(
                  "quota_limit_header_historical_SL",
                  "",
                  {},
                  intl,
                )
              : CheckValueLocale(
                  "quota_limit_header_historical_OM",
                  "",
                  {},
                  intl,
                )}
          </strong>
          <Box>
            {reqParams?.platform === smId
              ? CheckValueLocale("quota_limit_historical_SL", "", {}, intl)
              : CheckValueLocale("quota_limit_historical_OM", "", {}, intl)}
            <Link
              to={"/settings/subscription"}
              className="historical-link-subscription"
            >
              {CheckValueLocale("link_subscription", "", {}, intl)}
            </Link>
          </Box>
        </Alert>
      )}

      <Box className="text-field">
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("monitor_account", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="report-platform-select-label"
          id="report-platform-simple-select"
          fullWidth
          name="monitor"
          value={reqParams?.monitor}
          onChange={updatePlatformSelect}
          displayEmpty
          disabled={monitorsList === undefined || props?.isMonitorOption}
          className="create-historical-select"
        >
          {props?.isMonitorOption ? (
            <MenuItem
              className="paltform-select-item"
              value={props?.monitorData?.id}
            >
              {props?.monitorData?.attributes?.name}
            </MenuItem>
          ) : null}

          {!props?.isMonitorOption ? (
            <MenuItem value="" disabled className="paltform-select-item">
              {CheckValueLocale("select_placeholder", "", {}, intl)}
            </MenuItem>
          ) : null}
          {!props?.isMonitorOption
            ? monitorsList?.map((monitor, i) => {
                return (
                  <MenuItem
                    value={monitor.attributes.id}
                    className="paltform-select-item"
                    id={`settings-historical-request-monitor-${monitor?.attributes?.name ?? "name"}`}
                  >
                    {monitor?.attributes?.name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
        {monitorLoad ? <CircularProgress className="input-loader" /> : null}
      </Box>

      <Box className="text-field create-historical-calendar">
        <InputLabel className="body-input-label show-red-icon">
          {CheckValueLocale("dates_range", "", {}, intl)}
        </InputLabel>
        <DateRangePicker
          disablePast
          startDate={startNewDate}
          endDate={endNewDate}
          openCustomDateLeft={true}
          applyFilter={applyFilter}
          defaultDateRange={defaultDateRange}
          setDefaultDateRange={setDefaultDateRange}
          datePickerPosition="create-historical-calendar-datepicker-position"
          smallBoxCustom="create-historical-small-box-custom "
          className="create-historical-select reate-historical-select"
          oldestDate={oldestDate}
          id="date-range-picker-historical-request-center"
          monitorActiveTabId="historical-request"
        />
      </Box>
      <Box className="time-filter-create-historical">
        <Box className="text-field time-fild-create-historical">
          <InputLabel
            className="body-input-label show-red-icon"
            id="start-time-select-label"
          >
            {CheckValueLocale("start_time", "", {}, intl)}
          </InputLabel>
          <Select
            labelId="start-time-select-label"
            id="start-time-simple-select"
            name="time"
            fullWidth
            value={startTime}
            onChange={(e) => {
              setStartTime(e.target.value);
            }}
            className="create-historical-select"
            IconComponent={() => (
              <Box p={1}>
                <img src={Timer} alt="timer-icon" />
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {timeList?.map((time, i) => {
              return (
                <MenuItem
                  value={time.value}
                  className="paltform-select-item"
                  id={`settings-historical-request-time-${time.value}`}
                >
                  {time.name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box className="time-fild-create-historical">
          <InputLabel
            className="body-input-label show-red-icon"
            id="end-time-select-label"
          >
            {CheckValueLocale("end_time", "", {}, intl)}
          </InputLabel>
          <Select
            labelId="end-time-select-label"
            id="end-time-simple-select"
            name="time"
            fullWidth
            value={endTime}
            onChange={(e) => {
              setEndTime(e.target.value);
            }}
            className="create-historical-select"
            IconComponent={() => (
              <Box p={1}>
                <img src={Timer} alt="timer-icon" />
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {timeList?.map((time, i) => {
              return (
                <MenuItem
                  value={time.value}
                  id={`settings-historical-request-time-${time.value}`}
                >
                  {time.name}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>

      {reqParams?.datasource === xplatformId && quotaLimitMsg === null ? (
        <Box className="box-statistics-historical">
          <Box component={"h4"} className="statistics-historical-header">
            {CheckValueLocale("statistics_historical", "", {}, intl)}
          </Box>
          <Box component={"ul"}>
            <Box component={"li"}>
              <Box component="span" className="item-statistics_historical">
                <Box component="span">
                  {CheckValueLocale("estimated_Twitter", "", {}, intl)}
                </Box>
                <Box component="span" fontSize={16}>
                  {!loadingData
                    ? actualCountDataTwitter
                    : CheckValueLocale("loading_dotted", "", {}, intl)}
                </Box>
              </Box>
            </Box>
            <Box component={"li"}>
              <Box component="span" className="item-statistics_historical">
                <Box component="span">
                  {CheckValueLocale("estimated_duplicates", "", {}, intl)}
                </Box>
                <Box component="span" fontSize={16}>
                  {!loadingData
                    ? duplicatesCountDataTwitter
                    : CheckValueLocale("loading_dotted", "", {}, intl)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
      <Alert severity={"info"}>
        {CheckValueLocale("alert_time_historical", "", {}, intl)}
      </Alert>
    </div>
  );

  const stepTwo = (
    <div className="stepTwo">
      <Box className="shared-dialog-body">
        <p className="post-limit-msg-content">
          {CheckValueLocale("post_limit_msg", "", {}, intl)}
        </p>
      </Box>
      <div className="remaining-posts">
        <span>{CheckValueLocale("remaining_posts", "", {}, intl)}</span>{" "}
        <b>{commasAfterDigit(remainQuota)}</b>
      </div>
      <TextField
        variant="outlined"
        placeholder={CheckValueLocale("EX:30", "", {}, intl)}
        onChange={handlePostNum}
        className="create-historical-select create-historical-input"
        value={postNum}
        type="number"
        min="0"
      />
      {quotaAlert ? (
        <span className="hostorical-validation">
          {CheckValueLocale("quota_out", "", {}, intl)}
        </span>
      ) : null}

      {reqParams?.datasource === xplatformId ? (
        <Box className="estimated_data_Twitter">
          <Box>{CheckValueLocale("estimated_Twitter", "", {}, intl)}</Box>
          <Box component="span" fontSize={16}>
            {actualCountDataTwitter}
          </Box>
        </Box>
      ) : null}

      <div className="step-footer">
        <LucButton variant="outline" onClick={() => nextStep(1)}>
          {CheckValueLocale("back_historical", "", {}, intl)}
        </LucButton>

        <Box>
          <LucButton
            variant="flat"
            type="secondary"
            onClick={() => handleCloseCreateModal()}
            className="cancel-btn"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            onClick={() => createHistoricalRequest()}
            disabled={
              reqParams?.platform === "" ||
              reqParams?.datasource === "" ||
              reqParams?.monitor === "" ||
              postNum === "" ||
              quotaAlert === true
            }
          >
            {CheckValueLocale("request", "", {}, intl)}
          </LucButton>
        </Box>
      </div>
    </div>
  );
  const modalBody = (
    <div className="create-pop-body">
      <span className="step-span">
        {CheckValueLocale(
          "request_historical_step",
          "",
          {
            step: step,
          },
          intl,
        )}
      </span>
      {step === 1 ? stepOne : stepTwo}
    </div>
  );

  // Set pre-selected product id
  useEffect(() => {
    if (
      stats?.openCreateModal &&
      props?.isMonitorOption &&
      props?.monitorData &&
      platforms?.length > 0
    ) {
      platforms?.map((ele) => {
        if (ele?.attributes?.name?.toLowerCase() === "sm") {
          reqParams.platform = +ele?.attributes?.id;
          setReqParams({ ...reqParams, datasource: "" });
        }
      });
    }
  }, [
    stats?.openCreateModal,
    props?.isMonitorOption,
    props?.monitorData,
    platforms,
  ]);

  // Set pre-selected datasource in case of 1 datasource
  useEffect(() => {
    if (
      stats?.openCreateModal &&
      props?.isMonitorOption &&
      props?.monitorData &&
      reqParams?.platform !== "" &&
      props?.monitorData?.attributes?.data_sources?.length === 1 &&
      datasources?.length > 0
    )
      datasources?.map((item) => {
        if (
          item?.attributes?.source?.toLowerCase() ===
          props?.monitorData?.attributes?.data_sources[0]?.toLowerCase()
        ) {
          reqParams.datasource = +item?.attributes?.id;
          setReqParams({ ...reqParams });
        }
      });
  }, [
    stats?.openCreateModal,
    props?.isMonitorOption,
    props?.monitorData,
    datasources,
  ]);

  // Set pre-selected monitor/account
  useEffect(() => {
    if (
      stats?.openCreateModal &&
      props?.isMonitorOption &&
      props?.monitorData &&
      reqParams?.platform !== ""
    ) {
      reqParams.monitor = +props?.monitorData?.id;
      setReqParams({ ...reqParams });
    }
  }, [
    stats?.openCreateModal,
    props?.isMonitorOption,
    props?.monitorData,
    reqParams?.platform,
  ]);

  const createHistoricalRequestFlag = () => {
    CompaniesController?.createHistoricalRequestFlag().then((result) =>
      setCreateHistFlag(result?.data?.processing),
    );
  };

  useEffect(() => {
    if (props?.isMonitorOption) createHistoricalRequestFlag();
  }, [props?.isMonitorOption]);

  return (
    <div id="create-historical-request-div">
      {!props?.isMonitorOption && success && (
        <SnackBar
          open={success != null ? true : false}
          severity={successStatus}
          message={snackBarMsg}
          showCloseBtn={true}
        />
      )}

      {!props?.isMonitorOption && timeError && (
        <SnackBar
          open={timeError != null ? true : false}
          severity={"error"}
          message={CheckValueLocale("error_time_msg", "", {}, intl)}
        />
      )}

      {props?.isMonitorOption === true ? (
        <Tooltip
          id="monitor-options-hr-item-tooltip"
          placement="top"
          arrow
          title={
            isInstagramKeyword
              ? CheckValueLocale(
                  "historical_request_monitor_option_tooltip",
                  "",
                  {},
                  intl,
                )
              : ""
          }
        >
          <Box className="menu-item-wrapper">
            <MenuItem
              id={`monitor-menu-dropdown-historical-request-${
                props?.monitorData?.id ?? "monitor-item-id"
              }`}
              disableRipple
              onClick={() => handleOpenCreateModal()}
              disabled={createHistFlag || isInstagramKeyword}
            >
              <FontAwesomeIcon icon={faClockRotateLeft} width="24px" />
              {CheckValueLocale("historical_request", "", {}, intl)}
            </MenuItem>
          </Box>
        </Tooltip>
      ) : (
        <LucButton
          startIcon={<AddIcon />}
          onClick={() => handleOpenCreateModal()}
          disabled={props?.createHistFlag || props?.retryLoader === true}
        >
          {CheckValueLocale("new_historical_request", "", {}, intl)}
        </LucButton>
      )}

      {stats?.openCreateModal && (
        <PopupModal
          title={CheckValueLocale("new_historical_request", "", {}, intl)}
          body={modalBody}
          open={stats?.openCreateModal}
          close={handleCloseCreateModal}
          leftBtn={step === 1 ? CheckValueLocale("cancel", "", {}, intl) : null}
          rightBtn={
            step === 1 ? CheckValueLocale("next_step", "", {}, intl) : null
          }
          accept={nextStep}
          monitor_id={2}
          disabled={
            reqParams?.platform === "" ||
            reqParams?.datasource === "" ||
            reqParams?.monitor === "" ||
            loadingData ||
            quotaLimitMsg !== null
          }
          classeName={`create-historical-popup create-historical-pop-up-container ${step == 2 ? "step-two-content" : ""} actions-buttons-shared  `}
          modalStyle={step === 1 ? "form-style" : null}
          minWidth="600px"
        />
      )}
    </div>
  );
};
export default CreateHistorical;
