import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box } from "@mui/material";
import { getTimeZone, isEmptyObj } from "utils/helpers";
import { Link, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers/index";
import InteractionsController from "services/controllers/interactionsController";

import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu.js";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";

import gmail from "images/social-icons/gmail.svg";

import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import Luci from "pages/luci";
import { sanitize } from "dompurify";

const SingleEmailInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  let apiVars = "email/gmail";

  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "emails",
      url: `insights/email${
        hasDatasourceOnly === "true" ? `/gmail/${dataSourceId}` : ""
      }`,
    },
    {
      name: "gmail",
      url: "insights/email/gmail/" + dataSourceId,
    },
  ];
  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result.data)) {
        setConversation(result.data);
      }
    });
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyObj(conversation) ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="gmail" />
            <div className="single-intr">
              <Box className="single-intr-wrapper">
                <span className="single-intr__icon single-intr__icon--gmail">
                  <img src={gmail} alt="" />
                </span>
                <span className="single-intr__title">
                  {CheckValueLocale("gmail", "", {}, intl)}
                </span>

                <Box
                  className="interaction-list__footer single-int hide-sentiment-themes"
                  component="div"
                >
                  <ThemesMenu
                    data={conversation}
                    interactionId={interactionId}
                    trackerId={trackerId}
                    createdAt={conversation.created_at}
                    channelId={conversation.channel_id}
                    apiVars={apiVars}
                  />
                  <div className="vertical-line"></div>

                  <SentimentDropdown
                    sentiment={conversation.sentiment}
                    data={conversation}
                    interactionId={interactionId}
                    trackerId={trackerId}
                    createdAt={conversation.created_at}
                    channelId={conversation.channel_id}
                    apiVars={apiVars}
                  />
                </Box>
              </Box>
              <div className="single-intr__body">
                <div>
                  <Link
                    to={"/people/person/interaction/" + conversation.person_key}
                    className="single-intr__name"
                  >
                    {conversation.sender_name}
                  </Link>
                  <div className="single-intr-page__header row">
                    <p>
                      {moment
                        .unix(conversation.created_at)
                        .utc()
                        .format("MM/DD/YYYY, LT ")}
                      {getTimeZone() > 0
                        ? `(${CheckValueLocale(
                            "gmt",
                            "",
                            {},
                            intl,
                          )} +${getTimeZone()})`
                        : `(${CheckValueLocale(
                            "gmt",
                            "",
                            {},
                            intl,
                          )} -${getTimeZone()})`}
                    </p>
                  </div>

                  <h5>{conversation.sender_email_address}</h5>
                  <h4>{conversation.subject}</h4>
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        conversation.message
                          ?.replace(/(\r\n)+/g, "\r\n")
                          .replace(/(\n\r)+/g, "\r\n")
                          .replace(/\n+/g, "\n")
                          .replace(/\r+/g, "\r")
                          .trim(),
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleEmailInteraction;
