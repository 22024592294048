import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { IconButton, Menu, MenuItem, Snackbar, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import PopupModal from "components/popupModal";
import "./alert-list.scss";
import AlertController from "services/controllers/alertController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const AlertOptions = (props) => {
  const intl = useIntl();
  const {
    alertData,
    resetFormData,
    handleOpenForm,
    getAlertsUpdateData,
    getAlertData,
    getComanyEmails,
    alerts,
    page,
    setPage,
    setShowAlertActionMessage,
    setShowSnackBar,
    getAlerts,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [stats, setStats] = useState({
    openDeleteModal: false,
    openDropDownList: true,
    adminPermissions: true,
    openPauseResumeModal: false,
    modalTitle: "",
    modalBody: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    snackBarAction: "",
    snackBarSeverity: "",
  });
  const open = Boolean(anchorEl);

  window.addEventListener("scroll", (event) => {
    if (open) {
      handleClose();
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUpdateAlert = (id) => {
    resetFormData();
    handleOpenForm("editAlert");
    getAlertData();
    getAlertsUpdateData(id);
    getComanyEmails();
    setAnchorEl(null);
  };
  const [isRequestError, setIsRequestError] = useState(false);

  const handleOpenDeleteModal = () => {
    setStats({
      ...stats,
      openDeleteModal: true,
      modalTitle: "delete_title",
      modalBody: (
        <Box className="shared-dialog-body">
          <p>{`${CheckValueLocale(
            "delete_message",
            "",
            { name: alertData?.attributes?.name },
            intl,
          )}`}</p>
          <h2 className="sub-text-desc">
            {CheckValueLocale("deleting_msg", "", {}, intl)}
          </h2>
        </Box>
      ),

      modalRightBtn: "delete_confirm",
      modalLeftBtn: "cancel_btn",
    });
    setAnchorEl(false);
  };

  const handleDeleteAlert = (alertId) => {
    AlertController.deleteAlertsDataV2(alertId).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.data && res?.data?.status === 204) {
        if (alerts?.length === 1 && page > 1) {
          setPage(page - 1);
        } else {
          setPage(1);
        }
        getAlerts();
        setShowAlertActionMessage({
          severity: "success",
          message: "alert_delete_success",
        });
      } else {
        setShowAlertActionMessage({
          severity: "error",
          message: "delete_failed",
        });
      }
      setShowSnackBar(true);
      setStats({ ...stats, openDeleteModal: false });
    });
  };
  const handleCloseDeleteModal = () => {
    setStats({ ...stats, openDeleteModal: false });
  };
  return (
    <>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          onClick={handleClick}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="alert-action-dropdown"
      >
        <MenuItem
          onClick={() => handleUpdateAlert(alertData?.id)}
          disableRipple
          id={`alert-list-options-edit-${alertData?.id ?? "alert-date-id"}`}
        >
          <EditIcon />
          {CheckValueLocale("edit", "", {}, intl)}
        </MenuItem>
        <MenuItem
          onClick={handleOpenDeleteModal}
          disableRipple
          id={`alert-list-options-delete-${alertData?.id ?? "alert-date-id"}`}
        >
          <DeleteIcon />
          {CheckValueLocale("delete", "", {}, intl)}
        </MenuItem>
      </Menu>
      {stats?.openDeleteModal && stats?.openDropDownList ? (
        <PopupModal
          title={CheckValueLocale(stats?.modalTitle, "", {}, intl)}
          body={CheckValueLocale(stats?.modalBody, "", {}, intl)}
          leftBtn={CheckValueLocale(stats?.modalLeftBtn, "", {}, intl)}
          rightBtn={CheckValueLocale(stats?.modalRightBtn, "", {}, intl)}
          open={stats?.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={() => handleDeleteAlert(alertData?.id)}
          rightColor="error"
          warning
          leftBtnId="alerts-options-pop-up-cancel-left-btn"
          rightBtnId="alerts-options-pop-up-delete-right-btn"
          minWidth="600px"
        />
      ) : null}
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </>
  );
};

export default AlertOptions;
