import React, { useEffect, useState } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { CheckValueLocale, getTimeZone, isUserPermitted } from "utils/helpers";
import { Box, styled, Divider, Typography, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeh, faFrown, faSmile } from "@fortawesome/free-solid-svg-icons";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu.js";

const InteractionsListFooter = (props) => {
  let { obj, className } = props;
  const intl = useIntl();
  let date = obj?.CA_date
    ? obj?.CA_date
    : obj.channel_id === 2
      ? obj.created_at
      : obj.channel_id === 3
        ? obj.created_at
        : obj.messages === undefined
          ? obj.created_at
          : obj.messages[0].message_created_at;

  return (
    <Box className={`interaction-list__footer ${className}`} component="div">
      <Box>
        <Box className="date-format">
          {moment.unix(date)?.format("YYYY/MM/DD ")}
          <Typography component="span">at</Typography>
          {moment.unix(date)?.utc().format(" LT")}
          &nbsp;
        </Box>
        <Box className="time-zone-format">
          {getTimeZone() > 0
            ? `(${CheckValueLocale("gmt", "", {}, intl)} +${getTimeZone()})`
            : `(${CheckValueLocale("gmt", "", {}, intl)} -${getTimeZone()})`}
        </Box>
      </Box>
      {props.component === "google_my_business" && props.text === "" ? null : (
        <>
          <DividerVer orientation="vertical" />
          <ThemesMenu
            data={obj}
            themes={obj?.themes}
            subThemes={obj?.sub_themes}
            interactionId={obj?.isPublicChannel ? obj?.tweet_id : obj?.id}
            trackerId={obj?.tracker_id}
            createdAt={date}
            channelId={
              obj?.isPublicChannel ? obj?.CA_channel_id : obj?.channel_id
            }
            commentId={obj?.isPublicChannel ? obj?.tweet_id : obj?.id}
            mediaId={obj?.parent?.media_id}
            apiVars={props?.apiVars}
            account_id={
              obj?.isPublicChannel ? obj?.tracker_id : obj?.account_id
            }
            location_id={obj?.location_id}
            audioId={obj?.audio_id}
            lucidyaAudioId={obj?.lucidya_audio_id}
            datasourceName={props?.channelName}
          />
          <DividerVer orientation="vertical" />
          <SentimentDropdown
            sentiment={
              obj?.isPublicChannel ? obj?.CA_sentiment : obj?.sentiment
            }
            sentimentLabel={obj?.sentiment_label}
            data={obj}
            interactionId={obj?.id}
            trackerId={obj?.tracker_id}
            createdAt={date}
            channelId={obj?.channel_id}
            commentId={obj?.id}
            mediaId={obj?.parent?.media_id}
            account_id={obj?.account_id}
            location_id={obj?.location_id}
            isLatestInteractionEdit
            audioId={obj?.audio_id}
            lucidyaAudioId={obj?.lucidya_audio_id}
          />
        </>
      )}
    </Box>
  );
};

export default InteractionsListFooter;
const BoxWrapper = styled(Box)`
  background-color: #eef2f6;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 10px;
`;
const TypoText = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
`;
const TypoTextHead = styled(Typography)`
  color: #333b48;
  font-weight: 500;
  font-size: 12px;
  padding: 0;
  margin: 0;
`;
const DividerVer = styled(Divider)`
  color: #cbd5e1;
  width: 1px;
  height: 20px;
  margin: 0px 10px;
`;
