import { Box, Button, TextField, Divider, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import "./sideBar.scss";
import { CheckValueLocale, isEmptyObj, handlelUserRoles } from "utils/helpers";
import SetupFlow from "../../setup-flow/setupFlow";
import { useState, useEffect } from "react";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import filterIcon from "images/engagements-images/filter-icon.svg";
import CloseIcon from "images/engagements-images/close.svg";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import EngagementsController from "services/controllers/engagementsController";
import SnackBar from "components/snackBar";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import HeadingSidebar from "../../headingSidebar/headingSidebar";
import SwitchMenuList from "../../navigationSidebar/switchNavigationContent";
import RefreshIcon from "@mui/icons-material/Refresh";
import EngagementAnalyticsBanner from "../../engagementAnalytics/engagementAnalyticsBanner/engagementAnalyticsBanner";

const SideBarHeading = ({
  filters,
  setFilters,
  openFilters,
  setOpenFilters,
  filtersChangesCount,
  applyFilters,
  dates,
  setDates,
  reduxSelectedCard,
  allData,
  openNavigationMenu,
  setOpenNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  openSetupFlow,
  setOpenSetupFlow,
  openSetupFlowFirst,
  setOpenSetupFlowFirst,
  isDoneSetup,
  setIsDoneSetup,
  setOpenNewConversation,
  openNewConversation,
  samrtSortingNew,
  setSamrtSortingPopup,
}) => {
  const intl = useIntl();
  const [openSearch, setOpenSearch] = useState(false);
  const [openSortBy, setOpenSortBy] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [monitorsSelectedData, setMonitorsSelectedData] = useState([]);
  const [channelSelectedData, setChannelSelectedData] = useState([]);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    msg: "",
    state: "",
  });
  const [sortItems, setSortItems] = useState([
    "smart_sorting",
    "newest",
    "oldest",
    "first_response_time",
    "next_response_time",
    "unassigned_response_time",
    "time_to_complete",
  ]);
  const [hasSavedMonitorsFinished, setHasSavedMonitorsFinished] =
    useState(false);
  let isActiveFilters =
    allData?.filters?.data_sources?.length ||
    allData?.filters?.engagement_id?.length ||
    allData?.filters?.engagement_types?.length ||
    allData?.filters?.is_question ||
    allData?.filters?.min_followers ||
    allData?.filters?.sentiment?.length ||
    allData?.filters?.sub_themes?.length ||
    allData?.filters?.themes?.length ||
    allData?.filters?.usernames?.length ||
    allData?.filters?.verified_status ||
    (allData.monitors?.length &&
      (allData?.monitors ===
      [...monitorsSelectedData, ...channelSelectedData].join(",")
        ? false
        : true));

  // handle date filters
  const handleDatePicker = (startDate, endDate) => {
    if (dates?.startDate !== startDate || dates?.endDate !== endDate) {
      dates.startDate = startDate;
      dates.endDate = endDate;
      filters.page = 1;
      setFilters({
        ...filters,
      });
      setDates({ ...dates });
      if (isEmptyObj(reduxSelectedCard)) {
        applyFilters();
      }
    }
  };

  // handle search filters
  const handleSearch = (remove) => {
    if (remove) {
      setIsSearchFocused(true);
      setSearchInput("");
      if (filters?.keywords?.length) {
        filters.keywords = "";
        setFilters({ ...filters });
        applyFilters();
      }
    } else {
      if (filters?.keywords !== searchInput) {
        filters.keywords = searchInput;
        filters.page = 1;
        setFilters({ ...filters });
        applyFilters();
      }
    }
  };

  useEffect(() => {
    if (!window.location.href.includes("alert_id")) {
      EngagementsController.getSavedMonitors(
        window.localStorage.getItem("engagementsProductId"),
      ).then((data) => {
        setIsDoneSetup(data?.data?.engagements_setuped);
        setHasSavedMonitorsFinished(true);
      });
    }
  }, []);

  // this handle open popup to redirect to engagement setup
  useEffect(() => {
    if (!isDoneSetup) {
      setOpenSetupFlow(true);
      setOpenSetupFlowFirst(false);
    }
  }, [isDoneSetup]);

  // handle change sorting items locales
  useEffect(() => {
    if (allData?.filters?.engagement_status == "complete") {
      setSortItems([
        "smart_sorting",
        "newest_comp",
        "oldest_comp",
        "first_response_time",
        "next_response_time",
        "unassigned_response_time",
        "time_to_complete",
      ]);
      if (filters.sort_by == "newest") {
        filters.sort_by = "newest_comp";
        setFilters({ ...filters });
      } else if (filters.sort_by == "oldest") {
        filters.sort_by = "oldest_comp";
        setFilters({ ...filters });
      }
    } else {
      setSortItems([
        "smart_sorting",
        "newest",
        "oldest",
        "first_response_time",
        "next_response_time",
        "unassigned_response_time",
        "time_to_complete",
      ]);
      if (filters.sort_by == "newest_comp") {
        filters.sort_by = "newest";
        setFilters({ ...filters });
      } else if (filters.sort_by == "oldest_comp") {
        filters.sort_by = "oldest";
        setFilters({ ...filters });
      }
    }
  }, [allData?.filters?.engagement_status]);

  // handle change sorting items filters
  const sortByItem = (item) => {
    filters.page = 1;
    filters.sort_by = item;
    setFilters({ ...filters });
    applyFilters();
    if (item === "smart_sorting" && !samrtSortingNew) {
      setSamrtSortingPopup(true);
    }
  };
  return (
    <Box className="main-eng-header">
      <Box className="heading-wrapper inbox-main-header">
        <HeadingSidebar
          handleClickReload={() => {
            filters.page = 1;
            setFilters({ ...filters });
            applyFilters();
          }}
          handleSettings={() => setOpenSetupFlow(true)}
          setOpenNewConversation={setOpenNewConversation}
          openNewConversation={openNewConversation}
          openNavigationMenu={openNavigationMenu}
          setOpenNavigationMenu={setOpenNavigationMenu}
          navigationMenuStatus={navigationMenuStatus}
          setNavigationMenuStatus={setNavigationMenuStatus}
          showReload
          isDoneSetup={isDoneSetup}
          startDate={dates?.startDate}
          endDate={dates?.endDate}
          handleDatePicker={handleDatePicker}
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
          filtersChangesCount={filtersChangesCount}
        />
        {openSetupFlow &&
        handlelUserRoles("ENGAGEMENTS", "ENGAGEMENT_SETTINGS") ? (
          <SetupFlow
            openSetupFlowFirst={openSetupFlowFirst}
            filters={filters}
            setFilters={setFilters}
            setSnackBar={setSnackBar}
            setClose={() => setOpenSetupFlow(false)}
            monitorsSelectedData={monitorsSelectedData}
            channelSelectedData={channelSelectedData}
            setMonitorsSelectedData={setMonitorsSelectedData}
            setChannelSelectedData={setChannelSelectedData}
            applyFilters={applyFilters}
            isDoneSetup={isDoneSetup}
          />
        ) : null}
        {isDoneSetup && hasSavedMonitorsFinished ? (
          <EngagementAnalyticsBanner />
        ) : null}
      </Box>
      <SwitchMenuList
        openNavigationMenu={openNavigationMenu}
        setOpenNavigationMenu={setOpenNavigationMenu}
        navigationMenuStatus={navigationMenuStatus}
        setNavigationMenuStatus={setNavigationMenuStatus}
      />
      <Box className="search-date-picker-container">
        <Box>
          <Box className="search-container">
            <Box>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={isSearchFocused && !searchInput?.length}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                arrow
                placement="bottom"
                // id="search-engag-tooltip"
                title={CheckValueLocale("search_paragraph", "", {}, intl)}
              >
                <TextField
                  id="search-input"
                  placeholder={CheckValueLocale("search_keyword", "", {}, intl)}
                  value={searchInput}
                  inputRef={(input) =>
                    input && isSearchFocused && input.focus()
                  }
                  onChange={(e) => setSearchInput(e.target.value)}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && handleSearch();
                  }}
                  InputProps={{
                    endAdornment: (
                      <Box className="search-icons">
                        {searchInput?.length ? (
                          <Tooltip
                            title={CheckValueLocale("clear", "", {}, intl)}
                            arrow
                            placement="bottom"
                          >
                            <img
                              src={CloseIcon}
                              onClick={() => {
                                handleSearch(true);
                              }}
                            />
                          </Tooltip>
                        ) : null}
                        <Tooltip
                          title={CheckValueLocale("search", "", {}, intl)}
                          arrow
                          placement="top"
                        >
                          <SearchIcon
                            className={isSearchFocused && "is-focused"}
                            onClick={() => handleSearch()}
                          />
                        </Tooltip>
                        <Divider orientation="vertical" />
                      </Box>
                    ),
                  }}
                  inputProps={{
                    sx: {
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                />
              </Tooltip>
            </Box>
            <Tooltip
              title={CheckValueLocale("inbox_refresh", "", {}, intl)}
              arrow
              placement="top"
            >
              <RefreshIcon
                className="refrsh-icon"
                onClick={() => {
                  filters.page = 1;
                  setFilters({ ...filters });
                  applyFilters();
                }}
              />
            </Tooltip>
            <Box
              id="engagements-sort-by-btn"
              className={`icon-container main-sort-by active ${
                openSortBy && "opened"
              }`}
              onClick={() => setOpenSortBy(!openSortBy)}
            >
              <Tooltip
                title={CheckValueLocale("sort_by", "", {}, intl)}
                placement="top"
                arrow
              >
                <SortIcon />
              </Tooltip>

              {openSortBy ? (
                <ClickAwayListener onClickAway={() => setOpenSortBy(false)}>
                  <Box className="sort-items-container">
                    {sortItems.map((item, index) => {
                      return (
                        <>
                          {!index || index == 3 ? (
                            <Box className="sort-item  sort-items-category">
                              {CheckValueLocale(
                                !index ? "sort" : "sort_sla",
                                "",
                                {},
                                intl,
                              )}
                            </Box>
                          ) : null}
                          <Box
                            key={index}
                            className={`sort-item ${
                              filters?.sort_by === item && "selected"
                            }`}
                            onClick={() => sortByItem(item)}
                          >
                            {CheckValueLocale(item, "", {}, intl)}
                            {item === "smart_sorting" && !samrtSortingNew ? (
                              <Typography
                                variant="caption"
                                className="sort-new"
                              >
                                {CheckValueLocale("new_item", "", {}, intl)}
                              </Typography>
                            ) : null}
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </ClickAwayListener>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.state}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(snackBar?.msg, "", {}, intl)}
        handleClose={() => setSnackBar({ open: false, msg: "", title: "" })}
      />
    </Box>
  );
};
export default SideBarHeading;
