import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box, Divider, Typography } from "@mui/material";
import { CheckValueLocale, getTimeZone, isEmptyObj } from "utils/helpers";
import { useParams } from "react-router-dom";

import InteractionsController from "services/controllers/interactionsController";

import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu.js";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faComment, faShare } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";

import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import MySVG from "./linkedInIcons";
import SnackBar from "components/snackBar";
import Luci from "pages/luci";
const SingleLinkedInInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;
  const [openProfile, setOpenProfile] = useState(false);
  const intl = useIntl();
  const [conversation, setConversation] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);

  let apiVars = "social_media/linkedin/public";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  let timeZone =
    getTimeZone() > 0
      ? `(${CheckValueLocale("gmt", "", {}, intl)} +${getTimeZone()})`
      : `(${CheckValueLocale("gmt", "", {}, intl)} -${getTimeZone()})`;
  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "social_media",
      url: `insights/social-media${
        hasDatasourceOnly === "true" ? `/linkedin-channel/${dataSourceId}` : ""
      }`,
    },
    {
      name: "LinkedIn",
      url: "insights/social-media/linkedin-channel/" + dataSourceId,
    },
  ];

  useEffect(() => {
    setPageNumber(pageNumber + 1);
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
      pageNumber,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result?.data)) {
        if (result?.data) {
          setConversation(result.data);
          setSuccess(true);
        }
        setLoading(false);
        setSuccess(false);
      }
    });
  }, []);
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: conversation?.created_by,
            user_name: conversation?.created_by,
          }}
          dataSourceName={"LINKEDIN"}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {loading ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="linkedin" />
            {isEmptyObj(conversation) ? (
              <>
                <SnackBar
                  open={success != null ? true : false}
                  severity={"error"}
                  handleClose={() => {
                    setSuccess(null);
                  }}
                  message={CheckValueLocale(
                    "please_wait_and_try_again_later",
                    "",
                    {},
                    intl,
                  )}
                  title={CheckValueLocale("failed_error", "", {}, intl)}
                />
              </>
            ) : (
              <div className="single-intr">
                <Box className="single-intr-wrapper">
                  <div className="linkedin-wrapper">
                    <Box
                      onClick={handleSideBar}
                      className={`user-single-header hover-show`}
                    >
                      <span className="single-intr__icon single-intr__icon--linkedin">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </span>
                      <span className="linkedin-created-by">
                        {conversation?.created_by}
                      </span>
                    </Box>
                    <Divider
                      orientation="vertical"
                      className="vertical-line-list-header"
                    />
                    <Typography className="interaction-list-type">
                      {CheckValueLocale("type_comment", "", {}, intl)}
                    </Typography>
                  </div>
                  <Box
                    className="interaction-list__footer single-int"
                    component="div"
                  >
                    <ThemesMenu
                      data={conversation}
                      themes={conversation?.themes}
                      subThemes={conversation?.sub_themes}
                      interactionId={interactionId}
                      trackerId={trackerId}
                      createdAt={conversation?.created_at}
                      channelId={conversation?.channel_id}
                      commentId={conversation?.id}
                      apiVars={apiVars}
                    />
                    <div className="vertical-line"></div>
                    <SentimentDropdown
                      sentiment={conversation?.sentiment}
                      data={conversation}
                      interactionId={interactionId}
                      trackerId={trackerId}
                      createdAt={conversation?.created_at}
                      channelId={conversation?.channel_id}
                      commentId={conversation?.id}
                      apiVars={apiVars}
                    />
                  </Box>
                </Box>
                <div className="single-intr__body linkedin-body">
                  <div className="single-intr-wrapper">
                    <p className="linkedin-text">{conversation?.text}</p>
                    <a
                      href={conversation?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={faExternalLink}
                        className="linkedin-external-link"
                      />
                    </a>
                  </div>
                  <div className="tweet-meta box-image">
                    {/* commented until engine continue work next sprint */}
                    {/* <div className="linkedin-reactions">
                    <span>
                      <img src={MySVG.like} alt="" />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <img src={MySVG.celebrate} alt="" />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <img src={MySVG.support} alt="" />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <img src={MySVG.love} alt="" />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <img src={MySVG.insightful} alt="" />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <img src={MySVG.curios} alt="" />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <img src={MySVG.funny} alt="" />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <FontAwesomeIcon icon={faComment} />
                      {conversation.total_comments}
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div>
                  <div className="linkedin-reactions">
                    <span>
                      <FontAwesomeIcon icon={faShare} />
                    </span>
                    <div>٣٤٢٤٢٣</div>
                  </div> */}
                    <div className="linkedin-reactions">
                      <p>
                        {moment
                          .unix(conversation?.created_at)
                          .utc()
                          .format("MM/DD/YYYY, LT ")}
                        {timeZone}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleLinkedInInteraction;
