import {
  Box,
  Container,
  Typography,
  Grid,
  Stack,
  Avatar,
  Tooltip,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import "./viewUser.scss";
import { ViewUserHeader } from "./components/viewUserHeader";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import CircularLoading from "components/circularProgress";
import CompaniesController from "services/controllers/companiesController";
import { CheckValueLocale, getSortedArray } from "utils/helpers";
import ViewUserMainCard from "./components/ViewUserMainCard";
import SnackBar from "components/snackBar";
import ProductsPermissions from "../addNewUser/componants/productsPermissions/productsPermissions";
import { AdvancedPermissions } from "./components/advancedPermissions";
import { ViewUserTabs } from "./components/ViewUserTabs";
import ActivityLog from "../userActivityLog/activityLog";

export const ViewUser = () => {
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const intl = useIntl();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    mail: "",
    phone: "",
    job_title: "",
    Valid: false,
  });
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [selectedPermissions, setSelectedPermissions] = useState({
    general_setting: "moderator",
    social_listening: "moderator",
    channel_analytics: "moderator",
    surveys: "moderator",
    audience: "moderator",
    engagement: "no_acc",
    lucidya_api: "moderator",
  });

  const [selectedAccessData, setSelectedAccessData] = useState({
    engagement_features: [],
    accounts_access: [],
    monitors_access: [],
    channels_access: [],
  });

  const [collapseNumber, setCollapseNumber] = useState([]);

  const getUserView = (id) => {
    setIsLoading(true);
    CompaniesController.getUserView(id).then((res) => {
      //userData?
      if (!res?.errorMsg) {
        setUserData({
          id: id,
          name: `${res?.data?.included?.[0]?.attributes?.first_name?.toString()} ${res?.data?.included?.[0]?.attributes?.last_name?.toString()}`,
          first_name:
            res?.data?.included?.[0]?.attributes?.first_name?.toString(),
          last_name:
            res?.data?.included?.[0]?.attributes?.last_name?.toString(),
          mail: res?.data?.data?.attributes?.email?.toString(),
          phone: res?.data?.included?.[0]?.attributes?.phone_number?.toString(),
          job_title:
            res?.data?.included?.[0]?.attributes?.job_title?.toString(),
          Valid: res?.data?.included?.[0]?.attributes?.status?.toString(),
          status: res?.data?.data?.attributes?.status?.toString(),
          avatar: res?.data?.data?.attributes?.avatar,
          teams: res?.data?.data?.attributes?.user_teams,
          languages: res?.data?.data?.attributes?.user_languages,
          skills: res?.data?.data?.attributes?.user_skills,
          permissions: res?.data?.data?.attributes?.permissions,
          dialects: res?.data?.data?.attributes?.user_dialects,
          themes: res?.data?.data?.attributes?.user_themes,
          topics: res?.data?.data?.attributes?.topics_monitors,
          segments: res?.data?.data?.attributes?.user_segments,
          working_time: res?.data?.data?.attributes?.user_working_time,
          sentiments: res?.data?.data?.attributes?.user_sentiments,
          hasEngagements: res?.data?.data?.attributes?.permissions?.some(
            (permission) => permission?.product === "ENGAGEMENTS",
          ),
        });

        //permissions
        let productNames = {
          GENERAL: "general_setting",
          CXM: "channel_analytics",
          SM: "social_listening",
          SURVEY: "surveys",
          CDP: "audience",
          ENGAGEMENTS: "engagement",
          PUBLIC_API: "lucidya_api",
          AI_API: "lucidya_api",
        };
        let roleMapper = {
          product_admin: "manager",
          viewer: "viwer",
          user: "moderator",
          team_leader_egag: "team_leader",
          agent_egag: "agent_egagment",
          no_access_egag: "no_acc",
        };
        let selectedObj = {};
        res?.data?.data?.attributes?.permissions?.map((item) => {
          selectedObj[productNames[item?.product]] =
            roleMapper[
              item?.product == "ENGAGEMENTS" ? `${item?.role}_egag` : item?.role
            ];
        });
        if (!selectedObj?.engagement) {
          selectedObj.engagement = "no_acc";
        }
        setSelectedPermissions({ ...selectedObj });

        //tables integration
        if (
          res?.data?.data?.attributes?.engagements_permissions
            ?.engagement_features?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.engagement_features?.map(
            (row, i) => {
              selectedAccessData.engagement_features[i] = [
                row?.feature_name,
                ...getSortedArray(
                  row?.feature_name == "SAVED_REPLIES"
                    ? [
                        "FULL_ACCESS",
                        "CREATE_SAVED_REPLY",
                        "DELETE_SAVED_REPLY",
                      ]
                    : [
                        "FULL_ACCESS",
                        "INBOX",
                        "PUBLISH",
                        "SL_ENGAGEMENT",
                        "OC_ENGAGEMENT",
                      ],
                  row?.feature_permissions?.filter(
                    (el) => el !== "FULL_ACCESS",
                  ),
                ),
              ];
            },
          );
        }

        if (
          res?.data?.data?.attributes?.engagements_permissions?.accounts_access
            ?.data?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.accounts_access?.data?.map(
            (row, i) => {
              if (row?.attributes?.access_permissions?.length) {
                selectedAccessData.accounts_access[i] = [
                  { ...row?.attributes },
                  ...getSortedArray(
                    ["FULL_ACCESS", "REPLY", "PUBLISH"],
                    row?.attributes?.access_permissions?.filter(
                      (el) => el !== "FULL_ACCESS",
                    ),
                  ),
                ];
              }
            },
          );
        }

        if (selectedAccessData.accounts_access?.length) {
          collapseNumber?.push("accounts_access");
        }
        if (
          res?.data?.data?.attributes?.engagements_permissions?.monitors_access
            ?.data?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.monitors_access?.data?.map(
            (row, i) => {
              selectedAccessData.monitors_access[i] = { ...row?.attributes };
            },
          );
          collapseNumber?.push("monitors_access");
        }
        if (
          res?.data?.data?.attributes?.engagements_permissions?.channels_access
            ?.data?.length
        ) {
          res?.data?.data?.attributes?.engagements_permissions?.channels_access?.data?.map(
            (row, i) => {
              selectedAccessData.channels_access[i] = { ...row?.attributes };
            },
          );
          collapseNumber?.push("channels_access");
        }
        setCollapseNumber([...collapseNumber]);
        setSelectedAccessData({ ...selectedAccessData });
      }

      setIsLoading(false);
    });
  };
  useEffect(() => {
    getUserView(id);

    const search = history?.location?.search;
    let snackbarSuccessMessage;
    if (search && search?.includes("status=added_successfully")) {
      snackbarSuccessMessage = "user_added_succ";
    } else if (search && search?.includes("status=updated_successfully")) {
      snackbarSuccessMessage = "team_edits_saved_successfully";
    }

    if (snackbarSuccessMessage) {
      // show the message and then remove it from the url
      setSnackBar({
        message: snackbarSuccessMessage,
        severity: "success",
        openSnackBar: true,
      });
      window.history.pushState({}, "", window.location.pathname);
    }
  }, []);

  const handleCloseSnackBar = () => {
    setSnackBar({
      openSnackBar: false,
    });
  };

  if (isLoading) return <CircularLoading />;
  return (
    <Container maxWidth="xl" className="view-user-container">
      <ViewUserHeader user={userData} id={id} getUser={getUserView} />
      {/* UserBox */}
      <ViewUserMainCard userData={userData} />
      {/*user engagement info box  */}
      <ViewUserTabs userData={userData} />
      <Box className="main-view-user">
        <Box className="main-edit-user-card">
          <Typography className="edit-sec-tit">
            {CheckValueLocale("prod_permissions", "", {}, intl)}
          </Typography>
          <Box className="edit-sec-container">
            <ProductsPermissions
              stepTwoData={selectedPermissions}
              setStepTwoData={setSelectedPermissions}
              isView={true}
            />
          </Box>
        </Box>
        <Box className="main-activity-user-card">
          <Typography variant="h3">
            {CheckValueLocale("user_activity_log", "", {}, intl)}
          </Typography>
          <Box className="edit-sec-container">
            <ActivityLog isUserActivityLog userData={userData} />
          </Box>
        </Box>
      </Box>
      {userData?.hasEngagements &&
      (collapseNumber?.length ||
        selectedAccessData?.engagement_features?.length) ? (
        <AdvancedPermissions
          selectedAccessData={selectedAccessData}
          collapseNumber={collapseNumber}
        />
      ) : null}
      <SnackBar
        open={snackBar?.openSnackBar}
        severity={snackBar?.severity}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};
