import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/pro-regular-svg-icons";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { useIntl } from "react-intl";

const SaveOrderContent = ({
  editLiveDashboardHandler,
  isActionLoading,
  setOrderFlag,
}) => {
  const intl = useIntl();
  return (
    <Box className="save-order-wrapper">
      <LucButton
        type="primary"
        variant="filled"
        onClick={editLiveDashboardHandler}
        id="lang-live-dashboard-btn"
        startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
        loading={isActionLoading}
      >
        {CheckValueLocale("save_changes", "", {}, intl)}
      </LucButton>
      <LucButton
        type="secondary"
        variant="Flat"
        onClick={() => setOrderFlag(false)}
        id="lang-live-dashboard-btn"
        startIcon={<FontAwesomeIcon icon={faXmark} />}
      >
        {CheckValueLocale("discard", "", {}, intl)}
      </LucButton>
    </Box>
  );
};
export default SaveOrderContent;
