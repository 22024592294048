import { useWidgetName } from "../../../hooks/useWidgetName";

const widgetsMapping = {
  Tiktok__Single_Source__top_videos: "top_videos",
  Tiktok__Single_Source__comments_overtime: "comments_volume",
  Tiktok__Single_Source__volume_overtime: "video_volume",
  Tiktok__Single_Source__follower_growth_overtime: "follower_growth_overtime",
  Tiktok__Single_Source__account_author_activity: "account_authors_activity",
  Tiktok__Single_Source__videos_interactions: "videos_interactions",
  tiktok__Single_Source__themes_and_sub_themes: "comments_themes",
  Tiktok__Single_Source__top_keywords: "top_keywords",
  Tiktok__Single_Source__sentiment_analysis: "comments_sentiment_analysis",
  Tiktok__Single_Source__top_hashtags: "top_hashtags",
  Tiktok__Single_Source__gender_distribution:
    "comments_comments_gender_distribution",
  Tiktok__Single_Source__top_languages: "comments_comments_top_languages",
  Tiktok__Single_Source__associated_topics: "comments_associated_topics",
  Tiktok__Single_Source__top_engagers: "top_engagers",
  Tiktok__Single_Source__top_dialects: "dialects_subdialects",
  Tiktok__Single_Source__customer_care_performance: "customer_care_performance",
};

export const TiktokOmniRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  const widgetKey = widgetsMapping[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      widgetKey,
      response?.monitor_id,
      "TIKTOK",
      response?.eventData,
    );
  else if (stateKey)
    handleWidgetResponse(
      stateKey,
      response?.monitor_id,
      "TIKTOK",
      response?.eventData,
    );
};
