import { Box } from "@mui/material";
import NoDataFound from "components/no-Data/noDataFound";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import FetchingData from "../../components/fetchingData/fetchingData";
import "../echarts/echarts.scss";
import LineChart from "../echarts/lineChart.js";
import WidgetHeader from "./widgetHeader.js";
import ChartStatsWidget from "./customStatisctics/chartStatsWidget";
import ChartDetailsWidget from "./customStatisctics/chartDetailsWidget";

const checkValue = (data) => {
  if (_.isEmpty(data) || data === undefined) {
    return false;
  }
  return data.reduce((total, current) => (total += current.value), 0) > 0;
};

const LineChartWidget = (props) => {
  const intl = useIntl();
  const [dataForChart, setDataForChart] = useState(props.data);
  const [dataChartValue, setDataChartValue] = useState("");
  const [duration, setDuration] = useState();
  const getChartMeta = (data, duration) => {
    setDataForChart(data);
    setDuration(duration);
  };
  useEffect(() => {
    getChartMeta(props.data);
  }, [props.data]);
  let xAxisData = [],
    seriesData = [],
    zoomPercentage = 0;

  if (
    (dataForChart &&
      (checkValue(dataForChart) ||
        props.title === "posts_volume" ||
        props.title === "comments_volume")) ||
    props.title === "posts_volume_kw" ||
    props.title === "volume_overtime"
  ) {
    if (
      ((duration === undefined || duration === "day") &&
        props.quickInspectData === undefined) ||
      props.title === "comments_daily_volume" ||
      props.title === "posts_daily_volume"
    ) {
      xAxisData = dataForChart.map((data) =>
        typeof data.name === "number" || "string"
          ? moment.unix(Number(data.name)).utc().format("YYYY-MM-DD")
          : moment
              .unix(Number(moment(data.name.split(" ")[0]).unix()))
              .format("YYYY-MM-DD"),
      );
    } else {
      xAxisData = dataForChart.map((data) => data.name);
    }
    seriesData =
      dataChartValue.length > 0 ? [] : dataForChart.map((data) => data.value);

    // finding zoom percentage if data length more then 30
    if (dataForChart.length > 30) {
      zoomPercentage = Math.floor(100 - (10 / dataForChart.length) * 100);
    }
  } else {
    seriesData = [0];
    xAxisData.push(moment().format("YYYY-MM-DD"));
  }

  const handleDataChartValue = (e, value) => {
    if (dataChartValue === "") {
      setDataChartValue(value);
    } else {
      setDataChartValue("");
    }
  };

  const widgetSize = props.showChartDetailsWidget ? "increase-size" : "";

  return (
    <div className={`chart ${widgetSize}`} id={props.title}>
      <WidgetHeader
        showGroupBy={props?.showGroupBy && checkValue(dataForChart)}
        title={props.title}
        titleToolTip={props.titleToolTip}
        showAI={props.showAI}
        showDownloadIcon={props.showDownloadIcon}
        chartId={props.title}
        bigImg={props.bigImg}
        showFollowersGrowth={props.showFollowersGrowth}
        totalLabel={
          _.isEqual(
            props.lineChartPreLoaderDataSources?.sort(),
            props.preLoaderTrackerDataSources?.sort(),
          ) !== true
            ? undefined
            : props.totalLabel
        }
        totalValue={
          _.isEqual(
            props.lineChartPreLoaderDataSources?.sort(),
            props.preLoaderTrackerDataSources?.sort(),
          ) !== true
            ? undefined
            : props.totalValue
        }
        data={props.data}
        getChartMeta={getChartMeta}
        iconAfterTitle={props.iconAfterTitle}
        labelColor={props.labelColor}
        //here it is used for custom labelss
        handleDataChartValue={handleDataChartValue}
        dataChartValue={dataChartValue}
        showReportIcon={props.showReportIcon}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard}
        monitorData={props?.monitorData}
      />
      {_.isEqual(
        props.lineChartPreLoaderDataSources?.sort(),
        props.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <Box>
            {/* totalLabel= */}
            {_.isEqual(
              props?.lineChartPreLoaderDataSources?.sort(),
              props?.preLoaderTrackerDataSources?.sort(),
            ) !== true
              ? undefined
              : props.totalLabel}
            {/* totalValue= */}
            {_.isEqual(
              props?.lineChartPreLoaderDataSources?.sort(),
              props?.preLoaderTrackerDataSources?.sort(),
            ) !== true
              ? undefined
              : props?.totalValue}
          </Box>
          <FetchingData />
        </>
      ) : dataForChart?.every((item) => item?.value == 0) ? (
        <>
          <NoDataFound />
        </>
      ) : (
        <>
          {props.showChartDetailsWidget && (
            <ChartDetailsWidget
              statsDetailsData={props.statsDetailsData}
              redirectTo={props.redirectTo}
            />
          )}
          {props.showChartStatsWidget && (
            <ChartStatsWidget statsData={props.statsData} />
          )}
          <LineChart
            zoomPercentage={zoomPercentage}
            xAxisData={xAxisData}
            seriesData={seriesData}
            toolTipLabel={props.toolTipLabel}
            channelId={props.channelId}
            //showBarChart={props.showBarChart}
            handleOpenQuickInspect={props.handleOpenQuickInspect}
            inspectorName={props.inspectorName}
            haveData={props.haveData}
            color={props.lineColor ? props.lineColor : ["#2AAAE2"]}
            duration={duration}
            checkSymbolZize={props?.checkSymbolZize}
          />
        </>
      )}
    </div>
  );
};

export default LineChartWidget;
