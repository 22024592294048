import React, { useEffect, useState } from "react";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";

const CustomDashboardTableWidget = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);
  const [widgetFlags, setWidgetFlags] = useState({});

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "posts_top_hash_tags":
        case "comments_top_hash_tags":
          setData(dataState?.["top_hashtags"]);
          break;

        case "posts_top_keywords":
        case "comments_top_keywords":
          setData(dataState?.["top_keywords"]);
          break;

        case "top_engagers":
          if (
            widget?.dashboard_page_name === "TWITTER_PRIVATE" ||
            widget?.dashboard_page_name === "INSTAGRAM_PRIVATE" ||
            widget?.dashboard_page_name === "FACEBOOK_PRIVATE"
          ) {
            setData(dataState?.["top_engaged_authors"]);
          } else setData(dataState?.[widget?.widget_name]);
          break;

        case "comments_top_engagers":
          if (widget?.dashboard_page_name === "INSTAGRAM_PUBLIC") {
            setData(dataState?.["top_engagers"]);
          } else
            setData(
              dataState?.[widget?.widget_name] || dataState?.["top_engagers"],
            );
          break;

        case "top_authors":
          if (widget?.monitor_type?.name === "MANAGED_ACCOUNT") {
            setData(dataState?.["top_engagers"]);
          } else setData(dataState?.[widget?.widget_name]);
          break;

        case "comments_top_influencers":
          setData(
            dataState?.["top_influencers"] || dataState?.[widget?.widget_name],
          );
          break;

        default:
          setData(dataState?.[widget?.widget_name]);
          break;
      }

      // This switch case for table widget flags only
      switch (widget?.widget_name) {
        case "comments_top_keywords":
        case "posts_top_keywords":
        case "top_keywords":
          if (
            widget?.dashboard_page_name === "INSTAGRAM_PRIVATE" ||
            widget?.dashboard_page_name === "TWITTER_PRIVATE"
          ) {
            setWidgetFlags({
              leftTitle: "keyword",
              rightTitle: "number_of_conversations",
              keywords: true,
              wordsList: true,
            });
          } else if (widget?.dashboard_page_name === "LINKEDIN_PUBLIC") {
            setWidgetFlags({
              leftTitle: "keyword",
              rightTitle: "interactions",
              keywords: true,
              wordsList: true,
            });
          } else
            setWidgetFlags({
              leftTitle: "keyword",
              rightTitle: "number_of_posts",
              keywords: true,
              wordsList: true,
            });
          break;

        case "comments_top_hash_tags":
        case "posts_top_hash_tags":
        case "top_hashtags":
          setWidgetFlags({
            leftTitle: "hashtags",
            rightTitle: "number_of_posts",
            wordsList: true,
            hashtags: true,
          });
          break;

        case "comments_top_engagers":
          setWidgetFlags({
            leftTitle: "users",
            rightTitle: "engagements",
            itemsList: true,
            circularPP: true,
          });
          break;

        case "top_engagers":
          if (widget?.dashboard_page_name === "TIKTOK_PUBLIC") {
            setWidgetFlags({
              leftTitle: "username",
              rightTitle: "engagements",
              itemsList: true,
              isTiktok: true,
            });
          } else if (widget?.dashboard_page_name === "LINKEDIN_PUBLIC") {
            setWidgetFlags({
              leftTitle: "users",
              rightTitle: "interactions",
              itemsList: true,
              isTiktok: true,
            });
          } else if (
            widget?.dashboard_page_name === "INSTAGRAM_PRIVATE" ||
            widget?.dashboard_page_name === "TWITTER_PRIVATE"
          ) {
            setWidgetFlags({
              leftTitle: "users",
              rightTitle: "interactions",
              itemsList: true,
              circularPP: true,
            });
          } else
            setWidgetFlags({
              leftTitle: "users",
              rightTitle: "engagements",
              itemsList: true,
              circularPP: true,
            });
          break;

        case "top_influencers":
        case "comments_top_influencers":
        case "comments_top_verified_engagers":
        case "top_verified_engagers":
          setWidgetFlags({
            leftTitle: "users",
            rightTitle: "followers",
            itemsList: true,
            circularPP: true,
          });
          break;

        case "comments_top_engagers":
        case "top_authors":
          setWidgetFlags({
            leftTitle: "username",
            rightTitle: "number_of_posts",
            wordsList: true,
          });
          break;

        case "top_sites":
          setWidgetFlags({
            leftTitle: "links",
            rightTitle: "reach",
            wordsList: true,
            links: true,
          });
          break;

        case "top_engaged_sites":
          setWidgetFlags({
            leftTitle: "links",
            rightTitle: "posts",
            wordsList: true,
            links: true,
          });
          break;

        default:
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  return (
    <TableWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={titleTooltip || ""}
      leftColomnTitle={widgetFlags?.leftTitle}
      rightColomnTitle={widgetFlags?.rightTitle}
      wordsList={widgetFlags?.wordsList}
      itemsList={widgetFlags?.itemsList}
      links={widgetFlags?.links}
      keywords={widgetFlags?.keywords}
      hashtags={widgetFlags?.hashtags}
      circularPP={widgetFlags?.circularPP}
      isTiktok={widgetFlags?.isTiktok}
      data={data || []}
      showDownloadIcon
      bigImg
      tablePagination
      count={data?.length || 0}
      preLoaderTrackerDataSources={
        socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
          monitorId
        ]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ] || []
      }
      tableChartPreLoaderDataSources={[monitorId] || []}
      isCustomDashboard
      monitorData={{
        name:
          dashboardInfo?.product === "cxm"
            ? widget?.account_identifier
            : widget?.monitor_name,
        type:
          dashboardInfo?.product === "cxm"
            ? widget?.data_category
            : widget?.monitor_type?.name,
        datasource: widget?.is_dm
          ? `${widget?.data_source}_private`
          : widget?.data_source,
      }}
    />
  );
};

export default CustomDashboardTableWidget;
