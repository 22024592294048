import { useState } from "react";
import { Menu, MenuItem, Button, Box, Tooltip } from "@mui/material";
import { PlayArrow, Edit, Pause } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PopupModal from "components/popupModal.js";

//Locals
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import "./optionsDropDown.scss";
import {
  handlelUserRoles,
  handleActiveFeature,
  CheckValueLocale,
} from "../../../../../utils/helpers/index.js";
import MonitorsController from "../../../../../services/controllers/monitorsController";
import CircularLoading from "components/circularProgress";
import UserAccount from "services/controllers/userAccountController";
import Services from "services/api/Services.js";
import { useHistory } from "react-router-dom";
import LiveDashboardModel from "shared/liveDashboardModel/liveDashboardModel";
import CreateHistorical from "pages/settings/components/historicalRequest/components/CreateHistorical";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { faGrid2Plus } from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const disapperOptionsDropDown = (monitorItem) => {
  return (
    monitorItem.attributes.data_sources.includes("TWITTER") ||
    (handlelUserRoles("SM", "EDIT_MONITOR") &&
      monitorItem.attributes.monitor_owner == true) ||
    handlelUserRoles("SM", "EDIT_OTHERS_MONITOR") ||
    (handlelUserRoles("SM", "DESTROY_MONITOR") &&
      monitorItem.attributes.monitor_owner == true) ||
    handlelUserRoles("SM", "DESTROY_OTHERS_MONITOR")
  );
};

const OptionsDropDown = (props) => {
  const intl = useIntl();
  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    openPauseResumeModal: false,
    title: "",
    body: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    warning: false,
  });
  const [openLiveDashboardModel, setOpenLiveDashboardModel] = useState(false);
  const open = Boolean(stats.anchorEl);
  const history = useHistory();

  const handleClick = (event, monitorItem) => {
    if (disapperOptionsDropDown(monitorItem)) {
      setStats({ anchorEl: event.currentTarget, openPauseResumeModal: false });
    }
  };
  const handleClose = () => {
    setStats({ anchorEl: null });
  };

  const handleOpenDeleteModal = () => {
    const account_name =
      props.monitorItem && props.monitorItem.attributes.account_name;
    const posts = props.monitorItem && props.monitorItem.attributes.total_count;
    setStats({
      openDeleteModal: true,
      addClasses: "model-buttons",
      title: CheckValueLocale("delete_monitor", "", {}, intl),
      body: (
        <Box className="shared-dialog-body">
          <p>{`${CheckValueLocale("deleting_monitor", "", { account_name, posts }, intl)}`}</p>
          <h2 className="sub-text-desc">
            {CheckValueLocale("deleting_msg", "", {}, intl)}
          </h2>
        </Box>
      ),
      modalRightBtn: CheckValueLocale("delete_confirm", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      warning: true,
    });
  };

  const handleCloseDeleteModal = () => {
    setStats({ openDeleteModal: false });
  };

  const handleOpenPauseResumeModal = () => {
    const account_name =
      props.monitorItem && props.monitorItem.attributes.account_name;
    if (props.monitorItem.attributes.stream_status === "collecting") {
      setStats({
        openPauseResumeModal: true,
        addClasses: "model-buttons  ",
        title: CheckValueLocale("pause_monitor", "", {}, intl),
        body: (
          <Box className="shared-dialog-body">
            <p>
              {`${CheckValueLocale("pausing", "", { account_name }, intl)} `}
              <br />
              {CheckValueLocale("pausing_question", "", {}, intl)}
            </p>
          </Box>
        ),

        modalRightBtn: CheckValueLocale("confirm_pause", "", {}, intl),
        modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
        warning: true,
        leftBtnVariant: "flat",
      });
    } else {
      setStats({
        openPauseResumeModal: true,
        addClasses: "model-buttons model-resume",
        title: CheckValueLocale("resume_monitor", "", {}, intl),
        body: (
          <Box className="shared-dialog-body">
            <p>{`${CheckValueLocale("start_collecting", "", { account_name }, intl)} `}</p>
            <p className="sub-text">
              {CheckValueLocale("collecting_Step_One", "", {}, intl)}
            </p>
          </Box>
        ),

        modalRightBtn: CheckValueLocale("confirm_resume_monitor", "", {}, intl),
        modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
        warning: false,
      });
    }
  };

  const handleClosePauseResumeModal = () => {
    setStats({ openPauseResumeModal: false });
  };

  const handleAcceptPauseResumeModal = (monitor_id) => {
    handleClosePauseResumeModal();
    props.setLoading(true);
    MonitorsController.resumeOrPauseMonitors(monitor_id).then((res) => {
      if (res.errorMsg) {
        if (res.errorMsg.request.status === 400) {
          // Authentication Required
          props.setSuccess(false);
          const social = [];
          res.errorMsg.response.data.data.forEach((item) => {
            if (item.limit_error !== undefined && item.limit_error.length > 0) {
              social.push(CheckValueLocale("limit_error", "", {}, intl));
            } else if (
              item.account_error !== undefined &&
              item.limit_error === undefined
            ) {
              social.push(
                CheckValueLocale(
                  item.account_error[0].toLowerCase(),
                  "",
                  {},
                  intl,
                ),
              );
            }
          });
          if (window.localStorage.getItem("lang") === "ar") {
            props.setErrorMsg(
              `${CheckValueLocale(
                "authentication",
                "",
                {},
                intl,
              )}  ${social.join(", ")}`,
            );
          } else {
            props.setErrorMsg(
              `${social.join(", ")} ${CheckValueLocale(
                "authentication",
                "",
                {},
                intl,
              )} `,
            );
          }
        } else if (res.errorMsg.request.status === 405) {
          // Resume monitor refused because it is not approved
          props.setSuccess(false);
          props.setErrorMsg(
            `${CheckValueLocale("still", "", {}, intl)} [${
              props.monitorItem.attributes.name
            }] ${CheckValueLocale("approved_yet", "", {}, intl)}`,
          );
        } else if (res.errorMsg.request.status === 500) {
          // Internal Server Error
          props.setSuccess(false);
          props.setErrorMsg(CheckValueLocale("server_error", "", {}, intl));
        }
      } else {
        // Success
        props.setSuccess(true);
        if (props.monitorItem.attributes.stream_status === "collecting") {
          if (window.localStorage.getItem("lang") === "ar") {
            props.setSuccessMsg(
              `${CheckValueLocale("pause_msg", "", {}, intl)} [${
                props.monitorItem.attributes.name
              }]  ${CheckValueLocale("successful_msg", "", {}, intl)}!`,
            );
          } else {
            props.setSuccessMsg(
              `[${props.monitorItem.attributes.name}] ${CheckValueLocale(
                "pause_msg",
                "",
                {},
                intl,
              )} ${CheckValueLocale("successful_msg", "", {}, intl)}!`,
            );
          }
        } else {
          if (window.localStorage.getItem("lang") === "ar") {
            props.setSuccessMsg(
              `${CheckValueLocale("resume_msg", "", {}, intl)} [${
                props.monitorItem.attributes.name
              }]  ${CheckValueLocale("successful_msg", "", {}, intl)}!`,
            );
          } else {
            props.setSuccessMsg(
              `[${props.monitorItem.attributes.name}] ${CheckValueLocale(
                "resume_msg",
                "",
                {},
                intl,
              )} ${CheckValueLocale("successful_msg", "", {}, intl)}!`,
            );
          }
        }
      }
      props.getMonitors();
    });
  };

  const handleAcceptDeleteModal = (monitor_id) => {
    handleClosePauseResumeModal();
    props.setLoading(true);
    MonitorsController.deleteMonitor(monitor_id).then((res) => {
      if (res.data && res.data.status == 204) {
        // Success
        props.setSuccess(true);
        if (window.localStorage.getItem("lang") === "ar") {
          props.setSuccessMsg(
            `${CheckValueLocale("delete_msg", "", {}, intl)} [${
              props.monitorItem.attributes.name
            }]  ${CheckValueLocale("successful_msg", "", {}, intl)}!`,
          );
        } else {
          props.setSuccessMsg(
            `[${props.monitorItem.attributes.name}] ${CheckValueLocale(
              "delete_msg",
              "",
              {},
              intl,
            )} ${CheckValueLocale("successful_msg", "", {}, intl)}!`,
          );
        }
        if (props?.monitors?.length === 1 && props?.page > 1) {
          props?.setPage(props?.page - 1);
        } else {
          props?.getMonitors();
        }
      } else {
        // Failed
        props.setSuccess(false);
        props.setErrorMsg(CheckValueLocale("server_error", "", {}, intl));
        props.getMonitors();
      }
    });
  };

  const [isLoading, setLoading] = useState(false);
  window.addEventListener("scroll", (event) => {
    if (!document.body.classList.contains("modal-open-body")) {
      if (stats.anchorEl) {
        setStats({ anchorEl: null });
      }
    }
  });
  const handelRedirection = async (monitor, redirectType) => {
    setLoading(true);
    let redirectionUrl = Services.switchUrl;

    let dataSourcesLinksEdit = {
      KEYWORD: {
        TWITTER: "monitors",
        FACEBOOK: "fb",
        INSTAGRAM: "instagram",
        TALKWALKER: "news",
      },
      MANAGED_PAGE: {
        FACEBOOK: "managed_page/facebook",
      },
      MANAGED_ACCOUNT: {
        INSTAGRAM: "managed_account/instagram",
      },
      ACCOUNT_ANALYSIS: {
        TWITTER: "monitors",
        INSTAGRAM: "account_analysis/instagram",
        FACEBOOK: "public_pages/facebook",
      },
      REGION: {
        TWITTER: "monitors",
      },
    };

    let blankDash = false;
    let monitor_edit_link =
      "#/" +
      dataSourcesLinksEdit[monitor.attributes.monitor_type_name][
        monitor.attributes.data_sources[0]
      ];
    let redirectLink;
    if (redirectType === "edit") {
      redirectLink = `${monitor_edit_link}/information/edit/${monitor.id}/${monitor.attributes.created_at}/`;
      blankDash = false;
    } else {
      redirectLink = `#/monitors/live_dashboard/${monitor.id}`;
      blankDash = true;
    }

    await UserAccount.getSmData().then((result) => {
      if (result.data !== undefined) {
        localStorage.setItem("user_token", result.data.data.jwt);
        window.open(
          redirectionUrl + redirectLink + "?sm_token=" + result.data.data.jwt,
          blankDash ? "_blank" : "_self",
        );
        setLoading(false);
      }
    });
  };

  const isUserHasRols =
    (handlelUserRoles("SM", "EDIT_MONITOR") &&
      props.monitorItem.attributes.monitor_owner == true) ||
    handlelUserRoles("SM", "EDIT_OTHERS_MONITOR");

  const includeTwitter =
    props?.monitorItem?.attributes?.channel?.includes("TWITTER");

  const hasUserLiveDashboard =
    props?.monitorItem?.attributes?.has_user_live_dashboard;

  const handleLiveDashboardModel = () =>
    setOpenLiveDashboardModel(!openLiveDashboardModel);

  const handleLiveDashboard = () => {
    const monitorType = props?.monitorItem?.attributes?.monitor_type_name;
    const monitorId = props?.monitorItem?.id;
    const userDashboardId = hasUserLiveDashboard;

    hasUserLiveDashboard
      ? history.push({
          pathname: `/live-dashboard/${monitorType}/${monitorId}/${userDashboardId}`,
          state: props.monitorItem,
        })
      : handleLiveDashboardModel();
  };

  const hadleCreateDashboard = () => {
    const monitorType = props?.monitorItem?.attributes?.monitor_type_name;
    const monitorId = props?.monitorItem?.id;
    handleLiveDashboardModel();
    history.push({
      pathname: `/create-live-dashboard/${monitorType}/${monitorId}`,
      state: props.monitorItem,
    });
  };

  return (
    <>
      {isLoading ? <CircularLoading /> : false}
      <Box className="menu-list">
        <CustomizedTooltip
          id="menu-item-list-tooltip"
          title={
            <Box className="item-title-tooltip">
              {CheckValueLocale("items_ellipsis", "", {}, intl)}
            </Box>
          }
          arrow
          placement="bottom"
        >
          <Button
            variant="text"
            className={
              "menu-items menu-item-container " +
              (open ? "menu-item-active" : "") +
              (disapperOptionsDropDown(props?.monitorItem)
                ? ""
                : "disable-drop-menu")
            }
            aria-label="more"
            id="social-media-dropdown-long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={(e) => {
              handleClick(e, props?.monitorItem);
            }}
          >
            <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
          </Button>
        </CustomizedTooltip>
        <Menu
          id="monitor-menu-dropdown"
          MenuListProps={{
            "aria-labelledby": "monitor-menu-button",
          }}
          anchorEl={stats.anchorEl}
          open={open}
          onClose={handleClose}
        >
          {props?.monitorItem?.attributes?.paused != true &&
          handlelUserRoles("GENERAL", "VIEW_SETTING") ? (
            <CreateHistorical
              isMonitorOption={true}
              monitorData={props?.monitorItem}
              monitorsListSnack={props?.monitorsListSnack}
              setMonitorsListSnack={props?.setMonitorsListSnack}
              handleCloseDropdown={handleClose}
            />
          ) : null}

          {isUserHasRols &&
            props?.monitorItem?.attributes?.data_sources?.length !== 0 && (
              <MenuItem
                onClick={() => {
                  history.push(
                    `/monitor/monitor-settings/${props.monitorItem?.id}`,
                  );
                }}
                disableRipple
                id={`monitor-menu-dropdown-edit-${
                  props?.monitorItem?.id ?? "monitor-item-id"
                }`}
              >
                <Edit />
                {CheckValueLocale("edit", "", {}, intl)}
              </MenuItem>
            )}
          {isUserHasRols &&
            props?.monitorItem?.attributes?.data_sources?.length !== 0 && (
              <MenuItem
                onClick={handleOpenPauseResumeModal}
                disableRipple
                disabled={
                  props.accountError?.length > 0 ||
                  props.accountValidError?.length > 0
                    ? true
                    : false
                }
                id={`monitor-menu-dropdown-pause-play-${
                  props?.monitorItem?.id ?? "monitor-item-id"
                }`}
              >
                {props.monitorItem.attributes.stream_status === "collecting" ? (
                  <Pause />
                ) : (
                  <PlayArrow />
                )}
                {props.monitorItem.attributes.stream_status === "collecting"
                  ? CheckValueLocale("monitor_list_menu_pause", "", {}, intl)
                  : CheckValueLocale("monitor_list_menu_resume", "", {}, intl)}
              </MenuItem>
            )}
          {((handlelUserRoles("SM", "DESTROY_MONITOR") &&
            props.monitorItem.attributes.monitor_owner == true) ||
            handlelUserRoles("SM", "DESTROY_OTHERS_MONITOR")) && (
            <MenuItem
              onClick={handleOpenDeleteModal}
              disableRipple
              id={`monitor-menu-dropdown-delete-${
                props?.monitorItem?.id ?? "monitor-item-id"
              }`}
            >
              <DeleteOutlineOutlinedIcon />
              {CheckValueLocale("delete", "", {}, intl)}
            </MenuItem>
          )}
          <MenuItem
            onClick={handleLiveDashboard}
            disableRipple
            id={`monitor-menu-dropdown-handle-live-dashboard-${
              props?.monitorItem?.id ?? "monitor-item-id"
            }`}
            className="monitor-menu-live-dashboard"
          >
            <FontAwesomeIcon
              icon={
                hasUserLiveDashboard ? faArrowUpRightFromSquare : faGrid2Plus
              }
            />
            {CheckValueLocale(
              hasUserLiveDashboard
                ? "go_to_create_live_dashboard"
                : "create_live_dashboard",
              "",
              {},
              intl,
            )}
          </MenuItem>
        </Menu>

        {stats.openPauseResumeModal === true && (
          <PopupModal
            addClasses={stats.addClasses}
            title={stats.title}
            body={stats.body}
            leftBtn={stats.modalLeftBtn}
            rightBtn={stats.modalRightBtn}
            open={stats.openPauseResumeModal}
            close={handleClosePauseResumeModal}
            accept={handleAcceptPauseResumeModal}
            monitor_id={
              props?.monitorItem?.attributes?.id ?? "monitor-resume-id"
            }
            warning={stats.warning}
            minWidth="600px"
          />
        )}
        {stats.openDeleteModal === true && (
          <PopupModal
            title={stats.title}
            body={stats.body}
            leftBtn={stats.modalLeftBtn}
            rightBtn={stats.modalRightBtn}
            open={stats.openDeleteModal}
            isDeleteBtn={stats.openDeleteModal}
            close={handleCloseDeleteModal}
            accept={handleAcceptDeleteModal}
            monitor_id={
              props?.monitorItem?.attributes?.id ?? "monitor-delete-id"
            }
            warning={stats.warning}
            minWidth="600px"
            classeName="actions-buttons-shared"
          />
        )}
        {openLiveDashboardModel ? (
          <LiveDashboardModel
            open={openLiveDashboardModel}
            handleClose={handleLiveDashboardModel}
            handleCreate={hadleCreateDashboard}
          />
        ) : null}
      </Box>
    </>
  );
};
export default OptionsDropDown;
