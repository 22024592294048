import LiveDashboard from "../api/liveDashboard";

var LiveDashboardController = {};

LiveDashboardController.getMonitorWidgets = (queryData) => {
  return new Promise(function (resolve) {
    LiveDashboard.getMonitorWidgets(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.getMonitorLiveDashboardDetails = (queryData) => {
  return new Promise(function (resolve) {
    LiveDashboard.getMonitorLiveDashboardDetails(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.getMonitorLiveDashboardList = (queryData) => {
  return new Promise(function (resolve) {
    LiveDashboard.getMonitorLiveDashboardList(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.createLiveDashboard = (queryData) => {
  return new Promise(function (resolve) {
    LiveDashboard.createLiveDashboard(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.editLiveDashboard = (queryData) => {
  return new Promise(function (resolve) {
    LiveDashboard.editLiveDashboard(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.deleteLiveDashboard = (queryData) => {
  return new Promise(function (resolve) {
    LiveDashboard.deleteLiveDashboard(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          errorMsg: error,
        });
      });
  });
};

export default LiveDashboardController;
