import { useEffect, useState } from "react";
import {
  Typography,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
  FormHelperText,
  Chip,
  InputLabel,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import {
  CustomMenuItem,
  CustomSelect,
  CustomFormControl,
} from "./filter.styles.js";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  CheckValueLocale,
  handleFiltersParamsIds,
  isEmptyArray,
  isEmptyObj,
  handleApplicableFiltersCA,
  getSocialIcon,
  classNames,
} from "utils/helpers/index.js";
import { useDispatch, useSelector } from "react-redux";
import {
  filters,
  filtersDropDown,
  filtersDropDownToDisplay,
  filtersDropDownCopy,
} from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice.js";
import { simpleKeywordFieldCheck } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/simpleKeywordFieldCheck";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import { checkDirectionLang } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import KeywordTooltipChip from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
const useStyles = makeStyles((theme) => ({
  customWidth: {
    padding: "13px 13px 13px 10px",
  },
}));

const FilterDropDownMenu = (props) => {
  const { setErrorMessageKeywords, errorMessageKeywords } = props;
  const location = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filterFieldsByInteraction, setFilterFieldsByInteraction] = useState(
    [],
  );
  const [filterFieldsByUser, setFilterFieldsByUser] = useState([]);
  const [keywordValue, setKeywordValue] = useState("");
  const [dataExist, setDataExist] = useState(true);

  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  // start redux declaration

  const reduxFilterDropDownParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );

  const reduxFilterDropDownParamsCopy = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParamsCopy,
  );

  const reduxFilterDropDownParamsToDisplay = useSelector(
    (state) => state?.channelAnalytics?.filterDropDown,
  );

  // start redux declaration

  const translateFilterItem = (filterItem) => {
    // Note: we ned to use \/$ to check that path end with category name:
    // for Ex: social-media/
    // This to avoid confustion between social-media/ && social-media/x-platform-channel
    if (
      /social-media\/$/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "social_media_accounts";
    if (
      /x-platform-channel/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "twitter_accounts";
    if (
      /facebook-channel/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "facebook_accounts";
    if (
      /instagram-channel/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "instagram_accounts";
    if (
      /chat\/$/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "chats_accounts";
    if (
      /intercom/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "intercom_accounts";
    if (
      /whatsapp/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "whatsapp_accounts";
    if (
      /email\/$/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "emails_accounts";
    if (
      /ratings/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "gmb_accounts";
    if (/gmail/.test(location?.pathname) && filterItem.label === "monitor_list")
      return "gmail_accounts";
    if (
      /linkedin-channel/.test(location?.pathname) &&
      filterItem?.label === "monitor_list"
    )
      return "linkedin_accounts";
    if (filterItem?.label === "waiting_filter") return "avg_waiting_time";
    return filterItem?.label;
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    if (name === "main_dialect_filter") {
      var obj = {
        ...reduxFilterDropDownParams,
        main_dialect_filter:
          typeof value === "string" ? value?.split(",") : value,
      };
      var obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        main_dialect_filter:
          typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "sub_dialect_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        sub_dialect_filter:
          typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        sub_dialect_filter:
          typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "call_status") {
      obj = {
        ...reduxFilterDropDownParams,
        call_status: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        call_status: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "gender_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        gender_filter: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        gender_filter: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "sentiment_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        sentiment_filter: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        sentiment_filter: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "sub_themes_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        sub_themes_filter:
          typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        sub_themes_filter:
          typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "themes_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        themes_filter: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        themes_filter: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "monitor_list") {
      obj = {
        ...reduxFilterDropDownParams,
        monitor_list: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        monitor_list: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "waiting_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        waiting_filter: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        waiting_filter: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "cities_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        cities_filter: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        cities_filter: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "countries_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        countries_filter: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        countries_filter: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "languages_filter") {
      obj = {
        ...reduxFilterDropDownParams,
        languages_filter: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        languages_filter: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "categories") {
      obj = {
        ...reduxFilterDropDownParams,
        categories: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        categories: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "interaction_type") {
      obj = {
        ...reduxFilterDropDownParams,
        interaction_type: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        interaction_type: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "dm_list") {
      obj = {
        ...reduxFilterDropDownParams,
        dm_list: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        dm_list: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "channels") {
      obj = {
        ...reduxFilterDropDownParams,
        channels: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        channels: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "locations") {
      obj = {
        ...reduxFilterDropDownParams,
        locations: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        locations: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "with_comment") {
      const withComment = event.target.checked ? [1] : [];
      obj = {
        ...reduxFilterDropDownParams,
        with_comment: withComment,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        with_comment: withComment,
      };
    } else if (name === "manual_topics") {
      obj = {
        ...reduxFilterDropDownParams,
        manual_topics: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        manual_topics: typeof value === "string" ? value?.split(",") : value,
      };
    } else if (name === "question_status") {
      obj = {
        ...reduxFilterDropDownParams,
        question_status: typeof value === "string" ? value?.split(",") : value,
      };
      obj1 = {
        ...reduxFilterDropDownParamsToDisplay,
        question_status: typeof value === "string" ? value?.split(",") : value,
      };
    }
    let applicableFilters;
    if (!isEmptyObj(props.filterItem)) {
      applicableFilters = handleApplicableFiltersCA(
        props?.filterItem,
        reduxFilterDropDownParams,
      );
    }
    const commonObject = {};
    const allFilters = {
      ...props?.filterItem?.by_interactions,
      ...props?.filterItem?.by_user,
    };

    let finalArr = { ...allFilters };
    let dmListArr = [];

    allFilters?.dm_list?.map((dmList) => {
      dmListArr.push(`${dmList?.id}-${dmList?.name}`);
    });

    finalArr = { ...finalArr, dm_list: dmListArr };

    let monitorListArr = [];

    allFilters?.monitor_list?.map((monitorList) => {
      monitorListArr?.push(`${monitorList?.id}-${monitorList?.name}`);
    });

    finalArr = { ...finalArr, monitor_list: monitorListArr };

    let waitingListArr = [];

    allFilters?.waiting_filter?.map((waitingList) => {
      waitingListArr?.push(`${waitingList?.name}`);
    });

    finalArr = { ...finalArr, waiting_filter: waitingListArr };

    Object.keys(applicableFilters).forEach((key) => {
      if (
        Array.isArray(applicableFilters[key]) &&
        Array.isArray(finalArr[key])
      ) {
        const commonValues = applicableFilters[key].filter((value) => {
          return (
            finalArr[key].findIndex(
              (item) =>
                item?.toString()?.toLowerCase() ===
                value?.toString()?.toLowerCase(),
            ) !== -1
          );
        });
        commonObject[key] = commonValues;
      } else {
        commonObject[key] = [];
      }
    });

    let initialFiltersValue = { ...reduxFilterDropDownParamsCopy };

    let keyFromWhichToDelete = [...initialFiltersValue[name]];

    initialFiltersValue[name].map((val) => {
      if (commonObject[name].includes(val) && !obj[name].includes(val)) {
        if (Array.isArray(initialFiltersValue[name])) {
          const index = initialFiltersValue[name]?.indexOf(val);
          if (index !== -1) {
            keyFromWhichToDelete?.splice(index, 1);
            initialFiltersValue = {
              ...initialFiltersValue,
              [name]: [...keyFromWhichToDelete],
            };
            dispatch(filtersDropDownCopy(initialFiltersValue));
          }
        }
      }
    });

    let initialFiltersValueCopy = { ...initialFiltersValue };
    Object.keys(obj).forEach((key) => {
      if (
        initialFiltersValueCopy.hasOwnProperty(key) &&
        key !== "with_comment"
      ) {
        initialFiltersValueCopy[key] = Array.from(
          new Set([...initialFiltersValueCopy[key], ...obj[key]]),
        );
      } else {
        initialFiltersValueCopy[key] = obj[key];
      }
    });

    handleFiltersParamsIds(obj, props?.filterItem);
    props?.setFilterParams(obj);
    dispatch(filtersDropDown(initialFiltersValueCopy));
    dispatch(filtersDropDownToDisplay(obj1));
    dispatch(filters(handleFiltersParamsIds(obj, props?.filterItem)));
  };

  const getfilterFieldsByUser = () => {
    const items = [];
    let metadata;
    for (let [key, list] of Object.entries(props?.filterItem?.by_user)) {
      let label, value;
      if (list?.length === 0) {
        label = key;
        value = [];
      } else if (typeof list[0] === "object") {
        if (key === "waiting_filter") {
          label = key;
          value = list?.map((val) => {
            return val?.name;
          });
        } else {
          label = key;
          if (key === "monitor_list") {
            metadata = list?.map((val) => {
              return {
                source: val?.source,
                is_dm: val?.is_dm,
              };
            });
          }
          value = list?.map((val) => {
            return val?.id + "-" + val?.name;
          });
        }
      } else {
        label = key;
        value = list;
      }
      items.push({ label, value, metadata });
    }
    setFilterFieldsByUser(items);
  };

  const getfilterFieldsByInteraction = () => {
    const items = [];
    let metadata;
    for (let [key, list] of Object.entries(
      props?.filterItem?.by_interactions,
    )) {
      let label, value;
      if (list.length === 0) {
        label = key;
        value = [];
      } else if (typeof list[0] === "object") {
        if (key === "waiting_filter") {
          label = key;
          value = list?.map((val) => {
            return val?.name;
          });
        } else {
          label = key;
          if (key === "monitor_list") {
            metadata = list?.map((val) => {
              return {
                source: val?.source,
                is_dm: val?.is_dm,
              };
            });
          }
          value = list?.map((val) => {
            return val?.id + "-" + val?.name;
          });
        }
      } else {
        label = key;
        value = list;
      }
      items.push({ label, value, metadata });
    }
    setFilterFieldsByInteraction(items);
  };

  useEffect(() => {
    {
      props?.filterItem?.by_interactions !== undefined &&
        getfilterFieldsByInteraction();
    }
    {
      props?.filterItem?.by_user !== undefined && getfilterFieldsByUser();
    }
  }, [props?.filterItem]);

  const handleDeleteWord = (e, index, inputName) => {
    let words = reduxFilterDropDownParams[inputName]
      ? reduxFilterDropDownParams[inputName]?.toString()?.split(",")
      : reduxFilterDropDownParams[inputName];

    if (Array.isArray(words) && words?.length > 0) {
      words?.splice(index, 1);
    }
    let obj = {
      ...reduxFilterDropDownParams,
      [inputName]:
        Array.isArray(words) && words?.length > 0 ? words?.join() : "",
    };

    props?.setFilterParams(obj);
    dispatch(filtersDropDown(obj));
    dispatch(filtersDropDownToDisplay(obj));
  };
  const handleKeyFunc = () => {
    if (reduxFilterDropDownParams["keyword"]) {
      let removeDuplicateUser = reduxFilterDropDownParams["keyword"]
        ?.toString()
        ?.split(",");

      if (removeDuplicateUser.includes(keywordValue.trim())) {
        setKeywordValue("");
        return false;
      }
    }
  };

  const handleKeyDown = (event, inputName) => {
    var value = event.target.value;
    //inputValue.value.trim().length !== 0 is added to refuse empty input or contating spaces only
    if (event.keyCode === 13 && dataExist && value?.trim().length !== 0) {
      //Prevent user to enter same value twice

      if (inputName === "keyword") {
        let objData = handlekeywordsShared(
          value,
          inputName === "keyword" ? "keywords" : "spam_keywords",
          reduxFilterDropDownParams["keyword"]
            ? Array.isArray(reduxFilterDropDownParams["keyword"])
              ? reduxFilterDropDownParams["keyword"]
              : reduxFilterDropDownParams["keyword"]?.split(",")
            : [],
          [],
          "twitter",
          [
            {
              keywords: reduxFilterDropDownParams["keyword"]
                ? reduxFilterDropDownParams["keyword"]
                : [],
            },
            {
              spam_keywords: [],
            },
          ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
          100,
          undefined, //in case newsblogs,
          undefined,
        );
        setErrorMessageKeywords(objData?.errorMsg);

        if (!objData?.errorMsg) {
          var obj = {
            ...reduxFilterDropDownParams,
            [inputName]:
              reduxFilterDropDownParams[inputName] &&
              !isEmptyArray(reduxFilterDropDownParams[inputName]) &&
              reduxFilterDropDownParams[inputName] !== ""
                ? [...objData?.keywords]
                : [...objData?.keywords],
          };

          props.setFilterParams(obj);
          dispatch(filtersDropDown(obj));
          dispatch(filtersDropDownToDisplay(obj));
          if (!objData.exccedKeywordsLimit) {
            setKeywordValue("");
          }
        } else {
          setKeywordValue(value);
        }
      }
    }
  };
  const handleKeywordsChange = (event, inputName) => {
    var { value } = event.target;
    //using shared
    setKeywordValue(value);
    let errorMessage = handleValidationKeywords(
      value,
      reduxFilterDropDownParams["keyword"]
        ? Array.isArray(reduxFilterDropDownParams["keyword"])
          ? reduxFilterDropDownParams["keyword"]
          : reduxFilterDropDownParams["keyword"]?.split(",")
        : [],
      [],
      inputName === "keyword" ? "keywords" : "spam_keywords",
      setDataExist,
      "twitter",
    );

    setErrorMessageKeywords(errorMessage);
  };

  return (
    <>
      {props.filterItem.by_interactions !== undefined && (
        <Box className="filter_sort">
          {CheckValueLocale("by_interactions", "", {}, intl)}
        </Box>
      )}

      {filterFieldsByInteraction?.map((filterItem, i) => {
        return (
          <CustomFormControl sx={{ width: 300, marginBottom: "15px" }} key={i}>
            {filterItem?.label != "keyword" ? (
              <Typography className="filter__label" mb={1}>
                {/* User Translation method for all types*/}
                {filterItem?.label === "monitor_list" &&
                filterItem?.label === "monitor_list"
                  ? CheckValueLocale("channel_accounts", "", {}, intl)
                  : filterItem?.label === "dm_list"
                    ? CheckValueLocale("channel_accounts", "", {}, intl)
                    : CheckValueLocale(
                        translateFilterItem(filterItem),
                        "",
                        {},
                        intl,
                      )}
              </Typography>
            ) : (
              <InputLabel
                htmlFor="outlined-adornment-amount"
                className="keword-label"
                style={{ marginTop: "10px" }}
              >
                {CheckValueLocale(filterItem?.label, "", {}, intl)}
              </InputLabel>
            )}
            {filterItem?.label == "keyword" ? (
              <>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  label={CheckValueLocale(filterItem?.label, "", {}, intl)}
                  className={`filter_dropdown ${checkDirectionLang(
                    keywordValue,
                  )}`}
                  onKeyDown={(e) => {
                    handleKeyDown(e, filterItem?.label);
                  }}
                  value={keywordValue}
                  onChange={(e) => handleKeywordsChange(e, filterItem?.label)}
                  name={filterItem?.label}
                  style={{ marginTop: "10px" }}
                  error={errorMessageKeywords !== ""}
                />
                {errorMessageKeywords ? (
                  <Box className="err-msg" style={{ marginBottom: "15px" }}>
                    {CheckValueLocale(
                      errorMessageKeywords,
                      "",
                      { limit: 100 },
                      intl,
                    )}
                  </Box>
                ) : (
                  <FormHelperText
                    id="filled-weight-helper-text"
                    style={{ marginBottom: "15px" }}
                  >
                    {CheckValueLocale("click_enter_to_add", "", {}, intl)}
                  </FormHelperText>
                )}
                {/* Chip Section__________________________________________________ */}
                {!isEmptyArray(reduxFilterDropDownParams[filterItem?.label]) &&
                  reduxFilterDropDownParams[filterItem?.label] !== undefined &&
                  reduxFilterDropDownParams[filterItem?.label] !== "" && (
                    <div className="section-box-data box-margin">
                      {/* this part has two cases if user add keywords for 1st time it will be added in type of string 
                            BUT if user choose old saved filter that contain keywords it will be in type of Array
                            so we do split in first case to avoid map crash  */}
                      {typeof reduxFilterDropDownParams[filterItem?.label] ===
                      "string"
                        ? reduxFilterDropDownParams[filterItem?.label]
                            ?.split(",")
                            .map((word, index) => {
                              return (
                                <Chip
                                  label={word}
                                  className={"chip-style"}
                                  onDelete={(e) =>
                                    handleDeleteWord(
                                      e,
                                      index,
                                      filterItem?.label,
                                    )
                                  }
                                />
                              );
                            })
                        : reduxFilterDropDownParams["keyword"].map(
                            (word, index) => {
                              return (
                                <KeywordTooltipChip
                                  onDelete={(e) =>
                                    handleDeleteWord(
                                      e,
                                      index,
                                      filterItem?.label,
                                    )
                                  }
                                  dataSource={"twitter"}
                                  index={index}
                                  labels={word}
                                />
                              );
                            },
                          )}
                    </div>
                  )}
              </>
            ) : (
              <CustomSelect
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={reduxFilterDropDownParamsToDisplay[filterItem?.label]}
                name={filterItem?.label}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) =>
                  selected.map((i, index) => {
                    if (
                      selected?.length > 1 &&
                      index !== selected?.length - 1
                    ) {
                      return [CheckValueLocale(i, "", {}, intl) + ", "];
                    } else {
                      return [CheckValueLocale(i, "", {}, intl)];
                    }
                  })
                }
                disabled={isEmpty(filterItem?.value)}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {!isEmpty(filterItem?.value) &&
                  filterItem?.value
                    ?.map((word) => word)
                    .map((val, idx) => (
                      <CustomMenuItem
                        key={val}
                        value={val}
                        className={
                          filterItem?.label === "monitor_list"
                            ? "monitor-list-filter-item"
                            : null
                        }
                      >
                        <Checkbox
                          sx={{ margin: "5px" }}
                          checked={
                            reduxFilterDropDownParams[
                              filterItem?.label
                            ]?.indexOf(val) > -1
                          }
                        />
                        {filterItem?.label === "monitor_list" &&
                        filterItem?.metadata?.[idx]?.source ? (
                          <Box
                            className={classNames(
                              "monitor-list-filter-item-img-icon",
                              filterItem?.metadata?.[idx]?.source +
                                (filterItem?.metadata?.[idx]?.is_dm
                                  ? "_private"
                                  : ""),
                            )}
                          >
                            {getSocialIcon(
                              filterItem?.metadata?.[idx]?.source +
                                (filterItem?.metadata?.[idx]?.is_dm
                                  ? "_private"
                                  : ""),
                            )}
                          </Box>
                        ) : null}
                        <ListItemText
                          primary={
                            filterItem?.label === "monitor_list" ||
                            filterItem?.label === "dm_list"
                              ? CheckValueLocale(val, "", {}, intl)
                              : CheckValueLocale(
                                  val?.toLowerCase(),
                                  "",
                                  {},
                                  intl,
                                )
                          }
                        />
                      </CustomMenuItem>
                    ))}
              </CustomSelect>
            )}
          </CustomFormControl>
        );
      })}
      {props.filterItem.by_user !== undefined && (
        <Box className="filter_sort" mt={"12px"}>
          {CheckValueLocale("by_user", "", {}, intl)}
        </Box>
      )}

      {filterFieldsByUser?.map((filterItem, i) => {
        return (
          <CustomFormControl sx={{ width: 300, marginBottom: "15px" }} key={i}>
            <Typography className="filter__label" mb={1}>
              {CheckValueLocale(filterItem?.label, "", {}, intl)}
            </Typography>
            <CustomSelect
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={reduxFilterDropDownParamsToDisplay[filterItem?.label]}
              name={filterItem?.label}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) =>
                selected?.map((i, index) => {
                  if (selected?.length > 1 && index !== selected?.length - 1) {
                    return [CheckValueLocale(i, "", {}, intl) + ", "];
                  } else {
                    return [CheckValueLocale(i, "", {}, intl)];
                  }
                })
              }
              disabled={isEmpty(filterItem?.value)}
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {!isEmpty(filterItem?.value) &&
                filterItem?.label === "locations" &&
                filterItem?.value?.map((val, index) => (
                  <CustomMenuItem key={index} value={val}>
                    <Checkbox
                      sx={{ margin: "5px" }}
                      checked={
                        reduxFilterDropDownParams[
                          filterItem?.label?.toString()?.toLowerCase()
                        ]?.indexOf(val) >= 0
                      }
                    />
                    <Tooltip
                      title={
                        <Box className="rate-review-tooltip">
                          {CheckValueLocale(
                            val.replace(/-/g, ", "),
                            "",
                            {},
                            intl,
                          )}
                        </Box>
                      }
                      arrow
                      placement="bottom"
                      classes={{ tooltip: classes?.customWidth }}
                    >
                      <ListItemText
                        primary={
                          val.length > 35
                            ? val.replace(/-/g, ", ").slice(0, 34) + "..."
                            : val.replace(/-/g, ", ")
                        }
                      />
                    </Tooltip>
                  </CustomMenuItem>
                ))}
              {!isEmpty(filterItem?.value) &&
                filterItem?.label !== "locations" &&
                filterItem?.value
                  ?.map((word) => word?.toString()?.toLowerCase())
                  ?.map((val, index) => (
                    <CustomMenuItem key={index} value={val}>
                      <Checkbox
                        sx={{ margin: "5px" }}
                        checked={
                          reduxFilterDropDownParams[
                            filterItem?.label?.toString()?.toLowerCase()
                          ]?.indexOf(val?.toLowerCase()) >= 0
                        }
                      />
                      <ListItemText
                        primary={CheckValueLocale(
                          val?.toLowerCase(),
                          "",
                          {},
                          intl,
                        )}
                      />
                    </CustomMenuItem>
                  ))}
            </CustomSelect>
          </CustomFormControl>
        );
      })}
      {props?.showCheckbox && (
        <Box className="filterbody-checkbox">
          <Checkbox
            checked={reduxFilterDropDownParams?.with_comment?.[0] === 1}
            onClick={handleChange}
            name="with_comment"
          />
          <Box className="filterbody-msg">
            {CheckValueLocale("show_reviews_with_comments", "", {}, intl)}
          </Box>
        </Box>
      )}
    </>
  );
};
export default FilterDropDownMenu;
