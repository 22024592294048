//check if keyword contain Special character Or emojis to avoid it and fire Error
export const isPublicSpecialCharactersOrEmojis = (
  string,
  spam,
  title,
  is_fb_data_source = false,
  is_specific_format = false,
) => {
  // Rules according to PT specifications: https://lucidya.atlassian.net/wiki/spaces/Q3R/pages/2003959827/Create+Monitor
  // Username
  // 1- Under score “_” between names are okay
  // 2- Numbers are okay
  // 3- Letters are also okay for sure
  // 4- Other special characters are not allowed (comma, spaces, /, #, etc)
  // 5- With or without @, BE should handle it

  let result;
  // Special case here that dot is accepted in FB
  if (is_fb_data_source) {
    result =
      string.search(/[§±!^\#\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?,؛؟،'"` ]/g) !==
        -1 ||
      isEmojis(string) ||
      isContaionArabicPunctuation(string);
  } else if (is_specific_format) {
    result =
      verificationNameValidation(string) ||
      isEmojis(string) ||
      isContaionArabicPunctuation(string);
  } else {
    result =
      string.search(
        /[§±!^\#\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\,؛؟،'"` ]/g,
      ) !== -1 ||
      isEmojis(string) ||
      isContaionArabicPunctuation(string);
  }

  let containsSpecialOrEmoji = "";

  if (spam || title) {
    // Rules according to PT specifications for spam: https://lucidya.atlassian.net/wiki/spaces/Q3R/pages/2003959827/Create+Monitor
    // Spam Keywords & Keywords
    // 1- the query can be 60 characters long, the character count includes the entire query string including spaces and operators, for example, the following query is 59 characters long: (happy OR happiness) place_country:GB -birthday -is:retweet
    // 2- a user can match based on emojis
    // 3- "exact phrase matching" using ““
    // 4- Allowed character: [a-z][A-Z][0-9][۰-۹]["'@#$^_():;+/><=%\ ] [space but not as single character]
    let accepted_characters = new RegExp(
      /^[a-zA-Z0-9\u0660-\u0669\u0621-\u064A\^\#\_\@\"\'\,\(\) ]*$/g,
    );
    containsSpecialOrEmoji =
      string.search(/^[\-]/g) !== -1 || !accepted_characters.test(string);
    return containsSpecialOrEmoji;
  } else {
    return result;
  }
};

const verificationNameValidation = (string) => {
  let acceptedChar = new RegExp(/^[A-Za-z0-9_]{0,15}$/g);
  return !acceptedChar.test(string);
};

//check if keyword contain emojis or not to appear Error
export const isEmojis = (string) => {
  let result =
    string.search(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u201B]|[\u201F-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ) !== -1;

  return result;
};

// Check if keyword contains Arabic Punctuation characters
export const isContaionArabicPunctuation = (string) => {
  return (
    string.search(
      /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g,
    ) !== -1
  );
};

//check if URL is valid OR not
export const validateURL = (url) => {
  let isSubDomain = /^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/;
  let containProtocol = /^(?!https?)(?!www\.?).*\..+$/g;
  let checkURLContainArabic =
    /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]|[\u0200]|[\u00A0]/g;
  let notAcceptCapital = /[A-Z]/;
  let notAcceptSpecialChar =
    /[§±!^\#\@\$%&\*\(\)\-\+=\[\]{};:\\\|~<>\/\?\,؛؟،'"` ]/g;

  const checkedSubProtocol =
    url.search(containProtocol) == -1 ||
    url.search(isSubDomain) !== -1 ||
    url.search(checkURLContainArabic) !== -1 ||
    url.search(notAcceptCapital) !== -1 ||
    url.search(notAcceptSpecialChar) !== -1;

  return checkedSubProtocol;
};
