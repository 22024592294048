const urlLucidyaSuite = {
  SM: "https://knowledge.lucidya.com/%D9%84%D9%88%D8%B3%D9%8A%D8%AF%D9%8A%D8%A7-%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%85%D8%A7%D8%B9-%D8%A7%D9%84%D8%A7%D8%AC%D8%AA%D9%85%D8%A7%D8%B9%D9%8A",
  CXM: "https://knowledge.lucidya.com/%D8%AA%D8%AD%D9%84%D9%8A%D9%84-%D9%82%D9%86%D9%88%D8%A7%D8%AA-%D8%A7%D9%84%D8%AA%D9%88%D8%A7%D8%B5%D9%84",
  ENGAGEMENTS:
    "https://knowledge.lucidya.com/%D8%A7%D9%84%D8%AA%D9%81%D8%A7%D8%B9%D9%84",
  CDP: "https://knowledge.lucidya.com/%D8%A7%D9%84%D8%AC%D9%85%D8%A7%D9%87%D9%8A%D8%B1",
  SURVEY:
    "https://knowledge.lucidya.com/%D8%A7%D9%84%D8%A5%D8%B3%D8%AA%D8%A8%D9%8A%D8%A7%D9%86%D8%A7%D8%AA",
  LUCI: "https://knowledge.lucidya.com/%D9%84%D9%88%D8%B3%D9%8A",
};

export default urlLucidyaSuite;
