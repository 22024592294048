import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CompaniesController from "services/controllers/companiesController";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { Box } from "@mui/material";

const DeleteCustomDomainModal = (props) => {
  const intl = useIntl();
  const [open, setOpen] = useState(props?.openDeleteSurveyModal);
  const [snackBarDetails, setSnackBarDetails] = useState({
    openSnackBar: false,
    title: "",
    snackBarMsg: "",
    severity: "",
  });

  const modalBody = (
    <Box className="shared-dialog-body">
      <p>{CheckValueLocale("remove_custom_domain_body", "", {}, intl)}</p>
      <h2 className="sub-text-desc">
        {CheckValueLocale("deleting_msg", "", {}, intl)}
      </h2>
    </Box>
  );

  const handleDeleteCustomDomain = () => {
    CompaniesController.sendAndDeleteCustomDomain("").then((data) => {
      setOpen(!props?.openDeleteSurveyModal);
      props?.setCustomDomainError(false);
      if (data?.status === 200) {
        setSnackBarDetails({
          openSnackBar: true,
          severity: "success",
          snackBarMsg: CheckValueLocale("custom_remove_success", "", {}, intl),
        });
        setTimeout(() => {
          props?.setCustomDomainName("");
          props?.setCustomDomain("");
        }, 2000);
      } else {
        setOpen(!props?.openDeleteSurveyModal);
        setSnackBarDetails({
          openSnackBar: true,
          severity: "error",
          snackBarMsg: CheckValueLocale("try_again", "", {}, intl),
          title: CheckValueLocale("something_went_wrong", "", {}, intl),
        });
      }
      setTimeout(() => {
        setSnackBarDetails({ ...snackBarDetails });
      }, 3000);
    });
  };

  return (
    <>
      <PopupModal
        title={CheckValueLocale("custom_domain_remove", "", {}, intl)}
        body={modalBody}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
        open={open}
        close={() => {
          props?.setOpenDeleteSurveyModal(false);
        }}
        accept={handleDeleteCustomDomain}
        warning={true}
        minWidth="600px"
        classeName="custom-domain-modal actions-buttons-shared"
      />

      {snackBarDetails.openSnackBar && (
        <SnackBar
          open={true}
          setOpen={setOpen}
          severity={snackBarDetails?.severity}
          title={snackBarDetails?.title}
          message={snackBarDetails?.snackBarMsg}
          snackBarClassName={"delete-survey-snackbar"}
          showCloseBtn={snackBarDetails?.severity === "success" ? true : false}
          textColor={
            snackBarDetails?.severity === "success" ? "green" : "brown"
          }
          handleClose={() =>
            setSnackBarDetails({
              openSnackBar: false,
              snackBarMsg: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
};
export default DeleteCustomDomainModal;
