import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const FormDescription = ({ demo, text }) => {
  const intl = useIntl();
  return (
    <Box component="p" className="form-description">
      {demo ? (
        <>
          {CheckValueLocale("dont_have_acc", "", {}, intl)}{" "}
          <a
            href={"https://lucidya.com/request-demo/"}
            target="_blank"
            id="login-form-bottom-subtitle-link"
            rel="noreferrer"
          >
            {CheckValueLocale("get_free_demo", "", {}, intl)}
          </a>
        </>
      ) : (
        text
      )}
    </Box>
  );
};
export default FormDescription;
