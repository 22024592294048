import { Box, List, ListItem } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const BackgroundFooter = () => {
  const intl = useIntl();
  return (
    <Box className="background-footer-wrapper">
      <List className="background-footer-menu">
        <ListItem>
          <a
            href="https://lucidya.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            {CheckValueLocale("privacy_policy", "", {}, intl)}
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://lucidya.com/service-agreement/"
            target="_blank"
            rel="noreferrer"
          >
            {CheckValueLocale("service_greement", "", {}, intl)}
          </a>
        </ListItem>
        <ListItem>
          <a
            href="https://lucidya.com/author-privacy-stateme/"
            target="_blank"
            rel="noreferrer"
          >
            {CheckValueLocale("author_privacy", "", {}, intl)}
          </a>
        </ListItem>
      </List>
    </Box>
  );
};
export default BackgroundFooter;
