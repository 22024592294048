import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

const LiveDashBoardActions = ({
  isEditLiveDashboard,
  actionButtonDisabled,
  isActionLoading,
  editLiveDashboardHandler,
  createLiveDashboardHandler,
}) => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <Box className="create-live-dashboard-button">
      {isEditLiveDashboard ? (
        <LucButton onClick={() => history.goBack()} variant="outline">
          {CheckValueLocale("cancel", "", {}, intl)}
        </LucButton>
      ) : null}
      <LucButton
        disabled={actionButtonDisabled}
        loading={isActionLoading}
        onClick={
          isEditLiveDashboard
            ? editLiveDashboardHandler
            : createLiveDashboardHandler
        }
      >
        {CheckValueLocale(
          isEditLiveDashboard ? "save_changes" : "create_live_dashboard",
          "",
          {},
          intl,
        )}
      </LucButton>
    </Box>
  );
};

export default LiveDashBoardActions;
