import { useState } from "react";
import LoadingSpinner from "../loadingSpinner/loadingSpinner.js";
// Save widget image
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
//Save widget PDF
import jsPDF from "jspdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowToBottom, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers/index.js";

const WidgetDownloader = (props) => {
  const intl = useIntl();
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [showDownloadBox, setShowDownloadBox] = useState(true);

  let { downloadBtnId, showAILine = null } = props;

  //handle download IMG function
  const downloadImg = (widgetId, downLoadType, imgSize) => {
    setShowDownloadBox(false);
    setDownloadLoader(true);
    setTimeout(() => {
      var node = document.getElementById(widgetId);
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          if (downLoadType === "PNG") {
            download(dataUrl, widgetId + ".png");
          } else if (downLoadType === "PDF") {
            var pdf = new jsPDF({ orientation: "landscape" });
            if (imgSize != undefined && imgSize === true) {
              const imgProps = pdf.getImageProperties(dataUrl);
              //get PdfWidth
              const pdfWidth = pdf.internal.pageSize.getWidth();
              //get PdfHeight
              const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
              pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
            } else {
              pdf = new jsPDF({ orientation: "portrait" });
              pdf.addImage(dataUrl, "PNG", 0, 0);
            }
            const isFirefox =
              window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
            {
              isFirefox
                ? pdf.output("dataurlnewwindow")
                : pdf.save(widgetId + ".pdf");
            }
          }
          setDownloadLoader(false);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }, 3000);
  };

  return (
    <div
      id={downloadBtnId ?? "widget-download-btn"}
      onMouseOver={() => setShowDownloadBox(true)}
      onMouseLeave={() => setShowDownloadBox(true)}
      className={`${showAILine ? "show-ai-line" : ""}`}
    >
      <button className="arrowBtn">
        {downloadLoader ? (
          <LoadingSpinner />
        ) : (
          <FontAwesomeIcon icon={faArrowToBottom} />
        )}
        {showDownloadBox ? (
          <div className="dropdown-contentDiv">
            <button
              className="exportBtn"
              onClick={() => downloadImg(props.chartId, "PNG")}
              id={`widget-download-${downloadBtnId ?? "downloadBtnId"}-as-png-btn`}
            >
              {CheckValueLocale("download_as_png", "", {}, intl)}
            </button>
            <button
              className="exportBtn"
              onClick={() => downloadImg(props.chartId, "PDF", props.bigImg)}
              id={`widget-download-${downloadBtnId ?? "downloadBtnId"}-as-pdf-btn"`}
            >
              {CheckValueLocale("download_as_pdf", "", {}, intl)}
            </button>
          </div>
        ) : null}
      </button>
    </div>
  );
};

export default WidgetDownloader;
