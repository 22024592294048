import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import SurveyController from "services/controllers/surveyController";

const SurveyModalFunctions = (props) => {
  const intl = useIntl();
  const location = useLocation();
  const checkIsSurveyPage = location?.pathname?.includes("survey/list");
  const [open, setOpen] = useState(props?.openPublishSurveyModal);
  const [copied, setCopied] = useState(false);
  const [qrDown, setQrDown] = useState(false);
  const [domainActive, setDomainActive] = useState();
  const [surveyPanelList, setSurveyPanelList] = useState([]);
  const [surveyPanel, setSurveyPanel] = useState({});
  const [checkSwitch, setCheckSwitch] = useState(false);
  const [value, setValue] = useState("1");
  const [stepThree, setStepThree] = useState(false);
  const [isSurveyPanelEnabled, setIsSurveyPanelEnabled] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    Countries: [],
    Age: [],
    Industries: [],
    Gender: [],
  });
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    message: "",
    severity: "",
  });
  const checkEmptyValue =
    selectedValues?.Countries?.length == 0 ||
    selectedValues?.Age?.length == 0 ||
    selectedValues?.Gender?.length == 0 ||
    selectedValues?.Industries?.length == 0;

  const id = props?.surveyData?.id;

  const styles = makeStyles({
    tooltip: { fontSize: "12px", padding: "10px", width: "220px" },
  });

  const stepTwo =
    props?.customDomainStep ||
    checkIsSurveyPage ||
    props?.surveyData?.published;
  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(
      `${checkIfLucidyaCustomDomain}${props?.surveyData?.url_slug}`,
    );
  };

  const getSurveyspanelList = () => {
    SurveyController.getSurveyspanelList().then((result) => {
      setSurveyPanelList(result?.data);
    });
  };

  const submitSurveyspanel = () => {
    setLoading(true);
    SurveyController.submitSurveyspanel(
      "[" + selectedValues?.Age?.map((el) => '"' + el + '"')?.join(",") + "]",
      id,
      "[" +
        selectedValues?.Gender?.map((el) => '"' + el + '"')?.join(",") +
        "]",
      "[" +
        selectedValues?.Industries?.map((el) => '"' + el + '"')?.join(",") +
        "]",
      `https://survey.lucidya.com/${props?.surveyData?.url_slug}`,
      props?.surveyData?.name,
      "[" +
        selectedValues?.Countries?.map((ip) => '"' + ip + '"')?.join(",") +
        "]",
    ).then((result) => {
      if (result?.data?.status == 200) {
        SurveyspanelById();
        submitSurveysSkip();
        setSnackBar({
          open: true,
          title: "survey_panel_request",
          message: "survey_success_msg",
          severity: "success",
        });
      } else {
        setSnackBar({
          open: true,
          title: "",
          message: "survey_wrong_msg",
          severity: "error",
        });
      }
      setLoading(false);
      setTimeout(() => {
        setSnackBar({
          open: false,
        });
      }, 3000);
    });
  };

  const submitSurveysSkip = () => {
    SurveyController.submitSurveysSkip(id).then((result) => {
      props?.setIsSkeipped(result?.data?.is_skipped);
    });
  };

  const SurveyspanelById = () => {
    SurveyController.SurveyspanelById(id).then((result) => {
      setSelectedValues({
        Countries: [...result?.data?.data?.country],
        Age: [...result?.data?.data?.age],
        Industries: [...result?.data?.data?.industry],
        Gender: [...result?.data?.data?.gender],
      });
      setSurveyPanel(result?.data);
      setIsSurveyPanelEnabled(result?.data?.isSurveyPanelEnabled);
    });
  };

  const handleSubmit = () => {
    props?.handleSaveChanges("", true);
    setTimeout(() => {
      props?.setCustomDomainStep(true);
    }, 2000);
  };

  //handle download IMG function
  const downloadImg = (widgetId) => {
    var node = document.getElementById(widgetId);
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        download(dataUrl, widgetId + ".png");
        setQrDown(false);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const handleDomainClick = (id) => {
    setDomainActive(id);
    if (id == 1) props?.setIsLucidyaDomain(true);
    else props?.setIsLucidyaDomain(false);
  };

  const handleCloseModal = () => {
    props?.setOpenPublishSurveyModal(!props?.openPublishSurveyModal);
  };

  const checkIfLucidyaCustomDomain = props?.surveyData?.custom_domain
    ? `${props?.surveyData?.custom_domain}/`
    : "https://survey.lucidya.com/";

  //step two [ done ]
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  // share survey domain options
  const shareSurvey = [
    {
      name: "survey_lucidya_domain",
      desc: "survey_custom_domain_lucidya",
      tooltip: "survey_lucidya_domain_tooltip",
      type: "lucidya_domain",
      id: 1,
    },
    {
      name: "company_custom_domain",
      desc: "survey_custom_domain_description",
      tooltip:
        props?.customDomain == ""
          ? "survey_not_have_custom_domain"
          : "survey_custom_domain_tooltip",
      type: "custom_domain",
      id: 2,
    },
  ];

  const handleChange = (event) => setCheckSwitch(event.target.checked);
  const handelSendRequest = () => submitSurveyspanel();

  return {
    intl,
    open,
    copied,
    domainActive,
    surveyPanelList,
    surveyPanel,
    checkSwitch,
    value,
    stepThree,
    setStepThree,
    isSurveyPanelEnabled,
    error,
    setError,
    loading,
    selectedValues,
    setSelectedValues,
    snackBar,
    checkEmptyValue,
    stepTwo,
    handleCopy,
    handleSubmit,
    handleDomainClick,
    handleCloseModal,
    shareSurvey,
    handleChangeTabs,
    handleChange,
    handelSendRequest,
    downloadImg,
    getSurveyspanelList,
    SurveyspanelById,
    checkIsSurveyPage,
    checkIfLucidyaCustomDomain,
    submitSurveysSkip,
    styles,
  };
};

export default SurveyModalFunctions;
