import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import "./slaSideBar.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const SlaSidebar = ({
  filters,
  setFilters,
  setTabValue,
  applyFilters,
  slaCounterData,
  isOpen,
  setIsOpen,
}) => {
  const intl = useIntl();

  let slaItems = [
    "my_assigned_sla",
    "team_assigned_sla",
    "unassigned_sla",
    "all_sla",
    "overdue_sla",
  ];

  return (
    <Box className={`main-sla-sidebar ${!isOpen ? "sla-closed" : ""}`}>
      <Box className={"sla-sidebar-button"} onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={isOpen ? faChevronLeft : faChevronRight} />
      </Box>

      <Box className={`items-sla-sidebar ${!isOpen ? "items-sla-closed" : ""}`}>
        <Box className="menu-sla-sidebar">
          {CheckValueLocale("menu", "", {}, intl)}
        </Box>
        {slaItems?.map((item, x) => (
          <Box
            className={`menu-item-sla-sidebar ${
              item == filters?.sla_status ? "item-selected" : ""
            }`}
            key={x}
            onClick={() => {
              if (item === filters.sla_status) return;
              filters.sla_status = item;
              filters.page = 1;
              filters.engagement_status = "new";
              setTabValue(1);
              setFilters({ ...filters });
              applyFilters();
            }}
          >
            <Box> {CheckValueLocale(item, "", {}, intl)}</Box>
            {slaCounterData?.loading ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              <Box className="menu-item-sla-count">
                {+slaCounterData?.values[item?.replace("_sla", "")] >= 1000
                  ? `${(+slaCounterData?.values[item?.replace("_sla", "")] / 1000).toFixed()}K+`
                  : `${+slaCounterData?.values[item?.replace("_sla", "")]}`}
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SlaSidebar;
