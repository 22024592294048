import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { FormHelperText } from "@mui/material";
import CustomDomainInfo from "./components/customDomainInfo/customDomainInfo";
import CompaniesController from "services/controllers/companiesController";
import { validateURL } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { CheckValueLocale } from "utils/helpers";
import "./customDomain.scss";

const CustomDomain = (props) => {
  const intl = useIntl();
  const [customDomainName, setCustomDomainName] = useState("");
  const [customDomainError, setCustomDomainError] = useState(false);
  const [formFieldErrors, setFormFieldErrors] = useState({
    name: "",
  });

  const [customDomainLoading, setCustomDomainLoading] = useState(false);
  const handleInputChange = (e) => {
    let value = e?.target?.value;
    let customDomainNameError = "";

    setCustomDomainName(value);
    setCustomDomainError(false);
    if (validateURL(value)) {
      customDomainNameError = "custom_domain_validation";
    }
    setFormFieldErrors({
      ...formFieldErrors,
      name: customDomainNameError,
    });
  };

  const handleSubmitCustomDomain = () => {
    let customDomainInfo = `survey.${customDomainName}`;
    CompaniesController.sendAndDeleteCustomDomain(customDomainInfo).then(
      (data) => {
        setCustomDomainLoading(true);
        if (data?.status === 200) {
          setCustomDomainLoading(false);
          props?.getCompanyInfo();
        } else {
          setCustomDomainLoading(false);
          setCustomDomainError(true);
        }
      },
    );
  };

  return (
    <>
      <Box className="custom-domain-head">
        {CheckValueLocale("company_custom_domain", "", {}, intl)}
      </Box>
      <Box className="custom-domain-wrapper">
        <Box className="custom-domain-desc">
          {CheckValueLocale("add_domain_when_publishingـsurveys", "", {}, intl)}
        </Box>
        {props?.customDomain == "" || props?.customDomain == null ? (
          <Box>
            <Box className="custom-domain-input-wrapper">
              <TextField
                placeholder={CheckValueLocale(
                  "custom_domain_example",
                  "",
                  {},
                  intl,
                )}
                error={
                  formFieldErrors?.name || customDomainError ? true : false
                }
                value={customDomainName}
                onChange={(e) => handleInputChange(e)}
                InputProps={{
                  startAdornment: `${CheckValueLocale(
                    "custom_domain_survey",
                    "",
                    {},
                    intl,
                  )}.`,
                }}
              />
              <Button
                variant="contained"
                onClick={handleSubmitCustomDomain}
                loading={true}
                loadingPosition="start"
                className="custom-connect-btn"
                disabled={
                  customDomainName == "" || formFieldErrors?.name !== ""
                }
              >
                <Box component="span">
                  {customDomainLoading ? (
                    <CircularProgress className="custom-domain-loading" />
                  ) : (
                    CheckValueLocale("custom_domain_connect", "", {}, intl)
                  )}
                </Box>
              </Button>
            </Box>
            {formFieldErrors?.name || customDomainError ? (
              <FormHelperText error>
                {CheckValueLocale(
                  customDomainError
                    ? "custom_domain_validation"
                    : formFieldErrors?.name,
                  "",
                  {},
                  intl,
                )}
              </FormHelperText>
            ) : null}
          </Box>
        ) : (
          <CustomDomainInfo
            customDomain={props?.customDomain}
            setCustomDomain={props?.setCustomDomain}
            setCustomDomainName={setCustomDomainName}
            setCustomDomainError={setCustomDomainError}
          />
        )}
      </Box>
    </>
  );
};

export default CustomDomain;
