import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2Plus } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import LiveDashboardModel from "shared/liveDashboardModel/liveDashboardModel";
import { useState } from "react";
import CustomizedTooltip from "components/customizedTooltip";

const MonitorCreateLiveDashboard = ({ monitorData }) => {
  const intl = useIntl();
  const history = useHistory();
  const [openLiveDashboardModel, setOpenLiveDashboardModel] = useState(false);

  const handleLiveDashboardModel = () =>
    setOpenLiveDashboardModel(!openLiveDashboardModel);

  const handleCreateLiveDashboard = () => {
    const monitorType = monitorData?.attributes?.monitor_type_name;
    const monitorId = monitorData?.id;
    history.push({
      pathname: `/create-live-dashboard/${monitorType}/${monitorId}`,
      state: monitorData,
    });
  };

  return (
    <>
      <CustomizedTooltip
        arrow
        placement="bottom"
        title={
          <Box className="tooltip-custom-new">
            {CheckValueLocale("create_live_dashboard", "", {}, intl)}
          </Box>
        }
      >
        <Box>
          <LucButton
            id="create-live-dashboard-monitor-header-btn"
            className="monitor-header-action-btn"
            type="secondary"
            variant="flat"
            minWidth={42}
            onClick={handleLiveDashboardModel}
          >
            <FontAwesomeIcon icon={faGrid2Plus} size="lg" />
          </LucButton>
        </Box>
      </CustomizedTooltip>
      {openLiveDashboardModel ? (
        <LiveDashboardModel
          open={openLiveDashboardModel}
          handleClose={handleLiveDashboardModel}
          handleCreate={handleCreateLiveDashboard}
        />
      ) : null}
    </>
  );
};

export default MonitorCreateLiveDashboard;
