import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { CustomerCareBody } from "./CustomerCareBody";
import { useState } from "react";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { useEditCustomerCare } from "../hooks/useEditCustomerCare";
import "../manageChannels.scss";

export const EditCustomerCareModal = ({
  open,
  close,
  channelId,
  customerCare,
}) => {
  const intl = useIntl();
  const [customerCareVal, setCustomerCareVal] = useState(
    customerCare?.length ? customerCare : [],
  );
  const [errorCustomerCare, setErrorCustomerCare] = useState(false);
  const { mutate: editCustomerCare, isPending: isEditCustomerCarePending } =
    useEditCustomerCare();

  const handleEditCustomerCare = () => {
    if (errorCustomerCare) {
      return;
    }
    editCustomerCare(
      {
        channelId,
        customerCareVal,
      },
      {
        onSuccess: () => {
          close();
          handleCloseCustomerCareModal();
        },
      },
    );
  };

  const handleInputChange = (event) => {
    let val = event?.target?.value;
    setCustomerCareVal([val]);
    if (isPublicSpecialCharactersOrEmojis(val)) {
      setErrorCustomerCare(true);
    } else {
      setErrorCustomerCare(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
    >
      <Box className="customer-care-modal">
        <DialogTitle id="alert-dialog-title">
          {CheckValueLocale("customer_care_account_options", "", {}, intl)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CustomerCareBody
              handleInputChange={handleInputChange}
              customerCareVal={customerCareVal}
              errorCustomerCare={errorCustomerCare}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <LucButton
            onClick={close}
            id="pop-up-left-btn"
            variant="flat"
            type="secondary"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>

          <LucButton
            disabled={isEditCustomerCarePending}
            onClick={handleEditCustomerCare}
            id="pop-up-right-btn"
            variant="filled"
            type="red"
            className="popup__rightbtn"
            loading={isEditCustomerCarePending}
          >
            {CheckValueLocale("apply_ingagement", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
