import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  isContaionArabicPunctuation,
  isPublicSpecialCharactersOrEmojis,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import CompaniesController from "services/controllers/companiesController";
import AlertController from "services/controllers/alertController";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { isValidURL } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/newsBlogsSources";
import SharedFunctionsKeywords from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/sharedFunctionsKeywords";
import { useSortableTable } from "hooks/useSortableTable";
import { getAlertTableHead } from "../list/AlertList";

const AlertsFunctions = (isMonitorAction) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [fillterSearch, setFillterSearch] = useState([]);

  const [alertTypeNames, setAlertTypeNames] = useState([]);

  const [getAlertsLoading, setGetAlertsLoading] = useState(true);
  const [getEditAlertsLoading, setEditGetAlertsLoading] = useState(true);
  const [getApisLoading, setGetApisLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [openFormType, setOpenFormType] = useState("");
  const [alertName, setAlertName] = useState("");
  const [productType, setProductType] = useState("");
  const [datasourceType, setDatasourceType] = useState("");
  const [alertType, setAlertType] = useState("");
  const [alertConversationType, setAlertConversationType] = useState("");
  const [alertRateType, setAlertRateType] = useState("");
  const [alertsInfo, setAlertsInfo] = useState([]);
  const [selectedMonitors, setSelectedMonitors] = useState([]);
  const [monitorsList, setMonitorsList] = useState([]);
  const [sourcesListEngagements, setSourcesListEngagements] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [recieveAlertsHourly, setRecieveAlertsHourly] = useState(1);
  const [alertThresholdType, setAlertThresholdType] = useState("");
  const [thresholdManualValue, setThresholdManualValue] = useState(1);
  const [maxmNumberEmailsValue, setMaxmNumberEmailsValue] = useState(1);
  const [maxmNumValue, setMaxmNumValue] = useState(1);
  const [informerMaxEmails, setInformerMaxEmails] = useState(1);

  const [formFieldErrors, setFormFieldErrors] = useState({
    name: "",
    recieveAlertsHourly: "",
    thresholdManualVal: "",
    alertsEmails: "",
    keywordError: "",
    userPostsError: "",
    mimimumNoFollowers: "",
    errAlertMax: "",
    mimimumNoInteractions: "",
    url: "",
    verificationKey: "",
    authVarName: "",
    apiKey: "",
  });
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [emailsEnabled, setEmailsEnabled] = useState(true);
  const [keywordsFocused, setKeywordsFocused] = useState(false);
  const [alertKeywords, setAlertKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState("");
  const [checkKeywords, setCheckKeywords] = useState(false);
  const [checkUserPosts, setCheckUsersPosts] = useState(false);
  const [postUsers, setPostUsers] = useState("");
  const [selectedPostUsers, setSelectedPostUsers] = useState([]);
  const [minimumNoFollowers, setMiniumimNoFollowers] = useState(2500);
  const [showAlertActionMessage, setShowAlertActionMessage] = useState({
    message: "",
    severity: "",
    title: "",
  });
  // const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [alertEditData, setAlertEditData] = useState({});
  const [alertsNotificationType, setAlertsNotificationType] = useState("");
  const [monitorsLoading, setMonitorsLoading] = useState(true);
  const [sourcesLoadingEngagements, setSourcesLoadingEngagements] =
    useState(true);
  const [minimumNoInteractions, setMiniumimNoInteractions] = useState(1);
  const [alertWebhookInfo, setAlertwebhookinfo] = useState({});
  const [enableWebhooks, setEnableWebhooks] = useState(false);
  const [lucidyaApisList, setLucidyaApisList] = useState([]);
  const [keyLocationList, setKeyLocationList] = useState([]);
  const [apiKey, setApiKey] = useState("");
  const [method, setMethod] = useState("POST");
  const [url, setUrl] = useState("");
  const [verificationKey, setVerificationKey] = useState("");
  const [authVarName, setAuthVarName] = useState("verification_key");
  const [authLocation, setAuthLocation] = useState([]);
  const [locationString, setLocationString] = useState([]);
  const [testWebhooksResult, setTestWebhooksResult] = useState("");
  const [testWebhooksLoading, setTestWebhooksLoading] = useState(false);
  const [deleteWebhooksLoading, setDeleteWebhooksLoading] = useState(false);
  const [enableWebhooksLoading, setEnableWebhooksLoading] = useState(false);
  const [disableWebhooksLoading, setDisableWebhooksLoading] = useState(false);
  //keywords shared functions.
  const [dataExist, setDataExist] = useState(true);
  const { handleValidationKeywords, handlekeywordsShared } =
    SharedFunctionsKeywords();
  const onSortChangeCallback = () => {
    setIsSortableLoading(true);
    setPage(1);
  };
  const {
    columns,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: getAlertTableHead(intl),
    onSortChangeCallback,
    defaultSelectedColumn: "created_at",
    defaultDirection: "desc",
  });
  const getFilteredData = (data, filterType, isFilterDatasource) => {
    let filteredData =
      (data?.length &&
        data?.filter((item) => {
          return isFilterDatasource
            ? +item?.id === +filterType?.id &&
                +item?.channel_type_id === +filterType?.channel_type_id
            : +item?.id === +filterType;
        })[0]) ||
      [];
    return filteredData;
  };
  const splitDataSourceType = () => {
    let obj = "";
    let selectedArrDatasources = datasourceType?.split("-");
    if (datasourceType) {
      obj = {
        id: selectedArrDatasources?.length && +selectedArrDatasources[0],
        name: selectedArrDatasources?.length && selectedArrDatasources[1],
        channel_type_id:
          selectedArrDatasources?.length && +selectedArrDatasources[2],
      };
    }

    return obj;
  };
  //Here we set data that returned from BE and we filtered data for specific type
  let isManualAlert = alertThresholdType?.toLowerCase() === "manual";
  let isHourlyType = alertRateType?.toLowerCase() === "hourly";
  let isCreateAlert = openFormType === "addAlert" ? true : false;
  let filteredAlertsData = getFilteredData(alertsInfo, productType);
  let alertsSources = filteredAlertsData?.sources || [];
  let filteredDatasource = getFilteredData(
    alertsSources,
    splitDataSourceType(),
    true,
  );
  let alertsTypes = filteredDatasource?.alert_types || [];
  let alertsConversationTypes = filteredDatasource?.conversation_types || [];

  let filteredRate = getFilteredData(alertsTypes, alertType);
  let alertRates = filteredRate?.rates || [];
  let alertThresold = filteredRate?.threshold || [];
  let activeAlertType = filteredRate?.name?.toLowerCase();
  let isInfluncerType = activeAlertType === "influencer";
  let isViralTweetType = activeAlertType === "viral tweet";
  let isInstantType = alertRateType?.toLowerCase() === "instant";
  let isNegavtivePostsType =
    activeAlertType?.toLowerCase() === "negative posts";
  let isInstagramDatasource =
    filteredDatasource?.name?.toLowerCase() === "instagram";
  let activeProductNames = filteredAlertsData?.name?.toLowerCase();

  const regex = /^[0-9\b]+$/;
  const usersRegx = /^[A-Za-z0-9_]+$/;
  const alertTypeLocale = (item, datasourceName) => {
    let localeItem = item?.toLowerCase()?.split(" ")?.join("_");
    let alertsLocales = {
      "twitter[dm]": `${localeItem}_alert_dm`,
      "facebook[dm]": `${localeItem}_alert_dm`,
      "instagram[dm]": `${localeItem}_alert_dm`,
      genesys: `${localeItem}_alert_genesys`,
      googlemybusiness: `${localeItem}_alert_gmb`,
      intercom: `${localeItem}_alert_dm`,
      whatsapp: `${localeItem}_alert_dm`,
      survey: "new_post_alerts_survey",
      tiktok: `${localeItem}_alert_tiktok`,
      engagements: `${localeItem}_alert_engagement`,
      default: localeItem + "_alert_v2",
    };
    const val = alertsLocales[datasourceName] || alertsLocales.default;
    return CheckValueLocale(val, "", {}, intl);
  };

  const getPostsTypeTitle = () => {
    let increaseRateVal = {
      "negative posts": "alert_negative_posts_increase_rate",
      "high volume reach": "alert_reach_increase_rate",
      default: "alert_posts_increase_rate",
    };
    return increaseRateVal[activeAlertType] || increaseRateVal.default;
  };

  const getAlertsTypes = () => {
    AlertController?.getAlertsTypesV2()?.then((res) => {
      if (res?.data?.data) {
        let alertTypeNamesArr = res?.data?.data?.map((item) => ({
          value: item?.attributes?.alert_type,
          id: item?.id,
        }));
        if (res?.data?.webhook_feature_enabled) {
          alertTypeNamesArr = [
            ...alertTypeNamesArr,
            {
              value: "webhook_feature_enabled",
              id: "webhook_feature_enabled",
            },
          ];
        }
        setAlertTypeNames(alertTypeNamesArr);
      }
    });
  };

  const getAlerts = (page = 1, selectedAlert = []) => {
    if (!isSortableLoading) setLoading(true);
    let typeValue = [];
    let webhookFilterValue = null;
    if (selectedAlert.includes("webhook_feature_enabled")) {
      webhookFilterValue = true;
      typeValue = selectedAlert.filter(
        (item) => item !== "webhook_feature_enabled",
      );
    } else {
      typeValue = selectedAlert;
    }
    AlertController?.getAlerts(
      page,
      typeValue?.join(","),
      webhookFilterValue,
      sortColumn,
      sortDirection,
    ).then((res) => {
      if (res?.errorMsg) {
        setAlerts([]);
        setNumberOfPages(0);
        setShowAlertActionMessage({
          message: "try_again_error_message",
          severity: "error",
        });
        setShowSnackBar(true);
      } else {
        setAlerts(res?.data?.data);
        setNumberOfPages(res?.pagination?.pages);
      }
      setLoading(false);
      setIsSortableLoading(false);
    });
  };
  // listen to changes in sortColumn and sortDirection
  useEffect(() => {
    // call only if isSortableLoading is true
    if (!isSortableLoading) return;
    getAlerts(1, selectedTypes);
  }, [sortColumn, sortDirection]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    getAlerts(page, selectedTypes);
    setPage(page);
  };

  const handleTypeSelection = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTypes(value);
    getAlerts(1, value);
    setPage(1);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setOpenFormType("");
    resetFormData();
  };

  const handleOpenForm = (type) => {
    setOpenForm(true);
    setOpenFormType(type);
  };

  //hanlde alert name
  const handleInputChange = (e) => {
    let value = e?.target?.value;
    let alertNameErrorMsg = "";
    if (value === "") {
      alertNameErrorMsg = "alert_field_required";
    } else if (value?.length > 20) {
      alertNameErrorMsg = "alert_limit";
    } else if (
      isPublicSpecialCharactersOrEmojis(value, undefined, true) ||
      removeSpecialCharacters(value)?.length === 0
    ) {
      alertNameErrorMsg = "alert_rules";
    } else {
      alertNameErrorMsg = "";
    }
    setFormFieldErrors({
      ...formFieldErrors,
      name: alertNameErrorMsg,
    });
    setAlertName(value);
  };

  const verificationKeyValidation = (string) => {
    let acceptedChar = new RegExp(
      /^[a-zA-Z0-9\=\$\@\<\>\{\}\(\)\[\]\^\!\%\&\s]*$/g,
    );
    return !acceptedChar.test(string);
  };

  const handleVerificatinKeyChange = (e) => {
    let value = e?.target?.value;
    let alertVerificationKeyErrorMsg = "";
    if (value === "" || verificationKeyValidation(value))
      alertVerificationKeyErrorMsg = "ai_api_verification_key_validation";
    else if (value?.length < 10)
      alertVerificationKeyErrorMsg = "ai_api_verification_key_short_error";
    else if (value?.length > 128)
      alertVerificationKeyErrorMsg = "ai_api_verification_key_long_error";

    setFormFieldErrors({
      ...formFieldErrors,
      verificationKey: alertVerificationKeyErrorMsg,
    });
    setVerificationKey(value);
  };

  const authVarNameValidation = (string) => {
    let acceptedChar = new RegExp(/^[a-zA-Z][a-zA-Z0-9_]*$/g);
    return !acceptedChar.test(string);
  };

  const handleAuthVarName = (e) => {
    let value = e?.target?.value;
    let errorMsg = "";
    if (value === "" || authVarNameValidation(value))
      errorMsg = "auth_variable_name_error";

    setFormFieldErrors({
      ...formFieldErrors,
      authVarName: errorMsg,
    });
    setAuthVarName(value);
  };

  const handleAuthLocationChange = (e) => {
    const value = e?.target?.value;
    let allLocations = [];
    keyLocationList?.map((item) => allLocations?.push(item?.id));
    if (value.includes("select_all")) setAuthLocation(allLocations);
    else if (value.includes("unselect_all")) setAuthLocation([]);
    else setAuthLocation(value);
  };

  useEffect(() => {
    if (keyLocationList?.length >= 1) {
      let idArr = [];
      idArr?.push(keyLocationList[0]?.id);
      isCreateAlert === true && setAuthLocation(idArr);
    }
  }, [keyLocationList]);

  useEffect(() => {
    let locationsArr = [];
    if (authLocation)
      authLocation?.map((item) => {
        keyLocationList?.map((loc) => {
          if (loc?.id === item) locationsArr?.push(loc?.name);
        });
      });
    setLocationString(locationsArr);
  }, [authLocation]);

  //get alert data
  const getAlertData = () => {
    setGetAlertsLoading(true);
    AlertController.getAlertsData().then((res) => {
      setAlertsInfo(res?.data?.alerts_info);
      res?.data?.alerts_notification_types &&
        setAlertsNotificationType(res?.data?.alerts_notification_types[0]);
      res?.data?.max_emails && setInformerMaxEmails(res?.data?.max_emails);
      res?.data?.max_emails && setMaxmNumValue(res?.data?.max_emails);
      setAlertwebhookinfo(res?.data?.alert_webhook_info);
      setGetAlertsLoading(false);
      if (
        res?.data?.alert_webhook_info?.webhook_feature_enabled &&
        handlelUserRoles("PUBLIC_API", "CREATE_WEBHOOK_URL")
      ) {
        getLucidyaApisList();
      }
    });
  };
  //get filtered Data

  const handleChangeMultipleSelection = (event, allData) => {
    const { value, name } = event.target;

    let monitors = [...value];
    if (value?.includes("all")) {
      if (monitors?.length - 1 == allData?.length) {
        monitors = [];
      } else {
        monitors = [];
        allData?.map((item) => {
          monitors.push(item);
        });
      }
    }
    if (name === "alertUserEmails") {
      return setSelectedEmails(monitors);
    } else {
      setSelectedMonitors(monitors);
    }
  };

  const handleChangeSourcesEngagements = (event) => {
    const { value } = event.target;
    let sources = [...value];
    setSelectedSources(sources);
  };
  const handleSourcesIds = (allData) => {
    let dataIds = [];
    allData?.map((el) => {
      dataIds.push(el?.id);
    });
    return dataIds;
  };
  const getAlertsMonitors = (
    productId,
    datasourceId,
    alertTypeId,
    channelTypeId,
  ) => {
    setMonitorsLoading(true);
    setMonitorsList([]);
    AlertController.getAlertsMonitors(
      productId,
      datasourceId,
      alertTypeId,
      channelTypeId,
    ).then((res) => {
      setMonitorsList(res?.data?.monitors);
      setMonitorsLoading(false);
    });
  };

  // in case engagement
  const getAlertMonitorsEngagements = (conversationTypeId) => {
    let queryData = {
      monitors_types: conversationTypeId,
      product_id: window.localStorage.engagements_id,
    };
    setSourcesLoadingEngagements(true);
    setSourcesListEngagements([]);
    AlertController.getAlertMonitorsEngagements(queryData).then((res) => {
      setSourcesListEngagements(res?.data);
      setSourcesLoadingEngagements(false);
    });
  };

  const handleRecieveAlertsHourly = (event) => {
    const { value } = event.target;
    let errorMsgRecieveAlert = "";
    if (value === "" || (+value > 0 && +value <= 24)) {
      setRecieveAlertsHourly(value);
    }
    (value === "" || !regex.test(value)) &&
      (errorMsgRecieveAlert = "alerts_invalid_value");

    setFormFieldErrors({
      ...formFieldErrors,
      recieveAlertsHourly: errorMsgRecieveAlert,
    });
  };

  const handleMinimumNoFollowrs = (event) => {
    const { value } = event.target;
    let errorMsgMinimumFolower = "";
    setMiniumimNoFollowers(value);
    if (value === "" || !regex.test(value) || +value < 2500)
      errorMsgMinimumFolower = "alerts_invalid_value";

    setFormFieldErrors({
      ...formFieldErrors,
      mimimumNoFollowers: errorMsgMinimumFolower,
    });
  };

  const getComanyEmails = () => {
    CompaniesController.getCompanyActiveUsers().then((res) => {
      setCompanyUsers(res?.data?.data);
    });
  };

  const handleKeywordChange = (event) => {
    const { value } = event.target;
    setSelectedKeywords(value);
    let keywordsCopy = [...alertKeywords];

    //using shared
    let errorMessage = handleValidationKeywords(
      value,
      keywordsCopy?.length ? keywordsCopy : [],
      [],
      "keywords",
      setDataExist,
      "twitter",
    );
    setFormFieldErrors({ ...formFieldErrors, keywordError: errorMessage });
  };

  const handleKeywords = (event, clickType) => {
    const { value } = event.target;
    if (
      (event.keyCode === 13 || (clickType === "btn" && value)) &&
      dataExist &&
      value?.trim() !== ""
    ) {
      const keywordsCopy = [...alertKeywords];

      let objData = handlekeywordsShared(
        value,
        "keywords",
        keywordsCopy?.length ? keywordsCopy : [],
        [],
        "twitter",
        [
          {
            keywords: keywordsCopy?.length ? keywordsCopy : [],
          },
          {
            spam_keywords: [],
          },
        ], //keywords such as [{keywords:[]},{spamKeywords:[]}]
        100,
        undefined, //in case newsblogs,
        undefined,
      );

      if (!objData?.errorMsg) {
        setAlertKeywords([...objData?.keywords]);
        setSelectedKeywords("");
        setFormFieldErrors({
          ...formFieldErrors,
          keywordError: "",
        });
      } else {
        setSelectedKeywords(value);
        setFormFieldErrors({
          ...formFieldErrors,
          keywordError: objData?.errorMsg,
        });
      }
    }
  };

  const handleDeleteKeyword = (i) => {
    const keywordsCopy = [...alertKeywords];
    keywordsCopy?.splice(i, 1);
    setAlertKeywords(keywordsCopy);
    setFormFieldErrors({ ...formFieldErrors, keywordError: "" });
    setSelectedKeywords("");
  };
  const handleChangeAlertsFields = (event) => {
    const { value } = event.target;
    let errorMsgIncreaseAlert = "";
    if (value === "" || (+value > 0 && +value <= 100000)) {
      setThresholdManualValue(value);
    }
    (value === "" || !regex.test(value)) &&
      (errorMsgIncreaseAlert = "alerts_invalid_value");

    setFormFieldErrors({
      ...formFieldErrors,
      thresholdManualVal: errorMsgIncreaseAlert,
    });
  };
  const handleChangeAlertsMaximum = (event) => {
    const { value } = event.target;
    let errAlertMax = "";
    let val = value;
    if (val === "" || +val > 0) {
      setMaxmNumValue(val);
    } else if (+val < 1) {
      val = 1;
      setMaxmNumValue(val);
    }
    (val === "" || +val < 1 || !regex.test(val)) &&
      (errAlertMax = "alerts_invalid_value");

    setFormFieldErrors({
      ...formFieldErrors,
      errAlertMax: errAlertMax,
    });
  };
  const showAdavancedKeywords = () => {
    return (
      activeAlertType === "new post" ||
      activeAlertType === "viral tweet" ||
      activeAlertType === "influencer" ||
      activeAlertType === "verified author"
    );
  };
  //handle cpnversation types
  const convertDataConverstion = (data) => {
    let newrArrData = [];
    data?.map((el) => {
      newrArrData.push(+el);
    });
    return newrArrData;
  };
  //here we check disable create button
  const checkDisableCreateAlert = () => {
    return (
      formFieldErrors?.name !== "" ||
      (isHourlyType &&
        (formFieldErrors?.recieveAlertsHourly !== "" ||
          recieveAlertsHourly === "")) ||
      alertName?.trim() === "" ||
      productType === "" ||
      datasourceType === "" ||
      alertType === "" ||
      (activeProductNames !== "engagements" && alertRateType === "") ||
      formFieldErrors?.errAlertMax !== "" ||
      (activeProductNames == "engagements"
        ? !selectedSources?.length
        : !selectedMonitors?.length) ||
      (isManualAlert &&
        (thresholdManualValue === "" ||
          formFieldErrors?.thresholdManualVal !== "")) ||
      formFieldErrors?.keywordError !== "" ||
      formFieldErrors?.userPostsError !== "" ||
      (isInfluncerType && formFieldErrors?.mimimumNoFollowers !== "") ||
      (isInstagramDatasource &&
        !selectedPostUsers?.length &&
        showAdavancedKeywords() &&
        isInfluncerType) ||
      (isInstantType &&
        isViralTweetType &&
        (formFieldErrors?.mimimumNoInteractions !== "" ||
          minimumNoInteractions == "" ||
          minimumNoInteractions == 0)) ||
      (enableWebhooks &&
        (apiKey === "" ||
          method === "" ||
          url?.trim() === "" ||
          verificationKey === "" ||
          authVarName === "" ||
          formFieldErrors?.authVarName !== "" ||
          authLocation?.length === 0 ||
          testWebhooksResult !== "passed" ||
          formFieldErrors?.apiKey !== ""))
    );
  };

  const handlePostsUsersChange = (event) => {
    const { value } = event.target;
    setPostUsers(value);

    let errorPostsUsers = "",
      isExistPosts = true,
      usersPosts = [...selectedPostUsers],
      newPostsUsers = usersPosts,
      lowerCaseKeywords = newPostsUsers?.map((element) =>
        element?.toLowerCase(),
      ),
      usersDuplicate = false,
      invalidUsers = false;

    if (lowerCaseKeywords?.includes(value?.toLowerCase())) {
      usersDuplicate = true;
    } else if (!usersRegx?.test(value) && value?.trim() !== "") {
      invalidUsers = true;
    }
    if (usersDuplicate) {
      errorPostsUsers = "alert_form_added_username";
      isExistPosts = false;
    } else if (invalidUsers) {
      errorPostsUsers = "alert_form_username_validation";
      isExistPosts = false;
    } else {
      errorPostsUsers = "";
      isExistPosts = true;
    }

    setFormFieldErrors({ ...formFieldErrors, userPostsError: errorPostsUsers });
    setCheckUsersPosts(isExistPosts);
  };

  const handelPostUsers = (event, clickType) => {
    let errorMsgPostsUser = "";
    const { value } = event.target;
    if (
      (event.keyCode === 13 || (clickType === "btn" && value)) &&
      checkUserPosts &&
      value?.trim() !== ""
    ) {
      if (usersRegx?.test(postUsers)) {
        if (selectedPostUsers?.includes(postUsers)) {
          errorMsgPostsUser = "alert_form_added_username";
        } else {
          setSelectedPostUsers((val) => [...val, postUsers]);
          setPostUsers("");
        }
      } else {
        errorMsgPostsUser = "alert_form_username_validation";
      }
      setFormFieldErrors({
        ...formFieldErrors,
        userPostsError: errorMsgPostsUser,
      });
    }
  };
  // get alert data
  const getAlertsUpdateData = (id) => {
    setEditGetAlertsLoading(true);
    AlertController.getUpdateAlertsDataV2(id).then((res) => {
      res?.data?.data?.attributes &&
        setAlertEditData(res?.data?.data?.attributes);
      setEditGetAlertsLoading(false);
    });
  };
  //create alert
  const createAlertData = (queryData) => {
    AlertController.createAlertsV2(queryData).then((res) => {
      if (res?.status_code === 201 || res?.status_code === 200) {
        setShowAlertActionMessage({
          severity: "success",
          message: "alert_added_success",
        });
        if (!isMonitorAction) {
          setSelectedTypes([]);
          setPage(1);
          getAlerts();
        }
      } else {
        setShowAlertActionMessage({
          severity: "error",
          message: "alert_added_fail",
        });
      }
      setShowSnackBar(true);
    });
  };

  const updateAlertData = (queryData) => {
    AlertController.updateAlertsV2(queryData, alertEditData?.id).then((res) => {
      if (res?.status_code === 201 || res?.status_code === 200) {
        setShowAlertActionMessage({
          severity: "success",
          message: "alert_update_success",
        });
        setPage(1);
        getAlerts();
      } else {
        setShowAlertActionMessage({
          severity: "error",
          message: "alert_update_fail",
        });
      }
      setShowSnackBar(true);
    });
  };
  const createUpdateAlertData = (queryData) => {
    setOpenForm(false);
    setOpenFormType("");
    isCreateAlert ? createAlertData(queryData) : updateAlertData(queryData);
  };

  // submit data to BE
  const handleFormSubmit = () => {
    const sendAlertsData = {
      name: alertName,
      product_id: productType,
      data_source_id: splitDataSourceType()?.id,
      channel_type_id: splitDataSourceType()?.channel_type_id,
      alert_type_id: alertType,
      monitors_ids:
        activeProductNames == "engagements"
          ? selectedSources
          : getValueIds(selectedMonitors),
      rate:
        activeProductNames == "engagements"
          ? alertRates?.length
            ? alertRates[0]?.toLowerCase()
            : ""
          : alertRateType?.toLowerCase(),
      users_ids: getValueIds(selectedEmails),
      send_email: emailsEnabled,
      notification_type_id: alertsNotificationType?.id,
      constrains: {
        threshold: alertThresholdType?.toLowerCase(),
      },
      max_emails: Number(maxmNumValue),
    };
    if (showAdavancedKeywords()) {
      sendAlertsData.constrains.keywords = alertKeywords?.toString();
      sendAlertsData.constrains.usernames = selectedPostUsers?.toString();
    }
    if (isManualAlert)
      sendAlertsData.constrains.posts_increase_rate = thresholdManualValue;
    if (isHourlyType) sendAlertsData.hours = recieveAlertsHourly;
    if (isInfluncerType)
      sendAlertsData.constrains.min_followers = minimumNoFollowers;
    if (isViralTweetType && isInstantType)
      sendAlertsData.constrains.min_num_of_interactions = minimumNoInteractions;
    if (activeProductNames === "engagements") {
      sendAlertsData.conversation_type_id = convertDataConverstion(
        alertConversationType?.toString()?.split(","),
      );
    }

    if (enableWebhooks)
      sendAlertsData.webhook_info = {
        callback_url: url?.trim(),
        verification_key: verificationKey,
        method_type: method,
        token_id: apiKey,
        verification_key_name: authVarName,
        key_location_type_id: authLocation,
      };

    let sendUpdatedData = {
      name: alertName,
    };
    if (enableWebhooks)
      sendUpdatedData.webhook_info = {
        callback_url: url?.trim(),
        verification_key: verificationKey,
        method_type: method,
        token_id: apiKey,
        verification_key_name: authVarName,
        key_location_type_id: authLocation,
      };
    let queryData = isCreateAlert ? sendAlertsData : sendUpdatedData;
    createUpdateAlertData(queryData);
  };
  //get ids from data
  const getValueIds = (data) => {
    const valueIds = [];
    data?.map((el) => {
      valueIds.push(el?.id);
    });
    return valueIds;
  };

  const getLucidyaApisList = () => {
    var activeProducts = JSON.parse(
      window?.localStorage?.activeProducts || null,
    );
    let lucidyaApisActive = activeProducts?.find(
      (product) => product?.name == "PUBLIC_API",
    );
    if (lucidyaApisActive?.id) {
      setGetApisLoading(true);
      CompaniesController.getLucidyaApisList(0, lucidyaApisActive?.id).then(
        (res) => {
          setLucidyaApisList(res?.data?.message?.data);
          setKeyLocationList(res?.data?.key_location);
          setGetApisLoading(false);
        },
      );
    } else {
      setGetApisLoading(false);
    }
  };

  const checkDisableTestUrl = () =>
    apiKey === "" ||
    method === "" ||
    verificationKey === "" ||
    authVarName === "" ||
    formFieldErrors?.authVarName !== "" ||
    authLocation?.length === 0 ||
    url?.trim() === "";

  const handleLocationString = () => {
    let strArr = [];
    if (authLocation)
      authLocation?.map((item) => {
        keyLocationList?.map((loc) => {
          if (loc?.id === item) strArr?.push(loc?.name);
        });
      });
    setLocationString(strArr);
    return strArr;
  };

  const handleTestUrl = () => {
    const urlValue = url?.trim();
    if (isValidURL(urlValue)) {
      setFormFieldErrors({
        ...formFieldErrors,
        url: "",
      });
      setTestWebhooksLoading(true);
      const queryData = {
        callback_url: urlValue,
        method_type: method,
        token_id: apiKey,
        verification_key: verificationKey,
        verification_key_name: authVarName,
        authentication_location: handleLocationString(),
      };
      AlertController.getTestWebhooks(queryData).then((res) => {
        if (res?.status_code === 201 || res?.status_code === 200) {
          setTestWebhooksResult("passed");
        } else {
          setTestWebhooksResult("failed");
        }
        setTestWebhooksLoading(false);
      });
    } else {
      setFormFieldErrors({
        ...formFieldErrors,
        url: "invalid_source_URL",
      });
    }
  };

  const handleDeleteWebhooks = () => {
    setDeleteWebhooksLoading(true);
    AlertController.deleteWebhooks(alertEditData?.webhook_info?.id).then(
      (res) => {
        if (res?.status_code === 201 || res?.status_code === 200) {
          setShowAlertActionMessage({
            severity: "success",
            message: "webhook_deleted_successfully",
          });
          getAlertsUpdateData(alertEditData?.id);
          setPage(1);
          getAlerts();
        } else {
          setShowAlertActionMessage({
            severity: "error",
            message: "webhook_deleted_failed",
          });
        }
        setShowSnackBar(true);
        setDeleteWebhooksLoading(false);
      },
    );
  };

  const handleEnableWebhooks = () => {
    setEnableWebhooksLoading(true);
    AlertController.enableWebhooks(alertEditData?.webhook_info?.id).then(
      (res) => {
        if (res?.status_code === 201 || res?.status_code === 200) {
          setShowAlertActionMessage({
            severity: "success",
            message: "webhook_enabled_successfully",
          });
          setEnableWebhooks(res?.data?.data?.enabled || false);
          setApiKey(res?.data?.data?.token_id?.toString() || "");
          setMethod(res?.data?.data?.method_type || "");
          setUrl(res?.data?.data?.callback_url || "");
          setVerificationKey(res?.data?.data?.verification_key || "");
          setTestWebhooksResult("warning");
          setPage(1);
          getAlerts();
        } else {
          setShowAlertActionMessage({
            severity: "error",
            message: "webhook_enabled_failed",
          });
        }
        setShowSnackBar(true);
        setEnableWebhooksLoading(false);
      },
    );
  };

  const handleDisableWebhooks = () => {
    setDisableWebhooksLoading(true);
    AlertController.disableWebhooks(alertEditData?.webhook_info?.id).then(
      (res) => {
        if (res?.status_code === 201 || res?.status_code === 200) {
          setShowAlertActionMessage({
            severity: "success",
            message: "webhook_disabled_successfully",
          });
          getAlertsUpdateData(alertEditData?.id);
          setPage(1);
          getAlerts();
        } else {
          setShowAlertActionMessage({
            severity: "error",
            message: "webhook_disabled_failed",
          });
        }
        setShowSnackBar(true);
        setDisableWebhooksLoading(false);
      },
    );
  };

  const handleEnableDisableWebhooks = () => {
    if (isCreateAlert || !alertEditData?.webhook_info?.id) {
      setEnableWebhooks(!enableWebhooks);
    } else {
      enableWebhooks ? handleDisableWebhooks() : handleEnableWebhooks();
    }
  };

  const resetFormData = () => {
    setOpenFormType("");
    setAlertName("");
    setProductType("");
    setDatasourceType("");
    setAlertType("");
    setAlertRateType("");
    setSelectedMonitors([]);
    setRecieveAlertsHourly(1);
    setAlertThresholdType("");
    setThresholdManualValue(1);
    setMaxmNumberEmailsValue(1);
    setMaxmNumValue(1);
    setFormFieldErrors({
      name: "",
      recieveAlertsHourly: "",
      thresholdManualVal: "",
      alertsEmails: "",
      keywordError: "",
      userPostsError: "",
      mimimumNoFollowers: "",
      errAlertMax: "",
      mimimumNoInteractions: "",
      url: "",
      apiKey: "",
      authVarName: "",
    });

    setSelectedEmails([]);
    setCompanyUsers([]);
    setEmailsEnabled(true);
    setKeywordsFocused(false);
    setAlertKeywords([]);
    setSelectedKeywords("");
    setCheckKeywords(false);
    setCheckUsersPosts(false);
    setPostUsers("");
    setSelectedPostUsers([]);
    setMiniumimNoFollowers(2500);
    setAlertEditData({});
    setMiniumimNoInteractions(1);
    setAlertwebhookinfo({});
    setEnableWebhooks(false);
    setLucidyaApisList([]);
    setKeyLocationList([]);
    setApiKey("");
    setMethod("POST");
    setUrl("");
    setVerificationKey("");
    setAuthVarName("verification_key");
    setAuthLocation([]);
    setLocationString([]);
    setTestWebhooksResult("");
    setTestWebhooksLoading(false);
    setDeleteWebhooksLoading(false);
    setEnableWebhooksLoading(false);
    setDisableWebhooksLoading(false);
    setSelectedSources([]);
    setAlertConversationType("");
  };
  const handleDeleteInputField = (index, data, setData) => {
    data.splice(index, 1);
    setData([...data]);
    setFormFieldErrors({
      ...formFieldErrors,
      keywordError: "",
      errorPostsUsers: "",
    });
    setSelectedKeywords("");
    setPostUsers("");
  };

  const handleMinimumNoInteractions = (event) => {
    const { value } = event.target;
    let errorMsgInteractions = "";
    setMiniumimNoInteractions(value);
    if (value === "" || value == 0 || !regex.test(value))
      errorMsgInteractions = "alerts_invalid_value";

    setFormFieldErrors({
      ...formFieldErrors,
      mimimumNoInteractions: errorMsgInteractions,
    });
  };

  return {
    openForm,
    setOpenForm,
    openFormType,
    setOpenFormType,
    handleCloseForm,
    handleOpenForm,
    alertName,
    setAlertName,
    formFieldErrors,
    setFormFieldErrors,
    handleInputChange,
    productType,
    setProductType,
    getAlertData,
    alertsInfo,
    datasourceType,
    setDatasourceType,
    alertType,
    setAlertType,
    getFilteredData,
    alertRateType,
    setAlertRateType,
    selectedMonitors,
    setSelectedMonitors,
    handleChangeMultipleSelection,
    monitorsList,
    setMonitorsList,
    getAlertsMonitors,
    recieveAlertsHourly,
    setRecieveAlertsHourly,
    alertThresholdType,
    setAlertThresholdType,
    thresholdManualValue,
    setThresholdManualValue,
    handleChangeAlertsFields,
    getComanyEmails,
    companyUsers,
    setCompanyUsers,
    selectedEmails,
    setSelectedEmails,
    emailsEnabled,
    setEmailsEnabled,
    keywordsFocused,
    setKeywordsFocused,
    alertKeywords,
    setAlertKeywords,
    selectedKeywords,
    setSelectedKeywords,
    handleKeywordChange,
    handleKeywords,
    handleDeleteKeyword,
    checkDisableCreateAlert,
    handleRecieveAlertsHourly,
    handelPostUsers,
    handlePostsUsersChange,
    postUsers,
    selectedPostUsers,
    handleDeleteInputField,
    setSelectedPostUsers,
    setPostUsers,
    handleFormSubmit,
    showSnackBar,
    setShowSnackBar,
    showAlertActionMessage,
    resetFormData,
    loading,
    page,
    numberOfPages,
    alertTypeNames,
    selectedTypes,
    handleTypeSelection,
    alertTypeLocale,
    getAlerts,
    getAlertsTypes,
    alerts,
    handleChangePage,
    getValueIds,
    getAlertsLoading,
    isManualAlert,
    isHourlyType,
    showAdavancedKeywords,
    alertThresold,
    isCreateAlert,
    alertsSources,
    alertsTypes,
    alertRates,
    isNegavtivePostsType,
    getPostsTypeTitle,
    activeAlertType,
    handleMinimumNoFollowrs,
    minimumNoFollowers,
    isInfluncerType,
    getAlertsUpdateData,
    alertEditData,
    setAlertEditData,
    getEditAlertsLoading,
    setMiniumimNoFollowers,
    setPage,
    setShowAlertActionMessage,
    isInstagramDatasource,
    splitDataSourceType,
    filteredAlertsData,
    monitorsLoading,
    handleChangeAlertsMaximum,
    maxmNumberEmailsValue,
    setMaxmNumberEmailsValue,
    maxmNumValue,
    setMaxmNumValue,
    informerMaxEmails,
    setInformerMaxEmails,
    isViralTweetType,
    isInstantType,
    minimumNoInteractions,
    setMiniumimNoInteractions,
    handleMinimumNoInteractions,
    alertWebhookInfo,
    enableWebhooks,
    setEnableWebhooks,
    getApisLoading,
    lucidyaApisList,
    keyLocationList,
    apiKey,
    setApiKey,
    method,
    setMethod,
    url,
    setUrl,
    verificationKey,
    setVerificationKey,
    authVarName,
    setAuthVarName,
    handleAuthVarName,
    authLocation,
    setAuthLocation,
    handleAuthLocationChange,
    testWebhooksResult,
    setTestWebhooksResult,
    testWebhooksLoading,
    handleTestUrl,
    checkDisableTestUrl,
    deleteWebhooksLoading,
    handleDeleteWebhooks,
    handleEnableDisableWebhooks,
    enableWebhooksLoading,
    disableWebhooksLoading,
    handleVerificatinKeyChange,
    tableColumns: columns,
    isSortableLoading,
    alertsConversationTypes,
    alertConversationType,
    setAlertConversationType,
    getAlertMonitorsEngagements,
    selectedSources,
    setSelectedSources,
    sourcesListEngagements,
    sourcesLoadingEngagements,
    handleChangeSourcesEngagements,
    handleSourcesIds,
    fillterSearch,
    setFillterSearch,
  };
};
export default AlertsFunctions;
