import { useWidgetName } from "../../../hooks/useWidgetName";

const widgetsMapping = {
  // ----------- Instagram Public -----------
  Instagram__AccountPage__posts_interactions: "posts_posts_interactions",
  Instagram__AccountPage__account_author_activity:
    "posts_account_authors_activity",
  Instagram__EngagementsPage__volume_overtime: "comments_volume_overtime",
  Instagram__AccountPage__average_engagement_per_post:
    "posts_average_engagement_per_posts",
  Instagram__EngagementsPage__themes_and_sub_themes: "comments_themes",
  Instagram__AccountPage__content_type: "posts_content_type",
  Instagram__EngagementsPage__sentiment_analysis: "comments_sentiment_analysis",
  Instagram__EngagementsPage__comments_interactions:
    "comments_comments_interactions",
  Instagram__EngagementsPage__top_hashtags: "comments_top_hash_tags",
  Instagram__EngagementsPage__associated_topics: "comments_associated_topics",
  Instagram__EngagementsPage__dialects_and_sub_dialects:
    "comments_dialects_sub_dialects",
  Instagram__EngagementsPage__top_keywords: "comments_top_keywords",
  Instagram__EngagementsPage__customer_care_performance:
    "comments_customer_care_performance",
  Instagram__AccountPage__top_videos: "top_reels",
  Instagram__AccountPage__top_images: "posts_top_images",
  Instagram__EngagementsPage__top_engagers: "top_authors",

  // ----------- Instagram Private -----------
  sentiment: "sentiment_analysis",
  themes: "themes",
  content_type: "content_type",
  dialects: "dialects_sub_dialects",
  channel_accounts: "channel_accounts",
  interactions: "conversation_interactions",
  top_keywords: "top_keywords",
  genders: "gender_distribution",
  top_engaged_authors: "most_engaged_contacts",
  channel_activity: "account_contacts_activity",
};

export const InstagramOmniRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  const widgetKey = widgetsMapping[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      widgetKey,
      response?.monitor_id,
      response?.eventName?.includes("__") ? "INSTAGRAM" : "INSTAGRAM_PRIVATE",

      response?.eventData,
    );
  else if (stateKey)
    handleWidgetResponse(
      stateKey,
      response?.monitor_id,
      response?.eventName?.includes("__") ? "INSTAGRAM" : "INSTAGRAM_PRIVATE",

      response?.eventData,
    );
};
