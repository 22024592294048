import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { usePauseOrResumeChannel } from "../hooks/usePauseOrResumeChannel";

export const PauseOrResumeChannelModal = ({
  open,
  close,
  accountName,
  channelId,
  showSnackBar,
  status,
}) => {
  const intl = useIntl();

  const { mutate: pauseOrResumeChannel, isPending: isPauseOrResumePending } =
    usePauseOrResumeChannel();
  const handlePauseOrResumeChannel = () => {
    pauseOrResumeChannel(
      { channelId },
      {
        onSuccess: () => {
          close();
          showSnackBar("pauseOrResume", "success");
        },
        onError: () => {
          close();
          showSnackBar("pauseOrResume", "warning");
        },
      },
    );
  };

  const isPaused = status === "paused";

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
    >
      <Box className="pop-up-shared-content-ca actions-buttons-shared">
        <DialogTitle id="alert-dialog-title">
          {isPaused
            ? CheckValueLocale("resume_manage_title", "", {}, intl)
            : CheckValueLocale("pausing_manage_channel", "", {}, intl)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isPaused ? (
              <Box className="shared-dialog-body">
                <p>
                  {`${CheckValueLocale("resume_channel", "", { accountName: accountName }, intl)} `}
                </p>
                <p className="sub-text-not-italic">
                  {CheckValueLocale("resume_channel_des", "", {}, intl)}
                </p>
              </Box>
            ) : (
              <Box className="shared-dialog-body">
                <p>
                  {`${CheckValueLocale("pause_channel", "", { accountName: accountName }, intl)} `}
                  <br />
                  {CheckValueLocale("pause_channel_des", "", {}, intl)}
                </p>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <LucButton
            onClick={close}
            id="pop-up-left-btn"
            variant="flat"
            type="secondary"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>

          <LucButton
            disabled={isPauseOrResumePending}
            onClick={handlePauseOrResumeChannel}
            id="pop-up-right-btn"
            variant="filled"
            className="popup__rightbtn"
            loading={isPauseOrResumePending}
            type={isPaused ? "primary" : "red"}
          >
            {isPaused
              ? CheckValueLocale("resume", "", {}, intl)
              : CheckValueLocale("pause", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
