import { Box, Tooltip } from "@mui/material";
import gender from "images/engagements-images/gender.svg";
import dialects from "images/engagements-images/dialects.svg";
import location from "images/engagements-images/location.svg";
import sentiment from "images/engagements-images/sentiment.svg";
import phone from "images/engagements-images/phone.svg";
import email from "images/engagements-images/email.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const ProfileInfo = ({ infoData, infoArr }) => {
  const intl = useIntl();

  const getInfoImage = (type) => {
    const infoImage = {
      gender: <img src={gender} />,
      dialects: <img src={dialects} />,
      location: <img src={location} />,
      sentiment: <img src={sentiment} />,
      phone_number: <img src={phone} />,
      email: <img src={email} />,
    };
    return infoImage[type];
  };

  const toolTipAppear = (name, count) =>
    name?.length > count ? (
      <Tooltip title={name} arrow placement="top">
        <span>{name?.substring(0, count) + "..."}</span>
      </Tooltip>
    ) : (
      name
    );

  return (
    <Box className="user-profile-side-bar-content">
      <h4 className="content-side-bar-heading">
        {CheckValueLocale("user_info_profile", "", {}, intl)}:
      </h4>
      {infoArr?.map((info, i) => {
        return (
          <Box className="content-box" key={i}>
            <span className="title-box">
              {getInfoImage(info)}
              {CheckValueLocale(info + "_profile", "", {}, intl)}:
            </span>
            <span className="value-box">
              {info === "gender" &&
              (infoData[info] === "f" ||
                infoData[info] === "m" ||
                infoData[info] === "u")
                ? infoData[info]?.length
                  ? toolTipAppear(
                      CheckValueLocale(
                        infoData[info] + "_gender",
                        "",
                        {},
                        intl,
                      ),
                      18,
                    )
                  : "-"
                : info === "location"
                  ? infoData[info]?.country?.length ||
                    infoData[info]?.city?.length
                    ? infoData[info]?.country?.length &&
                      infoData[info]?.city?.length
                      ? toolTipAppear(
                          CheckValueLocale(
                            infoData[info]?.country,
                            "",
                            {},
                            intl,
                          ) +
                            " " +
                            "," +
                            " " +
                            CheckValueLocale(
                              infoData[info]?.city,
                              "",
                              {},
                              intl,
                            ),
                          18,
                        )
                      : toolTipAppear(
                          CheckValueLocale(
                            infoData[info]?.country,
                            "",
                            {},
                            intl,
                          ) +
                            " " +
                            CheckValueLocale(
                              infoData[info]?.city,
                              "",
                              {},
                              intl,
                            ),
                          18,
                        )
                    : "-"
                  : info === "phone_number"
                    ? infoData[info]?.length
                      ? toolTipAppear(infoData[info], 18)
                      : "-"
                    : infoData[info]?.length
                      ? toolTipAppear(
                          CheckValueLocale(infoData[info], "", {}, intl),
                          18,
                        )
                      : "-"}
            </span>
          </Box>
        );
      })}
    </Box>
  );
};
export default ProfileInfo;
