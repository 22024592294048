import React from "react";
import { Box } from "@mui/material";
import EmptySurvey from "./emptySurvey";
import SectionHeader from "./sectionHeader";
import SectionFooter from "./sectionFooter";
import "./style.scss";
import FreeAnswerQuestion from "../../questions/freeAnswerQuestion";
import MultipleChoiceQuestion from "../../mcqQuestion/mcqQuestion";
import DropdownQuestion from "../../questions/dropdownQuestion";
import YesNoQuestion from "../../questions/yesNoQuestion";
import EmailQuestion from "../../questions/emailQuestion";
import NameQuestion from "../../questions/nameQuestion";
import PhoneNumberQuestion from "../../questions/phoneNumberQuestion";
import NumberQuestion from "../../questions/numberQuestion";
import SocialMediaQuestion from "../../questions/socialMediaQuestion";
import NpsQuestion from "../../questions/npsQuestion";
import LinearScaleQuestion from "../../questions/linearScaleQuestion";

const SectionContainer = (props) => {
  const {
    surveySections,
    setSurveySections,
    filteredSections,
    section,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    handleSectionTitle,
    sqIndex,
    ssIndex,
    setSaveChangesFlag,
    emptySection,
    handleCardClick,
    handleChangeProceed,
    proceedError,
    afterSecError,
    secTitleErr,
  } = props;

  return (
    <Box className="section-container">
      {filteredSections?.length < 2 &&
        filteredSections[0]?.questions?.filter(
          (question) => question?.is_deleted !== true,
        )?.length < 1 && <EmptySurvey />}

      {filteredSections?.length > 1 && (
        <SectionHeader
          section={section}
          filteredSections={filteredSections}
          sectionIndex={sectionIndex}
          emptySection={emptySection}
          secTitleErr={secTitleErr}
          handleSectionTitle={handleSectionTitle}
        />
      )}

      {section?.questions
        ?.filter((question) => question?.is_deleted !== true)
        ?.map((question, index) => (
          <Box id={`section-${sectionIndex}-question-${index}`} key={index}>
            {question?.question?.type === "short_text" && (
              <FreeAnswerQuestion
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
            {question?.question?.type === "multiple_choice" && (
              <MultipleChoiceQuestion
                filteredSections={filteredSections}
                surveySections={surveySections}
                setSurveySections={setSurveySections}
                sectionIndex={sectionIndex}
                questionIndex={index}
                rootQuestion={question}
                question={question?.question}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                ssIndex={ssIndex}
                sqIndex={sqIndex}
                handleCardClick={handleCardClick}
                setSaveChangesFlag={setSaveChangesFlag}
                handleChangeProceed={handleChangeProceed}
                proceedError={proceedError}
              />
            )}
            {question?.question?.type === "dropdown" && (
              <DropdownQuestion
                filteredSections={filteredSections}
                setSurveySections={setSurveySections}
                surveySections={surveySections}
                rootQuestion={question}
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
                handleChangeProceed={handleChangeProceed}
                proceedError={proceedError}
              />
            )}
            {question?.question?.type === "yes_no" && (
              <YesNoQuestion
                filteredSections={filteredSections}
                rootQuestion={question}
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
                handleChangeProceed={handleChangeProceed}
                proceedError={proceedError}
              />
            )}
            {question?.question?.type === "email" && (
              <EmailQuestion
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
            {question?.question?.type === "name" && (
              <NameQuestion
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
            {question?.question?.type === "social_media" && (
              <SocialMediaQuestion
                filteredSections={filteredSections}
                setSurveySections={setSurveySections}
                surveySections={surveySections}
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
            {question?.question?.type === "phone_number" && (
              <PhoneNumberQuestion
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
            {question?.question?.type === "number" && (
              <NumberQuestion
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
            {question?.question?.type === "nps" && (
              <NpsQuestion
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
            {question?.question?.type === "scale" && (
              <LinearScaleQuestion
                question={question?.question}
                questionIndex={index}
                sectionIndex={sectionIndex}
                handleQuestionTitle={handleQuestionTitle}
                handleQuestionDesc={handleQuestionDesc}
                sqIndex={sqIndex}
                ssIndex={ssIndex}
                handleCardClick={handleCardClick}
              />
            )}
          </Box>
        ))}

      {filteredSections?.length > 1 && (
        <SectionFooter
          section={section}
          sectionIndex={sectionIndex}
          filteredSections={filteredSections}
          surveySections={surveySections}
          setSurveySections={setSurveySections}
          afterSecError={afterSecError}
        />
      )}
    </Box>
  );
};

export default SectionContainer;
