import React from "react";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCalendar,
  faComments,
  faFile,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileChartLine,
  faFileExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import TitleBox from "../header/titleBox";
import moment from "moment";
import "./sideBar.scss";
const SideBar = (props) => {
  const intl = useIntl();

  const { reportsData, loading, loadingDelay, csmInfo, needHelp } = props;

  // Current date
  const currentDate = moment();
  const unixDate = moment.unix(reportsData?.data?.next_delivery);
  const diff = currentDate.diff(unixDate);
  const duration = moment.duration(diff);
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  const checkReportData = () => {
    if (
      reportsData?.monitors_error !== true &&
      reportsData?.data?.manual_status == false
    ) {
      return (
        <>
          <FontAwesomeIcon icon={faFileExclamation} />
          {loading || loadingDelay ? (
            <Box className="report-skeleton">
              <Skeleton width="160px" height="13px"></Skeleton>
              <Skeleton width="110px" height="13px"></Skeleton>
            </Box>
          ) : (
            <Box>
              <Typography component={"h2"}>
                {CheckValueLocale("report_paused", "", {}, intl)}
              </Typography>
              <Typography>
                {CheckValueLocale("nextـscheduledـreport", "", {}, intl)}
              </Typography>
            </Box>
          )}
          <Button
            disabled={loading || loadingDelay ? true : false}
            onClick={() =>
              window.open(`/reports/view-report/${reportsData?.data?.id}`)
            }
          >
            {CheckValueLocale("home_page_view_details", "", {}, intl)}
          </Button>
        </>
      );
    } else if (reportsData?.monitors_error == true) {
      return (
        <>
          <FontAwesomeIcon icon={faFileExclamation} />
          {loading || loadingDelay ? (
            <Box className="report-skeleton">
              <Skeleton width="160px" height="13px"></Skeleton>
              <Skeleton width="110px" height="13px"></Skeleton>
            </Box>
          ) : (
            <Box>
              <Typography component={"h2"}>
                {CheckValueLocale("report_on_hold", "", {}, intl)}
              </Typography>
              <Typography>
                {CheckValueLocale(
                  "no_upcoming_reports_scheduled",
                  "",
                  {},
                  intl,
                )}
              </Typography>
            </Box>
          )}

          <Button
            disabled={loading || loadingDelay ? true : false}
            onClick={() =>
              window.open(`/reports/view-report/${reportsData?.data?.id}`)
            }
          >
            {CheckValueLocale("home_page_view_details", "", {}, intl)}
          </Button>
        </>
      );
    } else if (reportsData?.monitors_error === false) {
      return (
        <>
          <FontAwesomeIcon icon={faFile} />
          {loading || loadingDelay ? (
            <Box className="report-skeleton">
              <Skeleton width="160px" height="13px"></Skeleton>
              <Skeleton width="110px" height="13px"></Skeleton>
            </Box>
          ) : (
            <Box>
              <Typography component={"h2"}>
                {CheckValueLocale("no_upcoming_reports", "", {}, intl)}
              </Typography>
              <Typography>
                {CheckValueLocale("visit_your_reports", "", {}, intl)}
              </Typography>
            </Box>
          )}

          <Button
            disabled={loading ? true : false}
            onClick={() => window.open("/reports/add-report")}
          >
            {CheckValueLocale("home_page_create_report", "", {}, intl)}
          </Button>
        </>
      );
    } else if (reportsData?.reportsData?.data?.limit_error == true) {
      return (
        <>
          <FontAwesomeIcon icon={faFileExclamation} />
          {loading || loadingDelay ? (
            <Box className="report-skeleton">
              <Skeleton width="160px" height="13px"></Skeleton>
              <Skeleton width="110px" height="13px"></Skeleton>
            </Box>
          ) : (
            <Box>
              <Typography component={"h2"}>
                {CheckValueLocale("home_page_limit_reached", "", {}, intl)}
              </Typography>
              <Typography>
                {CheckValueLocale("reports_are_on_hold", "", {}, intl)}
              </Typography>
            </Box>
          )}
          <Button
            disabled={loading || loadingDelay ? true : false}
            onClick={() => window.open("/settings/subscription")}
          >
            {CheckValueLocale("home_page_upgrade_now", "", {}, intl)}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <FontAwesomeIcon icon={faFileChartLine} />
          {loading || loadingDelay ? (
            <Box className="report-skeleton">
              <Skeleton width="160px" height="13px"></Skeleton>
              <Skeleton width="110px" height="13px"></Skeleton>
            </Box>
          ) : (
            <Box>
              <Typography component={"h2"}>
                {CheckValueLocale("next_report_in", "", {}, intl)}
              </Typography>
              <Typography>
                {`${
                  days * -1 + CheckValueLocale("home_page_day", "", {}, intl)
                }, ${
                  hours * -1 + CheckValueLocale("home_page_hour", "", {}, intl)
                }, ${
                  minutes * -1 +
                  CheckValueLocale("home_page_minutes", "", {}, intl)
                }`}
                <br />
                {CheckValueLocale("the_next_delivery", "", {}, intl)}
              </Typography>
            </Box>
          )}

          <Button
            disabled={loading || loadingDelay ? true : false}
            onClick={() => window.open("/reports/list")}
          >
            {CheckValueLocale("home_page_vew_reports_list", "", {}, intl)}
          </Button>
        </>
      );
    }
  };
  return (
    <Box className="sidebar-container">
      <Box className="reports-box">
        <Box className="head">
          <TitleBox title={"reports"} tooltipText={"upcoming_reports"} />
        </Box>
        <Box className="reports-content">{checkReportData()}</Box>
      </Box>

      <Box className="need-help">
        <Box className="head">
          <TitleBox title={"need_help"} />
        </Box>
        {needHelp ? (
          <Box className="need-help-skeleton">
            <Box className="item-need-skeleton">
              <Skeleton
                animation="wave"
                variant="circular"
                width={20}
                height={20}
              />
              <Skeleton width="120px" height="10px"></Skeleton>
            </Box>
            <Box className="item-need-skeleton">
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton width="120px" height="10px"></Skeleton>
            </Box>
            <Box className="item-need-skeleton">
              <Skeleton
                animation="wave"
                variant="circular"
                width={20}
                height={20}
              />
              <Skeleton width="120px" height="10px"></Skeleton>
            </Box>
            <Box className="item-need-skeleton">
              <Skeleton
                animation="wave"
                variant="circular"
                width={20}
                height={20}
              />
              <Skeleton width="120px" height="10px"></Skeleton>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              className="item-content"
              onClick={() => window.open("https://knowledge.lucidya.com/")}
            >
              <FontAwesomeIcon icon={faBook} />
              {CheckValueLocale("knowledge_base", "", {}, intl)}
            </Box>
            <Box
              className="item-content"
              onClick={() =>
                window.open(
                  "https://knowledge.lucidya.com/%D8%A7%D9%84%D8%A3%D8%B3%D8%A6%D9%84%D8%A9-%D8%A7%D9%84%D8%B4%D8%A7%D8%A6%D8%B9%D8%A9",
                )
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              {CheckValueLocale("faqs", "", {}, intl)}
            </Box>
            {csmInfo?.session_url && (
              <Box
                onClick={() => window.open(csmInfo?.session_url)}
                className="item-content"
              >
                <FontAwesomeIcon icon={faCalendar} />
                {CheckValueLocale("success_session", "", {}, intl)}
              </Box>
            )}
            <Box className="item-content chat-with-us">
              <FontAwesomeIcon icon={faComments} />
              {CheckValueLocale("chat_with_us", "", {}, intl)}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SideBar;
