import { Chip } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import "./activeFilters.scss";
import CloseIcon from "@mui/icons-material/Close";
import { CheckValueLocale } from "utils/helpers";
// ____________________________________________________________________

export const getActiveParams = (assignActiveFilters) => {
  let allActiveFilters = [];
  if (assignActiveFilters) {
    Object.entries(assignActiveFilters).forEach(([key, val]) => {
      if (val.length) {
        val.map((v) => {
          allActiveFilters.push({ name: key, value: v });
        });
      }
    });
  }
  return allActiveFilters;
};
// ____________________________________________________________________
const ActiveFilters = (props) => {
  const intl = useIntl();
  const {
    assignActiveFilters,
    startDate,
    endDate,
    applyFilter,
    allActiveFilters,
    filterFields,
  } = props;
  const [showLess, setShowLess] = useState(false);
  // ____________________________________________________________________
  //show more and less filters
  const seeMoreFilters = () => {
    var element = document.getElementById("show-filter");
    element.classList.toggle("show-active-filters");
    setShowLess(!showLess);
  };
  // ____________________________________________________________________
  //remove single active filters param
  const removeActiveFilter = (name, value) => {
    let monitorListIds = [];
    let dmListIds = [];
    let waitingFilterIds = [];
    let monitorListFullName = [];
    let dmMonitorListFullName = [];
    let waitingFilterFullName = [];
    Object.entries(assignActiveFilters).forEach(([key, val]) => {
      if (val?.length && name == key) {
        val = val?.filter((item) => {
          return item != value;
        });
        assignActiveFilters[key] = val;
      }
    });
    if (assignActiveFilters.monitor_list?.length > 0) {
      monitorListFullName = assignActiveFilters.monitor_list;
      assignActiveFilters.monitor_list?.map((i) => {
        if (typeof i == "string") {
          monitorListIds.push(parseInt(i.split("-")[0]));
          assignActiveFilters.monitor_list = monitorListIds;
        }
      });
    }

    if (assignActiveFilters.dm_list?.length > 0) {
      dmMonitorListFullName = assignActiveFilters.dm_list;
      assignActiveFilters.dm_list?.map((i) => {
        if (typeof i == "string") {
          dmListIds.push(parseInt(i.split("-")[0]));
          assignActiveFilters.dm_list = dmListIds;
        }
      });
    }

    if (assignActiveFilters.waiting_filter?.length > 0) {
      waitingFilterFullName = assignActiveFilters.waiting_filter;
      assignActiveFilters.waiting_filter?.map((i) => {
        if (typeof i == "string") {
          filterFields.by_interactions.waiting_filter?.forEach((element) => {
            if (element.name === i) {
              waitingFilterIds.push(element.id);
              return (assignActiveFilters.waiting_filter = waitingFilterIds);
            }
          });
        }
      });
    }
    //After removing the filter param we re apply filter with new filters
    applyFilter(startDate, endDate, assignActiveFilters);
    if (assignActiveFilters.monitor_list?.length > 0) {
      assignActiveFilters.monitor_list = monitorListFullName;
    }
    if (assignActiveFilters.dm_list?.length > 0) {
      assignActiveFilters.dm_list = dmMonitorListFullName;
    }
    if (assignActiveFilters.waiting_filter?.length > 0) {
      assignActiveFilters.waiting_filter = waitingFilterFullName;
    }
  };

  let activeFiltersAfterHidingSomeParams = [];
  allActiveFilters &&
    allActiveFilters?.map((item) => {
      typeof item?.value === "string" &&
        item?.value?.split(",")?.map((val) => {
          activeFiltersAfterHidingSomeParams?.push({
            name: item?.name,
            value: val,
          });
        });
    });

  let showItems = showLess
    ? activeFiltersAfterHidingSomeParams
    : activeFiltersAfterHidingSomeParams?.slice(0, 5);

  return (
    <>
      {allActiveFilters.length > 0 && (
        <div className="active-filters-container">
          <div
            className="all-active-filters show-active-filters"
            id="show-filter"
          >
            {showItems?.map(({ name, value }) => {
              return (
                <Chip
                  className="active-filter-params"
                  label={`
                  ${
                    name === "monitor_list"
                      ? CheckValueLocale("channel_accounts", "", {}, intl)
                      : name === "dm_list"
                        ? CheckValueLocale("channel_accounts", "", {}, intl)
                        : CheckValueLocale(name, "", {}, intl)
                  }
                      : ${
                        typeof value === "number"
                          ? name === "with_comment"
                            ? CheckValueLocale("true", "", {}, intl)
                            : value
                          : CheckValueLocale(value, "", {}, intl)
                      }`}
                  onDelete={() => removeActiveFilter(name, value)}
                  deleteIcon={<CloseIcon />}
                  variant="outlined"
                />
              );
            })}
          </div>
          {showLess ? (
            <p onClick={seeMoreFilters} className="show-less-filters">
              {CheckValueLocale("show_less", "", {}, intl)}
            </p>
          ) : (
            activeFiltersAfterHidingSomeParams?.length > 5 && (
              <Chip
                label={`${CheckValueLocale("show_all", "", {}, intl)} (+${
                  activeFiltersAfterHidingSomeParams?.length - 5
                })`}
                variant="outlined"
                onClick={seeMoreFilters}
                className="show-hidden-filters"
              />
            )
          )}
        </div>
      )}
    </>
  );
};
export default ActiveFilters;
