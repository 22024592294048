import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Container, Box } from "@mui/material";
import { getTimeZone, isEmptyObj } from "utils/helpers";
import { useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import InteractionsController from "services/controllers/interactionsController";
import ThemesMenu from "components/interactions-list/components/themesMenu/themesMenu.js";
import SentimentDropdown from "components/interactions-list/components/sentiment-dropdown/SentimentDropdown";
import CircularLoading from "components/circularProgress";
import SingleInteractionHeader from "components/interactions-list/components/single-interaction/SingleInteractionHeader";
import InteractionListMessageWhatsapp from "components/interactions-list/components/InteractionListMessageWhatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import "components/interactions-list/components/single-interaction/SingleInteraction.scss";
import Luci from "pages/luci";

const SingleWhatsappInteraction = () => {
  const urlParams = useParams();
  const interactionId = urlParams.id;
  const trackerId = urlParams.tracker_id;
  const dataSourceId = urlParams.data_source_id;
  const intl = useIntl();
  const [message, setMessages] = useState({});
  const [openProfile, setOpenProfile] = useState(false);
  let apiVars = "chat/whatsapp";
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");

  const links = [
    {
      name: "interactions",
      url: "interactions",
    },
    {
      name: "chats",
      url: `insights/chat${
        hasDatasourceOnly === "true" ? `/whatsapp/${dataSourceId}` : ""
      }`,
    },
    {
      name: "whatsapp",
      url: "insights/chat/whatsapp/" + dataSourceId,
    },
  ];
  useEffect(() => {
    InteractionsController.getSingleInteraction(
      interactionId,
      trackerId,
      dataSourceId,
      window.localStorage.cxm_id,
      apiVars,
    ).then((result) => {
      if (result !== undefined && !isEmptyObj(result.data)) {
        setMessages(result.data);
      }
    });
  }, []);
  const handleSideBar = () => {
    setOpenProfile(true);
  };

  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: message?.message?.customer_phone_number,
            profile_image: "",
          }}
          dataSourceName={"WHATSAPP"}
          monitorId={trackerId}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <Container maxWidth="xl">
        <Luci isInactive={true} />
        {isEmptyObj(message) ? (
          <CircularLoading />
        ) : (
          <div>
            <SingleInteractionHeader links={links} dataSource="whatsapp" />
            <div className="single-intr">
              <Box className="single-intr-wrapper">
                <Box
                  className={`user-single-header hover-show  `}
                  onClick={handleSideBar}
                >
                  <span className="single-intr__icon single-intr__icon--whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </span>
                  <span className="single-intr__title">
                    {message?.customer_phone_number}
                  </span>
                </Box>

                <Box
                  className="interaction-list__footer single-int"
                  component="div"
                >
                  <ThemesMenu
                    data={message}
                    themes={message.themes}
                    subThemes={message.sub_themes}
                    interactionId={message.interaction_id}
                    trackerId={message.tracker_id}
                    createdAt={message.created_at}
                    channelId={message.channel_id}
                    commentId={message.id}
                    apiVars={apiVars}
                  />
                  <div className="vertical-line"></div>
                  <SentimentDropdown
                    sentiment={message.sentiment}
                    data={message}
                    interactionId={message.interaction_id}
                    trackerId={message.tracker_id}
                    createdAt={message.created_at}
                    channelId={message.channel_id}
                    commentId={message.id}
                    apiVars={apiVars}
                  />
                </Box>
              </Box>
              <div className="single-intr__body">
                <div className="user-profile-whatsap">
                  <span>
                    {CheckValueLocale("client_interaction", "", {}, intl)}
                  </span>
                  <span> {message?.message?.customer_phone_number}</span>
                </div>

                <div className="message-container">
                  <InteractionListMessageWhatsapp
                    obj={message}
                    id={message?.id}
                    tracker_id={message?.tracker_id}
                    channel_id={message?.channel_id}
                    channelName={"whatsapp"}
                    link={message?.message?.attachement?.link}
                    text={message?.message?.text}
                    attachementType={message?.message?.attachement?.type}
                    attachementLink={message?.message?.attachement?.link}
                    forwardedMessageFlag={message?.message?.forwarded_message}
                  />
                </div>

                <div className="whatsapp-date">
                  <span>
                    {moment
                      .unix(message.created_at)
                      .utc()
                      .format("DD/MM/YYYY, LT ")}
                  </span>
                  <span>
                    {getTimeZone() > 0
                      ? `(${CheckValueLocale(
                          "gmt",
                          "",
                          {},
                          intl,
                        )} gmt +${getTimeZone()})`
                      : `(${CheckValueLocale(
                          "gmt",
                          "",
                          {},
                          intl,
                        )} -${getTimeZone()})`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default SingleWhatsappInteraction;
