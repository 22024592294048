import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, handleThemes } from "utils/helpers";

const Themes = ({ themesData }) => {
  const intl = useIntl();

  return (
    <Box className="profile-side-bar-content no-border-box">
      <h4 className="content-side-bar-heading no-margin">
        {CheckValueLocale("themes", "", {}, intl)}:
      </h4>
      {themesData?.length > 0 ? (
        <Box className="content-wrap">
          {themesData?.map((theme, i) => (
            <Box className="single-hint" key={i}>
              <Box
                style={{
                  backgroundColor: handleThemes(theme),
                }}
                className="hint-color"
              ></Box>
              <span className="hint-text">
                {" "}
                {CheckValueLocale(theme, "", {}, intl)}{" "}
              </span>
            </Box>
          ))}
        </Box>
      ) : (
        <p className="profile-side-bar-content-empty">
          {" "}
          {CheckValueLocale("no_themes_found", "", {}, intl)}{" "}
        </p>
      )}
    </Box>
  );
};
export default Themes;
