import { Box, Button, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { ArrowForwardIos } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LucButton from "shared/lucButton/lucButton";

const ReportHeader = ({ reportData }) => {
  const intl = useIntl();
  const history = useHistory();

  const showWarningMsg = (arrMonitors) => {
    return (
      <Box component="span" className="warning-conianer-report">
        <Tooltip
          title={
            <ul className="warning-content-report">
              {arrMonitors?.map((item) => {
                return (
                  <li>
                    <Box
                      component="span"
                      className="account-name-report"
                    >{`${item?.name}`}</Box>
                    {CheckValueLocale(
                      `${getMsgStatusFormating(item?.status)}`,
                      "",
                      {},
                      intl,
                    )}
                  </li>
                );
              })}
            </ul>
          }
          id="warning-tooltip-report"
          sx={{ cursor: "default" }}
          arrow
          placement="bottom"
        >
          <ErrorOutlineIcon
            className="dashboard-warning-icon"
            fontSize="small"
          />
        </Tooltip>
      </Box>
    );
  };

  const getMsgStatusFormating = (status) => {
    const statuses = {
      "Fetching GNIP": "fetching_gnip",
      "Updating Tracker": "updating_tracker",
      "Authentication error!": "authentication_error",
      paused: "paused",
      "non-social": "non_social_medias_data_limit_reached",
      social: "social_medias_data_limit_reached",
      "Invalid twitter handler name!": "Invalid_twitter_handler_name",
      monitor_list_social_twitter_account_locked:
        "monitor_list_social_twitter_account_locked",
      "Account needs to be re-authenticated ":
        "accounts_needs_re-authenticated_report",
      tw_limit_reached: "reports_limit_reached",
    };
    return statuses[status] || status;
  };

  let reportsWarningStatus = reportData?.attributes?.monitors?.filter((i) => {
    return i?.status?.length !== 0;
  });

  return (
    <Box className="view-report-header">
      <Box className="report-detail-name">
        {reportsWarningStatus?.length
          ? showWarningMsg(reportData?.attributes?.monitors)
          : null}
        <Box>{reportData?.attributes?.title}</Box>
        <Box component="span" className="report-frequency">
          {CheckValueLocale(
            `report_${reportData?.attributes?.frequency?.toLowerCase()}`,
            "",
            {},
            intl,
          )}
        </Box>
      </Box>
      <LucButton
        type="secondary"
        size="small"
        variant="flat"
        onClick={() => {
          history.push(`/reports/list`);
        }}
        endIcon={<ArrowForwardIos />}
      >
        {CheckValueLocale("back_to_reports", "", {}, intl)}
      </LucButton>
    </Box>
  );
};

export default ReportHeader;
