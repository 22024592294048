import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, Tabs, Tab } from "@mui/material";
import "./sideBarTabs.scss";
import { CheckValueLocale } from "utils/helpers";
import TabContent from "./tabContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";

export const tabsName = [
  {
    name: "all",
  },
  {
    name: "new",
  },
  {
    name: "active_status",
  },
  {
    name: "complete",
  },
];

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const SideBarTabs = ({
  cardState,
  setCardState,
  selectedCard,
  setSelectedCard,
  setFilters,
  filters,
  applyFilters,
  response,
  allData,
  preLoader,
  tabValue,
  setTabValue,
  completionContent,
  valueInput,
  completionReason,
  setLastOperation,
  setEngagementPostId,
  setSlaCounterData,
  slaCounterData,
  setOpenSidebarToAssignTeam,
}) => {
  const intl = useIntl();
  const [totalCount, setTotalCount] = useState(0);
  const [totalNewCount, setTotalNewCount] = useState(0);
  const [totalActiveCount, setTotalActiveCount] = useState(0);
  const [totalCompletCount, setTotalCompletCount] = useState(0);
  const [countLoading, setCountLoading] = useState(true);
  const [totalInteractions, setTotalInteractions] = useState(0);

  useEffect(() => {
    setCardState({ activeTab: filters.engagement_status });
    setTotalInteractions(0);
    setLastOperation(0);
    setEngagementPostId(null);
    //check if there selcted monitors or channel an if not we stop loading and make count for all tabs is 0
    if (!allData?.monitors?.length) {
      setCountLoading(false);
      setSlaCounterData({ ...slaCounterData, loading: false });
    } else {
      setCountLoading(true);
      setSlaCounterData({ ...slaCounterData, loading: true });
    }
    if (filters.engagement_status == "all") {
      setTabValue(0);
    }
  }, [filters.engagement_status]);

  const handleTabChange = (event, newValue) => {
    setTotalInteractions(0);
    setLastOperation(0);
    setEngagementPostId(null);
    if (!allData?.monitors?.length) {
      setCountLoading(false);
      setSlaCounterData({ ...slaCounterData, loading: false });
    } else {
      setCountLoading(true);
      setSlaCounterData({ ...slaCounterData, loading: true });
    }
    const tabsNames = ["all", "new", "active", "complete"];
    filters.page = 1;
    filters.engagement_status = tabsNames[newValue];
    setFilters({ ...filters });
    applyFilters();
    setTabValue(newValue);
  };
  let showLoading =
    (!countLoading && window.location.href.includes("alert_id")) ||
    !window.location.href.includes("alert_id");
  return (
    <>
      <Box className="tabs-wrapper">
        {showLoading ? (
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {tabsName?.map((tab, i) => (
              <Tab
                label={
                  <p id={`engagements-tab-${tab?.name}`} className="tab-name">
                    {CheckValueLocale(tab?.name, "", {}, intl)}
                    {countLoading ? (
                      <FontAwesomeIcon icon={faSpinnerThird} spin />
                    ) : (
                      <span className="tab-number">
                        {tab?.name == "all"
                          ? +totalCount >= 1000
                            ? `${(+totalCount / 1000).toFixed()}K `
                            : `${+totalCount} `
                          : tab?.name == "new"
                            ? +totalNewCount >= 1000
                              ? `${(+totalNewCount / 1000).toFixed()}K `
                              : `${+totalNewCount} `
                            : tab?.name == "active_status"
                              ? +totalActiveCount >= 1000
                                ? `${(+totalActiveCount / 1000).toFixed()}K `
                                : `${+totalActiveCount} `
                              : tab?.name == "complete"
                                ? +totalCompletCount >= 1000
                                  ? `${(+totalCompletCount / 1000).toFixed()}K `
                                  : `${+totalCompletCount} `
                                : null}
                      </span>
                    )}
                  </p>
                }
                {...a11yProps(0)}
              />
            ))}
          </Tabs>
        ) : null}
      </Box>
      <Box className="tabs-panel-wrapper">
        {tabsName?.map((tab, i) => (
          <TabPanel value={tabValue} index={i} className="tabs-panel-box">
            <TabContent
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              cardState={cardState}
              setCardState={setCardState}
              response={response}
              setFilters={setFilters}
              filters={filters}
              applyFilters={applyFilters}
              allData={allData}
              preLoader={preLoader}
              setTotalInteractions={setTotalInteractions}
              setCountLoading={setCountLoading}
              setSlaCounterData={setSlaCounterData}
              slaCounterData={slaCounterData}
              totalInteractions={totalInteractions}
              totalCount={totalCount}
              setTotalCount={setTotalCount}
              totalNewCount={totalNewCount}
              setTotalNewCount={setTotalNewCount}
              totalActiveCount={totalActiveCount}
              setTotalActiveCount={setTotalActiveCount}
              totalCompletCount={totalCompletCount}
              setTotalCompletCount={setTotalCompletCount}
              completionContent={completionContent}
              valueInput={valueInput}
              completionReason={completionReason}
              setLastOperation={setLastOperation}
              setEngagementPostId={setEngagementPostId}
              setTabValue={setTabValue}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
            />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};
export default SideBarTabs;
