import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
const FormFooter = () => {
  const intl = useIntl();
  return (
    <Box className="form-footer-wrapper">
      <Box component="p" className="form-footer-text">
        {CheckValueLocale("copy_rights", "", {}, intl)}
        {new Date()?.getFullYear()}{" "}
        {CheckValueLocale("lucidya_rights_login", "", {}, intl)}
      </Box>
    </Box>
  );
};
export default FormFooter;
