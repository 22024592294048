import Services from "./Services";

var LiveDashboard = {};

LiveDashboard.getMonitorWidgets = (queryData) => {
  return Services.getData("api/v4/live_dashboard/monitor_widgets", queryData);
};

LiveDashboard.getMonitorLiveDashboardDetails = (queryData) => {
  return Services.getData("api/v4/live_dashboard/index", queryData);
};
LiveDashboard.getMonitorLiveDashboardList = (queryData) => {
  return Services.getData("api/v4/live_dashboard/show", queryData);
};

LiveDashboard.createLiveDashboard = (queryData) => {
  return Services.postData("api/v4/live_dashboard/create", queryData);
};

LiveDashboard.editLiveDashboard = (queryData) => {
  return Services.putData(`api/v4/live_dashboard/update`, queryData);
};
LiveDashboard.deleteLiveDashboard = (queryData) => {
  return Services.deleteData("api/v4/live_dashboard/delete ", queryData);
};

export default LiveDashboard;
