import Channels from "../api/channel.js";

var ChannelController = {};

ChannelController.getChannels = (product_id) => {
  const queryData = {
    product_id,
  };

  return new Promise(function (resolve) {
    Channels.getChannels(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

ChannelController.EditCustomerCare = (
  monitorId,
  customer_care_accounts,
  product_id,
) => {
  let queryData = {
    customer_care_accounts,
    product_id,
  };
  return new Promise(function (resolve) {
    Channels.EditCustomerCare(monitorId, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default ChannelController;
