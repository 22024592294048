import moment from "moment";
import { isEmptyArray } from "utils/helpers";
import { handleXlsData, handleSortedCase } from "../excelCommonFn";

const engagements_fb_Keywords = (response, lang) => {
  if (isEmptyArray(response)) {
    return [];
  } else {
    var commentsVolume = [],
      commentsInteractions = [],
      reactions = [],
      sentimentAnalysis = [],
      topKeywords = [],
      topHashtags = [],
      associatedTopics = [],
      topPosts = [],
      dialects = [],
      subDialects = [],
      allSheetData = [];
    //_______________________________________________________________________________
    response?.map((i) => {
      Object.entries(i).forEach(([key, value]) => {
        switch (key) {
          case "commentsVolume":
            value?.map((i) => {
              commentsVolume.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.posts_word]: i?.value,
              });
            });
            allSheetData.push([commentsVolume, lang.posts_volume]);
            break;
          //_______________________________________________________________________________
          case "commentsInteractions":
            value?.map((i) => {
              commentsInteractions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.comments]: i?.value[0],
                [lang.reactions]: i?.value[1],
                [lang.total]: i?.value[0] + i?.value[1],
              });
            });
            allSheetData.push([commentsInteractions, lang.posts_interactions]);
            break;
          //_______________________________________________________________________________

          case "associatedTopics":
            value?.map((i) => {
              associatedTopics.push({
                [lang.topic]: i?.name,
                [lang.number]: i?.value,
              });
            });
            allSheetData.push([associatedTopics, lang.posts_associated_topics]);
            break;
          //_______________________________________________________________________________
          case "reactions":
            value?.map((i) => {
              reactions.push({
                [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),
                [lang.like_reaction]: i?.value[0],
                [lang.love_reaction]: i?.value[1],
                [lang.care_reaction]: i?.value[2],
                [lang.haha_reaction]: i?.value[3],
                [lang.wow_reaction]: i?.value[4],
                [lang.angry_reaction]: i?.value[5],
                [lang.total]:
                  i?.value[0] +
                  i?.value[1] +
                  i?.value[2] +
                  i?.value[3] +
                  i?.value[4] +
                  i?.value[5],
              });
            });
            allSheetData.push([reactions, lang.posts_reactions]);
            break;

          // _______________________________________________________________________________

          case "sentimentAnalysis":
            value?.negative?.map((i, index) => {
              if (i.name == value.neutral[index].name) {
                let total =
                  value.neutral[index].value +
                  value.positive[index].value +
                  value.negative[index].value;
                sentimentAnalysis.push({
                  [lang.date]: moment.unix(i?.name).utc().format("YYYY-MM-DD"),

                  [lang.neutral]:
                    total !== 0
                      ? ((value.neutral[index].value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.positive]:
                    total !== 0
                      ? ((value.positive[index].value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,

                  [lang.negative]:
                    total !== 0
                      ? ((value.negative[index].value / total) * 100).toFixed(
                          2,
                        ) + "%"
                      : 0,
                });
              }
            });
            allSheetData.push([
              sentimentAnalysis,
              lang.posts_sentiment_analysis,
            ]);
            break;

          //_______________________________________________________________________________

          case "dialects":
            handleSortedCase(value, dialects, lang.dialect, lang.number);
            allSheetData.push([dialects, lang.main_dialect_filter]);
            break;
          //_______________________________________________________________________________

          case "subDialects":
            handleSortedCase(
              value,
              subDialects,
              lang.sub_dialects,
              lang.number,
            );
            allSheetData.push([subDialects, lang.sub_dialects]);
            break;
          //_______________________________________________________________________________

          case "topKeywords":
            value?.map((i) => {
              topKeywords.push({
                [lang.keywords]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([
              topKeywords,
              lang.posts_top_keywords_xlsx_sheetname,
            ]);

            break;

          case "topHashtags":
            value?.map((i) => {
              topHashtags.push({
                [lang.xlsx_hashtags]: i?.id,
                [lang.xlsx_number_of_posts]: i?.stats_count,
              });
            });
            allSheetData.push([topHashtags, lang.posts_top_hashtags]);

            break;

          // _______________________________________________________________________________

          case "topPosts":
            value?.map((i) => {
              topPosts.push({ [lang.posts_word]: i?.tweet_link });
            });
            allSheetData.push([topPosts, lang.top_posts]);

            break;
          //_______________________________________________________________________________
          default:
            break;
        }
      });
    });
    //_______________________________________________________________________________

    return handleXlsData(allSheetData);
  }
};
export { engagements_fb_Keywords };
