import { useState } from "react";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { MoreVertSharp } from "@mui/icons-material";
import SnackBar from "components/snackBar.js";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import moment from "moment";
import SurveyController from "services/controllers/surveyController";
import { CheckValueLocale } from "utils/helpers";
import CreateSurveyModal from "../components/createSurveyModal";
import DropDownMenuOptions from "./dropDownMenuOptions/dropDownMenuOptions";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faEdit,
  faEllipsisVertical,
} from "@fortawesome/pro-regular-svg-icons";
import { handlelUserRoles } from "utils/helpers";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();
  let history = useHistory();
  const {
    setOpenPublishSurveyModal,
    setOpenShareSurveyModal,
    openPublishSurveyModal,
    setOpenDeleteSurveyModal,
    survey,
    surveyLimitReach,
  } = props;

  const [snackBar, setSnackBar] = useState({
    openSnackBar: false,
    message: "",
    severity: "",
    title: "",
  });

  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    openPauseResumeModal: false,
    modalTitle: "",
    modalBody: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    openSnackBar: false,
    snackBarAction: "",
    snackBarSeverity: "success",
    openCreateSurveyModal: false,
  });

  const [surveyDuplicateName, setSurveyDuplicateName] = useState(
    `copy of ${survey?.name}`,
  );

  const [isHovered, setIsHovered] = useState(false);
  const handleCloseSnackBar = () => {
    setSnackBar({ openSnackBar: false });
  };

  const open = Boolean(stats.anchorEl);
  const handleClick = (event) => {
    setStats({
      ...stats,
      anchorEl: event.currentTarget,
      openPauseResumeModal: false,
    });
  };
  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };

  const handleApplyExport = () => {
    handleClose();
    const queryData = {
      data: {
        survey: {
          start_date: survey?.oldest_date,
          end_date: moment().unix(),
          survey_id: survey?.id,
        },
      },
    };
    SurveyController.exportSurvey(queryData).then((result) => {
      if (result?.data?.status == 204 || result?.data?.status == 200) {
        setSnackBar?.({
          openSnackBar: true,
          message: CheckValueLocale("export_alert_msg", "", {}, intl),
          severity: "success",
          title: CheckValueLocale("export_alert_title", "", {}, intl),
        });
      } else {
        setSnackBar?.({
          openSnackBar: true,
          message: CheckValueLocale("try_again_error_message", "", {}, intl),
          severity: "error",
          title: CheckValueLocale("failed_error_message", "", {}, intl),
        });
      }
    });
  };

  const handleOpenDuplicateSurvey = (value) => {
    setStats({ ...stats, openCreateSurveyModal: value });
  };

  const handleApplyDuplicate = () => {
    const queryData = {
      survey: {
        name: surveyDuplicateName,
      },
    };
    SurveyController.duplicateSurvey(survey?.id, queryData).then((data) => {
      if (data) {
        handleClose();
        setStats({
          ...stats,
          openCreateSurveyModal: false,
        });
        if (data?.error) {
          setSnackBar?.({
            openSnackBar: true,
            message: CheckValueLocale("failed_error", "", {}, intl),
            severity: "error",
          });
        } else {
          setSnackBar?.({
            openSnackBar: true,
            message: CheckValueLocale(
              "survey_duplicated_success",
              "",
              {},
              intl,
            ),
            severity: "success",
          });
          setTimeout(() => {
            history.push(`/survey/survey-builder/${data?.data?.id}`);
          }, 3000);
        }
      } else {
        handleClose();
        setStats({
          ...stats,
          openCreateSurveyModal: false,
        });
      }
    });
  };
  return (
    <Box className="btn-box-syrvey-card">
      <CustomizedTooltip
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale(
              survey?.status === "draft"
                ? "survey_not_published"
                : "survey_insights_tooltip",
              "",
              {},
              intl,
            )}
          </Box>
        }
        placement="top"
        arrow
      >
        <Box className="syrvey-card-btn">
          <LucButton
            type="secondary"
            variant="flat"
            size="small"
            className="syrvey-icon-card"
            onClick={() => {
              history.push({
                pathname: `/survey/survey-insights/${survey.id}`,
                state: survey,
              });
            }}
            disableRipple
            disabled={survey?.status === "draft"}
          >
            <FontAwesomeIcon fixedWidth icon={faChartPie} />
          </LucButton>
        </Box>
      </CustomizedTooltip>

      <CustomizedTooltip
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale(
              survey?.status === "deleted" ? "survey_is_unpublished" : "edit",
              "",
              {},
              intl,
            )}
          </Box>
        }
        placement="top"
        arrow
      >
        {handlelUserRoles("SURVEY", "EDIT_SURVEY") && (
          <Box className="syrvey-card-btn">
            <LucButton
              type="secondary"
              variant="flat"
              size="small"
              className="syrvey-icon-card"
              onClick={() =>
                history.push(`/survey/survey-builder/${survey.id}`)
              }
              disableRipple
              disabled={survey?.status === "deleted"}
            >
              <FontAwesomeIcon icon={faEdit} />
            </LucButton>
          </Box>
        )}
      </CustomizedTooltip>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="top"
      >
        <IconButton
          aria-label="more"
          id="survey-cards-long-button"
          className={`drop-action-btn ${open && "opened-drop-actions"}`}
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          onClose={handleClose}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </IconButton>
      </CustomizedTooltip>

      <DropDownMenuOptions
        stats={stats}
        handleClose={handleClose}
        open={open}
        survey={survey}
        setOpenPublishSurveyModal={setOpenPublishSurveyModal}
        setOpenShareSurveyModal={setOpenShareSurveyModal}
        openPublishSurveyModal={openPublishSurveyModal}
        setOpenDeleteSurveyModal={setOpenDeleteSurveyModal}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        handleOpenDuplicateSurvey={handleOpenDuplicateSurvey}
        handleApplyExport={handleApplyExport}
        surveyLimitReach={surveyLimitReach}
      />

      {props.success && (
        <SnackBar
          open={props.success != null ? true : false}
          severity={props.successStatus}
          message={props.snackBarMsg}
          showCloseBtn={true}
        />
      )}
      <SnackBar
        open={snackBar?.openSnackBar}
        severity={snackBar?.severity}
        message={snackBar?.message}
        title={snackBar?.title}
        handleClose={handleCloseSnackBar}
      />
      {stats.openCreateSurveyModal && (
        <CreateSurveyModal
          handleOpenCreateSurvey={handleOpenDuplicateSurvey}
          openCreateSurveyModal={stats.openCreateSurveyModal}
          handleCreateSurvey={handleApplyDuplicate}
          duplicateSurveyName={surveyDuplicateName}
          setDuplicateSurveyName={setSurveyDuplicateName}
          isDuplicateSurvey
        />
      )}
    </Box>
  );
};
export default OptionsDropDown;
