import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import "../../createMonitor.scss";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import SocialMediaManagedAccount from "./components/socialMediadataSources/socialMediaDatasource.js";
import SocialMediaTabs from "../../Components/SocialMediaTabs/socialMediaTab";

import CommonFunctions from "../../createCommonFunctions/createMonitorCommonFunctions";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import Topic from "../../../MonitorsShared/SharedComponent/manualTopics/index";

const SocialMediaSources = (props) => {
  const intl = useIntl();

  const { getUserSocialAccounts, socialAccounts, isLoading } =
    CommonFunctions();

  const {
    handleCreateMonitorBtn,
    monitorData,
    setMonitorData,
    companyAllowedSources,
    setActiveDataSourceId,
    activeDataSourceId,
    isClikedFb,
    setIsClikedFb,
    isClikedIg,
    setIsClikedIg,
    monitorTopics,
    setMonitorTopics,
    errorKeywordsMsg,
    setErrorKewyordsMsg,
    errorExKeywordsMsg,
    setExErrorKewyordsMsg,
    displayNameMsgError,
    setDisplayNameMsgError,
    disabledManualTopic,
    manualTopicStats,
    value,
    setValue,
  } = props;

  let categotyDataSources = companyAllowedSources;
  const icons = [faFacebook, faInstagram];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getUserSocialAccounts(value);
  }, [value]);

  return (
    <>
      <Box>
        <Typography
          variant="caption"
          className="select-monitor-style"
          marginTop={4}
        >
          {CheckValueLocale("choose_social_media_source", "", {}, intl)}:
        </Typography>
        {/* social media tabs */}
        <SocialMediaTabs
          handleChange={handleChange}
          value={value}
          categotyDataSources={categotyDataSources}
          icons={icons}
          socialMediaCount={2}
        />
        {/* IG dataSource */}
        {value === 3 && (
          <SocialMediaManagedAccount
            handleCreateMonitorBtn={handleCreateMonitorBtn}
            monitorData={monitorData}
            setMonitorData={setMonitorData}
            SourceId={props.dataSourcesId.igSourceId}
            setActiveDataSourceId={props.setActiveDataSourceId}
            activeDataSourceId={props.activeDataSourceId}
            isClikedAvtiveDatasource={isClikedIg}
            setIsClikedAvtiveDatasource={setIsClikedIg}
            isClikedDatasource={isClikedFb}
            setIsClikedDatasource={setIsClikedFb}
            dataSourceName={"instagram"}
            socialAccounts={socialAccounts}
            isLoading={isLoading}
          />
        )}
        {/* FB dataSource */}
        {value === 2 && (
          <SocialMediaManagedAccount
            handleCreateMonitorBtn={handleCreateMonitorBtn}
            monitorData={monitorData}
            setMonitorData={setMonitorData}
            SourceId={props.dataSourcesId.fbSourceId}
            setActiveDataSourceId={setActiveDataSourceId}
            activeDataSourceId={activeDataSourceId}
            isClikedAvtiveDatasource={isClikedFb}
            setIsClikedAvtiveDatasource={setIsClikedFb}
            isClikedDatasource={isClikedIg}
            setIsClikedDatasource={setIsClikedIg}
            dataSourceName={"facebook"}
            socialAccounts={socialAccounts}
            isLoading={isLoading}
          />
        )}
      </Box>

      {!isEmptyArray(socialAccounts) && (
        <Box>
          <Topic
            monitorTopics={monitorTopics}
            setMonitorTopics={setMonitorTopics}
            errorKeywordsMsg={errorKeywordsMsg}
            setErrorKewyordsMsg={setErrorKewyordsMsg}
            errorExKeywordsMsg={errorExKeywordsMsg}
            setExErrorKewyordsMsg={setExErrorKewyordsMsg}
            displayNameMsgError={displayNameMsgError}
            setDisplayNameMsgError={setDisplayNameMsgError}
            disabledManualTopic={disabledManualTopic}
            manualTopicStats={manualTopicStats}
            addSeperatedLine
          />
        </Box>
      )}
    </>
  );
};

export default SocialMediaSources;
