import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useIntl } from "react-intl";
import "../../../../../../shared/createHeader/createHeader.scss";
import { Card, CardContent, Grid } from "@mui/material";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import SmsIcon from "@mui/icons-material/Sms";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { checkCompanyDatasources } from "../../../MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import { CheckValueLocale } from "utils/helpers";
import { clear as clearExplore } from "utils/redux/features/ExplorePage/exploreSlice";
import { useDispatch } from "react-redux";
const MonitorType = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    setSelectedMonitorType,
    selectedMonitorType,
    reverseMonitorTypes,
    setMonitorData,
    setKeywords,
    setChecked,
    setArrowChecked,
    setLanguage,
    setCountries,
    setMajorSource,
    setNbRanks,
    setSelectedSource,
    setMonitorErrors,
    setNbExcludedSource,
    setTitleSearchKeyword,
    setMonitorTopics,
    setDisplayNameMsgError,
  } = props;

  let icon,
    cardSubText,
    monitorName,
    isMangedAccount = true;
  const [isChangedType, setIsTypeChanged] = useState(false);
  let companySources = checkCompanyDatasources(props.companyAllowedSources);

  const selectMonitorsType = (monitorTypeName) => {
    setSelectedMonitorType(monitorTypeName);
    setIsTypeChanged(true);
  };

  //reset monitor data and keyword
  useEffect(() => {
    if (isChangedType) {
      setMonitorData({
        monitorName: "",
        monitorDescription: "",
        userName: "",
        pageName: "",
        cstCareAccount: "",
        spamKeywords: [],
        languages: [],
        selectedAccount: "",
        twitterCountries: [],
        twitterLanguages: [],
        auto_summary_widget: false,
      });
      setKeywords([
        {
          twitter: [
            { keywords: [], errorMsg: "", copy: "", answer: "" },
            { spam_keywords: [], errorMsg: "", copy: "", answer: "" },
            { collect_tweets: [], errorMsg: "", copy: "" },
            { ignore_tweets: [], errorMsg: "", copy: "" },
          ],
        },
        {
          instagram: [
            { hashtags: [], errorMsg: "", copy: "", answer: "" },
            { spam_hashtags: [], errorMsg: "", copy: "", answer: "" },
          ],
        },
        {
          facebook: [
            { keywords: [], errorMsg: "", copy: "", answer: "" },
            { spam_keywords: [], errorMsg: "", copy: "", answer: "" },
          ],
        },

        {
          newsBlogs: [
            { keywords: [], errorMsg: "", copy: false, answer: "" },
            { spam_keywords: [], errorMsg: "", copy: false, answer: "" },
          ],
        },
      ]);
      setNbExcludedSource([]);
      setTitleSearchKeyword([]);
      setLanguage([]);
      setCountries([]);
      setMajorSource([]);
      setNbRanks([]);
      setChecked([0]);
      setArrowChecked([0]);
      setSelectedSource("all");
      setMonitorErrors({
        monitorName: "",
        userName: "",
        pageName: "",
        cstCareAccount: "",
        spamKeywords: "",
      });
      setIsTypeChanged(false);
      setMonitorTopics([
        {
          name: "",
          keywords: [],
          exclude_keywords: [],
        },
      ]);
      setDisplayNameMsgError({ i: "", errorMsg: "" });
      dispatch(clearExplore());
    }
  }, [isChangedType]);

  //check datasource
  //change initial selected monitor
  if (companySources.length > 0) {
    if (
      !(
        companySources.includes("facebook") ||
        companySources.includes("instagram")
      ) &&
      (selectedMonitorType == "MANAGED_ACCOUNT" ||
        selectedMonitorType == "MANAGED_PAGE")
    ) {
      setSelectedMonitorType("ACCOUNT_ANALYSIS");
    } else if (
      !(
        companySources.includes("twitter") ||
        companySources.includes("facebook") ||
        companySources.includes("instagram")
      ) &&
      selectedMonitorType == "ACCOUNT_ANALYSIS"
    ) {
      setSelectedMonitorType("KEYWORD");
    }
  }

  const getMonitorTypesName = (monitorTypeName) => {
    if (
      (monitorTypeName === "MANAGED_ACCOUNT" ||
        monitorTypeName === "MANAGED_PAGE") &&
      isMangedAccount
    ) {
      monitorName = "managed_account";
      cardSubText = "managed_account_description";
      icon = <AccountCircleIcon />;
    } else if (monitorTypeName === "KEYWORD") {
      monitorName = "keywords";
      cardSubText = "keywords_description";
      icon = <SmsIcon />;
    } else if (monitorTypeName === "ACCOUNT_ANALYSIS") {
      monitorName = "public_account";
      cardSubText = "public_account_description";
      icon = <CorporateFareIcon />;
    }
  };
  const hasMonitorType = (monitorTypeName) => {
    return (
      ((monitorTypeName === "MANAGED_ACCOUNT" ||
        monitorTypeName === "MANAGED_PAGE") &&
        isMangedAccount) ||
      monitorTypeName === "ACCOUNT_ANALYSIS" ||
      monitorTypeName === "KEYWORD"
    );
  };

  return (
    <div className="monitor-type">
      <Container>
        <Typography
          variant="caption"
          sx={{ display: "inline-block" }}
          className="select-monitor-style"
        >
          {CheckValueLocale("choose_monitor_type", "", {}, intl)}
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {reverseMonitorTypes.map((monitorType, i) => {
            let monitorTypeName = monitorType.attributes.name[0];
            getMonitorTypesName(monitorTypeName);
            return hasMonitorType(monitorTypeName) ? (
              <Grid item xs={6} className="grid-cards-Style" key={i}>
                {
                  <Card
                    className={`create-monitor-card ${
                      selectedMonitorType === monitorTypeName && `selected`
                    } ${
                      (monitorTypeName === "MANAGED_ACCOUNT" ||
                        monitorTypeName === "MANAGED_PAGE") &&
                      !(
                        companySources.includes("facebook") ||
                        companySources.includes("instagram")
                      )
                        ? "disable-monitor-type"
                        : ""
                    }
                  ${
                    monitorTypeName === "ACCOUNT_ANALYSIS" &&
                    !(
                      companySources.includes("twitter") ||
                      companySources.includes("facebook") ||
                      companySources.includes("instagram")
                    )
                      ? "disable-monitor-type"
                      : ""
                  }`}
                    onClick={() => selectMonitorsType(monitorTypeName)}
                  >
                    <CardContent className="card-padding-class">
                      <IconButton className="card-heading-with-icon">
                        {icon}
                        <Typography
                          variant="h6"
                          component="div"
                          className="card-heading"
                        >
                          {CheckValueLocale(monitorName, "", {}, intl)}
                        </Typography>
                      </IconButton>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                        className="card-sub-text"
                      >
                        {CheckValueLocale(cardSubText, "", {}, intl)}
                      </Typography>
                    </CardContent>
                  </Card>
                }
                {(isMangedAccount = false)}
              </Grid>
            ) : (
              ""
            );
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default MonitorType;
