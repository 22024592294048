import React from "react";
import { useIntl } from "react-intl";
import { Box, LinearProgress, Stack } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./commonStyle.module.css";
import "../../surveyBuilder.scss";

const Footer = (props) => {
  const {
    filteredSections,
    sectionsStack,
    submitted,
    progressValue,
    btnVal,
    handleClickNext,
    handleClickBack,
    handleSubmit,
    showPoweredBy,
  } = props;
  const intl = useIntl();
  return (
    <Box className={styles.footerStyle}>
      {!submitted && (
        <Box className={styles.footerPagination}>
          <Box className={styles.boxBack}>
            {sectionsStack?.length > 1 && (
              <LucButton
                className="back-to-survey-btn"
                variant="flat"
                minWidth={70}
                onClick={handleClickBack}
              >
                <ArrowBackIcon className={styles.ArrowBackIcon} />
                {CheckValueLocale("survey_back", "", {}, intl)}
              </LucButton>
            )}
          </Box>
          <Stack className={styles.stackProgress}>
            <LinearProgress
              className={styles.linearProgress}
              variant="determinate"
              value={progressValue}
            />
          </Stack>
          <Box className={styles.btnNext}>
            {btnVal === -1 ||
            btnVal === null ||
            filteredSections?.length < 2 ? (
              <LucButton
                className="next-to-survey-btn"
                minWidth={80}
                onClick={handleSubmit}
              >
                {CheckValueLocale("survey_submit", "", {}, intl)}
              </LucButton>
            ) : (
              <LucButton
                className="next-to-survey-btn"
                minWidth={80}
                endIcon={<ArrowBackIcon className={styles.ArrowBackIcon} />}
                onClick={handleClickNext}
              >
                {CheckValueLocale("survey_next", "", {}, intl)}
              </LucButton>
            )}
          </Box>
        </Box>
      )}

      {showPoweredBy ? (
        <Box>
          <img
            src="/survey_builder_icons/powered_by_lucidya_new.svg"
            alt="logo"
            className={styles.footerImgStyle}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default Footer;
