import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Addons from "./addons";
import ChannelAnalytics from "./channelAnalytics";
import SocialListenting from "./socialListenting";
import SurveyPackage from "./surveyPackage";
import AudiencePackage from "./audiencePackage";
import EngagementPackage from "./engagementPackage";
import PublicApiPackage from "./publicApiPackage";
import AiApiPackage from "./aiApiPackage";

import CircularLoading from "components/circularProgress";

//Controller
import CompaniesController from "services/controllers/companiesController";
import CxmPackage from "./cxmCorePackage";
import CxmCorePackage from "./cxmCorePackage";
import LuciPackage from "./luciPackage";
import PageHeader from "components/pageHeader";
import CombinedSocialListentingCA from "./combinedSocialListentingCA";

const SubscriptionIndex = (props) => {
  const history = useHistory();
  const [socialListeningSuccess, setSocialListeningSuccess] = useState(null);
  const [channelAnalyticsSuccess, setChannelAnalyticsSuccess] = useState(null);
  const [surveySuccess, setSurveySuccess] = useState(null);
  const [cdpSuccess, setCdpSuccess] = useState(null);
  const [engagementSuccess, setEngagementSuccess] = useState(null);
  const [publicApiSuccess, setPublicApiSuccess] = useState(null);
  const [aiApiSuccess, setAiApiSuccess] = useState(null);
  const [luciSnackBar, setLuciSnackBar] = useState(false);
  const [luciSuccess, setLuciSuccess] = useState(false);
  const [socialListeningRequest, setSocialListeningRequest] = useState(null);
  const [channelAnalyticsRequest, setChannelAnalyticsRequest] = useState(null);
  const [surveyRequest, setSurveyRequest] = useState(null);
  const [cdpRequest, setCdpRequest] = useState(null);
  const [engagementRequest, setEngagementRequest] = useState(null);
  const [publicApiRequest, setPublicApiRequest] = useState(null);
  const [aiApiRequest, setAiApiRequest] = useState(null);

  const [cxmActive, setCxmActive] = useState(false);
  const [smActive, setSmActive] = useState(false);
  const [cxmCoreActive, setCxmCoreActive] = useState(false);
  const [luciActive, setLuciActive] = useState(false);
  const [surveyActive, setSurveyActive] = useState(false);
  const [cdpActive, setCdpActive] = useState(false);
  const [engagementActive, setEngagementActive] = useState(false);
  const [publicApiActive, setPublicApiActive] = useState(false);
  const [aiApiActive, setAiApiActive] = useState(false);

  const [isSMLoadingUpgrade, setIsSMLoadingUpgrade] = useState(false);
  const [isCXMLoadingUpgrade, setIsCXMLoadingUpgrade] = useState(false);
  const [isSurveyLoadingUpgrade, setIsSurveyLoadingUpgrade] = useState(false);
  const [isLuciLoadingUpgrade, setIsLuciLoadingUpgrade] = useState(false);

  const [isAudienceLoadingUpgrade, setIsAudienceLoadingUpgrade] =
    useState(false);
  const [isEngagementLoadingUpgrade, setIsEngagementLoadingUpgrade] =
    useState(false);
  const [isPublicApiLoadingUpgrade, setIsPublicApiLoadingUpgrade] =
    useState(false);
  const [isAiApiLoadingUpgrade, setIsAiApiLoadingUpgrade] = useState(false);

  const [AddOnsSuccess, setAddOnsSuccess] = useState(null);

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [socialListeningInfo, setSocialListeningInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });
  const [newsAndBlogsInfo, setNewsAndBlogsInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });
  const [channelAnalyticsInfo, setChannelAnalyticsInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });
  const [surveyInfo, setSrveyInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });
  const [cdpInfo, setCdpInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });
  const [cxmCoreInfo, setCxmCoreInfo] = useState({});
  const [luciInfo, setLuciInfo] = useState({});
  const [engagementInfo, setEngagementInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });

  const [publicApiInfo, setPublicApiInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });
  const [aiApiInfo, setAiApiInfo] = useState({
    id: "",
    type: "",
    packageInfo: {},
    subscriptionInfo: {},
  });

  useEffect(() => {
    getCompanySubscriptions();
    if (
      window.localStorage?.activeProducts &&
      window.localStorage?.activeProducts.length > 0
    ) {
      var activeProducts = JSON.parse(window.localStorage?.activeProducts);
      activeProducts.map((product) => {
        if (product.name === "CXM") {
          setCxmActive(true);
        } else if (product.name === "SM") {
          setSmActive(true);
        } else if (product.name === "SURVEY") {
          setSurveyActive(true);
        } else if (product.name === "CDP") {
          setCdpActive(true);
        } else if (product.name === "CXM_CORE") {
          setCxmCoreActive(true);
        } else if (product.name === "LUCI") {
          setLuciActive(true);
        } else if (product?.name === "ENGAGEMENTS") {
          setEngagementActive(true);
        } else if (product?.name === "PUBLIC_API") {
          setPublicApiActive(true);
        } else if (product?.name === "AI_API") {
          setAiApiActive(true);
        }
      });
    }
  }, []);

  //social listening - SM
  const sendProductUpgradeSM = () => {
    let queryData = {
      service_name: "request_social_listening_upgrade",
    };
    setIsSMLoadingUpgrade(true);
    !isSMLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result.errorMsg) {
          setSocialListeningRequest(true);
          setSocialListeningSuccess(true);
        } else {
          setSocialListeningRequest(false);
          setSocialListeningSuccess(false);
        }
        setIsSMLoadingUpgrade(false);
      });
  };

  // Channel Analytics - CXM
  const sendProductUpgradeCXM = () => {
    let queryData = {
      service_name: "request_channel_analytics_upgrade",
    };
    setIsCXMLoadingUpgrade(true);
    !isCXMLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result.errorMsg) {
          setChannelAnalyticsRequest(true);
          setChannelAnalyticsSuccess(true);
        } else {
          setChannelAnalyticsRequest(false);
          setChannelAnalyticsSuccess(false);
        }
        setIsCXMLoadingUpgrade(false);
      });
  };

  // Survey
  const sendProductUpgradeSurvey = () => {
    let queryData = {
      service_name: "request_survey_upgrade",
    };
    setIsSurveyLoadingUpgrade(true);
    !isSurveyLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result.errorMsg) {
          setSurveyRequest(true);
          setSurveySuccess(true);
        } else {
          setSurveyRequest(false);
          setSurveySuccess(false);
        }
        setIsSurveyLoadingUpgrade(false);
      });
  };
  const sendProductUpgradeLuci = (isDemo) => {
    setLuciSuccess(false);
    setLuciSnackBar(false);
    let queryData = {
      service_name: isDemo ? "request_luci_demo" : "request_luci_upgrade",
    };
    setIsLuciLoadingUpgrade(true);
    !isLuciLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result.errorMsg) {
          setLuciSuccess(true);
          setLuciSnackBar(true);
        } else {
          setLuciSuccess(false);
          setLuciSnackBar(true);
        }
        setIsLuciLoadingUpgrade(false);
      });
  };
  //CDP
  const sendProductUpgradeCDP = () => {
    let queryData = {
      service_name: "request_audience_upgrade",
    };
    setIsAudienceLoadingUpgrade(true);
    !isAudienceLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result.errorMsg) {
          setCdpRequest(true);
          setCdpSuccess(true);
        } else {
          setCdpRequest(false);
          setCdpSuccess(false);
        }
        setIsAudienceLoadingUpgrade(false);
      });
  };
  // engagement
  const sendProductUpgradeEngagement = () => {
    let queryData = {
      service_name: "request_engagement_upgrade",
    };
    setIsEngagementLoadingUpgrade(true);
    !isEngagementLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result?.errorMsg) {
          setEngagementRequest(true);
          setEngagementSuccess(true);
        } else {
          setEngagementRequest(false);
          setEngagementSuccess(false);
        }
        setIsEngagementLoadingUpgrade(false);
      });
  };

  // publicApi
  const sendProductUpgradePublicApi = () => {
    let queryData = {
      service_name: "request_public_api_upgrade",
    };
    setIsPublicApiLoadingUpgrade(true);
    !isPublicApiLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result?.errorMsg) {
          setPublicApiRequest(true);
          setPublicApiSuccess(true);
        } else {
          setPublicApiRequest(false);
          setPublicApiSuccess(false);
        }
        setIsPublicApiLoadingUpgrade(false);
      });
  };
  // aiApi
  const sendProductUpgradeAiApi = () => {
    let queryData = {
      service_name: "request_ai_api_upgrade",
    };
    setIsAiApiLoadingUpgrade(true);
    !isAiApiLoadingUpgrade &&
      CompaniesController.requestService(queryData).then((result) => {
        if (!result?.errorMsg) {
          setAiApiRequest(true);
          setAiApiSuccess(true);
        } else {
          setAiApiRequest(false);
          setAiApiSuccess(false);
        }
        setIsAiApiLoadingUpgrade(false);
      });
  };
  //social analytics
  const getCompanySubscriptions = () => {
    CompaniesController.getCompanySubscriptions().then((result) => {
      if (!result.errorMsg) {
        let socialListeningInfo = result?.data?.data?.attributes?.sm_package;
        let newsAndBlogsInfo = result?.data?.data?.attributes?.nb_package;
        let cxm = result?.data?.data?.attributes?.sm_package;

        let channelAnalyticsInfo = result?.data?.data?.attributes?.ca_package;
        let surveyInfo = result?.data?.data?.attributes?.survey_package;
        let cdpInfo = result?.data?.data?.attributes?.cdp_package;
        let engagementInfo = result?.data?.data?.attributes?.engagement_package;
        let publicApiInfo = result?.data?.data?.attributes?.public_api_package;
        let aiApiInfo = result?.data?.data?.attributes?.ai_api_package;
        if (
          result?.data?.data?.attributes?.sm_package !== undefined &&
          result?.data?.data?.attributes?.sm_package !== null
        ) {
          setSocialListeningInfo({
            id: socialListeningInfo?.id,
            type: socialListeningInfo?.name,
            packageInfo: socialListeningInfo?.attributes,
            subscriptionInfo: socialListeningInfo?.attributes,
          });
        }
        if (
          result?.data?.data?.attributes?.sm_package !== undefined &&
          result?.data?.data?.attributes?.sm_package !== null
        ) {
          setNewsAndBlogsInfo({
            id: newsAndBlogsInfo?.id,
            type: newsAndBlogsInfo?.name,
            packageInfo: newsAndBlogsInfo?.attributes,
            subscriptionInfo: newsAndBlogsInfo?.attributes,
          });
        }
        if (
          result?.data?.data?.attributes?.ca_package !== undefined &&
          result?.data?.data?.attributes?.ca_package !== null
        ) {
          setChannelAnalyticsInfo({
            id: channelAnalyticsInfo?.id,
            type: channelAnalyticsInfo?.name,
            packageInfo: channelAnalyticsInfo?.attributes,
            subscriptionInfo: channelAnalyticsInfo?.attributes,
          });
        }
        if (
          result?.data?.data?.attributes?.survey_package !== undefined &&
          result?.data?.data?.attributes?.survey_package !== null
        ) {
          setSrveyInfo({
            id: surveyInfo?.id,
            type: surveyInfo?.name,
            packageInfo: surveyInfo?.attributes,
            subscriptionInfo: surveyInfo?.attributes,
          });
        }
        if (
          result?.data?.data?.attributes?.cdp_package !== undefined &&
          result?.data?.data?.attributes?.cdp_package !== null
        ) {
          setCdpInfo({
            id: cdpInfo?.id,
            type: cdpInfo?.name,
            packageInfo: cdpInfo?.attributes,
            subscriptionInfo: cdpInfo?.attributes,
          });
        }
        if (
          result.data.data.attributes.cxm_core_package !== undefined &&
          result.data.data.attributes.cxm_core_package !== null
        ) {
          setCxmCoreInfo(result.data.data.attributes.cxm_core_package);
        }
        if (
          result?.data?.data?.attributes?.luci_package == undefined ||
          result?.data?.data?.attributes?.luci_package == null ||
          result?.data?.data?.attributes?.luci_package
        ) {
          setLuciInfo(
            result?.data?.data?.attributes?.luci_package?.attributes || null,
          );
        }
        if (
          result?.data?.data?.attributes?.engagement_package !== undefined &&
          result?.data?.data?.attributes?.engagement_package !== null
        ) {
          setEngagementInfo({
            id: engagementInfo?.id,
            type: engagementInfo?.name,
            packageInfo: engagementInfo?.attributes,
            subscriptionInfo: engagementInfo?.attributes,
          });
        }
        if (
          result?.data?.data?.attributes?.public_api_package !== undefined &&
          result?.data?.data?.attributes?.public_api_package !== null
        ) {
          setPublicApiInfo({
            id: publicApiInfo?.id,
            type: publicApiInfo?.name,
            packageInfo: publicApiInfo?.attributes,
            subscriptionInfo: publicApiInfo?.attributes,
          });
        }
        if (
          result?.data?.data?.attributes?.ai_api_package !== undefined &&
          result?.data?.data?.attributes?.ai_api_package !== null
        ) {
          setAiApiInfo({
            id: aiApiInfo?.id,
            type: aiApiInfo?.name,
            packageInfo: aiApiInfo?.attributes,
            subscriptionInfo: aiApiInfo?.attributes,
          });
        }
        setIsLoading(false);
      } else {
        return false;
      }
    });
  };

  const smRef = useRef(null);
  const cxmRef = useRef(null);

  const scrollToRef = (elementRef) => {
    const elementRect = elementRef?.current?.getBoundingClientRect();
    const scrollTop =
      elementRect?.top - window?.innerHeight / 2 + elementRect?.height / 2;

    window.scrollTo({ top: scrollTop, behavior: "smooth" });
  };

  useEffect(() => {
    if (!isLoading) {
      if (
        history?.location?.needsAttentionLimitError === "sm_limit" ||
        history?.location?.needsAttentionLimitError === "news_blogs_limit"
      )
        return scrollToRef(smRef);
      if (history?.location?.needsAttentionLimitError === "cxm_limit")
        return scrollToRef(cxmRef);
    }
  }, [history?.location, isLoading]);

  return (
    <div>
      {isLoading === true ? (
        <CircularLoading />
      ) : (
        <div id="subscription">
          <PageHeader title="subscription" titleId="secondary-pages-header" />
          {cxmCoreInfo !== undefined && (
            <CxmCorePackage
              cxmCoreInfo={cxmCoreInfo}
              sendPackageUpgrade={sendProductUpgradeCDP}
              requested={socialListeningRequest}
              cxmCoreActive={cxmCoreActive}
            />
          )}
          {aiApiInfo !== undefined ? (
            <AiApiPackage
              aiApiInfo={aiApiInfo}
              sendPackageUpgrade={sendProductUpgradeAiApi}
              success={aiApiSuccess}
              setSuccess={setAiApiSuccess}
              requested={aiApiRequest}
              aiApiActive={aiApiActive}
              isLoadingUpgrade={isAiApiLoadingUpgrade}
            />
          ) : null}
          {publicApiInfo !== undefined ? (
            <PublicApiPackage
              publicApiInfo={publicApiInfo}
              sendPackageUpgrade={sendProductUpgradePublicApi}
              success={publicApiSuccess}
              setSuccess={setPublicApiSuccess}
              requested={publicApiRequest}
              publicApiActive={publicApiActive}
              isLoadingUpgrade={isPublicApiLoadingUpgrade}
            />
          ) : null}
          <LuciPackage
            luciInfo={luciInfo}
            sendPackageUpgrade={sendProductUpgradeLuci}
            luciSuccess={luciSuccess}
            luciSnackBar={luciSnackBar}
            setLuciSuccess={setLuciSuccess}
            setLuciSnackBar={setLuciSnackBar}
            luciActive={luciActive}
            isLoadingUpgrade={isLuciLoadingUpgrade}
          />

          {socialListeningInfo !== undefined &&
          !socialListeningInfo?.packageInfo?.combined_packages ? (
            <SocialListenting
              socialListeningInfo={socialListeningInfo}
              sendPackageUpgrade={sendProductUpgradeSM}
              success={socialListeningSuccess}
              setSuccess={setSocialListeningSuccess}
              requested={socialListeningRequest}
              smActive={smActive}
              isLoadingUpgrade={isSMLoadingUpgrade}
              newsAndBlogsInfo={newsAndBlogsInfo}
              smRef={smRef}
            />
          ) : null}

          {channelAnalyticsInfo !== undefined &&
          !channelAnalyticsInfo?.packageInfo?.combined_packages ? (
            <ChannelAnalytics
              channelAnalyticsInfo={channelAnalyticsInfo}
              sendPackageUpgrade={sendProductUpgradeCXM}
              success={channelAnalyticsSuccess}
              setSuccess={setChannelAnalyticsSuccess}
              requested={channelAnalyticsRequest}
              cxmActive={cxmActive}
              isLoadingUpgrade={isCXMLoadingUpgrade}
              cxmRef={cxmRef}
            />
          ) : null}

          {socialListeningInfo !== undefined &&
          channelAnalyticsInfo !== undefined &&
          socialListeningInfo?.packageInfo?.combined_packages &&
          channelAnalyticsInfo?.packageInfo?.combined_packages ? (
            <CombinedSocialListentingCA
              socialListeningInfo={socialListeningInfo}
              channelAnalyticsInfo={channelAnalyticsInfo}
              sendPackageUpgrade={sendProductUpgradeSM}
              success={socialListeningSuccess}
              setSuccess={setSocialListeningSuccess}
              requested={socialListeningRequest}
              smActive={smActive}
              isLoadingUpgrade={isSMLoadingUpgrade}
              newsAndBlogsInfo={newsAndBlogsInfo}
            />
          ) : null}

          {surveyInfo !== undefined && (
            <SurveyPackage
              surveyInfo={surveyInfo}
              sendPackageUpgrade={sendProductUpgradeSurvey}
              success={surveySuccess}
              setSuccess={setSurveySuccess}
              requested={surveyRequest}
              surveyActive={surveyActive}
              isLoadingUpgrade={isSurveyLoadingUpgrade}
            />
          )}
          {cdpInfo !== undefined && (
            <AudiencePackage
              cdpInfo={cdpInfo}
              sendPackageUpgrade={sendProductUpgradeCDP}
              success={cdpSuccess}
              setSuccess={setCdpSuccess}
              requested={cdpRequest}
              cdpActive={cdpActive}
              isLoadingUpgrade={isAudienceLoadingUpgrade}
            />
          )}
          {engagementInfo !== undefined ? (
            <EngagementPackage
              engagementInfo={engagementInfo}
              sendPackageUpgrade={sendProductUpgradeEngagement}
              success={engagementSuccess}
              setSuccess={setEngagementSuccess}
              requested={engagementRequest}
              engagementActive={engagementActive}
              isLoadingUpgrade={isEngagementLoadingUpgrade}
            />
          ) : null}
          <Addons
            errorMsg={errorMsg}
            successMsg={successMsg}
            success={AddOnsSuccess}
            setSuccess={setAddOnsSuccess}
          />
        </div>
      )}
    </div>
  );
};
export default SubscriptionIndex;
