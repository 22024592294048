import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import { faComments, faEye, faBolt } from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import {
  faAngry,
  faHeart,
  faLaughBeam,
  faMeh,
  faRetweet,
  faGlobeAsia,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import Reactions from "images/monitor_revamp/fb_react.svg";
import MonitorsController from "services/controllers/monitorsController";
import { useParams } from "react-router-dom";
import "./questionCard.scss";
import { numberFormatter, CheckValueLocale } from "utils/helpers/index";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ThemesDropdown from "./components/themesDropDown";
import SubThemesDropdown from "./components/subThemesDropdown";
import InteractionsController from "services/controllers/interactionsController";
import angryIcon from "images/reaction-icons/angry.svg";
import careIcon from "images/reaction-icons/care.svg";
import hahaIcon from "images/reaction-icons/haha.svg";
import likeIcon from "images/reaction-icons/like.svg";
import loveIcon from "images/reaction-icons/love.svg";
import newsIcon from "images/reaction-icons/newsIcon.svg";
import sadIcon from "images/reaction-icons/sad.svg";
import wowIcon from "images/reaction-icons/wow.svg";

const CardFooter = (props) => {
  const {
    question,
    showHeart,
    showRetweet,
    showFlash,
    showEye,
    showEarth,
    monitorDataSource,
    monitorType,
    getMoreInsights,
    insightsID,
    response,
    dates,
    datasourcePostsType,
    isAggregateDashboard,
    hideChangeSentiment,
    hideWhenRetweeted,
    activeTab,
  } = props;
  const intl = useIntl();
  const { monitorId } = useParams();
  let reactionCount = 0;
  const reactionsValue =
    question?.reactions && typeof question?.reactions === "object"
      ? Object.values(question.reactions)
      : question?.reactions;

  if (reactionsValue) {
    if (typeof question?.reactions === "object") {
      for (let i = 0; i < reactionsValue.length; i++) {
        reactionCount += reactionsValue[i];
      }
    } else {
      reactionCount = question?.reactions;
    }
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [themesOptions, setThemesOptions] = useState([]);
  const [subThemesOptions, setSubThemesOptions] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // __________________________________________________________________________
  var sentimentMenu = [
    {
      name: "positive",
      label: 1,
      icon: (
        <FontAwesomeIcon
          className={"smiliesStyle positive"}
          icon={faLaughBeam}
          style={{ color: "#8CC63F" }}
        />
      ),
    },

    {
      name: "neutral",
      label: 2,
      icon: (
        <FontAwesomeIcon
          className={"smiliesStyle neutral"}
          icon={faMeh}
          style={{ color: "#F9A700" }}
        />
      ),
    },
    {
      name: "negative",
      label: 0,
      icon: (
        <FontAwesomeIcon className={"smiliesStyle negative"} icon={faAngry} />
      ),
    },
  ];
  const [selectedSentiment, setSelectedSentiment] = useState({});
  // __________________________________________________________________________

  useEffect(() => {
    handleSelectedSentiment(question.sentiment);
  }, []);
  // __________________________________________________________________________

  const handleSelectedSentiment = (newLabel) => {
    sentimentMenu.map((sentiment, i) => {
      if (newLabel === sentiment.label || newLabel === sentiment.name) {
        setSelectedSentiment(sentiment);
        handleClose();
      }
    });
  };
  // __________________________________________________________________________

  const handleEditSentiment = (tweetId, newLabel, oldLabel) => {
    var previousLabel =
      oldLabel === "positive"
        ? 1
        : oldLabel === "negative"
          ? 0
          : oldLabel === "neutral"
            ? 2
            : null;

    MonitorsController.editSentimentInteractions(
      isAggregateDashboard
        ? datasourcePostsType
        : monitorDataSource === "NEWSBLOGS"
          ? "nb"
          : monitorDataSource,
      window.localStorage.sm_id,
      tweetId,
      monitorId ? monitorId : question?.tracker_id,
      newLabel,
      previousLabel,
    ).then((data) => {
      if (data.data.status === 200) {
        handleSelectedSentiment(newLabel);
      }
    });
  };
  useEffect(() => {
    if (
      activeTab === "comments_mentions" ||
      activeTab === "questions" ||
      activeTab === "engagements" ||
      activeTab === "customer_care"
    ) {
      InteractionsController.getThemes().then((data) =>
        setThemesOptions(data?.data?.data?.data),
      );
      InteractionsController.getSubThemes().then((data) =>
        setSubThemesOptions(data?.data?.data?.data),
      );
    }
  }, []);
  const localizedDate =
    window.localStorage.lang === "en"
      ? moment
          .unix(parseInt(question?.created_at))
          .utc()
          .format("D MMM, YYYY h:mm A")
      : moment
          .unix(question?.created_at)
          .utc()
          .format("D MMM, YYYY h:mm A")
          ?.split(" ")[0] +
        " " +
        CheckValueLocale(
          moment
            .unix(question?.created_at)
            .utc()
            .format("D MMM, YYYY h:mm A")
            ?.split(" ")[1],
          "",
          {},
          intl,
        ) +
        " " +
        moment
          .unix(question?.created_at)
          .utc()
          .format("D MMM, YYYY h:mm A")
          ?.split(" ")[2];
  const localizedTime =
    window.localStorage.lang === "en"
      ? ""
      : moment
          ?.unix(question?.created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[4] +
        " " +
        moment
          ?.unix(question?.created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[5];

  const reactionImages = {
    angry: angryIcon,
    care: careIcon,
    haha: hahaIcon,
    like: likeIcon,
    love: loveIcon,
    news: newsIcon,
    sad: sadIcon,
    wow: wowIcon,
  };

  return (
    <Box
      mt={
        (monitorDataSource === "INSTAGRAM" ||
          datasourcePostsType === "INSTAGRAM") &&
        3
      }
      className="card-footer"
    >
      <Box className="question-card-footer">
        <Box className="statistics-icons">
          {/* case to show when montiorType =keyword & reaction == 0 */}
          {question?.reactions == 0 &&
            (monitorDataSource === "FACEBOOK" ||
              datasourcePostsType === "FACEBOOK") &&
            monitorType === "keyword" && (
              <img
                src={Reactions}
                alt="reactions"
                className="reactions-style"
              />
            )}
          {question?.reactions && (
            <Box className="display-in-row">
              {Object.keys(question?.reactions).map((reaction) => {
                return (
                  <img
                    src={reactionImages[reaction]}
                    alt="reactions"
                    className="img-style"
                  />
                );
              })}
              {(reactionsValue.length === 0 ||
                typeof reactionsValue !== "object") && (
                <img
                  src={Reactions}
                  alt="reactions"
                  className="reactions-style"
                />
              )}
              {(typeof question?.reactions === "object" &&
                reactionsValue.length) ||
              (typeof question?.reactions === "number") > 0
                ? reactionCount.toLocaleString()
                : 0}
            </Box>
          )}
          {showRetweet && question.retweet_count !== undefined && (
            <Box className="post-footer-icon">
              <FontAwesomeIcon
                icon={faRetweet}
                id="monitors-question-card-retweet-fa-icon"
              />
              {question.retweet_count !== null
                ? parseInt(question?.retweet_count)?.toLocaleString()
                : 0}
            </Box>
          )}
          {showHeart && question.heart_count !== undefined && (
            <Box className="post-footer-icon">
              <FontAwesomeIcon
                icon={faHeart}
                id="monitors-question-card-heart-fa-icon"
              />
              {typeof question?.heart_count === "object" ||
              isNaN(question?.heart_count)
                ? 0
                : parseInt(question?.heart_count)?.toLocaleString()}
            </Box>
          )}
          <Box className="post-footer-icon">
            <FontAwesomeIcon
              icon={faComments}
              id="monitors-question-card-comments-fa-icon"
            />
            {question?.comments_count !== null
              ? parseInt(question?.comments_count)?.toLocaleString()
              : 0}
          </Box>

          {showEye && question?.alexa_pageviews && (
            <Box>
              <FontAwesomeIcon
                icon={faEye}
                id="monitors-question-card-eye-fa-icon"
              />
              {Math.round(
                parseInt(question?.alexa_pageviews / 1000),
              )?.toLocaleString()}
              {parseInt(question?.alexa_pageviews) !== 0 && "k"}
            </Box>
          )}
          {insightsID &&
            insightsID?.indexOf(question.tweet_id) !== -1 &&
            response?.length !== 0 && (
              <Box>
                <FontAwesomeIcon
                  icon={faEye}
                  id="monitors-question-card-tweets-eye-fa-icon"
                />
                {monitorDataSource === "FACEBOOK" ||
                datasourcePostsType === "FACEBOOK"
                  ? numberFormatter(
                      response[insightsID.indexOf(question.tweet_id)]?.reach[0]
                        ?.value,
                    )
                  : numberFormatter(
                      response[insightsID.indexOf(question.tweet_id)]?.reach,
                    )}
              </Box>
            )}

          {(question?.total_engagement || question?.reach) && (
            <Divider orientation="vertical" className="footer-divider" />
          )}
          {showFlash && (
            <Box>
              <FontAwesomeIcon
                icon={faBolt}
                id="monitors-question-card-bolt-fa-icon"
              />
              {parseInt(question?.total_engagement)?.toLocaleString()}
            </Box>
          )}
          {showEarth && question?.reach && (
            <Box>
              <FontAwesomeIcon
                icon={faGlobeAsia}
                id="monitors-question-card-global-asia-fa-icon"
              />

              {parseInt(question?.reach)?.toLocaleString()}
            </Box>
          )}
          {insightsID &&
            insightsID?.indexOf(question.tweet_id) !== -1 &&
            response?.length !== 0 && (
              <Box>
                <FontAwesomeIcon
                  icon={faGlobeAsia}
                  id="monitors-question-card-global-asia-fa-icon"
                />
                {monitorDataSource === "FACEBOOK" ||
                datasourcePostsType === "FACEBOOK"
                  ? parseInt(
                      response[insightsID.indexOf(question.tweet_id)]
                        ?.impressions[0]?.value,
                    )?.toLocaleString()
                  : parseInt(
                      response[insightsID.indexOf(question.tweet_id)]
                        ?.impressions,
                    )}
              </Box>
            )}
          {localizedDate && (
            <Divider orientation="vertical" className="footer-divider" />
          )}

          <Box className="question-footer-date">
            <Box className="display-in-row">
              <Box className="post-date ltr-direction">
                {localizedDate}
                {localizedTime ? (
                  <Box className="post-time ltr-direction">{localizedTime}</Box>
                ) : null}
              </Box>
            </Box>
          </Box>
          {!hideChangeSentiment && !hideWhenRetweeted && (
            <Divider
              orientation="vertical"
              sx={{ ml: 1 }}
              className="footer-divider"
            />
          )}

          {/* Sentiment Btn__________________________________________________________________________ */}
          {!hideChangeSentiment && !hideWhenRetweeted && (
            <Tooltip
              title={CheckValueLocale("change_sentiment", "", {}, intl)}
              placement="top"
              arrow
            >
              <>
                <Button
                  id="monitor-question-card-btn"
                  aria-controls={open ? "fade-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className={`sentiment-btn ${
                    selectedSentiment.name ? selectedSentiment.name : "none"
                  }`}
                >
                  {selectedSentiment.icon ? (
                    selectedSentiment.icon
                  ) : (
                    <HelpOutlineIcon
                      className="smiliesStyle"
                      style={{ color: "#64748B" }}
                    />
                  )}
                  {selectedSentiment.name
                    ? CheckValueLocale(selectedSentiment.name, "", {}, intl)
                    : CheckValueLocale("none", "", {}, intl)}
                  <ArrowDropDownIcon className="icon-angle" />
                </Button>
              </>
            </Tooltip>
          )}

          {/* Sentiment Drop Down Menu __________________________________________________________________________ */}
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "monitor-question-card-btn",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {sentimentMenu.map((sentiment, i) => {
              return (
                <MenuItem
                  onClick={() =>
                    handleEditSentiment(
                      monitorDataSource === "NEWSBLOGS"
                        ? question.id
                        : question.tweet_id,
                      sentiment.label,
                      question.sentiment,
                    )
                  }
                  className={`${sentiment.name} ul-sentiment`}
                  id={`monitors-question-card-sentiment-${
                    sentiment?.name ?? "sentiment-name"
                  }-fa-icon`}
                >
                  {sentiment.icon}
                  {CheckValueLocale(sentiment.name, "", {}, intl)}
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
        {insightsID && insightsID?.indexOf(question.tweet_id) !== -1 && (
          <Box display="flex" component={"span"}>
            <Typography margin={0.5} variant={"caption"} color={"#757575"}>
              {CheckValueLocale("last_update", "", {}, intl)}
              {dates[insightsID.indexOf(question.tweet_id)]}
            </Typography>
            <Tooltip
              title={CheckValueLocale("update_insights", "", {}, intl)}
              placement="top"
              arrow
            >
              <Box component="span">
                <FontAwesomeIcon
                  icon={faSync}
                  className="more-insights"
                  onClick={() =>
                    getMoreInsights(
                      question,
                      response,
                      insightsID,
                      dates,
                      true,
                      undefined,
                      datasourcePostsType,
                    )
                  }
                  id="monitors-question-card-more-insights-fa-icon"
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box className="question-theme">
        {(question?.themes && question?.themes?.length > 0) ||
        activeTab === "comments_mentions" ||
        activeTab === "questions" ||
        activeTab === "engagements" ||
        activeTab === "customer_care" ? (
          <>
            <ThemesDropdown
              question={question}
              themesOptions={themesOptions}
              monitorDataSource={monitorDataSource}
            />
            <SubThemesDropdown
              question={question}
              subThemesOptions={subThemesOptions}
              monitorDataSource={monitorDataSource}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default CardFooter;
