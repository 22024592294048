import { Box } from "@mui/material";
import "./sideBarTabs.scss";
import NoDataSideBarTab from "./noDataSideBarTab";
import Skeleton from "@mui/material/Skeleton";
import { CheckValueLocale, isEmptyObj } from "utils/helpers";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { useIntl } from "react-intl";
import profileImg from "images/engagements-images/profileImg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import CardItem from "./cardItem";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import CompaniesController from "services/controllers/companiesController";
import EngagementsController from "services/controllers/engagementsController";

const counterArr = [1, 2, 3, 4];
const cardLoading = () => {
  return (
    <Box className="main-cards-loader">
      <Box className="cards-loader-header">
        <Skeleton
          animation="pulse"
          variant="circular"
          width={32}
          height={32}
          sx={{ bgcolor: "white" }}
        />
        <Box className="cards-loader-title">
          <Skeleton
            animation="pulse"
            variant="text"
            width={100}
            height={10}
            sx={{ bgcolor: "white" }}
          />
          <Skeleton
            animation="pulse"
            variant="text"
            width={50}
            height={10}
            sx={{ bgcolor: "white" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const TabContent = ({
  selectedCard,
  setSelectedCard,
  setCardState,
  cardState,
  response,
  setFilters,
  filters,
  applyFilters,
  allData,
  preLoader,
  setTotalInteractions,
  slaCounterData,
  setSlaCounterData,
  setCountLoading,
  totalInteractions,
  totalCount,
  setTotalCount,
  totalNewCount,
  totalActiveCount,
  totalCompletCount,
  setTotalNewCount,
  setTotalActiveCount,
  setTotalCompletCount,
  setLastOperation,
  setEngagementPostId,
  setTabValue,
  searchInput,
  setOpenSidebarToAssignTeam,
}) => {
  const intl = useIntl();
  const [itemsData, setItemsData] = useState([]);
  const [loading, setLoading] = useState(
    // if there is no monitors selectdcd from the filters, we don't need to show the loading
    allData?.monitors?.length ? true : false,
  );
  const [slasList, setSlasList] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);

  useEffect(() => {
    if (!allData?.monitors?.length) {
      setTotalInteractions(0);
      setLastOperation(0);
      setEngagementPostId(null);
      setItemsData([]);
      dispatch(clearSla());
      if (!window.location.href.includes("alert_id")) {
        setLoading(false);
        setCountLoading(false);
        setSlaCounterData({
          values: {
            all: 0,
            my_assigned: 0,
            overdue: 0,
            team_assigned: 0,
            unassigned: 0,
          },
          loading: false,
        });
        setTotalNewCount(0);
        setTotalActiveCount(0);
        setTotalCompletCount(0);
        setTotalCount(0);
      }
      setLoadMore(false);
    } else if (allData?.page_number == 1) {
      setLoading(true);
      setLoadMore(false);
      setItemsData([]);
      dispatch(clearSla());
      setSelectedCard({});
      setTotalInteractions(0);
      setLastOperation(0);
      setEngagementPostId(null);
      setCountLoading(true);
      setSlaCounterData({ ...slaCounterData, loading: true });
    }
  }, [allData]);

  // handle cards list data
  useEffect(() => {
    let slaSideData = { ...slaCounterData };
    if (allData?.monitors?.length || allData?.filters?.alert_interaction_id) {
      setLoadMore(true);
      if (allData?.page_number == 1 && isEmptyObj(response)) {
        //reset all data at page 1
        setLoading(true);
        setItemsData([]);
        dispatch(clearSla());
        setSelectedCard({});
        setTotalInteractions(0);
        setLastOperation(0);
        setEngagementPostId(null);
        setTotalCount(0);
        setTotalNewCount(0);
        setTotalActiveCount(0);
        setTotalCompletCount(0);
        setCountLoading(true);
        setSlaCounterData({
          loading: true,
          values: {
            all: 0,
            my_assigned: 0,
            overdue: 0,
            team_assigned: 0,
            unassigned: 0,
          },
        });
      } else if (allData?.page_number == 1 && !itemsData?.length) {
        setLoading(true);
      }
      switch (response?.eventName) {
        case "EngagementsProductPage":
          if (
            response?.eventData?.engagements_status_count
              ?.engagements_status_count
          ) {
            // set tabs count
            !totalCount
              ? setTotalCount(
                  response?.eventData?.engagements_status_count
                    ?.engagements_status_count?.all,
                )
              : setTotalCount(totalCount);
            !totalNewCount
              ? setTotalNewCount(
                  response?.eventData?.engagements_status_count
                    ?.engagements_status_count?.new,
                )
              : setTotalNewCount(totalNewCount);
            !totalActiveCount
              ? setTotalActiveCount(
                  response?.eventData?.engagements_status_count
                    ?.engagements_status_count?.active,
                )
              : setTotalActiveCount(totalActiveCount);
            !totalCompletCount
              ? setTotalCompletCount(
                  response?.eventData?.engagements_status_count
                    ?.engagements_status_count?.complete,
                )
              : setTotalCompletCount(totalCompletCount);
            // at case is_alert we make active tab is all
            if (
              response?.eventData?.is_alert &&
              window.location.href?.includes("alert_id")
            ) {
              let newValTab = 0;
              let newStatusVal = "all";
              // may be used later
              // if (
              //   response?.eventData?.engagements_status_count
              //     ?.engagements_status_count?.new
              // ) {
              //   newValTab = 1;
              //   newStatusVal = "new";
              // } else if (
              //   response?.eventData?.engagements_status_count
              //     ?.engagements_status_count?.active
              // ) {
              //   newValTab = 2;
              //   newStatusVal = "active";
              // } else if (
              //   response?.eventData?.engagements_status_count
              //     ?.engagements_status_count?.complete
              // ) {
              //   newValTab = 3;
              //   newStatusVal = "complete";
              // } else {
              //   newValTab = 0;
              //   newStatusVal = "all";
              // }

              setFilters({ ...filters, engagement_status: newStatusVal });

              setTabValue(newValTab);
            }
          }
          response?.eventData?.engagements_status_count?.total_interactions !=
            undefined && !totalInteractions
            ? setTotalInteractions(
                response?.eventData?.engagements_status_count
                  ?.total_interactions,
              )
            : setTotalInteractions(totalInteractions);

          response?.eventData?.last_operation != undefined
            ? setLastOperation(response?.eventData?.last_operation)
            : setLastOperation(0);
          response?.eventData?.engagement_post_id != undefined
            ? setEngagementPostId(response?.eventData?.engagement_post_id)
            : setEngagementPostId(null);
          let data = response?.eventData?.top_engagements
            ? response?.eventData?.top_engagements
            : [];
          // set sla tabs counter
          if (
            response?.eventData?.engagements_tabs_count &&
            filters.page == 1
          ) {
            slaSideData.values = {
              ...response?.eventData?.engagements_tabs_count,
            };
            setSlaCounterData({
              ...slaSideData,
            });
          }
          if (data?.length) {
            // every change of page we call getUsers ,getCompanySLAs and getAllCompanyTeams to update list of slas, users and teams
            let slaObj = { ...reduxSlaData };
            EngagementsController.getCompanySLAs().then((res) => {
              if (!res?.errorMsg) {
                let slasList = res?.data?.data?.map((item) => item?.attributes);
                setSlasList([...slasList]);
              }
            });
            CompaniesController.getAllCompanyTeams().then((res) => {
              if (!res?.errorMsg && res?.data?.teams?.length) {
                slaObj.teams = [...res?.data?.teams];
              } else {
                slaObj.teams = [];
              }
              dispatch(slaData(slaObj));
            });

            EngagementsController.getUsers(
              window.localStorage.getItem("engagementsProductId"),
            ).then((res) => {
              if (!res?.errorMsg && res?.data?.data?.length) {
                slaObj.users = [...res?.data?.data];
              } else {
                slaObj.users = [];
              }
              dispatch(slaData(slaObj));
            });

            setItemsData([
              ...itemsData,
              ...data?.map((item, i) => {
                slaObj[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ] = {
                  // set sla card data at redux
                  frtSec: item?.sla_info?.first_response_time,
                  ttcSec: item?.sla_info?.time_to_complete,
                  lastMsgDirecton: item?.message?.content?.direction,
                  nrtSec:
                    item?.message?.content?.direction == "inbound"
                      ? null
                      : item?.sla_info?.next_response_time,
                  nrtDate: item?.message?.content?.created_at,
                  tutSec: item?.sla_info?.unassigned_response_time,
                  slaId: item?.sla_info?.sla_id,
                  userId: item?.assigning_info?.user_id,
                  teamId: item?.assigning_info?.team_id,
                  reload: 1,
                  cardText: item?.tweet_text
                    ? item?.tweet_text
                    : item?.message?.content?.text,
                };
                return {
                  ...item,
                  data_source: item?.data_source?.split("_")[0],
                  isDm: item?.data_source?.includes("private"),
                  user_profile_image_url: item?.user_profile_image_url
                    ? item?.user_profile_image_url
                    : profileImg,
                };
              }),
            ]);
            dispatch(slaData(slaObj));
          } else {
            setItemsData([...itemsData]);
          }
          if (window.location.href.includes("alert_id")) {
            window.history.pushState({}, "", window.location.pathname);
          }
          // here we stop all loading
          setLoadMore(false);
          setLoading(false);
          setCountLoading(false);
          slaSideData.loading = false;
          setSlaCounterData({ ...slaSideData });

          break;
        default:
          break;
      }
    }
  }, [response]);

  useEffect(() => {
    if (cardState?.changeStateDone) {
      if (!cardState?.notClose) {
        setSelectedCard({});
      }
      if (cardState?.activeTab == "all") {
        let cardObject = {
          ...cardState?.cardData,
          status: cardState?.changeStateTo,
          system_replies: {
            ...cardState?.cardData?.system_replies,
            status: cardState?.changeStateTo,
          },
        };
        const itemElement = document.getElementById(
          `card-engag-state-${cardState?.cardIndex}`,
        );

        if (itemElement) {
          itemElement.classList.add("card-state-animation");
          setTimeout(() => {
            itemsData[cardState?.cardIndex] = { ...cardObject };
            setItemsData([...itemsData]);
          }, 300);
          setTimeout(() => {
            itemElement.classList.remove("card-state-animation");
          }, 600);
        }
      } else {
        itemsData?.splice(cardState?.cardIndex, 1);
        setItemsData([...itemsData]);
      }
      if (cardState?.cardInitialState == "complete") {
        setTotalCompletCount(+totalCompletCount - 1);
      } else if (
        cardState?.cardInitialState == "active" ||
        cardState?.cardInitialState == "reopen"
      ) {
        setTotalActiveCount(+totalActiveCount - 1);
      } else {
        setTotalNewCount(+totalNewCount - 1);
      }

      if (cardState?.changeStateTo == "complete") {
        setTotalCompletCount(+totalCompletCount + 1);
      } else {
        setTotalActiveCount(+totalActiveCount + 1);
      }
      setCardState({ ...cardState, changeStateDone: false });
    }
  }, [cardState]);

  // Here we handle second call of applyFilters and this for performance with back-end
  useEffect(() => {
    if (
      !loading &&
      !loadMore &&
      itemsData?.length &&
      itemsData?.length < totalInteractions
    ) {
      applyFilters(false);
    }
  }, [itemsData, totalInteractions, loading, loadMore]);

  // Here we handle first call of applyFilters and this for get cards data
  const handleScroll = (event) => {
    const target = event.target;
    if (
      Math.trunc(target.scrollHeight) -
        Math.trunc(target.scrollTop) -
        Math.trunc(target.clientHeight) <=
      4
    ) {
      if (
        !loading &&
        !loadMore &&
        itemsData?.length &&
        itemsData?.length < totalInteractions
      ) {
        setLoadMore(true);
        filters.page = filters.page + 1;
        setFilters({ ...filters });
        applyFilters();
      }
    }
  };
  return (
    <Box
      className="tab-content-wrapper"
      id="handle-pagination-scroll"
      onScroll={handleScroll}
    >
      {loading ? (
        counterArr?.map((i) => cardLoading())
      ) : !itemsData?.length ? (
        <NoDataSideBarTab filters={filters} />
      ) : (
        <>
          {itemsData?.map((item, index) => (
            <CardItem
              cardState={cardState}
              setCardState={setCardState}
              item={item}
              setSelectedCard={setSelectedCard}
              cardText={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.cardText
              }
              lastMsgDirecton={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.lastMsgDirecton
              }
              reload={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.reload
              }
              frtSec={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.frtSec
              }
              ttcSec={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.ttcSec
              }
              nrtSec={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.nrtSec
              }
              nrtDate={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.nrtDate
              }
              tutSec={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.tutSec
              }
              slaId={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.slaId
              }
              userId={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.userId
              }
              teamId={
                reduxSlaData[
                  `${item?.tweet_id ? item?.tweet_id : item?.id}-${
                    item?.tracker_id
                  }`
                ]?.teamId
              }
              teams={reduxSlaData?.teams}
              users={reduxSlaData?.users}
              slasList={slasList}
              selectedCard={selectedCard}
              allData={allData}
              index={index}
              zIndex={itemsData?.length - index}
              morThanOneItem={itemsData?.length > 1}
              setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
            />
          ))}
          {loadMore ? (
            <Box className="pagination-loader">
              {CheckValueLocale("loading", "", {}, intl)}
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};
export default TabContent;
