import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { useDeleteChannel } from "../hooks/useDeleteChannel";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

export const DeleteChannelModal = ({
  open,
  close,
  accountName,
  channelId,
  showSnackBar,
}) => {
  const intl = useIntl();

  const { mutate: deleteChannel, isPending: isDeletePending } =
    useDeleteChannel();
  const handleDeleteChannel = () => {
    deleteChannel(
      { channelId },
      {
        onSuccess: () => {
          close();
          showSnackBar("delete", "success");
        },
        onError: () => {
          showSnackBar("delete", "warning");
        },
      },
    );
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
    >
      <Box className="pop-up-shared-content-ca actions-buttons-shared">
        <DialogTitle id="alert-dialog-title">
          {CheckValueLocale("deleting_manage_title", " ", {}, intl)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className="shared-dialog-body">
              <p>
                {`${CheckValueLocale("delete_manage_body", "", { accountName: accountName }, intl)}`}
              </p>
              <h2 className="sub-text-desc">
                {CheckValueLocale("deleting_msg", "", {}, intl)}
              </h2>
            </Box>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <LucButton
            onClick={close}
            id="pop-up-left-btn"
            variant="flat"
            type="secondary"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>

          <LucButton
            disabled={isDeletePending}
            onClick={handleDeleteChannel}
            id="pop-up-right-btn"
            variant="filled"
            type="red"
            className="popup__rightbtn"
            loading={isDeletePending}
          >
            {CheckValueLocale("delete_confirm", "", {}, intl)}
          </LucButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
