import { useWidgetName } from "../../../hooks/useWidgetName";

export const newsBlogsRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  switch (response?.eventName) {
    case "Talkwalker__EngagementsPage__reach_funnel":
      handleWidgetResponse(
        "posts_reach",
        response?.monitor_id,
        "TALKWALKER",
        response?.eventData,
      );
      break;

    default:
      if (stateKey)
        handleWidgetResponse(
          stateKey,
          response?.monitor_id,
          "TALKWALKER",
          response?.eventData,
        );
      break;
  }
};
