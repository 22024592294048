import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { Menu, IconButton, Box, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faEdit,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { faRepeatAlt } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { MoreHoriz, SyncDisabled } from "@mui/icons-material";
import { LucidyaApisContext } from "../lucidyaApisContext";
import DisConnect from "images/shared-images/dis-connect.svg";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = ({ item }) => {
  const intl = useIntl();
  const {
    handleCreateApiDialog,
    handleApiActionsDialog,
    setApiDialogType,
    setApiDialogItem,
  } = useContext(LucidyaApisContext);
  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const open = Boolean(stats?.anchorEl);

  const handleClick = (event) => {
    setStats({ anchorEl: event?.currentTarget });
  };
  const handleClose = () => {
    setStats({ anchorEl: null });
  };
  window.addEventListener("scroll", (event) => {
    if (open) {
      handleClose();
    }
  });

  const handleEditApiKey = () => {
    setApiDialogItem(item);
    setApiDialogType("editApiKey");
    handleCreateApiDialog();
  };

  const handleActionApiKey = (actionName) => {
    setApiDialogItem(item);
    setApiDialogType(actionName);
    handleApiActionsDialog();
  };

  return (
    <Box className="lucidya-apis-menu-wrapper">
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          aria-label="more"
          id="settings-dropdown-lucidya-apis-long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: intl?.locale == "ar" ? "left" : "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: intl?.locale == "ar" ? "left" : "right",
        }}
        anchorEl={stats?.anchorEl}
        open={open}
        onClose={handleClose}
        className="lucidya-apis-list"
      >
        {handlelUserRoles("PUBLIC_API", "MANAGE_PUBLIC_API") &&
          (item?.attributes?.is_valid ? (
            <MenuItem
              onClick={() => handleActionApiKey("inActivateApiKey")}
              disableRipple
              id={`api-list-options-de-activate-${item?.id ?? "api-date-id"}`}
            >
              <img src={DisConnect} />
              {CheckValueLocale("de_activate_api", "", {}, intl)}
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => handleActionApiKey("activateApiKey")}
              disableRipple
              id={`api-list-options-activate-${item?.id ?? "api-date-id"}`}
            >
              <InsertLinkIcon />
              {CheckValueLocale("activate_api", "", {}, intl)}
            </MenuItem>
          ))}

        {handlelUserRoles("PUBLIC_API", "REGENERATE_TOKEN") && (
          <MenuItem
            onClick={() => handleActionApiKey("regenerateApiKey")}
            disableRipple
            disabled={!item?.attributes?.is_valid}
            id={`api-list-options-Regenerate-${item?.id ?? "api-date-id"}`}
          >
            <FontAwesomeIcon icon={faRepeatAlt} />
            {CheckValueLocale("regenerate_new_key", "", {}, intl)}
          </MenuItem>
        )}
        {handlelUserRoles("PUBLIC_API", "EDIT_TOKEN") && (
          <MenuItem
            onClick={handleEditApiKey}
            disableRipple
            id={`api-list-options-edit-${item?.id ?? "api-date-id"}`}
          >
            <FontAwesomeIcon icon={faEdit} />
            {CheckValueLocale("edit", "", {}, intl)}
          </MenuItem>
        )}
        {handlelUserRoles("PUBLIC_API", "DESTROY_TOKEN") && (
          <MenuItem
            onClick={() => handleActionApiKey("deleteApiKey")}
            disableRipple
            id={`api-list-options-delete-${item?.id ?? "api-date-id"}`}
          >
            <FontAwesomeIcon icon={faTrash} />
            {CheckValueLocale("delete", "", {}, intl)}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
export default OptionsDropDown;
