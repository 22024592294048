import React, { useEffect, useState } from "react";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";

const CustomDashboardStackedLine = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);
  const [dataPiechart, setDataPieChart] = useState([]);
  const [labels, setLabels] = useState([]);
  const [labelColors, setLabelColors] = useState([]);
  const [widgetFlags, setWidgetFlags] = useState({
    hideLegend: true,
  });

  const labelsColors = {
    positive: "#89BB2A",
    negative: "#E50035",
    neutral: "#F9A700",

    reach: "#2AAAE2",
    page_reach_over_time: "#2AAAE2",
    impression: "#80868C",
    impressions: "#80868C",
    page_impressions_over_time: "#80868C",
  };

  const handleLegendsColors = (legends) => {
    let labels = [];
    let colors = [];
    legends?.map((legend) => {
      colors?.push(labelsColors?.[legend?.name]);
      labels?.push(legend?.name);
    });
    setLabelColors(colors);
    setLabels(labels);
  };

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "comments_sentiment_analysis":
        case "sentiment_analysis":
          setDataPieChart(dataState?.sentiment_piechart);
          if (dataState?.sentiment_overtime) {
            let negativeArray = [];
            let positiveArray = [];
            let neutralArray = [];
            Object.entries(dataState?.sentiment_overtime).forEach(
              ([key, value]) => {
                switch (key) {
                  case "negative":
                    value.map((i) => {
                      return negativeArray.push(i);
                    });
                    break;
                  case "positive":
                    value.map((i) => {
                      return positiveArray.push(i);
                    });
                    break;
                  case "neutral":
                    value.map((i) => {
                      return neutralArray.push(i);
                    });
                    break;
                  default:
                    break;
                }
              },
            );
            setData({
              negative: negativeArray,
              positive: positiveArray,
              neutral: neutralArray,
            });
          } else setData([]);
          setWidgetFlags({
            isSentimentAnalysisWidget: true,
            isSentimentAnalysisShowCharts: true,
            hideLegend: true,
            inspectorName: "sentiment_overtime",
          });
          break;

        case "posts_reach_impressions":
          setData(dataState?.reach_funnel_overtime);
          setDataPieChart(dataState?.reach_funnel_legends);
          handleLegendsColors(dataState?.reach_funnel_legends);
          setWidgetFlags({
            isPostsReachImpression: true,
            hideLegend: true,
          });
          break;

        case "page_reach_impressions":
          if (
            dataState?.page_reach_impressions_overtime
              ?.page_impressions_over_time !== undefined
          )
            setData({
              reach:
                dataState?.page_reach_impressions_overtime
                  ?.page_impressions_over_time,
              impression:
                dataState?.page_reach_impressions_overtime
                  ?.page_reach_over_time,
            });
          else setData(dataState?.page_reach_impressions_overtime);
          setDataPieChart(dataState?.total_page_reach_impressions);
          handleLegendsColors(dataState?.total_page_reach_impressions);
          setWidgetFlags({
            isPostsReachImpression: true,
            hideLegend: true,
          });
          break;

        default:
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  return (
    <StackedLineWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={titleTooltip || ""}
      data={data || []}
      showGroupBy={true}
      areaStyle={true}
      toolTipLabel={"toolTipLabel"}
      showAI
      showDownloadIcon
      bigImg
      isSwitchCase
      showScales
      isSentimentAnalysisWidget={widgetFlags?.isSentimentAnalysisWidget}
      isSentimentAnalysisShowCharts={widgetFlags?.isSentimentAnalysisShowCharts}
      isPostsReachImpression={widgetFlags?.isPostsReachImpression}
      dataPiechart={dataPiechart || []}
      hideLegend={widgetFlags?.hideLegend}
      labels={widgetFlags?.isSentimentAnalysisWidget ? undefined : labels || []}
      labelColors={
        widgetFlags?.isSentimentAnalysisWidget ? undefined : labelColors || []
      }
      colors={
        widgetFlags?.isSentimentAnalysisWidget ? undefined : labelColors || []
      }
      stackedLineWidgetPreLoaderDataSources={
        socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
          monitorId
        ]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ] || []
      }
      preLoaderTrackerDataSources={[monitorId] || []}
      isCustomDashboard
      monitorData={{
        name:
          dashboardInfo?.product === "cxm"
            ? widget?.account_identifier
            : widget?.monitor_name,
        type:
          dashboardInfo?.product === "cxm"
            ? widget?.data_category
            : widget?.monitor_type?.name,
        datasource: widget?.is_dm
          ? `${widget?.data_source}_private`
          : widget?.data_source,
      }}
    />
  );
};

export default CustomDashboardStackedLine;
