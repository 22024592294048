import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import DisConnect from "images/shared-images/dis-connect.svg";
import Repeat from "images/shared-images/repeat.svg";
import PopupModal from "components/popupModal";
import AuthController from "services/controllers/authController";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const {
    accountIdentifier,
    accountOwner,
    accountId,
    dataSource,
    monitorsCount,
    getUsersAccounts,
    setSnackBar,
    handleAccountAuth,
  } = props;
  const intl = useIntl();

  const [showUnAuthModal, setShowUnAuthModal] = useState(false);
  const [stats, setStats] = useState({ anchorEl: null });
  const open = Boolean(stats.anchorEl);

  const handleOpenMenu = (e) => setStats({ anchorEl: e.currentTarget });
  const handleCloseMenu = () => setStats({ anchorEl: null });

  window.addEventListener("scroll", (event) => {
    if (stats?.anchorEl) setStats({ anchorEl: null });
  });

  const openUnAuthModal = () => setShowUnAuthModal(true);
  const closeUnauthModal = () => setShowUnAuthModal(false);

  const handleUnauthAccount = () => {
    AuthController.unAuthAccount(dataSource?.toLowerCase(), accountId).then(
      (result) => {
        if (result?.errorMsg) {
          setSnackBar({
            open: true,
            severity: "error",
            title: CheckValueLocale(
              "unauth_user_account_error_title",
              "",
              {},
              intl,
            ),
            message: CheckValueLocale(
              "unauth_user_account_error_msg",
              "",
              {},
              intl,
            ),
          });
        } else {
          setSnackBar({
            open: true,
            severity: "success",
            title: "",
            message: CheckValueLocale(
              "unauth_user_account_success_msg",
              "",
              {},
              intl,
            ),
          });
          setTimeout(() => getUsersAccounts(1), 1000);
        }
        setShowUnAuthModal(false);
        handleCloseMenu();
      },
    );
  };

  return (
    <Box>
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <IconButton
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          id="settings-users-accounts-options-btn"
          aria-label="more"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleOpenMenu}
        >
          <FontAwesomeIcon
            size="small"
            icon={faEllipsis}
            className="ellipsis-icon"
          />
        </IconButton>
      </CustomizedTooltip>
      <Menu
        id="users-account-options-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={
          intl.locale !== "ar" && {
            vertical: "bottom",
            horizontal: "right",
          }
        }
        transformOrigin={
          intl.locale !== "ar" && {
            vertical: "top",
            horizontal: "right",
          }
        }
        className="accounts-action-dropdown"
      >
        <MenuItem
          id="users-accounts-re-authenticate-btn"
          className="auth__option"
          disableRipple
          onClick={() => handleAccountAuth(dataSource)}
          disabled={!accountOwner}
        >
          <img src={Repeat} alt="" />
          {CheckValueLocale("reauthenticate", "", {}, intl)}
        </MenuItem>
        <MenuItem
          id="users-accounts-authenticate-btn"
          className="auth__option"
          disableRipple
          onClick={openUnAuthModal}
          disabled={!accountOwner}
        >
          <img src={DisConnect} alt="" />
          {CheckValueLocale("unauthenticate", "", {}, intl)}
        </MenuItem>
      </Menu>
      {showUnAuthModal === true && (
        <PopupModal
          title={CheckValueLocale("unauthenticate_title_popup", "", {}, intl)}
          body={CheckValueLocale(
            "users_accounts_unauthenticate_popup_text",
            "",
            {
              break: <br />,
              accountIdentifier: accountIdentifier,
              accountUsedIn: monitorsCount || 0,
            },
            intl,
          )}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("unauthenticate", "", {}, intl)}
          open={true}
          warning={true}
          close={closeUnauthModal}
          accept={handleUnauthAccount}
          modalStyle={"unauth-popup-content-box"}
          addClasses={"unauth-popup-actions-box"}
        />
      )}
    </Box>
  );
};
export default OptionsDropDown;
