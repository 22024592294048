import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import MonitorsController from "services/controllers/monitorsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import {
  convertTwitterToXPlateform,
  deleteCookie,
  isEmptyObj,
} from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import moment from "moment";
import LiveDashboardController from "services/controllers/liveDashboardController";
import UserAccount from "services/controllers/userAccountController";
import LanguageContext from "context/Context";
import { data } from "pages/Monitors/Components/sidebar/dataSourceTabs";
import NameKeyWidget from "pages/createLiveDashboard/shard/nameKeyWidget";
import { arrayMoveImmutable } from "array-move";

const DashboardFunction = () => {
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const urlParams = useParams();
  const history = useHistory();
  const [liveDashboardName, setLiveDashboardName] = useState("");
  const [dataSources, setDataSources] = useState([]);
  const [response, setResponse] = useState([]);
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [resetAllFields, setResetAllFields] = useState(0);
  const [singleTweetAnalysis, setSingleTweetAnalysis] = useState(false);

  const [lastUpdated, setLastUpdated] = useState(moment());
  const [monitorLiveDashboardDetails, setMonitorLiveDashboardDetails] =
    useState(null);
  const [
    loadingMonitorLiveDashboardDetails,
    setLoadingMonitorLiveDashboardDetails,
  ] = useState(true);
  const [selectedWidgets, setSelectedWidgets] = useState(null);
  const [countWidgets, setCountWidgets] = useState(null);
  const [dataSourceCounter, setDataSourceCounter] = useState(0);
  const [sectionCounter, setSectionCounter] = useState(0);
  const [selectedWidgetsName, setSelectedWidgetsName] = useState(null);
  const [trackerWidgetsName, setTrackerWidgetsName] = useState([]);
  const [xPlateformWidgetTypes, setXPlateformWidgetTypes] = useState(null);
  const [facebookWidgetTypes, setFacebookWidgetTypes] = useState(null);
  const [instagramWidgetTypes, setInstagramWidgetTypes] = useState(null);
  const [newsBlogsWidgetTypes, setNewsBlogsWidgetTypes] = useState(null);
  const [orderFlag, setOrderFlag] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [snackBarHeading, setSnackBarHeading] = useState("");
  const [refreshCount, setRefreshCount] = useState(0);
  const languageContext = useContext(LanguageContext);
  const [showEditBtn, setShowEditBtn] = useState(true);
  const [stats, setStats] = useState({
    lang: languageContext.lang,
  });
  const monitorId = urlParams?.monitorId;
  const monitorType = urlParams?.monitorType;
  const userDashboardId = urlParams?.userDashboardId;

  let isSafari =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  const handleSwitchLang = () => {
    let lang = "";
    if (stats.lang === "ar") {
      lang = "en";
    } else {
      lang = "ar";
    }
    UserAccount.setUserLang(lang).then((res) => {
      if (res?.errorMsg?.response?.status === 401) {
        localStorage.clear();
        deleteCookie("email");
        window.location.href = "/login";
      }
      if (res?.data?.status == 200) {
        setStats({ ...stats, lang: lang });
        languageContext.switchLang(lang);
      }
    });
  };
  const handleSwitchLangSafari = async () => {
    let lang = "";
    await UserAccount.setUserLang(lang).then((res) => {
      if (stats.lang === "ar") {
        lang = "en";
      } else {
        lang = "ar";
      }
      if (res?.data?.status == 200) {
        setStats({ ...stats, lang: lang });
        languageContext.switchLang(lang);
      }
    });
    window.location.reload();
  };
  let changeLang = isSafari ? handleSwitchLangSafari : handleSwitchLang;

  const getSourceName = (id) =>
    dataSources?.find((singleDataSource) => singleDataSource?.id == id)?.source;

  const getSourceId = (name) =>
    dataSources?.find((singleDataSource) => singleDataSource?.source == name)
      ?.id;

  const unixDate = {
    start: startDate,
    end: endDate,
  };

  const getSocialIcon = (type) => {
    const SocialIcons = {
      FACEBOOK: <FontAwesomeIcon className="facebook" icon={faFacebook} />,
      TWITTER: (
        <img src={xPlatform} alt="x-platform-logo" className="x-platform" />
      ),
      INSTAGRAM: <FontAwesomeIcon className="instagram" icon={faInstagram} />,
      TALKWALKER: <FontAwesomeIcon className="news-blogs" icon={faNewspaper} />,
    };
    return SocialIcons[type];
  };

  const getSocialName = (type) => {
    const SocialName = {
      FACEBOOK: "facebook",
      TWITTER: "twitter",
      INSTAGRAM: "instagram",
      TALKWALKER: "nb",
    };
    return SocialName[type];
  };

  const getSocialMediaMonitorData = (
    startDate,
    endDate,
    section,
    dataSource,
  ) => {
    setResponse([]); //empty reponse wuth each new call
    setTrackerWidgetsName([]);
    MonitorsController.getSocialMediaMonitorData(
      getSocialName(getSourceName(dataSource)),
      monitorId,
      window.localStorage.sm_id,
      section,
      startDate,
      endDate,
      undefined,
      1,
    ).then((data) => {
      setSingleTweetAnalysis(data?.data?.single_tweet_analysis_enabled);
      if (!isEmptyObj(data?.data || {})) {
        setPreLoaderTrackerDataSources([parseInt(monitorId)]);
        // connectWithRabbitMQ(
        //   mqttConnectionClosed,
        //   clientMQTT,
        //   data?.data,
        //   setResponse,
        // );
        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };

  const multiRequestHandler = () => {
    if (dataSourceCounter < countWidgets?.length) {
      if (sectionCounter < countWidgets[dataSourceCounter]) {
        const sectionName =
          selectedWidgets[dataSourceCounter]?.section?.[sectionCounter];
        const dataSourceId = selectedWidgets[dataSourceCounter]?.data_source_id;
        setSelectedWidgetsName(
          selectedWidgets[dataSourceCounter]?.widgets
            ?.filter((widget) => sectionName === widget?.section)
            ?.map?.((widget) => widget?.name),
        );
        getSocialMediaMonitorData(
          startDate,
          endDate,
          sectionName,
          dataSourceId,
        );
      } else {
        setDataSourceCounter((prev) => prev + 1);
        setSectionCounter(0);
      }
    }
  };

  const handleResponseFunc = () => {
    if (selectedWidgetsName?.includes(response?.eventName)) {
      setTrackerWidgetsName((prevNames) => [...prevNames, response?.eventName]);
    }
  };

  const applyFilter = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    resetCountHandler();
  };

  const refreshRequestHandler = () => {
    resetCountHandler();
    setRefreshCount((prevCount) => prevCount + 1);
  };

  const resetCountHandler = () => {
    setResetAllFields((prev) => prev + 1);
    setLastUpdated(moment());
    setDataSourceCounter(0);
    setSectionCounter(0);
  };

  const removeRepeatedItems = (arr) =>
    arr.filter((widget, index) => arr.indexOf(widget) === index);

  const addEngagementSection = (arr) =>
    arr?.includes("engagements") ? arr : [...arr, "engagements"];

  const addDashboardStatisticWidgets = (widgets, dataSourceName) => {
    return [
      ...widgets,
      {
        id: "-1",
        name: dataSourceName + "__EngagementsPage__dashboard_data",
        section: "engagements",
        order: "-1",
      },
    ];
  };

  const sortWidgets = (oldIndex, newIndex, widgets, setWidgetTypes) => {
    const dashboardDataWidget = widgets?.find((widget) => widget?.id === "-1");
    const widgetsWithoutDashboardDataWidget = widgets?.filter(
      (widget) => widget?.id !== "-1",
    );
    const newWidgetOrderArr = arrayMoveImmutable(
      widgetsWithoutDashboardDataWidget,
      oldIndex,
      newIndex,
    )?.map((widget, i) => ({ ...widget, order: i + 1 }));
    setOrderFlag(true);
    setWidgetTypes([...newWidgetOrderArr, dashboardDataWidget]);
  };

  const capitalizeFirstLetter = (str) =>
    str?.[0]?.toUpperCase() + str?.slice(1);

  const handleLiveDashboardDetails = () => {
    const dataSourcesListArr =
      monitorLiveDashboardDetails?.monitor_user_live_dashboard
        ?.map((dataSource) => dataSource?.data_sources[0])
        .map((dataSource) => ({
          data_source_id: dataSource?.data_source_id,
          widgets: addDashboardStatisticWidgets(
            dataSource?.widgets,
            capitalizeFirstLetter(
              getSourceName(dataSource?.data_source_id)?.toLowerCase(),
            ),
          ),
          section: addEngagementSection(
            removeRepeatedItems(
              dataSource?.widgets?.map((widget) => widget?.section),
            ),
          ),
          count: addEngagementSection(
            removeRepeatedItems(
              dataSource?.widgets?.map((widget) => widget?.section),
            ),
          )?.length,
        }));
    setLiveDashboardName(
      monitorLiveDashboardDetails?.monitor_user_live_dashboard?.[0]
        ?.dashboard_name,
    );
    setSelectedWidgets(dataSourcesListArr);
    setCountWidgets(dataSourcesListArr?.map((dataSource) => dataSource?.count));
    setShowEditBtn(
      monitorLiveDashboardDetails?.monitor_user_live_dashboard?.[0]?.user_id ==
        localStorage.getItem("user_id"),
    );
  };

  const getMonitorLiveDashboardDetails = () => {
    setLoadingMonitorLiveDashboardDetails(true);
    LiveDashboardController.getMonitorLiveDashboardDetails({
      monitor_id: monitorId,
      dashboard_user_id: userDashboardId,
    }).then((data) => {
      if (!data?.data?.monitor_user_live_dashboard?.length) {
        history.push({
          pathname: `/social/monitor_list`,
        });
      }
      setMonitorLiveDashboardDetails(data?.data);
      setLoadingMonitorLiveDashboardDetails(false);
    });
  };

  const editLiveDashboardHandler = () => {
    setIsActionLoading(true);
    LiveDashboardController.editLiveDashboard(getQueryData()).then((res) =>
      handleResponse(res),
    );
  };

  const getQueryData = () => {
    let dataSourceWidgets = [];
    if (xPlateformWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("TWITTER")?.toString(),
          widgets: xPlateformWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    if (facebookWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("FACEBOOK")?.toString(),
          widgets: facebookWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    if (instagramWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("INSTAGRAM")?.toString(),
          widgets: instagramWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    if (newsBlogsWidgetTypes) {
      dataSourceWidgets = [
        ...dataSourceWidgets,
        {
          data_source_id: getSourceId("TALKWALKER")?.toString(),
          widgets: newsBlogsWidgetTypes
            ?.filter((widget) => widget?.id !== "-1")
            ?.map((widget) => ({
              widget_id: widget?.id,
              order: widget?.order,
            })),
        },
      ];
    }
    return {
      monitor_user_live_dashboard: {
        monitor_model_id: urlParams?.monitorId,
        dashboard_name: liveDashboardName,
        data_sources: dataSourceWidgets,
      },
    };
  };
  const handleResponse = (res) => {
    if (res?.status_code === 201 || res?.status_code === 200) {
      window.location.reload();
    } else {
      setSuccess(false);
      setSnackBarHeading("update_not");
      setIsActionLoading(false);
    }
  };

  const getWidgetsSoretedArr = (WidgetsArr) =>
    WidgetsArr?.sort(
      (first, sec) => parseFloat(first.order) - parseFloat(sec.order),
    );

  const redirectToMonitor = (urlParams, selectedWidgets) => {
    const monitor_id = urlParams?.monitorId;
    const monitorDataSources = getSourceName(
      selectedWidgets?.[0]?.data_source_id,
    );
    const dataSourceName = convertTwitterToXPlateform(monitorDataSources);
    data.forEach((i) => {
      //'data' here is static array of object for all avaliable tabs for each datasource as per the its monitorType
      if (Object.keys(i)[0] == `${monitorDataSources}_${monitorType}`) {
        history.push({
          pathname: `/monitor/${monitorType}/${
            dataSourceName === "TALKWALKER" || dataSourceName === "NEWSBLOGS"
              ? "NEWSBLOGS"
              : dataSourceName
          }/${Object.values(i)[0][0]?.analytics[0]?.tabName}/${monitor_id}`,
          state: history.location.state,
          tabs: `${monitorDataSources}_${monitorType}`,
        });
      }
    });
  };

  const getNameKeyWidget = (name) => {
    const widgetNameArr = name?.split("__");
    const widgetName = widgetNameArr?.[widgetNameArr?.length - 1];
    const widgetNameKey = NameKeyWidget(name, monitorType);
    return widgetNameKey || widgetName;
  };

  return {
    startDate,
    endDate,
    unixDate,
    selectedWidgetsName,
    trackerWidgetsName,
    setSectionCounter,
    response,
    countWidgets,
    selectedWidgets,
    sectionCounter,
    monitorLiveDashboardDetails,
    loadingMonitorLiveDashboardDetails,
    lastUpdated,
    getSocialIcon,
    resetAllFields,
    preLoaderTrackerDataSources,
    singleTweetAnalysis,
    multiRequestHandler,
    handleResponseFunc,
    applyFilter,
    handleLiveDashboardDetails,
    getMonitorLiveDashboardDetails,
    refreshRequestHandler,
    xPlateformWidgetTypes,
    setXPlateformWidgetTypes,
    facebookWidgetTypes,
    setFacebookWidgetTypes,
    instagramWidgetTypes,
    setInstagramWidgetTypes,
    newsBlogsWidgetTypes,
    setNewsBlogsWidgetTypes,
    orderFlag,
    setOrderFlag,
    sortWidgets,
    editLiveDashboardHandler,
    success,
    setSuccess,
    snackBarHeading,
    isActionLoading,
    getWidgetsSoretedArr,
    refreshCount,
    dataSources,
    setDataSources,
    getSourceName,
    liveDashboardName,
    changeLang,
    redirectToMonitor,
    getNameKeyWidget,
    showEditBtn,
  };
};

export default DashboardFunction;
