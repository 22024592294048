import { useIntl } from "react-intl";
import { Button, DialogActions } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const AlertActions = ({
  handleCloseForm,
  checkDisableCreateAlert,
  handleFormSubmit,
  isCreateAlert,
}) => {
  const intl = useIntl();

  return (
    <DialogActions>
      <LucButton
        variant="flat"
        type="secondary"
        onClick={handleCloseForm}
        id={`${isCreateAlert ? "alert-cancel-create-btn" : "alert-cancel-edit-btn"}`}
      >
        {CheckValueLocale("cancel", "", {}, intl)}
      </LucButton>
      <LucButton
        disabled={checkDisableCreateAlert()}
        onClick={handleFormSubmit}
        id={`${isCreateAlert ? "alert-form-create-save-changes" : "alert-form-edit-save-changes"}`}
      >
        {isCreateAlert
          ? CheckValueLocale("create_alert_btn", "", {}, intl)
          : CheckValueLocale("alerts_save", "", {}, intl)}
      </LucButton>
    </DialogActions>
  );
};
export default AlertActions;
