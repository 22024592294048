import { Box, OutlinedInput } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import PopupModal from "components/popupModal";

const LiveDashBoardFields = ({
  liveDashboardName,
  isEditLiveDashboard,
  deleteLiveDashboard,
  openPopupDelete,
  setOpenPopupDelete,
  isDeleteLoading,
  handleInputChange,
  liveDashboardNameError,
}) => {
  const intl = useIntl();

  return (
    <Box className="dashboard-group-wrapper">
      <Box className="create-live-dashboard-group">
        <Box component="p" className="create-live-dashboard-label">
          {CheckValueLocale("dashboard_name", "", {}, intl)}
          <Box component="span" className="dashboard-star">
            *
          </Box>
        </Box>
        <OutlinedInput
          variant="outlined"
          className="social-media-field"
          value={liveDashboardName}
          onChange={(e) => handleInputChange(e)}
        />
        <Box component="p" className="create-live-dashboard-error">
          {CheckValueLocale(liveDashboardNameError, "", {}, intl)}
        </Box>
      </Box>
      {isEditLiveDashboard ? (
        <LucButton
          variant="outline"
          type="red"
          startIcon={<FontAwesomeIcon icon={faTrashAlt} />}
          onClick={() => setOpenPopupDelete(true)}
          id="delete-live-dashboard-btn"
        >
          {CheckValueLocale("delete_dashboard", "", {}, intl)}
        </LucButton>
      ) : null}
      {openPopupDelete ? (
        <PopupModal
          title={CheckValueLocale("delete_live_dashboard", "", {}, intl)}
          body={CheckValueLocale("delete_live_dashboard_text", "", {}, intl)}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("delete", "", {}, intl)}
          disabled={isDeleteLoading}
          isRightBtnLoading={isDeleteLoading}
          open={openPopupDelete || false}
          close={() => setOpenPopupDelete(null)}
          accept={deleteLiveDashboard}
          classeName={"popupModal-delet"}
          warning
        />
      ) : null}
    </Box>
  );
};

export default LiveDashBoardFields;
