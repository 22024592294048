import React from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import EmptyDataShared from "./components/emptyDataShared";

const NoDataFoundProducts = (props) => {
  const intl = useIntl();
  const {
    image,
    title,
    subTitle,
    description,
    showApplyFilterMsg,
    linkOne,
    linkOneHref,
    orCase,
    linkTwo,
    needsAttentionUserName,
  } = props;

  return (
    <Box className="chart-no-data">
      <EmptyDataShared
        image={image}
        title={title}
        subTitle={subTitle}
        description={description}
        linkOne={linkOne}
        linkOneHref={linkOneHref}
        orCase={orCase}
        linkTwo={linkTwo}
        showApplyFilterMsg={showApplyFilterMsg}
        needsAttentionUserName={needsAttentionUserName}
      />
    </Box>
  );
};

export default NoDataFoundProducts;
