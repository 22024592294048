import { useEffect, useState } from "react";
import { Button, Menu, MenuItem, Box, Chip } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
//Locals
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const ChannelAnalyticsMenu = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const [anchorCA, setAnchorCA] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const openCA = Boolean(anchorCA);

  const handleClickChannelAnalytics = (event) => {
    setAnchorCA(event.currentTarget);
  };
  const handleCloseCA = () => {
    setAnchorCA(null);
  };

  const subMenuPaths = [
    "/insights/main",
    "/interactions",
    "/manage-channels",
    "/insights",
  ];
  useEffect(() => {
    setIsActive(subMenuPaths.includes(history.location.pathname));
    for (let [key, value] of Object.entries(subMenuPaths)) {
      if (history.location.pathname.includes(`${value}`)) {
        setIsActive(true);
      }
    }
  }, [history.location.pathname]);

  let menuDirection = intl.locale === "ar" ? "right" : "left";

  return (
    <Box className="navbar-header" onMouseLeave={handleCloseCA}>
      <Button
        id="main-header-channel-analytics"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={openCA ? "true" : undefined}
        disableElevation
        onClick={handleClickChannelAnalytics}
        onMouseOver={handleClickChannelAnalytics}
        endIcon={<KeyboardArrowDown />}
        className={`${
          isActive
            ? "selected-route btn-dropdown-nav channel-analytics-menu"
            : "btn-dropdown-nav channel-analytics-menu"
        } dropdown-menu-arrow header-dropdown-menu`}
      >
        {CheckValueLocale("channel_analytics", "", {}, intl)}
      </Button>
      <Menu
        id="demo-customized-menu"
        anchorEl={anchorCA}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuDirection,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuDirection,
        }}
        open={openCA}
        onClose={handleCloseCA}
        MenuListProps={{ onMouseLeave: handleCloseCA }}
        className="channel-analytics-menu"
      >
        {handlelUserRoles("CXM", "VIEW_CHANNEL") && (
          <MenuItem
            onClick={() => handleCloseCA()}
            className="menu-list"
            disableRipple
            id="channel-analytics-menu-analytics"
          >
            <NavLink activeClassName="selected-route" to="/insights/main">
              {CheckValueLocale("analytics", "", {}, intl)}
            </NavLink>
          </MenuItem>
        )}
        {handlelUserRoles("CXM", "VIEW_INTERACTION") && (
          <MenuItem
            onClick={() => handleCloseCA()}
            className="menu-list"
            disableRipple
            id="channel-analytics-menu-interactions"
          >
            <NavLink activeClassName="selected-route" to="/interactions">
              {CheckValueLocale("interactions", "", {}, intl)}
            </NavLink>
          </MenuItem>
        )}

        {(handlelUserRoles("CXM", "CREATE_CHANNEL") ||
          handlelUserRoles("CXM", "EDIT_CHANNEL") ||
          handlelUserRoles("CXM", "DESTROY_CHANNEL")) && (
          <MenuItem
            onClick={() => handleCloseCA()}
            className="menu-list"
            disableRipple
            id="channel-analytics-menu-manage-channels"
          >
            <NavLink
              activeClassName="selected-route"
              to="/manage-channels/list"
            >
              {CheckValueLocale("manage_channels", "", {}, intl)}
            </NavLink>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
export default ChannelAnalyticsMenu;
