import { useWidgetName } from "../../../hooks/useWidgetName";

const widgetsMapping = {
  Linkedin__CASingleDataSourceInsightsPage__themes_and_sub_themes: "themes",
  Linkedin__CASingleDataSourceInsightsPage__volume_overtime: "interactions",
  Linkedin__CASingleDataSourceInsightsPage__content_type: "content_type",
  Linkedin__CASingleDataSourceInsightsPage__dialects_and_sub_dialects:
    "dialects_sub_dialects",
  Linkedin__CASingleDataSourceInsightsPage__channel_accounts:
    "channel_accounts",
  Linkedin__CASingleDataSourceInsightsPage__sentiment_analysis:
    "comments_sentiment_analysis",
  Linkedin__CASingleDataSourceInsightsPage__top_keywords: "top_keywords",
  Linkedin__CASingleDataSourceInsightsPage__gender_distribution:
    "gender_distribution",
  Linkedin__CASingleDataSourceInsightsPage__top_hashtags: "top_hash_tags",
  Linkedin__CASingleDataSourceInsightsPage__reactions: "reactions",
  Linkedin__CASingleDataSourceInsightsPage__contacts_activity:
    "contact_activity",
  Linkedin__CASingleDataSourceInsightsPage__top_engagers:
    "most_engaged_contacts",
};

export const LinkedinOmniRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  const widgetKey = widgetsMapping[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      widgetKey,
      response?.monitor_id,
      "LINKEDIN",
      response?.eventData,
    );
  else if (stateKey)
    handleWidgetResponse(
      stateKey,
      response?.monitor_id,
      "LINKEDIN",
      response?.eventData,
    );
};
