import { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import moment from "moment";
import Services from "../../../services/api/Services";
import UserAccount from "../../../services/controllers/userAccountController";
import {
  Container,
  Box,
  Typography,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Alert,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "@mui/material/Pagination";
import CircularLoading from "components/circularProgress";
import ReportController from "services/controllers/reportController";
import Snackbar from "components/snackBar";
import ReportDetail from "./report-detail/report-detail";
import ReportHeader from "./report-header/report-header";
import "./view-report.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faArrowToBottom,
} from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const ViewReport = () => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState({});
  const [reportDateData, setReportDateData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [reportDownloadFlag, setReportDownloadFlag] = useState(null);
  const [isRequestError, setIsRequestError] = useState(false);

  const getReportDetails = (reportId, page) => {
    ReportController.getReportDetails(reportId, page).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data && setReportData(res?.data[0]?.report_info);
      res?.data && setReportDateData(res?.data[1]?.records);
      res?.pagination?.pages &&
        res?.pagination?.pages > 0 &&
        setTotalReports(res?.pagination?.pages);
    });
  };

  const exportEnhancedReport = (reportId) => {
    ReportController.exportEnhancedReport(reportId).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data &&
        res?.data?.map((item, key) => {
          window.open(item?.url, "_blank");
        });
      setReportDownloadFlag(true);
    });
  };

  const exportOldReport = (report) => {
    let type_report = reportData?.attributes?.report_extra?.branding_type,
      reportId = reportData?.id,
      monitorName =
        reportData?.attributes?.monitors &&
        reportData?.attributes?.monitors[0]?.name,
      dataFileName = report?.data_file_name,
      monitor_id =
        reportData?.attributes?.monitors &&
        reportData?.attributes?.monitors[0]?.id;
    var user_language = "";
    if (window?.localStorage?.lang === "en") {
      user_language = "en-US";
    } else {
      user_language = "ar-SA";
    }

    var monitor_sections = reportData?.attributes?.monitor_sections;
    var data_source_name;

    for (let i = 0; i < monitor_sections?.length; i++) {
      data_source_name = monitor_sections[i]?.data_source_name;
      for (let x = 0; x < monitor_sections[i]?.sections?.length; x++) {
        getMonitorSentimentCategories(
          monitor_id,
          reportId,
          monitorName,
          dataFileName,
          user_language,
          type_report,
          monitor_sections[i].sections[x],
          data_source_name,
        );
      }
    }
  };

  const getMonitorSentimentCategories = (
    monitor_id,
    reportId,
    moniotr_name,
    dataFileName,
    user_language,
    type_report,
    section_name,
    data_source_name,
  ) => {
    ReportController.getMonitorSentimentCategories(monitor_id).then((data) => {
      setIsRequestError(!!data?.errorMsg);
      if (data?.categories === undefined) {
        // this.handleSuccessErrorMsg(false)
      } else {
        let sentiment_categories = JSON.stringify(data?.categories);
        let suggested_name = reportId + "-" + moniotr_name;
        var queryData = {
          reportId,
          dataFileName,
          user_language,
          monitor_id,
          suggested_name,
          sentiment_categories,
          type_report,
          section_name,
          data_source_name,
        };
        if (data_source_name == "TWITTER" && section_name == "METRICS") {
          ReportController.getTwitterReport(queryData).then((res) => {
            setIsRequestError(!!res?.errorMsg);
            if (res?.status === 200) {
              setReportDownloadFlag(true);
            } else {
              setReportDownloadFlag(false);
            }
          });
        } else {
          ReportController.exportReportFromFile(
            monitor_id,
            type_report,
            queryData,
          ).then((res) => {
            setIsRequestError(!!res?.errorMsg);
            if (res?.params === undefined) {
              setReportDownloadFlag(false);
            } else {
              handleGetPptLink(res?.params);
            }
          });
        }
      }
    });
  };

  const handleGetPptLink = (queryData) => {
    ReportController.getPptLink(queryData).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      if (res?.status === 200) {
        setReportDownloadFlag(true);
      } else {
        setReportDownloadFlag(false);
      }
    });
  };

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
    getReportDetails(reportData?.id, page);
  };

  let nextDeliveryMsg = "";
  let nextDeliveryDate = "";
  if (
    reportData?.attributes?.are_monitors_deleted === false &&
    reportData?.attributes?.next_delivery &&
    reportData?.attributes?.next_delivery !== "N/A"
  ) {
    nextDeliveryDate = moment(reportData?.attributes?.next_delivery)
      .utcOffset(reportData?.attributes?.next_delivery)
      .format("YYYY-MM-DD (h:mma)");
  }

  if (reportData?.attributes?.are_monitors_deleted === true) {
    if (reportData?.attributes?.platform === "CXM") {
      nextDeliveryMsg = CheckValueLocale(
        "report_paused_channel_deleted",
        "",
        {
          link: (
            <Box
              component={"span"}
              className="create-report-link"
              onClick={() => history.push("/reports/add-report")}
            >
              {CheckValueLocale("report_create_report", "", {}, intl)}
            </Box>
          ),
        },
        intl,
      );
    } else {
      nextDeliveryMsg = CheckValueLocale(
        "report_paused_monitor_deleted",
        "",
        {
          link: (
            <Box
              component={"span"}
              className="create-report-link"
              onClick={() => history.push("/reports/add-report")}
            >
              {CheckValueLocale("report_create_report", "", {}, intl)}
            </Box>
          ),
        },
        intl,
      );
    }
  } else {
    if (reportData?.attributes?.next_delivery === "N/A") {
      nextDeliveryMsg = CheckValueLocale(
        "report_paused_reached_limit",
        "",
        {},
        intl,
      );
    } else {
      nextDeliveryMsg = CheckValueLocale(
        "report_next_delivery_msg",
        "",
        { date: nextDeliveryDate },
        intl,
      );
    }
  }

  const getSmData = async (page) => {
    setLoading(true);
    let baseUrl = Services.switchUrl;

    await UserAccount.getSmData().then((result) => {
      setIsRequestError(!!result?.errorMsg);
      if (result.data !== undefined) {
        localStorage.setItem("user_token", result?.data?.data?.jwt);
        window.open(
          baseUrl +
            page +
            "?lang=" +
            localStorage?.lang +
            "&sm_token=" +
            result?.data?.data?.jwt,
          "_blank",
        );
      }
    });
    setLoading(false);
  };

  const redirectToNewsLetterReport = (reportId, reportDataId) => {
    // Note: This is a Temporary Solution until the Product team implement new track for newsletters reports
    getSmData(
      `#/newslettersreport_details/${reportId}?report_data_id=${reportDataId}`,
    );
  };

  useEffect(() => {
    getReportDetails(location?.pathname?.split("/")[3]);
  }, [location]);

  return (
    <Container maxWidth="xl" className="create-report">
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          {reportDownloadFlag != null ? (
            <Snackbar
              open={reportDownloadFlag != null ? true : false}
              handleClose={() => {
                setReportDownloadFlag(null);
              }}
              severity={reportDownloadFlag ? "success" : "error"}
              message={
                reportDownloadFlag === true ? (
                  <Box className="view-report-msg">
                    <Box>
                      {CheckValueLocale("report_generated", "", {}, intl)}
                    </Box>
                    <Box>
                      {CheckValueLocale("report_generated_body", "", {}, intl)}
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Box>{CheckValueLocale("report_fail", "", {}, intl)}</Box>
                    <Box>
                      {CheckValueLocale("report_fail_try_later", "", {}, intl)}
                    </Box>
                  </Box>
                )
              }
            />
          ) : (
            ""
          )}
          <ReportHeader reportData={reportData} />
          <ReportDetail reportData={reportData} />
          {reportData?.attributes?.manual_status == false ? (
            <Box className="view-report-list">
              <Box className="warning-box">
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {CheckValueLocale(
                  "reports_was_paused",
                  "",
                  {
                    date:
                      moment(reportData?.attributes?.created_at).format(
                        "dddd",
                      ) &&
                      moment(reportData?.attributes?.created_at).format(
                        "YYYY-MM-DD",
                      ),
                  },
                  intl,
                )}
              </Box>
            </Box>
          ) : reportData?.attributes?.has_luci_quota === false &&
            reportData?.attributes?.is_luci_report === true ? (
            <Box mt={2}>
              <Alert severity="warning" className="luci-report-warning">
                <Box component={"span"}>
                  {CheckValueLocale("luci_report_energy_warning", "", {}, intl)}
                </Box>
                {handlelUserRoles("GENERAL", "VIEW_SETTING") ? (
                  <Box component={"span"} className="upgrade-luci">
                    <Link to="/settings/subscription">
                      {CheckValueLocale("upgrade_luci_energy", "", {}, intl)}
                    </Link>
                  </Box>
                ) : null}
              </Alert>
            </Box>
          ) : null}
          {reportData?.attributes &&
            (reportData?.attributes?.are_monitors_deleted === false &&
            reportData?.attributes?.next_delivery !== "N/A" ? (
              reportDateData?.length === 0 && (
                <Box className="report-date-empty-box">
                  <Typography variant="subtitle2" component="div">
                    {nextDeliveryMsg}
                  </Typography>
                </Box>
              )
            ) : reportData?.attributes?.has_luci_quota === true ? (
              <Box mt={2}>
                <Alert severity="warning">{nextDeliveryMsg}</Alert>
              </Box>
            ) : null)}

          {reportData?.attributes?.are_monitors_deleted === false &&
            reportDateData?.length > 0 && (
              <Box className="view-report-list">
                <Grid container>
                  {reportDateData?.map((val, index) => (
                    <Box key={`list-card-${index}`}>
                      <Box className="list-card">
                        <Typography variant="subtitle2">
                          {moment(val?.created_at).format("dddd")}(
                          {moment(val?.created_at).format("DD MMMM,YYYY")})
                        </Typography>

                        {reportData?.attributes?.report_type_id === 2 ? (
                          <Tooltip
                            title={CheckValueLocale(
                              "view_report",
                              "",
                              {},
                              intl,
                            )}
                            arrow
                            placement="top"
                          >
                            <IconButton
                              onClick={() =>
                                redirectToNewsLetterReport(
                                  location.pathname.split("/")[3],
                                  val?.report_data_id,
                                )
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        ) : reportData?.attributes?.report_extra
                            ?.report_template === "dynamic_design" ||
                          reportData?.attributes?.report_extra
                            ?.report_template === "enhanced_design" ? (
                          <CustomizedTooltip
                            id="menu-item-list-tooltip"
                            title={
                              <Box className="item-title-tooltip">
                                {CheckValueLocale("download", "", {}, intl)}
                              </Box>
                            }
                            placement="bottom"
                            arrow
                          >
                            <IconButton
                              onClick={() =>
                                exportEnhancedReport(val?.report_data_id)
                              }
                            >
                              <Box className="report-icon-download">
                                <FontAwesomeIcon icon={faArrowToBottom} />
                              </Box>
                            </IconButton>
                          </CustomizedTooltip>
                        ) : (
                          <CustomizedTooltip
                            id="menu-item-list-tooltip"
                            title={
                              <Box className="item-title-tooltip">
                                {CheckValueLocale("download", "", {}, intl)}
                              </Box>
                            }
                            placement="bottom"
                            arrow
                          >
                            <IconButton onClick={() => exportOldReport(val)}>
                              <Box className="report-icon-download">
                                <FontAwesomeIcon icon={faArrowToBottom} />
                              </Box>
                            </IconButton>
                          </CustomizedTooltip>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Grid>

                {totalReports > 1 && (
                  <Pagination
                    onChange={handleChangePage}
                    page={page}
                    className="pagination-monitor-list"
                    count={totalReports > 1 ? totalReports : 1}
                    variant="outlined"
                  />
                )}
              </Box>
            )}
          {isRequestError && (
            <Snackbar
              lang={window?.localStorage?.lang}
              open={true}
              handleClose={() => {
                setIsRequestError(!!false);
              }}
              severity="error"
              message={CheckValueLocale(
                "something_went_wrong_try_agin",
                "",
                {},
                intl,
              )}
            />
          )}
        </>
      )}
    </Container>
  );
};
export default ViewReport;
