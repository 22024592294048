import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  Input,
  Typography,
} from "@mui/material";
import "../../surveyBuilder.scss";

const LinearScaleQuestion = (props) => {
  const {
    question,
    questionIndex,
    sectionIndex,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    handleCardClick,
  } = props;

  const intl = useIntl();
  const [value, setValue] = useState({});
  const [editable, setEditable] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(10);
  const [lowValue, setLowValue] = useState("");
  const [highValue, setHighValue] = useState("");

  useEffect(() => {
    if (question) {
      setValue({ title: question?.text, description: question?.description });
      setEditable(question?.editable);
    }
  }, [question]);

  useEffect(() => {
    setMinValue(question?.constraints?.min_value);
    setMaxValue(question?.constraints?.max_value);
    setLowValue(question?.constraints?.low_value);
    setHighValue(question?.constraints?.high_value);
  }, [
    question?.constraints?.min_value,
    question?.constraints?.max_value,
    question?.constraints?.low_value,
    question?.constraints?.high_value,
  ]);

  const [answers, setAnswers] = useState([]);
  useEffect(() => {
    let arr = [];
    for (let i = minValue; i <= maxValue; i++) arr?.push(i);
    setAnswers(arr);
  }, [minValue, maxValue]);

  return (
    <Card
      className={`cardStyle ${
        ssIndex === sectionIndex + 1 &&
        sqIndex === questionIndex + 1 &&
        "noError"
      }`}
      onClick={() => handleCardClick(sectionIndex, questionIndex)}
    >
      <CardContent className="card-body title-question">
        <Box className="question-title-container">
          <Box component="span" className="questionNoStyle">
            {questionIndex + 1}.
          </Box>
          <Input
            fullWidth
            multiline
            className="questionStylee"
            style={{
              direction: checkKeyboardLanguage(value?.title) ? "rtl" : "ltr",
            }}
            id={questionIndex}
            value={
              value?.title ? CheckValueLocale(value?.title, "", {}, intl) : ""
            }
            onChange={(e) => {
              handleQuestionTitle(
                e,
                sectionIndex,
                questionIndex,
                value,
                setValue,
              );
            }}
            name="title"
            placeholder={CheckValueLocale("name_question", "", {}, intl)}
            disableUnderline={true}
          />
        </Box>
        <Input
          id={questionIndex}
          variant="outlined"
          className="subtitle"
          value={value?.description}
          onChange={(e) => {
            handleQuestionDesc(e, sectionIndex, questionIndex, value, setValue);
          }}
          style={{
            direction: checkKeyboardLanguage(value?.description)
              ? "rtl"
              : "ltr",
          }}
          name="subtitle"
          placeholder={CheckValueLocale("question_description", "", {}, intl)}
          disableUnderline={true}
        />
        <Box className={"scaleBox"}>
          <FormControl className={"scaleFormControl"}>
            <Box className={"scaleContainer"}>
              {answers?.map((item, index) => {
                return (
                  <label
                    className={`radio-item ${
                      (index === 0 || index === answers?.length - 1) &&
                      "valuesText"
                    }`}
                    key={index}
                  >
                    <input
                      type="radio"
                      style={{
                        direction: checkKeyboardLanguage(value?.description)
                          ? "rtl"
                          : "ltr",
                      }}
                      disabled
                    />
                    <Box component="span" className="choose">
                      <Box>{item}</Box>
                    </Box>
                    {index === 0 && (
                      <Typography component={"p"} textAlign={"center"}>
                        {lowValue}
                      </Typography>
                    )}
                    {index === answers?.length - 1 && (
                      <Typography component={"p"} textAlign={"center"}>
                        {highValue}
                      </Typography>
                    )}
                  </label>
                );
              })}
            </Box>
          </FormControl>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LinearScaleQuestion;
