import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import WhatsAppBody from "./whatsAppBody";
import InsightsPageHeader from "pages/channelAnalytics/InsightsPage/insightsPageHeader.js";
import moment from "moment";
import { connectWithRabbitMQ } from "../../insightsCommonFunctions.js";
import {
  isEmptyObj,
  handleCAFiltersIds,
  handleCAFiltersVals,
  handleApplicableFiltersCA,
  checkEmptyValuesinObjectOfArrays,
  getIdsFromFilter,
  handleFiltersParamsIds,
} from "utils/helpers";
import { useParams } from "react-router-dom";
import InsightsController from "services/controllers/insightsController.js";
import FiltersController from "services/controllers/filtersController";
import {
  getDataSources,
  getChannelsUsage,
} from "../../../channelAnalytics.hooks.js";
import AlertMessage from "shared/alert/alertMessage.js";
import { useSelector, useDispatch } from "react-redux";
import {
  filtersDropDownToDisplay,
  reduxApplicableFilters,
} from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice.js";
import { getActiveParams } from "shared/channel-analytics-filter/activeFilters/activeFilters";
import { useIntl } from "react-intl";

const WhatsAppPage = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  //to check if category has only one datasource or not , then redirect user to datasource when pressed category link.
  let hasDatasourceOnly = localStorage.getItem("has_datasource_only");
  let pathName = window.location.pathname?.slice(1);
  const links = [
    {
      name: "analytics",
      url: "insights/main",
    },
    {
      name: "chat",
      url: hasDatasourceOnly === "true" ? pathName : "insights/chat",
    },
  ];

  /* Start Redux Declarations */
  const reduxStartDateUx = useSelector(
    (state) => state?.channelAnalytics?.startDateUnix,
  );

  const reduxEndDateUx = useSelector(
    (state) => state?.channelAnalytics?.endDateUnix,
  );

  const reduxFilterParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );

  const reduxApplicableFiltersCA = useSelector(
    (state) => state?.channelAnalytics?.applicableFilters,
  );
  /* End Redux Declarations */

  const [response, setResponse] = useState([]);
  const [filterFields, setFilterFields] = useState({});
  const [dataSources, setDataSources] = useState([]);
  let startDate = reduxStartDateUx;
  let endDate = reduxEndDateUx;
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const urlParams = useParams();
  const dataSourceId = urlParams.data_source_id;
  const [resetAllFields, setResetAllFields] = useState(0);
  const [preLoaderTrackerDataSources, setPreLoaderTrackerDataSources] =
    useState(["whatsapp"]);
  const [assignActiveFilters, setAssignActiveFilters] = useState({});
  const [reachInteractionsLimit, setReachInteractionsLimit] = useState(false);
  const [reachInteractionsLimitAlert, setReachInteractionsLimitAlert] =
    useState(true);
  const [companyCanCreateChannel, setCompanyCanCreateChannel] = useState(false);
  const [checkAllDataExist, setCheckAllDataExist] = useState(false);
  const [checkDataIsEmpty, setCheckDataIsEmpty] = useState(false);
  //export btn
  const [checkAllDataFetchingExport, setCheckAllDataFetchingExport] =
    useState(false);
  const [excelSheetData, setExcelSheetData] = useState([]);

  const [isApplicableFilterCalculated, setIsApplicableFilterCalculated] =
    useState(false);

  const getCredentials = (startDate, endDate, filterParams, custom) => {
    setResponse([]);
    InsightsController.getWhatsappCredentials(
      startDate,
      endDate,
      filterParams,
      custom,
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      if (!isEmptyObj(data.data)) {
        setReachInteractionsLimit(data?.data?.interactions_limit_reach);
        setPreLoaderTrackerDataSources(data?.data?.data_sources);
        connectWithRabbitMQ(
          mqttConnectionClosed,
          clientMQTT,
          data.data,
          setResponse,
        );
      } else {
        mqttConnectionClosed.current = true;
      }
    });
  };

  const applyFilter = (
    startDate,
    endDate,
    applicableFilters,
    applicableFilterCalculated,
  ) => {
    if (isApplicableFilterCalculated || applicableFilterCalculated) {
      let selectedFilterParams = applicableFilters
        ? applicableFilters
        : !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
          ? reduxApplicableFiltersCA
          : assignActiveFilters;

      if (
        applicableFilters === undefined &&
        !checkEmptyValuesinObjectOfArrays(reduxApplicableFiltersCA)
      ) {
        const commonObject = {};
        const obj = {
          ...filterFields?.by_interactions,
          ...filterFields?.by_user,
        };

        const finalArr = getIdsFromFilter(obj);
        Object.keys(reduxApplicableFiltersCA).forEach((key) => {
          if (
            Array.isArray(reduxApplicableFiltersCA[key]) &&
            Array.isArray(finalArr[key])
          ) {
            const commonValues = reduxApplicableFiltersCA[key].filter(
              (value) => {
                return (
                  finalArr[key].findIndex(
                    (item) =>
                      item?.toString()?.toLowerCase() ===
                      value?.toString()?.toLowerCase(),
                  ) !== -1
                );
              },
            );
            commonObject[key] = commonValues;
          } else {
            commonObject[key] = [];
          }
        });
        selectedFilterParams = { ...commonObject };
        selectedFilterParams = handleFiltersParamsIds(
          selectedFilterParams,
          filterFields,
        );
      }

      const resp = handleCAFiltersIds(selectedFilterParams, filterFields);
      const {
        dmMonitorListFullName,
        waitingFilterFullName,
        monitorListFullName,
      } = resp;
      selectedFilterParams = resp?.selectedFilterParams;
      setResetAllFields((prev) => prev + 1);

      selectedFilterParams = handleCAFiltersVals(
        dmMonitorListFullName,
        monitorListFullName,
        waitingFilterFullName,
        selectedFilterParams,
      );
      setAssignActiveFilters(selectedFilterParams);
      getCredentials(startDate, endDate, selectedFilterParams, true);
    }
  };

  const getFiltersFileds = () => {
    FiltersController.getWhatsappFiltersFileds(
      window.localStorage.cxm_id,
      dataSourceId,
    ).then((data) => {
      setFilterFields(data.data);
    });
  };
  let activeFiltersArr = getActiveParams(assignActiveFilters);

  useEffect(() => {
    getDataSources(setDataSources);
    getFiltersFileds();
  }, []);

  useEffect(() => {
    getChannelsUsage(setCompanyCanCreateChannel);
  }, []);

  useEffect(() => {
    if (!isEmptyObj(filterFields)) {
      let applicableFilters = handleApplicableFiltersCA(
        filterFields,
        reduxFilterParams,
      );
      let commonObject = {};
      const obj = {
        ...filterFields?.by_interactions,
        ...filterFields?.by_user,
      };

      const finalArr = getIdsFromFilter(obj);

      Object.keys(applicableFilters).forEach((key) => {
        if (
          Array.isArray(applicableFilters[key]) &&
          Array.isArray(finalArr[key])
        ) {
          const commonValues = applicableFilters[key].filter((value) => {
            return (
              finalArr[key].findIndex(
                (item) =>
                  item?.toString()?.toLowerCase() ===
                  value?.toString()?.toLowerCase(),
              ) !== -1
            );
          });
          commonObject[key] = commonValues;
        } else {
          commonObject[key] = [];
        }
      });
      setIsApplicableFilterCalculated(true);
      applyFilter(
        startDate,
        endDate,
        handleFiltersParamsIds(commonObject, filterFields),
        true,
      );
      dispatch(reduxApplicableFilters(applicableFilters));
      dispatch(filtersDropDownToDisplay(commonObject));
    }
  }, [filterFields, intl?.locale]);

  return (
    <>
      <Container maxWidth="xl" className={`bread-crumb-page-style`}>
        <InsightsPageHeader
          title="whatsApp"
          showNewChannelBtn={true}
          showDatePicker={true}
          showFiltersBtn={true}
          filterFields={filterFields}
          applyFilter={applyFilter}
          startDate={startDate}
          endDate={endDate}
          showBreadCrumb={false}
          breadCrumbLinks={links}
          assignActiveFilters={assignActiveFilters}
          allActiveFilters={activeFiltersArr}
          companyCanCreateChannel={companyCanCreateChannel}
          sectionName="whatsapp"
          luciFeature="Analytics"
          dataSourceId={dataSourceId}
          dataLoaded={checkAllDataExist}
          dataEmpty={checkDataIsEmpty}
          showExportBtn
          isExportLoaded={checkAllDataFetchingExport}
          excelSheetData={excelSheetData}
          monitorDataSource={"whatsapp"}
          datasourceName={"whatsapp"}
          datasourcePath={"chat/whatsapp/insights"}
        />

        <AlertMessage
          mainFlag={reachInteractionsLimit}
          alertMainFlag={reachInteractionsLimitAlert}
          setAlertMainFlag={setReachInteractionsLimitAlert}
          alertMessageId={"interactions_limit_alert"}
        />

        <WhatsAppBody
          response={response}
          dataSources={dataSources}
          resetAllFields={resetAllFields}
          data_source_id={dataSourceId}
          preLoaderTrackerDataSources={preLoaderTrackerDataSources}
          assignActiveFilters={assignActiveFilters}
          setAssignActiveFilters={setAssignActiveFilters}
          startDate={startDate}
          endDate={endDate}
          applyFilter={applyFilter}
          allActiveFilters={activeFiltersArr}
          filterFields={filterFields}
          setCheckAllDataExist={setCheckAllDataExist}
          setCheckDataIsEmpty={setCheckDataIsEmpty}
          setCheckAllDataFetchingExport={setCheckAllDataFetchingExport}
          setExcelSheetData={setExcelSheetData}
        />
      </Container>
    </>
  );
};
export default WhatsAppPage;
