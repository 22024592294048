import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import "./request.scss";
import CompaniesController from "services/controllers/companiesController";
import { CheckValueLocale } from "utils/helpers";
import requestAPi from "images/noDataFound/API.svg";
import SnackBar from "components/snackBar";
import Header from "../header/header";

const Request = ({ lucidyaApisActiveCheck }) => {
  const intl = useIntl();
  const [request, setRequest] = useState(false);
  const [success, setSuccess] = useState(null);
  const [snackBarHeading, setSnackBarHeading] = useState(null);
  const [snackBarDescription, setSnackBarDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const requestAccessLucidyaApis = () => {
    let queryData = {
      service_name: "lucidya_api",
    };
    setIsLoading(true);
    CompaniesController.requestService(queryData).then((result) => {
      if (!result.errorMsg) {
        setSuccess(true);
        setRequest(true);
        setSnackBarHeading("request_access_success_heading");
        setSnackBarDescription("request_access_success_description");
      } else {
        setSuccess(false);
        setRequest(false);
        setSnackBarHeading("request_access_error_heading");
        setSnackBarDescription("request_access_error_description");
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      <Header
        requestHeader={true}
        request={request}
        requestAccessLucidyaApis={requestAccessLucidyaApis}
        isLoading={isLoading}
        lucidyaApisActiveCheck={lucidyaApisActiveCheck}
      />
      <Box className="request-wrapper">
        <Box className="notification-empty-box">
          <img src={requestAPi} />
          <Typography component={"h3"} className="no-data-found">
            {CheckValueLocale("request_lucidya_api", "", {}, intl)}
          </Typography>
          <Typography component={"span"} className="try-adjust-date">
            {CheckValueLocale(
              "lucidyaa_request_access_description",
              "",
              {},
              intl,
            )}{" "}
            <a
              href="https://docs.lucidya.com/"
              target="_blank"
              rel="noreferrer"
              className="api-documentation-link"
            >
              {CheckValueLocale("lucidya_api_documentation", "", {}, intl)}
            </a>
          </Typography>
        </Box>
      </Box>
      {success != null ? (
        <SnackBar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            <div className="lucidya-apis-snackbar-wrapper">
              <span className="lucidya-apis-snackbar-heading">
                {CheckValueLocale(snackBarHeading, "", {}, intl)}
              </span>
              <span className="lucidya-apis-snackbar-description">
                {CheckValueLocale(snackBarDescription, "", {}, intl)}
              </span>
            </div>
          }
        />
      ) : (
        ""
      )}
    </>
  );
};
export default Request;
