import LineChartWidget from "components/widgets/lineChartWidget";
import React, { useEffect, useState } from "react";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";

const CustomDashboardLineChart = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);
  const [totalValue, setTotalValue] = useState(null);
  const [totalLabel, setTotalLabel] = useState(null);
  const [totalLabelColor, setTotalLabelColor] = useState(null);

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "volume_overtime":
          if (
            widget?.dashboard_page_name === "TWITTER_PRIVATE" ||
            widget?.dashboard_page_name === "INSTAGRAM_PRIVATE" ||
            widget?.dashboard_page_name === "FACEBOOK_PRIVATE" ||
            widget?.dashboard_page_name === "LINKEDIN_PUBLIC"
          ) {
            setData(dataState?.interactions);
            setTotalValue(dataState?.total_interactions);
            setTotalLabel("interactions");
            setTotalLabelColor("#2AAAE2");
          } else {
            setData(
              dataState?.[widget?.widget_name]?.posts_over_time ||
                dataState?.posts_over_time,
            );
            setTotalValue(
              dataState?.[widget?.widget_name]?.total_posts_count ||
                dataState?.total_posts_count,
            );
            setTotalLabel(
              handledWidgetName === "posts_volume" ||
                handledWidgetName === "posts_volume_overtime"
                ? "posts"
                : "comments",
            );
            setTotalLabelColor("#2AAAE2");
          }

          break;

        case "posts_volume_overtime":
        case "comments_volume_overtime":
          setData(
            dataState?.[widget?.widget_name]?.posts_over_time ||
              dataState?.posts_over_time,
          );
          setTotalValue(
            dataState?.[widget?.widget_name]?.total_posts_count ||
              dataState?.total_posts_count,
          );
          setTotalLabel(
            handledWidgetName === "posts_volume" ||
              handledWidgetName === "posts_volume_overtime"
              ? "posts"
              : "comments",
          );
          setTotalLabelColor("#2AAAE2");
          break;

        case "engagers_volume":
          setData(dataState?.[widget?.widget_name]?.engagers_volume_over_time);
          setTotalValue(dataState?.[widget?.widget_name]?.total_engagers);
          setTotalLabel("engagers");
          setTotalLabelColor("#2AAAE2");
          break;

        case "page_views":
          setData(dataState?.page_views_overtime);
          setTotalValue(dataState?.total_page_views?.[0]?.value);
          setTotalLabel("views");
          setTotalLabelColor("#2AAAE2");
          break;

        case "videos_views":
          setData(dataState?.page_videos_views_overtime);
          setTotalValue(dataState?.total_page_videos_views?.[0]?.value);
          setTotalLabel("videos");
          setTotalLabelColor("#2AAAE2");
          break;

        case "video_volume":
          setData(dataState?.posts_over_time);
          setTotalValue(dataState?.total_posts_count);
          setTotalLabel("videos");
          setTotalLabelColor("#2AAAE2");
          break;

        case "comments_volume":
          setData(dataState?.interactions);
          setTotalValue(dataState?.total_interactions);
          setTotalLabel("comments");
          setTotalLabelColor("#2AAAE2");
          break;

        default:
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  return (
    <LineChartWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={titleTooltip || ""}
      data={data || []}
      totalLabel={totalLabel}
      labelColor={totalLabelColor}
      totalValue={totalValue || 0}
      lineChartPreLoaderDataSources={
        socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
          monitorId
        ]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ] || []
      }
      preLoaderTrackerDataSources={[monitorId] || []}
      showChartDetailsWidget={null}
      showChartStatsWidget={null}
      isCustomDashboard
      monitorData={{
        name:
          dashboardInfo?.product === "cxm"
            ? widget?.account_identifier
            : widget?.monitor_name,
        type:
          dashboardInfo?.product === "cxm"
            ? widget?.data_category
            : widget?.monitor_type?.name,
        datasource: widget?.is_dm
          ? `${widget?.data_source}_private`
          : widget?.data_source,
      }}
    />
  );
};

export default CustomDashboardLineChart;
