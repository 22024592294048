import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import {
  CheckValueLocale,
  handlelUserRoles,
  retrieveActiveProductsArray,
  convertXPlateformToTwitter,
} from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import UserProfileSideBar from "shared/userProfileSideBar/UserProfileSideBar";
import { useState } from "react";

const InteractionsListHeader = (props) => {
  const [openProfile, setOpenProfile] = useState(false);
  let intl = useIntl();
  const history = useHistory();
  let channelPath = history?.location?.pathname;

  const {
    obj,
    channelName,
    createdBy,
    icon,
    phoneNumber,
    sub_name,
    interactionType,
    audio,
    systemReplies,
    unixDate,
    showProfile,
  } = props;
  let channelType =
    channelName === "linkedIn"
      ? CheckValueLocale("type_comment", "", {}, intl)
      : channelName === "genesys"
        ? CheckValueLocale("type_call", "", {}, intl)
        : CheckValueLocale(interactionType, "", {}, intl);
  const isRequiredDatasource =
    (channelPath?.includes("/insights/social-media/") &&
      !channelPath?.includes("linkedin-channel") &&
      !channelPath?.includes("tikTok-channel") &&
      channelName !== "linkedIn") ||
    (channelPath?.includes("/interactions/social-media/") &&
      !channelPath?.includes("tikTok-channel") &&
      !channelPath?.includes("linkedin-channel") &&
      channelName !== "linkedIn") ||
    ((channelPath === "/insights/main" || channelPath === "/interactions") &&
      (channelName === "facebook" ||
        channelName === "instagram" ||
        channelName === "twitter"));

  const parseDataSourceForProfile = (channelName) => {
    const name = channelName?.toUpperCase()?.replace("-PRIVATE", "");
    return name === "X-PLATFORM" ? "TWITTER" : name;
  };
  const dataSourceName = parseDataSourceForProfile(channelName);
  const handleSideBar = () => {
    if (showProfile) {
      setOpenProfile(true);
    }
  };
  return (
    <>
      {openProfile ? (
        <UserProfileSideBar
          unixDate={unixDate}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
          selectedCard={{
            screen_name: sub_name?.replace("@", ""),
            user_name: createdBy?.replace("@", ""),
          }}
          dataSourceName={dataSourceName}
          monitorId={props.tracker_id}
          productId={window.localStorage?.getItem("cxm_id")}
        />
      ) : null}
      <div className="header-interaction">
        <div
          className="interaction-list__header clearfix"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span
            className={`main-item-header ${showProfile ? "hover-show" : ""} `}
            onClick={handleSideBar}
          >
            {icon}
            <span className="interaction-list-user-data">
              {createdBy ? (
                <p className="label">{createdBy}</p>
              ) : (
                <p className="label">
                  {CheckValueLocale("client", "", {}, intl)}
                </p>
              )}

              {/* Display Email just For gmail & Intercom & Name for twitter */}
              {/* we need to remove channelName === "x-platform-private" later, when we change isRequiredDatasource */}
              {(channelName === "gmail" ||
                channelName === "intercom" ||
                channelName === "twitter" ||
                channelName === "x-platform-private" ||
                ((channelName === "instagram-private" ||
                  channelName === "instagram") &&
                  sub_name)) && (
                <p className="sub-name">
                  {sub_name
                    ? sub_name
                    : "[" + CheckValueLocale("no_email", "", {}, intl) + "]"}
                </p>
              )}

              {/* Display phone number just for whatsapp */}
              {channelName === "whatsapp" ||
                (channelName === "genesys" && (
                  <p className="phoneNumber">{phoneNumber}</p>
                ))}
              {props.userName ? <p>{props.userName}</p> : null}
            </span>
          </span>

          <Divider
            orientation="vertical"
            className="vertical-line-list-header"
          />
          <Typography className="interaction-list-type">
            {channelType}
          </Typography>
          {isRequiredDatasource &&
          handlelUserRoles("ENGAGEMENT_ACCESS", "OC_ENGAGEMENT") &&
          retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
          props?.allawedTrackers?.includes(+props?.tracker_id) ? (
            <>
              {systemReplies?.is_replied === 1 ? (
                <Typography className="reply-success-text-channel-analytics">
                  {CheckValueLocale("replied", "", {}, intl)}
                </Typography>
              ) : (
                <Typography className="not-reply-text-channel-analytics">
                  {CheckValueLocale("not_replied", "", {}, intl)}
                </Typography>
              )}
            </>
          ) : null}
        </div>

        {(props.isAudiencePage &&
          handlelUserRoles("CDP", "VIEW_PROFILE_INTERACTIONS_DETAILS")) ||
        !props?.missedCalls ||
        (!props.isAudiencePage && channelName !== "genesys") ? (
          <LucButton
            type="secondary"
            variant="outline"
            size="small"
            onClick={() =>
              window.open(
                `${props.link}/${props?.id}/${props?.tracker_id}/${
                  props?.channel_id
                }${channelName == "tiktok" ? "/" + obj?.type : ""}`,
                "_blank",
              )
            }
            id="interactions-details-btn"
          >
            {CheckValueLocale("interaction_details", "", {}, intl)}
          </LucButton>
        ) : null}
      </div>
    </>
  );
};

export default InteractionsListHeader;
