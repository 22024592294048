import { Box } from "@mui/material";
import "./userImage.scss";
import DefaultUserAvtar from "images/shared-images/user-management-default.svg";

const colorArray = [
  "#FF5733",
  "#406375",
  "#51A960",
  "#787878",
  "#FF3333",
  "#FF8D8D",
  "#1B4E52",
  "#33A1FF",
  "#422064",
  "#338D8D",
  "#3357FF",
  "#FF8D33",
  "#FF338D",
  "#FF33F5",
  "#8D33FF",
  "#FF33A1",
  "#121C3F",
  "#1FAD1F",
  "#2B4B34",
  "#338DFF",
  "#A22525",
  "#5733FF",
  "#456eb2",
  "#6788A0",
  "#86603B",
];

const getAsccCode = (character) => {
  const ascCode = character?.charCodeAt()?.toString()?.split("");
  const finalValue =
    ascCode?.reduce((total = 0, item) => +total + +item) / ascCode?.length;
  return finalValue;
};

const getUserImage = (user) => {
  const fristName = user?.attributes?.name
    ?.split(" ")?.[0]
    ?.charAt(0)
    ?.toLocaleUpperCase();
  const lastName = user?.attributes?.name
    ?.split(" ")?.[1]
    ?.charAt(0)
    ?.toLocaleUpperCase();
  const contentImage =
    (fristName ? fristName : "") + (lastName ? lastName : "");

  const ascCodeFrist = getAsccCode(fristName);
  const ascCodeLast = getAsccCode(lastName);
  const indexColor = parseInt((ascCodeFrist + ascCodeLast) / 2);
  return {
    contentImage: contentImage,
    backColor: colorArray[indexColor < colorArray?.length ? indexColor : 0],
  };
};

const UserImage = ({ user }) => {
  if (user?.attributes?.avatar) {
    return (
      <Box
        component={"img"}
        src={user?.attributes?.avatar}
        alt="user Image"
        onError={(e) => {
          e.target.src = DefaultUserAvtar;
        }}
      />
    );
  } else {
    const userImage = getUserImage(user);
    return (
      <Box
        component={"span"}
        className="user-img"
        sx={{ background: userImage?.backColor }}
      >
        {userImage?.contentImage}
      </Box>
    );
  }
};

export default UserImage;
