import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Button, Tooltip } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const StepOne = (props) => {
  const {
    customDomain,
    shareSurvey,
    domainActive,
    handleDomainClick,
    handlelUserRoles,
    styles,
  } = props;
  const intl = useIntl();

  return (
    <>
      <Box className="share-survey-container">
        <Box className="close-share-btn publish-step">1 / 3</Box>
        <Box className="how-share-survey">
          <Box className="how-share-title">
            {CheckValueLocale("how_would_to_share_your_survey", "", {}, intl)}
          </Box>
          <Box className="publish-domain-step-one">
            {shareSurvey?.map(({ name, desc, tooltip, id }, index) => {
              return (
                <Box key={index} className="publish-step-btn">
                  <Button
                    className={
                      name == "company_custom_domain" && customDomain == ""
                        ? "publish-grid-content disabled-custom-domain"
                        : domainActive == id
                          ? "publish-grid-content active"
                          : "publish-grid-content"
                    }
                    onClick={() => handleDomainClick(id)}
                    disabled={
                      name == "company_custom_domain" && customDomain == ""
                    }
                  >
                    <Tooltip
                      title={CheckValueLocale(
                        tooltip,
                        "",
                        { domain_name: `${customDomain}` },
                        intl,
                      )}
                      placement="right"
                      arrow
                      classes={{ tooltip: styles().tooltip }}
                    >
                      <Box className="domain-name">
                        {CheckValueLocale(`${name}`, "", {}, intl)}
                      </Box>
                    </Tooltip>
                    <Box className="domain-desc">
                      {CheckValueLocale(`${desc}`, "", {}, intl)}
                    </Box>
                  </Button>
                </Box>
              );
            })}
          </Box>
        </Box>
        {handlelUserRoles("GENERAL", "EDIT_COMPANY_BASIC_INFO") && (
          <Box className="manage-domain-btn">
            <Link to="/settings">
              {CheckValueLocale("survey_manage_custom_domain", "", {}, intl)}
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};

export default StepOne;
