import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/material";
import PopupModal from "components/popupModal";
import { useState } from "react";
import { useIntl } from "react-intl";
import InstagramHashtagsController from "services/controllers/instagramHashtagsController";
import { CheckValueLocale } from "utils/helpers";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

function DeleteItem({ item, getHashtags, setSnakBarDelete, hashtags, page }) {
  const intl = useIntl();
  const hashtagName = item?.hashtag;
  const disabledIcon = item?.status !== "inactive";
  const [openPopupDelete, setOpenPopupDelete] = useState(null);
  let modalBody = (
    <Box className="shared-dialog-body">
      <p>{`${CheckValueLocale("content_delete_hashtag", "", { hashtagName }, intl)}`}</p>
    </Box>
  );

  const cancelPopupDelet = () => {
    setOpenPopupDelete(null);
  };
  const confirmDelet = () => {
    setOpenPopupDelete(null);
    InstagramHashtagsController.deletHashtag(item?.id).then((res) => {
      if (res?.errorMsg) {
        setSnakBarDelete({
          open: true,
          title: CheckValueLocale("hashtag_delete_title_error", "", {}, intl),
          message: CheckValueLocale("hashtag_delete_msg_error", "", {}, intl),
          severity: "error",
        });
      } else {
        setSnakBarDelete({
          open: true,
          title: "",
          message: CheckValueLocale(
            "hashtag_delete_msg_success",
            "",
            { hashtagName },
            intl,
          ),
          severity: "success",
        });
        let offsetPage = page;
        if (hashtags?.length === 1 && page > 1) {
          offsetPage = page - 1;
        }
        getHashtags(offsetPage);
      }
    });
  };

  const checkToOpenPopupDelete = () => {
    if (!item?.monitors?.length) {
      setOpenPopupDelete(true);
    }
  };
  return (
    <>
      <CustomizedTooltip
        title={
          item?.monitors?.length && !disabledIcon ? (
            <Box className="item-title-tooltip">
              {CheckValueLocale("delete", "", {}, intl)}
            </Box>
          ) : (
            ""
          )
        }
        arrow
        placement="bottom"
      >
        <IconButton
          disabled={disabledIcon}
          onClick={checkToOpenPopupDelete}
          id="instagram-hash-delete-btn"
        >
          <FontAwesomeIcon
            className={`delete-icon ${disabledIcon && "delete-icon-disabled"}`}
            icon={faTrashCan}
          />
        </IconButton>
      </CustomizedTooltip>

      {openPopupDelete && (
        <PopupModal
          title={CheckValueLocale("delete_hashtag", "", {}, intl)}
          body={modalBody}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
          open={openPopupDelete || false}
          close={cancelPopupDelet}
          accept={confirmDelet}
          minWidth="600px"
          classeName="popupModal-delet actions-buttons-shared"
          warning
        />
      )}
    </>
  );
}

export default DeleteItem;
