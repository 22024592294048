import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";
import { Box, Grid } from "@mui/material";
import _ from "lodash";
import LiveDashboardHeader from "./components/liveDashboardHeader";
import XPlateformDataSource from "./components/xPlateformDataSource/xPlateformDataSource";
import FacebookDataSource from "./components/facebookDataSource/facebookDataSource";
import InstagramDataSource from "./components/instagramDataSource/instagramDataSource";
import NewsBlogsDataSource from "./components/newsBlogsDataSource/newsBlogsDataSource";
import CircularLoading from "components/circularProgress";
import DashboardFunction from "./components/dashboardFunction";
import "./main.scss";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import SaveOrderContent from "./components/saveOrderContent";
import PowerLogoContent from "./components/powerLogoContent";
import StatisticWidgets from "./components/statisticWidget";

const defaultStatisticWidgets = {
  totalPosts: 0,
  totalAuthors: 0,
  totalInteractions: 0,
};
const LiveDashboard = () => {
  const urlParams = useParams();
  const monitorId = urlParams?.monitorId;
  const intl = useIntl();
  const [totalStatisticWidgets, setTotalStatisticWidgets] = useState(
    defaultStatisticWidgets,
  );
  const [loadingStatisticWidgets, setLoadingStatisticWidgets] = useState(true);

  const {
    startDate,
    endDate,
    unixDate,
    selectedWidgetsName,
    trackerWidgetsName,
    setSectionCounter,
    response,
    countWidgets,
    selectedWidgets,
    sectionCounter,
    monitorLiveDashboardDetails,
    loadingMonitorLiveDashboardDetails,
    lastUpdated,
    getSocialIcon,
    resetAllFields,
    preLoaderTrackerDataSources,
    singleTweetAnalysis,
    multiRequestHandler,
    handleResponseFunc,
    applyFilter,
    handleLiveDashboardDetails,
    getMonitorLiveDashboardDetails,
    refreshRequestHandler,
    xPlateformWidgetTypes,
    setXPlateformWidgetTypes,
    facebookWidgetTypes,
    setFacebookWidgetTypes,
    instagramWidgetTypes,
    setInstagramWidgetTypes,
    newsBlogsWidgetTypes,
    setNewsBlogsWidgetTypes,
    orderFlag,
    setOrderFlag,
    sortWidgets,
    editLiveDashboardHandler,
    success,
    setSuccess,
    snackBarHeading,
    isActionLoading,
    getWidgetsSoretedArr,
    refreshCount,
    dataSources,
    setDataSources,
    getSourceName,
    liveDashboardName,
    changeLang,
    redirectToMonitor,
    getNameKeyWidget,
    showEditBtn,
  } = DashboardFunction();

  useEffect(() => {
    getDataSources(setDataSources);
  }, []);

  useEffect(() => {
    if (_.isEqual(selectedWidgetsName?.sort(), trackerWidgetsName?.sort())) {
      setSectionCounter((prev) => prev + 1);
    }
  }, [trackerWidgetsName]);

  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  useEffect(() => {
    if (countWidgets && selectedWidgets && startDate && endDate) {
      multiRequestHandler();
    }
  }, [
    sectionCounter,
    countWidgets,
    selectedWidgets,
    startDate,
    endDate,
    refreshCount,
  ]);

  useEffect(() => {
    if (selectedWidgets) {
      selectedWidgets?.map((dataSource) => {
        if (getSourceName(dataSource?.data_source_id) === "TWITTER")
          setXPlateformWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
        if (getSourceName(dataSource?.data_source_id) === "FACEBOOK")
          setFacebookWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
        if (getSourceName(dataSource?.data_source_id) === "INSTAGRAM")
          setInstagramWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
        if (getSourceName(dataSource?.data_source_id) === "TALKWALKER")
          setNewsBlogsWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
      });
    }
  }, [selectedWidgets]);

  useEffect(() => {
    if (monitorLiveDashboardDetails) {
      handleLiveDashboardDetails();
      setInterval(() => {
        refreshRequestHandler();
      }, 60000);
    }
  }, [monitorLiveDashboardDetails]);

  useEffect(() => {
    if (monitorId && dataSources?.length) {
      getMonitorLiveDashboardDetails();
    }
  }, [monitorId, dataSources]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setTotalStatisticWidgets(defaultStatisticWidgets);
      setLoadingStatisticWidgets(true);
    }
  }, [resetAllFields]);

  return (
    <>
      <Box className="live-dashboard-wrapper prevent-select">
        {loadingMonitorLiveDashboardDetails ? (
          <CircularLoading />
        ) : (
          <>
            <LiveDashboardHeader
              applyFilter={applyFilter}
              startDate={startDate}
              endDat={endDate}
              lastUpdated={lastUpdated}
              liveDashboardName={liveDashboardName}
              changeLang={changeLang}
              selectedWidgets={selectedWidgets}
              redirectToMonitor={redirectToMonitor}
              showEditBtn={showEditBtn}
            />
            <StatisticWidgets
              totalStatisticWidgets={totalStatisticWidgets}
              loadingStatisticWidgets={loadingStatisticWidgets}
            />
            {selectedWidgets?.map((dataSource) => {
              if (
                getSourceName(dataSource?.data_source_id) === "TWITTER" &&
                xPlateformWidgetTypes
              ) {
                return (
                  <XPlateformDataSource
                    getSocialIcon={getSocialIcon}
                    resetAllFields={resetAllFields}
                    response={response}
                    preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                    unixDate={unixDate}
                    singleTweetAnalysis={singleTweetAnalysis}
                    xPlateformWidgetTypes={xPlateformWidgetTypes}
                    setXPlateformWidgetTypes={setXPlateformWidgetTypes}
                    sortWidgets={sortWidgets}
                    getNameKeyWidget={getNameKeyWidget}
                    showEditBtn={showEditBtn}
                    setTotalStatisticWidgets={setTotalStatisticWidgets}
                    setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                  />
                );
              }
              if (
                getSourceName(dataSource?.data_source_id) === "FACEBOOK" &&
                facebookWidgetTypes
              ) {
                return (
                  <FacebookDataSource
                    getSocialIcon={getSocialIcon}
                    resetAllFields={resetAllFields}
                    response={response}
                    preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                    unixDate={unixDate}
                    singleTweetAnalysis={singleTweetAnalysis}
                    facebookWidgetTypes={facebookWidgetTypes}
                    setFacebookWidgetTypes={setFacebookWidgetTypes}
                    sortWidgets={sortWidgets}
                    getNameKeyWidget={getNameKeyWidget}
                    showEditBtn={showEditBtn}
                    setTotalStatisticWidgets={setTotalStatisticWidgets}
                    setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                  />
                );
              }
              if (
                getSourceName(dataSource?.data_source_id) === "INSTAGRAM" &&
                instagramWidgetTypes
              ) {
                return (
                  <InstagramDataSource
                    getSocialIcon={getSocialIcon}
                    resetAllFields={resetAllFields}
                    response={response}
                    preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                    unixDate={unixDate}
                    singleTweetAnalysis={singleTweetAnalysis}
                    instagramWidgetTypes={instagramWidgetTypes}
                    setInstagramWidgetTypes={setInstagramWidgetTypes}
                    sortWidgets={sortWidgets}
                    getNameKeyWidget={getNameKeyWidget}
                    showEditBtn={showEditBtn}
                    setTotalStatisticWidgets={setTotalStatisticWidgets}
                    setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                  />
                );
              }
              if (
                getSourceName(dataSource?.data_source_id) === "TALKWALKER" &&
                newsBlogsWidgetTypes
              ) {
                return (
                  <NewsBlogsDataSource
                    getSocialIcon={getSocialIcon}
                    resetAllFields={resetAllFields}
                    response={response}
                    preLoaderTrackerDataSources={preLoaderTrackerDataSources}
                    unixDate={unixDate}
                    singleTweetAnalysis={singleTweetAnalysis}
                    newsBlogsWidgetTypes={newsBlogsWidgetTypes}
                    setNewsBlogsWidgetTypes={setNewsBlogsWidgetTypes}
                    sortWidgets={sortWidgets}
                    getNameKeyWidget={getNameKeyWidget}
                    showEditBtn={showEditBtn}
                    setTotalStatisticWidgets={setTotalStatisticWidgets}
                    setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                  />
                );
              }
            })}
            {orderFlag ? (
              <SaveOrderContent
                editLiveDashboardHandler={editLiveDashboardHandler}
                isActionLoading={isActionLoading}
                setOrderFlag={setOrderFlag}
              />
            ) : null}
            <PowerLogoContent />
            {success != null ? (
              <SnackBar
                open={success != null ? true : false}
                handleClose={() => {
                  setSuccess(null);
                }}
                severity={success ? "success" : "error"}
                message={CheckValueLocale(snackBarHeading, "", {}, intl)}
              />
            ) : (
              ""
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default LiveDashboard;
