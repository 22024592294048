import Services from "./Services";

var customDashboard = {};

// Get Monitors for selected product Social Listening
customDashboard.getCustomDashboardMonitors = (queryData) => {
  return Services.getData(`api/v3/dashboards/custom/monitors`, queryData);
};

// Get Accounts for selected product Omnichannel
customDashboard.getCustomDashboardAccounts = (queryData) => {
  return Services.getData(`api/v3/dashboards/custom/monitors`, queryData);
};

customDashboard.createNewDashboard = (queryData) => {
  return Services.postData(`api/v3/dashboards`, queryData);
};

// Custom dashboard get sidebar widgets
customDashboard.getSidebarWidgets = (id) => {
  return Services.getData(`api/v3/dashboards/custom/widgets/${id}`);
};

// Custom dashboard get content widgets
customDashboard.getContentWidgets = (id) => {
  return Services.getData(`api/v3/dashboards/custom/edit/widgets_list/${id}`);
};

// Update Custom Dashboard
customDashboard.updateCustomDashboard = (id, queryData) => {
  return Services.putData(`api/v3/dashboards/${id}`, queryData);
};

// Update live Custom Dashboard
customDashboard.updateLiveCustomDashboard = (queryData) => {
  return Services.putData(`api/v3/dashboards/custom/live-log`, queryData);
};

// Custom dashboard View widgets
customDashboard.getCustomDashboardView = (id, queryData) => {
  return Services.getData(`api/v3/dashboards/custom/${id}`, queryData);
};

export default customDashboard;
