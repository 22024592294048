import React, { useEffect, useState } from "react";
import { Button, SwipeableDrawer, Typography, Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { FilterAlt } from "@mui/icons-material";
import FilterDropDownMenu from "./filterDropDownMenu.js";
import {
  CheckValueLocale,
  isEmptyArray,
  handleFiltersParamsIds,
  isEmptyObj,
  handleApplicableFiltersCA,
  getIdsFromFilter,
} from "utils/helpers/index.js";
import { SwipeableDrawerWrapper } from "./filter.styles.js";
import { useDispatch, useSelector } from "react-redux";
import {
  clear,
  filters,
  filtersCount,
  filtersDropDown,
  filtersDropDownToDisplay,
  filtersDropDownCopy,
  filtersDropDownApplyFilter,
  reduxApplicableFilters,
} from "utils/redux/features/ChannelAnalytics/channelAnalyticsSlice.js";
import "shared/channel-analytics-filter/filter.scss";
import HelpIcon from "@mui/icons-material/Help";
import LucButton from "shared/lucButton/lucButton.js";

const Filter = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  //  start redux Declarations
  const reduxFilterParamsCA = useSelector(
    (state) => state?.channelAnalytics?.filterParams,
  );

  const reduxFilterDropDownParams = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownParams,
  );

  const reduxFilterParamsApplyFilter = useSelector(
    (state) => state?.channelAnalytics?.filterDropDownApplyFilter,
  );

  let initialFiltersValue = { ...reduxFilterParamsApplyFilter };

  const reduxFilterParamsCount = useSelector(
    (state) => state?.channelAnalytics?.filterParamsCount,
  );

  //  end redux Declarations

  const [state, setState] = useState({
    right: false,
  });
  const [isDisabled, setDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(reduxFilterParamsCA);
  const [errorMessageKeywords, setErrorMessageKeywords] = useState("");

  const toggleDrawer = (anchor, open, applyFilter) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const commonObject = {};
    const obj = {
      ...props?.filterFields?.by_interactions,
      ...props?.filterFields?.by_user,
    };

    const finalArr = getIdsFromFilter(obj);

    Object.keys(initialFiltersValue).forEach((key) => {
      if (
        Array.isArray(initialFiltersValue[key]) &&
        Array.isArray(finalArr[key])
      ) {
        const commonValues = initialFiltersValue[key].filter((value) => {
          return (
            finalArr[key].findIndex(
              (item) =>
                item?.toString()?.toLowerCase() ===
                value?.toString()?.toLowerCase(),
            ) !== -1
          );
        });
        commonObject[key] = commonValues;
      } else {
        commonObject[key] = [];
      }
    });
    if (!open && (applyFilter === undefined || !applyFilter)) {
      dispatch(filtersDropDown(initialFiltersValue));
      dispatch(filters(initialFiltersValue));
      dispatch(filtersDropDownToDisplay(commonObject));
    }
    setState({ ...state, [anchor]: open });

    if (applyFilter) {
      handleApplyFilter();
      dispatch(filtersCount(calculateFiltersCount(reduxFilterDropDownParams)));
    }
  };

  const handleClear = () => {
    dispatch(clear());
    setErrorMessageKeywords("");
  };

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 5000);
  }, []);

  useEffect(() => {
    if (props.setFilterParamsMainPage) {
      props.setFilterParamsMainPage(filterParams);
    }
  }, [filterParams]);

  const handleApplyFilter = () => {
    let activeFilterParams = reduxFilterDropDownParams;
    let applicableFilters;
    if (!isEmptyObj(props.filterFields)) {
      applicableFilters = handleApplicableFiltersCA(
        props.filterFields,
        reduxFilterDropDownParams,
      );
    }
    dispatch(reduxApplicableFilters(applicableFilters, props.filterFields));
    const commonObject = {};
    const obj = {
      ...props.filterFields?.by_interactions,
      ...props.filterFields?.by_user,
    };

    let finalArr = { ...obj };
    let dmListArr = [];

    obj?.dm_list?.map((dmList) => {
      dmListArr.push(`${dmList?.id}-${dmList?.name}`);
    });

    finalArr = { ...finalArr, dm_list: dmListArr };

    let monitorListArr = [];

    obj?.monitor_list?.map((monitorList) => {
      monitorListArr?.push(`${monitorList?.id}-${monitorList?.name}`);
    });

    finalArr = { ...finalArr, monitor_list: monitorListArr };

    let waitingListArr = [];

    obj?.waiting_filter?.map((waitingList) => {
      waitingListArr?.push(`${waitingList?.name}`);
    });

    finalArr = { ...finalArr, waiting_filter: waitingListArr };

    Object.keys(applicableFilters).forEach((key) => {
      if (
        Array.isArray(applicableFilters[key]) &&
        Array.isArray(finalArr[key])
      ) {
        const commonValues = applicableFilters[key].filter((value) => {
          return (
            finalArr[key].findIndex(
              (item) =>
                item?.toString()?.toLowerCase() ===
                value?.toString()?.toLowerCase(),
            ) !== -1
          );
        });
        commonObject[key] = commonValues;
      } else {
        commonObject[key] = [];
      }
    });
    props.applyFilter(
      props.startDate,
      props.endDate,
      handleFiltersParamsIds(commonObject, props.filterFields),
    );

    dispatch(filtersDropDown(activeFilterParams));
    dispatch(filtersDropDownApplyFilter(activeFilterParams));
    dispatch(filtersDropDownCopy(activeFilterParams));
    dispatch(filtersDropDownToDisplay(commonObject));
    dispatch(
      filters(handleFiltersParamsIds(activeFilterParams, props.filterFields)),
    );
  };

  var filterDirection = window.localStorage.lang !== "ar" ? "right" : "left";

  const calculateFiltersCount = (filterParamsObject) => {
    let filtersParams = filterParamsObject;
    let checkBoxesFalsyValues = 0;

    // checking False Value For checkboxes [hide spam & hid retweet]
    if (filtersParams["hide_spam"] === "False") checkBoxesFalsyValues++;
    if (filtersParams["hide_retweet"] === "False") checkBoxesFalsyValues++;

    let count =
      Object.keys(filtersParams).filter((key) => filtersParams[key]?.length)
        .length - checkBoxesFalsyValues;

    return count;
  };

  let inApplicableFilterContent = !isEmptyArray(props?.inApplicableFiltersArray)
    ? props?.inApplicableFiltersArray?.map((item, index) => {
        return (
          !isEmptyArray(item?.value) && (
            <div className="inapplicable-names" key={index}>
              {`${CheckValueLocale(item?.name, "", {}, intl)}: ${
                Array.isArray(item?.value)
                  ? CheckValueLocale(item?.value?.join(", "), "", {}, intl)
                  : item?.value.replace(",", ", ") ?? null
              }`}
            </div>
          )
        );
      })
    : null;

  return (
    <div id={props?.id ? props?.id : ""}>
      <LucButton
        variant="outline"
        type="secondary"
        onClick={toggleDrawer(filterDirection, true)}
        startIcon={<FilterAlt />}
        loading={props?.isActive === false ? true : isDisabled}
      >
        {CheckValueLocale("filters", "", {}, intl)}

        <span className={"monitor-filter-count"}>
          {reduxFilterParamsCount ? `(${reduxFilterParamsCount})` : ""}
        </span>
      </LucButton>

      <SwipeableDrawerWrapper
        anchor={filterDirection}
        open={state[filterDirection]}
        onClose={toggleDrawer(filterDirection, false)}
        onOpen={toggleDrawer(filterDirection, true)}
      >
        <Box
          sx={{
            width: "300px",
            padding: "20px",
            backgroundColor: "#FCFCFC",
            paddingTop: "30px",
          }}
        >
          {/* FILTER HEADER */}
          <Grid
            container
            columnSpacing={{ xs: 1 }}
            sx={{ marginBottom: "20px", backgroundColor: "#fff" }}
          >
            <Grid item xs={6}>
              <Box className="filter__head">
                <FilterAlt />
                {CheckValueLocale("filters", "", {}, intl)}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "end" }}>
              <LucButton
                onClick={toggleDrawer(filterDirection, false)}
                variant={"outline"}
                size="small"
                type="secondary"
              >
                {CheckValueLocale("hide", "", {}, intl)}
              </LucButton>
            </Grid>
          </Grid>
          {/* FILTER BODY */}
          <Box className="filterbody__wrapper">
            {!isEmptyArray(props?.filterFields) &&
            props?.isChannelAnalyticsGlobalFilters &&
            props?.inApplicableFiltersArrayLength?.filter(Boolean)?.length &&
            !isEmptyArray(props?.inApplicableFiltersArray) ? (
              <Box className="inapplicable-filters-sidemenu">
                <Box className="monitor-inapplicable-filters filter-sidemenu">
                  <Typography variant={"h7"}>
                    <HelpIcon />
                    {CheckValueLocale(
                      "inapplicable_filters",
                      "",
                      {
                        num: props?.inApplicableFiltersArrayLength.filter(
                          Boolean,
                        )?.length,
                      },
                      intl,
                    )}
                  </Typography>
                </Box>
                <div className="inapplicable-items">
                  {inApplicableFilterContent}
                </div>
              </Box>
            ) : null}
            <FilterDropDownMenu
              filterItem={props.filterFields}
              setFilterParams={setFilterParams}
              filterParams={filterParams}
              socialMediaFilter={props.socialMediaFilter}
              showCheckbox={props.showCheckbox}
              id="filter-options-dropdown"
              errorMessageKeywords={errorMessageKeywords}
              setErrorMessageKeywords={setErrorMessageKeywords}
            />
          </Box>

          {/* FILTER FOOTER */}
          <Box className="footer__wrapper">
            <LucButton
              fullWidth={true}
              size="small"
              onClick={toggleDrawer(filterDirection, false, true)}
            >
              {CheckValueLocale("apply", "", {}, intl)}
            </LucButton>
            <LucButton
              fullWidth={true}
              variant="outline"
              size="small"
              type="secondary"
              onClick={handleClear}
            >
              {CheckValueLocale("clear", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </SwipeableDrawerWrapper>
    </div>
  );
};
export default Filter;
