import React, { useEffect, useState } from "react";
import BarChartWidget from "components/widgets/barChartWidget";
import moment from "moment";
import {
  faClock,
  faPercent,
  faQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import { commasAfterDigit } from "utils/helpers";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";

const CustomDashboardBarChart = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);
  const [dataPiechart, setDataPieChart] = useState([]);
  const [statsDetails, setStatsDetails] = useState([]);
  const [widgetFlags, setWidgetFlags] = useState({
    showGroupBy: true,
  });

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "comments_customer_care_performance":
        case "customer_care_performance":
          let customerCareData =
            dataState?.["customer_care_performance"] || dataState;

          if (customerCareData?.response_time_segment) {
            Object.keys(customerCareData?.response_time_segment).map(
              (val, index) => {
                setData((oldArray) => [
                  ...oldArray,
                  {
                    name: val,
                    value: Object.values(
                      customerCareData?.response_time_segment,
                    )[index],
                  },
                ]);
              },
            );
          }
          setStatsDetails([
            {
              name: "total_ques",
              value:
                customerCareData?.total_nummber_of_questions &&
                commasAfterDigit(customerCareData?.total_nummber_of_questions),
              icon: faQuestion,
              titleToolTip: "number_of_ques_tooltip",
            },
            {
              name: "number_of_answered_questions",
              value: isNaN(
                (customerCareData?.number_of_answered_questions /
                  customerCareData?.total_nummber_of_questions) *
                  100,
              )
                ? 0 + "%"
                : (
                    customerCareData?.number_of_answered_questions /
                    customerCareData?.total_nummber_of_questions
                  ).toFixed(2) *
                    100 +
                  "%",
              icon: faPercent,
              titleToolTip: "response_rate_tooltip",
            },
            {
              name: "avg_response_time_in_seconds",
              value: +customerCareData?.avg_response_time_in_seconds,
              icon: faClock,
              type: "time",
              titleToolTip: "avg_response_time_tooltip",
            },
          ]);
          setWidgetFlags({
            showAI: true,
            chartStatsWidget: true,
            customerCareChart: true,
          });
          break;

        case "followers_growth":
        case "posts_followers_growth":
          setData(dataState?.followers_change_over_time);
          setDataPieChart(dataState?.followers_change_over_time);
          setStatsDetails([
            {
              name: "total_followers",
              value: dataState?.total_followers?.[0],
            },
            {
              name: "max_change_of_followers",
              value: dataState?.max_change_of_followers,
            },
            {
              name: "avg_change_per_day",
              value: dataState?.avg_chage_of_day,
            },
          ]);
          setWidgetFlags({ gridHeight: "45%" });
          break;

        case "follower_growth_overtime":
          setData(dataState?.followers_growth_overtime);
          setDataPieChart(dataState?.followers_growth_overtime);
          setStatsDetails([
            {
              name: "total_followers",
              value: dataState?.total_followers?.[0],
            },
            {
              name: "max_change_of_followers",
              value: dataState?.max_change_of_followers,
            },
            {
              name: "avg_change_per_day",
              value: dataState?.avg_chage_of_day,
            },
          ]);
          setWidgetFlags({ gridHeight: "45%" });
          break;

        case "average_engagements_per_post":
        case "posts_average_engagement_per_posts":
          setData(
            dataState?.["posts_interactions"]?.posts_engagements_over_time,
          );
          setDataPieChart(
            dataState?.["posts_interactions"]?.posts_engagements_over_time,
          );
          setStatsDetails([
            {
              name: "max_interactions_per_post",
              value: dataState?.["posts_interactions"]?.max_engagements,
              date: moment
                ?.unix(dataState?.["posts_interactions"]?.max_engagement_date)
                ?.format("D-M-YYYY"),
            },
            {
              name: "min_interactions_per_post",
              value: dataState?.["posts_interactions"]?.min_engagements,
              date: moment
                ?.unix(dataState?.["posts_interactions"]?.min_engagement_date)
                ?.format("D-M-YYYY"),
            },
            {
              name: "avg_interaction_per_post",
              value: dataState?.["posts_interactions"]?.average_interactions,
            },
          ]);
          setWidgetFlags({});
          break;

        default:
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  return (
    <BarChartWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      titleToolTip={titleTooltip || ""}
      data={data || []}
      showChartDetailsWidget={
        widget?.widget_name !== "customer_care_performance" && true
      }
      statsDetailsData={
        (widget?.widget_name !== "customer_care_performance" && statsDetails) ||
        []
      }
      dataColor="#2AAAE2"
      toolTipLabel={"posts"}
      showZoomPercentage
      showGroupBy={widgetFlags?.showGroupBy}
      showAI={widgetFlags?.showAI}
      showChartStatsWidget={widgetFlags?.chartStatsWidget}
      customerCareChart={widgetFlags?.customerCareChart}
      respTimeDistributionTooltip="respone_time_distribution_tooltip"
      gridHeight={widgetFlags?.gridHeight || "70%"}
      statsData={statsDetails || []}
      showFollowersGrowthStats
      showDownloadIcon
      bigImg
      isTwitterAccount={
        widget?.monitor_type?.name === "ACCOUNT_ANALYSIS" && true
      }
      barChartPreLoaderDataSources={
        socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
          monitorId
        ]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ] || []
      }
      preLoaderTrackerDataSources={[monitorId] || []}
      isCustomDashboard
      monitorData={{
        name:
          dashboardInfo?.product === "cxm"
            ? widget?.account_identifier
            : widget?.monitor_name,
        type:
          dashboardInfo?.product === "cxm"
            ? widget?.data_category
            : widget?.monitor_type?.name,
        datasource: widget?.is_dm
          ? `${widget?.data_source}_private`
          : widget?.data_source,
      }}
      showLegend={false}
    />
  );
};

export default CustomDashboardBarChart;
