import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers/index";
import { InfoOutlined } from "@mui/icons-material";
import { LucidyaApisContext } from "../lucidyaApisContext";

const GeneratedApi = ({ apikeyResponse }) => {
  const intl = useIntl();
  const { handleCreateApiDialog, apiDialogType, handleApiActionsDialog } =
    useContext(LucidyaApisContext);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(apikeyResponse?.token);
    setTimeout(() => {
      setCopied(false);
    }, "10000");
  };

  const handleReneratedClose = () =>
    apiDialogType === "createApiKey"
      ? handleCreateApiDialog()
      : handleApiActionsDialog();

  return (
    <>
      <DialogContent className="create-api-content">
        <p className="generate-api-title">
          <span>
            {CheckValueLocale(
              apiDialogType === "createApiKey"
                ? "generate_api_title"
                : "regenerate_api_title",
              "",
              {},
              intl,
            )}
          </span>
          {CheckValueLocale("copy_and_securely", "", {}, intl)}
          {CheckValueLocale("generate_api_description", "", {}, intl)}
        </p>
        <InputLabel id="api-name-label" className="create-api-input-label">
          {CheckValueLocale("api_key", "", {}, intl)}
          <span className="span-require"> * </span>
        </InputLabel>
        <OutlinedInput
          labelId="api-name-label"
          id="api-name"
          variant="outlined"
          className="create-api-input"
          value={apikeyResponse?.token}
          readOnly
          endAdornment={
            <Tooltip
              title={
                copied
                  ? CheckValueLocale("copied", "", {}, intl)
                  : CheckValueLocale("copy", "", {}, intl)
              }
              placement="top"
              arrow
            >
              <Button
                variant="text"
                onClick={handleCopy}
                className="key-copy-btn"
                id="key-copy-btn"
              >
                <FontAwesomeIcon icon={faCopy} />
              </Button>
            </Tooltip>
          }
        />
        <p className="create-api-input-hint">
          <InfoOutlined className="input-hint-icon" />
          <span>
            {CheckValueLocale("for_more_how_to_use_read_our", "", {}, intl)}{" "}
            <a
              href="https://docs.lucidya.com/"
              target="_blank"
              rel="noreferrer"
              className="api-documentation-link"
            >
              {CheckValueLocale("api_documentation", "", {}, intl)}
            </a>
          </span>
        </p>
      </DialogContent>
      <DialogActions className="create-api-actions">
        <Button
          variant="text"
          onClick={handleReneratedClose}
          className="create-api-cancel"
          id="create-api-cancel-btn"
        >
          {CheckValueLocale("cancel", "", {}, intl)}
        </Button>
        <Button
          variant="contained"
          onClick={handleReneratedClose}
          disabled={false}
          className="lucidya-apis-button"
          id="create-api-generate-btn"
        >
          {CheckValueLocale("got_it_btn", "", {}, intl)}
        </Button>
      </DialogActions>
    </>
  );
};

export default GeneratedApi;
