import Services from "./Services";

var Interactions = {};

Interactions.getDatasources = () => {
  return Services.getData("api/v3/datasources");
};
Interactions.getSentiments = () => {
  return Services.getData("api/v3/sentiments");
};
Interactions.getSubThemes = () => {
  return Services.getData("api/v3/sub_themes");
};
Interactions.getThemes = () => {
  return Services.getData("api/v3/themes");
};
Interactions.getInteractionsCredentials = (queryData) => {
  return Services.getData("api/v3/channel_analytics/interactions", queryData);
};
Interactions.getSingleInteraction = (queryData, apiVars) => {
  return Services.getData(
    "api/v3/channel_analytics/" + apiVars + "/single/interaction",
    queryData,
  );
};
Interactions.updateSingleInteractionThemes = (queryData, apiVars) => {
  return Services.putData(
    "api/v3/channel_analytics/" +
      apiVars +
      "/interactions/edit_themes_interactions",
    queryData,
  );
};
Interactions.updateSingleInteractionSubThemes = (queryData, apiVars) => {
  return Services.putData(
    "api/v3/channel_analytics/" +
      apiVars +
      "/interactions/edit_sub_themes_interactions",
    queryData,
  );
};
Interactions.updateSingleInteractionSentiment = (queryData, apiVars) => {
  return Services.putData(
    "api/v3/channel_analytics/" +
      apiVars +
      "/interactions/edit_sentiment_interactions",
    queryData,
  );
};
//whatsapp Interactions
Interactions.getInteractionsWhatsCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/whatsapp/interactions",
    queryData,
  );
};
//intercom Interactions
Interactions.getInteractionsIntercomCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/intercom/interactions",
    queryData,
  );
};
//twitter public Interactions
Interactions.getInteractionsTwitterPublicCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/twitter/public/interactions",
    queryData,
  );
};
//twitter private Interactions
Interactions.getInteractionsTwitterPrivateCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/twitter/private/interactions",
    queryData,
  );
};
//facebook Public Interactions
Interactions.getInteractionsFacebookPublicCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/facebook/public/interactions",
    queryData,
  );
};
// Facebook Private Inetractions
Interactions.getInteractionsFacebookPrivateCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/facebook/private/interactions",
    queryData,
  );
};
//instagram public Interactions
Interactions.getInteractionsInstagramPublicCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/instagram/public/interactions",
    queryData,
  );
};

//instagram private Interactions
Interactions.getInteractionsInstagramPrivateCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/instagram/private/interactions",
    queryData,
  );
};
//linkedin Interactions
Interactions.getInteractionsLinkedInCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/linkedin/public/interactions",
    queryData,
  );
};
//gmail Interactions
Interactions.getInteractionsGmailCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/email/gmail/interactions",
    queryData,
  );
};
//social media interactions
Interactions.getInteractionsSocialCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/interactions",
    queryData,
  );
};

// chat interactions
Interactions.getInteractionsChatCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/interactions",
    queryData,
  );
};

//gmb Interactions
Interactions.getInteractionsGMBCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/ratings/gmb/interactions",
    queryData,
  );
};
Interactions.getGenesysInteraction = (queryData) => {
  return Services.getData(
    "/api/v3/channel_analytics/calls/genesys/interactions",
    queryData,
  );
};

Interactions.getInteractionsTiktokPublicCredentials = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/social_media/tiktok/public/interactions",
    queryData,
  );
};
export default Interactions;
