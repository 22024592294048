//this widget already used for MONITOR only , as it has one case for punchCard
import PunchCard from "../echarts/punchCard-monitor";
import WidgetHeader from "components/widgets/widgetHeader";
import _ from "lodash";
import { Box } from "@mui/material";
import FetchingData from "components/fetchingData/fetchingData";
import TwitterIcon from "@mui/icons-material/Twitter";
import NoDataFound from "components/no-Data/noDataFound";

const PunchCardWidget = (props) => {
  return (
    <div className="chart" id={props.title}>
      <WidgetHeader
        title={props.title}
        titleToolTip={props.titleToolTip}
        showDownloadIcon={props.showDownloadIcon}
        chartId={props.title}
        bigImg={props.bigImg}
        channelActivityUpdated={props.clientActivity}
        iconAfterTitle={props.iconAfterTitle}
        showNotAppliedFilter={props?.showNotAppliedFilter}
        widgetSection={props?.widgetSection}
        isCustomDashboard={props?.isCustomDashboard}
        monitorData={props?.monitorData}
      />
      {_.isEqual(
        props?.authorActivityPreLoaderDataSources?.sort(),
        props?.preLoaderTrackerDataSources?.sort(),
      ) !== true ? (
        <>
          <FetchingData />
        </>
      ) : props?.clientActivity?.every((row) => row[2] == 0) ? (
        <>
          <NoDataFound />
        </>
      ) : (
        <PunchCard
          clientActivity={props.clientActivity}
          minValueClient={props?.minValueClient}
          maxValueClient={props?.maxValueClient}
        />
      )}
    </div>
  );
};

export default PunchCardWidget;
