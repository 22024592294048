import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellPlus } from "@fortawesome/pro-regular-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import AlertFilter from "./AlertFilter";
import "./alert-header.scss";
import LucButton from "shared/lucButton/lucButton";
import PageHeader from "components/pageHeader";
import CustomizedTooltip from "components/customizedTooltip";

const AlertHeader = (props) => {
  const intl = useIntl();
  const {
    handleOpenForm,
    resetFormData,
    getAlertData,
    getComanyEmails,
    isMonitorAction,
  } = props;

  const handleCreateAlert = () => {
    resetFormData();
    handleOpenForm("addAlert");
    getAlertData();
    getComanyEmails();
  };

  return (
    <>
      {isMonitorAction ? (
        <CustomizedTooltip
          arrow
          placement="bottom"
          title={
            <Box className="tooltip-custom-new">
              {CheckValueLocale("create_alert_button", "", {}, intl)}
            </Box>
          }
        >
          <Box>
            <LucButton
              id="create-alert-monitor-header-btn"
              className="monitor-header-action-btn"
              type="secondary"
              variant="flat"
              minWidth={42}
              onClick={handleCreateAlert}
            >
              <FontAwesomeIcon icon={faBellPlus} size="lg" />
            </LucButton>
          </Box>
        </CustomizedTooltip>
      ) : (
        <>
          <PageHeader title="alerts" />
          <Box className="main-header">
            <AlertFilter {...props} />
            <LucButton
              startIcon={<AddIcon />}
              onClick={handleCreateAlert}
              id="alert-create-new-alert-btn"
            >
              {CheckValueLocale("new_alert", "", {}, intl)}
            </LucButton>
          </Box>
        </>
      )}
    </>
  );
};

export default AlertHeader;
