import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import VideoFrame from "react-video-thumbnail";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useIntl } from "react-intl";
import {
  TextField,
  Breadcrumbs,
  Link,
  Box,
  MenuItem,
  Select,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  Popover,
  Button,
  Tooltip,
} from "@mui/material";
import "../../../Monitors/Components/replyModal/replyModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  faPaperPlane,
  faImage,
  faVideo,
  faSpinner,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import gifSvg from "images/gif-icon.svg";
import {
  CheckValueLocale,
  getNumberOfChar,
  handlelUserRoles,
} from "utils/helpers";
import MonitorsController from "services/controllers/monitorsController";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DefaultAccountImg from "../../../Monitors/Components/replyModal/defaultAccountImg";
import SavedRepliesModal from "../../../Monitors/Components/replyModal/savedRepliesModal";
import CreateRepliesModal from "../../../Monitors/Components/replyModal/createRepliesModal";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar.js";
import { useDispatch } from "react-redux";
import { cardData } from "utils/redux/features/Engagements/EngagementsSlice.js";
import { sanitize } from "dompurify";

const ReplyModal = (props) => {
  const intl = useIntl();
  const urlParams = useParams();
  const history = useHistory();
  const inputRefGif = useRef(null);
  const inputRefVideo = useRef(null);
  const inputRefImg = useRef(null);
  const dispatch = useDispatch();

  let channelPath = history?.location?.pathname;
  let dataSource = channelPath?.includes("facebook-public")
    ? "FACEBOOK"
    : channelPath?.includes("instagram-public") ||
        channelPath?.includes("instagram-private")
      ? "INSTAGRAM"
      : channelPath?.includes("facebook-private")
        ? "FACEBOOK"
        : channelPath?.includes("x-platform-private")
          ? "TWITTER"
          : "TWITTER";

  const { id, tracker_id } = urlParams;
  const {
    replyData,
    showSuccessModal,
    setShowSuccessModal,
    replyModalData,
    setShowErrorSnackBar,
    setText,
    text,
    setReplyModalData,
    setConversationMessages,
    conversationMessages,
    isPrivateInteraction,
  } = props;
  const repliedDate = replyData?.system_replies?.created_at;
  const replyBy = replyData?.system_replies?.replier_info?.email;
  const clientId = replyData?.client_id;
  // AgentId with FB DM
  const agentIdWithFBDM = replyData?.agent_id;

  // UserId with TW DM
  const userIdWithTWDM = replyData?.agent_username;

  const [maxChar, setMaxChar] = useState(280);
  const [loading, setLoading] = useState(false);
  const [savedRepliesloader, setSavedRepliesLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [accountsImg, setAccountsImg] = useState([]);
  const [accountsPageIds, setAccountsPageIds] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [selectedAccountImg, setSelectedAccountImg] = useState("");
  const [accountsIds, setAccountsIds] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [iconToDisplay, setIconToDisplay] = useState(null);
  const [accountsStatus, setAccountsStatus] = useState([]);
  const [outboundDMData, setOutboundDMData] = useState([]);
  const [showCreateRepliesModal, setShowCreateRepliesModal] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [replyToDelete, setReplyToDelete] = useState("");
  const [savedReplies, setSavedReplies] = useState([]);
  const [savedReplyIds, setSavedReplyIds] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarSeverity, setSnackbarSeverity] = useState("");
  const [snackBarMessage, setSnackbarMessage] = useState("");
  const [snackBarTitle, setSnackbarTitle] = useState("");
  const [injectedWord, setInjectedWord] = useState({});
  const [selectedFile, setSelectedFile] = useState([]);
  const [base64Code, setBase64Code] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [mediaErr, setMediaErr] = useState("");
  const [getAllAccounts, setGetAllAccounts] = useState([]);
  const [allawedAccounts, setAllawedAccounts] = useState([]);

  const isImageMimeType = (mimeType) => {
    return /^image\/\*/.test(mimeType);
  };

  const handleCloseSnack = () => {
    setShowSnackBar(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowCreateRepliesModal(false);
  };
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleAttachmentsUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (Math.round(file?.size / (1024 * 1024)) > 10) {
        setMediaErr("size_media_err");
      } else if (
        event.target.id === "gif-input" &&
        file?.type !== "image/gif"
      ) {
        setMediaErr("gif_media_err");
      } else if (
        event.target.id === "video-input" &&
        file?.type !== "video/mp4"
      ) {
        setMediaErr("vid_media_err");
      } else if (
        event.target.id === "image-input" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpeg"
      ) {
        setMediaErr("img_media_err");
      } else {
        setMediaErr("");
        const reader = new FileReader();
        reader.onload = () => {
          setBase64Code([...base64Code, reader.result]);
        };
        reader.readAsDataURL(file);
        setSelectedFile([...selectedFile, file]);
      }
      const formData = new FormData();

      formData.append("upload_file", file);
      formData.append("publisher_name", selectedValue);
      formData.append("account_info_id", selectedAccountId);
      // if (dataSource !== "TWITTER") formData.append("page_id", selectedPageId);
      formData.append("monitor_id", parseInt(tracker_id));
      formData.append("data_source_name", dataSource);
      formData.append("product_id", window?.localStorage?.cxm_id);
      formData.append("media_type", file?.type);
      //stg env
      //https://engupload.lucidya.com/upload
      try {
        setSendBtnDisabled(true);
        const response = await axios.post(
          "https://eng-upload.prod.lucidya.com/upload",
          formData,
          {
            headers: {
              "luc-authorization": window.localStorage.user_token,
            },
          },
        );
        setAttachments([...attachments, response?.data]);
        setSendBtnDisabled(false);
      } catch (error) {
        setSendBtnDisabled(false);
      }
    }
  };

  const handleRemoveAttachments = (index) => {
    const attachments = [...selectedFile];
    attachments.splice(index, 1);
    setSelectedFile(attachments);

    const base64 = [...base64Code];
    base64.splice(index, 1);
    setBase64Code(base64);

    const attachment = [...attachments];
    attachment.splice(index, 1);
    setAttachments(attachment);

    if (inputRefGif?.current) {
      inputRefGif.current.value = null;
    }
    if (inputRefVideo?.current) {
      inputRefVideo.current.value = null;
    }
    if (inputRefImg?.current) {
      inputRefImg.current.value = null;
    }
  };

  const handleChangeDropdown = (event) => {
    setSelectedValue(event.target.value);
    setReplyModalData({
      ...replyModalData,
      selectedValue: event.target.value,
    });
  };

  useEffect(() => {
    if (
      !showSuccessModal &&
      (replyData?.system_replies?.is_replied === undefined ||
        replyData?.system_replies?.is_replied === 0)
    ) {
      let accountsData = [...accounts];
      let accountsProfileImg = [...accountsImg];
      let accountInfoId = [...accountsIds];
      let accountStatus = [...accountsStatus];
      let accountPageIds = [...accountsPageIds];
      let newDataAccounts = [];

      setLoading(true);
      MonitorsController.getAllawedAccounts(
        window?.localStorage?.cxm_id,
        "reply",
      ).then((AllawedData) => {
        let allawedAccountArr =
          AllawedData?.data?.data?.user_accounts?.[dataSource];
        MonitorsController.getAllAccounts(window?.localStorage?.cxm_id)
          .then((data) => {
            if (data?.data?.status === 200) {
              let dropdownData = data?.data;
              //public , private page [ Facebook - Instagram]
              if (
                channelPath?.includes("facebook-public") ||
                channelPath?.includes("instagram-public") ||
                channelPath?.includes("facebook-private")
              ) {
                dropdownData?.data[dataSource]?.included?.map((accountData) => {
                  if (allawedAccountArr?.includes(+accountData?.id)) {
                    if (channelPath?.includes("instagram-public"))
                      accountsData?.push(accountData?.attributes?.user_name);
                    else accountsData?.push(accountData?.attributes?.name);
                    accountsProfileImg?.push(
                      accountData?.attributes?.image_url,
                    );
                    accountInfoId?.push(
                      accountData?.attributes?.account_info_id,
                    );
                    accountStatus?.push(accountData?.attributes?.status);
                    accountPageIds?.push(accountData?.attributes?.page_id);
                  }
                  newDataAccounts.push(accountData);
                });
              }
              // public , private page [ Twitter ]
              else {
                dropdownData?.data[dataSource]?.data?.map((accountData) => {
                  if (allawedAccountArr?.includes(+accountData?.id)) {
                    accountsData?.push(accountData?.attributes?.user_name);
                    accountsProfileImg?.push(
                      accountData?.attributes?.image_url,
                    );
                    accountInfoId?.push(accountData?.attributes?.id);
                    accountStatus?.push(accountData?.attributes?.status);
                  }
                  newDataAccounts.push(accountData);
                });
                if (channelPath?.includes("instagram-private")) {
                  dropdownData?.data[dataSource]?.included?.map(
                    (accountData) => {
                      accountPageIds?.push(
                        accountData?.attributes?.instagram_id,
                      );
                    },
                  );
                }
              }
              let allawedArr = [];
              newDataAccounts?.map((item) => {
                if (allawedAccountArr?.includes(+item?.id)) {
                  allawedArr.push(+item?.id);
                }
              });
              setAllawedAccounts(allawedArr);
              setGetAllAccounts([...newDataAccounts]);
              setAccounts(accountsData);
              setAccountsImg(accountsProfileImg);
              setAccountsIds(accountInfoId);
              setAccountsStatus(accountStatus);
              setAccountsPageIds(accountPageIds);
              setLoading(false);
            } else {
              setShowErrorSnackBar(true);
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      });
    }
  }, []);

  const getAllSavedReplies = () => {
    setSavedRepliesLoader(true);
    MonitorsController.getAllSavedReplies(window?.localStorage?.cxm_id)
      .then((data) => {
        setSavedReplies(data?.data?.data);
        setSavedRepliesLoader(false);
      })
      .catch((err) => {
        setSavedRepliesLoader(false);
        setAnchorEl(null);
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("please_try_again");
        setSnackbarTitle("wrong_request_parameter");
      });
  };

  useEffect(() => {
    const index = accounts?.indexOf(selectedValue);
    if (index !== -1) {
      setSelectedAccountImg(accountsImg[index]);
      setSelectedAccountId(accountsIds[index]);
      setSelectedPageId(accountsPageIds[index]);
    }
  }, [selectedValue]);

  const handleReply = () => {
    if (
      (text?.trim()?.length === 0 ||
        selectedValue === "" ||
        text?.length > maxChar) &&
      !base64Code?.length
    )
      return;
    else {
      setLoading(true);
      MonitorsController.postReplies(
        window?.localStorage?.cxm_id,
        selectedAccountId,
        selectedValue,
        dataSource,
        parseInt(tracker_id),
        id,
        "reply",
        text?.trimStart(),
        isPrivateInteraction ? clientId : undefined,
        attachments,
      ).then((data) => {
        if (data?.data?.status === 201) {
          setShowSuccessModal(true);
          setShowErrorModal(false);
          setReplyModalData({
            selectedValue: replyModalData?.selectedValue,
            replyTime: moment
              .utc()
              .add(parseInt(localStorage.getItem("companyInfo")), "hours")
              .format("DD/MM/YYYY - hh:mm A"),
          });
          const futureDate = moment
            .utc()
            .add(parseInt(localStorage.getItem("companyInfo")), "hours")
            .format("DD/MM/YYYY - hh:mm A");

          setText("");
        } else {
          setShowErrorModal(true);
          setShowSuccessModal(false);
        }
        setLoading(false);
      });
    }
  };
  const handleRedirectToEngagement = () => {
    dispatch(cardData(replyData));
    history.push("/engagements/main");
  };

  const successModal = () => (
    <Box className="display-in-row">
      <Box className="replied-success-modal replied-success-modal-ca">
        <FontAwesomeIcon icon={faCheckCircle} />
        <span>{CheckValueLocale("replied_success", "", {}, intl)},</span>
      </Box>
      <Box className="success-modal-description success-modal-description-ca">
        <span>{CheckValueLocale("by", "", {}, intl)}</span>
        <div>{replyBy ?? localStorage.getItem("email")}</div>
      </Box>
      <Box className="separator-style">-</Box>
      <Box className="success-modal-description success-modal-date">
        <span>{CheckValueLocale("reply_date", "", {}, intl)}</span>
        <div>
          {repliedDate
            ? moment?.unix(repliedDate).utc()?.format("DD/MM/YYYY [at] hh:mm A")
            : replyModalData?.replyTime}
        </div>
      </Box>
      {(channelPath?.includes("x-platform-private") ||
        channelPath?.includes("x-platform-public")) &&
      (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
        handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")) ? (
        <Box
          className="view-engagement-details"
          onClick={handleRedirectToEngagement}
        >
          {CheckValueLocale("view_engagement_details", "", {}, intl)}
        </Box>
      ) : null}
    </Box>
  );

  const errorModal = () => (
    <Box className="display-in-row">
      <Box className="replied-error-modal replied-error-modal-ca">
        <CancelIcon />
        <span>{CheckValueLocale("wrong_request_parameter", "", {}, intl)}</span>
      </Box>
      <Box className="error-modal-description error-modal-description-ca">
        <span>{CheckValueLocale("please", "", {}, intl)}</span>
        <div onClick={() => setShowErrorModal(false)}>
          {CheckValueLocale("reply_try_again", "", {}, intl)}
        </div>
      </Box>
    </Box>
  );

  const deleteModalBody = () => {
    return (
      <Box className="delete-modal-content">
        <Box>
          {CheckValueLocale("delete_saved_replies_body", "", {}, intl)}
          <span>{replyToDelete?.attributes?.title}</span>
        </Box>
        <Box>
          {CheckValueLocale("delete_saved_replies_description", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  const unAuthenticatedAccountModal = () => (
    <Box className="display-in-row">
      <Box className="unauthenticated-accounts-modal">
        <ErrorOutlineOutlinedIcon />
        <span>
          {CheckValueLocale("all_accounts_unauthenticated", "", {}, intl)}
        </span>
      </Box>
      <Box
        className="companies-settings-style"
        onClick={() => history.push("/settings")}
      >
        {CheckValueLocale("replies_company_settings", "", {}, intl)}
      </Box>
    </Box>
  );

  const handleCloseDeleteModal = () => {
    setShowDeletePopup(false);
  };

  const handleDeleteSavedReplies = () => {
    setInjectedWord({});
    MonitorsController.deleteSavedReplies(
      replyToDelete?.attributes?.id,
      window?.localStorage?.engagements_id,
    )
      .then((data) => {
        getAllSavedReplies();
        setShowSnackBar(true);
        setSnackbarSeverity("success");
        setSnackbarTitle("saved_replies_deleted_successfully");
        setInjectedWord({ reply: replyToDelete?.attributes?.title });
        setShowDeletePopup(false);
      })
      .catch((error) => {
        setShowSnackBar(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("please_try_again");
        setSnackbarTitle("wrong_request_parameter");
        setShowDeletePopup(false);
      });
  };

  useEffect(() => {
    setIconToDisplay(null);
    (async () => {
      const image = new Image();
      image.src = selectedAccountImg;
      image.onload = () => {
        setIconToDisplay(selectedAccountImg);
      };
    })();
  }, [selectedAccountImg]);

  const showSuccessCondition =
    showSuccessModal || replyData?.system_replies?.is_replied === 1;

  const mainModalCondition =
    !showSuccessModal &&
    !showErrorModal &&
    ((accounts?.length > 0 && accountsStatus?.includes("active")) ||
      (getAllAccounts?.length && !allawedAccounts?.length)) &&
    (replyData?.system_replies?.is_replied === undefined ||
      replyData?.system_replies?.is_replied === 0)
      ? "reply-modal reply-modal-channel-analytics"
      : "reply-modal reply-modal-msg";

  const anchorPosition =
    intl.locale === "en"
      ? {
          vertical: "top",
          horizontal: "right",
        }
      : {
          vertical: "top",
          horizontal: "left",
        };

  const dropdownStyle =
    selectedValue === "" ? "reply-modal-dropdown" : "reply-modal-dropdown-icon";

  const breadCrumbsStyle =
    text?.length > maxChar ? "text-reached-limit" : "reply-modal-breadcrumbs";

  const transformPosition =
    intl.locale === "en"
      ? {
          vertical: "bottom",
          horizontal: "right",
        }
      : {
          vertical: "bottom",
          horizontal: "left",
        };

  const disabledCondition = selectedValue === "";
  const disabledStyle = disabledCondition ? "disabled-icons" : "modal-icons";
  const disabledStyleFirst = disabledCondition
    ? "disabled-icons"
    : "first-icon";

  return (
    <Box className={mainModalCondition}>
      {loading ? (
        <div className="loading-style">
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>
      ) : !showSuccessModal &&
        !showErrorModal &&
        ((accounts?.length > 0 && accountsStatus?.includes("active")) ||
          (getAllAccounts?.length && !allawedAccounts?.length)) &&
        (replyData?.system_replies?.is_replied === undefined ||
          replyData?.system_replies?.is_replied === 0) ? (
        <>
          <Box className={dropdownStyle}>
            {selectedValue !== "" && (
              <List>
                <ListItem sx={{ padding: "12px 10px" }}>
                  <ListItemAvatar className="account-avtar">
                    {iconToDisplay !== null ? (
                      <Avatar src={selectedAccountImg} variant="rounded" />
                    ) : (
                      <div className="account-default-img">
                        <DefaultAccountImg selectedValue={selectedValue} />
                      </div>
                    )}
                  </ListItemAvatar>
                </ListItem>
              </List>
            )}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedValue}
              onChange={handleChangeDropdown}
              displayEmpty
              IconComponent={ArrowDropDownRoundedIcon}
              MenuProps={
                !accounts?.length &&
                (!getAllAccounts?.length || !allawedAccounts?.length)
                  ? {
                      style: {
                        left: intl?.locale == "en" ? "85px" : "-100px",
                        right: intl?.locale == "ar" ? "85px" : "0px",
                      },
                    }
                  : {}
              }
            >
              <MenuItem disabled value="" style={{ display: "none" }}>
                <em>{CheckValueLocale("select_account", "", {}, intl)}</em>
              </MenuItem>
              {accounts?.length ? (
                accounts?.map((account, i) => {
                  let option = getNumberOfChar(account, intl);
                  return (
                    <MenuItem
                      value={account}
                      disabled={
                        accountsStatus[i] !== "active" ||
                        (channelPath?.includes("facebook-private") &&
                          agentIdWithFBDM !== accountsPageIds[i]) ||
                        // i add [@] cause accounts get with previous @
                        (`@${userIdWithTWDM}` !== accounts[i] &&
                          channelPath?.includes("x-platform-private")) ||
                        (channelPath?.includes("instagram-private") &&
                          agentIdWithFBDM !== accountsPageIds[i])
                      }
                    >
                      {option}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem disabled value={null}>
                  <Box
                    id={"empty-reply-accounts"}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        CheckValueLocale(
                          !getAllAccounts?.length
                            ? "no_accounts"
                            : !allawedAccounts?.length
                              ? "no_permission"
                              : "no_accounts",
                          "",
                          {},
                          intl,
                        ),
                      ),
                    }}
                  />
                </MenuItem>
              )}
            </Select>
          </Box>
          <div className="textfield-container">
            <div
              className={
                selectedFile?.length > 0 ? "textfield-with-attachments" : ""
              }
            >
              <TextField
                value={text}
                onChange={handleChange}
                multiline
                maxRows={6}
                variant="outlined"
                placeholder={CheckValueLocale("enter_reply", "", {}, intl)}
                className="reply-modal-textarea-ca"
                error={text?.length > maxChar}
              />
            </div>
            <div className="attachments-with-textfield">
              {base64Code?.length !== 0 &&
                base64Code?.map((base64, index) => {
                  return (
                    <div className="attachments-container">
                      {selectedFile[index]?.type === "image/jpeg" ||
                      selectedFile[index]?.type === ".gif" ? (
                        <div className="attachments-style">
                          <img src={base64} alt="Uploaded" />
                        </div>
                      ) : (
                        <VideoFrame
                          videoUrl={base64}
                          thumbnailHandler={(thumbnail) => (
                            <img
                              src={thumbnail}
                              alt="Video Thumbnail"
                              style={{ maxWidth: "100%" }}
                            />
                          )}
                          width={33}
                          height={33}
                        />
                      )}
                      <CancelRoundedIcon
                        className="remove-attachments"
                        onClick={() => handleRemoveAttachments(index)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          {mediaErr ? (
            <span className="media-err">
              {CheckValueLocale(mediaErr, "", {}, intl)}
            </span>
          ) : null}
          <Box className="single-intr-wrapper reply-modal-footer">
            <Breadcrumbs aria-label="breadcrumb" className={breadCrumbsStyle}>
              <Link color="inherit">
                {text?.trim()?.length === 0 ? 0 : text?.trimStart()?.length}
              </Link>
              <Link color="inherit">{maxChar}</Link>
            </Breadcrumbs>
            <div className="display-in-row">
              <label className="saved-replied-btn" onClick={handleClick}>
                {CheckValueLocale("saved_replies", "", {}, intl)}
              </label>
              <Divider
                orientation="vertical"
                className="saved-replied-divider"
              />
              <Popover
                anchorOrigin={anchorPosition}
                transformOrigin={transformPosition}
                onClose={handleClose}
                open={open}
                anchorEl={anchorEl}
                className="saved-replies-popover"
              >
                {!showCreateRepliesModal ? (
                  <SavedRepliesModal
                    setText={setText}
                    setAnchorEl={setAnchorEl}
                    getAllSavedReplies={getAllSavedReplies}
                    savedReplies={savedReplies}
                    savedRepliesloader={savedRepliesloader}
                    setSavedReplyIds={setSavedReplyIds}
                    setReplyToDelete={setReplyToDelete}
                    setShowDeletePopup={setShowDeletePopup}
                    setShowCreateRepliesModal={setShowCreateRepliesModal}
                  />
                ) : (
                  <CreateRepliesModal
                    savedReplies={savedReplies}
                    setSavedReplies={setSavedReplies}
                    setShowSnackBar={setShowSnackBar}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarTitle={setSnackbarTitle}
                    setSnackbarSeverity={setSnackbarSeverity}
                    setShowCreateRepliesModal={setShowCreateRepliesModal}
                  />
                )}
              </Popover>
              {dataSource !== "INSTAGRAM" ? (
                <>
                  <Tooltip
                    title={
                      attachments.length === 4
                        ? CheckValueLocale("max_media_msg", "", {}, intl)
                        : ""
                    }
                    placement="top"
                    arrow
                  >
                    <label htmlFor="gif-input" className={disabledStyleFirst}>
                      <img src={gifSvg} />
                      <input
                        id="gif-input"
                        type="file"
                        accept=".gif, image/gif"
                        onChange={handleAttachmentsUpload}
                        className="modal-input-style"
                        disabled={setSendBtnDisabled || attachments === 4}
                        ref={inputRefGif}
                      />
                    </label>
                  </Tooltip>
                  <Tooltip
                    title={
                      attachments.length === 4
                        ? CheckValueLocale("max_media_msg", "", {}, intl)
                        : ""
                    }
                    placement="top"
                    arrow
                  >
                    <label htmlFor="video-input" className={disabledStyle}>
                      <FontAwesomeIcon icon={faVideo} />
                      <input
                        id="video-input"
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*"
                        onChange={handleAttachmentsUpload}
                        className="modal-input-style"
                        disabled={setSendBtnDisabled || attachments === 4}
                        ref={inputRefVideo}
                      />
                    </label>
                  </Tooltip>
                  <Tooltip
                    title={
                      attachments.length === 4
                        ? CheckValueLocale("max_media_msg", "", {}, intl)
                        : ""
                    }
                    placement="top"
                    arrow
                  >
                    <label htmlFor="image-input" className={disabledStyle}>
                      <FontAwesomeIcon icon={faImage} />
                      <input
                        id="image-input"
                        type="file"
                        accept="image/png,image/jpg,image/jpeg"
                        onChange={handleAttachmentsUpload}
                        className="modal-input-style"
                        disabled={setSendBtnDisabled || attachments === 4}
                        ref={inputRefImg}
                      />
                    </label>
                  </Tooltip>
                </>
              ) : null}
              <Button
                className="reply-modal-btn"
                onClick={handleReply}
                disabled={
                  (text?.trim()?.length === 0 && !base64Code?.length) ||
                  selectedValue === "" ||
                  text?.length > maxChar ||
                  sendBtnDisabled
                }
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </div>
          </Box>
        </>
      ) : showSuccessCondition ? (
        successModal()
      ) : accounts?.length === 0 || !accountsStatus?.includes("active") ? (
        unAuthenticatedAccountModal()
      ) : (
        errorModal()
      )}
      {showDeletePopup ? (
        <PopupModal
          title={CheckValueLocale("delete_saved_replies", "", {}, intl)}
          body={deleteModalBody()}
          leftBtn={CheckValueLocale("cancel_btn", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_btn", "", {}, intl)}
          open={showDeletePopup}
          close={handleCloseDeleteModal}
          accept={() => handleDeleteSavedReplies()}
          rightColor="error"
          warning
          leftBtnId="saved-replies-pop-up-cancel-left-btn"
          rightBtnId="saved-replies-pop-up-delete-right-btn"
        />
      ) : null}
      {showSnackBar && (
        <SnackBar
          open={showSnackBar}
          autoHideDuration={5000}
          severity={snackBarSeverity}
          message={CheckValueLocale(snackBarMessage, "", {}, intl)}
          title={CheckValueLocale(snackBarTitle, "", injectedWord, intl)}
          handleClose={handleCloseSnack}
        />
      )}
    </Box>
  );
};

export default ReplyModal;
