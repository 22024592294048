import "./tableWidget.scss";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import lexisnexis from "../../../images/lexisnexis/lexisnexis.svg";

const WordsList = (props) => {
  const licensesExist = props?.row?.licenses && props?.row?.licenses?.length;
  const showlexisnexisLicenses = licensesExist
    ? typeof props?.row?.licenses[0] === "string"
      ? JSON.parse(props?.row?.licenses[0])?.some(
          (item) => item?.name === "LexisNexis Licensed",
        )
      : null
    : null;

  const intl = useIntl();
  return (
    <TableRow key={props.row.id} className="tr-bg">
      <TableCell className="text-row">
        {showlexisnexisLicenses && (
          <Tooltip
            title={CheckValueLocale(
              "licensed_content_lexisNexis",
              "",
              {},
              intl,
            )}
            arrow
            placement="top"
          >
            <img className="lexisnexis-img" src={lexisnexis} alt="lexisnexis" />
          </Tooltip>
        )}
        {/* this case for keyword name in [social -> CA ] only */}
        {props?.socialKeywordName &&
          (props?.isGensys ? (
            <div>
              {props?.iconBeforeItemTitle && props?.iconBeforeItemTitle}
              <span className="rowid-name" dir="ltr">
                {props?.row?.name}
              </span>
            </div>
          ) : (
            <a className="rowid-name">{props?.row?.name}</a>
          ))}

        {(props.activeTab === "engagements" &&
          props.monitorDataSource === "TALKWALKER") ||
        props.monitorDataSource === "NEWSBLOGS" ||
        (props?.isCustomDashboard && props?.links) ? (
          <a
            href={props.row.id}
            target="_blank"
            rel="noreferrer"
            className="rowid-name"
          >
            {props.row.id}
          </a>
        ) : props.handleOpenQuickInspect ? (
          <Tooltip
            title={CheckValueLocale("click_for_details", "", {}, intl)}
            placement="top"
            arrow
          >
            <span
              className={
                props.dynamicClass
                  ? `rowid-name ${props.dynamicClass}`
                  : (props.activeTab === "engagements" &&
                        props.monitorDataSource === "FACEBOOK") ||
                      (props.activeTab === "engagements" &&
                        props.monitorDataSource === "INSTAGRAM")
                    ? ""
                    : props.dataSource === "googlemybusiness" &&
                        !props.dynamicClass
                      ? ""
                      : "rowid-name"
              } //add a dynamic class for quick-inspect-page
              name={props.row.id}
              onClick={(e) =>
                props.inspectorName &&
                props.handleOpenQuickInspect(
                  e.target.innerText,
                  props.inspectorName,
                )
              }
            >
              {props.showAtIcon && "@"}
              {props.row.id}
            </span>
          </Tooltip>
        ) : (
          <span
            className={
              props.dynamicClass
                ? `rowid-name ${props.dynamicClass}`
                : (props.activeTab === "engagements" &&
                      props.monitorDataSource === "FACEBOOK") ||
                    (props.activeTab === "engagements" &&
                      props.monitorDataSource === "INSTAGRAM")
                  ? ""
                  : props.dataSource === "googlemybusiness" &&
                      !props.dynamicClass
                    ? ""
                    : "rowid-name"
            } //add a dynamic class for quick-inspect-page
            name={props.row.id}
            onClick={(e) =>
              props.inspectorName &&
              props.handleOpenQuickInspect(
                e.target.innerText,
                props.inspectorName,
              )
            }
          >
            {props.showAtIcon && "@"}
            {props.row.id}
          </span>
        )}
      </TableCell>
      <TableCell
        sx={{
          textAlign: "initial",
        }}
      >
        {parseInt(
          props?.row?.stats_count ? props?.row?.stats_count : props?.row?.value,
        )?.toLocaleString()}
      </TableCell>
    </TableRow>
  );
};
export default WordsList;
