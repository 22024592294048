import { useWidgetName } from "../../../hooks/useWidgetName";

const widgetsMapping = {
  Facebook__AccountPage__volume_overtime: "posts_volume_overtime",
  Facebook__EngagementsPage__volume_overtime: "comments_volume_overtime",
  Facebook__EngagementsPage__reactions: "comments_reactions",
  Facebook__AccountPage__reactions: "posts_reactions",
  Facebook__EngagementsPage__sentiment_analysis: "comments_sentiment_analysis",
  Facebook__AccountPage__posts_interactions: "posts_posts_interactions",
  Facebook__EngagementsPage__posts_interactions: "comments_posts_interactions",
  Facebook__AccountPage__top_hashtags: "posts_top_hash_tags",
  Facebook__EngagementsPage__top_hashtags: "comments_top_hash_tags",
  Facebook__AccountPage__top_keywords: "posts_top_keywords",
  Facebook__EngagementsPage__top_keywords: "comments_top_keywords",
  Facebook__EngagementsPage__associated_topics: "comments_associated_topics",
  Facebook__AccountPage__content_type: "posts_content_type",
  Facebook__EngagementsPage__themes_and_sub_themes: "comments_themes",
  Facebook__AccountPage__account_author_activity:
    "posts_account_authors_activity",
  Facebook__EngagementsPage__dialects_and_sub_dialects:
    "comments_dialects_sub_dialects",
  Facebook__EngagementsPage__account_author_activity: "engagers_activity",
  Facebook__CASingleDataSourceInsightsPage__customer_care_performance:
    "customer_care_performance",
  Facebook__AccountPage__top_images: "posts_top_images",
  Facebook__AccountPage__top_videos: "posts_top_videos",

  // -------------------------- Facebook Private --------------------------
  dialects: "dialects_sub_dialects",
  channel_accounts: "channel_accounts",
  content_type: "content_type",
  themes: "themes",
  sentiment: "sentiment_analysis",
  interactions: "conversation_interactions",
  top_keywords: "top_keywords",
  genders: "gender_distribution",
  top_engaged_authors: "most_engaged_contacts",
  channel_activity: "account_contacts_activity",
};

export const FacebookOmniRespHandling = (
  response,
  handleWidgetResponse,
  dashboardInfo,
  monitorType,
) => {
  let stateKey = useWidgetName(
    dashboardInfo?.product, // Product Name
    response?.eventName?.split("__")?.[2], // Widget Name
    monitorType, // Monitor Type
    response?.eventName?.split("__")?.[1], // Page Name
    response?.eventName?.split("__")?.[0], // Datasource Name
  );

  const widgetKey = widgetsMapping[response?.eventName];

  if (widgetKey)
    handleWidgetResponse(
      widgetKey,
      response?.monitor_id,
      response?.eventName?.includes("__") ? "FACEBOOK" : "FACEBOOK_PRIVATE",
      response?.eventData,
    );
  else if (stateKey)
    handleWidgetResponse(
      stateKey,
      response?.monitor_id,
      response?.eventName?.includes("__") ? "FACEBOOK" : "FACEBOOK_PRIVATE",
      response?.eventData,
    );
};
