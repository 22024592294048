import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import CustomDashboardController from "services/controllers/customDashboardController";
import InteractionsController from "services/controllers/interactionsController";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import { isEmptyObj } from "utils/helpers";
import { facebookRespHandling } from "./responseHandling/socialListening/facebookRespHandling";
import { instagramRespHandling } from "./responseHandling/socialListening/instagramRespHandling";
import { twitterRespHandling } from "./responseHandling/socialListening/twitterRespHandling";
import { newsBlogsRespHandling } from "./responseHandling/socialListening/newsBlogsRespHandling";
import { TwitterOmniRespHandling } from "./responseHandling/omnichannel/twitterOmniRespHandling";
import { FacebookOmniRespHandling } from "./responseHandling/omnichannel/facebookOmniRespHandling";
import { TiktokOmniRespHandling } from "./responseHandling/omnichannel/tiktokOmniRespHandling";
import { InstagramOmniRespHandling } from "./responseHandling/omnichannel/instagramOmniRespHandling";
import { LinkedinOmniRespHandling } from "./responseHandling/omnichannel/linkedinOmniRespHandling";

const CustomDashboardFunctions = (params) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardInfo, setDashboardInfo] = useState({
    id: params?.dashboardId,
  });

  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());

  const [response, setResponse] = useState([]);
  const [dashboardWidgets, setDashboardWidgets] = useState([]);
  const [socialWidgets, setSocialWidgets] = useState({});
  const [monitorsTypes, setMonitorsTypes] = useState({});
  const [dataSources, setDataSources] = useState([]);
  const [isLiveData, setIsLiveData] = useState(false);
  const [disabledLive, setDisabledLive] = useState(false);
  const [snackBarData, setSnackBarData] = useState({
    open: false,
    severity: "",
    title: "",
    message: "",
  });

  // RabbitMQ configuration
  const clientMQTT = useRef();
  const mqttConnectionClosed = useRef(false);

  // Get datasources ids to check datasource id in case of omnichannel
  const getDataSources = () => {
    InteractionsController.getDatasources().then((data) => {
      setDataSources(data?.data?.data);
    });
  };

  const getCustomDashboardView = (
    dashboardId,
    startDate,
    endDate,
    liveDataReload,
  ) => {
    let queryData = { start_date: startDate, end_date: endDate };
    let allWidgets = [];

    if (!liveDataReload) setIsLoading(true);
    setResponse([]);
    setSocialWidgets({});
    setMonitorsTypes({});
    CustomDashboardController?.getCustomDashboardView(
      dashboardId,
      queryData,
    )?.then((data) => {
      if (!isEmptyObj(data?.data)) {
        if (data?.data?.product_id == localStorage?.getItem("cxm_id")) {
          getDataSources();
        }
        setDashboardInfo({
          ...dashboardInfo,
          name: data?.data?.name,
          dashboard_owner: data?.data?.dashboard_owner,
          product_id: +data?.data?.product_id,
          product:
            data?.data?.product_id == localStorage?.getItem("sm_id")
              ? "sm"
              : "cxm",
        });
        mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
        data?.data?.metrics?.forEach((item) => {
          allWidgets.push(item);
          setMonitorsTypes((prev) => ({
            ...prev,
            [item.attributes.monitor_id]: item?.attributes?.monitor_type?.name,
          }));
        });
        setDashboardWidgets(allWidgets);
      } else {
        mqttConnectionClosed.current = true;
      }
      setIsLoading(false);
    });
  };

  const handleChangeLiveData = async (e) => {
    setDisabledLive(true);
    const checked = e?.target?.checked;
    const queryData = {
      id: +dashboardInfo?.id,
      is_enabled: checked,
    };
    const response =
      await CustomDashboardController?.updateLiveCustomDashboard(queryData);
    if (response?.status == 200) {
      setIsLiveData(checked);
    }
    setDisabledLive(false);
  };
  useEffect(() => {
    let intervalId;
    // Start interval when isLiveData == True to reload every (ONE MINUTE == 60000 milliseconds)
    if (isLiveData) {
      intervalId = setInterval(() => {
        getCustomDashboardView(dashboardInfo?.id, startDate, endDate, true);
      }, 60000);
    }
    // Clear interval when isLiveData changes or set as False
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isLiveData, dashboardInfo?.id, startDate, endDate]);

  const applyFilter = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    getCustomDashboardView(dashboardInfo?.id, startDate, endDate);
  };

  // Function to set widget rabbitMQ response in main state
  const handleWidgetResponse = (
    widgetKey,
    monitorId,
    dataSource,
    eventData,
  ) => {
    setSocialWidgets({
      ...socialWidgets,
      [widgetKey]: {
        ...socialWidgets?.[widgetKey],
        [monitorId]: {
          ...socialWidgets?.[widgetKey]?.[monitorId],
          [dataSource]: eventData,
        },
      },
      [`${widgetKey}_pre_loader_data_sources`]: {
        ...socialWidgets?.[`${widgetKey}_pre_loader_data_sources`],
        [monitorId]: {
          ...socialWidgets?.[`${widgetKey}_pre_loader_data_sources`]?.[
            monitorId
          ],
          [dataSource]: monitorId,
        },
      },
    });
  };

  useEffect(() => {
    if (dashboardInfo?.product === "sm") {
      switch (response?.data_source) {
        case "FACEBOOK":
          facebookRespHandling(
            response,
            handleWidgetResponse,
            dashboardInfo,
            monitorsTypes?.[response?.monitor_id?.[0]],
          );
          break;

        case "INSTAGRAM":
          instagramRespHandling(
            response,
            handleWidgetResponse,
            dashboardInfo,
            monitorsTypes?.[response?.monitor_id?.[0]],
          );
          break;

        case "TWITTER":
          twitterRespHandling(
            response,
            handleWidgetResponse,
            dashboardInfo,
            monitorsTypes?.[response?.monitor_id?.[0]],
          );
          break;

        case "NEWSBLOGS":
          newsBlogsRespHandling(
            response,
            handleWidgetResponse,
            dashboardInfo,
            monitorsTypes?.[response?.monitor_id?.[0]],
          );
          break;

        default:
          break;
      }
    } else {
      let dataSource = dataSources?.filter(
        (item) => item?.id == response?.data_source,
      )?.[0];

      if (dataSource?.source === "TWITTER") {
        TwitterOmniRespHandling(
          response,
          handleWidgetResponse,
          dashboardInfo,
          monitorsTypes?.[response?.monitor_id?.[0]],
        );
      } else if (dataSource?.source === "FACEBOOK") {
        FacebookOmniRespHandling(
          response,
          handleWidgetResponse,
          dashboardInfo,
          monitorsTypes?.[response?.monitor_id?.[0]],
        );
      } else if (
        dataSource?.source === "TIKTOK" ||
        response?.data_source === "tiktok_public"
      ) {
        TiktokOmniRespHandling(
          response,
          handleWidgetResponse,
          dashboardInfo,
          monitorsTypes?.[response?.monitor_id?.[0]],
        );
      } else if (
        dataSource?.source === "INSTAGRAM" ||
        response?.data_source === "instagram_private"
      ) {
        InstagramOmniRespHandling(
          response,
          handleWidgetResponse,
          dashboardInfo,
          monitorsTypes?.[response?.monitor_id?.[0]],
        );
      } else if (dataSource?.source === "LINKEDIN") {
        LinkedinOmniRespHandling(
          response,
          handleWidgetResponse,
          dashboardInfo,
          monitorsTypes?.[response?.monitor_id?.[0]],
        );
      }
    }
  }, [response]);

  return {
    isLoading,
    dashboardInfo,
    startDate,
    endDate,
    applyFilter,
    dashboardWidgets,
    socialWidgets,
    isLiveData,
    disabledLive,
    handleChangeLiveData,
    snackBarData,
    setSnackBarData,
  };
};

export default CustomDashboardFunctions;
