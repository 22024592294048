import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { CheckValueLocale, translateLocalsFromRabbitMQ } from "utils/helpers";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/pro-regular-svg-icons";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import DragHandleIcon from "../dragHandleIcon";

const Container = SortableContainer(({ children }) => {
  return (
    <Grid container spacing={1} className="live-dashboard-widget-container">
      {children}
    </Grid>
  );
});

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const Element = SortableElement(
  ({ widgetItem, showEditBtn, widgetSection }) => {
    const intl = useIntl();
    if (widgetItem?.typeWidget === "pieChart") {
      return (
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="dashboard-widget-wrapper-column"
        >
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <PieChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              subData={widgetItem?.subData}
              color={widgetItem?.color}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              showAI={widgetItem?.showAI}
              twitterIcon={widgetItem?.twitterIcon}
              pieChartPreLoaderDataSources={
                widgetItem?.pieChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              inspectorName={widgetItem?.inspectorName}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "lineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <LineChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              totalLabel={widgetItem?.totalLabel}
              totalValue={widgetItem?.totalValue}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              toolTipLabel={widgetItem?.toolTipLabel}
              labelColor={widgetItem?.labelColor}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              lineChartPreLoaderDataSources={
                widgetItem?.lineChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              haveData={widgetItem?.haveData}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedLineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedLineWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showAI={widgetItem?.showAI}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dataPiechart={widgetItem?.dataPiechart}
              hideLegend={widgetItem?.hideLegend}
              labelColors={widgetItem?.labelColors}
              isSwitchCase={widgetItem?.isSwitchCase}
              isSentimentAnalysisWidget={widgetItem?.isSentimentAnalysisWidget}
              isSentimentAnalysisShowCharts={
                widgetItem?.isSentimentAnalysisShowCharts
              }
              stackedLineWidgetPreLoaderDataSources={
                widgetItem?.stackedLineWidgetPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedBarChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedBarWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              posts={widgetItem?.posts}
              colors={widgetItem?.colors}
              labels={widgetItem?.labels}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              showZoomPercentage={widgetItem?.showZoomPercentage}
              postsColumnPreLoaderDataSources={
                widgetItem?.postsColumnPreLoaderDataSources
              }
              hideLegend={widgetItem?.hideLegend}
              dataPiechart={widgetItem?.dataPiechart}
              showChartDetailsWidget={widgetItem?.showChartDetailsWidget}
              statsDetailsData={widgetItem?.statsDetailsData}
              isInteractionStatsWrapperStyle={
                widgetItem?.isInteractionStatsWrapperStyle
              }
              reachFunnel={widgetItem?.reachFunnel}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              showPostsInteractionStats={widgetItem?.showPostsInteractionStats}
              reachFunnelAccount={widgetItem?.reachFunnelAccount}
              xAxisValue={widgetItem?.xAxisValue}
              showLogChart={widgetItem?.showLogChart}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "tableWidget") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <TableWidget
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              monitorId={widgetItem?.monitorId}
              circularPP={widgetItem?.circularPP}
              itemsList={widgetItem?.itemsList}
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              leftColomnTitle={widgetItem?.leftColomnTitle}
              rightColomnTitle={widgetItem?.rightColomnTitle}
              tableChartPreLoaderDataSources={
                widgetItem?.tableChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              twitterIcon={widgetItem?.twitterIcon}
              tablePagination={widgetItem?.tablePagination}
              count={widgetItem?.count}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              wordsList={widgetItem?.wordsList}
              keywords={widgetItem?.keywords}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dynamicClass={widgetItem?.dynamicClass}
              hashtags={widgetItem?.hashtags}
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "questionCard") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <Box component="p" className="question-card-title">
              <Box component="span" className="widget-title">
                {CheckValueLocale(widgetItem?.questionCardTitle, "", {}, intl)}
                {widgetSection ? (
                  <span className="widget-title-section">
                    ({CheckValueLocale(widgetSection, "", {}, intl)})
                  </span>
                ) : (
                  ""
                )}
              </Box>
              {widgetItem?.questionCardIcon}
            </Box>
            <QuestionCard
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              latestUnanswer={widgetItem?.latestUnanswer}
              icon={widgetItem?.icon}
              iconColor={widgetItem?.iconColor}
              showBanIcon={widgetItem?.showBanIcon}
              latestUnanswerPreloader={widgetItem?.latestUnanswerPreloader}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              updateAnalysis={widgetItem?.updateAnalysis}
              noOfChar={widgetItem?.noOfChar}
              singleTweetAnalysis={widgetItem?.singleTweetAnalysis}
              hideWhenRetweeted={widgetItem?.hideWhenRetweeted}
              showRetweet={widgetItem?.showRetweet}
              showHeart={widgetItem?.showHeart}
              showScreenName={widgetItem?.showScreenName}
              showDefaultImg={widgetItem?.showDefaultImg}
              className={widgetItem?.className}
              hideVector={widgetItem?.hideVector}
              hideChartBar={widgetItem?.hideChartBar}
              showFlash={widgetItem?.showFlash}
              showEye={widgetItem?.showEye}
              showEarth={widgetItem?.showEarth}
              newsBlogImg={widgetItem?.newsBlogImg}
              datasourcePostsType="NEWSBLOGS"
            />
          </Box>
        </Grid>
      );
    } else {
      return "";
    }
  },
);

const DataSourceContent = ({
  resetAllFields,
  response,
  preLoaderTrackerDataSources,
  unixDate,
  singleTweetAnalysis,
  newsBlogsWidgetTypes,
  setNewsBlogsWidgetTypes,
  sortWidgets,
  getNameKeyWidget,
  showEditBtn,
  setTotalStatisticWidgets,
  setLoadingStatisticWidgets,
}) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorId = urlParams.monitorId;

  // engagement section

  const [postsVolume, setPostsVolume] = useState([]);
  const [postsVolumePreLoader, setPostsVolumePreLoader] = useState([]);
  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );
  const [topEngaged, setTopEngaged] = useState([]);
  const [topEngagedPreLoader, setTopEngagedPreLoader] = useState([]);
  const [topSites, setTopSites] = useState([]);
  const [topSitesPreLoader, setTopSitesPreLoader] = useState([]);
  const [reachFunnelLegacy, setReachFunnelLegacy] = useState([]);
  const [
    reachFunnelLegacyPreLoaderDataSources,
    setReachFunnelLegacyPreLoaderDataSources,
  ] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);
  const [topLanguages, setTopLanguages] = useState([]);
  const [topLanguagesPreLoader, setTopLanguagesPreLoader] = useState([]);
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(10);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);
  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);

  // posts section
  const [topPostsPosts, setTopPostsPosts] = useState([]);
  const [
    topTopPostsPostsPreLoaderDataSources,
    setTopPostsPostsPreLoaderDataSources,
  ] = useState([]);

  const newsBlogIcon = (
    <span className="topics-twitter-icon">
      <FontAwesomeIcon
        ml={2}
        className={"icon-after-title-news news-blogs-icon"}
        icon={faNewspaper}
      />
    </span>
  );

  const newsBlogsWidgetObj = {
    // engagement section
    Talkwalker__EngagementsPage__volume_overtime: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Talkwalker__EngagementsPage__volume_overtime"),
      titleToolTip: "posts_volume_fb_tooltip",
      totalLabel: "posts",
      totalValue: totalPostsVolumeCount,
      data: postsVolume,
      showGroupBy: true,
      toolTipLabel: "posts",
      showDownloadIcon: true,
      bigImg: true,
      lineChartPreLoaderDataSources: postsVolumePreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: newsBlogIcon,
      labelColor: "#2AAAE2",
    },
    Talkwalker__EngagementsPage__sentiment_analysis: {
      typeWidget: "stackedLineChart",
      title: getNameKeyWidget(
        "Talkwalker__EngagementsPage__sentiment_analysis",
      ),
      titleToolTip: "comments_sentiment_analysis_nb_tooltip",
      data: sentimentAnalysis,
      showGroupBy: true,
      showScales: true,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      bigImg: true,
      stackedLineWidgetPreLoaderDataSources: sentimentAnalysisPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      isSwitchCase: true,
      iconAfterTitle: newsBlogIcon,
      labelColors: ["#89BB2A", "#E50035", "#F9A700"],
      dataPiechart: sentimentPieChart,
      hideLegend: true,
      isSentimentAnalysisWidget: true,
      isSentimentAnalysisShowCharts: true,
    },
    Talkwalker__EngagementsPage__top_engaged_sites: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Talkwalker__EngagementsPage__top_engaged_sites"),
      titleToolTip: "top_engaged_sites_nb_tooltip",
      data: topEngaged,
      leftColomnTitle: "links",
      rightColomnTitle: "nb_posts",
      tableChartPreLoaderDataSources: topEngagedPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      keywords: true,
      tablePagination: true,
      count: topEngaged.length,
      iconAfterTitle: newsBlogIcon,
    },
    Talkwalker__EngagementsPage__top_sites: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Talkwalker__EngagementsPage__top_sites"),
      titleToolTip: "top_sites_nb_tooltip",
      data: topSites,
      leftColomnTitle: "links",
      rightColomnTitle: "nb_reach",
      tableChartPreLoaderDataSources: topSitesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hashtags: true,
      tablePagination: true,
      count: topSites.length,
      iconAfterTitle: newsBlogIcon,
    },
    Talkwalker__EngagementsPage__reach_funnel: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Talkwalker__EngagementsPage__reach_funnel"),
      titleToolTip: "posts_reach_nb_tooltip",
      bigImg: true,
      posts: reachFunnelLegacy,
      colors: ["#64748B"],
      labels: ["original"],
      xAxisValue: true,
      iconAfterTitle: newsBlogIcon,
      showDownloadIcon: true,
      showLogChart: true,
      postsColumnPreLoaderDataSources: reachFunnelLegacyPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Talkwalker__EngagementsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Talkwalker__EngagementsPage__top_posts_comments",
      ),
      questionCardIcon: newsBlogIcon,
      latestUnanswer: topPosts.slice(0, 6),
      latestUnanswerPreloader: topTopPostsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faNewspaper,
      iconColor: "news-blogs-icon",
      showHeart: true,
      hideVector: true,
      hideChartBar: true,
      showFlash: true,
      showEye: true,
      showEarth: true,
      newsBlogImg: true,
      showBanIcon: true,
    },
    Talkwalker__EngagementsPage__top_languages: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Talkwalker__EngagementsPage__top_languages"),
      titleToolTip: "top_languages_nb_tooltip",
      data: topLanguages,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: true,
      showAI: true,
      pieChartPreLoaderDataSources: topLanguagesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: newsBlogIcon,
    },
    Talkwalker__EngagementsPage__top_countries: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Talkwalker__EngagementsPage__top_countries"),
      titleToolTip: "top_countries_nb_tooltip",
      data: topCountries,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      color: ["#009BDF", "#003D59", "#45B9E9", "#97D7F3"],
      pieChartPreLoaderDataSources: topCountriesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: newsBlogIcon,
    },
    // account section
    // posts section
    Talkwalker__PostsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Talkwalker__PostsPage__top_posts_comments",
      ),
      questionCardIcon: newsBlogIcon,
      latestUnanswer: topPostsPosts,
      latestUnanswerPreloader: topTopPostsPostsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faNewspaper,
      className: "no-data-found-question",
      iconColor: "news-blogs-icon",
      showHeart: true,
      hideVector: true,
      hideChartBar: true,
      showFlash: true,
      showEye: true,
      showEarth: true,
      newsBlogImg: true,
      showDefaultImg: true,
      // ShowPagination: true,
      // setPage: setPage,
      // page: page,
      showBanIcon: true,
    },
  };

  const handleResponseFunc = async () => {
    // engagement section
    if (response.eventName === "Talkwalker__EngagementsPage__volume_overtime") {
      setPostsVolume(response.eventData.posts_over_time);
      setTotalPostsVolumeCount(response.eventData.total_posts_count);
      setPostsVolumePreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__top_engaged_sites"
    ) {
      setTopEngaged(response.eventData.top_engaged_sites);
      setTopEngagedPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__top_sites"
    ) {
      setTopSites(response.eventData.top_sites);
      setTopSitesPreLoader((oldArray) => [...oldArray, response.monitor_id]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__sentiment_analysis"
    ) {
      Object.entries(response.eventData.sentiment_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentAnalysis({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentAnalysisPieChart(response.eventData.sentiment_piechart);
      setSentimentAnalysisPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__reach_funnel"
    ) {
      setReachFunnelLegacy(response.eventData);
      setReachFunnelLegacyPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__top_posts_comments"
    ) {
      setTopPosts(response.eventData.top_posts);
      setTopPostsCount(response.eventData.top_posts_count);
      setTopPostsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__top_languages"
    ) {
      setTopLanguages(
        translateLocalsFromRabbitMQ(
          "Talkwalker__EngagementsPage__top_languages",
          response.eventData.top_languages,
        ),
      );
      setTopLanguagesPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__top_countries"
    ) {
      setTopCountries(response.eventData.top_countries);
      setTopCountriesPreLoader((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else if (
      response.eventName === "Talkwalker__EngagementsPage__dashboard_data"
    ) {
      setTotalStatisticWidgets((prev) => ({
        totalPosts: prev?.totalPosts + response?.eventData?.total_posts,
        totalAuthors: prev?.totalAuthors + response?.eventData?.total_authors,
        totalInteractions:
          prev?.totalInteractions + response?.eventData?.total_interactions,
      }));
      setLoadingStatisticWidgets(false);
      // posts section
    } else if (
      response.eventName === "Talkwalker__PostsPage__top_posts_comments"
    ) {
      setTopPostsPosts(response.eventData.top_posts);
      setTopPostsPostsPreLoaderDataSources((oldArray) => [
        ...oldArray,
        response.monitor_id,
      ]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      // engagement section
      setTopLanguages([]);
      setTopLanguagesPreLoader([]);
      setTopCountries([]);
      setTopCountriesPreLoader([]);
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setPostsVolumePreLoader([]);
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);
      setSentimentAnalysisPieChart([]);
      setTopEngaged([]);
      setTopEngagedPreLoader([]);
      setTopSites([]);
      setTopSitesPreLoader([]);

      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);

      setReachFunnelLegacy([]);

      setReachFunnelLegacyPreLoaderDataSources([]);
      setTopPosts([]);
      setTopPostsCount(0);
      setTopPostsPreLoaderDataSources([]);

      // posts section
      setTopPostsPosts([]);
      setTopPostsPostsPreLoaderDataSources([]);
    }
  }, [resetAllFields]);

  //this function is used for open the quick-inspect-model
  // const handleOpenQuickInspect = (value, name, sentimentValue) => {
  //   switch (true) {
  //     case name === "comments_overtime":
  //       setDataValue(
  //         props.engagementsKeyword ? "posts_mentions" : "comments_mentions",
  //       );
  //       setInspectorValue(props.engagementsKeyword ? "posts" : "comments");
  //       setColor("#2AAAE2");
  //       setDate(value.split("-").reverse().join("/"));
  //       break;
  //     case name === "sentiment_overtime":
  //       setDataValue(sentimentValue);
  //       setInspectorValue(
  //         CheckValueLocale(sentimentValue, "", {}, intl) +
  //           " " +
  //           CheckValueLocale("volume", "", {}, intl),
  //       );
  //       sentimentValue === "positive"
  //         ? setColor("#BAD532")
  //         : sentimentValue === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");
  //       setDate(value.split("-").reverse().join("/"));

  //       break;
  //     //incase of new design for quick inspect from sentiment legend
  //     case name === "sentiment_legend":
  //       setDataValue(
  //         sentimentValue + " " + CheckValueLocale(value, "", {}, intl),
  //       );
  //       setInspectorValue(CheckValueLocale(value + "_volume", "", {}, intl));
  //       value === "positive"
  //         ? setColor("#8CC63F")
  //         : value === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");

  //       setDate(
  //         CheckValueLocale("all_time_sentiment", "", {}, intl) +
  //           dateFormRefactor(reduxFilterStartDateUX) +
  //           " - " +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     case name === "top_hashtags" || name === "top_keywords":
  //       setDataValue(value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     default:
  //       setDataValue(sentimentValue + "% " + value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //   }
  //   setQuickResponse([]);
  //   setInspectorName(name);
  //   setOpen(true);

  //   let valueToSend;
  //   if (dataToSend[name] !== undefined && dataToSend[name].length !== 0) {
  //     valueToSend = dataToSend[name].filter(
  //       (data) =>
  //         CheckValueLocale(data.name, "", {}, intl).toUpperCase() ===
  //         value.toUpperCase(),
  //     );
  //   }
  //   value =
  //     valueToSend && valueToSend.length !== 0 ? valueToSend[0].name : value;
  //   name === "sentiment_overtime"
  //     ? getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //         sentimentValue,
  //       )
  //     : getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //       );
  // };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    sortWidgets(
      oldIndex,
      newIndex,
      newsBlogsWidgetTypes,
      setNewsBlogsWidgetTypes,
    );
  };
  return (
    <>
      <Container
        axis={"xy"}
        onSortEnd={onSortEnd}
        useDragHandle
        useWindowAsScrollContainer
      >
        {newsBlogsWidgetTypes
          ?.filter((item) => newsBlogsWidgetObj[item?.name])
          ?.map((item, index) => (
            <Element
              key={index}
              index={index}
              widgetItem={newsBlogsWidgetObj[item?.name]}
              showEditBtn={showEditBtn}
              widgetSection={item?.section}
            />
          ))}
      </Container>
    </>
  );
};
export default DataSourceContent;
