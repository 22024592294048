import Services from "./Services";

var Companies = {};

Companies.getCompanyProducts = (queryData) => {
  return Services.getData("api/v3/company/products", queryData);
};

Companies.getCompanyDataCategories = () => {
  return Services.getData("api/v3/company/data_categories");
};

Companies.getCompanyApps = () => {
  return Services.getData("auth/company/apps");
};

Companies.getDataSourcesForCategory = (queryData, pathData) => {
  return Services.getData(
    "api/v3/company/data_categories",
    queryData,
    pathData,
  );
};

Companies.getAuthorizedAccounts = (queryData) => {
  return Services.getData("api/v3/company/authorized_accounts", queryData);
};

Companies.WhatsAppIntegrationRequests = (queryData) => {
  return Services.getData(
    "api/v3/channel_analytics/chat/whatsapp/integration_requests",
    queryData,
  );
};
Companies.getCompanyInfo = () => {
  return Services.getData("api/v3/company/info");
};
Companies.putCompanyInfo = (companyInfo) => {
  return Services.putData("/api/v3/company/info", companyInfo);
};

// Get company accounts (authorizations) list
Companies.getCompanyAuthorizations = (queryData) => {
  return Services.getData("api/v3/company/company_authorizations", queryData);
};
// Get users accounts (authorizations) list
Companies.getUsersAuthorizations = (queryData) => {
  return Services.getData("api/v3/company/users_authorizations", queryData);
};

Companies.getCompanyUsers = (queryData, pathData) => {
  return Services.getData(
    "api/v4/company/user_managements",
    queryData,
    pathData,
  );
};

Companies.getCompanyActiveUsers = (queryData, pathData) => {
  return Services.getData(
    "api/v3/company/user_managements_active_users",
    queryData,
    pathData,
  );
};

// Get Company User
Companies.getCompanyUser = (id) => {
  return Services.getData(`api/v3/company/user_managements/${id}`);
};

// Add New User
Companies.postCompanyUser = (userInfo) => {
  return Services.postData("api/v3/company/user_managements", userInfo);
};

// Update User Info
Companies.putCompanyUser = (id, userInfo) => {
  return Services.putData(`api/v3/company/user_managements/${id}`, userInfo);
};

// Delete Company User
Companies.deleteCompanyUser = (id) => {
  return Services.deleteData(`api/v3/company/user_managements/${id}`);
};

// De-active Company User
Companies.deactiveCompanyUser = (id) => {
  return Services.patchData(`api/v3/company/user_managements/deactivate/${id}`);
};

// Activate Company User
Companies.activeCompanyUser = (id) => {
  return Services.patchData(`api/v3/company/user_managements/activate/${id}`);
};

// Invite Company User
Companies.inviteCompanyUser = (id) => {
  return Services.patchData(`api/v3/company/user_managements/re_invite/${id}`);
};

// Get All Company Subscription
Companies.getCompanySubscriptions = (queryData, pathData) => {
  return Services.getData("api/v3/company/subscription", queryData, pathData);
};

// Send Product Package Upgrade Request
Companies.sendRequestPackageUpgrade = (productInfo) => {
  return Services.putData(
    "api/v3/company/requests/package_upgrade",
    productInfo,
  );
};

// Send Request CallBack
Companies.sendRequestCall = (manager_id) => {
  return Services.putData(
    "api/v3/company/requests/request_customer_success_manager",
    manager_id,
  );
};

// Send Product Demo Request
Companies.sendRequestDemo = (productInfo) => {
  return Services.putData("api/v3/company/requests/product_demo", productInfo);
};

// Send Service Request
Companies.sendServiceRequest = (serviceInfo) => {
  return Services.putData(
    "api/v3/company/requests/3rd_party_integration",
    serviceInfo,
  );
};

// Integrate Intercom
Companies.intercomIntegrate = (intercomInfo) => {
  return Services.postData(
    "api/v3/auth/third_party_integrations/intercom",
    intercomInfo,
  );
};

Companies.getIntegrateIntercomData = () => {
  return Services.getData("api/v3/auth/third_party_integrations/intercom");
};

// Integrate Zendesk
Companies.zendeskIntegrate = (zendeskInfo) => {
  return Services.postData(
    "api/v3/auth/third_party_integrations/zendesk",
    zendeskInfo,
  );
};
//get zendsek data
Companies.getIntegrateZendeskData = () => {
  return Services.getData("api/v3/auth/third_party_integrations/zendesk");
};
// List of 3rd Party Integrations
Companies.thirdPartyList = () => {
  return Services.getData("api/v3/auth/third_party_integrations/");
};

/* Reports Branding Services */

Companies.getReportsBranding = () => {
  return Services.getData("api/v3/company/report_branding");
};

Companies.postReportsBranding = (queryData) => {
  return Services.postData("api/v3/company/report_branding", queryData);
};

Companies.getFonts = () => {
  return Services.getData("api/v3/company/font_types");
};

Companies.requestThirdPartyDemo = (serviceName) => {
  return Services.putData(
    "api/v3/company/requests/3rd_party_integration",
    serviceName,
  );
};

// requestServices
Companies.requestService = (serviceName) => {
  return Services.putData("api/v3/company/requests/service", serviceName);
};

// Get Company Monitor Types
Companies.getCompanyMonitorTypes = () => {
  return Services.getData(`api/v3/company/monitor_types`);
};

// Historical Request

Companies.getCompanyDataInsightsQuota = (queryData) => {
  return Services.getData(
    `api/v3/historical_requests/company_quota`,
    queryData,
  );
};
Companies.getCompanySingleTweetsQuota = (tweetID, queryData) => {
  return Services.getData(
    `api/v3/social_media/twitter/single_tweets/${tweetID}/get_quota_consumption`,
    queryData,
  );
};
Companies.getHistoricalRequestsList = (queryData) => {
  return Services.getData("api/v4/historical_requests", queryData);
};
Companies.deleteHistReq = (pathData) => {
  return Services.deleteData("api/v3/historical_requests/", "", pathData);
};
Companies.getHistoricalCompanyPlatforms = (queryData) => {
  return Services.getData(
    `api/v3/historical_requests/company_platforms`,
    queryData,
  );
};
Companies.getHistoricalDatasources = (queryData) => {
  return Services.getData(`api/v3/historical_requests/data_sources`, queryData);
};

Companies.getHistoricalMonitors = (queryData) => {
  return Services.getData(`api/v3/historical_requests/monitors`, queryData);
};
Companies.createHistoricalRequest = (queryData) => {
  return Services.postData("api/v3/historical_requests", queryData);
};
Companies.getAvailableDataTwitter = (queryData) => {
  return Services.getData(
    "api/v4/historical_requests/current_twitter_counts",
    queryData,
  );
};

Companies.createTeam = (queryData) => {
  return Services.postData("api/v4/company/teams", queryData);
};
Companies.getTeams = (queryData) => {
  return Services.getData("api/v4/company/teams", queryData);
};
Companies.updateTeam = (id, queryData) => {
  return Services.putData(`api/v4/company/teams/${id}`, queryData);
};
Companies.getTeam = (id) => {
  return Services.getData(`api/v4/company/teams/${id}`);
};
Companies.getTeamMembersSelected = (id, queryData) => {
  return Services.getData(
    `api/v4/company/teams/${id}/members_selected`,
    queryData,
  );
};
Companies.getSelectedTeamMembers = (id, queryData) => {
  return Services.getData(`api/v4/company/teams/${id}/users`, queryData);
};
Companies.deleteTeam = (id) => {
  return Services.deleteData(`api/v4/company/teams/${id}`);
};
Companies.getTeamMembers = (queryData) => {
  return Services.getData("api/v4/company/teams/users_informer", queryData);
};
Companies.getTeamProducts = () => {
  return Services.getData("api/v4/company/teams/products_informer");
};

Companies.getCompanySkills = () => {
  return Services.getData(`api/v4/company/informal/skills`);
};
Companies.addCompanySkills = (skillInfo) => {
  return Services.postData("api/v4/company/informal/skills", skillInfo);
};
Companies.getCompanyLanguages = () => {
  return Services.getData(`api/v4/company/informal/languages`);
};
Companies.getAllCompanyTeams = () => {
  return Services.getData(`api/v4/company/informal/teams`);
};
Companies.getAllCompanyRoutings = (queryData) => {
  return Services.getData(
    `api/v4/engagements/informal/inbound_routings`,
    queryData,
  );
};
Companies.getCompanyAiContent = () => {
  return Services.getData(`api/v4/company/informal/ai_content`);
};
Companies.getCompanySegments = () => {
  return Services.getData(`api/v4/company/informal/segments`);
};

Companies.getUsersAllowedToCreate = (queryData) => {
  return Services.getData("api/v3/monitors/company_users", queryData);
};
Companies.upgradeQuota = (queryData) => {
  return Services.putData(
    "api/v3/company/requests/historical_request_upgrade",
    queryData,
  );
};
Companies.retryRequest = (queryData) => {
  return Services.getData("api/v3/historical_requests/retry/" + queryData);
};

Companies.stopRequest = (queryData) => {
  return Services.getData(
    "api/v4/historical_requests/cancel_historical_request/" + queryData,
  );
};

Companies.createHistoricalRequestFlag = (queryData) => {
  return Services.getData(
    "api/v3/historical_requests/status_indicator",
    queryData,
  );
};
Companies.getSidebarCategories = (queryData) => {
  return Services.getData("api/v3/company/sidebar_categories", queryData);
};

//get custom domain
Companies.getCustomDomain = (queryData) => {
  return Services.getData("api/v3/company/info/custom_domain", queryData);
};

//create & delete custom domain
Companies.sendAndDeleteCustomDomain = (queryData) => {
  return Services.putData("api/v3/company/info/custom_domain", queryData);
};

// start lucidya apis

// Get lucidya apis list
Companies.getLucidyaApisList = (queryData) => {
  return Services.getData("api/v3/public_apis/public_apis", queryData);
};

// create lucidya api
Companies.createLucidyaApisRequest = (queryData) => {
  return Services.postData("api/v3/public_apis/public_apis", queryData);
};
export default Companies;

// get single lucidya api
Companies.getSingleLucidyaApis = (queryData) => {
  return Services.getData(`api/v3/public_apis/informer`, queryData);
};

// edit lucidya api
Companies.editLucidyaApisRequest = (id, queryData) => {
  return Services.putData(`api/v3/public_apis/public_apis/${id}`, queryData);
};

// Delete lucidya api
Companies.deleteLucidyaApisRequest = (id) => {
  return Services.deleteData(`api/v3/public_apis/public_apis/${id}`);
};

// Activate lucidya api
Companies.activateLucidyaApisRequest = (id) => {
  return Services.postData(`api/v3/public_apis/activate/${id}`);
};

// InActivate lucidya api
Companies.inActivateLucidyaApisRequest = (id) => {
  return Services.postData(`api/v3/public_apis/deactivate/${id}`);
};

// regenerate lucidya api
Companies.regenerateLucidyaApisRequest = (id) => {
  return Services.postData(`api/v3/public_apis/regenerate/${id}`);
};

// Get lucidya apis Types list
Companies.getLucidyaApisTypes = (id) => {
  return Services.getData("api/v3/public_apis/types");
};

Companies.getAccounts = (queryData) => {
  return Services.getData("api/v4/company/informal/social_accounts", queryData);
};

Companies.getMonitorsOrChannels = (queryData) => {
  return Services.getData("api/v4/company/informal/monitors", queryData);
};

Companies.getEngagPermissions = () => {
  return Services.getData("api/v4/company/informal/engagement_permissions");
};

Companies.getUserView = (id) => {
  return Services.getData(`api/v4/company/user_managements/${id}`);
};

Companies.createCompanyUsers = (queryData) => {
  return Services.postData("api/v4/company/user_managements", queryData);
};

Companies.editCompanyUsers = (queryData, id) => {
  return Services.putData(`api/v4/company/user_managements/${id}`, queryData);
};

Companies.getAccountsEdit = (queryData, id) => {
  return Services.getData(
    `api/v4/company/user_managements/${id}/selected_accounts`,
    queryData,
  );
};

Companies.getMonitorsOrChannelsEdit = (queryData, id) => {
  return Services.getData(
    `api/v4/company/user_managements/${id}/selected_monitors`,
    queryData,
  );
};
Companies.getUserActivityLogs = (queryData) => {
  return Services.getData(`api/v3/user_activity_logs`, queryData);
};
Companies.getFilterActivityLogs = (queryData) => {
  return Services.getData(`api/v3/user_activity_logs/filters`, queryData);
};
