import { Box } from "@mui/material";
import "../detailsSideBar.scss";
import "./slaDetailsSideBar.scss";
import React, { useState, useEffect } from "react";
import { CheckValueLocale } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SlaLoader from "./slaLoader";
import SlaDetailsContant from "./slaDetailsContant";
import CompaniesController from "services/controllers/companiesController";
import EngagementsController from "services/controllers/engagementsController";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";

const SlaDetailsSideBar = ({
  setOpenSlaSideBar,
  selectedCard,
  headerData,
  slaLogs,
}) => {
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const intl = useIntl();
  const [loading, setLoading] = useState(true);

  // every time we open sla sideBar we update list of teams and users and this use at this sideBar and cards
  useEffect(() => {
    setLoading(true);
    let slaObj = { ...reduxSlaData };
    CompaniesController.getAllCompanyTeams().then((res) => {
      if (!res?.errorMsg && res?.data?.teams?.length) {
        slaObj.teams = [...res?.data?.teams];
      } else {
        slaObj.teams = [];
      }
      EngagementsController.getUsers(
        window.localStorage.getItem("engagementsProductId"),
      ).then((res) => {
        if (!res?.errorMsg && res?.data?.data?.length) {
          slaObj.users = [...res?.data?.data];
        } else {
          slaObj.users = [];
        }
      });
      dispatch(slaData(slaObj));
      setLoading(false);
    });
  }, []);

  return (
    <Box className="details-side-bar-wrapper">
      <Box className="sla-details-side-bar-wrapper">
        <Box className="sla-details-header">
          {CheckValueLocale("engag_det", "", {}, intl)}
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setOpenSlaSideBar(false)}
          />
        </Box>
        <Box className="sla-details-main-content">
          {loading ? (
            <SlaLoader />
          ) : (
            <SlaDetailsContant
              selectedCard={selectedCard}
              headerData={headerData}
              slaLogs={slaLogs}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default SlaDetailsSideBar;
