import AccountAnalysisType from "./monitorTypeData/accountAnalysisType";
import ManagedType from "./monitorTypeData/managedType";
import KeywordsType from "./monitorTypeData/keywordsType/keywordsType";

const MonitorTypeData = (props) => {
  const monitorDetails = props.monitorDetails?.attributes;
  const {
    setUpdatedParams,
    updatedParams,
    handleKeywords,
    setNBexceedLimit,
    twitterCountries,
    setTwitterCountries,
    twitterLanguages,
    setTiwtterLanguages,
    twitterWordsCounter,
    setTwitterWordsCounter,
    getTwitterLanguagesAndCountries,
    twitterLanguagesAndCountries,
    setSelectedFacebookPages,
    selectedFacebookPages,
    facebookPages,
    setFacebookPages,
    getFacebookPages,
    isLoadingSelectList,
    setDisableSave,
    monitorTwitterOtherFieldLimit,
    monitorTwitterKeywordLimit,
    monitorInstagramHashtagLimit,
    monitorInstagramOldHashtags,
    monitorInstagramSettingHashtags,
    monitorFaceBookKeywordLimit,
    monitorFaceBookOtherFieldLimit,
    nbRanks,
    setNbRanks,
    nbDuplicated,
    setNbDuplicated,
  } = props;

  return (
    <>
      {monitorDetails?.monitor_type_name === "ACCOUNT_ANALYSIS" ? (
        <AccountAnalysisType
          monitorDetails={monitorDetails}
          setUpdatedParams={setUpdatedParams}
          updatedParams={updatedParams}
          handleKeywords={handleKeywords}
          setTiwtterLanguages={setTiwtterLanguages}
          twitterLanguages={twitterLanguages}
          setTwitterCountries={setTwitterCountries}
          twitterCountries={twitterCountries}
          getTwitterLanguagesAndCountries={getTwitterLanguagesAndCountries}
          twitterLanguagesAndCountries={twitterLanguagesAndCountries}
          twitterWordsCounter={twitterWordsCounter}
          keywords={props?.keywords}
          setSQuestion={props?.setSQuestion}
          handleValidation={props?.handleValidation}
          handleDelete={props?.handleDelete}
          monitorErrors={props?.monitorErrors}
          setMonitorErrors={props?.setMonitorErrors}
        />
      ) : monitorDetails?.monitor_type_name === "MANAGED_PAGE" ||
        monitorDetails?.monitor_type_name === "MANAGED_ACCOUNT" ? (
        <ManagedType
          monitorDetails={monitorDetails}
          setUpdatedParams={setUpdatedParams}
          updatedParams={updatedParams}
          monitorErrors={props.monitorErrors}
          setMonitorErrors={props.setMonitorErrors}
        />
      ) : monitorDetails?.monitor_type_name === "KEYWORD" ? (
        <KeywordsType
          monitorDetails={monitorDetails}
          setUpdatedParams={setUpdatedParams}
          updatedParams={updatedParams}
          keywords={props.keywords}
          handleKeywords={handleKeywords}
          setKeywords={props.setKeywords}
          answer={props.answer}
          spamAnswer={props.spamAnswer}
          sQuestion={props.sQuestion}
          setSQuestion={props.setSQuestion}
          handleDelete={props.handleDelete}
          handleValidation={props.handleValidation}
          handleData={props.handleData}
          wordCountFacebook={props.wordCountFacebook}
          wordCountInstagram={props.wordCountInstagram}
          titleSearchKeyword={props.titleSearchKeyword}
          collectTweets={props.collectTweets}
          ignoreTweets={props.ignoreTweets}
          wordCountNewsSpamKeyword={props.wordCountNewsSpamKeyword}
          nbExcludedSource={props.nbExcludedSource}
          setLanguage={props.setLanguage}
          language={props.language}
          setCountries={props.setCountries}
          countries={props.countries}
          setMajorSource={props.setMajorSource}
          majorSource={props.majorSource}
          nbRanks={nbRanks}
          setNbRanks={setNbRanks}
          nbDuplicated={nbDuplicated}
          setNbDuplicated={setNbDuplicated}
          wordCountNews={props.wordCountNews}
          setSelectedSource={props.setSelectedSource}
          selectedSource={props.selectedSource}
          setNbExcludedSource={props.setNbExcludedSource}
          setTitleSearchKeyword={props.setTitleSearchKeyword}
          setTitleSearchKeywordError={props.setTitleSearchKeywordError}
          setNbExcludedSourceError={props.setNbExcludedSourceError}
          newsBlogCount={props.newsBlogCount}
          monitorErrors={props.monitorErrors}
          setMonitorErrors={props.setMonitorErrors}
          setNBexceedLimit={setNBexceedLimit}
          twitterCountries={twitterCountries}
          setTwitterCountries={setTwitterCountries}
          twitterLanguages={twitterLanguages}
          setTiwtterLanguages={setTiwtterLanguages}
          twitterWordsCounter={twitterWordsCounter}
          setTwitterWordsCounter={setTwitterWordsCounter}
          getTwitterLanguagesAndCountries={getTwitterLanguagesAndCountries}
          twitterLanguagesAndCountries={twitterLanguagesAndCountries}
          selectedFacebookPages={selectedFacebookPages}
          setSelectedFacebookPages={setSelectedFacebookPages}
          facebookPages={facebookPages}
          setFacebookPages={setFacebookPages}
          getFacebookPages={getFacebookPages}
          isLoadingSelectList={isLoadingSelectList}
          setDisableSave={setDisableSave}
          monitorTwitterOtherFieldLimit={monitorTwitterOtherFieldLimit}
          monitorTwitterKeywordLimit={monitorTwitterKeywordLimit}
          monitorInstagramHashtagLimit={monitorInstagramHashtagLimit}
          monitorInstagramOldHashtags={monitorInstagramOldHashtags}
          monitorInstagramSettingHashtags={monitorInstagramSettingHashtags}
          monitorFaceBookKeywordLimit={monitorFaceBookKeywordLimit}
          monitorFaceBookOtherFieldLimit={monitorFaceBookOtherFieldLimit}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default MonitorTypeData;
