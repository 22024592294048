import { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { faCheckCircle, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import TitleBox from "../header/titleBox";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import sL from "images/home-page/sl.svg";
import OC from "images/home-page/oc.svg";
import CDP from "images/home-page/CDP.svg";
import luci from "images/home-page/luci.svg";
import Engagement from "images/home-page/Engagement.svg";
import survey from "images/home-page/survey.svg";
import urlLucidyaSuite from "./urlLucidyaSuite";
import "./lucidyaSuite.scss";
import CompaniesController from "services/controllers/companiesController";
SwiperCore.use([Navigation]);

const LucidyaSuite = (props) => {
  const { loading, luciSuite, loadingDelay, setSnackBar, snackBar } = props;
  const [serviceName, setServiceName] = useState("");
  const intl = useIntl();

  const handleDataIcon = (iconName) => {
    let iconByName = {
      SM: sL,
      CXM: OC,
      ENGAGEMENTS: Engagement,
      CDP: CDP,
      SURVEY: survey,
      LUCI: luci,
    };
    return iconByName[iconName] || sL;
  };
  const handleDataMsg = (message) => {
    let iconByName = {
      SM: "home_page_sm",
      CXM: "home_page_cxm",
      ENGAGEMENTS: "home_page_engagements",
      CDP: "home_page_cdp",
      SURVEY: "home_page_survey",
      LUCI: "home_page_luci",
    };
    return iconByName[message] || "home_page_luci";
  };
  const handleDataService = (serviceName) => {
    let iconByName = {
      SM: "request_social_listening_demo",
      CXM: "request_channel_analytics_demo",
      ENGAGEMENTS: "request_engagement_demo",
      CDP: "request_audience_demo",
      SURVEY: "request_survey_demo",
      LUCI: "request_luci_demo",
    };
    return iconByName[serviceName] || undefined;
  };
  const handleStaticUrl = (url) => {
    return urlLucidyaSuite[url] || undefined;
  };

  useEffect(() => {
    const requestService = () => {
      let queryData = {
        service_name: serviceName,
      };
      CompaniesController.requestService(queryData).then((result) => {
        if (!result?.errorMsg) {
          setSnackBar({
            open: true,
            title: "request_sent_subscription",
            message: "request_demo_success",
            severity: "success",
          });
        } else {
          setSnackBar({
            open: true,
            title: "request_demo_failed",
            message: "please_try_again_later",
            severity: "error",
          });
        }
        setTimeout(() => {
          setSnackBar({
            open: false,
            title: "",
            message: "",
            severity: "",
          });
        }, 3000);
      });
    };
    if (serviceName !== "") {
      requestService();
    }
  }, [serviceName]);
  const data = [
    {
      title: "1",
    },

    {
      title: "2",
    },
    {
      title: "3",
    },
    {
      title: "4",
    },
    {
      title: "5",
    },
  ];
  const swiperSlidefun = () => {
    return data?.map((el) => (
      <SwiperSlide>
        <Box className="skeleton-parant">
          <Box className="skeleton-head">
            <Skeleton variant="rounded" width="30px" height="30px"></Skeleton>
            <Skeleton width="100%" height="13px"></Skeleton>
            <Skeleton width="80%" height="13px"></Skeleton>
            <Skeleton width="60%" height="13px"></Skeleton>
          </Box>
          <Box>
            <Skeleton width="140px" height="13px"></Skeleton>
            <Skeleton width="140px" height="13px"></Skeleton>
            <Skeleton width="100px" height="13px"></Skeleton>
          </Box>
          <Skeleton width="100px" height="13px"></Skeleton>
        </Box>
      </SwiperSlide>
    ));
  };
  return (
    <Box className="section-slide lucidya-suite">
      <TitleBox
        title={"lucidya_suite"}
        tooltipText={"learn_more_lucidya"}
        customerView={"View_Of_Customers"}
      />
      <Box className="slider-container">
        <Swiper
          navigation
          observer={true}
          spaceBetween={15}
          slidesPerView={4.3}
          modules={[Navigation]}
          key={intl.locale}
        >
          {loading || loadingDelay
            ? swiperSlidefun()
            : luciSuite?.map((el) => {
                if (
                  el?.name !== "CXM_CORE" &&
                  el?.name !== "AI_API" &&
                  el?.name !== "PUBLIC_API"
                ) {
                  return (
                    <SwiperSlide>
                      <Box
                        className={
                          el?.active
                            ? "item-slide-luc_suite"
                            : "item-slide-luc_suite is-disabled"
                        }
                      >
                        <Box className="head-box">
                          <Box className="head">
                            {handleDataIcon(el?.name)?.length > 0 ? (
                              <Box className="img-box">
                                <img
                                  src={handleDataIcon(el?.name)}
                                  alt=""
                                  className={el?.active ? "" : "icon-lock"}
                                />
                              </Box>
                            ) : null}
                            <FontAwesomeIcon
                              icon={el?.active ? faCheckCircle : faLock}
                              className={el?.active ? "" : "icon-lock"}
                            />
                          </Box>
                          <Box>
                            <Typography className="luc_suite-name">
                              {CheckValueLocale(el?.name, "", {}, intl)}
                            </Typography>
                            <Typography className="luc_suite-message">
                              {CheckValueLocale(
                                handleDataMsg(el?.name),
                                "",
                                {},
                                intl,
                              )}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="btn-luci-suite">
                          {!el?.active ? (
                            <Button
                              className="luc_suite-btn"
                              onClick={() => {
                                setServiceName(handleDataService(el?.name));
                              }}
                            >
                              {CheckValueLocale(
                                "home_page_request_demo",
                                "",
                                {},
                                intl,
                              )}
                            </Button>
                          ) : null}
                          <Button
                            onClick={() =>
                              window.open(handleStaticUrl(el?.name))
                            }
                            className="luc_suite-btn"
                          >
                            {CheckValueLocale(
                              "home_page_learn_more",
                              "",
                              {},
                              intl,
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  );
                }
              })}
        </Swiper>
      </Box>
    </Box>
  );
};
export default LucidyaSuite;
