import { useIntl } from "react-intl";
import { useEffect, useState } from "react";
import moment from "moment";
import PopupModal from "components/popupModal.js";
import SnackBar from "components/snackBar";
import { Grid, Pagination, Box, Tooltip } from "@mui/material";
import QuotaItem from "./components/QuotaItem";
import CircularLoading from "components/circularProgress";
import OptionsDropDown from "./components/optionsDropDown.js";
import CreateHistorical from "./components/CreateHistorical";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";
import style from "./style.scss";
import CompaniesController from "../../../../services/controllers/companiesController";
import {
  getSocialIcon,
  CheckValueLocale,
  convertUnixTimeStampToUTCFormat,
  convertUnixTimeStampToTimeFormat,
} from "utils/helpers";
import PageHeader from "components/pageHeader";
import SharedTable from "../../../../shared/table/sharedTable";
import { sortedColumnTypes, useSortableTable } from "hooks/useSortableTable";
// import Pagination from "@mui/material/Pagination";
import SocialListening from "images/Historical Request/SocialListening.svg";
import Omnichannel from "images/Historical Request/Omnichannel.svg";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";

const HistoricalRequest = (props) => {
  const intl = useIntl();
  const [tableBody, setTableBody] = useState([]);
  const [loader, setLoader] = useState(true);
  const [quotas, setQuotas] = useState(null);
  const [historicalRequestsList, setHistoricalRequestsList] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [page, setPage] = useState(1);
  const [remainNewsBlogs, setRemainNewsBlogs] = useState();
  const [remainPosts, setRemainPosts] = useState();
  const [remainInteractions, setRemainInteractions] = useState();
  const [tableloader, setTableLoader] = useState(true);
  const [dataSources, setDataSources] = useState([]);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [success, setSuccess] = useState(null);
  const [retryLoader, setRetryLoader] = useState(false);
  const [createHistFlag, setCreateHistFlag] = useState(false);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);
  const [historicalRequestsSnackBar, setHistoricalRequestsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });

  const onSortChangeCallback = () => {
    setPage(1);
    setIsSortableLoading(true);
  };
  const {
    columns: tableHeaders,
    sortColumn,
    sortDirection,
    isSortableLoading,
    setIsSortableLoading,
  } = useSortableTable({
    initialColumns: [
      {
        body: CheckValueLocale("company_id", "", {}, intl),
        width: "5%",
        name: "id",
        type: sortedColumnTypes.number,
      },
      {
        body: CheckValueLocale("products", "", {}, intl),
        name: "product",
        type: sortedColumnTypes.string,
      },
      {
        body: CheckValueLocale("requested_by", "", {}, intl),
        name: "requested_by",
        type: sortedColumnTypes.string,
      },
      {
        body: CheckValueLocale("requested_to", "", {}, intl),
        name: "requested_to",
        type: sortedColumnTypes.string,
      },
      {
        body: CheckValueLocale("date_range", "", {}, intl),
        name: "date_range",
        type: sortedColumnTypes.date,
      },
      {
        body: CheckValueLocale("collected_data", "", {}, intl),
        name: "data_collected",
        type: sortedColumnTypes.number,
      },
      {
        body: CheckValueLocale("duplicate_data", "", {}, intl),
        name: "duplicate_data",
        type: sortedColumnTypes.number,
      },
      {
        body: CheckValueLocale("status", "", {}, intl),
        name: "status",
        type: sortedColumnTypes.string,
      },
      {
        body: " ",
      },
    ],
    onSortChangeCallback,
  });

  const startLoading = (loaderType) => {
    if (!isSortableLoading) {
      if (loaderType === "retryLoader") {
        setRetryLoader(true);
      } else {
        setTableLoader(true);
      }
    }
  };
  const stopLoading = () => {
    setRetryLoader(false);
    setTableLoader(false);
    setIsSortableLoading(false);
  };
  const handleCloseSnackBar = () => {
    setHistoricalRequestsSnackBar({ openSnackBar: false });
  };
  useEffect(() => {
    (async () => {
      await getCompanyDataInsightsQuota();
      await getHistoricalRequestsList("tableLoader");
      await createHistoricalRequestFlag();
    })();
  }, [page, sortColumn, sortDirection]);

  useEffect(() => {
    var dataSourcesObj = {};
    for (var i = 0; i < dataSources?.length; i++) {
      dataSourcesObj[dataSources[i]?.source] = dataSources[i].id;
    }

    localStorage.setItem("dataSourcesObj", JSON.stringify(dataSourcesObj));
  }, [dataSources]);
  useEffect(() => {
    getDataSources(setDataSources);
  }, []);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };
  const getCompanyDataInsightsQuota = () => {
    setLoader(true);
    CompaniesController.getCompanyDataInsightsQuota().then((result) => {
      setQuotas(result.data);
      var quotas = result.data;
      for (var i = 0; i < quotas.length; i++) {
        if (quotas[i].name === "SM") {
          setRemainNewsBlogs(
            quotas[i].quota.news_blogs.limit - quotas[i].quota.news_blogs.used,
          );
          setRemainPosts(
            quotas[i].quota.posts_limit.limit -
              quotas[i].quota.posts_limit.used,
          );
        }
        if (quotas[i].name === "CXM") {
          setRemainInteractions(
            quotas[i].quota.interactions.limit -
              quotas[i].quota.interactions.used,
          );
        }
      }
      setLoader(false);
    });
  };
  const getHistoricalRequestsList = (loaderType) => {
    startLoading(loaderType);
    CompaniesController.getHistoricalRequestsList(
      page,
      sortColumn,
      sortDirection,
    ).then((result) => {
      if (result?.errorMsg) {
        setHistoricalRequestsList([]);
        setPageData(null);
        setHistoricalRequestsSnackBar({
          message: "try_again_error_message",
          severity: "error",
          title: "failed_error_message",
          openSnackBar: true,
        });
      } else {
        setHistoricalRequestsList(result?.data?.data);
        setPageData(result?.data?.pagination);
      }
      stopLoading();
    });
  };

  const retryRequest = (reqId) => {
    setRetryLoader(true);
    CompaniesController.retryRequest(reqId).then((result) => {
      if (
        result.status === 201 ||
        result.status === 200 ||
        result.status === 204
      ) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(
          <div className="historical-snackbar historical-snackbar-success">
            <h3>
              {CheckValueLocale(
                "historical_request_retried_successfully",
                "",
                {},
                intl,
              )}
            </h3>
          </div>,
        );
        setSuccessStatus("success");
        getHistoricalRequestsList("retryLoader");
      } else {
        setSuccess(true);
        setSuccessStatus("error");
        setTimeout(() => {
          setSuccess(null);
        }, 7000);
        setSnackBarMsg(
          <div className="historical-snackbar historical-snackbar-fail">
            <h3>
              {CheckValueLocale(
                "retrying_historical_request_failed",
                "",
                {},
                intl,
              )}
            </h3>
            <span>{CheckValueLocale("try_again", "", {}, intl)}</span>
          </div>,
        );
      }
      createHistoricalRequestFlag();
      setRetryLoader(false);
    });
  };
  const createHistoricalRequestFlag = () => {
    CompaniesController.createHistoricalRequestFlag().then((result) => {
      setCreateHistFlag(result.data.processing);
    });
  };

  const getStatus = (status) => {
    return (
      <Box className={"tb-status tb-status-" + status}>
        {CheckValueLocale(status, "", {}, intl)}
      </Box>
    );
  };

  const getCreater = (creater, createdAt) => {
    const newCreatedAt = createdAt
      ?.split("T")[0]
      .split("-")
      .reverse()
      .join("-");
    return (
      <Box>
        <Box>{creater}</Box>
        <Box className={"created-at-historical"}>{newCreatedAt}</Box>
      </Box>
    );
  };

  const sourcesBodyCell = (item) => {
    return (
      <Box className="icon-div">
        {getSocialIcon(item?.attributes?.data_source.source)}
        <Box className="req-to">{item?.attributes?.monitor_model.name}</Box>
      </Box>
    );
  };
  const optionsCell = (item) => {
    return (
      <OptionsDropDown
        reqId={item?.id}
        reqPosts={item?.attributes?.collected}
        dataSource={item?.attributes?.data_source}
        getHistoricalRequestsList={getHistoricalRequestsList}
        createHistoricalRequestFlag={createHistoricalRequestFlag}
        status={item?.attributes?.status}
        retryRequest={retryRequest}
        snackBarMsg={snackBarMsg}
        successStatus={successStatus}
        success={success}
        createHistFlag={createHistFlag}
        retryLoader={retryLoader}
      />
    );
  };

  const grtProductIcon = (product) => {
    if (product?.name === "SM") {
      return (
        <Box
          component={"img"}
          src={SocialListening}
          alt={CheckValueLocale(product?.name, "", {}, intl)}
        />
      );
    }
    return (
      <Box
        component={"img"}
        src={Omnichannel}
        alt={CheckValueLocale(product?.name, "", {}, intl)}
      />
    );
  };

  const productsCell = (product) => {
    return (
      <Tooltip
        title={<Box>{CheckValueLocale(product?.name, "", {}, intl)}</Box>}
        arrow
        placement="top"
      >
        {grtProductIcon(product)}
      </Tooltip>
    );
  };

  const getDateRangeCell = (start, end) => {
    const dateRange = `${convertUnixTimeStampToUTCFormat(start)} - ${convertUnixTimeStampToUTCFormat(end)}`;
    const startTime = `${CheckValueLocale("start_time", "", {}, intl)}: ${convertUnixTimeStampToTimeFormat(start)}`;
    const endTime = `${CheckValueLocale("end_time", "", {}, intl)}: ${convertUnixTimeStampToTimeFormat(end)}`;
    return (
      <Tooltip
        title={
          <Box>
            <Box>{startTime}</Box>
            <Box>{endTime}</Box>
          </Box>
        }
        arrow
        placement="top"
      >
        {dateRange}
      </Tooltip>
    );
  };
  useEffect(() => {
    const arr = ["1"];
    const tabBody =
      historicalRequestsList?.length == 0
        ? !retryLoader
          ? []
          : arr?.map((item) => {
              return {
                data: [
                  {
                    body: null,
                  },
                  {
                    body: null,
                  },
                  {
                    body: null,
                  },
                  {
                    body: null,
                  },
                  {
                    body: <CircularLoading />,
                    className: "retry-historical-loader",
                  },
                  {
                    body: null,
                  },
                  {
                    body: null,
                  },
                  {
                    body: null,
                  },
                ],
              };
            })
        : historicalRequestsList?.map((item) => {
            return {
              data: [
                {
                  body: item?.id ? item?.id : "—",
                },
                {
                  body: productsCell(item?.attributes?.product),
                },
                {
                  body: item?.attributes?.created_by?.trim()?.length
                    ? getCreater(
                        item?.attributes?.created_by,
                        item?.attributes?.created_at,
                      )
                    : "—",
                  isCreatedBy: true,
                },
                {
                  body: sourcesBodyCell(item),
                },
                {
                  body: getDateRangeCell(
                    item?.attributes?.start_date,
                    item?.attributes?.end_date,
                  ),
                },
                {
                  body: `${
                    item?.attributes?.collected?.toLocaleString("en-US") ?? 0
                  } / ${
                    item?.attributes?.count_quota_requested?.toLocaleString(
                      "en-US",
                    ) ?? 0
                  }`,
                },
                {
                  body: `${
                    item?.attributes?.data_source?.id === 1
                      ? item?.attributes?.duplicates ?? 0
                      : "N/A"
                  }`,
                },
                {
                  body: getStatus(item?.attributes?.status),
                },
                {
                  body: optionsCell(item),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(historicalRequestsList));
  }, [
    retryLoader,
    historicalRequestsList,
    snackBarMsg,
    successStatus,
    success,
    createHistFlag,
    retryLoader,
    intl.locale,
  ]);

  const emptyBody = (historicalRequestsList) => {
    return (
      <>
        {historicalRequestsList?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="emptyHR"
              title="request_historical_data"
              description="request_historical_data_dec"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks.historical_request_link}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
            />
          </Box>
        ) : null}
      </>
    );
  };
  return (
    <>
      {loader && tableloader ? (
        <CircularLoading />
      ) : (
        <div id="historical-data">
          <div class="historical-data-intro">
            <Grid item xs={8}>
              <PageHeader
                title="historical_request"
                titleId="secondary-pages-header"
              />
            </Grid>
            <Grid container spacing={2}>
              {quotas?.map((quota, i) => {
                return (
                  <Grid item xs={quotas.length > 1 ? 6 : 12}>
                    <QuotaItem quota={quota} />
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <div className="historical-data-body">
            <div id="requestsListHeader">
              <Grid container>
                <Grid item xs={6}>
                  {CheckValueLocale("my_requests", "", {}, intl)} (
                  {pageData?.count})
                </Grid>
                {/* {handlelUserRoles("CXM", "CREATE_CHANNEL") && ( */}
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <CreateHistorical
                    remainNewsBlogs={remainNewsBlogs}
                    remainPosts={remainPosts}
                    remainInteractions={remainInteractions}
                    getHistoricalRequestsList={getHistoricalRequestsList}
                    createHistFlag={createHistFlag}
                    createHistoricalRequestFlag={createHistoricalRequestFlag}
                    retryLoader={retryLoader}
                  />
                </Grid>
                {/* )} */}
              </Grid>
            </div>
            {/* {handlelUserRoles("CXM", "VIEW_CHANNEL_LIST") && ( */}
            {tableloader === true ? (
              <CircularLoading />
            ) : (
              <>
                <SharedTable
                  isSharedStyle
                  tableHeader={tableHeaders}
                  tableBody={tableBody}
                  emptyBody={tableEmptyBody}
                  loading={isSortableLoading}
                />
                {pageData?.pages > 1 && !isSortableLoading ? (
                  <Pagination
                    onChange={handleChangePage}
                    page={page}
                    className="pagination-monitor-list"
                    count={pageData?.pages}
                    variant="outlined"
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      )}
      <SnackBar
        open={historicalRequestsSnackBar?.openSnackBar}
        severity={historicalRequestsSnackBar?.severity}
        message={CheckValueLocale(
          historicalRequestsSnackBar?.message,
          "",
          {},
          intl,
        )}
        title={CheckValueLocale(
          historicalRequestsSnackBar?.title,
          "",
          {},
          intl,
        )}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};
export default HistoricalRequest;
