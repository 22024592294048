import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
} from "@mui/material";
import { faMeh, faFrown, faSmile } from "@fortawesome/free-solid-svg-icons";
import "../page-view.scss";
import OverviewLinkIcon from "../../../../images/notification-icons/OverviewLink.svg";
import OverviewRetweetIcon from "../../../../images/notification-icons/OverviewRetweet.svg";
import OverviewHeartIcon from "../../../../images/notification-icons/OverviewHeart.svg";
import OverviewClendarIcon from "../../../../images/notification-icons/OverviewClendar.svg";
import OverviewClockIcon from "../../../../images/notification-icons/OverviewClock.svg";
import OverviewTagIcon from "../../../../images/notification-icons/OverviewTag.svg";
import ImagePlaceholder from "../../../../images/shared-images/placeholder-for-broken-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale, checkIfWeNeedShowMoreTweet } from "utils/helpers";
import FullTextTweetPopUp from "components/fullTextTweetPopUp";

const TweetOverView = (props) => {
  const intl = useIntl();
  const [sentimentIcon, setSentimentIcon] = useState("");
  const [sentimentLabel, setSentimentLabel] = useState("");
  const [sentimentColor, setSentimentColor] = useState("");
  const [showFullTextTweet, setShowFullTextTweet] = useState(false);
  const { timeZone } = props;
  const {
    user,
    post_link,
    truncated,
    extended_tweet,
    text,
    entities,
    retweet_count,
    quote_count,
    favorite_count,
    created_at,
    categories,
    category,
    overallSentiment_label,
    tweet_full_text,
  } = props?.singleTweet;
  const tweetText =
    truncated && extended_tweet !== undefined
      ? extended_tweet?.full_text
      : text;
  const tweet_time = moment(created_at).format("HH:mm A");
  const tweet_date = moment(created_at).format("DD-MM-YYYY");

  let mediaItem = {};
  let mediaUrl = "";

  const formatMediaUrl = (item) => {
    let url = "";
    if (Object.keys(item)?.length > 0) {
      if (item?.type === "video") {
        item?.video_info?.variants.forEach((variant) => {
          if (variant?.content_type === "video/mp4") {
            url = variant?.url;
          }
        });
      } else if (item?.type === "animated_gif") {
        url = item?.video_info?.variants[0]?.url;
      } else {
        url = item?.media_url_https;
      }
    }
    return url || null;
  };

  if (
    extended_tweet !== undefined &&
    extended_tweet?.entities !== undefined &&
    "media" in extended_tweet?.entities &&
    extended_tweet?.entities?.media?.length
  ) {
    mediaItem = extended_tweet?.extended_entities?.media[0];
    mediaUrl = formatMediaUrl(mediaItem);
  }
  if (entities !== undefined && "media" in entities && entities.media.length) {
    mediaItem = entities.media[0];
    mediaUrl = formatMediaUrl(mediaItem);
  }

  const getCategoryName = () => {
    if (categories !== undefined) {
      var categoryPos = categories[category];
      if (categoryPos !== undefined) {
        return categoryPos?.category_name;
      } else {
        return CheckValueLocale("unknown", "", {}, intl);
      }
    } else {
      return CheckValueLocale("unknown", "", {}, intl);
    }
  };

  const handleSentiment = () => {
    let icon, label, color;
    switch (overallSentiment_label) {
      case "Negative":
        icon = "negative";
        label = faFrown;
        color = "#E50C35";
        break;
      case "Positive":
        icon = "positive";
        label = faSmile;
        color = "#89BB2A";
        break;
      case "Neutral":
        icon = "neutral";
        label = faMeh;
        color = "#F9A700";
        break;
      default:
        icon = "none";
        label = faMeh;
        color = "#80868c";
        break;
    }
    setSentimentIcon(icon);
    setSentimentLabel(label);
    setSentimentColor(color);
  };
  const handleImageError = (event) => {
    event.target.src = ImagePlaceholder;
    event.target.alt = "placeholder-image";
  };

  useEffect(() => {
    handleSentiment();
  }, []);
  return (
    <>
      <Box className="overview-box">
        <Typography variant="h6" className="overview-box-title">
          {CheckValueLocale("overview", "", {}, intl)}
        </Typography>
        <Card className="overview-card">
          <CardHeader
            avatar={<Avatar src={user?.profile_image_url_https} />}
            action={
              <a
                aria-label="settings"
                href={post_link}
                target="_blank"
                rel="noreferrer"
              >
                <img src={OverviewLinkIcon} alt="overview icon" />
              </a>
            }
            title={user?.name}
            subheader={`@${user?.screen_name}`}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              {tweetText}
              {checkIfWeNeedShowMoreTweet(tweetText, tweet_full_text) ? (
                <Box
                  component="span"
                  className="tweet-full-text-read-all-btn"
                  onClick={() => setShowFullTextTweet(true)}
                >
                  {CheckValueLocale("read_all", "", {}, intl)}
                </Box>
              ) : null}
            </Typography>
            {mediaItem && mediaUrl && (
              <div className={`tweet-media-"${mediaItem?.type}`}>
                {mediaItem.type === "video" ? (
                  <video controls className="tweet-overview-media">
                    <source type="video/mp4" src={mediaUrl} />
                  </video>
                ) : (
                  <img
                    className="tweet-overview-media overview-content-img"
                    src={mediaUrl}
                    onError={handleImageError}
                    alt="overview media"
                  />
                )}
              </div>
            )}
          </CardContent>
          <CardActions disableSpacing>
            <Box>
              <img src={OverviewRetweetIcon} alt="retweet icon" />
              <Typography>{retweet_count + quote_count}</Typography>
            </Box>
            <Box className="heart-box">
              <img src={OverviewHeartIcon} alt="heart icon" />
              <Typography>{favorite_count}</Typography>
            </Box>
            <Box className="time-box">
              <img src={OverviewClendarIcon} alt="calender icon" />
              <Typography>{tweet_date}</Typography>
            </Box>
            <Box className="time-box">
              <img src={OverviewClockIcon} alt="time icon" />
              <Typography sx={{ direction: "ltr" }}>
                {tweet_time}{" "}
                {timeZone > 0
                  ? `${CheckValueLocale("gmt", "", {}, intl)}+${timeZone}`
                  : `${CheckValueLocale("gmt", "", {}, intl)}-${timeZone}`}
              </Typography>
            </Box>
            <Box className="time-box">
              <img src={OverviewTagIcon} alt="overview icon" />
              <Typography>
                {CheckValueLocale("overview_category", "", {}, intl)}:{" "}
                {getCategoryName()}
              </Typography>
            </Box>
            <Box style={{ color: sentimentColor, fontWeight: 500 }}>
              <FontAwesomeIcon
                icon={sentimentLabel}
                style={{ margin: "0px 5px" }}
              />
              {sentimentIcon
                ? CheckValueLocale(sentimentIcon, "", {}, intl)
                : ""}
            </Box>
          </CardActions>
        </Card>
        {showFullTextTweet ? (
          <FullTextTweetPopUp
            closeFilter={() => setShowFullTextTweet(false)}
            post={props?.singleTweet}
            tweet_date={moment(created_at).format("D MMM, YYYY h:mm A")}
            showRetweet={true}
            showHearts={true}
          />
        ) : null}
      </Box>
    </>
  );
};

export default TweetOverView;
