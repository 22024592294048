import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";

const PuplishPopup = (props) => {
  const intl = useIntl();
  const stepTwo =
    props?.customDomainStep ||
    props?.checkIsSurveyPage ||
    props?.surveyData?.published;
  const stepOne = !props?.customDomainStep || !props?.surveyData?.published;

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      id="alert-dialog"
      className={props.addClasses ? props.addClasses : ""}
    >
      <Box
        className={props.classeName ? props.classeName : ""}
        minWidth={props?.minWidth}
        height={props?.height}
      >
        {!props?.fixPadding && (
          <DialogTitle id="alert-dialog-title">
            {props?.checkIsSurveyPage
              ? CheckValueLocale("share_link", "", {}, intl)
              : CheckValueLocale("publish_survey", "", {}, intl)}
          </DialogTitle>
        )}
        <DialogContent className={props.modalStyle ? props.modalStyle : ""}>
          <DialogContentText id="alert-dialog-description">
            {props.body}
            {props.children}
          </DialogContentText>
        </DialogContent>

        {stepOne && !props?.checkIsSurveyPage && !stepTwo && (
          <DialogActions className={props.addClasses ? props.addClasses : ""}>
            {props.topicsExist && props.topicSelectedCounter}
            <>
              <LucButton
                onClick={props?.close}
                disabled={props?.checkSwitch}
                variant="outline"
              >
                {CheckValueLocale("cancel", "", {}, intl)}
              </LucButton>
              <LucButton
                id="pop-up-right-btn"
                className="survey-publish-btn"
                disabled={props?.disabled ? props?.disabled : false}
                onClick={() => {
                  props?.accept(props.monitor_id);
                }}
              >
                {!props.customDomainStep &&
                  !props?.checkIsSurveyPage &&
                  !props?.surveyData?.published &&
                  CheckValueLocale("survey_publish", "", {}, intl)}
              </LucButton>
            </>
          </DialogActions>
        )}
        {props?.checkIsSurveyPage && (
          <DialogActions className={props?.addClasses ? props?.addClasses : ""}>
            <LucButton
              variant="outline"
              disabled={props?.checkSwitch}
              onClick={props.close}
            >
              {CheckValueLocale("done", "", {}, intl)}
            </LucButton>
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
};
export default PuplishPopup;
