import { Box, Button, FormHelperText, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import "./mainSetup.scss";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "../../../../../utils/helpers/index.js";
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";

const NewReplayPopup = ({ newRepPopup, setNewRepPopup }) => {
  const intl = useIntl();

  const body = () => {
    return (
      <Box className="main-new-rep-popup">
        <Box className="rep-popup-title">
          <Box className="input-label">
            <Box className="body-input-label">
              {CheckValueLocale("rep_tit", "", {}, intl)}
            </Box>
            <Box className="input-label-nums">{`${
              newRepPopup?.title?.trim()?.length
            } / 35`}</Box>
          </Box>
          <FormControl className="input-field">
            <TextField
              disabled={newRepPopup?.loading}
              value={newRepPopup?.title}
              onChange={(e) =>
                setNewRepPopup({
                  ...newRepPopup,
                  title: e.target.value,
                  isDuplicate: false,
                })
              }
              variant="outlined"
              error={
                newRepPopup?.title?.trim()?.length > 35 ||
                newRepPopup?.isDuplicate
              }
              placeholder={CheckValueLocale(
                "rep_placeholder_title",
                "",
                {},
                intl,
              )}
            />
          </FormControl>
          {newRepPopup?.title?.trim()?.length > 35 ||
          newRepPopup?.isDuplicate ? (
            <FormHelperText error>
              {CheckValueLocale(
                newRepPopup?.isDuplicate
                  ? "saved_replies_title_duplicate_error"
                  : "tit_max",
                "",
                {},
                intl,
              )}
            </FormHelperText>
          ) : null}
        </Box>
        <Box className="rep-popup-contant">
          <Box className="input-label">
            <Box>{CheckValueLocale("rep_cont", "", {}, intl)}</Box>
            <Box className="input-label-nums">{`${
              newRepPopup?.contant?.trim()?.length
            } / 280`}</Box>
          </Box>
          <FormControl className="input-field">
            <TextField
              disabled={newRepPopup?.loading}
              value={newRepPopup?.contant}
              placeholder={CheckValueLocale(
                "rep_placeholder_text",
                "",
                {},
                intl,
              )}
              onChange={(e) =>
                setNewRepPopup({ ...newRepPopup, contant: e.target.value })
              }
              multiline
              maxRows={6}
              variant="outlined"
              error={newRepPopup?.contant?.trim()?.length > 280}
            />
          </FormControl>
          {newRepPopup?.contant?.trim()?.length > 280 ? (
            <FormHelperText error>
              {CheckValueLocale("cont_max", "", {}, intl)}
            </FormHelperText>
          ) : null}
        </Box>
      </Box>
    );
  };

  return (
    <PopupModal
      title={CheckValueLocale("saved_rep", "", {}, intl)}
      body={body()}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale(
        newRepPopup?.ai ? "move_save" : "save",
        "",
        {},
        intl,
      )}
      rightBtnId="save-reply-engag"
      leftBtnId="cancel-reply-engag"
      open={newRepPopup?.open}
      minWidth="600px"
      classeName="actions-buttons-shared"
      close={() =>
        !newRepPopup?.loading &&
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          ai: false,
          loading: false,
          done: false,
        })
      }
      disableLeftBtn={newRepPopup?.loading}
      disabled={
        newRepPopup?.loading ||
        newRepPopup?.contant?.trim()?.length > 280 ||
        newRepPopup?.title?.trim()?.length > 35 ||
        newRepPopup?.isDuplicate ||
        newRepPopup?.contant?.trim()?.length == 0 ||
        newRepPopup?.title?.trim()?.length == 0
      }
      acceptOtherOption={() =>
        setNewRepPopup({
          open: false,
          id: null,
          isDuplicate: false,
          title: "",
          contant: "",
          ai: false,
          loading: false,
          done: false,
        })
      }
      accept={() =>
        setNewRepPopup({
          ...newRepPopup,
          loading: true,
          done: true,
        })
      }
    />
  );
};

export default NewReplayPopup;
