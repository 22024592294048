import { useIntl } from "react-intl";
import { Grid } from "@mui/material";
import PieChartWidget from "components/widgets/pieChartWidget.js";
import { CheckValueLocale } from "utils/helpers";

const sumObjectsByKey = (...objs) => {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
    }
    return a;
  }, {});
};
const PieChartsTweet = (props) => {
  const intl = useIntl();
  const {
    AI_Stats,
    AI_Stats_retweeters,
    location_stats,
    location_stats_retweeters,
  } = props;
  const lang_id = localStorage.lang === "ar" ? "id_ar" : "id";
  let genderstData = [];
  let dialectstData = [];
  let subDialectData = [];
  let accountTypestData = [];
  let citiesData = [];
  let countriesData = [];
  let citiesName = "";
  let countryName = "";
  let citiesDistribution = {};
  let countryDistribution = {};

  // change format of data
  Object.entries(
    sumObjectsByKey(
      AI_Stats?.number_of_gender,
      AI_Stats_retweeters?.number_of_gender,
    ),
  ).forEach(([key, val]) => {
    genderstData.push({ name: key, value: val });
  });

  Object.entries(AI_Stats?.number_of_dialect).forEach(([key, val]) => {
    dialectstData.push({ name: key, value: val });
  });

  Object.entries(AI_Stats?.number_of_sub_dialect).forEach(([key, val]) => {
    Object.keys(val)?.map((keyName, i) =>
      subDialectData.push({ name: keyName, value: val[keyName] }),
    );
  });
  Object.entries(
    sumObjectsByKey(
      AI_Stats?.number_of_account_type,
      AI_Stats_retweeters?.number_of_account_type,
    ),
  ).forEach(([key, val]) => {
    accountTypestData.push({
      name: CheckValueLocale(key, "", {}, intl),
      value: val,
    });
  });

  location_stats.CitiesDistribution.concat(
    location_stats_retweeters.CitiesDistribution,
  ).map((item) => {
    citiesName = item[lang_id];
    citiesDistribution[citiesName] =
      (citiesDistribution[citiesName] ? citiesDistribution[citiesName] : 0) +
      item.stats_count;
  });

  Object.entries(citiesDistribution).forEach(([key, val]) => {
    citiesData.push({ name: key, value: val });
  });

  location_stats.CountriesDistribution.concat(
    location_stats_retweeters.CountriesDistribution,
  ).map((item) => {
    countryName = item[lang_id];
    countryDistribution[countryName] =
      (countryDistribution[countryName]
        ? countryDistribution[countryName]
        : 0) + item.stats_count;
  });

  Object.entries(countryDistribution).forEach(([key, val]) => {
    countriesData.push({ name: key, value: val });
  });
  return (
    <>
      <Grid item xs={6}>
        <PieChartWidget
          title={"dialects_subdialects"}
          data={dialectstData}
          subData={subDialectData}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={true}
          showDownloadIcon
          pieChartPreLoaderDataSources={[]}
          preLoaderTrackerDataSources={[]}
        />
      </Grid>
      <Grid item xs={6}>
        <PieChartWidget
          title={"countries"}
          data={countriesData}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showDownloadIcon
          pieChartPreLoaderDataSources={[]}
          preLoaderTrackerDataSources={[]}
        />
      </Grid>
      <Grid item xs={6}>
        <PieChartWidget
          title={"cities"}
          data={citiesData}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showDownloadIcon
          pieChartPreLoaderDataSources={[]}
          preLoaderTrackerDataSources={[]}
        />
      </Grid>
      <Grid item xs={6}>
        <PieChartWidget
          title={"genders"}
          data={genderstData}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          // color={["#6D737A", "#009BDF", "#F680BC"]}
          chartType={"gender"}
          showDownloadIcon
          pieChartPreLoaderDataSources={[]}
          preLoaderTrackerDataSources={[]}
        />
      </Grid>
      <Grid item xs={6}>
        <PieChartWidget
          title={"account_types"}
          data={accountTypestData}
          areaStyle={true}
          toolTipLabel={"toolTipLabel"}
          showAI={true}
          color={["#003D59", "#009BDF", "#45B9E9"]}
          showDownloadIcon
          pieChartPreLoaderDataSources={[]}
          preLoaderTrackerDataSources={[]}
        />
      </Grid>
    </>
  );
};

export default PieChartsTweet;
