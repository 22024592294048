import { useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";

import customTheme from "./customeTheme";
import { CheckValueLocale } from "utils/helpers";

const SentimentRepliesChart = (props) => {
  const registerTheme = () => {
    echarts.registerTheme("custom_theme", customTheme);
  };
  useEffect(() => {
    registerTheme();
  }, []);

  const toolTipFormatter = (params, ticket, callback) => {
    const { intl, activeTimeGroup, sentimentData, activeDay } = props;

    const { name, seriesName, value } = params;

    let formattedDateKey = "";
    let absoluteValue = 0;

    switch (activeTimeGroup) {
      case "week":
        const dateKey = name.replace("\n", "~");
        formattedDateKey = dateKey.replace("~", " : ");
        absoluteValue = sentimentData[dateKey][seriesName];
        break;
      case "month":
        formattedDateKey = name;
        absoluteValue = sentimentData[name][seriesName];
        break;
      case "hour":
        formattedDateKey = activeDay + " at " + name;
        absoluteValue = sentimentData[name][seriesName];
        break;
      default:
        formattedDateKey = name;
        absoluteValue = sentimentData[name][seriesName];
        break;
    }

    return `
      <div class="sentiment-replies-tooltip">
        <div class="sentim-dateKey">${formattedDateKey}</div>
        <div class="sentim-stats">
            <span>${CheckValueLocale(seriesName, "", {}, intl)}</span>
            <span>${absoluteValue} (${value}%)</span>
        </div>
      </div>
    `;
  };

  const { intl } = props;

  let positiveValues = [];
  let neutralValues = [];
  let negativeValues = [];

  let { sentimentData, xAxisData, activeTimeGroup } = props;

  if (xAxisData !== undefined) {
    xAxisData = xAxisData.map((dateKey) => {
      let Positive =
        dateKey in sentimentData && "Positive" in sentimentData[dateKey]
          ? sentimentData[dateKey].Positive
          : 0;
      let Neutral =
        dateKey in sentimentData && "Neutral" in sentimentData[dateKey]
          ? sentimentData[dateKey].Neutral
          : 0;
      let Negative =
        dateKey in sentimentData && "Negative" in sentimentData[dateKey]
          ? sentimentData[dateKey].Negative
          : 0;
      let total = dateKey in sentimentData ? sentimentData[dateKey].total : 0;

      positiveValues.push(total ? Math.round((Positive * 100) / total) : 0);
      neutralValues.push(total ? Math.round((Neutral * 100) / total) : 0);
      negativeValues.push(total ? Math.round((Negative * 100) / total) : 0);

      if (dateKey.includes("~")) {
        dateKey = dateKey.replace("~", "\n");
      }
      return dateKey;
    });
  }

  let xAxisLabel_rotate = 0;
  let zoomStatus = true;
  let zoomStart = 0;
  let intervalStatus = "auto";
  if (activeTimeGroup === "hour") {
    xAxisLabel_rotate = 45;
    zoomStatus = false;
    intervalStatus = 0;
  } else {
    zoomStart =
      xAxisData.length > 7
        ? ((xAxisData.length - 7) / xAxisData.length) * 100
        : 0;
  }

  const option = {
    color: ["#8DC63C", "#F6B92B", "#F10F41"],
    tooltip: {
      formatter: toolTipFormatter,
    },
    legend: {
      data: ["Positive", "Neutral", "Negative"],
      left: 0,
      top: 0,
      itemWidth: 10,
      itemHeight: 10,
      formatter: function (name) {
        return CheckValueLocale(name, "", {}, intl);
      },
    },
    label: {
      normal: {
        show: true,
      },
    },
    grid: {
      containLabel: true,
      left: 10,
      right: 10,
    },
    xAxis: {
      data: xAxisData,
      name: "",
      splitLine: { show: false },
      axisLabel: {
        rotate: xAxisLabel_rotate,
        interval: intervalStatus,
      },
      axisLine: {
        lineStyle: {
          type: "dashed",
        },
      },
    },
    yAxis: {
      splitArea: {
        show: false,
        lineStyle: "dashed",
      },
      type: "value",
      max: 100,
      axisLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLabel: {
        formatter: "{value} %",
      },
    },
    dataZoom: [
      {
        show: zoomStatus,
        start: 0,
        end: 100,
        bottom: 0,
      },
    ],
    series: [
      {
        name: "Positive",
        type: "bar",
        stack: "one",
        data: positiveValues,
        barMaxWidth: "75px",
        // label: {
        //   normal: {
        //     show: true,
        //     formatter: (a) => (a.value ? a.value + "%" : ""),
        //   },
        // },
      },
      {
        name: "Neutral",
        type: "bar",
        stack: "one",
        data: neutralValues,
        barMaxWidth: "75px",
        // label: {
        //   normal: {
        //     show: true,
        //     formatter: (a) => (a.value ? a.value + "%" : ""),
        //   },
        // },
      },
      {
        name: "Negative",
        type: "bar",
        stack: "one",
        data: negativeValues,
        barMaxWidth: "75px",
        // will leave it as a comment in case we need it in future
        // label: {
        //   normal: {
        //     show: true,
        //     formatter: (a) => (a.value ? a.value + "%" : ""),
        //   },
        // },
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      theme="custom_theme"
      style={{ height: "350px", width: "100%" }}
    />
  );
};

export default SentimentRepliesChart;
