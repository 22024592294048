import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Box, Divider, TextField } from "@mui/material";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import "../surveyBuilder.scss";

const EditSectionHeader = (props) => {
  const { surveyData, setSurveyData } = props;
  const inputRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (
      surveyData?.title === "Untitled Survey" ||
      surveyData?.title === "نموذج بدون عنوان"
    ) {
      inputRef?.current?.focus();
      inputRef?.current?.select();
    }
  }, []);

  const handleSurveyTitle = (e) =>
    setSurveyData({ ...surveyData, title: e.target.value });
  const handleSurveyDescription = (e) =>
    setSurveyData({ ...surveyData, subtitle: e.target.value });

  return (
    <Box className="header-style">
      <Divider className="divider-style" />
      <Box mx={4} mt={4}>
        <TextField
          inputRef={inputRef}
          id="outlined-basic"
          variant="standard"
          className="title-header-field"
          value={surveyData?.title}
          onChange={handleSurveyTitle}
          style={{
            direction: checkKeyboardLanguage(surveyData?.title) ? "rtl" : "ltr",
          }}
          name="title"
          placeholder={CheckValueLocale("Untitled Survey", "", {}, intl)}
          disableUnderline={true}
          multiline
          fullWidth
        />
      </Box>
      <Box mx={4} mt={2}>
        <TextField
          variant="standard"
          className="subtitle-header-field"
          value={surveyData?.subtitle}
          style={{
            direction: checkKeyboardLanguage(surveyData?.subtitle)
              ? "rtl"
              : "ltr",
          }}
          name="subtitle"
          onChange={handleSurveyDescription}
          placeholder={CheckValueLocale(
            "Survey Description (Optional)",
            "",
            {},
            intl,
          )}
          disableUnderline={true}
          multiline
          fullWidth
        />
      </Box>
    </Box>
  );
};

export default EditSectionHeader;
