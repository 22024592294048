import { useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import { PlayArrow, Delete, MoreHoriz, Pause, Sync } from "@mui/icons-material";
import PopupModal from "components/popupModal.js";
import SnackBar from "components/snackBar.js";
//Locals
import { useIntl } from "react-intl";
import CompaniesController from "../../../../../services/controllers/companiesController";
import { handlelUserRoles, CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRetweet,
  faTrashAlt,
  faRepeatAlt,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";

const OptionsDropDown = (props) => {
  const intl = useIntl();

  const [stats, setStats] = useState({
    anchorEl: null,
    openDeleteModal: false,
    openPauseResumeModal: false,
    modalTitle: "",
    modalBody: "",
    modalRightBtn: "",
    modalLeftBtn: "",
    openSnackBar: false,
    snackBarAction: "",
    snackBarSeverity: "success",
  });
  const [stopRequestSnackBar, setStopRequestSnackBar] = useState(null);
  const [stopSuccess, setStopSuccess] = useState(false);
  const [stopLoader, setStopLoader] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(null);
  const StopRequestmodalBody = (
    <Box>
      <Box component={"span"} className="question-stop-request">
        {CheckValueLocale("َquestion_stop_request", "", {}, intl)}
      </Box>
      <Box className="estimated-collected">
        <Box className="estimated-collected-text">
          {CheckValueLocale("estimated_Collected", "", {}, intl)}
        </Box>
        <Box className="estimated-collected-number">{props?.reqPosts || 0}</Box>
      </Box>
    </Box>
  );
  const open = Boolean(stats.anchorEl);
  window.addEventListener("scroll", (event) => {
    if (open) {
      handleClose();
    }
  });

  const handleClick = (event) => {
    setStats({
      ...stats,
      anchorEl: event.currentTarget,
      openPauseResumeModal: false,
    });
  };
  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };
  const handleOpenDeleteModal = () => {
    setStats({
      ...stats,
      openDeleteModal: true,
      modalTitle: `${CheckValueLocale(
        "delete_historical_Request",
        "",
        {},
        intl,
      )}`,
      modalBody: (
        <div className="delete-pop-body">
          {CheckValueLocale("are_you_sure_you_want_to_delete", "", {}, intl) +
            "(" +
            props.reqId +
            "),"}
          <div>
            {CheckValueLocale("knowing_that_it_has", "", {}, intl) +
              "[" +
              props.reqPosts +
              " " +
              CheckValueLocale("post_interactions", "", {}, intl) +
              "]" +
              "?"}
          </div>

          <div>
            {CheckValueLocale("this_action_cant_be_undone", "", {}, intl)}
          </div>
        </div>
      ),
      modalRightBtn: CheckValueLocale("delete", "", {}, intl),
      modalLeftBtn: CheckValueLocale("cancel", "", {}, intl),
      anchorEl: null,
    });
  };

  const handleCloseDeleteModal = () => {
    setStats({ ...stats, openDeleteModal: false });
  };

  const handleDeleteReq = (reqId) => {
    CompaniesController.deleteHistReq(reqId).then((data) => {
      if (data.data.status === 204) {
        showSnackBar("delete", "success");
        setTimeout(() => {
          props.getHistoricalRequestsList("retryLoader");
        }, 3000);
      } else {
        showSnackBar("delete", "warning");
      }
    });
  };

  const showSnackBar = (action, severity) => {
    setStats({
      ...stats,
      openSnackBar: true,
      snackBarAction: action,
      snackBarSeverity: severity,
      openDeleteModal: false,
      openPauseResumeModal: false,
      snackBarMsg: "",
    });
    window.scrollTo(0, 0);
    if (action === "delete" && severity === "success") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: CheckValueLocale(
          "historical_deleted_successfully",
          "",
          {},
          intl,
        ),
      });
    } else if (action === "delete" && severity === "warning") {
      setStats({
        ...stats,
        openSnackBar: true,
        snackBarAction: action,
        snackBarSeverity: severity,
        openDeleteModal: false,
        snackBarMsg: CheckValueLocale(
          "historical_deleted_failed",
          "",
          {},
          intl,
        ),
      });
    }
  };

  const handleCloseSnackBar = () => {
    setStats({ ...stats, openSnackBar: false });
  };

  const stopRequestAction = async () => {
    setOpenStopModal(null);
    setStopLoader(true);
    const result = await CompaniesController.stopRequest(props?.reqId);
    setStopRequestSnackBar(true);
    setTimeout(() => {
      setStopRequestSnackBar(null);
    }, 7000);
    if (result?.status === 204) {
      setStopSuccess(true);
    } else {
      setStopSuccess(false);
    }
    props?.getHistoricalRequestsList("retryLoader");
    props?.createHistoricalRequestFlag();
    setStopLoader(false);
    handleClose();
  };

  return (
    <Box className="menu-list">
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <Button
          variant="text"
          className={
            "menu-items menu-item-container " + (open ? "menu-item-active" : "")
          }
          aria-label="more"
          id="social-media-dropdown-long-button"
          aria-controls="long-menu"
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          onClose={handleClose}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </Button>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={stats.anchorEl}
        open={open}
        onClose={handleClose}
        className="manage-menu historical-droplist"
        anchorOrigin={
          intl.locale !== "ar" && {
            vertical: "bottom",
            horizontal: "right",
          }
        }
        transformOrigin={
          intl.locale !== "ar" && {
            vertical: "top",
            horizontal: "right",
          }
        }
      >
        {/* <MenuItem
          onClick={handleOpenDeleteModal}
          disableRipple
          className="drop-down-btn"
          disabled={props.status === "processing" ? true : false}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
          {CheckValueLocale("delete" ,"", {},intl)}
        </MenuItem>{" "}
         */}
        <MenuItem
          onClick={() => props.retryRequest(props.reqId)}
          disableRipple
          className="drop-down-btn"
          disabled={
            props.status !== "failed" ||
            props.createHistFlag === true ||
            props.retryLoader === true
          }
          id="historical-request-dropdown-retry"
        >
          <FontAwesomeIcon icon={faRepeatAlt} />
          {CheckValueLocale("retry", "", {}, intl)}
        </MenuItem>

        {props?.dataSource?.source === "TWITTER" ? (
          <MenuItem
            onClick={() => setOpenStopModal(true)}
            className="drop-down-btn"
            id="historical-request-dropdown-stop"
            disabled={props?.status !== "processing" || stopLoader}
          >
            <FontAwesomeIcon icon={faTimes} />
            {CheckValueLocale("stop_request", "", {}, intl)}
          </MenuItem>
        ) : null}
      </Menu>

      {stats.openDeleteModal && (
        <PopupModal
          title={stats.modalTitle}
          body={stats.modalBody}
          leftBtn={stats.modalLeftBtn}
          rightBtn={stats.modalRightBtn}
          open={stats.openDeleteModal}
          close={handleCloseDeleteModal}
          accept={() => handleDeleteReq(props.reqId)}
          warning="warning"
        />
      )}

      {openStopModal && (
        <PopupModal
          title={CheckValueLocale("stop_request", "", {}, intl)}
          body={StopRequestmodalBody}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("stop_request", "", {}, intl)}
          open={openStopModal}
          close={() => setOpenStopModal(null)}
          accept={stopRequestAction}
          warning
        />
      )}
      {stopRequestSnackBar && (
        <SnackBar
          open={true}
          severity={stopSuccess ? "success" : "error"}
          title={
            stopSuccess
              ? CheckValueLocale("request_stoped_successfully", "", {}, intl)
              : `${CheckValueLocale("something_went_wrong", "", {}, intl)}!`
          }
          message={
            stopSuccess
              ? ""
              : CheckValueLocale("please_try_again_later", "", {}, intl)
          }
          showCloseBtn={true}
        />
      )}

      {props.success && (
        <SnackBar
          open={props.success != null ? true : false}
          severity={props.successStatus}
          message={props.snackBarMsg}
          showCloseBtn={true}
        />
      )}
    </Box>
  );
};
export default OptionsDropDown;
