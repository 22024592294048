import Services from "./Services";

var Surveys = {};

Surveys.getSurveys = (queryData) => {
  return Services.getData("api/v3/surveys", queryData);
};

Surveys.getSurveysById = (id, queryData) => {
  return Services.getData(`api/v3/surveys/${id}`, queryData);
};

Surveys.createSurveys = (queryData) => {
  return Services.postData("api/v3/surveys", queryData);
};

Surveys.saveSurveyChanges = (id, queryData) => {
  return Services.patchData(`api/v3/surveys/${id}`, queryData);
};

Surveys.unpublishSurvey = (id, queryData) => {
  return Services.postData(`api/v3/surveys/${id}/unpublish`, queryData);
};

Surveys.getSurveyInsights = (queryData) => {
  return Services.getData(`api/v3/surveys/insights`, queryData);
};

Surveys.editSentiment = (queryData) => {
  return Services.putData(
    "api/v3/surveys/interactions/edit_sentiment_interactions",
    queryData,
  );
};

Surveys.editThemes = (queryData) => {
  return Services.putData(
    "api/v3/surveys/interactions/edit_themes_interactions",
    queryData,
  );
};

Surveys.editSubThemes = (queryData) => {
  return Services.putData(
    "api/v3/surveys/interactions/edit_sub_themes_interactions",
    queryData,
  );
};

Surveys.exportSurvey = (queryData) => {
  return Services.postData(`api/v3/export/xlsx`, queryData);
};

Surveys.duplicateSurvey = (id, queryData) => {
  return Services.postData(`api/v3/surveys/${id}/duplicate`, queryData);
};

Surveys.getSurveyspanelList = (queryData) => {
  return Services.getData("api/v4/survey/survey_panel/list", queryData);
};

Surveys.submitSurveyspanel = (queryData) => {
  return Services.postData(`api/v4/survey/survey_panel`, queryData);
};
Surveys.SurveyspanelById = (id, queryData) => {
  return Services.getData(`api/v4/survey/survey_panel/${id}`, queryData);
};
Surveys.submitSurveysSkip = (id, queryData) => {
  return Services.postData(`api/v4/survey/survey_panel/skip/${id}`, queryData);
};

export default Surveys;
