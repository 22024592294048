import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { Box, Container } from "@mui/material";
import "./main.scss";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import CreateDashboardFunction from "./components/createDashboardFunction";
import LiveDashBoardHeader from "./components/liveDashBoardHeader";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";
import LiveDashBoardFields from "./components/liveDashBoardFields";
import LiveDashBoardActions from "./components/liveDashBoardActions";
import LiveDashBoardDataSources from "./components/liveDashBoardDataSources";

const CreateLiveDashboard = () => {
  const intl = useIntl();
  const urlParams = useParams();
  const history = useHistory();

  const {
    liveDashboardName,
    selectedWidgets,
    expanded,
    setExpanded,
    loadingMonitorWidgets,
    monitorWidgets,
    isActionLoading,
    success,
    setSuccess,
    snackBarHeading,
    handleChangeCollapse,
    getMonitorWidgets,
    createLiveDashboardHandler,
    editLiveDashboardHandler,
    handleSelectWidget,
    getSourceName,
    monitorLiveDashboardDetails,
    getMonitorLiveDashboardDetails,
    loadingMonitorLiveDashboardDetails,
    handleLiveDashboardDetails,
    dataSources,
    setDataSources,
    deleteLiveDashboard,
    openPopupDelete,
    setOpenPopupDelete,
    isDeleteLoading,
    isEditLiveDashboard,
    getSelectedWidgetsLength,
    getDataSourceTabsSorted,
    getMonitorUrl,
    getSocialIcon,
    handleInputChange,
    liveDashboardNameError,
    hasLiveDashboard,
    getMonitorLiveDashboardList,
  } = CreateDashboardFunction();

  useEffect(() => {
    getDataSources(setDataSources);
  }, []);

  useEffect(() => {
    if (monitorWidgets) {
      const firstDataSource = Object.keys(monitorWidgets || {})?.[0];
      setExpanded(firstDataSource);
      handleChangeCollapse(firstDataSource);
    }
  }, [monitorWidgets]);

  useEffect(() => {
    if (monitorLiveDashboardDetails) {
      handleLiveDashboardDetails();
    }
  }, [monitorLiveDashboardDetails]);

  useEffect(() => {
    if (urlParams?.monitorId) {
      getMonitorWidgets();
      getMonitorLiveDashboardList();
    }
  }, [urlParams?.monitorId]);

  useEffect(() => {
    if (urlParams?.monitorId && urlParams?.userDashboardId) {
      getMonitorLiveDashboardDetails();
    }
  }, [urlParams?.monitorId, urlParams?.userDashboardId]);

  const selectedWidgetsLength = Object.keys(selectedWidgets || {})?.find(
    (dataSource) => selectedWidgets?.[dataSource]?.length,
  );
  const actionButtonDisabled =
    !liveDashboardName ||
    isActionLoading ||
    !selectedWidgetsLength ||
    liveDashboardNameError;

  const loadingCondition =
    loadingMonitorWidgets ||
    loadingMonitorLiveDashboardDetails ||
    !dataSources?.length ||
    hasLiveDashboard;
  return (
    <>
      <Container maxWidth="xl" className="live-dashboard-container">
        {loadingCondition ? (
          <CircularLoading />
        ) : (
          <>
            <LiveDashBoardHeader
              isEditLiveDashboard={isEditLiveDashboard}
              monitorWidgets={monitorWidgets}
              getMonitorUrl={getMonitorUrl}
            />
            <Box className="create-live-dashboard-container">
              <LiveDashBoardFields
                liveDashboardName={liveDashboardName}
                isEditLiveDashboard={isEditLiveDashboard}
                deleteLiveDashboard={deleteLiveDashboard}
                openPopupDelete={openPopupDelete}
                setOpenPopupDelete={setOpenPopupDelete}
                isDeleteLoading={isDeleteLoading}
                handleInputChange={handleInputChange}
                liveDashboardNameError={liveDashboardNameError}
              />
              <LiveDashBoardDataSources
                monitorWidgets={monitorWidgets}
                getSourceName={getSourceName}
                handleChangeCollapse={handleChangeCollapse}
                expanded={expanded}
                selectedWidgets={selectedWidgets}
                handleSelectWidget={handleSelectWidget}
                getSelectedWidgetsLength={getSelectedWidgetsLength}
                getDataSourceTabsSorted={getDataSourceTabsSorted}
                getSocialIcon={getSocialIcon}
              />
              <LiveDashBoardActions
                isEditLiveDashboard={isEditLiveDashboard}
                actionButtonDisabled={actionButtonDisabled}
                isActionLoading={isActionLoading}
                editLiveDashboardHandler={editLiveDashboardHandler}
                createLiveDashboardHandler={createLiveDashboardHandler}
              />
            </Box>
            {success != null ? (
              <SnackBar
                open={success != null ? true : false}
                handleClose={() => {
                  setSuccess(null);
                }}
                severity={success ? "success" : "error"}
                title={CheckValueLocale(snackBarHeading, "", {}, intl)}
                message={CheckValueLocale(
                  "redirecting_to_your_live_dashboard",
                  "",
                  {},
                  intl,
                )}
              />
            ) : (
              ""
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default CreateLiveDashboard;
