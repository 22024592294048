import { Box, Button, Typography } from "@mui/material";
import SideBarHeading from "./sideBarHead/sideBarHeading";
import SideBarTabs from "./sideBarTabs/index";
import Filters from "./filters/filters";
import React, { useState, useEffect } from "react";
import moment from "moment";
import EngagementsController from "services/controllers/engagementsController";
import { connectWithRabbitMQ } from "services/controllers/rabbitMQResponce";
import { isEmptyObj } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { clear } from "utils/redux/features/Engagements/EngagementsSlice.js";
import { useParams } from "react-router-dom";
import AlertController from "services/controllers/alertController";
import { useHistory } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import mqttRabbitMQResponce from "services/controllers/mqttRabbitMQResponce";
import SlaSidebar from "./slaSidebar/slaSideBar";

const EngagementsSideBar = ({
  selectedCard,
  setSelectedCard,
  setCardState,
  cardState,
  refresh,
  dates,
  setDates,
  totalEngagements,
  openNavigationMenu,
  setOpenNavigationMenu,
  navigationMenuStatus,
  setNavigationMenuStatus,
  openSetupFlow,
  setOpenSetupFlow,
  openSetupFlowFirst,
  setOpenSetupFlowFirst,
  tabValue,
  setTabValue,
  complate,
  isDoneSetup,
  setIsDoneSetup,
  openNewConversation,
  setOpenNewConversation,
  isSLASideBarOpen,
  setIsSLASideBarOpen,
  setOpenSidebarToAssignTeam,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const reduxSelectedCard = useSelector(
    (state) => state?.engagements?.selectedCard,
  );
  const mqttConnectionClosed = React.useRef(false);
  const clientMQTT = React.useRef();
  const [response, setResponse] = useState({});
  const [openFilters, setOpenFilters] = useState(false);
  const [enabledFilters, setEnabledFilters] = useState({});
  const [totalEngagementsFlag, setTotalEngagementsFlag] = useState(false);
  const [savedFilters, setSavedFilters] = useState({
    data_sources: [],
    company_monitors: {
      monitors: [],
      channels: [],
    },
    engagement_types: [],
    completion_reasons: [],
    engegements_id: "",
    themes: [],
    sub_themes: [],
    sentiments: [],
    questions_only: false,
    verified_accounts_only: false,
    min_followers: "0",
    users_accounts: [],
    sla: [],
    team: [],
    assign: [],
  });

  const [allData, setAllData] = useState({});
  const [preLoader, setPreLoader] = useState([]);
  const [filters, setFilters] = useState({
    endDate: moment().tz("utc", true).unix(),
    startDate: moment().tz("utc", true).subtract(6, "d").startOf("day").unix(),
    keywords: "",
    sort_by: "newest",
    company_monitors: {
      monitors: [],
      channels: [],
    },
    page: 1,
    engagement_status: "new",
    interaction_date: "",
    // in case the user is redirected from the alert page
    // made the tab is all engagements
    sla_status: window.location.href.includes("alert_id")
      ? "all_sla"
      : "my_assigned_sla",
  });
  const [lastOperation, setLastOperation] = useState(0);
  const [engagementPostId, setEngagementPostId] = useState(null);
  // incase alert
  const history = useHistory();
  const urlParams = useParams();
  const [slaCounterData, setSlaCounterData] = useState({
    loading: true,
    values: {
      all: 0,
      my_assigned: 0,
      overdue: 0,
      team_assigned: 0,
      unassigned: 0,
    },
  });
  const [samrtSortingNew, setSamrtSortingNew] = useState(false);
  const [samrtSortingPopup, setSamrtSortingPopup] = useState(false);
  const [filtersChangesCount, setFiltersChangesCount] = useState(0);

  // Here we call enabledFilters Api to get all filters Fields.
  useEffect(() => {
    EngagementsController.enabledFilters(
      window.localStorage.getItem("engagementsProductId"),
    ).then((data) => {
      setEnabledFilters({ ...data?.data });
      applyFilters(true, { ...data?.data });
    });
  }, []);

  //handle sorting change
  const filterChanged = (sort) => {
    const filters = {
      newest: "end_date",
      oldest: "start_date",
      newest_comp: "end_date",
      oldest_comp: "start_date",
      default: sort,
    };
    return filters[sort] || filters["default"];
  };

  /*
calling getEngagementsData to get list of cards.
getEngagementsData this api called twice at every time 
at first time we call it if is_rabbitmq_data is eqaul true so we connect with RabbitMQ to get cards 
data , at second time if is_rabbitmq_data is eqaul false so page will be sent as next page and this for performance from Backend.
*/
  const getEngagemetSidebarData = (queryData) => {
    EngagementsController.getEngagementsData({ ...queryData }).then((data) => {
      if (queryData?.is_rabbitmq_data) {
        if (data?.data?.password && !isEmptyObj(data?.data)) {
          setPreLoader([...data?.data?.data_sources]);
          setSamrtSortingNew(data?.data?.smart_sorting_choice);
          setResponse({});
          // connectWithRabbitMQ(
          //   mqttConnectionClosed,
          //   clientMQTT,
          //   data?.data,
          //   setResponse
          // );
          mqttRabbitMQResponce(clientMQTT, data?.data, setResponse);
        } else {
          mqttConnectionClosed.current = true;
          // window.location.reload();
        }
      }
    });
  };

  const applyFilters = (isRabMq = true, enabledFilter = enabledFilters) => {
    //if user select monitors or channel we send it to BE, else we send all list
    let allMonitorsAndChannels = [
      ...(enabledFilter?.company_monitors?.channels?.length
        ? enabledFilter?.company_monitors?.channels?.map((item) => item?.id)
        : []),
      ...(enabledFilter?.company_monitors?.monitors?.length
        ? enabledFilter?.company_monitors?.monitors?.map((item) => item?.id)
        : []),
    ];
    let selectedMonitorsAndChannels = [
      ...filters?.company_monitors?.monitors,
      ...filters?.company_monitors?.channels,
    ];
    //reduxSelectedCard used when redirect from social lisining or omnichannels
    if (!isEmptyObj(reduxSelectedCard)) {
      savedFilters.engegements_id =
        reduxSelectedCard?.system_replies?.engagement_id;
      setSavedFilters({ ...savedFilters });
      filters.company_monitors.monitors = [reduxSelectedCard?.tracker_id];
      filters.company_monitors.channels = [];

      filters.interaction_date = `${reduxSelectedCard?.created_at}`;
      filters.engagement_status = "all";
      setFilters({ ...filters });
      //setSelectedCard({ ...reduxSelectedCard });
      dispatch(clear());
    }

    //here we check if sla selected tab is all engagments or overdue so we send selected filters fields for (slas_ids,teams_ids,assignees_ids)
    const isAllOrOverdue = ["all", "overdue"].includes(
      filters?.sla_status.replace("_sla", ""),
    );
    const slasFilters = isAllOrOverdue
      ? {
          slas_ids: savedFilters?.sla?.length
            ? savedFilters?.sla?.join(",")
            : undefined,
          teams_ids: savedFilters?.team?.length
            ? savedFilters?.team?.join(",")
            : undefined,
          assignees_ids: savedFilters?.assign?.length
            ? savedFilters?.assign?.join(",")
            : undefined,
        }
      : {};
    let queryData = {
      product_id: window.localStorage.getItem("engagementsProductId"),
      start_date: dates?.startDate,
      end_date: dates?.endDate,
      monitors: selectedMonitorsAndChannels?.length
        ? selectedMonitorsAndChannels?.join(",")
        : allMonitorsAndChannels?.join(","),
      page_number: filters?.page,
      engagement_tab: filters?.sla_status?.replace("_sla", ""),
      filters: {
        completion_reasons: savedFilters?.completion_reasons.length
          ? savedFilters.completion_reasons?.join(",")
          : undefined,
        interaction_date: filters?.interaction_date.length
          ? filters?.interaction_date
          : undefined,
        keywords: filters?.keywords,
        sort_by:
          !filters?.sort_by?.length ||
          filters?.sort_by === "newest" ||
          filters?.sort_by === "newest_comp"
            ? "latest_posts"
            : filters?.sort_by === "oldest_comp"
              ? "oldest"
              : filters?.sort_by,
        data_sources: savedFilters?.data_sources?.length
          ? savedFilters?.data_sources?.join(",")
          : undefined,
        engagement_types: savedFilters?.engagement_types?.length
          ? savedFilters?.engagement_types?.join(",")
          : undefined,
        engagement_id: savedFilters?.engegements_id?.length
          ? savedFilters?.engegements_id
          : undefined,
        themes: savedFilters?.themes?.length
          ? savedFilters?.themes?.join(",")
          : undefined,
        sub_themes: savedFilters?.sub_themes?.length
          ? savedFilters?.sub_themes?.join(",")
          : undefined,
        sentiment: savedFilters?.sentiments?.length
          ? savedFilters?.sentiments?.join(",")
          : undefined,
        is_question: !savedFilters?.questions_only ? undefined : true,
        verified_status: !savedFilters?.verified_accounts_only
          ? undefined
          : true,
        min_followers: savedFilters?.min_followers?.length
          ? +savedFilters?.min_followers
          : undefined,
        usernames: savedFilters?.users_accounts?.length
          ? savedFilters?.users_accounts?.join(",")
          : undefined,
        engagement_status: filters?.engagement_status,
        ...slasFilters,
      },
      last_type: ["newest", "oldest", "newest_comp", "oldest_comp"]?.includes(
        filters?.sort_by,
      )
        ? "created_at"
        : filters?.sort_by === "smart_sorting"
          ? "smart_order"
          : [
                "first_response_time",
                "next_response_time",
                "unassigned_response_time",
                "time_to_complete",
              ]?.includes(filters?.sort_by)
            ? "sla"
            : filters?.sort_by.replace("most_", ""),
      last_operation: filters?.page > 1 ? lastOperation : 0,
      filter_changed: filterChanged(filters?.sort_by),
      engagement_post_id:
        filters?.page > 1 ? JSON.stringify(engagementPostId) : null,
      is_rabbitmq_data: isRabMq,
    };

    filters.interaction_date = "";
    // count the number of changes in the filters
    let count = 0;
    count += !!selectedMonitorsAndChannels?.length;
    count += !!queryData?.filters?.completion_reasons?.length;
    count += !!queryData?.filters?.interaction_date?.length;
    count += !!queryData?.filters?.data_sources?.length;
    count += !!queryData?.filters?.engagement_types?.length;
    count += !!queryData?.filters?.engagement_id?.length;
    count += !!queryData?.filters?.themes?.length;
    count += !!queryData?.filters?.sub_themes?.length;
    count += !!queryData?.filters?.sentiment?.length;
    count += !!queryData?.filters?.is_question;
    count += !!queryData?.filters?.verified_status;
    count += !!queryData?.filters?.min_followers;
    count += !!queryData?.filters?.usernames?.length;
    if (isAllOrOverdue) {
      count += !!queryData?.filters?.slas_ids?.length;
      count += !!queryData?.filters?.teams_ids?.length;
      count += !!queryData?.filters?.assignees_ids?.length;
    }
    setFiltersChangesCount(count);
    if (!isRabMq) {
      queryData.page_number = filters?.page + 1;
      queryData.last_operation = lastOperation;
      queryData.engagement_post_id = JSON.stringify(engagementPostId);
    } else {
      setFilters({ ...filters });
      setAllData({ ...queryData });
      setResponse({});
    }
    // NOTE: if queryData?.monitors?.length == 0 so we do not call getEngagemetSidebarData api
    if (queryData?.monitors?.length) {
      getEngagemetSidebarData(queryData);
    }

    // handle alert_id case
    if (window.location.href?.includes("alert_id")) {
      let url = window.location.search;
      const urlParams = new URLSearchParams(url);
      const alertId = urlParams.get("alert_id");
      AlertController.getAlertDetail(alertId, 1).then((data) => {
        if (
          !isEmptyObj(data?.data) &&
          (data?.status_code === 201 || data?.status_code === 200)
        ) {
          queryData.start_date = data?.data?.start_date;
          queryData.end_date = data?.data?.end_date;
          queryData.monitors = data?.data?.monitor_id;
          queryData.filters.alert_interaction_id =
            data?.data?.alert_interaction_id;
          queryData.filters.data_sources = data?.data?.data_source_name?.length
            ? Array.isArray(data?.data?.data_source_name)
              ? data?.data?.data_source_name?.join(",")
              : data?.data?.data_source_name
            : undefined;
          queryData.filters.engagement_status = "new";
          setFilters({
            ...filters,
            company_monitors: {
              monitors: !data?.data?.isChannel ? [+data?.data?.monitor_id] : [],
              channels: data?.data?.isChannel ? [+data?.data?.monitor_id] : [],
            },
          });
          setSavedFilters({
            ...savedFilters,
            company_monitors: {
              monitors: !data?.data?.isChannel
                ? [
                    {
                      id: +data?.data?.monitor_id,
                      name: data?.data?.monitor_name,
                    },
                  ]
                : [],
              channels: data?.data?.isChannel
                ? [
                    {
                      id: +data?.data?.monitor_id,
                      name: data?.data?.monitor_name,
                    },
                  ]
                : [],
            },
            data_sources: data?.data?.data_source_name?.length
              ? [...data?.data?.data_source_name]
              : [],
          });
          if (isRabMq) {
            setAllData({ ...queryData });
            setResponse({});
          }

          getEngagemetSidebarData(queryData);
        } else if (data?.errorMsg?.response?.status === 404) {
          history.push(`/alerts/deletedAlerts/engagement`);
        }
      });
    }
  };

  // handle refresh cards list
  useEffect(() => {
    if (refresh) {
      filters.page = 1;
      setFilters({ ...filters });
      applyFilters();
    }
  }, [refresh]);

  // handle change card state to complete or active , we handle that at EngagementHeader componanent
  useEffect(() => {
    if (complate) {
      filters.engagement_status = complate;
      setFilters({ ...filters });
      applyFilters();
    }
  }, [complate]);

  // handle show all engagement for profile, we handle that at EngagementsDetails componanent
  useEffect(() => {
    if (!isEmptyObj(totalEngagements)) {
      let usersAccountsArr = !savedFilters?.users_accounts?.includes(
        totalEngagements?.user_name,
      )
        ? [...savedFilters?.users_accounts, totalEngagements?.user_name]
        : [...savedFilters?.users_accounts];
      let dataSourcesArr = !savedFilters?.data_sources?.includes(
        totalEngagements?.data_sources,
      )
        ? [...savedFilters?.data_sources, totalEngagements?.data_sources]
        : [...savedFilters?.data_sources];

      setSavedFilters({
        ...savedFilters,
        data_sources: dataSourcesArr,
        users_accounts: usersAccountsArr,
      });
      setTotalEngagementsFlag(true);
    }
  }, [totalEngagements]);

  useEffect(() => {
    if (totalEngagementsFlag) {
      filters.page = 1;
      setFilters({ ...filters });
      applyFilters();
      setTotalEngagementsFlag(false);
    }
  }, [totalEngagementsFlag]);

  return (
    <Box className="engagements-sideBar-main-container">
      <SlaSidebar
        filters={filters}
        setFilters={setFilters}
        applyFilters={() => applyFilters()}
        setTabValue={setTabValue}
        isOpen={isSLASideBarOpen}
        setIsOpen={setIsSLASideBarOpen}
        slaCounterData={slaCounterData}
      />
      <Box className="engagements-sideBar-wrapper">
        <SideBarHeading
          filters={filters}
          setFilters={setFilters}
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
          applyFilters={applyFilters}
          filtersChangesCount={filtersChangesCount}
          dates={dates}
          setDates={setDates}
          reduxSelectedCard={reduxSelectedCard}
          allData={allData}
          openNavigationMenu={openNavigationMenu}
          setOpenNavigationMenu={setOpenNavigationMenu}
          navigationMenuStatus={navigationMenuStatus}
          setNavigationMenuStatus={setNavigationMenuStatus}
          openSetupFlow={openSetupFlow}
          setOpenSetupFlow={setOpenSetupFlow}
          openSetupFlowFirst={openSetupFlowFirst}
          setOpenSetupFlowFirst={setOpenSetupFlowFirst}
          isDoneSetup={isDoneSetup}
          setIsDoneSetup={setIsDoneSetup}
          openNewConversation={openNewConversation}
          setOpenNewConversation={setOpenNewConversation}
          samrtSortingNew={samrtSortingNew}
          setSamrtSortingPopup={setSamrtSortingPopup}
        />
        {openFilters ? (
          <Filters
            enabledFilters={enabledFilters}
            filters={filters}
            setFilters={setFilters}
            savedFilters={savedFilters}
            setSavedFilters={setSavedFilters}
            closeFilter={() => setOpenFilters(false)}
            applyFilters={applyFilters}
          />
        ) : null}

        {samrtSortingPopup ? (
          <Box className="smart-sorting-popup">
            <Typography variant="h6" className="smart-sorting-title">
              {CheckValueLocale("smart_sorting_popup_title", "", {}, intl)}
            </Typography>

            <Typography variant="body1" className="smart-sorting-contant">
              {CheckValueLocale("smart_sorting_popup_contant", "", {}, intl)}
            </Typography>

            <Button
              className="smart-sort-btn"
              variant="outlined"
              onClick={() => {
                setSamrtSortingPopup(false);
                setSamrtSortingNew(true);
              }}
            >
              {CheckValueLocale("got_it", "", {}, intl)}
            </Button>
          </Box>
        ) : null}

        <SideBarTabs
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          cardState={cardState}
          setCardState={setCardState}
          filters={filters}
          setFilters={setFilters}
          applyFilters={applyFilters}
          response={response}
          allData={allData}
          preLoader={preLoader}
          tabValue={tabValue}
          setTabValue={setTabValue}
          setLastOperation={setLastOperation}
          setEngagementPostId={setEngagementPostId}
          setSlaCounterData={setSlaCounterData}
          slaCounterData={slaCounterData}
          setOpenSidebarToAssignTeam={setOpenSidebarToAssignTeam}
        />
      </Box>
    </Box>
  );
};
export default EngagementsSideBar;
