import { useState } from "react";
import { useIntl } from "react-intl";
import PopupModal from "components/popupModal";
import SurveyController from "services/controllers/surveyController";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { Box, Typography } from "@mui/material";

const DeleteSurveyModal = (props) => {
  const intl = useIntl();
  const [open, setOpen] = useState(props?.openDeleteSurveyModal);
  const [archiveLoading, setArchiveLoading] = useState(false);
  const [snackBarDetails, setSnackBarDetails] = useState({
    openSnackBar: false,
    snackBarMsg: "",
    severity: "",
  });
  const surveyName = (
    <Typography component={"span"} style={{ textTransform: "capitalize" }}>
      {props?.surveyName}
    </Typography>
  );

  const modalBody = (
    <Box className="shared-dialog-body">
      {`${CheckValueLocale("are_you_sure_to_delete", "", { Name: props?.surveyName }, intl)}`}
    </Box>
  );
  const handleDeleteSurvey = () => {
    setArchiveLoading(true);
    SurveyController.unpublishSurvey(props?.surveyId).then((data) => {
      setOpen(!props?.openDeleteSurveyModal);
      if (data?.data?.survey?.status === "success") {
        setSnackBarDetails({
          openSnackBar: true,
          severity: "success",
          snackBarMsg: CheckValueLocale(
            "survey_deleted_successfully",
            "",
            {},
            intl,
          ),
        });

        props.getSurveys();
      } else {
        setOpen(!props?.openDeleteSurveyModal);
        setSnackBarDetails({
          openSnackBar: true,
          severity: "error",
          snackBarMsg:
            CheckValueLocale("failed_to_delete", "", {}, intl) +
            " #" +
            props?.surveyName,
        });
      }
      setArchiveLoading(false);
      setTimeout(() => {
        setSnackBarDetails({ ...snackBarDetails });
      }, 3000);
    });
  };
  return (
    <>
      <PopupModal
        title={CheckValueLocale("archive_survey", "", {}, intl)}
        body={modalBody}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("archive", "", {}, intl)}
        open={open}
        disabled={archiveLoading}
        close={() => {
          props.setOpenDeleteSurveyModal(false);
        }}
        accept={handleDeleteSurvey}
        warning={true}
        minWidth="600px"
      />

      {snackBarDetails?.openSnackBar && (
        <SnackBar
          open={true}
          setOpen={setOpen}
          severity={snackBarDetails?.severity}
          message={snackBarDetails?.snackBarMsg}
          snackBarClassName={"delete-survey-snackbar"}
          showCloseBtn={snackBarDetails?.severity === "success" ? true : false}
          textColor={
            snackBarDetails?.severity === "success" ? "green" : "brown"
          }
          handleClose={() =>
            setSnackBarDetails({
              openSnackBar: false,
              snackBarMsg: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
};
export default DeleteSurveyModal;
