import { AppBar, Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import LucButton from "shared/lucButton/lucButton";
import { CheckValueLocale } from "utils/helpers";

export const EditTeamFooter = ({
  handleSave,
  handleCancel,
  isLoading,
  isDisabled,
  numberOfChanges,
}) => {
  const intl = useIntl();
  return (
    <AppBar className="edit-team-footer">
      <Box className="edit-team-footer-toolbar">
        <Typography variant="body2" className="edit-team-footer-counter">
          {CheckValueLocale(
            "you_have_changed_detials",
            "",
            { number: numberOfChanges },
            intl,
          )}
        </Typography>
        <Box className="edit-team-footer-toolbar-btns">
          <LucButton
            onClick={handleCancel}
            variant="flat"
            disabled={isLoading}
            type="secondary"
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
          <LucButton
            onClick={handleSave}
            disabled={isDisabled}
            loading={isLoading}
          >
            {CheckValueLocale("save", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
    </AppBar>
  );
};
