export const customDashboardCxmTooltips = {
  // --------------- Twitter Public ---------------
  cxm_twitter_posts_followers_growth: "followers_growth_tw_tooltip",
  cxm_twitter_posts_account_authors_activity:
    "account_authors_activity_tw_tooltip",
  cxm_twitter_posts_content_type: "posts_content_type_tw_tooltip",
  cxm_twitter_posts_posts_interactions: "posts_interactions_tw_tooltip",
  cxm_twitter_posts_reach_funnel: "reach_funnel_tw_tooltip",
  cxm_twitter_posts_posts_top_images: "top_images_tw_tooltip",
  cxm_twitter_posts_posts_top_videos: "top_videos_tw_tooltip",
  cxm_twitter_comments_volume_overtime: "comments_volume_tw_tooltip",
  cxm_twitter_comments_sentiment_analysis:
    "comments_sentiment_analysis_tooltip",
  cxm_twitter_comments_content_style: "comments_content_style_tw_tooltip",
  cxm_twitter_comments_associated_topics: "comments_associated_topics_tooltip",
  cxm_twitter_comments_top_keywords: "comments_top_keywords_tooltip",
  cxm_twitter_comments_top_hash_tags: "comments_top_hashtags_tooltip",
  cxm_twitter_comments_top_languages: "top_languages_fb_tooltip",
  cxm_twitter_comments_dialects_sub_dialects: "dialects_subdialects_tooltip",
  cxm_twitter_comments_top_countries: "top_countries_tw_tooltip",
  cxm_twitter_comments_top_cities: "top_cities_tw_tooltip",
  cxm_twitter_comments_gender_distribution: "gender_distribution_tw_tooltip",
  cxm_twitter_comments_account_types: "account_types_tw_tooltip",
  cxm_twitter_comments_top_engagers: "top_engagers_tw_tooltip",
  cxm_twitter_comments_top_sources: "top_sources_tw_tooltip",
  cxm_twitter_comments_top_verified_engagers:
    "top_verified_engagers_tw_tooltip",
  cxm_twitter_comments_top_influencers: "top_infleuncers_tw_tooltip",
  cxm_twitter_comments_themes: "them_tolt",
  cxm_twitter_comments_customer_care_performance:
    "customer_performance_ig_tooltip",
  cxm_twitter_posts_volume_overtime: "posts_volume_public_ig_tooltip",
  cxm_twitter_customer_care_performance: "customer_performance_ig_tooltip",
  // --------------- Twitter Private ---------------
  cxm_twitter_dm_conversation_interactions: "conversation_interaction_tooltip",
  cxm_twitter_dm_sentiment_analysis: "sentiments_dm_tooltip",
  cxm_twitter_dm_themes: "themes_dm_tooltip",
  cxm_twitter_dm_dialects_sub_dialects: "dialects_subdialects_dm_tooltip",
  cxm_twitter_dm_channel_accounts: "channel_accounts_dm_tooltip",
  cxm_twitter_dm_content_type: "content_type_dm_tooltip",
  cxm_twitter_dm_account_contacts_activity: "channel_activity_dm_tooltip",
  cxm_twitter_dm_top_keywords: "top_kewords_dm_tooltip",
  cxm_twitter_dm_gender_distribution: "genders_tooltip",
  cxm_twitter_dm_most_engaged_contacts: "most_engaged_dm_tooltip",
  cxm_twitter_dm_top_influencers: "most_influential_tooltip",
  cxm_twitter_dm_top_verified_engagers: "verified_contacts_tooltip",
  // --------------- Facebook Public ---------------
  cxm_facebook_posts_content_type: "posts_content_type_fb_tooltip",
  cxm_facebook_posts_top_images: "posts_top_images_tooltip",
  cxm_facebook_posts_top_videos: "posts_top_videos_tooltip",
  cxm_facebook_posts_volume_overtime: "posts_volume_fb_tooltip",
  cxm_facebook_posts_reactions: "reactions_fb_tooltip",
  cxm_facebook_comments_sentiment_analysis:
    "comments_sentiment_analysis_tooltip",
  cxm_facebook_posts_posts_interactions: "posts_interactions_fb_tooltip",
  cxm_facebook_posts_top_keywords: "posts_top_keywords_fb_tooltip",
  cxm_facebook_posts_top_hash_tags: "posts_top_hashtags_fb_tooltip",
  cxm_facebook_comments_associated_topics: "comments_associated_topics_tooltip",
  cxm_facebook_comments_dialects_sub_dialects: "dialects_subdialects_tooltip",
  cxm_facebook_posts_account_authors_activity:
    "account_authors_activity_fb_tooltip",
  cxm_facebook_comments_themes: "themes_tooltip_linkedin",
  cxm_facebook_customer_care_performance: "customer_performance_fb_tooltip",
  cxm_facebook_comments_volume_overtime: "total_interactions_public_tooltip",
  cxm_facebook_comments_reactions: "comments_reactions_fb_tooltip",
  cxm_facebook_engagers_activity: "engagers_activity_fb_tooltip",
  cxm_facebook_comments_top_keywords: "comments_top_keywords_tooltip",
  cxm_facebook_comments_top_hash_tags: "comments_top_hashtags_tooltip",
  cxm_facebook_comments_posts_interactions: "comments_interactions_fb_tooltip",
  // --------------- Facebook Private ---------------
  cxm_facebook_dm_conversation_interactions: "conversation_interaction_tooltip",
  cxm_facebook_dm_sentiment_analysis: "sentiments_dm_tooltip",
  cxm_facebook_dm_themes: "themes_dm_tooltip",
  cxm_facebook_dm_dialects_sub_dialects: "dialects_subdialects_dm_tooltip",
  cxm_facebook_dm_channel_accounts: "channel_accounts_dm_tooltip",
  cxm_facebook_dm_content_type: "content_type_dm_tooltip",
  cxm_facebook_dm_account_contacts_activity: "channel_activity_dm_tooltip",
  cxm_facebook_dm_top_keywords: "top_kewords_dm_tooltip",
  cxm_facebook_dm_gender_distribution: "genders_tooltip",
  cxm_facebook_dm_most_engaged_contacts: "most_engaged_dm_tooltip",
  // --------------- Instagram Public ---------------
  cxm_instagram_posts_account_authors_activity:
    "account_authors_activity_ig_tooltip",
  cxm_instagram_posts_posts_interactions: "posts_interactions_ig_tooltip",
  cxm_instagram_posts_average_engagement_per_posts:
    "average_engagements_per_post_ig_tooltip",
  cxm_instagram_posts_content_type: "posts_content_type_ig_tooltip",
  cxm_instagram_top_reels: "posts_top_reels_tooltip",
  cxm_instagram_posts_top_images: "posts_top_images_tooltip",
  cxm_instagram_comments_volume_overtime: "comments_volume_ig_tooltip",
  cxm_instagram_comments_sentiment_analysis:
    "comments_sentiment_analysis_tooltip",
  cxm_instagram_comments_themes: "themes_tooltip",
  cxm_instagram_comments_comments_interactions:
    "comments_interactions_ig_tooltip",
  cxm_instagram_comments_top_keywords: "comments_top_keywords_tooltip",
  cxm_instagram_comments_top_hash_tags: "comments_top_hashtags_ig_tooltip",
  cxm_instagram_comments_associated_topics:
    "comments_associated_topics_tooltip",
  cxm_instagram_comments_dialects_sub_dialects: "dialects_subdialects_tooltip",
  cxm_instagram_top_authors: "top_authors_ig_tooltip",
  cxm_instagram_comments_customer_care_performance:
    "customer_performance_ig_tooltip",
  // --------------- Instagram Private ---------------
  cxm_instagram_dm_conversation_interactions:
    "conversation_interaction_tooltip",
  cxm_instagram_dm_sentiment_analysis: "sentiments_dm_tooltip",
  cxm_instagram_dm_themes: "themes_dm_tooltip",
  cxm_instagram_dm_dialects_sub_dialects: "dialects_subdialects_dm_tooltip",
  cxm_instagram_dm_channel_accounts: "channel_accounts_dm_tooltip",
  cxm_instagram_dm_content_type: "content_type_dm_tooltip",
  cxm_instagram_dm_account_contacts_activity: "channel_activity_dm_tooltip",
  cxm_instagram_dm_top_keywords: "top_kewords_dm_tooltip",
  cxm_instagram_dm_gender_distribution: "genders_tooltip",
  cxm_instagram_dm_most_engaged_contacts: "most_engaged_dm_tooltip",
  // --------------- LinkedIn Public ---------------
  cxm_linkedin_interactions: "interaction_linkedin_tooltip",
  cxm_linkedin_comments_sentiment_analysis:
    "sentiment_analysis_tooltip_linkedin",
  cxm_linkedin_themes: "themes_tooltip_linkedin",
  cxm_linkedin_channel_accounts: "channel_accounts_linkedin_tooltip",
  cxm_linkedin_content_type: "content_type_linkedin_tooltip",
  cxm_linkedin_dialects_sub_dialects:
    "dialects_per_subdialects_tooltip_linkedin",
  cxm_linkedin_contact_activity: "channel_activity_linkedin_tooltip",
  cxm_linkedin_top_keywords: "top_kewords_linkedin_tooltip",
  cxm_linkedin_top_hash_tags: "top_hashtags_linkedin_tooltip",
  cxm_linkedin_top_images: "top_images_tooltip_linkedin",
  cxm_linkedin_top_videos: "top_videos_tooltip_linkedin",
  cxm_linkedin_gender_distribution: "genders_tooltip_linkedin",
  cxm_linkedin_reactions: "reactions_linkedin_tooltip",
  cxm_linkedin_most_engaged_contacts: "most_engaged_tooltip_linkedin",
  // --------------- TikTok Public ---------------
  cxm_tiktok_video_volume: "vid_vol_tolt",
  cxm_tiktok_follower_growth_overtime: "foll_gro_tolt",
  cxm_tiktok_account_authors_activity: "autor_tolt",
  cxm_tiktok_videos_interactions: "vid_inter_tolt",
  cxm_tiktok_top_videos: "top_vid_tolt",
  cxm_tiktok_comments_volume: "comm_tolt",
  cxm_tiktok_comments_sentiment_analysis: "sent_tolt",
  cxm_tiktok_comments_themes: "them_tolt",
  cxm_tiktok_top_keywords: "comments_top_keywords_tooltip",
  cxm_tiktok_top_hashtags: "comments_top_hashtags_tooltip",
  cxm_tiktok_comments_comments_gender_distribution: "gender_tolt",
  cxm_tiktok_comments_associated_topics: "assoc_tolt",
  cxm_tiktok_comments_comments_top_languages: "lang_tolt",
  cxm_tiktok_dialects_subdialects: "dialct_tolt",
  cxm_tiktok_top_engagers: "engager_tolt",
  cxm_tiktok_customer_care_performance: "cust_tolt",
};
