import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useIntl } from "react-intl";
import "../../../../createMonitor.scss";
import CommonFunctions from "../../../../createCommonFunctions/createMonitorCommonFunctions";
import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import { getCompanyApps } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import CircularLoading from "components/circularProgress";
import SocialMediaAccountButton from "../../../../Components/SocialMediaAuthButton/socialMediaAuthButton";
import ManagedAccountAuthUser from "../SocialMediaAuthUsers/managedAccountAuthUsers";
import CustomerCareInput from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/customerCareInput";

const SocialMediaManagedAccount = (props) => {
  const intl = useIntl();
  const [coustomerCareVal, setCoustomerCareVal] = useState([]);
  const {
    SourceId,
    dataSourceName,
    handleCreateMonitorBtn,
    monitorData,
    setMonitorData,
    socialAccounts,
    isLoading,
  } = props;
  const [companyApps, setCompanyApps] = useState({});
  useEffect(() => {
    setMonitorData({ ...monitorData, coustomerCareVal });
  }, [coustomerCareVal]);

  useEffect(() => {
    // getSocialAccounts(SourceId);
    //Called to get data used later in auth if user has no account
    getCompanyApps(setCompanyApps);
    props.setActiveDataSourceId(SourceId);
    isEmptyArray(socialAccounts) && handleCreateMonitorBtn("");
  }, []);
  return (
    <>
      {!socialAccounts || isLoading ? (
        <CircularLoading />
      ) : isEmptyArray(socialAccounts) ? (
        <SocialMediaAccountButton
          authHead={`authinticate_new_${dataSourceName}_account`}
          authDataSourceName={`add_${dataSourceName}_account`}
          companyApps={companyApps}
          dataSourceName={dataSourceName}
          isDataSourceEmpty
          isCompanyAccount={false}
          redirectUrl={
            window.location.origin +
            "/settings/accounts_management/users_accounts"
          }
        />
      ) : (
        <>
          <Typography className="choose-account-managed">
            {CheckValueLocale("select_an_account", "", {}, intl)}:
          </Typography>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mb={7}
            sx={{ marginBottom: "35px" }}
          >
            <ManagedAccountAuthUser
              {...props}
              socialAccounts={socialAccounts}
            />
            <Grid item xs={4} mt={1.5}>
              <SocialMediaAccountButton
                authHead={`authinticate_new_${dataSourceName}_account`}
                authDataSourceName={`add_${dataSourceName}_account`}
                companyApps={companyApps}
                dataSourceName={dataSourceName}
                isCompanyAccount={false}
                redirectUrl={
                  window.location.origin +
                  "/settings/accounts_management/users_accounts"
                }
              />
            </Grid>
          </Grid>

          <CustomerCareInput
            coustomerCareVal={coustomerCareVal}
            setCoustomerCareVal={setCoustomerCareVal}
            dataSourceName={dataSourceName}
          />
        </>
      )}
    </>
  );
};

export default SocialMediaManagedAccount;
