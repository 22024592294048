import { useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

import KeywordsInput from "./keywordsInput";
import { CheckValueLocale } from "utils/helpers";
import DateRangePicker from "shared/dateRangePicker/dateRangePicker";
import ExploreStepper from "./exploreStepper";

const ExploreFields = (props) => {
  const intl = useIntl();
  const {
    keywordsFocused,
    setKeywordsFocused,
    keywords,
    setKeywords,
    selectedDatasource,
    spamKeywords,
    setSpamKeywords,
    spamKeywordsFocused,
    setSpamKeywordsFocused,
    selectedKeyword,
    setSelectedKeyword,
    selectedSpamKeyword,
    setSelectedSpamKeyword,
    startDate,
    endDate,
    handleDatasourceChange,
    getExploreData,
    trendtingTopicsData,
    datasourceQuota,
    formFieldErrors,
    isLoading,
    informerExploreData,
    applyFilter,
    isDatePickerChange,
    setIsResetDate,
    setDisableCreateExplore,
    exploreInitialDate,
    disableCreateExplore,
    activeExploreStep,
  } = props;

  useEffect(() => {
    getExploreData();
  }, []);
  const handleSelectedDatasource = () => {
    let datasoucrName = [];
    selectedDatasource?.map((el) => {
      datasoucrName.push(el?.name?.toLowerCase());
    });
    return datasoucrName;
  };
  const handleCheckValueMsgDatasouece = () => {
    let titleName = "";
    if (
      handleSelectedDatasource()?.length == 1 &&
      handleSelectedDatasource()?.includes("twitter")
    ) {
      titleName = "explore_datasources_heading_twitter";
    } else if (
      handleSelectedDatasource()?.length == 1 &&
      handleSelectedDatasource()?.includes("talkwalker")
    ) {
      titleName = "explore_datasources_heading_newsblogs";
    } else {
      titleName = "explore_datasources_heading";
    }
    return titleName;
  };
  return (
    <Grid container className="explore-fields-container" spacing={3}>
      <Grid className="explore-keywords-box" item xs={6}>
        <KeywordsInput
          isKeywordsFields
          setFocused={setKeywordsFocused}
          focused={keywordsFocused}
          keywords={keywords}
          setKeywords={setKeywords}
          setKeywordsContent={setKeywords}
          {...props}
          keywordsTitle="explore_keywords_title"
          keywordsErrorMsg={formFieldErrors?.keywords}
          setKeywordValue={setSelectedKeyword}
          setSelectedKeyword={setSelectedKeyword}
          recentTitle="explore_keywords_recent"
          trendingData={trendtingTopicsData || []}
          recentData={informerExploreData?.keywords || []}
          inputName="keywords"
          showContentRecentTrending={
            isLoading ||
            trendtingTopicsData?.length ||
            informerExploreData?.keywords?.length
          }
          disableCreateExplore={disableCreateExplore}
          setDisableCreateExplore={setDisableCreateExplore}
        />
      </Grid>
      <Grid className="explore-spam-keywords-box" item xs={6}>
        <KeywordsInput
          setFocused={setSpamKeywordsFocused}
          focused={spamKeywordsFocused}
          keywordsContent={spamKeywords}
          spamKeywords={spamKeywords}
          setSpamKeywords={setSpamKeywords}
          setKeywordsContent={setSpamKeywords}
          {...props}
          keywordsTitle="explore_spam_keywords_title"
          setKeywordValue={setSelectedSpamKeyword}
          setSelectedSpamKeyword={setSelectedSpamKeyword}
          keywordsErrorMsg={formFieldErrors?.exclude_keywords}
          keywordValue={selectedSpamKeyword}
          recentTitle="explore_spam_keywords_recent"
          recentData={informerExploreData?.spam_keywords || []}
          inputName="exclude_keywords"
          showContentRecentTrending={
            isLoading || informerExploreData?.spam_keywords?.length
          }
          isLoading={isLoading}
          setDisableCreateExplore={setDisableCreateExplore}
        />
      </Grid>
      <Grid item xs={6} className="explore-datasources-box">
        <Box className="input-form-control">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {CheckValueLocale("explore_datasource", "", {}, intl)}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Data sources"
              className="datasources-select-items"
              multiple
              displayEmpty
              value={selectedDatasource}
              onChange={(e) => handleDatasourceChange(e)}
              renderValue={(selected) => {
                return selected
                  ?.map((val) => CheckValueLocale(val?.name, "", {}, intl))
                  ?.join(", ");
              }}
              disabled={!informerExploreData?.data_sources?.length}
            >
              {informerExploreData?.data_sources?.map((item) => (
                <MenuItem
                  key={`explore-datasource-type-${item?.id}`}
                  className="explore-datasource-item"
                  value={item}
                  disabled={!datasourceQuota?.[item?.name]?.can_create}
                >
                  <Checkbox
                    checked={
                      selectedDatasource.findIndex(
                        (val) => +val?.id === +item?.id,
                      ) >= 0
                    }
                  />
                  <ListItemText
                    className="list-item-explore-datasource"
                    primary={CheckValueLocale(item?.name, "", {}, intl)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!isLoading && selectedDatasource?.length ? (
          <Typography component={"span"} className="explore-datasource-details">
            {CheckValueLocale(
              handleCheckValueMsgDatasouece(),
              "",
              {
                twToken: datasourceQuota?.TWITTER?.token?.toLocaleString() || 0,
                twCurrent:
                  datasourceQuota?.TWITTER?.current?.toLocaleString() || 0,
                nbToken:
                  datasourceQuota?.TALKWALKER?.token?.toLocaleString() || 0,
                nbCurrent:
                  datasourceQuota?.TALKWALKER?.current?.toLocaleString() || 0,
              },
              intl,
            )}
          </Typography>
        ) : null}
      </Grid>
      <Grid tem xs={6} className="explore-date-picker">
        <DateRangePicker
          applyFilter={applyFilter}
          startDate={startDate}
          endDate={endDate}
          openCustomDateLeft={true}
          id="date-range-picker-explore-social"
          monitorActiveTabId="explore-page"
          isExplorePage
          oldestDate={informerExploreData?.minDate}
          isDatePickerChange={isDatePickerChange}
          setIsResetDate={setIsResetDate}
          exploreInitialDate={exploreInitialDate}
          setDisableCreateExplore={setDisableCreateExplore}
          activeExploreStep={activeExploreStep}
        />
      </Grid>
      <ExploreStepper {...props} />
    </Grid>
  );
};

export default ExploreFields;
