import React, { useEffect, useState } from "react";
import moment from "moment";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import { useCustomDashboardWidgetTooltip } from "../../../hooks/useCustomDashboardWidgetTooltip";

const CustomDashboardStackedBarChart = (props) => {
  const { widget, handledWidgetName, socialWidgets, dashboardInfo } = props;

  const [monitorId, setMonitorId] = useState(null);
  const [data, setData] = useState([]);
  const [dataPiechart, setDataPieChart] = useState([]);
  const [statsDetails, setStatsDetails] = useState([]);
  const [colors, setColors] = useState([]);
  const [lables, setLables] = useState([]);
  const [widgetFlags, setWidgetFlags] = useState({ hideLegend: true });

  const labelColors = {
    likes: "#2AAAE2",
    unlikes: "#80868C",
    like_page_over_time: "#2AAAE2",
    unlike_page_over_time: "#80868C",
    comments: "#80868C",
    replies: "#80868C",
    reactions: "#2AAAE2",
    retweets: "#334155",
    reposts: "#334155",
    original: "#2AAAE2",
    authors: "#2AAAE2",
    potential_reach: "#334155",
    potential_impression: "#80868C",
    video: "#2AAAE2",
    image: "#80868C",
    url: "#334155",
    text: "#AFBCCE",
    like_count: "#2AAAE2",
    comment_count: "#334155",
    view_count: "#80868C",
    share_count: "#CBD5E1",
    page_negative_interactions_over_time: "#C51162",
    page_positive_interactions_over_time: "#4CAF50",
    page_website_clicks_over_time: "#2AAAE2",
    website_clicks: "#2AAAE2",
    page_call_phone_clicks_over_time: "#80868C",
    phone_clicks: "#80868C",
    page_directions_clicks_over_time: "#334155",
    directs_clicks: "#334155",
    profile_visits: "#AFBCCE",
    male: "#2AAAE2",
    female: "#FF4081",
    like_reaction: "#1892F2",
    celebrate_reaction: "#F7B94D",
    curious_reaction: "#1C404E",
    love_reaction: "#FB5C77",
    care_reaction: "#F7B94D",
    haha_reaction: "#FEDC5F",
    wow_reaction: "#00D7B5",
    angry_reaction: "#EC8A92",
    sad_reaction: "#1C404E",
    insightful_reaction: "#EC8A92",
    support_reaction: "#00D7B5",
    catalog: "#334155",
    post: "#334155",
    page_reach_over_time: "#2AAAE2",
    page_impressions_over_time: "#80868C",
  };

  // Set Labels names and colors for chart
  const handleLegendsColors = (legends) => {
    let colors = [];
    let labels = [];
    legends?.map((legend) => {
      labels?.push(legend?.name);
      colors?.push(labelColors?.[legend?.name]);
    });
    setColors(colors);
    setLables(labels);
  };

  const handleGenderAgeDistResp = (response) => {
    if (response) {
      response?.map((item) => {
        Object.keys(item).map((val, index) => {
          setData((oldArray) => [
            ...oldArray,
            { name: val, value: Object.values(item)?.[index] },
          ]);
        });
      });
    }
  };

  useEffect(() => {
    if (widget && handledWidgetName && socialWidgets) {
      let dataState =
        socialWidgets?.[handledWidgetName]?.[widget?.monitor_id]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ];
      setMonitorId(widget?.monitor_id);

      switch (widget?.widget_name) {
        case "comments_posts_interactions":
        case "posts_posts_interactions":
          setData(
            dataState?.["posts_interactions"]?.posts_interactions_over_time ||
              dataState?.["posts_interactions"]?.comments_interactions,
          );
          setDataPieChart(
            dataState?.["posts_interactions"]?.posts_interactions_legends ||
              dataState?.["posts_interactions"]?.comments_interactions_legends,
          );
          setStatsDetails([
            {
              name: "max_interaction",
              value: dataState?.["interactions"]?.max_interactions,
              date: moment
                ?.unix(dataState?.["interactions"]?.[`max_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "min_interaction",
              value: dataState?.["interactions"]?.min_interactions,
              date: moment
                ?.unix(dataState?.["interactions"]?.[`min_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "avg_interaction_per_day",
              value: dataState?.["interactions"]?.average_interactions,
            },
          ]);
          handleLegendsColors(
            dataState?.["posts_interactions"]?.posts_interactions_legends ||
              dataState?.["posts_interactions"]?.comments_interactions_legends,
          );

          break;

        case "comments_comments_interactions":
          setData(dataState?.comments_interactions);
          setDataPieChart(dataState?.comments_interactions_legends);
          setStatsDetails([
            {
              name: "max_interaction",
              value: dataState?.max_interactions,
              date: moment
                ?.unix(dataState?.[`max_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "min_interaction",
              value: dataState?.min_interactions,
              date: moment
                ?.unix(dataState?.[`min_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "avg_interaction_per_day",
              value: dataState?.average_interactions,
            },
          ]);
          handleLegendsColors(dataState?.comments_interactions_legends);
          break;

        case "interactions":
          setData(
            dataState?.[widget?.widget_name]?.posts_interactions_over_time ||
              dataState?.[widget?.widget_name]?.comments_interactions,
          );
          setDataPieChart(
            dataState?.[widget?.widget_name]?.posts_interactions_legends ||
              dataState?.[widget?.widget_name]?.comments_interactions_legends,
          );
          setStatsDetails([
            {
              name: "max_interaction",
              value: dataState?.["interactions"]?.max_interactions,
              date: moment
                ?.unix(dataState?.["interactions"]?.[`max_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "min_interaction",
              value: dataState?.["interactions"]?.min_interactions,
              date: moment
                ?.unix(dataState?.["interactions"]?.[`min_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "avg_interaction_per_day",
              value: dataState?.["interactions"]?.average_interactions,
            },
          ]);
          handleLegendsColors(
            dataState?.[widget?.widget_name]?.posts_interactions_legends ||
              dataState?.[widget?.widget_name]?.comments_interactions_legends,
          );
          setWidgetFlags({
            ...widgetFlags,
            showChartDetailsWidget: true,
          });
          break;

        case "videos_interactions":
          setData(dataState?.videos_interactions_over_time);
          setDataPieChart(dataState?.videos_interactions_over_time_legends);
          setStatsDetails([
            {
              name: "max_interaction",
              value: dataState?.max_interactions,
              date: moment
                ?.unix(dataState?.[`max_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "min_interaction",
              value: dataState?.min_interactions,
              date: moment
                ?.unix(dataState?.[`min_interaction_date`])
                ?.format("D-M-YYYY"),
            },
            {
              name: "avg_interaction_per_day",
              value: dataState?.average_interactions,
            },
          ]);
          handleLegendsColors(dataState?.videos_interactions_over_time_legends);
          setWidgetFlags({
            ...widgetFlags,
            showChartDetailsWidget: true,
          });
          break;

        case "reach_funnel":
          setData(dataState?.[widget?.widget_name]);
          setColors(["#64748B", "#2AAAE2"]);
          setLables(["Retweets", "original"]);
          setWidgetFlags({
            reachFunnel: true,
          });
          break;

        case "posts_reach_funnel":
          setData(dataState?.["reach_funnel"]);
          setColors(["#64748B", "#2AAAE2"]);
          setLables(["Retweets", "original"]);
          setWidgetFlags({
            reachFunnel: true,
          });
          break;

        case "page_actions":
          setData(dataState?.page_actions_overtime);
          setDataPieChart(dataState?.pie_chart_page_actions);
          handleLegendsColors(dataState?.pie_chart_page_actions);
          break;

        case "page_contact_details_interactions":
          setData(dataState?.page_contact_details_interactions_overtime);
          setDataPieChart(
            dataState?.pie_chart_page_contact_details_interactions,
          );
          handleLegendsColors(
            dataState?.pie_chart_page_contact_details_interactions,
          );
          break;

        case "page_likes_unlikes":
          setData(dataState?.page_likes_unlikes_overtime);
          setDataPieChart(dataState?.pie_chart_page_likes_unlikes);
          handleLegendsColors(dataState?.pie_chart_page_likes_unlikes);
          break;

        case "gender_age_distribution":
          handleGenderAgeDistResp(
            dataState?.["gender_distribution"]?.gender_distribution ||
              dataState?.gender_distribution,
          );

          setDataPieChart(
            dataState?.["gender_distribution"]?.gender_piechart ||
              dataState?.gender_piechart,
          );
          handleLegendsColors(
            dataState?.["gender_distribution"]?.gender_piechart ||
              dataState?.gender_piechart,
          );
          setWidgetFlags({ isGenderAgeSorted: true, hideLegend: true });
          break;

        case "comments_reactions":
        case "posts_reactions":
        case "reactions":
          setData(
            dataState?.[handledWidgetName]?.posts_reactions_over_time ||
              dataState?.["posts_reactions"]?.posts_reactions_over_time ||
              dataState?.posts_reactions_over_time,
          );
          setDataPieChart(
            dataState?.[handledWidgetName]?.posts_reactions_piechart ||
              dataState?.["posts_interactions"]?.posts_reactions_piechart ||
              dataState?.posts_reactions_piechart,
          );
          handleLegendsColors(
            dataState?.[handledWidgetName]?.posts_reactions_piechart ||
              dataState?.["posts_interactions"]?.posts_reactions_piechart ||
              dataState?.posts_reactions_piechart,
          );
          break;

        case "posts_reach":
          setData(dataState);
          setDataPieChart([]);
          break;

        case "content_type":
          setData(dataState?.posts_content_type);
          setDataPieChart(dataState?.posts_content_types_legends);
          handleLegendsColors(dataState?.posts_content_types_legends);
          break;

        case "posts_content_type":
          setData(
            dataState?.[widget?.widget_name]?.posts_content_type ||
              dataState?.posts_content_type,
          );
          setDataPieChart(
            dataState?.[widget?.widget_name]?.posts_content_types_legends ||
              dataState?.posts_content_types_legends,
          );
          handleLegendsColors(
            dataState?.[widget?.widget_name]?.posts_content_types_legends ||
              dataState?.posts_content_types_legends,
          );
          break;

        default:
          break;
      }
    }
  }, [widget, handledWidgetName, socialWidgets]);

  let titleTooltip = useCustomDashboardWidgetTooltip(
    dashboardInfo?.product,
    widget?.data_source,
    widget?.monitor_type?.name,
    handledWidgetName,
    widget?.is_dm,
  );

  return (
    <StackedBarWidget
      title={
        dashboardInfo?.product == "sm"
          ? handledWidgetName
          : `custom_omni_${handledWidgetName}`
      }
      reachFunnel={widgetFlags?.reachFunnel}
      isGenderAgeSorted={widgetFlags?.isGenderAgeSorted}
      titleToolTip={titleTooltip || ""}
      showDownloadIcon
      bigImg
      posts={data || []}
      showChartDetailsWidget={widgetFlags?.showChartDetailsWidget}
      showScales
      showGroupBy={false}
      statsDetailsData={statsDetails || []}
      colors={colors || []}
      labels={lables || []}
      showPostsInteractionStats
      showZoomPercentage
      hideLegend={widgetFlags?.hideLegend}
      dataPiechart={dataPiechart || []}
      postsColumnPreLoaderDataSources={
        socialWidgets?.[`${handledWidgetName}_pre_loader_data_sources`]?.[
          monitorId
        ]?.[
          widget?.is_dm ? `${widget?.data_source}_PRIVATE` : widget?.data_source
        ] || []
      }
      preLoaderTrackerDataSources={[monitorId] || []}
      isCustomDashboard
      monitorData={{
        name:
          dashboardInfo?.product === "cxm"
            ? widget?.account_identifier
            : widget?.monitor_name,
        type:
          dashboardInfo?.product === "cxm"
            ? widget?.data_category
            : widget?.monitor_type?.name,
        datasource: widget?.is_dm
          ? `${widget?.data_source}_private`
          : widget?.data_source,
      }}
    />
  );
};

export default CustomDashboardStackedBarChart;
