import { isEmptyObj } from "utils/helpers/index.js";
import Survey from "../api/survey.js";

var SurveyController = {};

SurveyController.getSurveys = (searchText, page) => {
  const queryData = {
    name_search: searchText,
    page: page,
  };
  return new Promise(function (resolve) {
    Survey.getSurveys(queryData)
      .then((response) => {
        resolve({
          data: response.data.surveys,
          survey_limit_reach: response?.data?.survey_limit_reach,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.getSurveysById = (id) => {
  return new Promise(function (resolve) {
    Survey.getSurveysById(id)
      .then((response) => {
        resolve({
          data: response.data.survey.data,
          custom_domain: response?.data?.company_custom_domain,
          survey_limit_reach: response?.data?.survey_limit_reach,
          is_survey_panel_enabled: response?.data?.is_survey_panel_enabled,
          is_skipped: response?.data?.is_skipped,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
SurveyController.saveSurveyChanges = (id, survey) => {
  return new Promise(function (resolve) {
    Survey.saveSurveyChanges(id, survey)
      .then((response) => {
        resolve({
          data: response.data.survey.data,
          status: response.data.survey.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.createSurveys = (surveyName, surveyLang) => {
  var queryData = {
    survey: {
      name: surveyName,
      lang: surveyLang,
    },
  };
  return new Promise(function (resolve) {
    Survey.createSurveys(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.createSurveys = (surveyName, surveyLang) => {
  var queryData = {
    survey: {
      name: surveyName,
      lang: surveyLang,
    },
  };
  return new Promise(function (resolve) {
    Survey.createSurveys(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.unpublishSurvey = (id) => {
  return new Promise(function (resolve) {
    Survey.unpublishSurvey(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: "failed",
        });
      });
  });
};

SurveyController.getSurveyInsights = (
  survey_id,
  product_id,
  start_date,
  end_date,
) => {
  var queryData = {
    survey_id,
    product_id,
    start_date,
    end_date,
  };
  return new Promise(function (resolve) {
    Survey.getSurveyInsights(queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.editSentiment = (
  id,
  survey_id,
  old_label,
  new_label,
  product_id,
) => {
  var queryData = {
    id,
    survey_id,
    old_label,
    new_label,
    product_id,
  };
  return new Promise(function (resolve) {
    Survey.editSentiment(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.editThemes = (
  id,
  survey_id,
  old_label,
  new_label,
  product_id,
) => {
  var queryData = {
    id,
    survey_id,
    old_label,
    new_label,
    product_id,
  };
  return new Promise(function (resolve) {
    Survey.editThemes(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.editSubThemes = (
  id,
  survey_id,
  old_label,
  new_label,
  product_id,
) => {
  var queryData = {
    id,
    survey_id,
    old_label,
    new_label,
    product_id,
  };
  return new Promise(function (resolve) {
    Survey.editSubThemes(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

SurveyController.exportSurvey = (queryData) => {
  return new Promise(function (resolve) {
    Survey.exportSurvey(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: error,
        });
      });
  });
};

SurveyController.duplicateSurvey = (id, queryData) => {
  return new Promise(function (resolve) {
    Survey.duplicateSurvey(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          error: error,
        });
      });
  });
};

// SurveyController.getSurveyInsights = (
//   survey_id,
//   product_id,
//   start_date,
//   end_date
// ) => {
//   var queryData = {
//     survey_id,
//     product_id,
//     start_date,
//     end_date,
//   };
//   return new Promise(function (resolve) {
//     Survey.getSurveyInsights(queryData)
//       .then((response) => {
//         resolve({
//           data: response.data.data,
//         });
//       })
//       .catch((error) => {
//         resolve({
//           data: [],
//           errorMsg: error,
//         });
//       });
//   });
// };

SurveyController.getSurveyspanelList = () => {
  return new Promise(function (resolve) {
    Survey.getSurveyspanelList()
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
SurveyController.submitSurveyspanel = (
  age,
  survey_id,
  gender,
  industry,
  survey_link,
  survey_name,
  country,
) => {
  let queryData = {
    age,
    survey_id,
    gender,
    industry,
    survey_link,
    survey_name,
    country,
  };
  return new Promise(function (resolve) {
    Survey.submitSurveyspanel(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
SurveyController.SurveyspanelById = (id, queryData) => {
  return new Promise(function (resolve) {
    Survey.SurveyspanelById(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          error: error,
        });
      });
  });
};
SurveyController.submitSurveysSkip = (survey_id, queryData) => {
  return new Promise(function (resolve) {
    Survey.submitSurveysSkip(survey_id, queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default SurveyController;
