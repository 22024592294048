import React from "react";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Box,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";

import PopupModal from "../../../../../../../../../src/components/popupModal";
import Chip from "@mui/material/Chip";
import monitorsController from "../../../../../../../../services/controllers/monitorsController";
import TopicsFuntions from "../topicsFunctions";

import "../style.scss";
import { getSocialIcon } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers";

const TopicImport = (props) => {
  const intl = useIntl();

  const {
    monitorTopics,
    setMonitorTopics,
    updatedParams,
    setUpdatedParams,
    monitorSettings,
    setTopicImported,
    setTopicImportedName,
    setTopicImportedNumber,
    setOpenSnakbar,
  } = props;

  const {
    handleUpdateParams,
    moniotrSelectedDataSources,
    collectDataSourcesArray,
  } = TopicsFuntions();

  const [open, setOpen] = useState(props.openImportModal);
  const [selectOpened, setSelectOpened] = useState(false);

  const [monitorsList, setMoniotrsList] = useState([]);
  const [monitorSelected, setMonitorSelected] = useState(0);
  const [monitorSelectedName, setMonitorSelectedName] = useState("");

  const [topicsArray, setTopicsArray] = useState([]);
  const [topicsExist, setTopicsExist] = useState(false); // should be | false | by default
  const [topicsSelectedCounter, setTopicsSelectedCounter] = useState(0);
  const [topicsSelectedArray, setTopicsSelectedArray] = useState([]);

  const handleClose = () => {
    props.handleOpenImportModal(false);
  };

  const handleSelectDisabledValue = (e) => {
    if (e) {
      setSelectOpened(true);
    }
  };

  const getAllTopicsMonitors = () => {
    monitorsController.getTopicsMonitors().then((data) => {
      setMoniotrsList(data?.data);
      collectDataSourcesArray(data?.data);
    });
  };

  const getTopicsForMonitor = (monitor_Id) => {
    monitorsController.getTopicsForMonitor(monitor_Id).then((data) => {
      let newData = data.data;
      if (newData !== undefined && newData.length !== 0) {
        setTopicsArray(newData);
        setTopicsExist(true);
      } else {
        setTopicsExist(false);
      }
    });
  };

  useEffect(() => {
    getAllTopicsMonitors();
  }, []);

  const handleSelectTopic = (e, topicValue, index, t) => {
    let newArray = [...topicsSelectedArray];
    if (!checkTopicExist(index, topicValue)) {
      newArray.push({
        i: index,
        value: topicValue,
        keywords: t.attributes.keywords,
        exclude_keywords: t.attributes.exclude_keywords,
      });
    } else {
      newArray = newArray.filter((e) => e.i !== index);
    }

    setTopicsSelectedArray(newArray);
    setTopicsSelectedCounter(newArray.length);
  };

  const checkTopicExist = (index, value) => {
    let isExist = false;
    topicsSelectedArray.map((t, idx) => {
      if (index === t.i && value === t.value) {
        isExist = true;
      }
    });

    return isExist;
  };

  const checkTopicSelected = (index) => {
    let selected = -1;
    topicsSelectedArray.map((t, idx) => {
      if (index === t.i) {
        selected = index;
      }
    });
    return selected;
  };

  const handleMonitorValue = (monitorId, monitorName) => {
    setMonitorSelected(monitorId);
    setMonitorSelectedName(monitorName);
    getTopicsForMonitor(monitorId);
  };

  //here we check before adding topic is exist or not
  const checkSelectTopics = (value) => {
    let newTopicsArr = [];
    value?.map((topic, i) => {
      newTopicsArr.push(
        topic?.name ? topic?.name?.trim() : topic?.value?.trim(),
      );
    });
    return newTopicsArr;
  };

  let allTopicsName = checkSelectTopics(monitorTopics);
  let selectedTopicsNames = checkSelectTopics(topicsSelectedArray);

  // check if selecetd topic exist or not
  let isTopicExist = allTopicsName.some((ai) =>
    selectedTopicsNames.includes(ai),
  );

  const handleImportTopics = (e) => {
    let importedArray = [...monitorTopics];

    if (isValidNumberOfTopics(importedArray, topicsSelectedArray)) {
      const arr = importedArray.filter((topic) => topic.name !== "");
      importedArray = arr;
      topicsSelectedArray.map((topic, i) =>
        importedArray.push({
          name: topic.value,
          keywords: topic.keywords,
          exclude_keywords: topic.exclude_keywords,
        }),
      );

      setMonitorTopics(importedArray);

      setTopicImported(true);
      setTopicImportedName(monitorSelectedName);
      setTopicImportedNumber(topicsSelectedCounter);
    } else {
      setTopicImported(false);
    }

    monitorSettings &&
      handleUpdateParams(
        importedArray,
        setMonitorTopics,
        updatedParams,
        setUpdatedParams,
      );

    setOpenSnakbar(true);
    handleClose();
  };

  const isValidNumberOfTopics = (topicsArr, importedSelectedArr) => {
    let count = 0;
    topicsArr.map((topic, index) => {
      if (topic.name === "") {
        count = count + 1;
      }
    });
    let allTopicsLength = topicsArr.length + importedSelectedArr.length - count;
    if (allTopicsLength > 8) return false;
    else return true;
  };

  const ImportBody = (
    <Grid id="import-body" item lg={6} xl={6}>
      <Box className="import-body-input">
        <InputLabel className="body-input-label">
          {CheckValueLocale("monitor", "", {}, intl)}
        </InputLabel>
        <Select
          labelId="report-platform-select-label"
          id="import-platform-simple-select"
          fullWidth
          name="monitor"
          className="import-select-box"
          value={selectOpened && monitorsList.length ? monitorSelected : "none"}
          defaultValue={
            selectOpened && monitorsList.length ? monitorSelected : "none"
          }
          onOpen={(e) => {
            handleSelectDisabledValue(e);
          }}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected === "none" || selected === 0) {
              return (
                <div>
                  {CheckValueLocale("select_placeholder", "", {}, intl)}
                </div>
              );
            }
            const arr = monitorsList.filter((monitor) => {
              if (monitor.id === selected) {
                return monitor;
              }
            });
            return arr[0].attributes.name;
          }}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: { sm: 150, xs: 150, md: 220, lg: 220, xl: 220 },
              },
            },
          }}
        >
          <MenuItem value="none" disabled id="import-menu-items-placeholder">
            {selectOpened && monitorsList.length
              ? CheckValueLocale("results", "", {}, intl)
              : CheckValueLocale("select_monitor", "", {}, intl)}
          </MenuItem>
          {monitorsList !== undefined && monitorsList.length ? (
            monitorsList?.map((monitor) => (
              <MenuItem
                onClick={() =>
                  handleMonitorValue(monitor?.id, monitor?.attributes?.name)
                }
                value={monitor?.id}
                key={monitor?.id}
                id={`import-menu-items-${monitor?.attributes?.name ?? "monitor-attributes-name"}`}
              >
                <div className="icons-before-monitors-list">
                  {moniotrSelectedDataSources !== undefined &&
                    moniotrSelectedDataSources !== [] &&
                    moniotrSelectedDataSources.map((source) =>
                      source.id === monitor?.id
                        ? source.data_sources.length > 1
                          ? source.data_sources.map((s) => {
                              return getSocialIcon(s);
                            })
                          : getSocialIcon(source.data_sources)
                        : null,
                    )}
                </div>

                <div className="topic-monitor-names-list">
                  {monitor?.attributes?.name}
                </div>
              </MenuItem>
            ))
          ) : (
            <MenuItem
              className="no-monitors-found"
              disabled
              id={`import-menu-items-no-topics`}
            >
              {CheckValueLocale("no_topics_monitors", "", {}, intl)}
            </MenuItem>
          )}
        </Select>
      </Box>

      {topicsExist && (
        <Box className="import-body-input">
          <InputLabel className="body-input-label">
            {CheckValueLocale("topics", "", {}, intl)}
          </InputLabel>
          <Box>
            {topicsArray.map((t, index) => (
              <Chip
                style={{ textAlign: "initial !important" }}
                label={t.attributes.name}
                key={index}
                onClick={(e) => {
                  handleSelectTopic(e, t.attributes.name, index, t);
                }}
                className="chip-style-inital"
                id={
                  checkTopicSelected(index) === index
                    ? "chip-style-selected"
                    : "chip-style-inital"
                }
              />
            ))}
          </Box>
          {isTopicExist && (
            <Box className="topics-err-msg">
              {CheckValueLocale("display_name_duplicate_error", "", {}, intl)}
            </Box>
          )}
        </Box>
      )}
    </Grid>
  );

  const ImportSelectedCounter = (
    <Box className="selected-counter-box">
      {topicsExist && (
        <p>
          <span> {topicsSelectedCounter}</span>{" "}
          {CheckValueLocale("selected", "", {}, intl)}
        </p>
      )}
    </Box>
  );

  return (
    <Box className="Import-topics-box">
      <PopupModal
        title={CheckValueLocale("import_topics_popup", "", {}, intl)}
        body={ImportBody}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("add", "", {}, intl)}
        open={open}
        close={handleClose}
        disabled={topicsSelectedCounter === 0 || isTopicExist}
        classeName="import-topics-window"
        modalStyle="import-topics-modal"
        addClasses="import-topics-actions"
        topicsExist
        topicSelectedCounter={ImportSelectedCounter}
        accept={handleImportTopics} //save only option
        minWidth="600px"
      />
    </Box>
  );
};

export default TopicImport;
